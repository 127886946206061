<p class="presenter" *ngIf="presentationFacade.presenterName$ | async as presenterName">
  {{ presenterName }} is presenting
</p>
<div class="presentation">
  <div class="slides" *ngIf="isPresenter">
    <div
      class="slide"
      *ngFor="let slide of slides"
      [ngClass]="{ current: slide.assetId === currentSlide.assetId }"
      (click)="setCurrentSlide(slide)"
    >
      <img [src]="slide.url" [alt]="slide.assetId" />
    </div>
  </div>
  <div class="main-slide">
    <img [src]="currentSlide?.url" alt="" />
  </div>
</div>
