import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ShareModalData } from '@openreel/frontend/common/hosting/dialogs/share/share-modal-data.interface';
import { ShareComponent } from '@openreel/frontend/common/hosting/dialogs/share/share.component';
import { UpdateVideoDetails, HostableVideo } from '@openreel/frontend/common/hosting/hosting-interfaces';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { InvitationsService } from '../../services/invitations/invitations.service';
import { HostingService, HostingHubService, ManageHubError } from '../services';
import { HostablePermissionTypes, HostableTypes } from '../constants';
import { CreateHostableComponent } from './create-hostable/create-hostable.component';
import { CreateEditHubComponent } from './create-edit-hub/create-edit-hub.component';
import { HostingShareComponent } from './hosting-share/hosting-share.component';
import { AddToHubComponent } from './add-to-hub/add-to-hub.component';
import { HostingConfirmComponent } from './hosting-confirm/hosting-confirm.component';
import { ToastrService } from 'ngx-toastr';
import { HostingVideosDialogComponent } from './hosting-videos-dialog/hosting-videos-dialog.component';

@Injectable({ providedIn: 'root' })
export class HostingDialogService {
  constructor(
    private dialog: MatDialog,
    private hostingHubService: HostingHubService,
    private hostingService: HostingService,
    private invitationService: InvitationsService,
    private toastr: ToastrService
  ) {}

  openCreateHostable(sourceType: HostableTypes, sourceId: number, title = ''): Observable<HostableVideo> {
    return this.dialog
      .open(CreateHostableComponent, {
        width: '405px',
        disableClose: true,
        data: {
          id: sourceId,
          title,
          type: sourceType,
        },
      })
      .afterClosed()
      .pipe(switchMap((res) => (res ? of(res) : EMPTY)));
  }

  openAddVideoToHubs(videoId: number) {
    return this.dialog
      .open(AddToHubComponent, {
        width: '405px',
        height: '410px',
        disableClose: true,
        data: { videoId },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result || (!result.checked && !result.unchecked && !result.created)) {
            return EMPTY;
          }

          return this.hostingHubService.linkUnlinkHubs(videoId, result).pipe(
            tap((response) => {
              if (response.some((r: ManageHubError) => !r.error && result?.checked?.some(i => i.id === r.id))) {
                this.toastr.success('This video will be visible to everyone who already has access to selected hubs.');
              }
              if (response.some((r: ManageHubError) => !r.error && result?.unchecked?.some(i => i.id === r.id))) {
                this.toastr.success('Video removed from hub.');
              }
            }),
            map(linkedHubs => {
              const errors = linkedHubs.filter((r: ManageHubError) => !!r.error);
              const success = linkedHubs.filter((r: ManageHubError) => !r.error);
              const created = result.created || [];

              return { errors, success, created };
            })
          );
        })
      );
  }

  openCreateHub() {
    return this.dialog
      .open(CreateEditHubComponent, {
        width: '405px',
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result || !result.hub || !result.permission) return of(null);
          return this.hostingService.createHub(result.hub, result.permission, result.slug);
        })
      );
  }
  openEditHub(id: number, name: string, slug: string, idHash: string) {
    return this.dialog
      .open(CreateEditHubComponent, {
        width: '405px',
        data: { id, name, slug, idHash }
      }).afterClosed()
  }

  private openShare(
    type: 'video' | 'hub',
    id: number,
    slug: string,
    idHash: string,
    hasPassword: boolean,
    permission: HostablePermissionTypes,
    embedEnabledAt?: Date,
    embedLimit?: number,
    embedExceed?: boolean,
    embedVideoCount?: number
  ) {
    const dialogRef = this.dialog.open(HostingShareComponent, {
      width: '450px',
      data: { type, id, slug, idHash, permission, embedEnabledAt, embedLimit, embedExceed, hasPassword, embedVideoCount },
      autoFocus: false,
    });

    return dialogRef.afterClosed().pipe(
      switchMap((data) => {
        const hasPassword = dialogRef.componentInstance.data.hasPassword;
        return of(hasPassword).pipe(
          switchMap(() =>
            data
              ? this.invitationService.invite2Hosting({
                  email: data.email,
                  message: data.message,
                  type,
                  id,
                })
              : of(false)
          ),
          map((data) => ({
            invitation: data,
            hasPassword: hasPassword,
          }))
        );
      })
    );
  }

  openNewSharingModal(data: ShareModalData): Observable<Partial<UpdateVideoDetails>> {
    const dialogRef = this.dialog.open(ShareComponent, {
      data,
      width: '530px',
      autoFocus: false,
    });

    return dialogRef.afterClosed().pipe(switchMap((data) => (data ? of(data) : of(null))));
  }

  openShareVideo(
    id: number,
    slug: string,
    idHash: string,
    hasPassword: boolean,
    permission: HostablePermissionTypes,
    embedEnabledAt: Date,
    embedLimit: number,
    embedExceed: boolean,
    embedVideoCount: number
  ) {
    return this.openShare(
      'video',
      id,
      slug,
      idHash,
      hasPassword,
      permission,
      embedEnabledAt,
      embedLimit,
      embedExceed,
      embedVideoCount
    );
  }

  openShareHub(id: number, slug: string, idHash: string, hasPassword: boolean, permission: HostablePermissionTypes) {
    return this.openShare('hub', id, slug, idHash, hasPassword, permission);
  }

  private openConfirm(header: string, text: string) {
    return this.dialog
      .open(HostingConfirmComponent, {
        width: '405px',
        data: {
          header,
          text,
        },
      })
      .afterClosed();
  }

  openDeleteHub(id: number, title: string) {
    return this.openConfirm('Confirm Delete', `Do you want to delete '${title}' Hub?`).pipe(
      switchMap((confirm) => (confirm ? this.hostingService.deleteHub(id) : EMPTY))
    );
  }

  openDeleteVideo(id: number, title: string, message?: string) {
    return this.openConfirm('Confirm Delete', `Do you want to delete '${title}' clip? ${message ?? ''}`).pipe(
      switchMap((confirm) => (confirm ? this.hostingService.deleteVideo(id) : EMPTY))
    );
  }

  openCancelVideo(id: number, title: string) {
    return this.openConfirm('Confirm Remove', `Do you want to remove '${title}' from the queue?`).pipe(
      switchMap((confirm) => (confirm ? this.hostingService.cancel(id) : EMPTY))
    );
  }

  openGenericCancel() {
    return this.openConfirm('Confirm Cancel', 'Are you sure you want to cancel?');
  }

  openConfirmResetThumb() {
    return this.openConfirm('Confirm Reset Thumbnail', 'Are you sure you want to reset your thumbnail?');
  }

  openConfirmDelteCta() {
    return this.openConfirm('Confirm Delete Call to Action', 'Are you sure you want to delete your call to action?');
  }

  openConfirmAutoChapters() {
    return this.openConfirm(
      'Are you sure?',
      'This action could overwrite your existing chapters. It will be definitely deleted if you proceed.'
    );
  }

  openConfirmAutoCaptions() {
    return this.openConfirm(
      'Are you sure?',
      'This action could overwrite your existing captions. It will be definitely deleted if you proceed.'
    );
  }

  openVideosDialog(data?: { selectedVideo?: HostableVideo }) {
    return this.dialog
      .open(HostingVideosDialogComponent, {
        width: '600px',
        disableClose: true,
        data: data,
      })
      .afterClosed()
  }
}
