import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { DrawableArea } from "../../resources/drawable.types";
import { Subject, combineLatest, takeUntil } from "rxjs";
import { fromSizeOfHtmlElement } from "../../../rxjs-utils/from-element-resize";
import { DrawableKonvaWrapper } from "../../resources/drawable-konva-wrapper";
import { AsyncPipe } from "@angular/common";
import { DRAWABLE_SHAPES_VIEWER_MANAGER } from "../../resources/drawable.constants";

@Component({
    selector: 'or-drawable-shapes-viewer',
    templateUrl: './drawable-shapes-viewer.component.html',
    styleUrls: ['./drawable-shapes-viewer.component.scss'],
    standalone: true,
    imports: [AsyncPipe]
})
export class DrawableShapesViewerComponent implements OnInit, OnDestroy {
    @ViewChild('container', { static: true })
    container!: ElementRef<HTMLDivElement>;

    manager = inject(DRAWABLE_SHAPES_VIEWER_MANAGER);

    drawableDataToView$ = this.manager.drawableDataToView$

    private _drawableArea: DrawableArea | null = null;

    private unsub$ = new Subject<void>();

    ngOnInit(): void {
        this._drawableArea = DrawableKonvaWrapper.create(this.container.nativeElement);
        this._subscribeDrawableData();
    }

    ngOnDestroy(): void {
        this._drawableArea.destroy();
        this.unsub$.next();
        this.unsub$.complete();
    }

    private _subscribeDrawableData() {
        combineLatest([
            this.manager.drawableDataToView$,
            fromSizeOfHtmlElement(this.container.nativeElement)
        ])
        .pipe(takeUntil(this.unsub$))
        .subscribe(([data, size]) => {
            this._drawableArea.clearShapes();
            this._drawableArea.setSize(size.width, size.height);
            if(!data) return;
            this._drawableArea.drawFromJSON(data);
        })
    }
}
