import { Pipe, PipeTransform } from '@angular/core';
import { CommentAccessibility } from '../../constants';

@Pipe({
  name: 'commentAccessibility',
  standalone: true,
})
export class CommentAccessibilityPipe implements PipeTransform {
  transform(value: CommentAccessibility): string {
    switch(value) {
        case 'internal': return "Internal comment";
        case 'public': return "Public comment";
        default: return '';
    }
  }
}