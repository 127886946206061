import { Inject, Injectable, Optional } from '@angular/core';
import {
  AclPermission,
  FeatureFlaggingService,
  HostableUserPermission,
  OPENREEL_ACL_FEATURE_FLAG,
} from '@openreel/frontend/common';
import { OWNERSHIP_SHARING } from '@openreel/common';
import { toAclFunction } from './to-acl-function';

@Injectable({ providedIn: 'root' })
export class AclService {
  constructor(
    private readonly featureFlaggingService: FeatureFlaggingService,
    @Optional() @Inject(OPENREEL_ACL_FEATURE_FLAG) private readonly aclFeatureFlag: string
  ) {}

  isAclFeatureFlagEnabled(): boolean {
    /**
     * We can override default OWNERSHIP_SHARING feature flag by providing OPENREEL_ACL_FEATURE_FLAG token
     * at the feature module/component level
     *
     * Example:
     * {
     *   provide: OPENREEL_ACL_FEATURE_FLAG,
     *   useValue: OWNERSHIP_SHARING,
     * },
     */

    return this.aclFeatureFlag
      ? this.featureFlaggingService.getFlag(this.aclFeatureFlag)
      : this.featureFlaggingService.getFlag(OWNERSHIP_SHARING);
  }

  isUserHasPermissions(
    permissions: HostableUserPermission[],
    requestedPermission: AclPermission | AclPermission[]
  ): boolean {
    const aclFunction = toAclFunction(permissions, this.isAclFeatureFlagEnabled());

    return aclFunction(requestedPermission);
  }
}
