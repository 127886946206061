<openreel-permissioned-list-item
  [entityDetailsTemplate]="detailsTemplate"
  [entity]="group"
  permissionedEntityType='group'
  hideOwnershipTransfer
></openreel-permissioned-list-item>

<ng-template #detailsTemplate let-groupDetails>
  <div class="group" fxLayoutAlign="flex-start center" fxLayoutGap="15px">
    <div class="group-avatar">
      <mat-icon>group_add</mat-icon>
    </div>

    <div class="group-details" fxLayout="column">
      <span class="group-name paragraph-p100">{{ groupDetails.name }}</span>
      <span class="group-description paragraph-p100">{{ groupDetails.teamMembers }} users</span>
    </div>
  </div>
</ng-template>
