import { NgModule } from '@angular/core';
import { OpenreelPresentationsComponent } from './openreel-presentations.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [OpenreelPresentationsComponent],
  exports: [OpenreelPresentationsComponent],
})
export class OpenreelPresentationsModule {}
