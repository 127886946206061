import { Directive, HostListener, Input, inject } from "@angular/core";
import { Router } from "@angular/router";
import { HostingAnalyticsService } from "../hosting-analytics.service";
import { HostingPlayerVideoEvent } from "../../hosting-interfaces";
import { HostingAnalyticsEvents, VideoPlayerInteraction } from "../hosting-analytics-events";
import { DOCUMENT } from '@angular/common';

interface PlayerEventCommonData {
    videoId?: number;
    hubId?: number;
    videoIdHash?: string;
    hubIdHash?: string;
    videoTitle?: string;
    accountIdHash?: string;
}

@Directive({
    selector: 'openreel-hosting-player[playerEventCapture]',
    standalone: true,
})
export class HostingPlayerEventCaptureDirective {
    @Input() 
    get playerEventCapture() { return this._playerEventCapture }
    set playerEventCapture(v: PlayerEventCommonData) {
        this._playerEventCapture = v;
        this._logShowVideoEvent();
    }
    private _playerEventCapture!: PlayerEventCommonData

    private analyticsService = inject(HostingAnalyticsService);
    private router = inject(Router)
    private document = inject(DOCUMENT);

    @HostListener('document:visibilitychange')
    onVisibilityChange() {
      if (this.document.visibilityState === 'visible') {
        this._logShowVideoEvent();
      }
    }

    @HostListener('videoEvent', ['$event'])
    onPlayerEvent(e: HostingPlayerVideoEvent): void {
        if (this.analyticsService.isInternalAnalytics) {
            if (!this.playerEventCapture.videoId) return;
            const interaction = this._getInteraction(e);
            if (!interaction) return;
            this.analyticsService.logEvent(HostingAnalyticsEvents.videoDetailsPlayerInteraction({
                id: this.playerEventCapture.videoId,
                hub_id: this.playerEventCapture.hubId,
                interaction: interaction,
                url: this.router.url,
            }));
        }
        else if (this.analyticsService.isPublicAnalytics) {
            if (!this.playerEventCapture.videoIdHash) return;
            const createPlayerEvent = this._getPlayerEventCreatorForPublicAnalytics(e);
            if(!createPlayerEvent) return;
            this.analyticsService.logEvent(createPlayerEvent({
                id: this.playerEventCapture.videoIdHash,
                title: this.playerEventCapture.videoTitle,
                account_id: this.playerEventCapture.accountIdHash,
                action: e.action,
                url: e.url,
                position: e.position,
                ...(e.cta_button_url && { cta_button_url: e.cta_button_url }),
            }))
        }
    }

    private _getInteraction(e: HostingPlayerVideoEvent): VideoPlayerInteraction | null {
        switch (e.action) {
            case 'play': return 'play';
            case 'pause': return 'pause';
            case 'seeked': return 'seek';
            case 'toggle_fullscreen': return 'full_screen';
            case 'toggle_picture_in_picture': return 'picture_in_picture';
            case 'toggle_captions': return 'captions';
            case 'toggle_chapters': return 'chapters';
            default: return null;
        }
    }

    private _logShowVideoEvent() {
        if(!this.analyticsService.isPublicAnalytics) return;
        this.analyticsService.logEvent(HostingAnalyticsEvents.switchToVideo({
            id: this.playerEventCapture.videoIdHash,
            account_id: this.playerEventCapture.accountIdHash,
            title: this.playerEventCapture.videoTitle
        }));
      }

    private _getPlayerEventCreatorForPublicAnalytics(e: HostingPlayerVideoEvent) {
        switch (e.action) {
            case 'play': return HostingAnalyticsEvents.videoPlay;
            case 'pause': return HostingAnalyticsEvents.videoPause;
            case 'seeked': return HostingAnalyticsEvents.videoSeek;
            case 'ended': return HostingAnalyticsEvents.videoEnded;
            case 'video_completed': return HostingAnalyticsEvents.videoCompleted;
            case 'cta_button_clicked': return HostingAnalyticsEvents.ctaButtonClicked;
            case 'toggle_fullscreen': return HostingAnalyticsEvents.toggleFullscreen;
            case 'toggle_picture_in_picture': return HostingAnalyticsEvents.togglePictureInPicture;
            case 'toggle_captions': return HostingAnalyticsEvents.toggleCaptions;
            case 'toggle_chapters': return HostingAnalyticsEvents.toggleChapters;
            default: return null;
        }
    }
}
