import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { HostableVideo, PageOptions, SortOption } from '../../hosting-interfaces';
import { HostingService } from '../../services';
import { Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';


interface HostingVideosDialogState {
  searchText: string;
  pageOptions: PageOptions;
  sortOptions: SortOption;
  videos: HostableVideo[];
  total: number;
  isLoading: boolean;
}

@Injectable()
export class HostingVideosDialogStore extends ComponentStore<HostingVideosDialogState> {
  constructor(
    private readonly hostingService: HostingService, 
    private readonly toastr: ToastrService
  ) {
    super({
      searchText: '',
      pageOptions: { pageIndex: 0, pageSize: 12 },
      sortOptions: { field: 'updated_at', direction: 'DESC' },
      videos: [],
      total: 0,
      isLoading: false,
    });
  }

  public readonly pageOptions$ = this.select((state) => state.pageOptions);
  public readonly sortOptions$ = this.select((state) => state.sortOptions);
  public readonly searchText$ = this.select((state) => state.searchText);
  public readonly total$ = this.select(state => state.total);
  public readonly videos$ = this.select(state => state.videos);
  public readonly isLoading$ =this.select(state => state.isLoading);

  public readonly vm$ = this.select({
    pageOptions: this.pageOptions$,
    total: this.total$,
    videos: this.videos$,
    isLoading: this.isLoading$
  })

  public readonly setIsLoading = this.updater((state, isLoading: boolean) => ({ ...state, isLoading }));
  public readonly setVideosData = this.updater((state, [videos, total]: [HostableVideo[], number]) => ({ ...state, videos, total }));
  public readonly setPageOptions = this.updater((state, options: PageOptions) => ({ ...state, pageOptions: options }));
  public readonly setSearchText = this.updater((state, searchText: string) => ({ ...state, searchText, pageOptions: { ...state.pageOptions, pageIndex: 0 } }))

  public readonly fetchVideos = this.effect(($: Observable<void>) =>
    $.pipe(
      withLatestFrom(this.pageOptions$, this.sortOptions$, this.searchText$),
      tap((_) => this.setIsLoading(true)),
      switchMap(([_, pageOptions, sortOptions, searchText]) =>
        this.hostingService.getAllHostedVideos(pageOptions.pageIndex, pageOptions.pageSize, sortOptions, { title: searchText }).pipe(
          tapResponse(
            (response) => {
              this.setIsLoading(false);
              this.setVideosData([response.rows, response.count]);
            },
            (error: Error) => {
              this.setIsLoading(false);
              this.toastr.error(error.message, 'Error!');
            }
          )
        )
      )
    )
  );
}
