import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { InternalCommentsCountDirective } from './internal-comments-count.directive';
import { InternalCommentsComponent } from './internal-comments.component';
import { CommentListComponent } from '../hosting/components/comment-list/comment-list.component';
import { FeatureFlagDirective } from '../directives/feature-flag.directive';
import { AvatarModule } from 'ngx-avatar';
import { FormatDurationPipe } from '../pipes/format-duration.pipe';
import { RelativeDatePipe } from '../pipes/relative-date.pipe';
import { ParseDurationPipe } from '../pipes/parse-duration.pipe';
import { DurationMaskDirective } from '../directives/duration-mask.directive';
import { DrawableToolbarComponent } from '../drawable-container/components/drawable-toolbar/drawable-toolbar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [InternalCommentsComponent, InternalCommentsCountDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FormatDurationPipe,
    RelativeDatePipe,
    ParseDurationPipe,
    CommentListComponent,
    FeatureFlagDirective,
    DurationMaskDirective,
    AvatarModule,
    DrawableToolbarComponent,
  ],
  exports: [InternalCommentsComponent, InternalCommentsCountDirective],
})
export class InternalCommentsModule {}
