import { UgcSessionVideoInstructionSource } from '@openreel/common';
import { Recording } from '.';

export enum UgcEmailNotification {
  Instant = 'instant',
  Hourly = 'hourly',
  FourHourInterval = '4_hour',
  Daily = 'daily',
  Weekly = 'weekly',
  Never = 'never',
}

export interface UgcEmailNotificationOption {
  label: string;
  value: UgcEmailNotification;
}

export const ugcEmailNotificationOptions: UgcEmailNotificationOption[] = [
  {
    label: 'Instant',
    value: UgcEmailNotification.Instant,
  },
  {
    label: 'Hourly',
    value: UgcEmailNotification.Hourly,
  },
  {
    label: 'Every 4 Hours',
    value: UgcEmailNotification.FourHourInterval,
  },
  {
    label: 'Daily',
    value: UgcEmailNotification.Daily,
  },
  {
    label: 'Weekly',
    value: UgcEmailNotification.Weekly,
  },
  {
    label: 'Never',
    value: UgcEmailNotification.Never,
  },
];

export enum UgcSupportType {
  Question = 'question',
  Teleprompter = 'teleprompter',
  VideoInstruction = 'video_instruction',
}

export interface UgcSupportTypeOption {
  label: string;
  value: UgcSupportType;
  selected: boolean;
  disableFor?: UgcSupportType;
}

export const UgcSupportTypeOptions: UgcSupportTypeOption[] = [
  {
    label: 'Teleprompter',
    selected: false,
    value: UgcSupportType.Teleprompter,
    disableFor: UgcSupportType.Question,
  },
  {
    label: 'Questions',
    selected: false,
    value: UgcSupportType.Question,
    disableFor: UgcSupportType.Teleprompter,
  },
  {
    label: 'Video Instruction',
    selected: false,
    value: UgcSupportType.VideoInstruction,
  },
];
export interface UgcSupportQuestion {
  question: string;
  suggestion?: string;
  maxDuration: number;
  durationUnit: DurationUnit;
  createdAt?: string;
  updatedAt?: string;
  id?: number;
  sessionId?: number;
  hasVideos?: boolean;
}

export interface UgcQuestionDurationOption {
  label: string;
  value: string;
}

export enum DurationUnit {
  Secs = 'secs',
  Mins = 'mins',
  Hours = 'hours',
}

export const ugcQuestionDurationOptions: UgcQuestionDurationOption[] = [
  {
    label: 'Sec',
    value: DurationUnit.Secs,
  },
  {
    label: 'Mins',
    value: DurationUnit.Mins,
  },
  {
    label: 'Hours',
    value: DurationUnit.Hours,
  },
  {
    label: 'No Time Limit',
    value: '',
  },
];

export interface InvitedSubject {
  email: string;
  invalid: boolean;
  createdAt?: string;
  updatedAt?: string;
  id?: number;
  sessionId?: number;
}

export interface CreateUpdateUgcSessionPayload {
  projectId: number;
  sessionName: string;
  description?: string;
  startAt?: string;
  expireAt?: string;
  invitedSubjects?: InvitedSubject[];
  frameRate: string;
  resolution: string;
  pageCountDownEnabled: boolean;
  emailNotificationInterval: UgcEmailNotification;
  supportType?: UgcSupportType[];
  questions?: UgcSupportQuestion[];
  teleprompterId?: number;
  instructionVideoId?: number;
  instructionVideoSource?: UgcSessionVideoInstructionSource;
  instructionVideoToken?: string;
  sessionState?: 'published' | 'draft';
}

export interface UgcSessionLink {
  startAt: string;
  expireAt: string;
  link: string;
}

export interface CreateUpdateUgcSessionData extends CreateUpdateUgcSessionPayload {
  links: UgcSessionLink[];
  createdAt: string;
  updatedAt: string;
  sessionId: number;
}

export interface UgcSessionResponse {
  data: CreateUpdateUgcSessionData;
  message: string;
}

export interface UgcRecordingGroup {
  name: string;
  recordings: Recording[];
}
