/* eslint-disable  no-async-promise-executor */
import { mediaValidatorMP4 } from './validate-mp4';
import { mediaValidatorWebM } from './validate-webm';

export class MediaVideo {
    codec:string;
    width:number;
    height:number;
}

export class MediaAudio {
    codec:string;
}

export class MediaMeta {
    video?: MediaVideo;
    audio?: MediaAudio;
}

export class Track {
    codec:string;
    type:string;
    width?:number;
    height?:number;
}

export class MediaValidator {

  static isVideoExtensionSupported(file: File): boolean {
    const supportedVideoExtensions = ['video/mp4', 'video/quicktime', 'video/webm'];

    return supportedVideoExtensions.includes(file.type);
  }

    //
    // parse media header
    //
    static getMediaDataAsync(file: File): Promise<MediaMeta> {
        let data:MediaMeta;
        let videoParsedMeta:Track[];
        const offset = 0;
        const position = 0;
        const maxPosition = 4 * 1024; // 4K for start

        return new Promise(async (resolve:(data: MediaMeta) => void, reject) => {

            try{
                if(file.type === 'video/mp4' || file.type === 'video/quicktime'){
                    const  mediaMP4 = new mediaValidatorMP4();
                    await mediaMP4.parse(file, offset, position, maxPosition);
                    videoParsedMeta = mediaMP4.getData();
                }else if(file.type === 'video/webm'){
                    const mediaWebM = new mediaValidatorWebM();
                    await mediaWebM.parse(file, offset, position, maxPosition);
                    videoParsedMeta = mediaWebM.getData();
                }
            }
            catch(e){
                resolve(null);
            }

            if(videoParsedMeta.length <= 0){
                data = null;
            }else{
                data = new MediaMeta();
                // can be multiple tracks - we are interesting in video only.
                videoParsedMeta.forEach(track => {
                    if(track.type === 'video'){
                        if(!data.video){
                            data.video = new MediaVideo();
                        }
                        data.video.codec = track.codec;
                        data.video.width = track.width;
                        data.video.height = track.height;
                    }else if(track.type === 'audio'){
                        if(!data.audio){
                            data.audio = new MediaAudio();
                        }
                        data.audio.codec = track.codec;
                    }
                });
            }
            resolve(data);
        });
    }
}
