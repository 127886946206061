import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsoRoutingModule } from './sso-routing.module';
import { OpenreelCommonModule } from '@openreel/frontend/common';
import { SsoAuthComponent } from './sso-auth/sso-auth.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { SsoSetupComponent } from './sso-setup/sso-setup.component';
import { UiModule } from '@openreel/ui';
import { ThemeModule } from '@openreel/theme';

@NgModule({
  declarations: [SsoAuthComponent, SsoLoginComponent, SsoSetupComponent],
  imports: [OpenreelCommonModule, SsoRoutingModule, ThemeModule, UiModule, FormsModule, ReactiveFormsModule],
  exports: [SsoAuthComponent, SsoLoginComponent, SsoSetupComponent],
})
export class SsoModule {}
