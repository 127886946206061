import { Component, inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { DirectorUserDetails, IPopupComponent } from '../../interfaces';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { SalesforceChatService } from '@openreel/ui/salesforce-chat/salesforce-chat.service';
import {
  AuthService,
  BillingService,
  EnterpriseFeatures,
  MaxSeatsAllowed,
  Product,
  SeatsInfo,
  SeatsService,
} from '../../services';
import { filter, first, map, take, tap } from 'rxjs/operators';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ROUTE_DIRECTOR_LOGIN } from '../../route-utils';
import { ProfileMenuComponent } from '../profile-menu';
import { DomSanitizer } from '@angular/platform-browser';
const icons = ['premium-user', 'feature-check', 'chat-to-us'];

@Component({
  selector: 'openreel-premium-paywall',
  templateUrl: './premium-paywall.component.html',
  styleUrls: ['./premium-paywall.component.scss'],
  standalone: true,
  imports: [CommonModule, MatLegacyDialogModule, MatIconModule, MatLegacyButtonModule, ProfileMenuComponent],
})
export class PremiumPaywallComponent implements IPopupComponent {
  isPopup: true;
  isLightThemed = true;
  dayRemaining = 60;
  monthly$: Observable<Product>;
  annual$: Observable<Product>;
  selectedProduct$: Observable<Product>;
  selected$ = new BehaviorSubject<'month' | 'year'>('month');
  enterpriseFeatures = EnterpriseFeatures;
  isPremiumProduct = true;
  seatInfo: SeatsInfo;
  isRootAdmin = true;
  userDetails: DirectorUserDetails;
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);

  constructor(
    private dialog: MatDialogRef<PremiumPaywallComponent>,
    private salesforceChatService: SalesforceChatService,
    private authService: AuthService,
    private billingService: BillingService,
    private seatsService: SeatsService,
    private toastr: ToastrService,
    private readonly router: Router,
    public dialogRef: MatDialogRef<PremiumPaywallComponent>
  ) {
    this.isPopup = true;
    this.isLightThemed = true;
    for (const icon of icons) {
      const path = `assets/common/icons/${icon}.svg`;
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    }
  }
  ngOnInit(): void {
    this.isRootAdmin = this.authService.isRootAdmin();
    if (this.isRootAdmin) {
      this.getSeatsInfo();
      this.getProductsInfo();
    }
    this.authService.userDetails$
      .pipe(
        filter((data) => !!data),
        take(1)
      )
      .subscribe((data) => {
        this.userDetails = data;
        if (data.site_users.captureExpireAt) {
          const differenceInDays = Math.ceil(moment().diff(moment(data.site_users.captureExpireAt), 'days', false));
          this.dayRemaining = this.dayRemaining - differenceInDays;
          if (this.dayRemaining < 0) this.dayRemaining = 0;
        }
      });
  }
  getSeatsInfo() {
    this.seatsService.getSeatsInfo().subscribe((data) => {
      this.seatInfo = data;
    });
  }
  getProductsInfo() {
    const products = this.billingService.getAllProducts();
    products
      .pipe(
        map((prod) => prod.find((p) => p.default)),
        first(),
        filter((product) => Boolean(product)),
        tap((product) => this.selected$.next(product.recurringInterval as 'month' | 'year'))
      )
      .subscribe();

    this.monthly$ = products.pipe(map((prod) => prod.find((p) => p.recurringInterval === 'month')));
    this.annual$ = products.pipe(map((prod) => prod.find((p) => p.recurringInterval === 'year')));
    this.selectedProduct$ = combineLatest([this.selected$.asObservable(), products]).pipe(
      map(([selected, prod]) => prod.find((p) => p.recurringInterval === selected))
    );
  }
  openChatWindow() {
    this.salesforceChatService.openChat();
  }
  selectPlan() {
    if (this.isPremiumProduct) {
      this.selectedProduct$.pipe(take(1)).subscribe((product) => {
        this.activatePremium(product.id);
      });
    } else {
      window.open('https://form.typeform.com/to/fLD0BfPl', '_blank');
    }
  }
  activatePremium(id: string) {
    const maxSeats =
      this.seatInfo.captureSeatsExist > this.seatInfo.creatorSeatsExist
        ? this.seatInfo.captureSeatsExist
        : this.seatInfo.creatorSeatsExist;
    this.billingService.getCheckoutLink(id, this.seatInfo.captureSeats, maxSeats, MaxSeatsAllowed).subscribe(
      (result) => {
        document.location.href = result.url;
      },
      () => {
        this.toastr.error('Unable to subscribe product', 'Error!');
      }
    );
  }
  onLogoutButtonClicked() {
    this.authService.logout();
    this.dialogRef.close();
    this.router.navigateByUrl(`/director/${ROUTE_DIRECTOR_LOGIN}`);
  }
}
