import { getLayerFromId } from '../helpers';
import { Layer } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface LayersToggleEvent {
  layerIds: string[];
  enabled: boolean;
}

export class LayersToggleCommand extends WorkflowBaseBuilder<LayersToggleEvent, Layer[]> {
  run({ layerIds, enabled }: LayersToggleEvent) {
    for (const layerId of layerIds) {
      this.toggleLayer(layerId, enabled);
    }

    const layers = layerIds
      .map((layerId) => getLayerFromId(layerId, this.source))
      .filter((el) => el)
      .map(({ layer }) => layer);

    return this.ok(layers);
  }
}
