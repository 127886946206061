<div class="entity-details" fxLayoutAlign="space-between center" [formGroup]="form">
  <ng-container *ngTemplateOutlet="entityDetailsTemplate; context: { $implicit: entity }"></ng-container>

  <div class="role">
    <button
      mat-button
      class="role-menu-trigger"
      [matMenuTriggerFor]="menu"
      [disabled]="isUserOwner || (!entity.role.systemRole && !(entity.role.id | canManageRole: (canManageRolesIds$ | async)))"
    >
      <span class="role-menu-trigger-label paragraph-p100">{{ form.value.roleName | titlecase }}</span>

      <mat-icon>expand_more</mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="role-menu">
      <ng-container *ngFor="let role of objectRoles$ | async">
        <button
          mat-menu-item
          *ngIf="role.canManage"
          class="role-menu-option"
          (click)="setRole(role)"
        >
          <mat-icon class="role-check-icon" [class.visible]="role.id === form.value.roleId">check</mat-icon>
          <span>{{ role.name | titlecase }}</span>
        </button>
      </ng-container>

      <mat-divider></mat-divider>

      <button mat-menu-item (click)="removePermissionedObject()">
        <span>Remove access</span>
      </button>

      <button
        mat-menu-item
        (click)="transferOwnership()"
        *ngIf="!hideOwnershipTransfer && (isOwnerTransferShipAvailable$ | async)"
      >
        <span>Transfer Ownership</span>
      </button>
    </mat-menu>
  </div>
</div>
