/// <reference types="dom-mediacapture-record" />

import { Injectable } from '@angular/core';
import { deleteDB, IDBPDatabase, openDB } from 'idb';
// import { Subject } from 'rxjs';
import { ILocalRecorderService } from './local-recorder-base.service';
import { getBestCodec } from './recorder.helpers';
import { ChunkRecordingSchema, RecordingState } from './recorder.interfaces';
import {
  BITS_PER_RESOLUTION_PER_FPS,
  DB_NAME_PREFIX,
  DB_VERSION,
  MINIMUM_BITRATE,
  TIMESLICE,
} from './recorder.constants';

export declare interface MediaRecorderDataAvailableEvent extends Event {
  data: Blob;
}

/**
 * This is the active local recorder now
 */
@Injectable()
export class LocalRecorderService extends ILocalRecorderService {
  private mediaRecorder = new Map<number, MediaRecorder>(); //multiple recording (Webcam & screen)
  private db = new Map<number, IDBPDatabase<ChunkRecordingSchema>>();

  // stop$ = new Subject<boolean>(); // why is this exposed or defined here

  private preferedCodec = getBestCodec();
  constructor() {
    super();
    console.log('Using indexed db for recording');
  }

  public get isRecording() {
    let isRecording = false;
    for (const mediaRecorder of this.mediaRecorder.values()) {
      if (mediaRecorder.state === 'recording') isRecording = true;
    }
    return isRecording;
  }

  // eslint-disable-next-line max-lines-per-function
  public doStartRecording() {
    throw new Error('not implemented');
  }

  public async doStartRecordingStream(videoId: number, stream: MediaStream, filename = '', videoPersonName: string) {
    const settings = stream.getVideoTracks()[0].getSettings();
    // if video is disabled settings resolution and framerate are unavailable
    settings.height = settings.height ?? 720;
    settings.frameRate = settings.frameRate ?? 24;
    const videoBitsPerSecond = Math.max(
      BITS_PER_RESOLUTION_PER_FPS * settings.height * settings.frameRate,
      MINIMUM_BITRATE
    );
    console.log(
      'Effective recording resolution/fps/videoBitsPerSecond: ' +
        settings.height +
        '/' +
        settings.frameRate +
        '/' +
        videoBitsPerSecond
    );

    this.newVideoInfo = {
      bit_rate: `${videoBitsPerSecond}`,
      device_name: `${settings.deviceId}`,
      fps: `${Number(settings.frameRate.toFixed())}`, // to resolve safari framerate numbers like 30.000030517578125
      resolution: `${settings.height}`,
      identity: `web_${settings.deviceId}`,
      video_name: filename,
      admin_ovra_id: 0,
      video_type: 1,
      person_name: videoPersonName,
    };

    const options: MediaRecorderOptions = {
      videoBitsPerSecond,
      audioBitsPerSecond: 128 * 1000, // The maximum is 128,000 bps
      mimeType: this.preferedCodec,
    };
    const mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.set(videoId, mediaRecorder);

    mediaRecorder.onstop = () => {
      this.handleStop(videoId);

      /**
       * Total mess
       * Need timeout to ensure this happens LAST, should clean this up
       */
      setTimeout(() => {
        this.stopSource.next({ value: true, videoId: videoId });
      }, 1000);
      // this.stopSource.next(true);
    };
    mediaRecorder.onerror = (err) => this.handleError(err as MediaRecorderErrorEvent, videoId);
    mediaRecorder.ondataavailable = (evt) => this.handleDataAvailable(evt, filename, videoId);
    mediaRecorder.onstart = () => this.handleStart(videoId);

    this.closeDB(videoId);

    const db = await this.initDbForRecordingChunk(filename);
    this.db.set(videoId, db);
    mediaRecorder.start(TIMESLICE);
  }

  private handleStart(videoId) {
    /**
     * iOS hack
     * By default iOS enables every track before starting to record.
     * If we want to record disabled screen or muted mic first we have to toggle the tracks
     * */
    this.mediaRecorder
      .get(videoId)
      .stream.getTracks()
      .forEach((t) => {
        t.enabled = !t.enabled;
        t.enabled = !t.enabled;
      });

    this.recordingState$.next(RecordingState.RECORDING);
    this.chunkReset();
  }

  async testBrowserSupportForIDB() {
    const db = await this.initDbForRecordingChunk('test-browser');
    const codecs = getBestCodec()?.split(';');
    const blobType = codecs?.length ? codecs[0] : null;
    const bigBlob = new Blob(['test data'], {
      type: blobType,
    });
    try {
      await db.put('chunks' as unknown as never, bigBlob);
      return true;
    } catch {
      return false;
    } finally {
      db.close();
      await deleteDB(DB_NAME_PREFIX + 'test-browser');
    }
  }

  private async initDbForRecordingChunk(filename = ''): Promise<IDBPDatabase<ChunkRecordingSchema>> {
    return await openDB(DB_NAME_PREFIX + filename, DB_VERSION, {
      upgrade: (db) => {
        db.createObjectStore('chunks' as unknown as never, {
          autoIncrement: true,
        });
        console.log('Database upgraded for ' + filename);
      },
    });
  }

  private closeDB(videoId: number) {
    if (this.db.get(videoId)) {
      const db = this.db.get(videoId);
      db.close();
      this.db.delete(videoId);
    }
  }

  async handleDataAvailable(evt: MediaRecorderDataAvailableEvent, filename = '', videoId: number) {
    this.chunkAvailableSource.next({
      localFileName: filename,
      data: evt.data,
      videoId: videoId,
    });
    const isRecorderInactive = this.mediaRecorder.get(videoId)?.state === 'inactive';
    try {
      let db = this.db.get(videoId);
      if (!db) {
        db = await this.initDbForRecordingChunk(filename);
        this.db.set(videoId, db);
      }
      await db.put('chunks' as unknown as never, evt.data);
    } catch (e) {
      console.error(e);
      if (
        ['QuotaExceededError', 'DataError'].includes(e.name) &&
        this.mediaRecorder.get(videoId)?.state !== 'inactive'
      ) {
        this.doStopRecording(videoId);
        this.stopRecordingOnError.next('run out of storage');
      }
    }
    
    if (isRecorderInactive) {
      this.partsStopSource.next({ value: true, videoId: videoId });
    }
  }

  handleStop(videoId: number) {
    this.recordingState$.next(RecordingState.IDLE);
    this.closeDB(videoId);
  }

  private handleError(err: MediaRecorderErrorEvent, videoId: number) {
    this.handleStop(videoId);
    console.error('Error during recording: ');
    console.log(err);
    throw new Error(JSON.stringify(err));
  }

  async doStopRecording(videoId = -1) {
    if (videoId === -1) {
      for (const mediaRecorder of this.mediaRecorder.values()) {
        mediaRecorder.stop();
      }
    } else {
      this.mediaRecorder.get(videoId)?.stop();
    }
  }

  async doPauseRecording(videoId: number) {
    this.mediaRecorder.get(videoId)?.pause();
  }

  async doResumeRecording(videoId: number) {
    this.mediaRecorder.get(videoId)?.resume();
  }
}
