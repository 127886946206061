import * as Interfaces from './global-assets.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';

@Injectable({ providedIn: 'root' })
export class GlobalAssetsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}global-assets`,
    byId: (globalAssetId: number) => `${commonenv.nextGenApiUrl}global-assets/${globalAssetId}`,
    ready: (globalAssetId: number) => `${commonenv.nextGenApiUrl}global-assets/${globalAssetId}/ready`,
    getAll: (dto: Interfaces.GlobalAssetsGetRequestDto) =>
      `${this.urls.base}?page=${dto.page}&perPage=${dto.perPage}&type=${dto.type}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll(dto: Interfaces.GlobalAssetsGetRequestDto) {
    let url = this.urls.getAll(dto);
    if (dto.tagIds?.length > 0) {
      const tagIdsUrl = dto.tagIds.reduce(
        (prev, curr, i, arr) => prev + `tagIds[${i}]=${curr}${i < arr.length - 1 ? '&' : ''}`,
        '&'
      );
      url += tagIdsUrl;
    }
    return this.httpClient.get<Interfaces.GlobalAssetsEntity>(url);
  }

  create(dto: Interfaces.GlobalAssetCreateDto) {
    return this.httpClient.post<Interfaces.GlobalAssetEntity>(this.urls.base, dto, {
      headers: new HttpHeaders({ [CaseConverterInterceptorSkipHeader]: '' }),
    });
  }

  update(globalAssetId: number, dto: Interfaces.GlobalAssetUpdateDto) {
    return this.httpClient.put<Interfaces.GlobalAssetEntity>(this.urls.byId(globalAssetId), dto, {
      headers: new HttpHeaders({ [CaseConverterInterceptorSkipHeader]: '' }),
    });
  }

  markAsReady(globalAssetId: number) {
    return this.httpClient.patch(this.urls.ready(globalAssetId), {});
  }

  delete(globalAssetId: number) {
    return this.httpClient.delete(this.urls.byId(globalAssetId));
  }
}
