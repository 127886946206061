export enum SessionType {
  Pro = 'pro',
  UGC = 'ugc',
  SelfRecord = 'self_record',
  Lite = 'lite',
}
export enum SessionState {
  Published = 'published',
  Draft = 'draft',
}

export enum SessionTitles {
  Pro = 'Capture Session',
  UGC = 'Video Request Session',
  SelfRecord = 'Self Record Session',
  Lite = 'Capture Lite Session',
}

export const ResolutionMapping = {
  high: { width: 3840, height: 2160, label: 'Up to 4K' },
  medium: { width: 1920, height: 1080, label: 'Up to 1080p' },
  low: { width: 1280, height: 720, label: 'Up to 720p' },
};

export const ResolutionMappingOptions = [
  ResolutionMapping.low,
  ResolutionMapping.medium,
  ResolutionMapping.high,
];

export const FrameRateMapping: Record<string, number> = {
  high: 60,
  medium: 30,
  low: 24,
};

export const FrameRateMappingOptions = [
  FrameRateMapping.low,
  FrameRateMapping.medium,
  FrameRateMapping.high,
];

export enum UserMappingUserTypes {
  ExternalUser = 1,
  InternalUsers = 0,
}

export enum SessionChatIsReadStatus {
  NotRead = '0',
  Read = '1',
}

export const USERS_PER_SESSION_MAX = 9;

export enum DeviceLogDeviceType {
  AP = 'AP',
  IP = 'IP',
  WEB = 'WEB',
  CHROME = 'CHROME',
}

export enum UgcSessionQuality {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum UgcDirectorEmailNotification {
  Instant = 'instant',
  Hourly = 'hourly',
  FourHourInterval = '4_hour',
  Daily = 'daily',
  Weekly = 'weekly',
  Never = 'never',
}

export enum UgcSupportType {
  Question = 'question',
  Teleprompter = 'teleprompter',
  VideoInstruction = 'video_instruction',
}

export enum UgcSessionVideoInstructionSource {
  RecordedClip = 'recorded_clip',
  HostedVideo = 'hosted_video',
}

export const UgcSupportTypes = [UgcSupportType.Question, UgcSupportType.Teleprompter, UgcSupportType.VideoInstruction];
export const UgcResolution = ['720', '1080', '2160'];
export const UgcFps = ['24', '25', '30', '60'];

export enum DurationUnit {
  Secs = 'secs',
  Mins = 'mins',
  Hours = 'hours',
}
export interface LiteTeleprompterState {
  scriptId: number;
  text: string;
  speed: number;
  playStartTime: number;
  playStartPercent: number;
  pausedPercent: number;
}
