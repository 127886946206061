<div class="social-share">
  <div class="headings">
    <h2 class="headline-h400 title">Social share</h2>
    <p class="paragraph-p100 subtitle">
      Share this {{ entityType === PermissionableEntity.Hostable ? 'video' : 'hub' }} with your customers
    </p>
  </div>

  <div class="social-share-buttons">
    <a [href]="shareUrl | socialShareUrl: 'linkedin'" target="_blank" class="social-share-button" (click)="share.emit('linkedin')">
      <img src="assets/common/icons/linkedin.png" alt="linkedin" class="social-share-icon">
    </a>

    <a [href]="shareUrl | socialShareUrl: 'x'" target="_blank" class="social-share-button" (click)="share.emit('x')">
      <img src="assets/common/icons/x-logo-black.png" alt="X" class="x-icon social-share-icon">
    </a>

    <a *ngIf="enableYoutube" class="social-share-button" (click)="share.emit('youtube')">
      <img src="assets/common/images/youtube-logo.svg" alt="Youtube" class="social-share-icon">
    </a>
  </div>
</div>
