import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HostableVideo } from '../../hosting-interfaces';
import { MatIconModule } from '@angular/material/icon';
import { PlayRecordingPopupComponent } from '@openreel/ui/play-recording-popup/play-recording-popup.component';
import { SecondsToMinSecPipe } from '../../../pipes/secondsToMinSec.pipe';
import { ImgFallbackDirective } from '../../../directives/img-fallback.directive';
import { useHostingVideoViewerDialogManager } from '../../dialogs/hosting-video-viewer-dialog/hosting-video-viewer-dialog.helpers';
import { HostingVideoAnalyticsType } from '@openreel/frontend/common/hosting/components/hosting-video-viewer/hosting-video-viewer.types';

@Component({
    selector: 'openreel-hosting-video-card',
    templateUrl: './hosting-video-card.component.html',
    styleUrls: ['./hosting-video-card.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconModule, PlayRecordingPopupComponent, DatePipe, SecondsToMinSecPipe, ImgFallbackDirective]
})
export class HostingVideoCardComponent {
    @Input() video: HostableVideo;
    @Input() videoToken?: string;
    @Input() enableSelection: boolean = false;
    @Input() isSelected: boolean = false;
    @Input() enablePreview: boolean = true;
    @Input() videoAnalytics: HostingVideoAnalyticsType = 'internal';

    @Output() selectVideo = new EventEmitter<void>();
    @Output() clickVideo = new EventEmitter<void>();

    private hostingVideoViewerDialogManager = useHostingVideoViewerDialogManager()

    select(e: Event) {
        e.stopPropagation();
        e.preventDefault();
        this.selectVideo.next();
    }

    click(e: Event) {
        e.stopPropagation();
        e.preventDefault();
        this.clickVideo.next();
        this.preview();
    }

    preview() {
        if(!this.enablePreview) return;

        this.hostingVideoViewerDialogManager.open({
            source: !!this.videoToken ? 'videoToken' : 'videoId',
            sourceData: this.videoToken ?? this.video.id,
            analytics: this.videoAnalytics
        })
    }
}