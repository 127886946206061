import { FontVariant, LoadedFont } from '@openreel/common';
import { Font, FontPathOrigin } from './../interfaces/lottie.interface';

interface LottieFontInfo {
  fName: string;
  fWeight: string;
  fStyle: string;
}

const VARIANTS_LOTTIE_MAP = new Map<FontVariant, LottieFontInfo>([
  ['regular', { fName: '400_normal', fWeight: '400', fStyle: 'regular normal' }],
  ['italic', { fName: '400_italic', fWeight: '400', fStyle: 'regular italic' }],
  ['700', { fName: '700_normal', fWeight: '700', fStyle: 'bold normal' }],
  ['700italic', { fName: '700_italic', fWeight: '700', fStyle: 'bold italic' }],
]);

export function getLottieFName(font: LoadedFont) {
  return `${font.fontFamily}_${VARIANTS_LOTTIE_MAP.get(font.fontVariant).fName}`
}

export function convertToCanvasKitFonts(fonts: LoadedFont[]) {
  const skottieFonts = new Map<string, LoadedFont>();

  fonts.forEach((font) => {
    skottieFonts.set(getLottieFName(font), font);
  });

  return skottieFonts;
}

export function convertToLottieFonts(fonts: LoadedFont[]): Font[] {
  const lottieFonts: Font[] = [];
  for (const font of fonts) {
    const lottieFontInfo = VARIANTS_LOTTIE_MAP.get(font.fontVariant);

    lottieFonts.push({
      origin: FontPathOrigin.Local,
      fFamily: font.fontFamily,
      fName: getLottieFName(font),
      ascent: null,
      fStyle: lottieFontInfo.fStyle,
      fWeight: lottieFontInfo.fWeight,
      fPath: '',
    });

    // also inject Roboto font for hard-coded text fields in lotties.
    if (font.fontFamily === 'Roboto') {
      lottieFonts.push({
        origin: FontPathOrigin.Local,
        fFamily: font.fontFamily,
        fName: font.fontFamily,
        ascent: null,
        fStyle: 'regular normal',
        fWeight: '400',
        fPath: '',
      });
    }
  }

  return lottieFonts;
}
