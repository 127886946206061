import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  AuthService,
  CreatorMixpanelService,
  GlobalService,
  IPopupComponent,
  isPopupComponent,
  LiveSessionIOService,
  LocalPerformanceService,
  UploadService,
  TitleService,
} from '@openreel/frontend/common';
import { PerformanceScreenPopupComponent } from '@openreel/frontend/common/components/performance-screen-popup';
import { windowClose } from './app-state/session/session.actions';
import { FullStoryService } from './core/fullstory/fullstory.service';
import { Userpilot } from 'userpilot';
import { environment } from '../environments/environment';
import { AccessibeService } from '@openreel/ui/accessibe/accessibe.service';

@Component({
  selector: 'openreel-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'openreel-electron';

  constructor(
    private readonly router: Router,
    private readonly global: GlobalService,
    private readonly upload: UploadService,
    private readonly overlayContainer: OverlayContainer,
    private readonly fullStoryService: FullStoryService,
    private readonly liveSessionIOService: LiveSessionIOService,
    private readonly localPerformanceService: LocalPerformanceService,
    private readonly authService: AuthService,
    private readonly creatorMixpanelService: CreatorMixpanelService,
    private readonly store: Store,
    private readonly accessibeService: AccessibeService,
    private readonly titleService: TitleService,
    dialog: MatDialog,
    zone: NgZone
  ) {
    /**
     * Initialize and hook userpilot
     */
    if (environment.userPilotToken && environment.production) {
      Userpilot.initialize(environment.userPilotToken);

      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          Userpilot.reload('');
        }
      });
    }

    this.fullStoryService.init();
    this.liveSessionIOService.initLiveSession(); //initialize live session tracking
    (
      window as Window &
        typeof globalThis & {
          performanceScreen: () => void;
        }
    ).performanceScreen = () => {
      zone.run(() => {
        dialog.open(PerformanceScreenPopupComponent, {
          data: {
            performanceService: this.localPerformanceService,
          },
        });
      });
    };
    dialog.afterOpened.subscribe((ref) => {
      this.onNewDialogOpened(ref.componentInstance);
    });

    dialog.afterAllClosed.subscribe(() => {
      this.onDialogClosed();
    });

    (
      window as Window &
        typeof globalThis & {
          uploadService: UploadService;
        }
    ).uploadService = upload;

    // Redirect all #'d routes to non-#'d routes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }

      if (event instanceof NavigationEnd) {
        this.identifyCreatorMixpanel();
      }
    });
  }

  async ngOnInit() {
    if (localStorage.getItem('selfRecording') !== 'true') {
      await this.upload.uploadAllStoredRecordings();
    }

    this.titleService.init('OpenReel', 'OpenReel');
    this.initCreatorMixpanel();
  }

  ngAfterViewInit(): void {
    /**
     * Initialize and show the accessibe widget
     */
    setTimeout(() => {
      this.accessibeService.addWidget();
    }, 5000);
  }

  private initCreatorMixpanel() {
    this.creatorMixpanelService.init();
  }

  private identifyCreatorMixpanel() {
    this.creatorMixpanelService.identify(
      this.authService.getUserDetails()?.data?.user_id,
      this.authService.getUserDetails()?.data?.analytics_id
    );
    this.creatorMixpanelService.setSuperProperty('account_id', this.authService.getUserDetails()?.data?.account_id);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.store.dispatch(windowClose());
  }

  private onNewDialogOpened(component: Component & IPopupComponent) {
    if (!component || (isPopupComponent(component) && component.isLightThemed)) {
      this.overlayContainer.getContainerElement().classList.remove(/*'mat-app-background', */ 'dark-theme');
    } else {
      this.overlayContainer.getContainerElement().classList.add(/*'mat-app-background', */ 'dark-theme');
    }
    // no components in the application have an onSessionReceived method
    // if (
    //   isPopupComponent(component) &&
    //   component.onSessionReceived &&
    //   isSessionHolder(this.global.activeComponent$.value)
    // ) {
    //   component.onSessionReceived(
    //     this.global.activeComponent$.value.session.session
    //   );
    // }
  }

  private onDialogClosed() {
    this.overlayContainer.getContainerElement().classList.remove('dark-theme');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRouterOutletActivate(evt: any) {
    this.global.activeComponent$.next(evt);
  }
}
