import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanySsoInfo, SsoService } from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'openreel-sso-auth',
  templateUrl: './sso-auth.component.html',
  styleUrls: ['./sso-auth.component.scss'],
})
export class SsoAuthComponent {
  companyName: string;
  loginUrl: string;
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private ssoService: SsoService) {
    this.route.paramMap.subscribe((paramMap) => {
      this.getSsoInfo(paramMap.get('slug'));
    });
  }
  async getSsoInfo(slug: string) {
    try {
      const ssoInfo: CompanySsoInfo = await this.ssoService.getSsoInfo(slug);
      this.companyName = ssoInfo.companyName;
      this.loginUrl = this.ssoService.urls.getLoginUrl(ssoInfo.slug);
    } catch {
      this.toastr.error('No SSO Config Found', 'Error!');
    }
  }
}
