import { Injectable } from '@angular/core';
import { HostingHub } from '../hosting-interfaces';
import { forkJoin, of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HostingService } from './hosting.service';

export interface ManageHubError {
  id: number,
  error: Error,
}

@Injectable({providedIn: 'root'})
export class HostingHubService {

  constructor(private hostingService: HostingService) { }

  // link and unlink the video to Hub(s).
  linkUnlinkHubs(videoId: number, result: {checked: HostingHub[], unchecked: HostingHub[], created: HostingHub[]}): Observable<(HostingHub|ManageHubError)[]> {
    const batchObservables = [
      ...result.checked.map(checked =>
          this.hostingService.link2Hub(videoId, checked.id).pipe(catchError(error => of({id: checked.id, error})))),
      ...result.unchecked.map(unchecked =>
          this.hostingService.unlinkFromHub(videoId, unchecked.id).pipe(catchError(error => of({id: unchecked.id, error})))),
      ...result.created.map(created => of(created)),
    ];

    if (batchObservables.length) {
      return forkJoin(batchObservables);
    }

    return of([]);
  }
}
