import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthService } from '@openreel/frontend/common';
import { AuthEffects } from './auth.effects';
import { authReducer } from './auth.reducer';

const effects = [AuthEffects];

@NgModule({
  imports: [StoreModule.forFeature('auth', authReducer), EffectsModule.forFeature(effects)],
  providers: [AuthService, ...effects],
})
export class AuthStoreModule {}
