import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { WorkflowApprovalDto, WORKFLOW_VIDEO_APPROVABLE_TYPE } from './workflow-approval.interfaces';
import { ApprovalStatuses } from '@openreel/common';

@Injectable({
  providedIn: 'root',
})
export class WorkflowApprovalService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}approval`,
    current: `${commonenv.nextGenApiUrl}approval/current`,
    byApprovalId: (approvalId: number) => `${commonenv.nextGenApiUrl}approval/${approvalId}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getCurrentApproval(projectId: number) {
    return this.httpClient.get<WorkflowApprovalDto>(this.urls.current, {
      params: {
        id: projectId,
        type: WORKFLOW_VIDEO_APPROVABLE_TYPE,
      },
    });
  }

  sendForApproval(projectId: number) {
    return this.httpClient.post<WorkflowApprovalDto>(this.urls.root, {
      id: projectId,
      type: WORKFLOW_VIDEO_APPROVABLE_TYPE,
    });
  }

  cancelApproval(approvalId: number) {
    return this.httpClient.put<WorkflowApprovalDto>(this.urls.byApprovalId(approvalId), {
      approvalStatus: ApprovalStatuses.Canceled,
    });
  }
}
