import * as Interfaces from './workflow-layouts.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';
import { SaveAllResult } from '../../interfaces/save-all-result.interface';

@Injectable({
  providedIn: 'root',
})
export class LayoutsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}workflows/layouts`,
    getAll: (templateId: number) => `${this.urls.base}${templateId ? '?templateId=' + templateId : ''}`,
    getAllAspectRatios: () => `${this.urls.base}/aspect-ratios`,
    byId: (layoutId: number) => `${this.urls.base}/${layoutId}`,
    assignToAllTemplates: (layoutId: number) => `${this.urls.byId(layoutId)}/assign-to-all-templates`,
    removeFromAllTemplates: (layoutId: number) => `${this.urls.byId(layoutId)}/remove-from-all-templates`,
    saveAll: () => `${this.urls.base}/save-all`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll(templateId?: number) {
    return this.httpClient.get<Interfaces.WorkflowLayoutEntity[]>(this.urls.getAll(templateId));
  }

  getById(id: number) {
    return this.httpClient.get<Interfaces.WorkflowLayoutEntity>(this.urls.byId(id));
  }

  getAllAspectRatios() {
    return this.httpClient.get<Interfaces.AspectRatioEntity[]>(this.urls.getAllAspectRatios());
  }

  create(data: Interfaces.UpsertLayoutRequest) {
    return this.httpClient.post<Interfaces.WorkflowLayoutEntity>(this.urls.base, data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  update(id: number, data: Interfaces.UpsertLayoutRequest) {
    return this.httpClient.put<Interfaces.WorkflowLayoutEntity>(this.urls.byId(id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  delete(id: number) {
    return this.httpClient.delete<void>(this.urls.byId(id));
  }

  assignToAllTemplates(id: number) {
    return this.httpClient.post<Interfaces.WorkflowLayoutEntity>(this.urls.assignToAllTemplates(id), {});
  }

  removeFromAllTemplates(id: number) {
    return this.httpClient.post<Interfaces.WorkflowLayoutEntity>(this.urls.removeFromAllTemplates(id), {});
  }

  saveAll() {
    return this.httpClient.post<SaveAllResult[]>(this.urls.saveAll(), {});
  }
}
