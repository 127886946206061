<h2 mat-dialog-title>Manage Hubs</h2>

<mat-dialog-content>
  <div class="add-to-hub-container">
    <button mat-button color="primary" (click)="setNewHubCreationFormVisibility(true)" *ngIf="!hubCreationActive">
      <mat-icon>add</mat-icon>
      Create new hub
    </button>

    <ng-container *ngIf="hubCreationActive">
      <div class="create-new-hub-wrapper" [formGroup]="newHubForm">
        <mat-form-field class="form-field-input" appearance="outline">
          <input
            matInput
            formControlName="name"
            placeholder="Enter hub name..."
            (keydown.enter)="createNewHub()"
            (keydown.escape)="exitHubCreationMode()"
          >
        </mat-form-field>

        <button
          mat-button
          color="primary"
          (click)="createNewHub()"
          [disabled]="newHubForm.get('name').invalid || creatingNewHub"
        >
          <mat-icon>check</mat-icon>
        </button>

        <button mat-button color="warn" (click)="exitHubCreationMode()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-error *ngIf="newHubForm.get('name').hasError('required')" class="error-message">
        Hub name is required
      </mat-error>

      <mat-error *ngIf="newHubForm.get('name').hasError('maxlength')" class="error-message">
        Hub name cannot be more than 50 characters long
      </mat-error>

      <mat-error *ngIf="newHubForm.get('name').hasError('pattern')" class="error-message">
        Hub name must contain numbers and letters
      </mat-error>
    </ng-container>

    <div *ngFor="let hub of hubList">
      <div *openreelAcl="let acl; permissions: hub.hub.userPermissions"
           class="hub-item"
           matTooltip="You do not have permission to update this hub"
           [matTooltipDisabled]="acl(HostingPermissions.Update)"
      >
        <mat-checkbox
          color="primary"
          class="add-to-hub-check"
          [disabled]="!acl(HostingPermissions.Update)"
          [checked]="hub.checked"
          (change)="onChange($event, hub)"
        >
          <div
            #hubTitle
            class="hub-title"
            [matTooltip]="hub.hub.title"
            [matTooltipDisabled]="hubTitle.scrollWidth <= hubTitle.clientWidth"
          >
            {{hub.hub.title}}
          </div>
        </mat-checkbox>

        <mat-icon *ngIf="hub.hub.permission === 'private'" inline=true class="blue lock-icon">group_off</mat-icon>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="add2Hub()" [disabled]="saveDisabled">Save</button>
    <button mat-stroked-button mat-dialog-close (click)="cancel()">Cancel</button>
</mat-dialog-actions>
