<div class="preview">
  <div class="preview-image-wrapper" [ngStyle]="{ border: '4px solid ' + (color || defaultColor) }">
    <div class="preview-image-container">
      <img src="assets/common/images/man.png" class="preview-image" alt="Preview image">

      <div class="preview-logo" *ngIf="logoSrc" [ngStyle]="{ 'background-image': 'url(' + logoSrc + ')' }"></div>

      <div class="preview-watermark" *ngIf="watermarkSrc" [ngStyle]="{ 'background-image': 'url(' + watermarkSrc + ')' }"></div>

      <div class="preview-text" [ngStyle]="{ 'font-family': fontName }">
        <span class="preview-text-name">John Doe</span>
        <div
          class="preview-text-divider"
          [ngStyle]="{ 'background': 'linear-gradient(90deg, ' + (color || defaultColor) + ' 0%, rgba(0, 102, 255, 0.00) 100%)' }"
        ></div>
        <span class="preview-text-title">Account Manager</span>
      </div>
    </div>
  </div>
</div>
