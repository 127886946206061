import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'openreel-divider-bar',
  templateUrl: './openreel-divider-bar.component.html',
  styleUrls: ['./openreel-divider-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class OpenreelDividerBarComponent {
  @Input() height: number;
  @Input() top: number;
  @Input() bottom: number;
}
