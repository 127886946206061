import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {PermissionableEntity, YOUTUBE_INTEGRATION, HOSTING_SOCIAL_SHARE} from '@openreel/common';
import {Cleanupable, FeatureFlaggingService} from '@openreel/frontend/common';
import { HostablePermissionTypes, HostingEmbedDto, HostingService } from '@openreel/frontend/common/hosting';
import {
  HOSTING_SHARE_FORM,
  HostingShareForm,
  HostingShareFormService,
} from '@openreel/frontend/common/hosting/dialogs/share/hosting-share-form.service';
import { HostingShareComponentStore } from '@openreel/frontend/common/hosting/dialogs/share/hosting-share.store';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, merge, Observable, of, startWith, Subject, timer, combineLatest } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  map,
  pairwise,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { fadeIn, heightCollapse } from '../../../animations';

import {ShareModalData} from './share-modal-data.interface';
import {YoutubeSharingStore} from "./youtube-sharing-dialog/youtube-sharing.store";
import { SocialShareType } from './social-share/social-share-type';

@Component({
  selector: 'openreel-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightCollapse, fadeIn],
  providers: [
    {
      provide: HOSTING_SHARE_FORM,
      deps: [HostingShareFormService],
      useFactory: (formService: HostingShareFormService) => formService.form,
    },
    HostingShareFormService,
    YoutubeSharingStore,
  ],
})
export class ShareComponent extends Cleanupable implements OnInit {
  readonly isLightThemed = true;
  readonly isPopup = true;

  readonly copyLinkClicked$: Observable<boolean>;
  readonly formInvalid$: Observable<boolean>;
  readonly shareModalData$ = this.hostingShareComponentStore.shareModalData$;
  readonly saving$ = this.hostingShareComponentStore.saving$;
  readonly passwordValid$ = this.hostingShareComponentStore.passwordValid$;
  readonly isHostingSocialShareEnabled$ = this.featureFlaggingService.isFeatureFlagEnabled(HOSTING_SOCIAL_SHARE);

  readonly isVideoSharing$ = this.shareModalData$.pipe(
    map(data => data.type == 'video')
  )

  readonly userCanManageAccessType$ = this.shareModalData$.pipe(
    map(data => {
      const userUpdatePermission = data.userPermissions.find(o => o.action == 'update');
      if(!userUpdatePermission?.fields) return false;
      const fields = userUpdatePermission.fields;
      if(fields.includes('permission') || fields === '*') return true;
      return false;
    })
  )

  readonly isEnableYoutubeSharing$ = combineLatest([
    this.isVideoSharing$,
    this.featureFlaggingService.isFeatureFlagEnabled(YOUTUBE_INTEGRATION),
    this.userCanManageAccessType$,
  ]).pipe(
    map(([isVideo, isFeatureEnabled, canManageAccessType]) => isVideo && isFeatureEnabled && canManageAccessType)
  )

  readonly hasYoutubeIntegratedUsers$ = this.youtubeSharingStore.hasIntegratedUsers$;

  private readonly copyLinkClickedSubject = new Subject<void>();

  get hasPassword(): boolean {
    return this.data.permission === HostablePermissionTypes.Password;
  }

  get shareFormInvalid(): boolean {
    return this.form.invalid;
  }

  get isVideo(): boolean {
    return this.data.type === 'video';
  }

  get entityType(): PermissionableEntity {
    return this.isVideo ? PermissionableEntity.Hostable : PermissionableEntity.HostingHubs;
  }

  get advancedSharingEnabled(): boolean {
    return this.form.controls.advancedMode.value && (this.form.controls.accessType.value === HostablePermissionTypes.Public);
  }

  get isPublic(): boolean {
    const publicTypes = [HostablePermissionTypes.Public, HostablePermissionTypes.Password];

    return publicTypes.includes(this.form.controls.accessType.value);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ShareModalData,
    @Inject(HOSTING_SHARE_FORM) readonly form: FormGroup<HostingShareForm>,
    private readonly dialogRef: MatDialogRef<ShareComponent>,
    private readonly hostingShareComponentStore: HostingShareComponentStore,
    private readonly hostingService: HostingService,
    private readonly youtubeSharingStore: YoutubeSharingStore,
    private readonly featureFlaggingService: FeatureFlaggingService,
    private readonly toastr: ToastrService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.copyLinkClicked$ = this.handleCopyLinkButtonClick();
    this.formInvalid$ = this.isFormInvalid();
  }

  ngOnInit(): void {
    this.hostingShareComponentStore.getPermissionedObjects([this.data.id, this.entityType]);
    this.hostingShareComponentStore.getEntityRoles([this.data.id, this.entityType]);
    this.hostingShareComponentStore.setShareModalData(this.data);

    // this allows to pass the data to the parent component on ESC press or backdrop click
    this.dialogRef.beforeClosed().pipe(switchMap(() => this.closeDialog()), take(1)).subscribe();

    this.setEmbeddedUsageInformation();
    this.setInitialAccessType();
    this.subscribeToAccessTypeChanges();
    this.subscribeToRequirePasswordChanges();
    this.updatePermissionOnAccessTypeChange();
    this.subscribeToEmbedControlChanges();
    this.subscribeToAdvancedModeChanges();
    this.setPasswordInitialState();
    this.fetchYoutubeIntegratedUsers();
  }

  share(): void {
    if (this.shareFormInvalid) {
      return;
    }

    this.mortalize(this.closeDialog()).subscribe();
  }

  close(): void {
    this.mortalize(this.closeDialog()).subscribe();
  }

  private setEmbeddedUsageInformation(): void {
    if (this.data.type === 'video') {
      const { embedEnabledAt, embedExceed, embedLimit, embedVideoCount } = this.data;
      this.form.patchValue({
        embedEnabledAt,
        embedExceed,
        embedLimit,
        embedVideoCount,
        embedEnabled: !!embedEnabledAt,
      });
    }
  }

  private setInitialAccessType(): void {
    const getInitialAccessType = (): HostablePermissionTypes => {
      if (this.hasPassword) {
        return HostablePermissionTypes.Public;
      }

      return this.data.permission;
    };

    const accessTypeControl = this.form.controls.accessType as FormControl;
    const requirePasswordControl = this.form.controls.requirePassword as FormControl;

    accessTypeControl.setValue(getInitialAccessType());
    requirePasswordControl.setValue(this.hasPassword);
  }

  copyLink(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.hostingShareComponentStore.copyEntityLink();
    this.copyLinkClickedSubject.next(null);
  }

  socialShare(type: SocialShareType) {
    if(type == 'youtube') {
      this.shareOnYoutube();
      return;
    }
  }

  shareOnYoutube() {
    if(this.data.type == 'video') {
      this.youtubeSharingStore.openSharingDialog({
        videoId: this.data.id,
        videoDuration: this.data.duration,
      });
    }
  }

  private handleCopyLinkButtonClick(): Observable<boolean> {
    const DISPLAY_LABEL_DURATION = 3000;

    const click$ = this.copyLinkClickedSubject.asObservable().pipe(map(() => true));

    const timer$ = click$.pipe(exhaustMap(() => timer(DISPLAY_LABEL_DURATION).pipe(map(() => false))));

    return merge(of(false), click$, timer$);
  }

  private setPasswordInitialState(): void {
    if (this.data.permission === HostablePermissionTypes.Password) {
      this.form.controls.requirePassword.setValue(true);
    }
  }

  private subscribeToAccessTypeChanges(): void {
    this.mortalize(this.form.controls.accessType.valueChanges)
      .pipe(
        startWith<HostablePermissionTypes>(this.form.controls.accessType.value as HostablePermissionTypes),
        tap((accessType) => {
          this.hostingShareComponentStore.setAccessType(accessType);
        }),
      )
      .subscribe();
  }

  private subscribeToRequirePasswordChanges(): void {
    this.mortalize(this.form.controls.requirePassword.valueChanges)
      .pipe(
        startWith<boolean>(this.form.controls.requirePassword.value as boolean),
        tap((requirePassword) => {
          this.hostingShareComponentStore.setRequirePassword(requirePassword);
        }),
      )
      .subscribe();
  }

  private subscribeToAdvancedModeChanges(): void {
    this.mortalize(this.form.controls.advancedMode.valueChanges)
      .pipe(
        withLatestFrom(this.shareModalData$),
        tap(([advancedMode, shareModalData]) => {
          if (!advancedMode) {
            // change permission to Public if user switches off Advanced mode
            if (shareModalData.permission !== HostablePermissionTypes.Public) {
              this.hostingShareComponentStore.updatePermission(HostablePermissionTypes.Public);
            }

            // Reset password and embed settings if user switches off Advanced mode
            this.form.controls.embedEnabled.setValue(false, { emitEvent: this.form.controls.embedEnabled.value });
            this.resetPasswordValidators(false);
          }
        }),
      )
      .subscribe();
  }

  private updatePermissionOnAccessTypeChange(): void {
    this.mortalize(this.hostingShareComponentStore.accessType$)
      .pipe(
        pairwise(),
        tap(([previousPermission, currentPermission]) => {
          this.hostingShareComponentStore.updatePermission(currentPermission);

          if (previousPermission === HostablePermissionTypes.Public) {
            this.resetPasswordValidators(false);

            this.form.controls.advancedMode.setValue(false, { emitEvent: false });

            if (this.form.controls.embedEnabled.value) {
              this.form.controls.embedEnabled.setValue(false);
            }
          }

          if (currentPermission === HostablePermissionTypes.Public && !this.form.controls.embedExceed.value) {
            this.form.controls.embedEnabled.enable({ emitEvent: false });
          }
        }),
      )
      .subscribe();
  }

  private updateEmbeddedVideo(enabled: boolean): Observable<HostingEmbedDto> {
    const { id } = this.data;

    const embedEnabledControl = this.form.controls.embedEnabled;

    return this.hostingService.updateEmbedVideo(id, enabled).pipe(
      catchError((err) => {
        embedEnabledControl.setValue(!enabled, { emitEvent: false });

        this.toastr.error(err.message, 'Unable to change embedding settings');

        return EMPTY;
      }),
      tap((data) => {
        this.form.patchValue(data);

        if (
          !data.embedExceed &&
          !this.form.controls.requirePassword.value &&
          this.form.controls.accessType.value === HostablePermissionTypes.Public
        ) {
          this.form.controls.embedEnabled.enable({ emitEvent: false });
        }

        this.cdr.markForCheck();
      })
    );
  }

  private subscribeToEmbedControlChanges(): void {
    const embedEnabledControl = this.form.controls.embedEnabled;

    this.mortalize(embedEnabledControl.valueChanges)
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((enabled) => this.updateEmbeddedVideo(enabled)),
      )
      .subscribe();
  }

  private closeDialog(): Observable<ShareModalData> {
    return this.shareModalData$.pipe(
      tap(data => {
        this.dialogRef.close({
          permission: data.permission,
          shareUrl: data.shareUrl,
        });
      }),
    );
  }

  private isFormInvalid(): Observable<boolean> {
    return this.form.statusChanges.pipe(
      debounceTime(0),
      startWith(this.form.status),
      map((status) => status === 'INVALID')
    )
  }

  private resetPasswordValidators(emitEvent = true): void {
    this.form.patchValue({
      requirePassword: false,
      password: null,
    }, { emitEvent });

    this.form.controls.password.removeValidators([Validators.required]);
    this.form.controls.password.updateValueAndValidity();

    this.hostingShareComponentStore.setPasswordSubmitted(true);
    this.hostingShareComponentStore.setRequirePassword(false);
  }

  private fetchYoutubeIntegratedUsers() {
    this.mortalize(this.isEnableYoutubeSharing$).subscribe(isEnableYoutubeSharing => {
      if(!isEnableYoutubeSharing) return;
      this.youtubeSharingStore.fetchIntegratedUsers();
    })
  }
}
