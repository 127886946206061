import { PresetField } from '@openreel/creator/common';
import { FillShape } from '../interfaces/lottie.interface';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';

export class TransformerFill extends TransformerBase {
  test(fieldDef: PresetField) {
    return fieldDef.type === 'fill';
  }

  process({ animation, fieldDef, fieldValues }: TransformerProcessOptions) {
    if (!fieldDef.lottiePath) {
      return animation;
    }

    const rootNode = this.getRootNode<FillShape>(animation, fieldDef.lottiePath);
    rootNode.c = {
      ...rootNode.c,
      k: fieldValues.color,
    };

    return animation;
  }
}
