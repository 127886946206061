import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { HostingPermissions, PermissionableEntity } from '@openreel/common';
import { AclService, Cleanupable, EntityRole, SharePermissionedEntityBase, PermissionedEntityType } from '@openreel/frontend/common';
import { HostingShareComponentStore } from '../../hosting-share.store';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CanManageRolePipe } from './pipes/can-manage-role.pipe';

@Component({
  selector: 'openreel-permissioned-list-item',
  templateUrl: './permissioned-list-item.component.html',
  styleUrls: ['./permissioned-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule, FlexModule, ReactiveFormsModule, MatDividerModule, MatIconModule, MatLegacyButtonModule,
    MatLegacyMenuModule, CanManageRolePipe,
  ],

})
export class PermissionedListItemComponent<T extends SharePermissionedEntityBase> extends Cleanupable {
  @Input() readonly entityDetailsTemplate: TemplateRef<{ $implicit: T }>;
  @Input() readonly permissionedEntityType: PermissionedEntityType;

  @Input() set hideOwnershipTransfer(isOwnershipHidden: boolean) {
    this._hideOwnershipTransfer = coerceBooleanProperty(isOwnershipHidden);
  }

  get hideOwnershipTransfer(): boolean {
    return this._hideOwnershipTransfer;
  }

  @Input() set entity(entity: T) {
    if (entity) {
      this._entity = entity;

      this.form.patchValue({
        roleId: entity.role.id,
        roleName: entity.role.name,
      });
    }
  }

  get entity(): T {
    return this._entity;
  }

  readonly objectRoles$ = this.hostingShareComponentStore.objectRoles$.pipe(
    map((objectRoles) => objectRoles.filter((role) => !role.isOwner))
  );

  readonly canManageRolesIds$ = this.hostingShareComponentStore.canManageRolesIds$;

  readonly isOwnerTransferShipAvailable$ = this.hostingShareComponentStore.shareModalData$.pipe(
    map((shareModalData) =>
      this.aclService.isUserHasPermissions(shareModalData.userPermissions, HostingPermissions.TransferOwnership)
    ),
  );

  readonly form = this.fb.group({
    roleId: null,
    roleName: null,
  });

  private _entity: T;
  private _hideOwnershipTransfer = false;

  get isUserOwner(): boolean {
    return this.entity.role.isOwner;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly hostingShareComponentStore: HostingShareComponentStore,
    private readonly aclService: AclService,
  ) {
    super();
  }

  setRole(role: EntityRole): void {
    const permissionId = this.entity.permissionId;

    this.mortalize(this.hostingShareComponentStore.shareModalData$)
      .pipe(
        take(1),
        switchMap(({ id, type }) =>
          this.hostingShareComponentStore.updateUserObjectRole({
            permissionId,
            roleId: role.id,
            entityId: id,
            entity: this.getEntityType(type),
          }, this.permissionedEntityType)
        )
      )
      .subscribe(() => {
        this.updateForm(role);
      });
  }

  updateForm(role: EntityRole): void {
    this.form.patchValue({
      roleId: role.id,
      roleName: role.name,
    });
  }

  removePermissionedObject(): void {
    const { id: permissionedEntityId } = this.entity;

    this.mortalize(this.hostingShareComponentStore.shareModalData$)
      .pipe(
        take(1),
        tap(({ id, type }) => {
          this.hostingShareComponentStore.deleteUserFromObject({
            permissionedEntityId,
            entityId: id,
            entity: this.getEntityType(type),
            permissionedEntityType: this.permissionedEntityType,
          });
        })
      )
      .subscribe();
  }

  transferOwnership(): void {
    const { id: newOwnerId } = this.entity;

    this.mortalize(this.hostingShareComponentStore.shareModalData$)
      .pipe(
        take(1),
        withLatestFrom(
          this.hostingShareComponentStore.getCurrentOwner$,
          this.hostingShareComponentStore.getObjectRoleByName$('Editor')
        ),
        switchMap(([{ id, type }, currentOwner, editorRole]) =>
          this.hostingShareComponentStore.transferOwnership({
            entityId: id,
            setfromUserToRoleId: editorRole.id,
            toUserId: newOwnerId,
            fromUserId: currentOwner.id,
          }, this.getEntityType(type))
        )
      )
      .subscribe();
  }

  private getEntityType(type: 'hub' | 'video'): PermissionableEntity {
    return type === 'video' ? PermissionableEntity.Hostable : PermissionableEntity.HostingHubs;
  }
}
