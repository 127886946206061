import { trimSpaceInLines } from '@openreel/common';
import { CaptionEditorTimebox, CaptionSpeaker } from '../../../interfaces/caption.interface';
import { ASSCue, ASSStyle, toASSCue, toASSStyle } from '../../../utils';
import { SubtitleBuilderStrategy } from '../subtitle-builder-strategy';

export interface ASSStyleWithFont {
  globalStyle: ASSStyle;
  fontFamily: string;
}

export class ASSStrategy extends SubtitleBuilderStrategy {
  static readonly ASS_EVENTS_HEADER = trimSpaceInLines(`
    [Events]
    Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text
  `);

  static readonly ASS_SCRIPT_HEADER = (resolution: { width: number; height: number }) =>
    trimSpaceInLines(`
      [Script Info]
      ; Script generated by OpenReel
      ; https://openreel.com/
      Title: OpenReel SubStation Alpha Subtitles file
      ScriptType: v4.00+
      WrapStyle: 0
      ScaledBorderAndShadow: yes
      YCbCr Matrix: TV.601
      PlayResX: ${resolution.width}
      PlayResY: ${resolution.height}
    `);

  static readonly ASS_STYLES_HEADER = trimSpaceInLines(`
    [V4+ Styles]
    Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
  `);

  private content = '';

  constructor(
    private readonly timeboxes: CaptionEditorTimebox[],
    speakers: CaptionSpeaker[],
    private readonly offset: number,
    private readonly resolution: { width: number; height: number },
    private readonly style: ASSStyleWithFont
  ) {
    super(speakers);
  }

  getHeader(): string {
    return trimSpaceInLines(`
      ${ASSStrategy.ASS_SCRIPT_HEADER(this.resolution)}

      ${ASSStrategy.ASS_STYLES_HEADER}

      ${toASSStyle(this.style.globalStyle, this.style.fontFamily, this.resolution)}
    `);
  }

  getContent(): string {
    return this.content;
  }

  buildCues(): void {
    let previousTimebox: CaptionEditorTimebox = null;

    this.content += `\n\n${ASSStrategy.ASS_EVENTS_HEADER}\n`;

    for (const timebox of this.timeboxes) {
      const previousSpeaker = previousTimebox ? this.speakers.get(previousTimebox.speakerId) : null;
      const speaker = this.speakers.get(timebox.speakerId);

      const isSameSpeaker = speaker && speaker.id === previousSpeaker?.id;

      this.addCue(timebox, !isSameSpeaker, speaker);

      previousTimebox = timebox;
    }
  }

  private addCue(timebox: CaptionEditorTimebox, showSpeaker?: boolean, speaker?: CaptionSpeaker) {
    const speakerText = showSpeaker && speaker?.name ? `${speaker.name}: ` : '';

    if (timebox) {
      const cueProperties: ASSCue = {
        startMS: this.offset + timebox.start,
        endMS: this.offset + timebox.end,
        style: 'or_default',
        speaker: speaker?.name,
        text: `${speakerText}${timebox.text}`,
      };

      if (isFinite(timebox.marginV)) {
        cueProperties.marginV = timebox.marginV;
      }

      this.content += toASSCue(cueProperties, this.resolution);
    }
  }
}
