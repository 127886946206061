import {
  TemplateSettingsDto,
  TemplateSettingsResponseDto,
  UpdateTemplateSettingsRequestDto,
} from './template-settings.interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateSettingsService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/templates-settings`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getTemplatesForAccount() {
    return this.httpClient.get<TemplateSettingsResponseDto>(this.urls.root);
  }

  updateTemplateForAccount(templatesSettingsRequest: UpdateTemplateSettingsRequestDto) {
    return this.httpClient.put(this.urls.root, templatesSettingsRequest);
  }

  sortByTemplateIdFn = (a: TemplateSettingsDto, b: TemplateSettingsDto) => {
    if (a.templateId > b.templateId) {
      return 1;
    } else if (a.templateId < b.templateId) {
      return -1;
    }
    return 0;
  };

  isInstantTemplateEnabled() {
    return this.httpClient.get<boolean>(`${this.urls.root}/is-instant-template-enabled`);
  }
}
