import { Injectable } from '@angular/core';
import { INativeLibService, ElectronService } from '@openreel/frontend/common';

@Injectable()
export class NativeLibBrowserService extends INativeLibService {
  getCpuSpeedGHz(): Promise<number> {
    throw new Error('Method not implemented.');
  }
  async getCpuCores(): Promise<number> {
    return navigator.hardwareConcurrency;
  }
  getCpuUsage(): Promise<number> {
    throw new Error('Method not implemented.');
  }
  getAppCpuUsage(): Promise<number> {
    throw new Error('Method not implemented.');
  }
  getMemoryGB(): Promise<number> {
    throw new Error('Method not implemented.');
  }
  getMemoryUsage(): Promise<number> {
    throw new Error('Method not implemented.');
  }
  getMemoryAppUsage(): Promise<number> {
    throw new Error('Method not implemented.');
  }
  async getOsName(): Promise<string> {
    return navigator.platform;
  }
  async getAppVersion(): Promise<string> {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document['documentMode'] === true) {
      return 'IE';
    } else {
      return 'Nextgen Web client';
    }
  }
}

@Injectable()
export class NativeLibElectronService extends INativeLibService {
  constructor(private myElectron: ElectronService) {
    super();
  }
  async getOsName(): Promise<string> {
    const osInfo = await this.myElectron.si.osInfo();
    return osInfo.distro;
  }
  async getAppVersion(): Promise<string> {
    return 'Nextgen Desktop client';
  }
  async getCpuSpeedGHz(): Promise<number> {
    const cpuInfo = await this.myElectron.si.cpu();
    return cpuInfo.speed;
  }
  async getCpuCores(): Promise<number> {
    const cpuInfo = await this.myElectron.si.cpu();
    return cpuInfo.cores;
  }
  async getCpuUsage(): Promise<number> {
    const load = await this.myElectron.si.currentLoad();
    return load.currentLoad / 100.0;
  }
  async getAppCpuUsage(): Promise<number> {
    const processes = await this.myElectron.si.processes();
    return processes.list.find((p) => p.pid === process.pid).cpu / 100;
  }
  async getMemoryGB(): Promise<number> {
    const mem = await this.myElectron.si.mem();
    return mem.total / 1024 / 1024 / 1024;
  }
  async getMemoryUsage(): Promise<number> {
    const mem = await this.myElectron.si.mem();
    return mem.free / 1024 / 1024 / 1024 / (await this.getMemoryGB());
  }
  async getMemoryAppUsage(): Promise<number> {
    const processes = await this.myElectron.si.processes();
    const myProcess = processes.list.find((p) => p.pid === process.pid);
    return myProcess.mem / 100.0;
  }
}
