import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, ROUTE_LIBRARY_PROJECTS } from '@openreel/frontend/common';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnterprisePackageGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.userDetails$.pipe(
      filter((user) => !!user),
      take(1),
      map((userDetails) => {
        if (userDetails.isPremiumPackage) {
          const stateUrlArr = state.url.split('/');
          const isProjectDetails = stateUrlArr.indexOf('projects') !== -1 && stateUrlArr.length === 3;
          if (isProjectDetails) {
            this.router.navigate([`/${ROUTE_LIBRARY_PROJECTS}/${stateUrlArr[2]}`]);
          } else {
            this.router.navigate([`/${ROUTE_LIBRARY_PROJECTS}`]);
          }
          return false;
        }

        return true;
      })
    );
  }
}
