import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { MagicLinkResDto } from '@openreel/frontend/common';

@Injectable({ providedIn: 'root' })
export class MagicLinkService {
  private readonly http = inject(HttpClient);

  private readonly _magicLinkRootUrl = commonenv.nextGenApiUrl + 'magic-link';
  private readonly _checkSocialUserRegistrationUrl = `${this._magicLinkRootUrl}/social-user`

  checkSocialUserRegistration(userToken: string) {
    return this.http.post<MagicLinkResDto>(this._checkSocialUserRegistrationUrl, { userToken });
  }
}
