import { TeleprompterScript, TeleprompterScriptCreate, TeleprompterScriptEdit } from '../../../interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { firstValueFrom } from 'rxjs';

const URL_SCRIPT_GET_BY_PROJECT = 'teleprompter/in-project/';
const URL_SCRIPT_EDIT_CREATE = 'teleprompter';

@Injectable({
  providedIn: 'root',
})
export class TelepromterApiService {
  constructor(private httpClient: HttpClient) {}

  getScriptsInProject(projectId: number) {
    return firstValueFrom(
      this.httpClient.get<TeleprompterScript[]>(commonenv.nextGenApiUrl + URL_SCRIPT_GET_BY_PROJECT + projectId)
    );
  }

  editScript(scriptId: number, newContent: TeleprompterScriptEdit) {
    return firstValueFrom(
      this.httpClient.put<TeleprompterScript>(
        commonenv.nextGenApiUrl + URL_SCRIPT_EDIT_CREATE + '/' + scriptId,
        newContent
      )
    );
  }

  createScript(newContent: TeleprompterScriptCreate) {
    return firstValueFrom(
      this.httpClient.post<TeleprompterScript>(commonenv.nextGenApiUrl + URL_SCRIPT_EDIT_CREATE, newContent)
    );
  }

  deleteScript(scriptId: number) {
    return this.httpClient.delete(`${commonenv.nextGenApiUrl}${URL_SCRIPT_EDIT_CREATE}/${scriptId}`);
  }
}
