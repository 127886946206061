import { Observable, share, tap } from 'rxjs';

export function cacheValue<T>(timeout: number) {
  let cache: { value: T; expires: number } | undefined;
  return (source: Observable<T>) =>
    new Observable<T>((observer) => {
      if (cache && cache.expires > Date.now()) {
        observer.next(cache.value);
        observer.complete();
      } else {
        return source.pipe(tap((value) => (cache = { value, expires: Date.now() + timeout }))).subscribe(observer);
      }
    }).pipe(share({ resetOnComplete: true, resetOnError: true, resetOnRefCountZero: true }));
}
