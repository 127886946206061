import { SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash-es';

export function isPropChanged(changes: SimpleChanges, propName: string, skipFirstChange = false) {
  return (
    propName in changes &&
    (!skipFirstChange || !changes[propName].firstChange) &&
    !isEqual(changes[propName].previousValue, changes[propName].currentValue)
  );
}

export function isAnyOfPropsChanged(changes: SimpleChanges, propNames: string[], skipFirstChange = false) {
  return propNames.some((propName) => isPropChanged(changes, propName, skipFirstChange));
}
