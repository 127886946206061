/// <reference types="@types/offscreencanvas" />

import { BehaviorSubject, Subject } from 'rxjs';
import { Canvas, CanvasKit, ManagedSkottieAnimation } from './canvas-kit';
import { clamp, round } from 'lodash';
import { SkottieCanvasKitInitOptions } from './skottie-canvas-kit.service';
import { SkottieCanvasKitRenderer } from './skottie-canvas-kit-renderer';
import { OFFSCREEN_CANVAS_WIDTH } from '@openreel/creator/common';
import { loadedFontsToCanvasKitFonts } from './canvas-kit-fonts';
import { LoadedFont } from '@openreel/common';

const FRAME_TIME = 20;
const MS_TO_WAIT_FOR_CANVAS_RENDER = 0;

export interface EnterFrameEvent {
  frame: number;
}

export class SkottieCanvasKitAnimation {
  public totalFrames = 0;
  public duration = 0;
  public firstFrame = 0;
  private isPlaying = false;
  public isLoaded = new BehaviorSubject(false);
  public isLoading = new BehaviorSubject(false);

  private readonly _enterFrame$ = new Subject<EnterFrameEvent>();
  public readonly enterFrame$ = this._enterFrame$.asObservable();

  private currentProgress = 0;
  private skottieAnimation: ManagedSkottieAnimation;
  private skiaBounds: Float32Array;
  private requestedTime: number = null;

  constructor(
    private readonly CanvasKit: CanvasKit,
    private readonly renderer: SkottieCanvasKitRenderer,
    private readonly fonts: Map<string, LoadedFont>,
    private readonly opts: SkottieCanvasKitInitOptions
  ) {}

  goToAndStop(time: number) {
    this.isPlaying = false;

    if (this.currentProgress === time && time === 0) {
      return;
    }

    this.requestedTime = time;
    this.renderer.requestAnimationFrame(this, this.drawSingleSkottieFrame.bind(this));
  }

  play() {
    this.isPlaying = true;
    this.renderer.requestAnimationFrame(this, this.drawMultipleSkottieFrame.bind(this));
  }

  pause() {
    this.isPlaying = false;
  }

  goToAndPlay(time: number) {
    this.requestedTime = time;
    this.play();
  }

  destroy() {
    this.isPlaying = false;
    this.renderer.destroy(this);
    this.opts.canvasEl?.getContext('2d')?.clearRect(0, 0, this.opts.canvasEl.width, this.opts.canvasEl.height);
  }

  async loadAnimation(time?: number) {
    if (this.isLoaded.getValue() || this.isLoading.getValue()) {
      return;
    }

    if (!this.opts.animationData) {
      console.warn('No animation data provided. Nothing to render.');
      return;
    }

    this.isLoading.next(true);
    this.isLoaded.next(false);

    // Set animation bounds
    const animationAspectRatio = this.opts.animationData.w / this.opts.animationData.h;
    if (this.opts.canvasEl instanceof HTMLCanvasElement) {
      this.opts.canvasEl.width = Math.min(
        this.opts.canvasEl.getBoundingClientRect().width * window.devicePixelRatio,
        1920
      );
    } else {
      this.opts.canvasEl.width = Math.min(this.opts.canvasEl.width, 1920);
    }

    if (this.opts.fitOptions.animationFit === 'stretch') {
      this.opts.canvasEl.height = this.opts.canvasEl.width / this.opts.fitOptions.hostAspectRatio;

      const skiaBoundsWidth = Math.min(this.opts.canvasEl.width, OFFSCREEN_CANVAS_WIDTH);
      const skiaBoundsHeight = skiaBoundsWidth / animationAspectRatio;
      this.skiaBounds = this.CanvasKit.LTRBRect(0, 0, skiaBoundsWidth, skiaBoundsHeight);
    } else {
      this.opts.canvasEl.height = this.opts.canvasEl.width / animationAspectRatio;
      this.skiaBounds = this.CanvasKit.LTRBRect(0, 0, this.opts.canvasEl.width, this.opts.canvasEl.height);
    }

    this.skottieAnimation = this.CanvasKit.MakeManagedAnimation(
      JSON.stringify(this.opts.animationData),
      loadedFontsToCanvasKitFonts(this.fonts)
    );

    if (!this.skottieAnimation) {
      throw new Error('Failed to create skottie animation.');
    }

    this.duration = this.skottieAnimation.duration() * 1000;
    this.totalFrames = round(this.skottieAnimation.fps() * this.skottieAnimation.duration());

    this.firstFrame = this.now();

    if (time) {
      this.requestedTime = time;
      this.renderer.requestAnimationFrame(this, this.drawSingleSkottieFrame.bind(this));
    } else {
      this.renderer.requestAnimationFrame(this, this.drawFirstSkottieFrame.bind(this));
    }
  }

  private async drawMultipleSkottieFrame(skiaCanvas: Canvas, offScreenCanvas: HTMLCanvasElement) {
    if (this.isPlaying) {
      await this.drawSingleSkottieFrame(skiaCanvas, offScreenCanvas);
      setTimeout(() => {
        if (this.isPlaying) {
          this.renderer.requestAnimationFrame(this, this.drawMultipleSkottieFrame.bind(this));
        }
      }, FRAME_TIME);
    }
  }

  private async drawSingleSkottieFrame(skiaCanvas: Canvas, offScreenCanvas: HTMLCanvasElement) {
    if (this.requestedTime !== null) {
      this.firstFrame = this.now() - this.requestedTime;
      this.requestedTime = null;
    }

    if (this.opts.isStatic) {
      this.isPlaying = false;
      return;
    }

    this.clearCanvas(skiaCanvas);
    this.currentProgress = (this.now() - this.firstFrame) / this.duration;
    const skottieSeek = clamp(this.currentProgress, 0, 1);
    this._enterFrame$.next({ frame: skottieSeek * this.totalFrames });
    this.skottieAnimation.seek(skottieSeek);
    this.skottieAnimation.render(skiaCanvas, this.skiaBounds);
    await this.copyToCanvas(offScreenCanvas);

    if (this.currentProgress >= 1) {
      if (this.opts.loop) {
        this.firstFrame = this.now();
      } else {
        this.isPlaying = false;
      }
    }

    this.onLoaded();
  }

  private async drawFirstSkottieFrame(skiaCanvas: Canvas, offScreenCanvas: HTMLCanvasElement) {
    this._enterFrame$.next({ frame: 0 });
    this.clearCanvas(skiaCanvas);
    this.skottieAnimation.seekFrame(0);
    this.skottieAnimation.render(skiaCanvas, this.skiaBounds);
    await this.copyToCanvas(offScreenCanvas);

    this.onLoaded();
  }

  private copyToCanvas(offScreenCanvas: HTMLCanvasElement) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        const context = this.opts.canvasEl.getContext('2d');
        context.clearRect(0, 0, this.opts.canvasEl.width, this.opts.canvasEl.height);

        if (this.opts.fitOptions.animationFit === 'stretch') {
          context.drawImage(
            offScreenCanvas,
            0,
            0,
            this.skiaBounds[2],
            this.skiaBounds[3],
            0,
            0,
            this.opts.canvasEl.width,
            this.opts.canvasEl.height
          );
        } else {
          context.drawImage(offScreenCanvas, 0, 0);
        }
        resolve();
      }, MS_TO_WAIT_FOR_CANVAS_RENDER);
    });
  }

  private clearCanvas(skiaCanvas: Canvas) {
    skiaCanvas.clear([0, 0, 0, 0]);
  }

  private now() {
    return new Date().getTime();
  }

  private onLoaded() {
    if (!this.isLoaded.getValue()) {
      this.isLoaded.next(true);
    }
    if (this.isLoading.getValue()) {
      this.isLoading.next(false);
    }
  }
}
