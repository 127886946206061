import { ChangeDetectionStrategy, Component, inject, Injector, Input } from '@angular/core';
import { BRANDFETCH_BRANDING_FORM } from '../apply-branding-form.service';
import { AsyncPipe, JsonPipe, NgForOf, NgIf, NgStyle } from '@angular/common';
import { startWith } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BrandkitFormModalComponent } from '../brandkit-form-modal/brandkit-form-modal.component';
import { take } from 'rxjs/operators';
import { BrandfetchRetrieveBrand } from '@openreel/common';
import {
  ApplyBrandKitModalOutput,
  BrandkitFormModalData,
} from '../brandkit-form-modal/interfaces/brandkit-form-modal-data.interface';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'openreel-brandkit-preview-form',
  templateUrl: './brandkit-preview-form.component.html',
  styleUrls: ['./brandkit-preview-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    AsyncPipe,
    JsonPipe,
    NgIf,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    MatIconModule,
  ],
})
export class BrandkitPreviewFormComponent {
  @Input() data: BrandfetchRetrieveBrand | null = null;

  readonly form = inject(BRANDFETCH_BRANDING_FORM);
  readonly formValue$ = this.form.valueChanges.pipe(
    startWith(this.form.value),
  );

  private readonly dialog = inject(MatDialog);
  private readonly injector = inject(Injector);

  get fontControl(): FormControl {
    return this.form.get('font') as FormControl;
  }

  openFormModal(formType: 'color' | 'logo' | 'watermark'): void {
    const dialogRef = this.dialog.open<BrandkitFormModalComponent, BrandkitFormModalData, ApplyBrandKitModalOutput>(BrandkitFormModalComponent, {
      data: {
        formType,
        ...this.data,
      },
      injector: this.injector,
      width: '600px',
    });

    dialogRef.afterClosed().pipe(
      take(1),
    ).subscribe(updatedBrandKit => {
      if (!updatedBrandKit) {
        return;
      }

      const { formType, logo, watermark, color } = updatedBrandKit;

      if (formType === 'color') {
        this.form.patchValue({ color });
      } else if (formType === 'logo' || formType === 'watermark') {
        this.form.patchValue({ logo, watermark });
      }
    });
  }
}
