import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { IPopupComponent } from '../../interfaces';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
export class ModalAlertData {
  title: string;
  content: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  neverShowText?: string;
  neverShowDefault?: boolean;
  isLightThemed?: boolean;
  iconSvg?: string;
  // allow html in content.
  unsanitizedContent?: boolean;
}
export class DialogCloseData {
  value: boolean;
  isNeverShow: boolean;
}

@Component({
  selector: 'openreel-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyCheckboxModule,
  ],
})
export class GenericAlertComponent implements IPopupComponent {
  isPopup: true;
  isLightThemed = true;
  isNeverShow = new UntypedFormControl();

  constructor(
    private dialog: MatDialogRef<GenericAlertComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ModalAlertData
  ) {
    this.isPopup = true;
    this.isLightThemed = data.isLightThemed;

    if (data.neverShowDefault) {
      this.isNeverShow.setValue(!!data.neverShowDefault);
    }
  }
  onDialogCloseClicked(value: boolean) {
    this.dialog.close({ value: value, isNeverShow: this.isNeverShow.value });
  }
}
