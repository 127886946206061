export function getComputedWidth(element: HTMLElement) {
  return parseFloat(getComputedStyle(element).width);
}

export function getComputedHeight(element: HTMLElement) {
  return parseFloat(getComputedStyle(element).height);
}

export function getComputedWidthHeightRatio(element: HTMLElement) {
  return getComputedWidth(element) / getComputedHeight(element);
}

export function getPositionRelativeTo(element: HTMLElement, relativeTo?: HTMLElement) {
  const bounds = {
    x: element.offsetLeft,
    y: element.offsetTop,
    width: getComputedWidth(element),
    height: getComputedHeight(element),
  };

  let currentElement = element;
  while (currentElement.offsetParent && currentElement.offsetParent !== relativeTo) {
    currentElement = currentElement.offsetParent as HTMLElement;

    bounds.x += currentElement.offsetLeft;
    bounds.y += currentElement.offsetTop;
  }

  return bounds;
}
