import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'openreel-page-title',
  templateUrl: './openreel-page-title.component.html',
  styleUrls: ['./openreel-page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class OpenreelPageTitleComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() titleSize: number;
  @Input() subTitleSize: number;
}
