export const EVT_CLIENT_DISCONNECT = 'client_disconnect';
export const EVT_SEND_JOIN_ROOM = 'join_to_room';
export const EVT_SEND_JOIN_ROOM_ACC = 'join_to_room_via_account';
export const EVT_D2S_DIRECTOR_ONLINE = 'director_online';
export const EVT_D2S_DIRECTOR_LIVE_STATUS = 'director_live_status';
export const EVT_D2S_DIRECTOR_FORCE_RELEASE = 'director_force_release';
export const EVT_D2S_DIRECTOR_SESSION_SETTINGS_CHANGED = 'session_settings_changed';
export const EVT_D2S_DEVICE_JOIN = 'ack_device_join';
export const EVT_D2S_DEVICE_HARDWARE_PERFORMANCE = 'device_hardware_performance';
export const EVT_D2S_DEVICE_NETWORK_SPEED = 'device_network_speed';
export const EVT_D2S_FETCH_CAMERA_CONTROL = 'fetch_camera_control';
export const EVT_D2S_SET_ALL_CAMERA_SETTINGS = 'set_all_camera_settings';
export const EVT_D2S_SET_RESOLUTION = 'set_resolution';
export const EVT_D2S_SET_FPS = 'set_frame_rate';
export const EVT_D2S_FLIP_CAMERA = 'camera_flip';
export const EVT_D2S_SELECT_LENS = 'lens_select';
export const EVT_D2S_SELECT_ORIENTATION = 'orientation_select';
export const EVT_D2S_APP_BACKGROUND = 'ackappbackground';
export const EVT_D2S_AUDIO_IDENTITY = 'app_audio_identity';
export const EVT_D2S_VIDEO_IDENTITY = 'app_video_identity';
export const EVT_D2S_TELEPROMPTER_INFO = 'teleprompter_info';
export const EVT_D2S_SCRIPT_CONTENT = 'script_content';
export const EVT_D2S_SCRIPT_CONTENT_PAUSE = 'script_content_pause';
export const EVT_D2S_SCRIPT_CONTENT_RESUME = 'script_content_resume';
export const EVT_D2S_SCRIPT_CONTENT_CLOSE = 'script_content_close';
export const EVT_D2S_SCRIPT_PLAY = 'script_play_status';
export const EVT_D2S_SCRIPT_RESTART = 'script_restart';
export const EVT_D2S_SCRIPT_TOGGLE_VIEW = 'recordingortelepromting';
export const EVT_D2S_INPUT_AUDIO = 'appinputAudio';
export const EVT_D2S_FOCUS_POINT = 'focuspoint';
export const EVT_D2S_EXPOSURE_POINT = 'exposurepoint';
export const EVT_D2S_MIC_SETTINGS = 'set_mobile_mic_option';
export const EVT_D2D_DIRECTOR_MUTE = 'set_director_mic_option';
export const EVT_D2S_CHANGE_VIDEO_SOURCE = 'set_subject_video_source';

export const EVT_D2S_START_RECORDING = 'start_video';
export const EVT_D2S_STOP_RECORDING = 'stop_video';
export const EVT_D2S_UPLOAD_VIDEO_END = 'upload_video_end';
export const EVT_D2S_TRANSCODE_VIDEO_END = 'transcode_video_s3_upload_complete';
export const EVT_D2S_CANCEL_UPLOAD = 'cancel_uploading';
export const EVT_D2S_START_UPLOADING = 'start_uploading';
export const EVT_START_SPOT_PRESENTATION_RECORDING = 'start_spot_presentation_recording';

export const EVT_TRANSCODE_STATUS_CHANGE = 'video_transcoding_status_change';
export const EVT_CAPTURE_AUDIO_EXTRACT = 'capture_audio_extract_event';

export const EVT_D2S_SET_EXPOSURE = 'set_exposure';
export const EVT_D2S_SET_AUTO_EXPOSURE = 'set_auto_exposure';
export const EVT_D2S_SET_ISO = 'set_iso';
export const EVT_D2S_SET_CONTRAST = 'set_contrast';
export const EVT_D2S_SET_COLOR_TEMPERATURE = 'set_white_balance';
export const EVT_D2S_DELETE_VIDEO_REQUEST_ACK = 'ack_confirm_delete_video';
export const EVT_D2S_DELETE_VIDEO_REQUEST = 'delete_video';
export const EVT_D2S_SET_AUDIO = 'set_audio';

export const EVT_S2D_SET_AUDIO_ACK = 'set_audio_ack';
export const EVT_S2D_APP_MICROPHONE_RANGE = 'app_microphone_range';
export const EVT_S2D_BLUETOOTH_MIC_CONNECTION_CHANGE = 'subject_bluetooth_hands_free_connection_change';
export const EVT_D2A_PROFESSIONAL_AUDIO_STATUS = 'director_professional_audio_status';
export const EVT_S2D_SET_RESOLUTION_ACK = 'ack_set_resolution';
export const EVT_S2D_SET_FRAMERATE_ACK = 'ack_frameRateSet';
export const EVT_S2D_AUDIO_IDENTITY_ACK = 'ack_app_audio_identity';
export const EVT_S2D_FETCH_CAMERA_CONTROL_ACK = 'ack_fetch_camera_control';
export const EVT_S2D_INPUT_AUDIO_ACK = 'ack_appinputAudio';
export const EVT_S2D_DEVICE_JOIN = 'device_join';
export const EVT_S2D_DEVICE_HARDWARE_PERFORMANCE_ACK = 'ack_device_hardware_performance';
export const EVT_S2D_DEVICE_NETWORK_SPEED_ACK = 'ack_device_network_speed';
export const EVT_S2D_APP_BACKGROUND = 'appbackground';
export const EVT_S2D_TELEPROMPTER_INFO_ACK = 'ack_teleprompter_info';
export const EVT_S2D_SCRIPT_CONTENT_FINISH = 'ack_script_content_finish';
export const EVT_S2D_SCRIPT_CONTENT_ACK = 'ack_script_content';
export const EVT_S2D_SCRIPT_CONTENT_PAUSE_ACK = 'ack_script_content_pause';
export const EVT_S2D_SCRIPT_CONTENT_RESUME_ACK = 'ack_script_content_resume';
export const EVT_S2D_SCRIPT_CONTENT_CLOSE_ACK = 'ack_script_content_close';
export const EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK = 'ack_set_all_camera_settings';
export const EVT_S2D_MIC_SETTINGS_ACK = 'ack_set_mobile_mic_option';

export const EVT_S2D_START_RECORDING_ACK = 'ackforrecordstart';
export const EVT_S2D_STOP_RECORDING_ACK = 'ackforrecordstop';
export const EVT_S2D_CHECK_RECORDING = 'checkrecordingstatus';
export const EVT_S2D_START_UPLOADING = 'ack_start_uploading';
export const EVT_S2D_CANCEL_UPLOAD_ACK = 'ack_cncl_uplSet';
export const EVT_S2D_UPLOAD_STATUS = 'upload_video_status';
export const EVT_S2D_UPLOAD_DONE_APP = 'upload_video_end_app';
export const EVT_S2D_DELETE_VIDEO_REQUEST = 'confirm_delete_video';
export const EVT_S2D_DELETE_VIDEO_ACK = 'ack_delete_video';
export const EVT_S2D_RECORDING_COMPLETE = 'ack_recording_complete';

export const EVT_S2D_SET_EXPOSURE_ACK = 'ack_set_exposure';
export const EVT_S2D_SET_AUTO_EXPOSURE_ACK = 'ack_set_exposure';
export const EVT_S2D_SET_AUTO_EXPOSURE_IOS_ACK = 'ack_autoExposure';
export const EVT_S2D_SET_ISO_ACK = 'ack_set_iso';
export const EVT_S2D_SET_CONTRAST_ACK = 'ack_set_contrast';
export const EVT_S2D_SET_COLOR_TEMPERATURE_ACK = 'ack_set_white_balance';
export const EVT_S2D_FOCUS_POINT_ACK = 'ack_focuspoint';
export const EVT_S2D_EXPOSURE_POINT_ACK = 'ack_exposurepoint';
export const EVT_S2D_SCRIPT_PLAY_ACK = 'ack_script_play_status';
// ios sends EVT_S2D_SCRIPT_PAUSE_ACK if(play_status !== 1)
export const EVT_S2D_SCRIPT_PAUSE_ACK = 'ack_script_pause_status';
export const EVT_S2D_SCRIPT_RESTART_ACK = 'ack_script_restart';
export const EVT_S2D_SCRIPT_TOGGLE_VIEW_ACK = 'ack_recordingortelepromting';
export const EVT_S2D_FLIP_CAMERA_ACK = 'ack_flipCameraSet';
export const EVT_S2D_MOBILE_DEVICE_ALERT = 'show_device_alert';

export const EVT_D2D_START_RECORDING_ACK = 'ack_start_web_record';
export const EVT_D2D_STOP_RECORDING_ACK = 'ack_stop_web_record';
export const EVT_D2D_DIRECTOR_SCRIPT_UPDATE = 'director_script_update';
export const EVT_D2D_GUIDE_ENABLED_IDS = 'director_guide_enabled_ids';

export const EVT_REQUEST_TO_JOIN = 'request_to_join';
export const EVT_ADMISSION_RESPONSE = 'admission_response';

export const EVT_TOGGLE_PIN_STATUS = 'toggle_pin_status';

export const WEB_VIDEO_UPLOAD_STATUS = 'web_video_upload_status';
export const REMOVE_INVITEE_IMMEDIATELY = 'remove_invitee_immediately';

export const EVT_D2S_SET_UPLOAD_VIDEO_ACTIVE = 'set_upload_video_active';
export const EVT_S2D_SET_UPLOAD_VIDEO_ACTIVE_ACK = 'ack_set_upload_video_active';

export const EVT_S2D_UNSUPPORTED_BROWSER = 'unsupported_browser';
export const EVT_SUBJECT_DISCONNECTED = 'subject_disconnected';
export const EVT_TRANSCRIPT_DONE = 'transcript_done';
export const EVT_S2D_RECORDING_STOPPED_ON_ERROR = 'recording_stopped_on_error';
export const EVT_D2D_RECORDING_START_REQUEST = 'request_recording_start';
export const EVT_D2D_TELEPROMPTER_STATUS = 'teleprompter_status';
export const EVT_S2D_TELEPROMPTER_STATUS = 'teleprompter_status';

export const EVT_D2D_UPDATE_LIVE_BROADCAST = 'update_live_streaming';
export const EVT_D2D_STOP_LIVE_BROADCAST = 'stop_live_streaming';
export const EVT_D2D_STREAMING_UPDATE_PROGRESS = 'live_streaming_update_in_progress';
export const EVT_D2S_LIVE_STREAMING_IDENTITIES = 'live_streaming_identities';
export const EVT_SOCKET_EXCEPTION = 'exception';

export enum SocketAckStatus {
  SUCCESS = '1',
  FAILED = '0',
}

export interface AckResponse {
  stat: '0' | '1';
  message?: string;
}

////////////////////////////////////////////////
/// DEVICE SOCKETS
////////////////////////////////////////////////
import { PartialNextgenParticipant } from './nextgen-participant.interface';
import {
  DeviceInfo,
  Recording,
  TranscriptStatus,
  VideoChild,
  VideoShowableStatus,
  VideoTranscodingStatus,
} from './interfaces';
import { AudioDevice, VideoSource } from '../media';

export interface DeviceJoinRequest extends DeviceInfo {
  login_id: string;
}
export interface ResolutionSetAck extends AckResponse {
  value: string;
  width?: string;
  fps: string;
}
export interface FrameRateSetAck extends AckResponse {
  frame_rate: string;
}

export interface ClientDisconnect {
  name: string;
}
/*
{
  white_balance: -1,
  fps: "30";
  focus: -1;
  potrait: "";
  autoExposure: "";
  autoFocus: "";
  mbit: "12";
  stabilize: "0";
  tint: "-1";
  filter_camera_data: {
    saturation: "-1";
    vibrance: "-1";
    shadow: "-1";
    highlight: "-1";
    color_overlay: {
      color: "-1";
      value: "-1"
    };
    gamma: -1
  };
  flashlight: 0;
  zoom: 0;
  audio_sample_rate: "0";
  resolution: "720";
  crop_factor: "Ratio169";
  focus_peaking: 0;
  mobile_feed: 0;
  mobile_mic_options: 0;
}
*/
/*
{
  white_balance: -1
  fps: "15"
  focus: -1
  potrait: ""
  autoExposure: "1"
  autoFocus: "1"
  mbit: "12"
  stabilize: "0"
  tint: "-1"
  filter_camera_data: {
    saturation: "-1"
    vibrance: "-1"
    shadow: "-1"
    highlight: "-1"
    color_overlay: {
      color: "-1"
      value: "-1"
    }
    gamma: -1
  }
  flashlight: 0
  zoom: 0
  audio_sample_rate: "44.1"
  resolution: "240"
  crop_factor: "Ratio169"
  focus_peaking: 0
}
*/

interface ColorOverlay {
  color: '-1';
  value: '-1';
}

interface FilterCameraData {
  saturation: '-1';
  vibrance: '-1';
  shadow: '-1';
  highlight: '-1';
  color_overlay: ColorOverlay;
  gamma: -1;
}
export interface SetAllCameraSettingsRequest {
  white_balance: number;
  fps: string;
  focus: -1;
  potrait: '';
  // if "" then it is auto exposure, otherwise, value might be "2"
  autoExposure: string;
  autoFocus: '';
  contrast: string;
  iso: string;
  mbit: '12';
  stabilize: '0';
  tint: '-1';
  filter_camera_data: FilterCameraData;
  flashlight: 0;
  zoom: 0;
  audio_sample_rate: '44.1';
  resolution: string;
  width?: string;
  crop_factor: 'Ratio169';
  focus_peaking: 0;
  mobile_feed: 0;
  mobile_mic_options: 0;
}

export interface SetAllCameraSettingsResponse extends SetAllCameraSettingsRequest, AckResponse { }

export interface SetExposureRequest {
  value: string;
  type?: 'plus' | 'minus' | 'set';
}

export interface SetAutoExposureRequest {
  value: string;
  data?: string;
}

export type SetIsoRequest = SetExposureRequest;

export type SetContrastRequest = SetExposureRequest;

export interface SetColorTemperatureRequest {
  value: string;
}

export interface SetResolutionRequest {
  width: string;
  value: string;
  is_portrait_mode_on: boolean;
}

export interface SetFramerateRequest {
  frame_rate: string;
}

export interface AudioIdentity {
  admin_feed: number;
  update_before_record_listen_audio: number;
  audioIdentityArr: string[];
}

export interface AppInputAudio {
  data: 0 | 1;
}

export enum ProfessionalAudioSource {
  Bluetooth = 'bluetooth',
  BuiltIn = 'builtIn',
  External = 'external',
}

export interface AppMicrophoneRange {
  identity?: string;
  is_front_camera: string;
  model: string;
  network: string;
  speed: string;
  storage: string;
  storage_bytes?: string;
  browserAnimationFps?: number;
  battery_level: number;
  is_background: number;
  resolution: string;
  width?: string;
  fps: string;
  tele_script_id: string;
  mobile_mic_options?: string; //used for set internal/external mic for iOS
  sound_route?: string; //used for detect external mic for iOS
  isTeleprompterVisible: boolean;
  isPresenting: boolean;
  videoSource?: VideoSource;
  activeVideoSources?: VideoSource[];
  professional_audio_status?: null | {
    enabled: boolean;
    source: ProfessionalAudioSource;
    gain: number;
    // all available mics
    builtInMics: string[];
    // selected mic or none if bluetooth/external is used
    builtInMic: string | null;
  };
  selected_camera?: string;
  orientation?: string;
  cameras?: LensInfo[];

  //new
  audio?: ParticipantAudioProperties;
}

export interface ParticipantAudioProperties {
  proAudio: boolean;
  inputMuted: boolean;
  outputMuted: boolean;
  speakerOverride: boolean;
  gain: number;
  lavaMic: string;
  selectedInput: string;
  availableInputs: AudioDevice[];
  selectedOutput: string;
  availableOutputs: AudioDevice[];
}

export interface ParticipantAudioOptions {
  id: string;
  type?: 'internal' | 'external' | 'bluetooth';
  name: string;
}

export interface AudioSettingsRequest {
  inputMuted: boolean;
  outputMuted: boolean;
  proAudio: boolean;
  speakerOverride: boolean;
  gain: number;
  lavaMic: string;
  input: string;
  output: string;
  SessionID: string;
  identity: string;
}

export interface LiveStreamingIdentitiesRequest {
  identity: string;
  liveStreamingIdentities: string[];
}

export interface LensInfo {
  id: string;
  name: string;
  type: LensType;
  capabilities: {
    focus_lock_unlock: boolean;
    tap_focus: boolean;
    manual_focus: boolean;
    manual_exposure: boolean;
    exposure_lock_unlock: boolean;
    tap_exposure: boolean;
    temperature: boolean;
  };
  resolutions: [
    {
      resolution: number;
      fps: number[];
    }
  ];
}

export enum LensType {
  FRONT = 'front',
  BACK = 'back',
  EXTERNAL = 'external',
}

export interface DeviceHardwarePerformanceAck {
  cpu_speed?: number;
  cpu_count?: number;
  cpu_usage?: number;
  cpu_app_usage?: number;
  memory?: number;
  memory_usage?: number;
  memory_app_usage?: number;
  network_quality?: number;
}

export interface DeviceNetworkSpeedAck {
  download?: number;
  upload?: number;
}

///////////////////////////////////////////
/// RECORDING SOCKETS
///////////////////////////////////////////

export enum VideoStatus {
  RECORDING = 1,
  RECORDED = 2,
  UPLOADING = 3,
  UPLOADED = 4,
}

export interface StartVideo {
  VideoNameObj: {
    [identity: string]: string;
  };
  VideoSize: string;
  videoIdObj: {
    [identity: string]: number;
  };
  EmployeeID: string;
  file_size: number;
  resolution: string;
  status: VideoStatus;
  timer: number;
  timer_value?: number;
  sound: number;
  start_stop: number;
  fps: string;
  admin_talkback: number;
  uploadDuringRecording: string[];
  before_record_listen_audio: {
    [identity: string]: string;
  };
  videoSource: VideoSource;
  identity: string;
  uploadAuthToken: string;
  startPresentationSpotRecording?: boolean;
}

export interface PreRecordingPayload {
  videoId: number;
  videoName: string;
  videoSource: VideoSource;
  countdown: number;
  startRecordingTime: Date;
  uploadDuringRecording: string[];
  resolution: string;
  fps: string;
  uploadAuthToken: string;
  startSpotPresentationRecording?: boolean;
}

export interface AckStartVideo extends AckResponse {
  videoid: number;
  VideoName: string;
  resolution: number;
  file_size: number;
  status: VideoStatus;
  extra_info?: string;
  videoDetails?: Recording;
  // TODO: move this into video details on response
  uploadDuringRecording?: boolean;
  errorcode?: string;
}

export interface CheckRecordingStatus {
  time: string;
  videoid: number;
}

export interface DeleteVideo {
  videoid: number;
  videoname: string;
  identity: string;
  value?: '0' | '1';
}
export interface AckDeleteVideo extends AckResponse {
  videoid: number;
  videoname: string;
  identity: string;
  noSubject?: boolean;
}

export interface AckUploadCancel extends AckResponse {
  videoid: number;
  identity: string;
  videoname?: string;
}

export interface StopVideo {
  EmployeeID: string;
  videoid: number;
  status: VideoStatus;
}

export interface AckStopVideo extends AckResponse {
  status: VideoStatus;
  videoid: number;
  VideoName: string;
  file_size: number;
  video_length: number;
}
export interface StartUploading {
  videoid: number;
  status: VideoStatus;
}
export interface AckStartUploading extends AckResponse {
  videoid: number;
  status: VideoStatus;
}

export interface VideoUploadStatus {
  videoid: number;
  upload_percentage: number;
  SessionID: number;
  isLiteVideo: boolean;
}

export interface UploadVideoEndApp {
  videoid: number;
  iscorrupted: '0' | '1';
  isdeleted: '0' | '1';
}

export interface AckUploadVideoEndApp extends AckResponse {
  videoid: number;
  iscorrupted: '0';
  isdeleted: '0';
}

export interface UploadVideoEnd {
  file_size: number;
  video_length: number;
  video_filename: string;
  status: VideoStatus;
  transcoding_status: 'queued';
  resolution: string;
  videoid: string;
}

export interface TranscodeVideoS3UploadComplete {
  stream_url: string;
  s3_data: string;
  videoid: string;
}

export interface TranscodeStatus {
  SessionID: number;
  account_id: number;
  data?: VideoChild & { video: Recording; video_length: number };
  status: 'queued' | 'ready' | 'processing';
  type: 'sd_transcoding' | 'hq_transcoding';
  video_id: string;
  transcoding_percentage: number;
  preview_status?: VideoTranscodingStatus;
  hq_status?: VideoTranscodingStatus;
  video_showable_status?: VideoShowableStatus;
  video_status?: VideoTranscodingStatus;
  transcript_status?: TranscriptStatus;
  transcript?: { fullText?: string };
  isPresentationVideo: boolean;
}

export interface CaptureExtractAudioTranscodeStatus {
  state: 'queue' | 'processing' | 'ready' | 'failed';
  progress?: number;
  videoId: number;
}

export interface CancelVideoUpload {
  videoid: number;
}

export interface SetVideoUploadActive {
  videoid: number;
  active: number;
}

export interface AckSetVideoUploadActive {
  videoid: number;
  active: number;
}

//////////////////////////////////////////
/// APP SOCKETS
//////////////////////////////////////////

export interface BackgroundSet extends AckResponse {
  is_background: number;
}

export interface DirectorLiveStatus {
  is_online: '1' | '0';
}

export interface TogglePinStatus {
  ovra_user_id: string;
  status: boolean;
}

export interface AckDirectorStartVideo extends AckResponse {
  SessionID: number;
  identity: string;
  videoid: number;
  video_name: string;
  videoDetails?: Recording;
  // TODO: move this into video details on response
  uploadDuringRecording?: boolean;
  errorcode?: string;
}

export interface ExposureFocusPointRequest {
  screen: {
    X: string;
    Y: string;
  };
  focussed: {
    X: string;
    Y: string;
  };
  is_mirror_feed: number;
  is_lock: number;
}
export interface MicSettingsReqest {
  value: '0' | '1' | '2';
}

export interface ChangeVideoSourceReqest {
  source: VideoSource;
}

export interface SocketEventEffectWrapper {
  participant: PartialNextgenParticipant;
}

export interface RequestToJoinPayload {
  id: string; // loginId for collab/watcher, identity for subject
  name: string;
}

export interface AdmissionResponsePayload {
  name: string;
  status: boolean;
}
