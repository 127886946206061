import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InvitedUser, PartialNextgenParticipant } from '../../interfaces';
import { ISessionService } from '../../interfaces/session-state.interface';
import * as events from '../../interfaces/socket-events';
import {
  AppMicrophoneRange,
  FrameRateSetAck,
  ResolutionSetAck,
  SetAllCameraSettingsRequest,
  SetAutoExposureRequest,
  SetColorTemperatureRequest,
  SetContrastRequest,
  SetExposureRequest,
  SetIsoRequest,
  SocketEventEffectWrapper,
  TogglePinStatus,
  ClientDisconnect,
} from '../../interfaces/socket-events';
import { DirectorSocketService } from '../socket/director-socket.service';
import { PresentationStatus } from '@openreel/common';

const handleAppMicrophoneRange = (data: AppMicrophoneRange): PartialNextgenParticipant => ({
  audioInputEnabled: !data.audio?.inputMuted,
  audioOutputEnabled: !data.audio?.outputMuted,

  videoProperties: {
    resolution: data.resolution,
    width: data.width,
    fps: data.fps,
    videoSource: data.videoSource || 0,
    activeVideoSources: data.activeVideoSources || [],
  },

  deviceProperties: {
    micSettings: data.mobile_mic_options || '0', //deprecated
    speed: data.speed,
    isFrontCamera: data.is_front_camera === '1',
    battery0_100: setBattery(data.battery_level?.toString()),
    storageGB:
      data.identity.includes('ios') && data.storage_bytes
        ? setIosStorageInGB(data.storage_bytes)
        : setStorage(data.storage?.toString()),
    browserAnimationFps: data.browserAnimationFps,

    externalMic: data.sound_route, // TODO Observable to handle detection notification //deprecated
    // TODO Teleprompter detection
    selectedCamera: data.selected_camera,
    selectedOrientation: data.orientation || 'landscape',
    cameras: data.cameras,
  },

  teleprompterProperties: {
    isTeleprompterVisible: data.isTeleprompterVisible,
    teleprompterId: data.tele_script_id ? parseInt(data.tele_script_id, 10) : 0,
  },

  presentationProperties: {
    status: data.isPresenting ? PresentationStatus.Presenting : PresentationStatus.NotPresenting,
  },

  audio: data.audio,
});

const handleResolutionChange = (data: ResolutionSetAck): PartialNextgenParticipant => ({
  videoProperties: {
    width: data.width,
    resolution: data.value,
    fps: data.fps,
  },
  // this.videoPropertiesRefreshed();
});

const handleFpsChange = (data: FrameRateSetAck): PartialNextgenParticipant => ({
  videoProperties: {
    fps: data.frame_rate,
  },
  // this.videoPropertiesRefreshed();
});

const handleExposureChange = (data: SetExposureRequest): PartialNextgenParticipant =>
  data.value
    ? {
        videoProperties: {
          exposure: parseFloat(data.value),
        },
      }
    : {};

const handleAutoExposureLockChange = (data: SetAutoExposureRequest): PartialNextgenParticipant =>
  data.data
    ? {
        videoProperties: {
          autoExposureLock: parseFloat(data.data),
        },
      }
    : {};

const handleIsoChange = (data: SetIsoRequest): PartialNextgenParticipant => ({
  videoProperties:
    !data.type && data.value === '-1'
      ? {
          exposure: undefined,
          iso: undefined,
        }
      : {
          iso: parseFloat(data.value),
        },
});
const handleColorTemperatureChange = (data: SetColorTemperatureRequest): PartialNextgenParticipant => ({
  videoProperties: {
    colorTemperature: data.value === '1' ? undefined : parseFloat(data.value),
  },
});

const handleContrastChange = (data: SetContrastRequest): PartialNextgenParticipant => ({
  videoProperties: {
    contrast: data.value === '1' ? undefined : parseFloat(data.value),
  },
});

const handleSetAllCameraSettings = (req: SetAllCameraSettingsRequest): PartialNextgenParticipant => ({
  videoProperties: {
    fps: req.fps,
    width: req.width,
    resolution: req.resolution,
    exposure: req.autoExposure ? parseFloat(req.autoExposure) : undefined,
    iso: req.iso ? parseFloat(req.iso) : undefined,
    contrast: req.contrast ? parseFloat(req.contrast) : undefined,
    colorTemperature: req.white_balance === -1 ? undefined : req.white_balance,
  },
});

const handlePinUnpin = (payload: TogglePinStatus): Partial<InvitedUser> => ({
  ovra_sessionUser_mapping_id: +payload.ovra_user_id,
  is_pinned: payload.status,
});

const handleMuteUnmute = (payload: { participantId: number }): Partial<InvitedUser> => ({
  ovra_sessionUser_mapping_id: +payload.participantId,
});

const setBattery = (value: string | undefined) => {
  value = value || 'N/A';
  let result = parseFloat(value.replace('%', ''));
  if (isNaN(result)) {
    result = undefined;
  }
  return result;
};

const setStorage = (value: string | undefined) => {
  value = value || 'N/A';
  let result = parseFloat(value.replace('GB', ''));
  if (isNaN(result)) {
    result = undefined;
  }
  return result;
};

const setIosStorageInGB = (value: string | undefined) => {
  value = value || 'N/A';
  let result = parseFloat(value) * 0.000000001;
  if (isNaN(result)) {
    result = undefined;
  }
  return result;
};

@Injectable()
export class DirectorSocketEventsService {
  // Update Remote Participants
  public appMicrophoneRange$ = this.connectSocketEvent$(events.EVT_S2D_APP_MICROPHONE_RANGE, handleAppMicrophoneRange);
  public handleResolutionChange$ = this.connectSocketEvent$(events.EVT_S2D_SET_RESOLUTION_ACK, handleResolutionChange);
  public handleFpsChange$ = this.connectSocketEvent$(events.EVT_S2D_SET_FRAMERATE_ACK, handleFpsChange);
  public handleExposureChange$ = this.connectSocketEvent$(events.EVT_S2D_SET_EXPOSURE_ACK, handleExposureChange);
  public handleAutoExposureIos$ = this.connectSocketEvent$(
    events.EVT_S2D_SET_AUTO_EXPOSURE_IOS_ACK,
    handleAutoExposureLockChange
  );
  public handleIsoChange$ = this.connectSocketEvent$(events.EVT_S2D_SET_ISO_ACK, handleIsoChange);
  public handleColorTemperatureChange$ = this.connectSocketEvent$(
    events.EVT_S2D_SET_COLOR_TEMPERATURE_ACK,
    handleColorTemperatureChange
  );
  public handleContrastChange$ = this.connectSocketEvent$(events.EVT_S2D_SET_CONTRAST_ACK, handleContrastChange);
  public handleSetAllCameraSettings$ = this.connectSocketEvent$(
    events.EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
    handleSetAllCameraSettings
  );

  // Update Invited Users
  public handlePinUnpin$ = this.connectUserEvent$(events.EVT_TOGGLE_PIN_STATUS, handlePinUnpin);

  // Misc
  public handleDirectorMicOption$ = this.connectUserEvent$(events.EVT_D2D_DIRECTOR_MUTE, handleMuteUnmute);

  public clientDisconnect$ = this.connectSocketEvent$(events.EVT_CLIENT_DISCONNECT, (data: ClientDisconnect) => data);

  constructor(
    private directorSocket: DirectorSocketService,
    @Inject('ISessionService') private sessionService: ISessionService
  ) {}

  private connectSocketEvent$<I>(
    eventName: string,
    mapper: (data: I) => PartialNextgenParticipant
  ): Observable<SocketEventEffectWrapper> {
    return this.directorSocket.anySocketEvent$.pipe(
      filter((evt) => evt.eventName === eventName),
      map((evt) => ({
        participant: { identity: evt.from, ...mapper(evt.data as I) },
      }))
    );
  }

  private connectUserEvent$<I>(
    eventName: string,
    mapper: (data: I) => Partial<InvitedUser>
  ): Observable<Partial<InvitedUser>> {
    return this.directorSocket.anySocketEvent$.pipe(
      filter((evt) => evt.eventName === eventName),
      map((evt) => mapper(evt.data as I))
    );
  }
}
