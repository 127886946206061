import { Injectable, NgZone } from '@angular/core';
import { Animation } from '@openreel/lottie-parser';
import { Observable, fromEvent, map, of, switchMap, take } from 'rxjs';
import { FileMetadata } from './file.interface';
import { RxTextFileReader } from './file.utils';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly ngZone: NgZone) {}

  getFileMetadata(
    type: 'image' | 'video' | 'document' | 'font' | 'presentation' | 'audio',
    file: File
  ): Observable<FileMetadata> {
    switch (type) {
      case 'image':
        return this.getImageMetadata(file);
      case 'video':
        return this.getVideoMetadata(file);
      case 'document':
        return this.getDocumentMetadata(file);
      default:
        return of(null);
    }
  }

  private getImageMetadata(file: File) {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    return fromEvent(img, 'load').pipe(
      map(() => ({
        resolution: {
          width: img.width,
          height: img.height,
        },
      })),
      take(1)
    );
  }

  private getVideoMetadata(file: File) {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);

    return fromEvent(video, 'loadedmetadata').pipe(
      map(() => ({
        resolution: {
          width: video.videoWidth,
          height: video.videoHeight,
        },
        durationMs: video.duration * 1000,
      })),
      take(1)
    );
  }

  private getDocumentMetadata(file: File) {
    return RxTextFileReader(file).pipe(
      map((content) => {
        try {
          const lottie = JSON.parse(content) as Animation;

          if (lottie.w && lottie.h) {
            return {
              resolution: {
                width: lottie.w,
                height: lottie.h,
              },
            };
          }
        } catch {}

        return null;
      })
    );
  }

  stripMetadata(
    type: 'image' | 'video' | 'document' | 'font' | 'presentation' | 'audio',
    file: File
  ): Observable<File> {
    if (type !== 'image') {
      return of(file);
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    return fromEvent(img, 'load').pipe(
      switchMap(() => {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        return new Observable<File>((subscriber) => {
          canvas.toBlob((blob) => {
            const newFile = new File([blob], file.name, { type: file.type });

            this.ngZone.run(() => {
              subscriber.next(newFile);
              subscriber.complete();
            });
          });
        });
      }),
      take(1)
    );
  }
}
