import { Injectable, OnDestroy } from '@angular/core';
import { NewVideoInfo } from '@openreel/frontend/common/interfaces';
import { BehaviorSubject, Subject, zip } from 'rxjs';
import { filter, take, switchMap, shareReplay } from 'rxjs/operators';

import { AudioDevice, VideoDevice } from '../../../media';
import { Buffer } from 'buffer';
import { RecordingState } from './recorder.interfaces';

// This is needed for ts-ebml to work
(window as Window & typeof globalThis & { Buffer: typeof Buffer }).Buffer = Buffer;

// is the raw recording process. There may be different implementations of the
// recorder service. It can provide you with raw data that is recorded.
// It is also "filename-based" meaning that, it should be able to recover any
// previous video recorded with that particular name. You can delete any
// previous videos as well.
@Injectable()
export abstract class ILocalRecorderService implements OnDestroy {
  newVideoInfo: NewVideoInfo;
  lastFileName: string;
  videoPersonName: string;
  lastFileResolution: number;
  recordingState$ = new BehaviorSubject<RecordingState>(RecordingState.IDLE);
  recordingStartedAt$ = new BehaviorSubject<Date>(null);
  lastRecordingDuration$ = new BehaviorSubject<number>(null);
  lastError$ = new BehaviorSubject<Error>(null);
  // stop$: Subject<boolean>;
  protected stopSource = new Subject<{ value: true; videoId: number }>();
  protected partsStopSource = new Subject<{ value: true; videoId: number }>();
  stop$ = zip(this.stopSource, this.partsStopSource);
  protected chunkAvailableSource = new Subject<{
    localFileName: string;
    data: Blob;
    videoId: number;
  }>();
  private chunkResetSource = new BehaviorSubject<true>(true);
  chunkAvailable$ = this.chunkResetSource.pipe(switchMap(() => this.chunkAvailableSource.pipe(shareReplay(1))));
  globalVideoId: number;
  public stopRecordingOnError = new Subject<string>();

  constructor() {
    this.recordingState$.subscribe((state) => {
      console.log('Recording state is now: ' + RecordingState[state]);
    });
    window.addEventListener('unload', () => {
      this.ngOnDestroy();
    });
  }

  async ngOnDestroy() {
    await this.stopRecording();
  }

  async startRecordingStream(videoId: number = null, stream: MediaStream, fileName = null, videoPersonName = null) {
    if (!videoId) {
      this.globalVideoId = new Date().getTime();
      videoId = this.globalVideoId;
    }
    // first try to stop recording
    await this.stopRecording(videoId);
    this.lastError$.next(null);
    this.lastFileName = fileName ? fileName : this.getNewFileName('');
    this.videoPersonName = videoPersonName ? videoPersonName : 'Recording';
    this.recordingStartedAt$.next(new Date());
    this.recordingState$.next(RecordingState.STARTING_RECORDING);
    await this.doStartRecordingStream(videoId, stream, this.lastFileName, this.videoPersonName);
    await this.waitForState(RecordingState.RECORDING);
  }

  async startRecording(
    videoId: number = null,
    resolution: number,
    fps: number,
    audioDevice: AudioDevice,
    videoDevice: VideoDevice
  ) {
    if (!videoId) {
      this.globalVideoId = new Date().getTime();
      videoId = this.globalVideoId;
    }
    // first try to stop recording
    await this.stopRecording(videoId);
    this.lastError$.next(null);
    this.lastFileResolution = resolution;
    this.lastFileName = this.getNewFileName('');
    this.recordingStartedAt$.next(new Date());
    this.recordingState$.next(RecordingState.STARTING_RECORDING);
    await this.doStartRecording(fps, audioDevice, videoDevice);
    await this.waitForState(RecordingState.RECORDING);
  }
  async stopRecording(videoId: number = null) {
    if (!videoId) videoId = this.globalVideoId;
    if (this.recordingState$.value === RecordingState.RECORDING) {
      this.recordingState$.next(RecordingState.STOPPING_RECORDING);
      await this.doStopRecording(videoId);
      await this.waitForState(RecordingState.IDLE);
      const current = new Date();
      this.lastRecordingDuration$.next(current.getTime() - (this.recordingStartedAt$.value?.getTime() || 0));
      this.recordingStartedAt$.next(null);
    }
  }
  async pauseRecording(videoId: number = null) {
    if (!videoId) videoId = this.globalVideoId;
    await this.doPauseRecording(videoId);
  }
  async resumeRecording(videoId: number = null) {
    if (!videoId) videoId = this.globalVideoId;
    await this.doResumeRecording(videoId);
  }
  protected chunkReset() {
    this.chunkResetSource.next(true);
  }
  protected async waitForState(requestedState: RecordingState) {
    // Wait for recording state to be idle
    await this.recordingState$
      .pipe(
        filter((state) => state === requestedState),
        take(1)
      )
      .toPromise();
  }

  getFileNameForUpload(): string {
    return this.lastFileName;
  }

  getNewFileName(fileNameAppend: string = ''): string {
    return new Date().getTime().toString() + '_' + fileNameAppend;
  }

  protected getFileSizeMB(): Promise<number> {
    return Promise.resolve(0);
  }
  protected getFileLengthSeconds(): Promise<number> {
    return Promise.resolve(-1);
  }

  protected abstract doStartRecording(fps: number, audioDevice: AudioDevice, videoDevice: VideoDevice);
  abstract doStartRecordingStream(videoId: number, stream: MediaStream, filename: string, videoPersonName?: string);
  abstract doStopRecording(videoId: number);
  abstract doPauseRecording(videoId: number): Promise<void>;
  abstract doResumeRecording(videoId: number): Promise<void>;
  abstract testBrowserSupportForIDB(): Promise<boolean>;
}
