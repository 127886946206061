import { createSelector } from '@ngrx/store';
import { RecordingMetadata, VideoSource } from '@openreel/frontend/common';
import { selectAuthRole } from '../../auth/auth.selector';
import { RootState } from '../../root-state';
import { selectSessionDetails } from '../session.selectors';
import { RecorderState, recordingAdapter } from './interfaces/recording-state';

const selectFeature = (state: RootState) => state.session.recording;

const { selectAll } = recordingAdapter.getSelectors(selectFeature);

export const selectAllRecordings = selectAll;

export const selectRecordingVideoIds = createSelector(selectAllRecordings, (recordings) =>
  recordings?.map((recording) => recording.videoId)
);

export const selectIsRecording = createSelector(selectAllRecordings, (recordings) => {
  const isRecording =
    recordings.filter((recording) => recording.recorderState === RecorderState.RECORDING).length > 0 ? true : false;
  return isRecording;
});

export const selectRecordingVideoIdBySource = (source: VideoSource) =>
  createSelector(selectAllRecordings, (recording) => recording?.find((o) => o.videoSource === source)?.videoId);

export const selectRecordingBySource = (source: VideoSource) =>
  createSelector(selectAllRecordings, (recording) => recording.find((o) => o.videoSource === source));

export const selectRecordingById = (videoId: number) =>
  createSelector(selectAllRecordings, (recording) => recording.find((o) => o.videoId === videoId));

export const selectRecorderStateBySource = (source: VideoSource) =>
  createSelector(selectRecordingBySource(source), (recording) =>
    recording ? recording.recorderState : RecorderState.IDLE
  );

export const selectUploadDuringRecordingById = (videoId: number) =>
  createSelector(selectRecordingById(videoId), (recording) => recording?.uploadDuringRecording);

export const selectShouldUploadDuringRecordingById = (videoId: number) =>
  createSelector(
    selectUploadDuringRecordingById(videoId),
    (uploadDuringRecording) => uploadDuringRecording && uploadDuringRecording.includes('web')
  );

export const selectRecordingOrPrerecordingBySource = (source: VideoSource) =>
  createSelector(
    selectRecordingBySource(source),
    (recording) =>
      recording?.recorderState === RecorderState.RECORDING || recording?.recorderState === RecorderState.PRERECORDING
  );

export const selectRecordCountdownBySource = (source: VideoSource) =>
  createSelector(selectRecordingBySource(source), (recording) => recording?.countdown);

export const selectRecordingMetadataById = (id: number) =>
  createSelector(
    selectFeature,
    selectSessionDetails,
    selectAuthRole,
    (recordingState, sessionDetails, authRole) =>
    ({
      sessionId: sessionDetails?.session_id,
      token: recordingState.entities[id]?.uploadAuthToken,
      identity: sessionDetails?.identity,
      localFileName: recordingState.entities[id]?.videoName,
      resolution: parseInt(recordingState.entities[id]?.resolution, 10),
      videoId: recordingState.entities[id]?.videoId,
      role: authRole,
      fileNameForUpload: recordingState.entities[id]?.videoName + '.webm',
      uploadDuringRecording: recordingState.entities[id]?.uploadDuringRecording?.includes('web'),
      videoSource: recordingState.entities[id]?.videoSource,
    } as RecordingMetadata)
  );

export const selectLastRecording = createSelector(selectAllRecordings, (recordings) =>
  recordings.length > 0 ? recordings[recordings.length - 1] : null
);

export const selectRecorderState = createSelector(selectLastRecording, (recordings) =>
  recordings ? recordings.recorderState : RecorderState.IDLE
);

export const selectRecordingStartTime = createSelector(
  selectLastRecording,
  (recordings) => recordings?.startRecordingTime
);

export const selectRecordingVideoId = createSelector(selectLastRecording, (recordings) => recordings?.videoId);

export const selectRecordCountdown = createSelector(selectLastRecording, (recordings) => recordings?.countdown);

export const selectTotalOngoingRecordings = createSelector(selectAllRecordings, (recordings) => {
  return recordings?.length || 0;
});

export const selectPrerecordingStateRecordings = createSelector(selectAllRecordings, (recordings) =>
  recordings.filter((recording) => recording?.recorderState === RecorderState.PRERECORDING)
);

export const selectOneRecordingStateRecording = createSelector(selectAllRecordings, (recordings) =>  
  recordings?.find((o) => o.recorderState === RecorderState.RECORDING)
);

export const selectCurrentRecorderState = createSelector(selectAllRecordings, (recordings) =>{
  const isAnyInRecordingState = recordings.find(x=>x.recorderState === RecorderState.RECORDING);
  if(isAnyInRecordingState){
    return RecorderState.RECORDING;
  }else{
    const lastRecording = recordings.length > 0 ? recordings[recordings.length - 1] : null;
    return lastRecording?.recorderState || RecorderState.IDLE;
  }
});