import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const setCustomErrorStatus = (fc: UntypedFormControl, error: boolean, msg: string) => {
  if (error) {
    fc.setErrors({ incorrect: true, message: msg });
    fc.markAllAsTouched();
    fc.markAsDirty();
  } else {
    fc.setErrors({ incorrect: false });
    fc.updateValueAndValidity();
    fc.markAsPristine();
    fc.markAsUntouched();
  }
};

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function noWhitespaceValidator(control: UntypedFormControl) {
  const isWhitespace = (control.value || '').replace(/[\n\r\s+]/g, '').length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

export function requiredIfAsync(filter$: Observable<boolean>) {
  return (formControl: AbstractControl) =>
    filter$.pipe(map((filter) => (filter ? Validators.required(formControl) : null)));
}

export function requiredIf(predicate) {
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}
