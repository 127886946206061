<div fxLayout="column" fxLayoutGap="12px">
  <div class="section-header" fxLayoutAlign="space-between center" [formGroup]="form">
    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="5px">
      <h2 class="headline-h400 title">Embed video</h2>
      <p class="paragraph-p100 subtitle">Use this code to embed the video into another website</p>
    </div>

    <div fxLayoutAlign="flex-start center" fxLayoutGap="5px">
      <mat-slide-toggle
        color="primary"
        formControlName="embedEnabled"
        labelPosition="before"
        class="embed-toggle"
        [matTooltip]="disabledToggleTooltip"
        [matTooltipDisabled]="embedEnabledControl.enabled"
      >
        <h2 data-cy="toggle-embed" class="headline-h300 title embed-title">
          {{ embedEnabledControl.value ? 'Active' : 'Inactive' }}
        </h2>
      </mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="12px" *ngIf="embedEnabledControl.value" @fadeIn @heightCollapse>

    <mat-divider></mat-divider>

    <div class="code-snippet-controls" @fadeIn>
      <mat-radio-group [formControl]="responsivenessControl">
        <mat-radio-button disableRipple [value]="true" class="responsive-option paragraph-p200">Responsive size</mat-radio-button>
        <mat-radio-button disableRipple [value]="false" class="paragraph-p200">Fixed size</mat-radio-button>
      </mat-radio-group>

      <button mat-stroked-button class="copy-button" (click)="copyEmbed($event)">
        <mat-icon class="copy-button-icon">add_link</mat-icon> Copy embed code
      </button>
    </div>

    <div class="code-snippet" @fadeIn @heightCollapse>
      <mat-form-field class="embed-url" appearance="outline">
        <textarea class="paragraph-p100" [attr.data-cy]="embedLinkControl.value" matInput [formControl]="embedLinkControl" readonly></textarea>
      </mat-form-field>
    </div>

    <div
      *ngIf="fixedSizeEnabled"
      [formGroup]="embedVideoSizingForm"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="15px"
      @fadeIn
      @heightCollapse
    >
      <div class="size-input-wrapper" fxLayout="column" fxFlex="100%">
        <h2 class="headline-h400 title">Width (pixels)</h2>

        <mat-form-field class="form-share-text size-input" appearance="outline">
          <input matInput type="number" formControlName="width" class="size-input">
        </mat-form-field>
      </div>

      <div class="size-input" fxLayout="column" fxFlex="100%">
        <h2 class="headline-h400 title size-title">Height (pixels)</h2>

        <mat-form-field class="form-share-text size-input" appearance="outline">
          <input matInput type="number" formControlName="height" class="size-input">
        </mat-form-field>
      </div>
    </div>

    <div
      class="usage-details"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="4px"
      *ngIf="shareModalData$ | async as hostableDetails"
      @fadeIn
      @heightCollapse
    >
      <span class="paragraph-p200">You are using {{ embedVideoCount$ | async }} of {{ hostableDetails.embedLimit }} embedded videos.</span>

      <a
        *ngIf="isRootAdmin"
        [routerLink]="['', 'settings']"
        [queryParams]="{ tab: 'seats' }"
        class="paragraph-p200 plans-link"
      >
        View plans
      </a>
    </div>
  </div>
</div>
