import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export enum SelfSignupUIVersion {
    V1 = 'V1',
    V2 = 'V2'
}

export const GUEST_USER_ACCOUNT_INFO = 'GUEST_USER_ACCOUNT_INFO';
export const GUEST_USER_COMPANY_WEBSITE = 'guest_user_company_website';
export const SELF_SIGNUP_UI_VERSION = new InjectionToken<Observable<SelfSignupUIVersion>>('SELF_SIGNUP_UI_VERSION');
export const USE_SELF_SINGUP_UI_V2 = new InjectionToken<boolean>('USE_SELF_SIGNUP_UI_V2');