<h2 mat-dialog-title>{{title ?? 'Play Video'}}</h2>

<mat-dialog-content>
    <openreel-hosting-video-viewer
        [source]="source" 
        [sourceData]="sourceData"
        [analytics]="analytics"
    ></openreel-hosting-video-viewer>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>