import { Directive, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertEvent,
  OpenreelAlertContainerComponent,
} from '@openreel/frontend/common/components/openreel-alert-banner-container/openreel-alert-container.component';
import { HostingAnalyticsService } from '../hosting-analytics.service';
import { BannerType, HostingAnalyticsEvent, HostingAnalyticsEvents } from '../hosting-analytics-events';

@Directive({
  selector: 'openreel-alert-container[alertEventCapture]',
  standalone: true,
})
export class AlertEventCaptureDirective {
  host = inject(OpenreelAlertContainerComponent);
  analyticsService = inject(HostingAnalyticsService);
  router = inject(Router);

  @HostListener('alertEvent', ['$event'])
  onAlertEvent(e: AlertEvent): void {
    if (!e.alert.name) return;
    const analyticsEvent = this._getAnalyticsEvent(e);
    if (!analyticsEvent) return;
    this.analyticsService.logEvent(analyticsEvent);
  }

  private _getAnalyticsEvent(e: AlertEvent): HostingAnalyticsEvent | null {
    switch (e.event) {
      case 'clickAction':
        return HostingAnalyticsEvents.bannerUpgradeClicked({ banner_type: e.alert.name as BannerType });
      case 'clickClose':
        return HostingAnalyticsEvents.bannerDismissed({ banner_type: e.alert.name as BannerType });
      default:
        return null;
    }
  }
}
