function isContainerCaretBeforeDescendant(container: Node, containerCaretOffset: number, descendant: Node) {
  let directDescendant = descendant;
  while (directDescendant.parentNode !== container) {
    directDescendant = directDescendant.parentNode as Node;
  }

  let descendantOffset = 0;
  while (directDescendant.previousSibling) {
    directDescendant = directDescendant.previousSibling as Node;
    descendantOffset++;
  }

  return containerCaretOffset <= descendantOffset;
}

export function isSelectionLeftToRight(selection: {
  anchorNode: Node;
  anchorOffset: number;
  focusNode: Node;
  focusOffset: number;
}) {
  if (!selection.anchorNode || !selection.focusNode) {
    return false;
  }

  const compare = selection.anchorNode.compareDocumentPosition(selection.focusNode);
  if (compare === 0) {
    return selection.anchorOffset <= selection.focusOffset;
  }
  // eslint-disable-next-line no-bitwise
  if (compare & Node.DOCUMENT_POSITION_CONTAINS) {
    return !isContainerCaretBeforeDescendant(selection.focusNode, selection.focusOffset, selection.anchorNode);
  }
  // eslint-disable-next-line no-bitwise
  if (compare & Node.DOCUMENT_POSITION_CONTAINED_BY) {
    return isContainerCaretBeforeDescendant(selection.anchorNode, selection.anchorOffset, selection.focusNode);
  }

  // eslint-disable-next-line no-bitwise
  if (compare & Node.DOCUMENT_POSITION_FOLLOWING) {
    return true;
  }

  return false;
}

export function getChildAfterCaret(container: Node, caretOffset: number, predicate?: (child: Node) => boolean) {
  const children = container.childNodes;

  if (caretOffset >= children.length) {
    return null;
  }

  for (let child = children.item(caretOffset); child; child = child.nextSibling) {
    if (predicate && !predicate(child)) {
      continue;
    }

    return child;
  }

  return null;
}

export function getChildBeforeCaret(container: Node, caretOffset: number, predicate?: (child: Node) => boolean) {
  const children = container.childNodes;

  if (caretOffset === 0) {
    return null;
  }

  for (let child = children.item(caretOffset - 1); child; child = child.previousSibling) {
    if (predicate && !predicate(child)) {
      continue;
    }

    return child;
  }

  return null;
}
