import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { commonenv } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  UserListRequest,
  UserListResponse,
  CreateTeamRequest,
  TeamResponse,
  TeamListRequest,
  TeamListResponse,
  TeamDetailsResponse,
  MyTeam,
  TeamUserResponse,
  TeamPermissionableEntity,
  TeamUserWithApprovalWorflow,
} from '../../interfaces/team.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  public urls = {
    getuser: commonenv.nextGenApiUrl + 'user/list',
    createTeam: commonenv.nextGenApiUrl + 'teams/create',
    teamList: commonenv.nextGenApiUrl + 'teams/list',
    myTeams: commonenv.nextGenApiUrl + 'teams/mine',
    permissionableEntities: commonenv.nextGenApiUrl + 'teams/list/permissionable',
    permissionableEntitiesByIds: commonenv.nextGenApiUrl + 'teams/list/permissionable-by-id',
    teamUsers: commonenv.nextGenApiUrl + 'teams/users',
    allTeamUsers: commonenv.nextGenApiUrl + 'teams/users/all',
    teamAllUsersForApprovals: commonenv.nextGenApiUrl + 'teams/users/approvals',
    getTeam: commonenv.nextGenApiUrl + 'teams',
    updateTeam: commonenv.nextGenApiUrl + 'teams/update',
    deleteTeam: commonenv.nextGenApiUrl + 'teams/delete',
  };
  constructor(public httpClient: HttpClient) {}
  getUserList(data: UserListRequest) {
    let params = new HttpParams()
      .set('limit', data.limit.toString())
      .set('team_id', data.team_id.toString())
      .set('page', data.page.toString());

    if (data.search_string) {
      params = params.set('search_string', data.search_string);
    }

    return this.httpClient.get<UserListResponse>(this.urls.getuser, { params: params });
  }
  createTeam(data: CreateTeamRequest) {
    return this.httpClient.post<TeamResponse>(this.urls.createTeam, data);
  }
  getTeamList(data: TeamListRequest) {
    const teamlistUrl =
      this.urls.teamList + '?limit=' + data.limit + '&page=' + data.page + '&team_name=' + data.team_name;
    return this.httpClient.get<TeamListResponse>(teamlistUrl);
  }

  getMyTeams() {
    return this.httpClient.get<MyTeam[]>(this.urls.myTeams);
  }

  getPermissionableEntitiesByIds(userIds: number[], groupIds: number[]): Observable<TeamPermissionableEntity[]> {
    return this.httpClient.get<TeamPermissionableEntity[]>(`${this.urls.permissionableEntitiesByIds}`, {
      params: {
        userIds,
        groupIds,
      },
    })
  }

  getPermissionableEntities(limit: number, page: number, query: string): Observable<TeamPermissionableEntity[]> {
    return this.httpClient.get<TeamPermissionableEntity[]>(`${this.urls.permissionableEntities}`, {
      params: {
        limit,
        page,
        query: encodeURIComponent(query),
      },
    })
  }

  getTeamUsers(
    limit: number,
    page: number,
    query: string,
    hostingEnabledUsers?: boolean
  ): Observable<TeamUserResponse> {
    return this.httpClient.get<TeamUserResponse>(this.urls.teamUsers, {
      params: {
        limit,
        page,
        query: encodeURIComponent(query),
        hostingEnabledUsers,
      },
    });
  }

  getAllTeamUsers(query: string): Observable<TeamUserResponse> {
    return this.httpClient.get<TeamUserResponse>(this.urls.allTeamUsers, {
      params: {
        query: encodeURIComponent(query),
      },
    });
  }

  getTeamAllUsersWithApprovalWorkflow(query: string, canBeApprovalApprover?: boolean): Observable<TeamUserWithApprovalWorflow[]> {
    return this.httpClient.get<TeamUserWithApprovalWorflow[]>(this.urls.teamAllUsersForApprovals, {
      params: {
        query: encodeURIComponent(query),
        canBeApprovalApprover
      },
    });
  }

  getTeamDetails(id: number) {
    const teamdetailsUrl = this.urls.getTeam + '/' + id;
    return this.httpClient.get<TeamDetailsResponse>(teamdetailsUrl);
  }
  updateTeam(data: CreateTeamRequest) {
    const teamupdateUrl = this.urls.updateTeam + '/' + data.id;
    return this.httpClient.put<TeamResponse>(teamupdateUrl, data);
  }
  deleteTeam(id: number) {
    const teamdeleteUrl = this.urls.updateTeam + '/' + id;
    return this.httpClient.delete<TeamResponse>(teamdeleteUrl);
  }
}
