import { CtaFormModel } from './hosting-interfaces';

export enum HostableTypes {
  WorkflowVideo = 'workflow_video',
  CaptureVideo = 'video',
  CaptureVideoChild = 'video_child',
  UserUpload = 'user_upload',
  Webinar = 'webinar',
}

export enum HostablePermissionTypes {
  Public = 'public',
  Private = 'private',
  Password = 'password',
}

export enum HostableVideoStatuses {
  NotStarted = 'not_started',
  Rendering = 'rendering',
  Queued = 'queued',
  Done = 'done',
  Failed = 'failed',
  Canceled = 'cancelled',
}

export const CAPTION_LANGUAGE_MAP = {
  'en-US': 'English',
  'es-ES': 'Spanish',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'fr-FR': 'French',
  'hi-IN': 'Hindi',
  'zh-CN': 'Mandarin',
};

export enum HostableCtaType {
  TextButton = 'textButton',
  ImageButton = 'imageButton',
}

export const EXTENSION_UPLOAD_PROGRESS_STORAGE_KEY = 'extension-upload-progress';

export const CLOSE_EMBED_UPGRADE_BAR = 'close_embed_bar';

export const CTA_FORM_DEFAULT_VALUE: CtaFormModel = {
  id: null,
  buttonText: null,
  linkUrl: '',
  position: 'top-left',
  timeFrame: {
    start: 0,
    end: 0,
  },
};


export type CommentableType = 'hostable' | 'approval' | 'creator_project';
export type CommentAccessibility = 'internal' | 'public';
export type CommentableSortOptions = 'createdAt' | 'timecodeMs';
