import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IOpenreelPresentationFacade, Slide } from '@openreel/ui/openreel-presentations';
import {
  clearPresentation,
  setPresentingStatus,
  setSelectedSlide,
  togglePresentationEnabledDevices,
} from '../../app-state/session/presentation/presentation.actions';
import { combineLatest, filter, first, map, take, tap } from 'rxjs';
import {
  selectCurrentSlide,
  selectEnabledDevicesIdentityExceptMine,
  selectPresentationSlides,
  selectPresenterName,
} from '../../app-state/session/presentation/presentation.selectors';
import { PresentationStatus } from '@openreel/common';
import {
  selectRecordingStartTime,
  selectRecordingVideoIdBySource,
} from '../../app-state/session/recording/recording.selectors';
import {
  selectIsPresenting,
  selectPresentationParticipants,
} from '../../app-state/session/participants/participant.selectors';
import { GenericAlertService, VideoSource } from '@openreel/frontend/common';
import { stopRecording } from '../../app-state/session/recording/recording.actions';

@Injectable()
export class PresentationService implements IOpenreelPresentationFacade {
  constructor(private store: Store, private alertService: GenericAlertService) { }

  isLastSlide$ = combineLatest([
    this.store.select(selectPresentationSlides),
    this.store.select(selectCurrentSlide),
  ]).pipe(
    map(([slides, currentSlide]) => ({ currentIndex: slides.indexOf(currentSlide), slides })),
    map(({ currentIndex, slides }) => currentIndex === slides.length - 1)
  );

  isFirstSlide$ = combineLatest([
    this.store.select(selectPresentationSlides),
    this.store.select(selectCurrentSlide),
  ]).pipe(
    map(([slides, currentSlide]) => ({ currentIndex: slides.indexOf(currentSlide), slides })),
    map(({ currentIndex }) => currentIndex === 0)
  );

  presentationParticipants$ = this.store.select(selectPresentationParticipants);
  isPresenting$ = this.store.select(selectIsPresenting);
  presenterName$ = this.store.select(selectPresenterName);

  setCurrentSlide(slide: Slide) {
    combineLatest([
      this.store.select(selectPresentationSlides),
      this.store.select(selectCurrentSlide),
      this.store.select(selectRecordingStartTime),
    ])
      .pipe(
        map(([slides, currentSlide, recordingStartTime]) => {
          const totalSeconds = Math.abs(
            Math.floor((new Date().getTime() - (recordingStartTime?.getTime() || 0)) / 1000)
          );
          return {
            currentIndex: slides.indexOf(currentSlide),
            slides,
            recordTotalDuration: totalSeconds,
          };
        }),
        first(),
        tap(({ slides, currentIndex, recordTotalDuration }) =>
          this.store.dispatch(
            setSelectedSlide({
              slideSelectType: 'direct_click',
              slide,
              recordingDuration: recordTotalDuration,
              previousAssetId: slides[currentIndex].assetId,
            })
          )
        )
      )
      .subscribe();
  }

  present(identities?: string[]) {
    this.store.dispatch(setPresentingStatus({ status: PresentationStatus.Presenting, identities }));
  }

  stop(identities?: string[]) {
    this.store.dispatch(setPresentingStatus({ status: PresentationStatus.NotPresenting, identities }));
    this.store
      .pipe(
        select(selectRecordingVideoIdBySource(VideoSource.PRESENTATION)),
        take(1),
        filter((recording) => Boolean(recording)),
        tap((recording) => {
          this.store.dispatch(stopRecording({ videoId: recording }));
        })
      )
      .subscribe();
  }

  reset() {
    combineLatest([
      this.store.select(selectPresentationSlides),
      this.store.select(selectCurrentSlide),
      this.store.select(selectRecordingStartTime),
    ])
      .pipe(
        map(([slides, currentSlide, recordingStartTime]) => {
          const totalSeconds = Math.abs(
            Math.floor((new Date().getTime() - (recordingStartTime?.getTime() || 0)) / 1000)
          );
          return {
            currentIndex: slides.indexOf(currentSlide),
            slides,
            recordTotalDuration: totalSeconds,
          };
        }),
        first(),
        tap(({ slides, currentIndex, recordTotalDuration }) =>
          this.store.dispatch(
            setSelectedSlide({
              slideSelectType: 'reset',
              slide: slides[0],
              recordingDuration: recordTotalDuration,
              previousAssetId: slides[currentIndex].assetId,
            })
          )
        )
      )
      .subscribe();
  }

  previous() {
    combineLatest([
      this.store.select(selectPresentationSlides),
      this.store.select(selectCurrentSlide),
      this.store.select(selectRecordingStartTime),
    ])
      .pipe(
        map(([slides, currentSlide, recordingStartTime]) => {
          const totalSeconds = Math.abs(
            Math.floor((new Date().getTime() - (recordingStartTime?.getTime() || 0)) / 1000)
          );
          return {
            currentIndex: slides.indexOf(currentSlide),
            slides,
            recordTotalDuration: totalSeconds,
          };
        }),
        first(),
        filter(({ currentIndex }) => currentIndex > 0),
        tap(({ slides, currentIndex, recordTotalDuration }) =>
          this.store.dispatch(
            setSelectedSlide({
              slideSelectType:'previous',
              slide: slides[currentIndex - 1],
              recordingDuration: recordTotalDuration,
              previousAssetId: slides[currentIndex].assetId,
            })
          )
        )
      )
      .subscribe();
  }

  next() {
    combineLatest([
      this.store.select(selectPresentationSlides),
      this.store.select(selectCurrentSlide),
      this.store.select(selectRecordingStartTime),
    ])
      .pipe(
        map(([slides, currentSlide, recordingStartTime]) => {
          const totalSeconds = Math.abs(
            Math.floor((new Date().getTime() - (recordingStartTime?.getTime() || 0)) / 1000)
          );
          return {
            currentIndex: slides.indexOf(currentSlide),
            slides,
            recordTotalDuration: totalSeconds,
          };
        }),
        first(),
        filter(({ currentIndex, slides }) => currentIndex < slides.length - 1),
        tap(({ slides, currentIndex, recordTotalDuration }) =>
          this.store.dispatch(
            setSelectedSlide({
              slideSelectType:'next',
              slide: slides[currentIndex + 1],
              recordingDuration: recordTotalDuration,
              previousAssetId: slides[currentIndex].assetId,
            })
          )
        )
      )
      .subscribe();
  }

  clear() {
    this.alertService
      .openAlertModal({
        title: 'Remove Presentation',
        content: 'Are you sure you want to remove the current presentation?',
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Remove',
      })
      .pipe(
        filter((data) => data.value),
        take(1),
        tap(() => {
          this.store.dispatch(clearPresentation());
        })
      )
      .subscribe();
  }

  devicesStatusChange(identities: string[], enabled: boolean, forAll = false) {
    this.store.select(selectEnabledDevicesIdentityExceptMine).pipe(take(1)).subscribe(x => {
      if (!forAll && !enabled && x.length === 1) {
        forAll = true;
      }
      this.store.dispatch(togglePresentationEnabledDevices({ enabled, identities, forAll }));
    });
  }

}
