import { TemplateTeleprompterScript } from './template-teleprompter-scripts.interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateTeleprompterScriptsService {
  private url = (templateSettingsId: number) =>
    `${commonenv.nextGenApiUrl}workflows/templates-settings/${templateSettingsId}/teleprompter-scripts`;

  constructor(private readonly httpClient: HttpClient) {}

  getTemplateScripts(templateSettingsId: number) {
    return this.httpClient.get<TemplateTeleprompterScript[]>(this.url(templateSettingsId));
  }

  getTemplateTeleprompterScripts(templateSettingsId: number) {
    return this.httpClient.get<TemplateTeleprompterScript[]>(
      `${commonenv.nextGenApiUrl}workflows/projects/${templateSettingsId}/teleprompter-scripts`
    );
  }

  createUpdateScript(templateSettingsId: number, script: TemplateTeleprompterScript) {
    if (script.id) {
      return this.httpClient.put<TemplateTeleprompterScript[]>(this.url(templateSettingsId) + '/' + script.id, script);
    } else {
      return this.httpClient.post<TemplateTeleprompterScript[]>(this.url(templateSettingsId), script);
    }
  }

  deleteScript(templateSettingsId: number, scriptId: number) {
    return this.httpClient.delete<TemplateTeleprompterScript[]>(this.url(templateSettingsId) + '/' + scriptId);
  }
}
