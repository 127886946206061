<openreel-alert-banner
  *ngFor="let alert of alerts"
  [message]="alert.message"
  [type]="alert.type"
  [buttonText]="alert.buttonText"
  [buttonSuffixIcon]="alert.buttonSuffixIcon"
  [buttonPrefixIcon]="alert.buttonPrefixIcon"
  (actionButtonClick)="actionButtonClick(alert)"
  (closeButtonClick)="closeButtonClick(alert)"
></openreel-alert-banner>
