import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { cacheValue } from '@openreel/frontend/common';

interface Speed {
  bps?: number;
  kbps?: number;
  mbps?: number;
}
@Injectable({
  providedIn: 'root',
})
export class InternetSpeedService {
  public urls = {
    upload: commonenv.nextGenApiUrl + 'speed-test/upload/5MB',
    download: commonenv.nextGenApiUrl + 'speed-test/download/5MB',
  };

  downloadSize = 5200000;
  uploadSize = 5200000;
  randData: string | undefined;

  startSpeedTest$ = new Subject();

  private speed$ = this.startSpeedTest$.pipe(
    exhaustMap(() =>
      this.getDownloadSpeed().pipe(
        exhaustMap((downloadSpeed) =>
          this.getUploadSpeed().pipe(map((uploadSpeed) => ({ download: downloadSpeed, upload: uploadSpeed })))
        ),
        catchError(() => of({ download: { bps: -1, kbps: -1, mbps: -1 }, upload: { bps: -1, kbps: -1, mbps: -1 } }))
      )
    )
  );
  private cachedSpeed$ = this.getDownloadSpeed().pipe(
    exhaustMap((downloadSpeed) =>
      this.getUploadSpeed().pipe(map((uploadSpeed) => ({ download: downloadSpeed, upload: uploadSpeed })))
    ),
    catchError(() => of({ download: { bps: -1, kbps: -1, mbps: -1 }, upload: { bps: -1, kbps: -1, mbps: -1 } })),
    cacheValue(30000)
  );

  constructor(public httpClient: HttpClient) {}

  getCachedSpeed() {
    return this.cachedSpeed$;
  }

  getSpeed() {
    return this.speed$;
  }

  private randomString(length: number) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  private calulateSpeed(startTime: number): Speed {
    const endTime: number = new Date().getTime();
    const duration: number = (endTime - startTime) / 1000;
    const bitsLoaded: number = this.downloadSize * 8;
    const speedBps: number = bitsLoaded / duration;
    const speedKbps: number = speedBps / 1024;
    const speedMbps: number = speedKbps / 1024;
    return {
      bps: speedBps,
      kbps: speedKbps,
      mbps: speedMbps,
    };
  }

  private getDownloadSpeed() {
    return this.httpClient.get<{ data: string }>(this.urls.download).pipe(
      mergeMap(({ data }) => {
        const downStartTime = new Date().getTime();
        return this.httpClient.get(data, { responseType: 'text' }).pipe(map(() => this.calulateSpeed(downStartTime)));
      })
    );
  }

  private getUploadSpeed() {
    return this.httpClient.get<{ data: string }>(this.urls.upload).pipe(
      mergeMap(({ data }) => {
        this.randData = this.randData ?? this.randomString(this.uploadSize);
        const formData = new FormData();
        const blob = new Blob([this.randData], { type: 'text' });
        formData.set('key', 'upload-test-file-5mb-' + new Date().getTime());
        formData.append('file', blob);
        const upStartTime = new Date().getTime();
        return this.httpClient.put(data, formData).pipe(map(() => this.calulateSpeed(upStartTime)));
      })
    );
  }
}
