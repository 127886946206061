import { getLayerFromId } from '../helpers/timelines.helpers';
import { LayersDataChangedEvent } from '../interfaces/element.interfaces';
import { cloneDeep } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLayersDataEvent {
  data: LayersDataChangedEvent;
}

export class UpdateLayersDataCommand extends WorkflowBaseBuilder<UpdateLayersDataEvent> {
  run({ data }: UpdateLayersDataEvent) {
    const newData = cloneDeep(data);
    for (const [layerId, changes] of Object.entries(newData)) {
      const layerFromId = getLayerFromId(layerId, this.source);
      if (!layerFromId) {
        return this.error(`Layer with id ${layerId} was not found!`);
      }
      const { layer } = layerFromId;
      this.applyLayerChanges(layer, changes);
    }

    return this.ok();
  }
}
