import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { EMPTY, Observable, Subject, of, takeUntil } from 'rxjs';
import { HostingVideoPlaybackService } from '../../services';
import { VideoPlaybackData } from '../../hosting-interfaces';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HostingVideoPlayerModule } from '../../video-player/video-player.module';
import { HostingPlayerEventCaptureDirective, InitHostingInternalAnalyticsDirective, InitHostingPublicAnalyticsDirective } from '../../analytics';
import { HostingVideoViewerSource, HostingVideoViewerSourceData, HostingVideoAnalyticsType } from './hosting-video-viewer.types';

@Component({
  selector: 'openreel-hosting-video-viewer',
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    HostingVideoPlayerModule,
    HostingPlayerEventCaptureDirective,
    InitHostingInternalAnalyticsDirective,
    InitHostingPublicAnalyticsDirective,
  ],
  templateUrl: './hosting-video-viewer.component.html',
  styleUrls: ['./hosting-video-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostingVideoViewerComponent implements OnInit, OnDestroy {
  @Input() source!: HostingVideoViewerSource;
  @Input() sourceData!: HostingVideoViewerSourceData;
  @Input() analytics?: HostingVideoAnalyticsType;

  private readonly hostingPlaybackService = inject(HostingVideoPlaybackService);
  private readonly cdr = inject(ChangeDetectorRef);

  loading: boolean = true;
  playbackData: VideoPlaybackData = null;

  private unsub$ = new Subject<void>();

  ngOnInit(): void {
    this._fetchPlaybackData();
  }

  ngOnDestroy(): void {
      this.unsub$.next();
      this.unsub$.complete();
  }

  private _fetchPlaybackData() {
    let playbackData$: Observable<VideoPlaybackData> = EMPTY;

    if(this.source == 'videoId') playbackData$ = this.hostingPlaybackService.getVideoData(this.sourceData as number);
    else if(this.source == 'videoToken') playbackData$ = this.hostingPlaybackService.getVideoPlaybackDataByToken(this.sourceData as string);
    else if(this.source == 'playbackData') playbackData$ = of(this.sourceData as VideoPlaybackData)

    playbackData$.pipe(takeUntil(this.unsub$)).subscribe(data => {
        this.loading = false;
        this.playbackData = data;
        this.cdr.detectChanges();
    })
  }
}
