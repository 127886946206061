import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class AccessibeService {
  constructor() {}

  addWidget() {
    const h = document.head || document.getElementsByTagName('head')[0] || document.body;

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';

    s.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: true,
        hideTrigger: true,
        disableBgProcess: false,
        language: 'en',
        position: 'left',
        leadColor: '#146FF8',
        triggerColor: '#146FF8',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'bottom',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'left',
          triggerPositionY: 'bottom',
          triggerOffsetX: 20,
          triggerOffsetY: 20,
          triggerRadius: '20', 
        },
      });
    };
    h.appendChild(s);
  }
}
