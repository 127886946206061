import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@openreel/ui';
import { OpenreelAlertComponent } from './openreel-alert.component';
import { AlertService } from './openreel-alert.service';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [OpenreelAlertComponent],
  exports: [OpenreelAlertComponent],
  providers: [AlertService],
})
export class OpenreelAlertModule {}
