<h1 mat-dialog-title>
  {{ title }}
  <button
    mat-button
    class="dialog-close-button"
    style="float: right"
    (click)="closePopup()"
  >
    <mat-icon color="primary">close</mat-icon>
  </button>
</h1>

<div
  mat-dialog-content
  fxLayout="column"
  fxLayoutGap="5px"
  class="dialog-content"
  [class.loading]="isLoading"
>
  <video #target class="video-js" controls preload="none" playsinline></video>

  <mat-spinner
    diameter="50"
    *ngIf="isLoading"
  ></mat-spinner>
</div>
