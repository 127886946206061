import { HttpEventType, HttpUploadProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetUploadCredentialsOptions,
  UploaderBaseService,
} from 'libs/ui/src/components/openreel-uploader/openreel-uploader.component';
import { filter, map } from 'rxjs/operators';
import { AssetsService } from '../assets/assets.service';

@Injectable({ providedIn: 'root' })
export class UploaderService extends UploaderBaseService<string> {
  constructor(private readonly assetsService: AssetsService) {
    super();
  }

  getUploadCredentials({ type, extension, fileSize, fileResolution, feature }: GetUploadCredentialsOptions) {
    return this.assetsService.createAssetUrl({ type, extension, fileSize, fileResolution, feature }).pipe(
      map((response) => ({
        id: response.id,
        credential: response.upload_url.url,
      }))
    );
  }

  upload(credentials: string, file: File) {
    return this.assetsService.uploadAsset(credentials, file).pipe(
      filter((event) => event.type === HttpEventType.UploadProgress),
      map(({ loaded, total }: HttpUploadProgressEvent) => ({ loaded, total }))
    );
  }
}
