import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { HostingVideoViewerComponent } from '../../components/hosting-video-viewer/hosting-video-viewer.component';
import { HostingVideoViewerDialogData } from './hosting-video-viewer-dialog.interface';

@Component({
  selector: 'openreel-hosting-video-viewer-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    HostingVideoViewerComponent,
  ],
  templateUrl: './hosting-video-viewer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostingVideoViewerDialogComponent {
  isLightThemed = true;
  isPopup = true;

  private readonly dialogData: HostingVideoViewerDialogData = inject(MAT_DIALOG_DATA);

  title = this.dialogData.title;
  source = this.dialogData.source;
  sourceData = this.dialogData.sourceData;
  analytics = this.dialogData.analytics;
}
