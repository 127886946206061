export const LINKEDIN_MINIMUM_VIDEO_DURATION_SECONDS = 3;
export const LINKEDIN_MAXIMUM_VIDEO_DURATION_SECONDS = 30 * 60;
export const LINKEDIN_MAXIMUM_VIDEO_SIZE_BYTES = 500 * 1024 * 1024;

export enum LinkedInPostVisibility {
  PUBLIC = 'PUBLIC',
  CONNECTIONS = 'CONNECTIONS',
}

export const MULTIPART_UPLOAD_MIN_VIDEO_BYTE_SIZE = 50 * 1024 * 1024 + 1;
