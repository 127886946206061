import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { DrawEventType, DrawableArea, DrawableShape } from "../../resources/drawable.types";
import { Subject, takeUntil, withLatestFrom } from "rxjs";
import { fromSizeOfHtmlElement } from "../../../rxjs-utils/from-element-resize";
import { DRAWABLE_SHAPES_CREATOR_MANAGER } from "../../resources/drawable.constants";
import { AsyncPipe } from "@angular/common";
import { DrawableKonvaWrapper } from "../../resources/drawable-konva-wrapper";
import { DrawableShapeAttributes } from "@openreel/common";

@Component({
    selector: 'or-drawable-shapes-creator',
    templateUrl: './drawable-shapes-creator.component.html',
    styleUrls: ['./drawable-shapes-creator.component.scss'],
    standalone: true,
    imports: [AsyncPipe]
})
export class DrawableShapesCreatorComponent implements OnInit, OnDestroy {
    @ViewChild('container', { static: true })
    container!: ElementRef<HTMLDivElement>;

    manager = inject(DRAWABLE_SHAPES_CREATOR_MANAGER);

    isActiveDrawMode$ = this.manager.isActiveDrawMode$;

    private _drawableArea: DrawableArea | null = null;
    private _drawableShape: DrawableShape<DrawableShapeAttributes> | null = null;

    private unsub$ = new Subject<void>();

    ngOnInit(): void {
        this._drawableArea = DrawableKonvaWrapper.create(this.container.nativeElement);
        this._subscribeDrawResult();
        this._subscribeDrawEvents();
        this._subscribeContainerResizeEvent();
    }

    ngOnDestroy(): void {
        this._drawableArea?.destroy();
        this.unsub$.next();
        this.unsub$.complete();
    }

    private _subscribeDrawResult() {
        this.manager.drawResult$
            .pipe(takeUntil(this.unsub$))
            .subscribe(drawResult => {
                if(drawResult) return;
                this._drawableShape = null;
                this._drawableArea?.clearShapes();
            })
    }

    private _subscribeDrawEvents() {
        this._drawableArea?.draw$.pipe(
            takeUntil(this.unsub$),
            withLatestFrom(this.manager.drawableShapeType$, this.manager.drawableShapeColor$),
        ).subscribe(([event, shape, color]) => {
            switch(event.type) {
                case DrawEventType.StartDrawing:
                    this._drawableShape = this._drawableArea!.drawShape(shape);
                    this._drawableShape?.setColor(color);
                    this._drawableShape?.setStartPoint(event.position!);
                    break;
                case DrawEventType.MovePointer:
                    this._drawableShape?.setEndPoint(event.position!);
                    break;
                case DrawEventType.CompleteDrawing:
                    const result = this._drawableArea?.toJSON();
                    this.manager.completeDraw(result);
                    break;
                default: break;
            }
        });
    }

    private _subscribeContainerResizeEvent() {
        fromSizeOfHtmlElement(this.container.nativeElement)
            .pipe(takeUntil(this.unsub$), withLatestFrom(this.manager.drawResult$))
            .subscribe(([size, drawResult]) => {
                this._drawableArea.clearShapes();
                this._drawableArea.setSize(size.width, size.height);
                if(!drawResult) return;
                this._drawableArea.drawFromJSON(drawResult);
            })
    }
}