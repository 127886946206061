import { addEditableAsset } from './../helpers';
import { LayerOptions, Section, SectionLayer, SectionType, WorkflowDataDto } from './../interfaces/workflow.interfaces';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

export function createSection(sectionType: SectionType) {
  const section: Section = {
    timelines: [],
    groups: [],
    sectionType,
    sectionDuration: 0,
    backgroundColor: { type: 'solid', color: '#000000' },
    audio: { muted: false, volume: 1 },
  };

  return section;
}

export function createSectionLayer(sectionId: string, layerId: string = '') {
  const sectionLayer: LayerOptions & SectionLayer = {
    layerId: layerId || v4(),
    enabled: true,
    loop: false,
    type: 'section',
    sectionId,
  };

  return sectionLayer;
}

export function getBaseWorkflow(sectionsToCreate: { sectionId: string; sectionType: SectionType }[]) {
  const baseTemplate: WorkflowDataDto = {
    assets: [],
    styles: [],
    features: {
      layouts: {
        styles: {},
      },
    },
    sections: {},
    timelines: [
      {
        type: 'main',
        layers: [],
      },
    ],
    globalSettings: {
      resolution: { width: 1920, height: 1080 },
      logo: { uploaded: [], settings: { assetId: null, enabled: false } },
      watermark: { uploaded: [], settings: { assetId: null, enabled: false } },
      backgroundAsset: { uploaded: [], settings: { assetId: null, enabled: false } },
      soundtrack: { introVolume: 80, mainVolume: 20, outroVolume: 80, assetId: null },
      backgroundColor: { type: 'solid', color: '#000000' },
      placeholders: { interviewAssetIds: {}, presentationAssetIds: {} },
    },
  };

  const mainTimeline = baseTemplate.timelines.find((t) => t.type === 'main');
  for (const { sectionId, sectionType } of sectionsToCreate) {
    const section = createSection(sectionType);
    const sectionLayer = createSectionLayer(sectionId);
    baseTemplate.sections[sectionId] = section;
    mainTimeline.layers.push(sectionLayer);
  }

  addEditableAsset(baseTemplate);

  return cloneDeep(baseTemplate);
}

export function getBaseTemplateWorkflow() {
  const baseTemplate: WorkflowDataDto = {
    assets: [],
    styles: [],
    features: {
      layouts: {
        styles: {},
      },
    },
    sections: {},
    templateSections: {},
    timelines: [
      {
        type: 'main',
        layers: [],
      },
    ],
    globalSettings: {
      resolution: { width: 1920, height: 1080 },
      logo: { uploaded: [], settings: { assetId: null, enabled: false } },
      watermark: { uploaded: [], settings: { assetId: null, enabled: false } },
      backgroundAsset: { uploaded: [], settings: { assetId: null, enabled: false } },
      backgroundColor: { type: 'solid', color: '#000000' },
      soundtrack: { introVolume: 80, mainVolume: 20, outroVolume: 80, assetId: null },
      placeholders: { interviewAssetIds: {}, presentationAssetIds: {} },
    },
  };

  return cloneDeep(baseTemplate);
}
