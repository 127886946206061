<h2 mat-dialog-title class="create-title">Add to Library</h2>

<mat-dialog-content>
    <form [formGroup]="createForm">
        <div class="form-field-title">Title<sup>*</sup></div>
        <mat-form-field class="create-input" appearance="outline">
            <input matInput formControlName="title">
            <mat-error class="error-message" *ngIf="createForm.get('title').hasError('required')">
                Title is required.
            </mat-error>
            <mat-error class="error-message" *ngIf="createForm.get('title').hasError('maxlength')">
                Title cannot be more than 100 characters long.
            </mat-error>
        </mat-form-field>

        <div class="form-field-title">URL Slug<sup>*</sup> (Cannot be changed after creation) <or-slug-info-icon></or-slug-info-icon></div>
        <mat-form-field class="create-input" appearance="outline">
            <input matInput formControlName="slug" slugField [errorStateMatcher]="errorMatcher">
            <mat-error class="error-message" *ngIf="createForm.get('slug').hasError('required')">
                Slug is required.
            </mat-error>
            <mat-error class="error-message" *ngIf="createForm.get('slug').hasError('maxlength')">
                Slug cannot be more than 100 characters long.
            </mat-error>
        </mat-form-field>

        <ng-container *ngIf="!(sharingFeatureEnabled$ | async)">
          <div class="form-field-title">Permissions<sup>*</sup></div>
          <mat-form-field class="create-input"  appearance="outline">
              <mat-select formControlName="permission" class="form-field-input" appearance="outline">
                  <mat-option value="private" data-cy="add-private">Private</mat-option>
                  <mat-option value="public" data-cy="add-public">Public</mat-option>
              </mat-select>
          </mat-form-field>
        </ng-container>

        <div class="form-field-title">Description</div>
        <mat-form-field class="create-input" appearance="outline">
            <textarea matInput
                formControlName="description"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="8"></textarea>
                <mat-error *ngIf="createForm.get('description').hasError('maxlength')" class="error-message">
                    Description cannot be more than 1000 characters long.
                </mat-error>
        </mat-form-field>

        <div class="form-field-title">Alt Text</div>
        <mat-form-field class="create-input" appearance="outline">
            <input matInput formControlName="altText">
            <mat-error *ngIf="createForm.get('altText').hasError('maxlength')" class="error-message">
                Alt text cannot be more than 100 characters long.
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button [disabled]="saving" mat-flat-button color="primary" data-cy="submit-button" (click)="create()">
        Submit
    </button>
    <button [disabled]="saving" mat-stroked-button mat-dialog-close data-cy="cancel-button">
        Cancel
    </button>
    <mat-progress-bar *ngIf="saving" mode="indeterminate"></mat-progress-bar>
</mat-dialog-actions>
