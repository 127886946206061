<h2 mat-dialog-title>Videos</h2>

<mat-dialog-content *ngIf="vm$ | async as vm">
    <mat-form-field class="search-text-control" appearance="outline">
        <input matInput placeholder="Search" [formControl]="searchTextControl" />
    </mat-form-field>

    <ng-container *ngrxLet="selectedVideo$ as selectedVideo">
        <mat-spinner *ngIf="vm.isLoading" [diameter]="40"></mat-spinner>
        <ng-container *ngIf="!vm.isLoading">
            <p *ngIf="vm.videos.length == 0">No videos found</p>
            <div *ngIf="vm.videos.length > 0" class="videos-list">
                <openreel-hosting-video-card
                    *ngFor="let video of vm.videos"
                    [video]="video"
                    [enableSelection]="true"
                    [isSelected]="selectedVideo?.id == video.id"
                    (selectVideo)="toggleVideoSelection(video)"
                ></openreel-hosting-video-card>
            </div>
        </ng-container>
    </ng-container>
    
    <div>
        <ng-container *ngIf="vm.total">
            <or-pager
                (page)="loadPage($event)"
                [pageIndex]="vm.pageOptions.pageIndex"
                [length]="vm.total"
                [pageSize]="vm.pageOptions.pageSize"
                [pageSizeOptions]="[8, 12, 20, 50]"
            >
            </or-pager>
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="selectedVideoControl.pristine" (click)="confirm()">Confirm</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>