import { PresetField } from '@openreel/creator/common';
import { FillShape, GroupShapeElement, ShapeLayer } from '../interfaces/lottie.interface';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';

export class TransformerShape extends TransformerBase {
  test(fieldDef: PresetField) {
    return fieldDef.type === 'shape';
  }

  process({ animation, fieldDef, fieldValues }: TransformerProcessOptions) {
    if (!fieldDef.lottiePath) {
      return animation;
    }

    const rootNode = this.getRootNode<ShapeLayer>(animation, fieldDef.lottiePath);
    if (!rootNode || !rootNode.shapes) {
      return animation;
    }

    const fills = rootNode.shapes.filter((s) => s.ty === 'fl') as FillShape[];
    const groupShapes = rootNode.shapes.filter((s) => s.ty === 'gr') as GroupShapeElement[];
    for (const groupShape of groupShapes) {
      const groupFills = groupShape.it.filter((s) => s.ty === 'fl') as FillShape[];
      fills.push(...groupFills);
    }

    for (const fill of fills) {
      fill.c = {
        ...fill.c,
        k: fieldValues.color,
      };
    }

    return animation;
  }
}
