export enum SELECTION_TYPE {
  NONE = 'None',
  CARET = 'Caret',
  RANGE = 'Range',
}

export enum Keys {
  Shift = 'Shift',
  Escape = 'Escape',
  Backspace = 'Backspace',
  Delete = 'Delete',
  Enter = 'Enter',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Comma = ',',
  Period = '.',
  Minus = '-',
  Plus = '+',
}

export enum KeyCodes {
  Z = 'KeyZ',
  Y = 'KeyY',
}
