import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AssetPreloadingService } from '../services';
import { Cleanupable } from '../classes';
import { takeUntil } from 'rxjs';

@Directive({
  selector: '[openreelAssetImage]',
  standalone: true,
})
export class AssetImageDirective extends Cleanupable implements OnChanges {
  @Input('openreelAssetImage') assetId: number;

  constructor(private readonly elementRef: ElementRef, private readonly preloadingService: AssetPreloadingService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.elementRef.nativeElement.style.display = 'none';
    if (!('assetId' in changes)) {
      return;
    }

    if (!this.assetId) {
      this.elementRef.nativeElement.src = '';
      return;
    }

    this.showImage();
  }

  @HostListener('load') onLoad() {
    this.elementRef.nativeElement.style.display = 'inline-block';
  }
  @HostListener('error') onError() {
    this.showImage();
  }

  private showImage() {
    this.preloadingService
      .preloadAsset(this.assetId, 'or-assets', 'image')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((assetUrl) => (this.elementRef.nativeElement.src = assetUrl));
  }
}
