<div class="dark-theme video-preview">
  <openreel-navbar-no-user padding-110>
    <img class="logo" src="assets/common/images/logo-with-text.light.png" alt="Logo" />
    <span style="flex: 1 1 auto"></span>
  </openreel-navbar-no-user>

  <div class="content">
    <div class="sidebar">
      <div class="container">
        <ng-container *ngIf="uploadInfo as items">
          <mat-form-field appearance="outline" class="video-select" *ngIf="items.length">
            <mat-select
              placeholder="Select Video"
              [(ngModel)]="selectedUpload"
              (selectionChange)="onVideoSelectionChange()"
            >
              <mat-option *ngFor="let item of items" [value]="item">
                {{ item.fileNameForUpload }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="!items.length"> No videos to upload. </ng-container>
        </ng-container>

        <div *ngIf="selectedUpload" class="buttons action-btn">
          <ng-container *ngIf="selectedUpload">
            <div class="row">
              <a
                [disabled]="!recordingUrl && !uploadError.get(selectedUpload.videoId)"
                class="button"
                mat-raised-button
                [href]="recordingUrl"
                [download]="selectedUpload.fileNameForUpload"
              >
                <mat-icon class="icon" svgIcon="save"></mat-icon>
                Download
              </a>
              <button class="button" mat-raised-button (click)="upload()" [disabled]="!uploadError.get(selectedUpload.videoId)">
                <mat-icon class="icon" svgIcon="upload"></mat-icon>
                Retry Upload
              </button>
              <button class="button alert" mat-raised-button (click)="onDelete()"  [disabled]="!uploadError.get(selectedUpload.videoId)">
                <mat-icon class="icon" svgIcon="trash"></mat-icon>
                Delete
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="main-container">
      <ng-container *ngIf="selectedUpload">
        <h2 class="title">
          {{ this.videoName(selectedUpload) }}
        </h2>
        <h3 class="subtitle">Video Preview</h3>
        <div *ngIf="video$ | async">
          <openreel-player
            class="preview"
            [video$]="video$"
            [options]="{ controls: true, fluid: true }"
          ></openreel-player>
        </div>
      </ng-container>

      <h3 *ngIf="!selectedUpload" class="no-video-text">No video selected</h3>
    </div>
  </div>
</div>
