import { z } from 'zod';
import { GradientColorSchema, ProjectFontSchema, SolidColorSchema, TransitionsSchema } from './common.zod';
import { AssetSchema } from './assets.zod';

const GlobalSettingsTextOverlaysSchema = z.object({
  assetId: z.string(),
  transitions: z.optional(TransitionsSchema),
});

const GlobalSettingsAssetSchema = z.object({
  uploaded: z.array(AssetSchema),
  settings: z.object({
    assetId: z.string(),
    enabled: z.boolean(),
  }),
});

const GlobalSettingsSoundtrack = z.object({
  assetId: z.string(),
  introVolume: z.number().min(0).max(100),
  mainVolume: z.number().min(0).max(100),
  outroVolume: z.number().min(0).max(100),
});

const ResolutionSchema = z.object({
  width: z.number(),
  height: z.number(),
});

const PlaceholdersSchema = z.object({
  simpleAssetId: z.optional(z.string()),
  interviewAssetIds: z.optional(
    z.object({
      left: z.optional(z.string()),
      right: z.optional(z.string()),
    })
  ),
  presentationAssetIds: z.optional(
    z.object({
      left: z.optional(z.string()),
      right: z.optional(z.string()),
    })
  ),
});

export const GlobalSettingsSchema = z.object({
  resolution: ResolutionSchema,
  font: z.optional(ProjectFontSchema),
  primaryColor: z.optional(z.union([SolidColorSchema, GradientColorSchema])),
  backgroundColor: z.optional(z.union([SolidColorSchema, GradientColorSchema])),
  logo: GlobalSettingsAssetSchema,
  watermark: GlobalSettingsAssetSchema,
  backgroundAsset: GlobalSettingsAssetSchema,
  soundtrack: GlobalSettingsSoundtrack,
  textOverlays: z.optional(GlobalSettingsTextOverlaysSchema),
  placeholders: z.optional(PlaceholdersSchema),
});
