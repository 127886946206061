import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@openreel/ui';
import { OpenreelUploaderComponent } from './openreel-uploader.component';
import { AssetVideoDirective } from '@openreel/frontend/common/directives/asset-video.directive';
import { AssetImageDirective } from '@openreel/frontend/common/directives/asset-image.directive';

@NgModule({
  declarations: [OpenreelUploaderComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, AssetVideoDirective, AssetImageDirective],
  exports: [OpenreelUploaderComponent],
})
export class OpenreelUploaderModule {}
