import { NgModule } from '@angular/core';
import { Action, ActionReducer, INIT, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from './../../environments/environment';
import { AuthStoreModule } from './auth/auth-store.module';
import { logout } from './session/session.actions';
import { RootState } from './root-state';

export function reInitState(reducer: ActionReducer<RootState, Action>) {
  return (state, action) => {
    if (action != null && action.type === logout.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

const commonModules = [
  AuthStoreModule,
  StoreModule.forRoot(
    {
      auth: null,
      session: null,
    },
    {
      metaReducers: [reInitState],
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
      },
    }
  ),
  EffectsModule.forRoot([]),
];

// add dev tools for non prod
const imports = environment.production ? commonModules : [...commonModules, StoreDevtoolsModule.instrument({})];

@NgModule({
  imports,
})
export class RootStoreModule {}
