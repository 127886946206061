import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ShareModule } from '@openreel/frontend/common/hosting/dialogs/share/share.module';
import { ToastrModule } from 'ngx-toastr';
import { UiModule } from '@openreel/ui';
import { CreateHostableComponent } from './dialogs/create-hostable/create-hostable.component';
import { CreateEditHubComponent } from './dialogs/create-edit-hub/create-edit-hub.component';
import { AddToHubComponent } from './dialogs/add-to-hub/add-to-hub.component';
import { HostingShareComponent } from './dialogs/hosting-share/hosting-share.component';
import { HostingConfirmComponent } from './dialogs/hosting-confirm/hosting-confirm.component';
import { EmailListComponent } from './components/email-list/email-list.component';
import {
  SlugInfoIconComponent,
  PermissionSelectorComponent,
  InlineEditableComponent,
  ThumbnailInfoIconComponent,
} from './components';
import { UpdatePasswordComponent } from './dialogs/update-password/update-password.component';
import { AclModule } from '../acl/acl.module';
import { ParseDurationPipe } from '../pipes/parse-duration.pipe';
import { RelativeDatePipe } from '../pipes/relative-date.pipe';
import { FeatureFlagDirective } from '../directives/feature-flag.directive';
import { SlugFieldDirective } from '../directives/slug-field.directive';

const PUBLIC_COMPONENTS = [
  SlugInfoIconComponent,
  EmailListComponent,
  PermissionSelectorComponent,
  InlineEditableComponent,
  ThumbnailInfoIconComponent,
];

const PUBLIC_MODULES = [ShareModule];

const PRIVATE_COMPONENTS = [
  CreateHostableComponent,
  AddToHubComponent,
  CreateEditHubComponent,
  HostingShareComponent,
  HostingConfirmComponent,
  UpdatePasswordComponent,
  SlugInfoIconComponent,
  EmailListComponent,
  PermissionSelectorComponent,
  InlineEditableComponent,
  ThumbnailInfoIconComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatChipsModule,
    ClipboardModule,
    UiModule,
    ToastrModule,
    MatProgressBarModule,
    FlexLayoutModule,
    AclModule,
    ParseDurationPipe,
    RelativeDatePipe,
    FeatureFlagDirective,
    SlugFieldDirective,
    ...PUBLIC_MODULES,
  ],
  declarations: [...PUBLIC_COMPONENTS, ...PRIVATE_COMPONENTS],
  exports: [...PUBLIC_COMPONENTS, ...PUBLIC_MODULES],
})
export class HostingCommonModule {}
