import { PresetField } from '@openreel/creator/common';
import { ImageAsset } from '../interfaces/lottie.interface';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';

export class TransformerImage extends TransformerBase {
  test(fieldDef: PresetField) {
    return fieldDef.type === 'logo';
  }

  process({ animation, fieldDef, fieldValues }: TransformerProcessOptions) {
    if (!fieldDef.lottieAssetRefId) {
      return animation;
    }

    const asset = animation.assets.find((a) => a.id === fieldDef.lottieAssetRefId) as ImageAsset;
    if (fieldValues?.path) {
      asset.e = 0; // skottie in skottie2movie only supports not embedded local assets
      asset.p = fieldValues.path.substring(fieldValues.path.lastIndexOf('/') + 1);
    } else {
      asset.e = 1; // skottie in canvaskit only supports embedded assets
      asset.p = fieldValues?.dataUrl || '';
    }

    return animation;
  }
}
