/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  ChangeDetectionStrategy,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'openreel-volume-meter',
  templateUrl: './volume-meter.component.html',
  styleUrls: ['./volume-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class VolumeMeterComponent implements AfterViewInit, OnDestroy {
  @ViewChild('vmcanvas')
  volumeMeterCanvas: ElementRef<HTMLCanvasElement>;

  width: number;
  height: number;
  tickValue: number;
  vmCanvasContext: CanvasRenderingContext2D;

  private intervalSubscription = Subscription.EMPTY;

  @Input()
  set audioTrack(audioTrack: MediaStreamTrack) {
    this.intervalSubscription.unsubscribe();
    this._audioTrack = audioTrack;
    this.ngZone.runOutsideAngular(() => {
      this.initNewTrack().then();
    });
  }
  get audioTrack() {
    return this._audioTrack;
  }
  _audioTrack: MediaStreamTrack;

  @Input()
  set audioStream(stream: MediaStream) {
    this.intervalSubscription.unsubscribe();
    this._audioStream = stream;
    this.ngZone.runOutsideAngular(() => {
      this.initNewStream().then();
    });
  }
  get audioStream() {
    return this._audioStream;
  }
  _audioStream: MediaStream;

  @Input() vertical = true;

  levels = [
    {
      color: '#61d55b',
      stop: 0,
    },
    {
      color: '#61d55b',
      stop: 0.4,
    },
    {
      color: '#f5aa00',
      stop: 0.6,
    },
    {
      color: '#ea6e13',
      stop: 0.8,
    },
    {
      color: '#ff0000',
      stop: 1,
    },
  ];

  constructor(public elementRef: ElementRef, private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.setVolumeMeterCanvasSizes(), 500);
  }

  setVolumeMeterCanvasSizes() {
    this.vmCanvasContext = this.volumeMeterCanvas.nativeElement.getContext('2d');
    this.width = this.elementRef.nativeElement.offsetWidth;
    this.height = this.elementRef.nativeElement.offsetHeight;

    this.volumeMeterCanvas.nativeElement.width = this.width;
    this.volumeMeterCanvas.nativeElement.height = this.height;
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  private async initNewStream() {
    if (this._audioStream instanceof MediaStream) {
      await this.createAudioContext(this._audioStream);
    }
  }

  private async initNewTrack() {
    if (this._audioTrack instanceof MediaStreamTrack) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(this._audioTrack);
      await this.createAudioContext(mediaStream);
    }
  }

  private async createAudioContext(mediaStream: MediaStream) {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
    mediaStreamSource.connect(analyser);

    this.intervalSubscription = interval(500).subscribe(() => {
      const dataArray = new Uint8Array(analyser.fftSize);
      analyser.getByteTimeDomainData(dataArray);

      // Returns value from 0...127
      const max = Math.max(128 - Math.min(...dataArray), Math.max(...dataArray) - 128);

      this.tickValue = ((100.0 * max) / 128) * 0.9;
      this.updateTick();
    });
  }

  private drawTick() {
    const grd = this.vmCanvasContext.createLinearGradient(
      0,
      this.vertical ? this.height : 0,
      this.vertical ? 0 : this.width,
      0
    );

    for (const level of this.levels) {
      grd.addColorStop(level.stop, level.color);
    }

    // Fill with gradient
    this.vmCanvasContext.fillStyle = grd;
    this.vmCanvasContext.fillRect(0, 0, this.width, this.height);
  }

  private updateTick() {
    if (this.vmCanvasContext) {
      this.drawTick();
      if (this.vertical === false) {
        this.vmCanvasContext.clearRect((this.width * this.tickValue) / 100, 0, this.width, this.height);
      } else {
        this.vmCanvasContext.clearRect(0, 0, this.width, this.height * (1 - this.tickValue / 100));
      }
    }
  }
}
