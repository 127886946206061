import { Directive, ElementRef } from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
  selector: 'input[durationInput]',
  standalone: true,
})
export class DurationMaskDirective {
  constructor(private elementRef: ElementRef) {
    Inputmask({
      alias: 'duration',
      mask: '99:99:99',
    }).mask(this.elementRef.nativeElement);
  }
}
