import { Pipe, PipeTransform } from '@angular/core';
import { SocialShareType } from './social-share-type';

@Pipe({
  name: 'socialShareUrl',
  standalone: true,
})
export class SocialShareUrlPipe implements PipeTransform {

  transform(shareUrl: string, socialShareType: SocialShareType): string {
    switch (socialShareType) {
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${ encodeURIComponent(shareUrl) }`;
      case 'x':
        return `https://x.com/intent/post?url=${ encodeURIComponent(shareUrl) }`;
      case 'linkedin':
        return `https://www.linkedin.com/shareArticle?mini=true&url=${ encodeURIComponent(shareUrl) }`;
      default:
        return shareUrl;
    }
  }

}
