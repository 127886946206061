import { DBSchema, deleteDB, IDBPDatabase, openDB } from 'idb';
import { from, Observable, forkJoin, of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

const DB_VERSION = 1;
const CHUNK_KEY = 'chunks' as never;

interface ChunkRecordingSchema<T> extends DBSchema {
  chunks: {
    key: number;
    value: T;
  };
}

export class DbAdapter<T> {
  private count = 0;
  private memDb: { [key: number]: T } = {};
  constructor(private db?: IDBPDatabase<ChunkRecordingSchema<T>>) {}

  private convertToMem() {
    return this.getAllKeys().pipe(
      switchMap((keys) =>
        keys.length ? forkJoin(keys.map((k) => this.get(+k).pipe(switchMap((v) => this._memPut(v, +k))))) : of([])
      ),
      switchMap(() => from(this.db.clear(CHUNK_KEY))),
      switchMap(() => {
        this.db.close();
        return this.deleteDb();
      }),
      tap(() => (this.db = undefined))
    );
  }

  private handleIdbPutError(value: T) {
    return (obs) =>
      obs.pipe(
        catchError((e) => {
          console.error('IDB PUT ERROR, FALL BACK TO IN MEM');
          Sentry.captureException(e, { tags: { detail: 'IDB PUT ERROR, CONVERT TO IN MEM' } });
          return this.convertToMem().pipe(switchMap(() => this._memPut(value)));
        })
      );
  }

  getAllKeys(): Observable<IDBValidKey[]> {
    if (this.db) {
      return from(this.db.getAllKeys(CHUNK_KEY));
    }
    return of(Object.keys(this.memDb));
  }

  getAll(): Observable<T[]> {
    if (this.db) {
      return from(this.db.getAll(CHUNK_KEY));
    }
    return of(Object.values(this.memDb));
  }

  getByKeys(partKeys: number[]): Observable<T[]> {
    return forkJoin(partKeys.map((k) => this.get(k)));
  }

  private _memPut(value: T, k?: number) {
    this.count += 1;
    this.memDb[k ?? this.count] = value;
    return of(k ?? this.count);
  }

  put(value: T): Observable<number> {
    if (this.db) {
      return from(this.db.put(CHUNK_KEY, value)).pipe(this.handleIdbPutError(value));
    }
    return this._memPut(value);
  }

  get(key: number): Observable<T> {
    if (this.db) {
      return from(this.db.get(CHUNK_KEY, key));
    }
    return of(this.memDb[+key]);
  }

  delete(key: number) {
    if (this.db) {
      return from(this.db.delete(CHUNK_KEY, key));
    }
    this.memDb[key] = undefined;
    delete this.memDb[key];
    return of(undefined);
  }

  close() {
    if (this.db) {
      this.db.close();
    }
    this.memDb = undefined;
  }

  clear() {
    if (this.db) {
      this.db.clear(CHUNK_KEY);
    }
    this.memDb = {};
  }

  deleteDb() {
    if (this.db) {
      return from(deleteDB(this.db.name));
    }
    return of(undefined);
  }

  static initDb<T>(dbKey: string, useIdb: boolean) {
    if (useIdb) {
      return from(
        openDB<ChunkRecordingSchema<T>>(dbKey, DB_VERSION, {
          upgrade: (db) => {
            db.createObjectStore(CHUNK_KEY, { autoIncrement: true });
          },
        })
      ).pipe(
        map((db) => new DbAdapter<T>(db)),
        catchError((e) => {
          console.error('IDB CREATE ERROR, FALL BACK TO IN MEM');
          Sentry.captureException(e, { tags: { detail: 'IDB CREATE ERROR, FALLBACK TO IN MEM' } });
          return of(new DbAdapter<T>());
        })
      );
    }
    return of(new DbAdapter<T>());
  }
}
