import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandKitDto } from './brand-kit.interfaces';
import { BrandKitApplyBrandfetchDto } from '@openreel/common';
import { commonenv } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandKitService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/brand-kits`,
    byId: (brandKitId: number) => `${commonenv.nextGenApiUrl}workflows/brand-kits/${brandKitId}`,
    forAccount: `${commonenv.nextGenApiUrl}workflows/brand-kits/for-account`,
    applyBrandfetchBranding: `${commonenv.nextGenApiUrl}workflows/brand-kits/apply-branding`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getBrandKitForAccount() {
    return this.httpClient.get<BrandKitDto>(this.urls.forAccount);
  }

  updateBrandKit(brandKitId: number, brandKit: BrandKitDto) {
    return this.httpClient.put<BrandKitDto>(this.urls.byId(brandKitId), brandKit);
  }

  applyBranding(data: BrandKitApplyBrandfetchDto): Observable<void> {
    return this.httpClient.post<void>(`${this.urls.applyBrandfetchBranding}`, data);
  }
}
