import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AssetPreloadingService } from '../services';
import { Cleanupable } from '../classes';
import { takeUntil } from 'rxjs';

@Directive({
  selector: '[openreelAssetVideo]',
  standalone: true,
})
export class AssetVideoDirective extends Cleanupable implements OnChanges {
  @Input('openreelAssetVideo') assetId: number;
  @Input() assetVideoProvider?: 'or-assets' | 'or-recordings';

  constructor(private readonly elementRef: ElementRef, private readonly preloadingService: AssetPreloadingService) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!('assetId' in changes)) {
      return;
    }

    if (!this.assetId) {
      this.elementRef.nativeElement.src = '';
    } else {
      const provider = this.assetVideoProvider ?? 'or-assets';

      this.preloadingService
        .preloadAsset(this.assetId, provider, 'video')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((assetUrl) => (this.elementRef.nativeElement.src = assetUrl));
    }
  }
}
