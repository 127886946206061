import { createAction, props } from '@ngrx/store';
import { Slide } from './interfaces/presentation.interface';
import { Presentation, PresentationStatus } from '@openreel/common';

const context = '[Presentation]';

export const setPresentation = createAction(
  `${context} Set Presentation`,
  props<{ presentation: Presentation, currentSlide?: { url: string; assetId: number } }>()
);

export const setSelectedSlide = createAction(
  `${context} Set Selected Slide`,
  props<{ slide: Slide; recordingDuration: number; previousAssetId: number, slideSelectType?: 'next' | 'previous' | 'reset' | 'direct_click' }>()
);
export const clearPresentation = createAction(`${context} Clear Presentation`);

export const setSelectedSlideSuccess = createAction(`${context} Set Selected Slide Success`, props<{ slide: Slide }>());

export const setPresentingStatus = createAction(
  `${context} Set Presenting Status`,
  props<{ status: PresentationStatus; originalAssetId?: number; identities?: string[] }>()
);

export const setPresentingStatusSuccess = createAction(
  `${context} Set Presenting Status Success`,
  props<{ status: PresentationStatus; isPresenter: boolean; originalAssetId?: number; fileMeta?: { name: string } }>()
);

export const cancelPresentationProcessing = createAction(`${context} Cancel Presentation Processing`);

export const cancelPresentationUploading = createAction(`${context} Cancel Presentation Uploading`);

export const presentationUploadingStarted = createAction(`${context} Presentation Uploading Started`);

export const presentationUploadingFailed = createAction(`${context} Presentation Uploading Failed`);

export const togglePresentationEnabledDevices = createAction(
  `${context} Toggle presentation enabled devices`,
  props<{ identities: string[]; enabled: boolean, forAll?: boolean }>()
);