import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AssetId } from '@openreel/creator/common';
import { FileMetadata, FileService } from '@openreel/frontend/common';
import { last } from 'lodash';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  firstValueFrom,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FileInfo, FileUploadStatus, OpenreelUploadManager, UploadState } from '../openreel-upload-manager';
import {
  FileValidatorBaseService,
  UPLOADER_SERVICE_TOKEN,
  UploadCredentials,
  UploaderBaseService,
  VALIDATOR_SERVICE_TOKEN,
} from '../openreel-uploader.component';
import { splitNameAndExtension } from '@openreel/common';

export interface ParallelUploadFileInfo extends FileInfo {
  uid: string;
  status: FileUploadStatus;
  metadata?: FileMetadata;
  sourceId?: number | string;
  progress: number;
  error?: string;
}

export interface ParallelUploadState extends UploadState {
  uploadingFiles: ParallelUploadFileInfo[];
  remainingFiles: ParallelUploadFileInfo[];
}

const INITIAL_STATE: ParallelUploadState = {
  remainingFiles: [],
  uploadingFiles: [],
  isUploadInProgress: false,
  status: FileUploadStatus.NotStarted,
  progress: 0,
};

@Injectable()
export class OpenreelParallelUploadManager extends OpenreelUploadManager<ParallelUploadState> {
  private readonly beforeUploadStart = new Subject<ParallelUploadFileInfo[]>();
  readonly beforeUploadStart$ = this.beforeUploadStart.asObservable();

  private readonly fileStatusChanged = new Subject<ParallelUploadFileInfo>();
  readonly fileStatusChanged$ = this.fileStatusChanged.asObservable();

  public readonly isUploadInProgress$ = this.select((state) =>
    state.uploadingFiles.some(
      (file) =>
        ![
          FileUploadStatus.UploadCompleted,
          FileUploadStatus.UploadCanceled,
          FileUploadStatus.UploadFailed,
          FileUploadStatus.ValidationFailed,
        ].includes(file.status)
    )
  );

  constructor(
    @Inject(UPLOADER_SERVICE_TOKEN) uploaderService: UploaderBaseService<UploadCredentials>,
    @Inject(VALIDATOR_SERVICE_TOKEN) @Optional() fileValidatorService: FileValidatorBaseService,
    protected readonly fileService: FileService
  ) {
    super(uploaderService, fileValidatorService, fileService);

    this.setState(INITIAL_STATE);
  }

  private getUploadingFile(uid: string) {
    return this.select((state) => state.uploadingFiles.find((file) => file.uid === uid)).pipe(take(1));
  }

  private readonly movePendingFileToUploading = this.updater((state, uid: string) => {
    const index = state.remainingFiles.findIndex((rf) => rf.uid === uid);
    const file = state.remainingFiles[index];

    const remainingFiles = [...state.remainingFiles];
    remainingFiles.splice(index, 1);

    const uploadingFiles: ParallelUploadFileInfo[] = [...state.uploadingFiles, file];

    return { ...state, remainingFiles, uploadingFiles };
  });

  private readonly setFileStatus = this.updater((state, { uid, status }: { uid: string; status: FileUploadStatus }) => {
    const index = state.uploadingFiles.findIndex((rf) => rf.uid === uid);
    const file = state.uploadingFiles[index];

    const uploadingFiles = [...state.uploadingFiles];
    uploadingFiles.splice(index, 1, { ...file, status });

    return { ...state, uploadingFiles };
  });

  private readonly setFileMetadata = this.updater(
    (state, { uid, file, metadata }: { uid: string; file: File; metadata: FileMetadata }) => {
      const index = state.uploadingFiles.findIndex((rf) => rf.uid === uid);
      const fileInfo = state.uploadingFiles[index];

      const uploadingFiles = [...state.uploadingFiles];
      uploadingFiles.splice(index, 1, {
        ...fileInfo,
        file,
        metadata,
      });

      return { ...state, uploadingFiles };
    }
  );

  private readonly setFileSourceId = this.updater((state, { uid, sourceId }: { uid: string; sourceId: AssetId }) => {
    const index = state.uploadingFiles.findIndex((rf) => rf.uid === uid);
    const fileInfo = state.uploadingFiles[index];

    const uploadingFiles = [...state.uploadingFiles];
    uploadingFiles.splice(index, 1, {
      ...fileInfo,
      sourceId,
    });

    return { ...state, uploadingFiles };
  });

  private readonly setFileUploadProgress = this.updater(
    (state, { uid, progress }: { uid: string; progress: number }) => {
      const index = state.uploadingFiles.findIndex((rf) => rf.uid === uid);
      const fileInfo = state.uploadingFiles[index];

      const uploadingFiles = [...state.uploadingFiles];
      uploadingFiles.splice(index, 1, {
        ...fileInfo,
        progress,
      });

      return { ...state, uploadingFiles };
    }
  );

  private readonly setFileError = this.updater((state, { uid, message }: { uid: string; message: string }) => {
    const index = state.uploadingFiles.findIndex((rf) => rf.uid === uid);
    const file = state.uploadingFiles[index];

    const uploadingFiles = [...state.uploadingFiles];
    uploadingFiles.splice(index, 1, { ...file, error: message });

    return { ...state, uploadingFiles };
  });

  private readonly setUploaderStatus = this.updater(
    (state, { status, sourceId }: { status: FileUploadStatus; sourceId: AssetId }) => ({
      ...state,
      status,
      sourceId,
    })
  );

  private readonly resetUploader = this.updater(() => INITIAL_STATE);

  protected override _addFiles = this.updater((state, fileInfos: FileInfo[]) => ({
    ...state,
    remainingFiles: [
      ...state.remainingFiles,
      ...fileInfos.map((file) => ({
        ...file,
        uid: uuidv4(),
        status: FileUploadStatus.NotStarted,
        progress: 0,
      })),
    ],
  }));

  protected override _startUpload = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      withLatestFrom(this.remainingFiles$),
      switchMap(([_, files]: [void, ParallelUploadFileInfo[]]) => {
        if (!files.length) {
          return;
        }

        this.setUploaderStatus({ status: FileUploadStatus.UploadStarted, sourceId: null });
        this.beforeUploadStart.next(files);

        return of(...files).pipe(mergeMap((file) => this.doUploadFile(file.uid)));
      })
    )
  );

  private setFileStatusAndTrigger(uid: string, status: FileUploadStatus) {
    this.setFileStatus({ uid, status });

    this.getUploadingFile(uid).subscribe((fileInfo) => this.fileStatusChanged.next(fileInfo));
  }

  private setFileMetadataAndStatus(uid: string, file: File, metadata: FileMetadata) {
    this.setFileMetadata({ uid, file, metadata });
    this.setFileStatusAndTrigger(uid, FileUploadStatus.MetadataReceived);
  }

  private setFileSourceIdAndStatus(uid: string, sourceId: AssetId) {
    this.setFileSourceId({ uid, sourceId });
    this.setFileStatusAndTrigger(uid, FileUploadStatus.SourceIdAssigned);
  }

  private setFileUploadProgressAndStatus(uid: string, progress: number) {
    this.setFileUploadProgress({ uid, progress });
    this.setFileStatusAndTrigger(uid, FileUploadStatus.UploadProgress);
  }

  private doUploadFile(uid: string) {
    this.movePendingFileToUploading(uid);
    this.setFileStatusAndTrigger(uid, FileUploadStatus.ValidationStarted);

    return this.getUploadingFile(uid).pipe(
      switchMap((fileInfo) =>
        this.validate(fileInfo).pipe(
          tap({
            next: () => this.setFileStatusAndTrigger(uid, FileUploadStatus.ValidationCompleted),
            error: (error: Error) => {
              this.setFileError({ uid, message: error?.message });
              this.setFileStatusAndTrigger(uid, FileUploadStatus.ValidationFailed);
            },
          }),
          switchMap(() => this.getUploadingFile(uid))
        )
      ),
      switchMap((fileInfo) =>
        this.generateMetadata(fileInfo).pipe(
          tap(([metadata, file]) => this.setFileMetadataAndStatus(uid, file, metadata)),
          switchMap(() => this.getUploadingFile(uid))
        )
      ),
      switchMap((fileInfo) =>
        this.fetchCredentials(fileInfo, fileInfo.metadata).pipe(
          tap({
            next: (credentials) => this.setFileSourceIdAndStatus(uid, credentials.id),
            error: (err: HttpErrorResponse | Error) => {
              this.setFileError({ uid, message: this._getMessageFromError(err) });
              this.setFileStatusAndTrigger(uid, FileUploadStatus.SourceIdFailed);
            },
          }),
          switchMap((credentials) => this.getUploadingFile(uid).pipe(map((fileInfo) => ({ fileInfo, credentials }))))
        )
      ),
      switchMap(({ fileInfo, credentials }) => {
        this.setFileStatusAndTrigger(uid, FileUploadStatus.UploadStarted);

        return this._uploaderService.upload(credentials.credential, fileInfo.file, credentials.id).pipe(
          tap({
            next: (event) => {
              const progress = !event.total ? 0 : Math.round((event.loaded / event.total) * 100);
              this.setFileUploadProgressAndStatus(uid, progress);
            },
            error: (error: Error) => {
              this.setFileError({ uid, message: error?.message });
              this.setFileStatusAndTrigger(uid, FileUploadStatus.UploadFailed);
            },
            complete: async () => {
              this.setFileStatusAndTrigger(uid, FileUploadStatus.UploadCompleted);
              await this.checkUploadStatus();
            },
          })
        );
      }),
      catchError(() => EMPTY)
    );
  }

  protected fetchCredentials(fileInfo: ParallelUploadFileInfo, metadata: FileMetadata) {
    const [name, extension] = splitNameAndExtension(fileInfo.file.name);

    return this._uploaderService.getUploadCredentials({
      uid: fileInfo.uid,
      type: this._getFileType(fileInfo.type),
      extension,
      name,
      fileSize: fileInfo.file.size,
      fileResolution: metadata?.resolution,
      duration: metadata?.durationMs,
      feature: fileInfo.type,
    });
  }

  private async checkUploadStatus() {
    const isUploadInProgress = await firstValueFrom(this.isUploadInProgress$);
    const lastFile = await firstValueFrom(this.select((state) => last(state.uploadingFiles)));

    if (isUploadInProgress) {
      return;
    }

    this.setUploaderStatus({ status: FileUploadStatus.UploadCompleted, sourceId: lastFile.sourceId });
    this.resetUploader();
  }
}
