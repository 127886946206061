import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { AclMemoize } from './acl-memoize';
import { AclService } from './acl.service';
import { HostableUserPermission } from './hostable-user.permission.interface';
import { AclPermission } from './acl-permission.type';
import { toAclFunction } from './to-acl-function';
import { Operator } from './acl-operator.type';

interface AclDirectiveContext {
  $implicit(permission: AclPermission | AclPermission[] | null, operator?: Operator): boolean;
}

/**
 *
 * To use the directive create a permission context within the HTML file, example:
 * <ng-container *openreelAcl="let acl; permissions: (userPermissions$ | async)"></ng-container>
 *
 * This gives you access to the `acl()` function anywhere you want within the wrapped element file:
 * <div *ngIf="acl('read.title')"></div> or <button [disabled]="!acl('update.title')">Save</button>
 *
 * Or you can use syntax sugared version of the directive - `*hasPermission` directive:
 * <div *hasPermission="['read.title', 'read.description']; operator: 'OR'"></div>
 *
 * <div *hasPermission="['read.title', 'read.description']; operator: 'AND'; else: elseTemplate"></div>
 * <ng-template #elseTemplate>...</ng-template>
 *
 * `acl()` method accepts string as a permissions scope. Possible formats:
 * 1. `read.title` - checks if user has a `read` permission and access to the `title` field
 * 2. `get_cta` - checks if user has `get_cta` permission without checking any fields
 * 3. [read.title, read.description] - pass values as an array to specify alternative permissions.
 *
 * When you use array syntax you can use second argument which is an operator.
 * Operator can be either `AND` or `OR`. Default value is `OR`.
 *  - If 'OR' operator is used then `acl()` method will return `true` if user has at least one of the passed permissions.
 *  - If 'AND' operator is used then `acl()` method will return `true` only if user has all of the passed permissions.
 *
 * Directive uses simple memoization in order to improve performance
 *
 */
@Directive({
  selector: '[openreelAcl]',
})
export class AclDirective implements OnDestroy {
  @Input() set openreelAclPermissions(permissions: HostableUserPermission[]) {
    this.viewContainerRef.clear();
    this.aclMemoize.clear();

    const isFeatureFlagEnabled = this.aclService.isAclFeatureFlagEnabled();

    this.view = this.viewContainerRef.createEmbeddedView(this.templateRef, {
      $implicit: toAclFunction(permissions, isFeatureFlagEnabled, this.aclMemoize),
    });
  }

  private readonly aclMemoize = new AclMemoize();
  private view: EmbeddedViewRef<AclDirectiveContext>;

  get aclFunction(): (requestedPermission: AclPermission | AclPermission[], operator?: Operator) => boolean {
    return this.view.context.$implicit;
  }

  constructor(
    private readonly templateRef: TemplateRef<AclDirectiveContext>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly aclService: AclService,
  ) {
  }

  ngOnDestroy(): void {
    this.aclMemoize.clear();
  }
}
