export enum HostingCaptionsTranslationStatus {
  Processing = 'processing',
  Success = 'success',
  Failed = 'failed',
  None = 'none',
}

export enum HostingAudioExtractionStatus {
  Queued = 'queued',
  Processing = 'processing',
  Ready = 'ready',
  Failed = 'failed',
}
