import {
  TranscribeProgress,
  ApprovalStatusChange,
  WorkflowsJoinRoomSocketEvent,
  ThumbnailSpritesheetFinished,
} from '../workflow/workflow-project.interfaces';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Cleanupable } from '../../classes';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { WorkflowsSocketService } from './workflows-socket.service';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { WorkflowProjectRenderProgress } from '@openreel/creator/common';
import {
  EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID,
  EVT_WORKFLOW_APPROVAL_STATUS_CHANGE,
  EVT_WORKFLOW_RENDER_PROGRESS,
  EVT_WORKFLOW_THUMBNAILS_DONE,
  EVT_WORKFLOW_TRANSCRIBE_PROGRESS,
} from '@openreel/common';

@Injectable()
export class WorkflowProjectSocketService extends Cleanupable {
  renderProgress$: Observable<WorkflowProjectRenderProgress>;
  transcribeProgress$: Observable<TranscribeProgress>;
  thumbnailSpritesheets$: Observable<ThumbnailSpritesheetFinished>;
  approvalStatusChange$: Observable<ApprovalStatusChange>;
  rejoinSub?: Subscription;

  constructor(private readonly socket: WorkflowsSocketService) {
    super();
    this.observeRenderProgress();
    this.observeTranscribeProgress();
    this.observeThumbnailSpritesheet();
    this.observeApprovalStatusChange();
  }

  connect(sessionInfo: WorkflowsJoinRoomSocketEvent, reconnect = false) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.connect(commonenv.websocketUrl + '?access-token=' + payload.token);
    console.log('Creator - starting listening socket...');

    if (reconnect) {
      this.rejoinSub?.unsubscribe();
      this.rejoinSub = this.socket.socket.connectionStatus$
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter((status) => status.connected),
          map((status) => status.connected)
        )
        .subscribe(() => {
          this.joinRoom(sessionInfo);
        });
    } else {
      this.joinRoom(sessionInfo);
    }
  }

  joinRoom(sessionInfo: WorkflowsJoinRoomSocketEvent) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.emit(EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID, payload);
  }

  disconnect() {
    this.socket.socket.disconnect();
    this.rejoinSub?.unsubscribe();
    this.rejoinSub = null;
  }

  private observeRenderProgress() {
    this.renderProgress$ = this.socket
      .getSocketEventByName<WorkflowProjectRenderProgress>(EVT_WORKFLOW_RENDER_PROGRESS)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private observeTranscribeProgress() {
    this.transcribeProgress$ = this.socket
      .getSocketEventByName<TranscribeProgress>(EVT_WORKFLOW_TRANSCRIBE_PROGRESS)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private observeThumbnailSpritesheet() {
    this.thumbnailSpritesheets$ = this.socket
      .getSocketEventByName<ThumbnailSpritesheetFinished>(EVT_WORKFLOW_THUMBNAILS_DONE)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private observeApprovalStatusChange() {
    this.approvalStatusChange$ = this.socket
      .getSocketEventByName<ApprovalStatusChange>(EVT_WORKFLOW_APPROVAL_STATUS_CHANGE)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

  private getSessionInfoPayload(sessionInfo: WorkflowsJoinRoomSocketEvent) {
    return {
      device_type: 'WEB',
      user_type: sessionInfo.userType,
      token: sessionInfo.token,
      captureProjectId: sessionInfo.captureProjectId,
    };
  }
}
