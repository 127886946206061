<div class="upload-container" orDragOver>
  <openreel-uploader
    type="video"
    allowDragAndDrop="true"
    [options]="options"
    [extra]="extra"
    (started)="onUploadStarted($event)"
    (filesSelected)="onFilesSelected($event)"
  >
    <div class="upload-message">
      <mat-icon class="upload-icon" svgIcon="upload2"></mat-icon>
      <p>
        <span>Click to upload</span>
        <span> or drag and drop</span>
      </p>
      <p class="sub-text">.mp4, .mov or .webm (max 5GB per file)</p>
    </div>
  </openreel-uploader>

  <div class="upload-tos">
    By Uploading, you agree to OpenReel's &nbsp;<a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.openreel.com/terms-of-service/"
      >Terms of Service</a
    >
  </div>
</div>
