import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  ROUTE_DASHBOARD,
  ROUTE_PROJECTS,
  ROUTE_SETTINGS,
  ROUTE_HOST,
  AuthGuard,
  ROUTE_IMPORT_OLD_SESSIONS,
  ImportOldSessionsGuard,
  ROUTE_TEMPLATES,
  FeatureFlagGuard,
  ROUTE_ARCHIVED_PROJECTS,
  ROUTE_SEARCH,
  ROUTE_AUTOMATIONS,
  EnterprisePackageGuard,
} from '@openreel/frontend/common';
import { CAPTURE_ARCHIVE_PROJECTS, CAPTURE_ENABLE_CONVERT_OLDGEN_SESSIONS } from '@openreel/common';
import { NavigationComponent } from './navigation.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_DASHBOARD,
  },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ROUTE_AUTOMATIONS,
        loadChildren: () => import('./../automations/automations.module').then((m) => m.AutomationsModule),
      },
      {
        path: ROUTE_DASHBOARD,
        loadChildren: () => import('./../dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [EnterprisePackageGuard],
      },
      {
        path: ROUTE_TEMPLATES,
        loadChildren: () => import('./../templates/templates.module').then((m) => m.TemplatesModule),
      },
      {
        path: ROUTE_PROJECTS,
        loadChildren: () => import('./../project/project.module').then((m) => m.ProjectModule),
        canActivate: [EnterprisePackageGuard],
      },
      {
        path: ROUTE_HOST,
        loadChildren: () => import('./../hosting/hosting.module').then((m) => m.HostingModule),
      },
      {
        path: ROUTE_SEARCH,
        loadChildren: () => import('./../search/search.module').then((m) => m.SearchModule),
      },
      {
        path: ROUTE_SETTINGS,
        loadChildren: () => import('./../settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: ROUTE_IMPORT_OLD_SESSIONS,
        loadChildren: () => import('./../old-session/old-session.module').then((m) => m.OldSessionModule),
        canActivate: [ImportOldSessionsGuard, FeatureFlagGuard],
        data: {
          featureFlag: CAPTURE_ENABLE_CONVERT_OLDGEN_SESSIONS,
        },
      },
      {
        path: ROUTE_ARCHIVED_PROJECTS,
        loadChildren: () =>
          import('./../archived-projects/archived-projects.module').then((m) => m.ArchivedProjectsModule),
        canActivate: [FeatureFlagGuard],
        data: {
          featureFlag: CAPTURE_ARCHIVE_PROJECTS,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationRoutingModule {}
