import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const PRESENTATION_FACADE = new InjectionToken('PRESENTATION_FACADE');

export interface Slide {
  url: string;
  assetId: number;
}

export interface IOpenreelPresentationFacade {
  presenterName$: Observable<string>;
  present(identities?: string[]);
  stop(identities?: string[]);
  reset();
  clear();
  previous(currentIndex: number, slides: Slide[]);
  next(currentIndex: number, slides: Slide[]);
  setCurrentSlide(slide: Slide);
}
