<div class="form" *ngIf="formValue$ | async as formValue">
  <div class="colors">
    <h3 class="section-title">Primary Color</h3>

    <div class="item-wrapper color" (click)="openFormModal('color')">
      <div class="color-container">
        <div class="color-picker-wrapper">
          <div
            class="color-picker"
            *ngIf="formValue.color; else noValueSelected"
            [ngStyle]="{ 'background-color': formValue.color }"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="font" *ngIf="data.fonts.length">
    <h3 class="section-title">Font Family</h3>

    <mat-form-field appearance="outline" class="font-select">
      <mat-select placeholder="Choose a font" [formControl]="fontControl">
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let font of data.fonts" [value]="font">{{ font.family }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="container">
    <div class="logo-wrapper">
      <h3 class="section-title">Logo</h3>

      <div class="logo item-wrapper" (click)="openFormModal('logo')">
        <ng-container *ngIf="formValue.logo?.src; else noValueSelected">
          <img [src]="formValue.logo.src" class="image" alt="Logo">
        </ng-container>
      </div>
    </div>

    <div class="watermark-wrapper">
      <h3 class="section-title">Watermark</h3>

      <div class="watermark item-wrapper" (click)="openFormModal('watermark')">
        <ng-container *ngIf="formValue.watermark?.src; else noValueSelected">
          <img [src]="formValue.watermark.src" class="image" alt="Watermark">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #noValueSelected>
  <mat-icon class="empty-icon" svgIcon="cog-outline"></mat-icon>
</ng-template>
