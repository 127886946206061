import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SlugService } from '../services/slug/slug.service';
import { Cleanupable } from '../classes/cleanupable';

@Directive({
  selector: '[formControl][slugField],[formControlName][slugField]',
  standalone: true,
})
export class SlugFieldDirective extends Cleanupable implements OnInit {
  constructor(private ngControl: NgControl, private slugService: SlugService) {
    super();
  }

  ngOnInit() {
    this.mortalize(this.ngControl.control.valueChanges).subscribe((v) => this.setValue(v));
  }

  private confirmSlug() {
    const val = this.ngControl.control.value || '';
    this.setValue(val);
  }

  private setValue(text: string) {
    this.ngControl.control.setValue(this.slugService.toSlug(text), { emitEvent: false });
    this.ngControl.control.markAsTouched();
  }
}
