import { TextBoxMeasurer, TextBoxMeasurerProps } from '@openreel/creator/common';

export class TextBoxDomMeasurer implements TextBoxMeasurer {
  measureTextBox(props: TextBoxMeasurerProps) {
    if (typeof window === 'undefined') {
      throw new Error('DOM measurer requires window to be available.');
    }

    const textWrapperElement = document.createElement('div');
    textWrapperElement.style.visibility = 'hidden';
    textWrapperElement.style.pointerEvents = 'none';
    document.body.appendChild(textWrapperElement);

    const textElement = document.createElement('div');
    textElement.style.fontSize = props.fontSize + 'px';
    textElement.style.lineHeight = `${props.lineHeight}`;
    textElement.style.maxWidth = props.maxWidth + 'px';
    textElement.style.width = 'auto';
    textElement.style.display = 'inline-block';
    textElement.style.paddingRight = props.fontSize / 10 + 'px';
    textElement.style.paddingLeft = props.fontSize / 10 + 'px';
    textElement.style.position = 'fixed';
    textElement.style.overflowWrap = 'break-word';
    textElement.innerText = props.text;
    textElement.style.fontFamily = props.font.fontFamily;
    textElement.style.fontWeight = props.font.fontVariant === '700' ? '700' : '400';
    textWrapperElement.appendChild(textElement);

    const width = parseFloat(getComputedStyle(textElement).width);
    const height = parseFloat(getComputedStyle(textElement).height);
    document.body.removeChild(textWrapperElement);

    return {
      width,
      height,
    };
  }
}
