import * as Interfaces from './workflow-layer-combinations.interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkflowLayerCombinationsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}workflows/layer-combinations`,
    byId: (id: number) => `${commonenv.nextGenApiUrl}workflows/layer-combinations/${id}`,
    getAll: (dto: Interfaces.LayerCombinationsGetRequestDto) =>
      `${this.urls.base}?page=${dto.page}&perPage=${dto.perPage}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll(dto: Interfaces.LayerCombinationsGetRequestDto) {
    return this.httpClient.get<Interfaces.WorkflowLayerCombinationsEntity>(this.urls.getAll(dto));
  }

  getOne(id: number) {
    return this.httpClient.get<Interfaces.WorkflowLayerCombinationEntity>(this.urls.byId(id));
  }

  create(dto: Interfaces.LayerCombinationsUpsertRequestDto) {
    return this.httpClient.post<Interfaces.WorkflowLayerCombinationEntity>(this.urls.base, dto, {});
  }

  update(id: number, dto: Interfaces.LayerCombinationsUpsertRequestDto) {
    return this.httpClient.put<Interfaces.WorkflowLayerCombinationEntity>(this.urls.byId(id), dto);
  }

  delete(id: number) {
    return this.httpClient.delete(this.urls.byId(id));
  }
}
