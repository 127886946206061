import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { GdprMailRequest, GdprPurgeRequest } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class GdprService {
  gdprStorageName = 'gdpr-pass';
  public urls = {
    download: `${commonenv.nextGenApiUrl}gdpr/mail`,
    purge: `${commonenv.nextGenApiUrl}gdpr/purge`,
    downloadCollaborator: `${commonenv.nextGenApiUrl}gdpr/download-collaborator-logs`,
    deleteToken: `${commonenv.nextGenApiUrl}invitations/delete-email-token`,
  };
  constructor(public httpClient: HttpClient) {}

  downloadGDPR(request: GdprMailRequest) {
    return this.httpClient.get(`${this.urls.download}?email=${request.email}&identity=${request.identity}`);
  }
  downloadCollaboratorLogs() {
    const params = { responseType: 'blob' as 'json' };
    return this.httpClient.get<Blob>(`${this.urls.downloadCollaborator}`, params);
  }
  purgeGDPR(request: GdprPurgeRequest) {
    let uri = `${this.urls.purge}/${request.identity}`;
    if (request.session_id) {
      uri = uri + `?session_id=${request.session_id}`;
    }
    return this.httpClient.delete(uri);
  }
  deleteEmailToken(emailToken: string) {
    return this.httpClient.post(this.urls.deleteToken, {
      emailToken,
    });
  }
}
