import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import mixpanel from 'mixpanel-browser';
import { commonenv } from '../../environments/environment';
import {
  HostingAnalyticsEvent,
  HostingAnalyticsEventName,
  HostingAnalyticsEventProperties,
  HostingApplicationName,
} from './hosting-analytics-events';

@Injectable({
  providedIn: 'root'
})
export class HostingAnalyticsService {
  private prefix = 'INTERNAL Analytics: ';

  private platformId = inject(PLATFORM_ID);
  private _logEvents: boolean = isPlatformBrowser(this.platformId);

  private _isMixpanelInitialized: boolean = false;

  private _userId: number;
  private _accountId: number;
  private _isAdmin: boolean;
  private _application: HostingApplicationName;

  private _initMixpanel(): void {
    if (!this._isMixpanelInitialized) {
      mixpanel.init(commonenv.hostingMixpanelToken);
      this._isMixpanelInitialized = true;
    }
  }

  initInternalAnalytics() {
    this._initMixpanel();
    this.prefix = 'INTERNAL Analytics: ';
    this._application = HostingApplicationName.Internal;
  }

  initPublicAnalytics() {
    this._initMixpanel();
    this.prefix = 'PUBLIC Analytics: ';
    this._application = HostingApplicationName.Public;
  }

  get isInternalAnalytics() { 
    return this._application == HostingApplicationName.Internal 
  };

  get isPublicAnalytics() { 
    return this._application == HostingApplicationName.Public 
  };

  setUserId(id: number): void {
    this._userId = id;
  }

  setAccountId(accountId: number): void {
    this._accountId = accountId;
  }

  setIsAdmin(isAdmin: boolean): void {
    this._isAdmin = isAdmin;
  }

  private _logEvent(eventName: HostingAnalyticsEventName, properties: HostingAnalyticsEventProperties): void {
    if (this._logEvents && this._isMixpanelInitialized) {
      const eventProperties = {};

      if (properties) {
        for (const key in properties) {
          if (properties[key] != undefined && properties[key] != null) {
            eventProperties[`#${key}`] = properties[key];
          }
        }
      }

      mixpanel.track(eventName, eventProperties);

      mixpanel.people.increment('^total_event_count', 1);
      mixpanel.people.set({ '^last_event': new Date() });
    }
  }

  logEvent(event: HostingAnalyticsEvent): void {
    this._logEvent(event.eventName, {
      ...(event.properties ?? {}),
      description: this.prefix + event.description,
      user_id: this._userId,
      account_id: event.properties?.['account_id'] ?? this._accountId,
      is_admin: this._isAdmin,
      application: this._application,
    });
  }
}
