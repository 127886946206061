<h2 mat-dialog-title>Public Sharing Options</h2>

<mat-dialog-content>
  <div [formGroup]="shareForm">
    <div class="form-field-title">Public URL</div>

    <mat-form-field class="form-share-text" appearance="outline">
      <input [attr.data-cy]="shareForm.value.link" matInput formControlName="link" readonly>

      <button mat-icon-button matSuffix (click)="copyLink($event)">
        <mat-icon data-cy="copy-pub-link">content_copy</mat-icon>
      </button>
    </mat-form-field>

    <div class="password-contain">
      <ng-container *ngIf="data.hasPassword">
        <span class="password-txt">
          <mat-icon>lock</mat-icon>
          Password Protected
        </span>
        <button mat-button (click)="setPassword(false,null)">Remove</button>
        <button mat-button (click)="openUpdatePassword();">Change</button>
      </ng-container>
      <ng-container *ngIf="!data.hasPassword">
        <button mat-button (click)="openUpdatePassword();">
          <mat-icon>lock</mat-icon>
          Set Access Password
        </button>
      </ng-container>
    </div>

    <ng-container *ngIf="shareForm.value.embed">
      <div class="code-snippet">
        <div class="form-field-title">
          <mat-slide-toggle
            matTooltip="Can’t embed password protected video"
            class="embed-toggle"
            labelPosition="before"
            [formControl]="embedVideoToggleControl"
            [matTooltipDisabled]="!data.hasPassword"
          >
            Generate Embed Code
          </mat-slide-toggle>
        </div>

        <mat-form-field
          *ngIf="!embedUpdating && embedVideoToggleControl.value"
          class="form-share-text"
          appearance="outline"
        >
          <input
            matInput
            formControlName="embed"
            readonly
            matTooltip="Get a code snippet to embed this video in your webpage HTML"
            [attr.data-cy]="shareForm.value.embed"
          >
          <button mat-icon-button matSuffix (click)="copyEmbed($event)">
            <mat-icon data-cy="copy-embed-link" >content_copy</mat-icon>
          </button>
        </mat-form-field>

        <div *ngIf="!embedUpdating && embedVideoToggleControl.value" class="embed-count-info"
          [ngClass]="{ 'embed-count-info-transform': shareForm.value.embedEnabledAt }">
          You have used {{embedVideoCount}} out of {{embedLimit}} embedded videos.<span>&nbsp;</span>
        </div>
      </div>

      <ng-container *ngIf="embedVideoToggleControl.value && !embedUpdating">
        <div
          *ngIf="fixedSizeEnabled"
          [formGroup]="embedVideoSizingForm"
          fxLayoutAlign="flex-start center"
          fxLayoutGap="15px"
          @fadeIn
          @heightCollapse
        >
          <mat-form-field class="form-share-text size-input" appearance="outline">
            <mat-label>Width</mat-label>

            <input matInput type="number" formControlName="width">
          </mat-form-field>

          <mat-form-field class="form-share-text size-input" appearance="outline">
            <mat-label>Height</mat-label>

            <input matInput type="number" formControlName="height">
          </mat-form-field>
        </div>

        <div class="code-snippet-controls" fxLayoutAlign="flex-end center">
          <mat-radio-group [formControl]="responsivenessControl">
            <mat-radio-button [value]="true" class="responsive-option">Responsive size</mat-radio-button>
            <mat-radio-button [value]="false">Fixed size</mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <hr style="clear: both;" />

  <form #formRef="ngForm" [formGroup]="shareForm" (submit)="share()">
    <!-- email address -->
    <div class="form-field-title margin-top-16">Share Via Email</div>

    <openreel-email-list [emailList]="emailList" [maxEmails]="10" [formCtrl]="shareForm.get('email')">
    </openreel-email-list>

    <!-- email body -->
    <div class="form-field-title">Message (optional)</div>

    <mat-form-field class="form-share-text" appearance="outline">
      <textarea matInput class="email-message" formControlName="message" placeholder="Enter message..."
        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="formRef.onSubmit(); share()" [disabled]="shareFormInvalid" data-cy="btn-share">
    Share
  </button>

  <button mat-stroked-button mat-dialog-close data-cy="btn-cancel">Cancel</button>
</mat-dialog-actions>