const MIME_TYPES = new Map<string, string>([
  ['csv', 'text/csv'],
  ['jpeg', 'image/jpeg'],
  ['jpg', 'image/jpeg'],
  ['json', 'application/json'],
  ['mov', 'video/quicktime'],
  ['mp3', 'audio/mpeg'],
  ['mp4', 'video/mp4'],
  ['otf', 'font/otf'],
  ['pdf', 'application/pdf'],
  ['png', 'image/png'],
  ['srt', 'text/srt'],
  ['ttf', 'font/ttf'],
  ['vtt', 'text/vtt'],
  ['webm', 'video/webm'],
  ['webp', 'image/webp'],
]);

export function getMimeTypeFromPath(filePath: string) {
  const [, extension] = splitNameAndExtension(filePath);
  return getMimeTypeFromExtension(extension);
}

export function getMimeTypeFromExtension(fileExtension: string) {
  return MIME_TYPES.get(fileExtension.toLowerCase());
}

export function splitNameAndExtension(fileName: string): [string, string] {
  const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;
  const extension = FILE_EXTENSION_REGEX.exec(fileName)[1];
  const name = fileName.slice(0, -(extension.length + 1));
  return [name, extension];
}

const FALLBACK_S3_MIME_TYPE = 'application/octet-stream';

export function getDataUrl(url: string) {
  return new Promise<string>((resolve, reject) => {
    fetch(url, {
      mode: 'cors',
      headers: {
        Accept: 'image/*',
        Origin: window.location.host,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          let base64 = reader.result as string;
          const urlParsed = new URL(url);
          const mimeType = getMimeTypeFromPath(urlParsed.pathname);
          if (mimeType && base64.startsWith(`data:${FALLBACK_S3_MIME_TYPE}`)) {
            base64 = base64.replace(`data:${FALLBACK_S3_MIME_TYPE}`, `data:${mimeType}`);
          }
          return resolve(base64);
        };
        reader.onerror = (err) => reject(err);
      })
      .catch((err) => reject(err));
  });
}
