export enum KeepCopyValues {
  true = '1',
  false = '0',
}

export interface S3IntegrationStatus {
  connected: boolean;
  keepCopy: boolean;
}

export interface DestinationAuthUrlResponse {
  url: string;
}
