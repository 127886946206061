<ng-container *ngIf="mpUploads$ | async as mpUploads">
  <div
    class="upload-indicator-holder"
    fxLayout="column-reverse"
    fxLayoutAlign="bottom center"
    *ngIf="uploads?.length > 0 || mpUploads.length"
  >
    <button (click)="show = !show" class="single-upload-indicator small">
      <span *ngIf="!show">&#9650;</span>
      <span *ngIf="show">&#9660;</span>
    </button>
    <ng-container *ngIf="show">
      <div
        class="mat-app-background dark-theme single-upload-indicator"
        *ngFor="let upload of uploads"
        fxLayout="column"
      >
        <ng-container *ngIf="upload.status$ | async as status">
          <div fxFlex="1 1" fxLayout="row" fxLayoutAlign="center center">
            <span class="indicator-text">
              <mat-icon>
                {{ status.isUploading ? 'cloud_upload' : status.hasFailed ? 'error' : 'pause' }}
              </mat-icon>
              <ng-container *ngIf="upload.metadata.retryNumber && !status.hasFailed">
                (retry: {{ upload.metadata.retryNumber }})
              </ng-container>
              <a *ngIf="status.hasFailed" mat-button (click)="retryUpload(upload.metadata)"> Retry upload </a>
              {{ upload.metadata.fileNameForUpload }}
            </span>
          </div>
          <mat-progress-bar
            *ngIf="!status.hasFailed"
            color="primary"
            [mode]="isNaN(status.percentage) ? 'indeterminate' : 'determinate'"
            [value]="status.percentage * 100"
          >
          </mat-progress-bar>
        </ng-container>
      </div>

      <div
        class="mat-app-background dark-theme single-upload-indicator"
        *ngFor="let mpUpload of mpUploads"
        fxLayout="column"
      >
        <ng-container
          *ngIf="{
            progress: mpUpload.uploader.progress$ | async,
            hasError: mpUpload.uploader.hasError$ | async,
            isClosed: mpUpload.uploader.closed$ | async
          } as data"
        >
          <div fxFlex="1 1" fxLayout="row" fxLayoutAlign="center center">
            <span class="indicator-text">
              <mat-icon>
                {{ data.hasError ? 'error' : 'cloud_upload' }}
              </mat-icon>
              <a *ngIf="data.hasError" mat-button (click)="retryUpload(mpUpload.metadata)"> Retry upload</a>
              {{ mpUpload.metadata.fileNameForUpload }}
            </span>
          </div>
          <mat-progress-bar
            *ngIf="!data.hasError"
            color="primary"
            [mode]="isNaN(data.progress) ? 'indeterminate' : 'determinate'"
            [value]="(data.progress || 0) * 100"
          >
          </mat-progress-bar>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
