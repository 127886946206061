// Creator
export const EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID = 'workflow-join-to-room-via-capture-project-id';
export const EVT_WORKFLOW_RENDER_PROGRESS = 'workflow-render-progress';
export const EVT_WORKFLOW_TRANSCRIBE_PROGRESS = 'workflow-transcribe-progress';
export const EVT_WORKFLOW_THUMBNAILS_DONE = 'workflow-thumbnails-done';
export const EVT_WORKFLOW_APPROVAL_STATUS_CHANGE = 'workflow-approval-status-change';
export const getCreatorRoomByCaptureProjectId = (captureProjectId): string =>
  'workflow-capture-project-id-' + captureProjectId;

// Automations
export const EVT_AUTOMATIONS_JOIN_BY_CAPTURE_PROJECT_ID = 'automations-join-to-room-via-capture-project-id';
export const EVT_AUTOMATIONS_RUN_CREATED = 'automations-run-created';
export const EVT_AUTOMATIONS_RUN_UPDATED = 'automations-run-updated';
export const getAutomationsRoomByCaptureProjectId = (captureProjectId): string =>
  'automations-capture-project-id-' + captureProjectId;
