import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { PresentationStatus } from '@openreel/common';

export interface Slide {
  url: string;
  assetId: number;
}

export interface PresentationState extends EntityState<Slide> {
  presentationId: number;
  currentSlide: Slide;
  status: PresentationStatus;
  identity: string;
  presenterName: string;
  enabledDevices: string[]
  fileName: string;
}
export const presentationEntityAdapter: EntityAdapter<Slide> = createEntityAdapter<Slide>({
  selectId: (s) => s.assetId,
});