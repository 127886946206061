import { createSelector } from '@ngrx/store';
import { VideoSource } from '@openreel/frontend/common';
import { RootState } from '../../root-state';
import { StreamState } from './interfaces/stream-state';

const selectFeature = (state: RootState): StreamState => state.session.stream;

export const selectVideoSource = createSelector(selectFeature, (streamState) => streamState.videoSource);

export const selectActiveVideoStreamSources = createSelector(
  selectFeature,
  (streamState) => streamState.activeVideoStreamSources
);

export const selectVideoDevice = createSelector(selectFeature, (streamState) => streamState.videoDevice);

export const selectAudioDevice = createSelector(selectFeature, (streamState) => streamState.audioInputDevice);

export const selectAudioOutputDevice = createSelector(selectFeature, (streamState) => streamState.audioOutputDevice);


export const selectAudioStream = createSelector(selectFeature, (streamState) => streamState.audioStream);

export const selectStreamingAudioStream = createSelector(
  selectFeature,
  (streamState) => streamState.streamingAudioStream
);

export const selectWebcamStream = createSelector(selectFeature, (streamState) => streamState.webcamStream);

export const selectDesktopStream = createSelector(selectFeature, (streamState) => streamState.desktopStream);

export const selectStreamBySource = (source: VideoSource) =>
  createSelector(selectFeature, (streamState) =>
    source === VideoSource.DESKTOP ? streamState.desktopStream : streamState.webcamStream
  );

export const selectConstraintsBySource = (source: VideoSource) =>
  createSelector(selectStreamBySource(source), (videoStream) => videoStream?.settings);
export const selectVideoStream = createSelector(selectFeature, (streamState) =>
  streamState.videoSource === VideoSource.DESKTOP ? streamState.desktopStream : streamState.webcamStream
);

export const selectCurrentConstraints = createSelector(selectVideoStream, (videoStream) => videoStream?.settings);

export const selectWebcamConstraints = createSelector(selectWebcamStream, (videoStream) => videoStream?.settings);

export const selectDesktopConstraints = createSelector(selectDesktopStream, (videoStream) => videoStream?.settings);

export const selectAvailableScreencastSources = createSelector(
  selectFeature,
  (streamState) => streamState.availableScreenshareSources
);

export const selectAvailablePresentSources = createSelector(
  selectFeature,
  (streamState) => streamState.availablePresentSources
);

export const selectAvailableWebcamDevices = createSelector(
  selectFeature,
  (streamState) => streamState.availableWebcamDevices
);

/**
 * Return both webcam and screencast devices
 */
export const selectAvailableVideoDevices = createSelector(selectFeature, (streamState) =>
  streamState.availableScreenshareSources.concat(streamState.availableWebcamDevices as never[])
);

export const selectAvailableAudioDevices = createSelector(
  selectFeature,
  (streamState) => streamState.availableAudioInputDevices
);

export const selectAvailableAudioOutputDevices = createSelector(
  selectFeature,
  (streamState) => streamState.availableAudioOutputDevices
);

export const selectStreamToken = createSelector(selectFeature, (state) => state.streamToken);

export const selectStreamTokenError = createSelector(selectFeature, (state) => state.streamError);

export const selectStreamNetworkQuality = createSelector(selectFeature, (state) => state.streamNetworkQuality);

export const selectIsLiveStatus = createSelector(selectFeature, (state) => state.isLive);

export const selectLiveBroadcastUrl = createSelector(selectFeature, (state) => state.liveBroadcastUrl);

export const selectLiveBroadcastRtmps = createSelector(selectFeature, (state) => state.liveBroadcastRtmps);

export const selectLiveParticipants = createSelector(selectFeature, (state) => state.liveParticipantsIds);
export const selectLiveParticipantsIdentities = createSelector(
  selectFeature,
  (state) => state.liveParticipantsIdentities
);

export const selectStreamType = createSelector(selectFeature, (state) => state.streamToken?.streamType);
