import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BrandkitFormComponent } from '../brandkit-form/brandkit-form.component';
import { BrandkitFormModalData } from './interfaces/brandkit-form-modal-data.interface';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'openreel-brandkit-form-modal',
  templateUrl: './brandkit-form-modal.component.html',
  styleUrls: ['./brandkit-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, BrandkitFormComponent, MatIconModule],
})
export class BrandkitFormModalComponent {
  readonly data = inject<BrandkitFormModalData>(MAT_DIALOG_DATA);

  @ViewChild(BrandkitFormComponent) brandkitFormComponent: BrandkitFormComponent;

  private readonly dialogRef = inject(MatDialogRef<BrandkitFormModalComponent>);

  confirm(): void {
    const formValue = this.brandkitFormComponent.form.value;

    this.dialogRef.close({ formType: this.data.formType, ...formValue });
  }
}
