import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'clipSizeBytes', standalone: true })
export class ClipSizeBytesPipe implements PipeTransform {
  transform(bytes: number) {
    const kiloBytes = 1024;
    const megaBytes = 1048576;
    const gigaBytes = 1073741824;
    if (bytes < kiloBytes) {
      return Math.round(bytes) + 'bytes';
    } else if (bytes < megaBytes) {
      return Math.round(bytes / kiloBytes) + 'kb';
    } else if (bytes < gigaBytes) {
      return Math.round(bytes / megaBytes) + 'mb';
    } else {
      return Math.round(bytes / gigaBytes) + 'gb';
    }
  }
}
