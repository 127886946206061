import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { BrandkitPreviewComponent } from './brandkit-preview/brandkit-preview.component';
import { BRANDFETCH_BRANDING_FORM, ApplyBrandingFormService } from './apply-branding-form.service';
import { BrandkitPreviewFormComponent } from './brandkit-preview-form/brandkit-preview-form.component';
import { MatIconModule } from '@angular/material/icon';
import { BrandfetchBrandingModalData } from './branding-form.interface';
import { FontDOMService } from '@openreel/frontend/common';

@Component({
  selector: 'openreel-apply-brandfetch-branding-modal',
  templateUrl: './apply-brandfetch-branding-modal.component.html',
  styleUrls: ['./apply-brandfetch-branding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    BrandkitPreviewComponent,
    BrandkitPreviewFormComponent,
    MatIconModule,
    NgIf,
  ],
  providers: [
    ApplyBrandingFormService,
    {
      provide: BRANDFETCH_BRANDING_FORM,
      deps: [ApplyBrandingFormService],
      useFactory: (formService: ApplyBrandingFormService) => formService.form,
    },
  ],
})
export class ApplyBrandfetchBrandingModalComponent {
  readonly data = inject<BrandfetchBrandingModalData>(MAT_DIALOG_DATA);

  readonly form = inject(BRANDFETCH_BRANDING_FORM);

  private readonly router = inject(Router);
  private readonly dialogRef = inject(MatDialogRef<ApplyBrandfetchBrandingModalComponent>);
  private readonly fontDomService = inject(FontDOMService);

  constructor() {
    this.initializeForm();
    this.fontDomService.addFontFaces(this.data.fonts);
  }

  goToBrandKitSettings(): void {
    this.dialogRef.close(null);

    this.router.navigate(['settings'], { queryParams: { tab: 'brandkit' } });
  }

  apply(): void {
    this.dialogRef.close(this.form.value);
  }

  private initializeForm(): void {
    this.form.patchValue({
      domain: this.data.domain,
      logo: this.data.logos[0]?.formats[0] ?? null,
      watermark: this.data.logos[0]?.formats[0] ?? null,
      color: this.data.colors[0]?.hex ?? null,
      font: this.data.fonts[0] ?? null,
    });
  }
}
