import { AnalyticsReferrer } from '@openreel/frontend/common/hosting/analytics';

export interface AnalyticsFilters {
  period: AnalyticsFilterPeriod;
  referrers: AnalyticsReferrer[];
  hubIds: string[];
}

export enum AnalyticsFilterPeriod {
  LastDay = 'd',
  LastWeek = 'w',
  LastMonth = 'M',
  AllTime = 'all',
}
