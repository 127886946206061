import { Component, Input } from '@angular/core';
import { S3IntegrationStatus } from '../../interfaces/integration.interface';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { ImgFallbackDirective } from '../../directives/img-fallback.directive';

@Component({
  selector: 'openreel-title-line',
  templateUrl: './title-line.component.html',
  styleUrls: ['./title-line.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatIconModule, OverlayModule, ImgFallbackDirective],
})
export class TitleLineComponent {
  @Input()
  title: string;
  @Input()
  titleAlign: 'start' | 'center' | 'end' = 'start';

  @Input() s3IntegrationStatus: S3IntegrationStatus;

  @Input() accountDestinations: string[];

  isOpen: boolean;
}
