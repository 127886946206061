import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { commonenv } from '../../environments/environment';
import { Recording } from '../../interfaces/interfaces';
import { ProjectLevelSessionSettings } from '../../interfaces/settings.interface';
import { SessionApiService } from '../session/session-api.service';
import { HubsListRequest, HubsListResponse } from '../workflow-recording/workflow-recording.interface';
import {
  CreateProjectData,
  CreateProjectRequest,
  CreateProjectResponse,
  DashboardSummaryProjectsResponse,
  ProjectData,
  ProjectDetails,
  ProjectDetailsResponse,
  ProjectListCountRequest,
  ProjectListCountResponse,
  ProjectListRequest,
  ProjectListResponse,
  ProjectMember,
  ProjectRecordingsResponse,
  ProjectUpdateRequest,
  ProjectUpdateResponse,
} from './project.interface';

const throwIfUnsuccessful = <T>(request$: Observable<{ status: number; message: string; data?: T }>): Observable<T> =>
  request$.pipe(
    map((project) => {
      if (project.status === 1) {
        return project.data;
      } else {
        throw new Error(project.message);
      }
    })
  );

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public urls = {
    projectCreate: `${commonenv.nextGenApiUrl}projects/create`,
    projectList: `${commonenv.nextGenApiUrl}projects`,
    projectListCount: `${commonenv.nextGenApiUrl}projects/count`,
    hubsList: `${commonenv.nextGenApiUrl}hosting/hubs`,
    projectDetails: `${commonenv.nextGenApiUrl}projects/details`,
    projectUpdate: `${commonenv.nextGenApiUrl}projects/update`,
    projectArchive: `${commonenv.nextGenApiUrl}projects/archive`,
    projectRestore: `${commonenv.nextGenApiUrl}projects/restore`,
    getProjectDelete: (projectId) => `${commonenv.nextGenApiUrl}projects/${projectId}`,
    getProjectLevelSessionSettings: (projectId) => `${commonenv.nextGenApiUrl}projects/${projectId}/session-settings`,
    getProjectRecordings: (projectId) => `${commonenv.nextGenApiUrl}projects/${projectId}/recordings`,
    getProjectFavouriteRecordings: (projectId) =>
      `${commonenv.nextGenApiUrl}projects/${projectId}/favourite-recordings`,
    getProjectArchivedRecordings: (projectId) => `${commonenv.nextGenApiUrl}projects/${projectId}/archived-recordings`,
    getProjectDetails: (projectId: number, hasClips?: boolean) =>
      `${commonenv.nextGenApiUrl}projects/details/?project_id=${projectId}${hasClips ? '&has_clips=1' : ''}`,
    getProjectMembers: (projectId: number) => `${commonenv.nextGenApiUrl}projects/${projectId}/members`,
    getDashboardSummaryProjects: `${commonenv.nextGenApiUrl}projects/dashboard-recent-projects`,
  };

  constructor(public httpClient: HttpClient, private sessionApi: SessionApiService) {}

  createProject(data: CreateProjectRequest): Observable<CreateProjectData> {
    return throwIfUnsuccessful(this.httpClient.post<CreateProjectResponse>(this.urls.projectCreate, data));
  }

  getDashboardSummaryProjects(): Observable<DashboardSummaryProjectsResponse> {
    return this.httpClient.get<DashboardSummaryProjectsResponse>(this.urls.getDashboardSummaryProjects);
  }

  getProjectList({
    limit,
    page,
    orderField,
    orderSort,
    searchString,
    startDate,
    endDate,
    sessionId,
    hasClips,
  }: ProjectListRequest): Observable<Array<ProjectData>> {
    const params: {
      [param: string]: string | string[] | boolean;
    } = {
      limit: limit.toString(),
      page: page.toString(),
      order_field: orderField,
      order_sort: orderSort,
    };

    if (hasClips) {
      params.has_clips = true;
    }

    if (searchString) {
      params.projectName = searchString;
    }

    if (sessionId) {
      params.session_id = String(sessionId);
    }

    if (startDate) {
      params.start_date = startDate;
    }

    if (endDate) {
      params.end_date = endDate;
    }

    return throwIfUnsuccessful(
      this.httpClient.get<ProjectListResponse>(this.urls.projectList, {
        params,
      })
    );
  }

  getHubsList({ page, perPage }: HubsListRequest): Observable<HubsListResponse> {
    const params: {
      [param: string]: string | string[] | boolean;
    } = {
      page: page.toString(),
      perPage: perPage.toString(),
    };

    return this.httpClient.get<HubsListResponse>(this.urls.hubsList, {
      params,
    });
  }

  getArchivedProjectsList(isArchived: boolean, limit: number, page: number) {
    return this.httpClient.get<ProjectListResponse>(this.urls.projectList, {
      params: { isArchived, limit, page },
    });
  }

  getProjectDetails(id: number, hasClips?: boolean): Observable<ProjectDetails> {
    return throwIfUnsuccessful(this.httpClient.get<ProjectDetailsResponse>(this.urls.getProjectDetails(id, hasClips)));
  }

  updateProject(data: ProjectUpdateRequest): Observable<void> {
    return throwIfUnsuccessful(this.httpClient.put<ProjectUpdateResponse>(this.urls.projectUpdate, data));
  }

  archiveProject(data: ProjectUpdateRequest): Observable<void> {
    return throwIfUnsuccessful(this.httpClient.put<ProjectUpdateResponse>(this.urls.projectArchive, data));
  }

  restoreProject(data: ProjectUpdateRequest): Observable<void> {
    return throwIfUnsuccessful(this.httpClient.put<ProjectUpdateResponse>(this.urls.projectRestore, data));
  }

  deleteProject(projectId: number) {
    return this.httpClient.delete<{ message: string }>(this.urls.getProjectDelete(projectId));
  }

  getProjectLevelSessionSettings(projectId: number): Promise<ProjectLevelSessionSettings> {
    return this.httpClient
      .get<ProjectLevelSessionSettings>(this.urls.getProjectLevelSessionSettings(projectId))
      .toPromise();
  }

  updateProjectLevelSessionSettings(
    projectId: number,
    payload: ProjectLevelSessionSettings
  ): Promise<ProjectLevelSessionSettings> {
    return this.httpClient
      .post<ProjectLevelSessionSettings>(this.urls.getProjectLevelSessionSettings(projectId), payload)
      .toPromise();
  }

  getProjectRecordings(projectId: number, params: HttpParams): Promise<ProjectRecordingsResponse> {
    return this.httpClient
      .get<ProjectRecordingsResponse>(this.urls.getProjectRecordings(projectId), { params })
      .toPromise();
  }

  getProjectFavouriteRecordings(projectId: number, params: HttpParams): Promise<Recording[]> {
    return this.httpClient
      .get<Recording[]>(this.urls.getProjectFavouriteRecordings(projectId), {
        params,
      })
      .toPromise();
  }

  getProjectArchivedRecordings(projectId: number, params: HttpParams): Promise<ProjectRecordingsResponse> {
    return this.httpClient
      .get<ProjectRecordingsResponse>(this.urls.getProjectArchivedRecordings(projectId), { params })
      .toPromise();
  }

  sessionCreateUpdateInputData(projectId: number, sessionId: number) {
    return Promise.all([
      sessionId ? this.sessionApi.getSessionData(sessionId).toPromise() : undefined,
      !sessionId
        ? this.httpClient.get<{ data: ProjectMember[] }>(this.urls.getProjectMembers(projectId)).toPromise()
        : undefined,
    ]);
  }

  getProjectsTotalCount(params: ProjectListCountRequest): Observable<number> {
    let httpParams = new HttpParams().append('isArchived', String(params.isArchived));

    if (params.startDate) {
      httpParams = httpParams.append('startDate', params.startDate);
    }

    if (params.endDate) {
      httpParams = httpParams.append('endDate', params.endDate);
    }

    return this.httpClient.get<ProjectListCountResponse>(this.urls.projectListCount, {
      params: httpParams,
    }).pipe(
      map((response) => {
        return response.count;
      })
    );
  }
}
