import {
  NextgenParticipant,
  PartialNextgenParticipant,
  ParticipantPresentationProperties,
} from '@openreel/frontend/common';
import { ParticipantProp } from './interfaces/participant-props.interface';
import { ParticipantState } from './interfaces/participant-store.interface';

export const onSetMyParticipant = (state: ParticipantState, action: ParticipantProp): ParticipantState => ({
  ...state,
  myParticipant: { ...action.participant, presentationProperties: state.myParticipant?.presentationProperties, isMe: true } || {
    ...state.myParticipant,
  },
});

export const onUpdateMyParticipant = (
  state: ParticipantState,
  changes: PartialNextgenParticipant
): ParticipantState => ({
  ...state,
  myParticipant: {
    ...state.myParticipant,
    ...(changes as Partial<NextgenParticipant>),
    isMe: true,
  },
});

// get entity ID for participant
export const getParticipantId = (identity: string) => {
  if (!identity) return;
  const splitIdentity = identity.split('_');
  const isSubject = splitIdentity[0] !== 'web';
  return `${isSubject ? 's' : 'd'}_${splitIdentity[splitIdentity.length - 1]}`;
};

export const getDirectorIdFromLoginId = (loginId: number) => `d_${loginId}`;

// update function for participant
export const onUpdateParticipant = (
  oldParticipant: NextgenParticipant,
  changes: PartialNextgenParticipant
): NextgenParticipant => {
  const { videoProperties, deviceProperties, presentationProperties, ...partial } = changes;
  return {
    ...oldParticipant,
    ...(partial as Partial<NextgenParticipant>),
    videoProperties: { ...oldParticipant.videoProperties, ...videoProperties },
    deviceProperties: {
      ...oldParticipant.deviceProperties,
      ...deviceProperties,
    },
    teleprompterProperties: {
      ...oldParticipant.teleprompterProperties,
    },
    presentationProperties:
      ({ ...oldParticipant.presentationProperties, ...presentationProperties } as ParticipantPresentationProperties) ||
      oldParticipant.presentationProperties,
  };
};
