<div class="sso-setup-wrapper">
  <input
    #xmlInput
    hidden="true"
    type="file"
    id="file-input"
    (click)="removeValue($event)"
    (change)="readFile($event)"
    accept=".xml"
  />
  <button mat-stroked-button class="upload-xml-btn" color="primary" (click)="xmlInput.click()">Upload XML</button>
  <form class="sso-form" *ngIf="ssoForm" [formGroup]="ssoForm" (submit)="submitSsoForm()">
    <mat-form-field class="full-form-field" appearance="outline">
      <mat-label>Slug</mat-label>
      <input matInput (blur)="formatSlug()" formControlName="slug" placeholder="Slug" />
    </mat-form-field>
    <mat-form-field class="full-form-field" appearance="outline">
      <mat-label>Identifier (Entity ID)</mat-label>
      <input matInput formControlName="issuer" placeholder="Identifier" />
    </mat-form-field>
    <mat-form-field class="full-form-field" appearance="outline">
      <mat-label>Login URL</mat-label>
      <input matInput formControlName="entryPoint" placeholder="Login URL" />
    </mat-form-field>
    <mat-form-field class="full-form-field" appearance="outline">
      <mat-label>Callback URL</mat-label>
      <input matInput readonly formControlName="callbackUrl" placeholder="Callback URL" />
    </mat-form-field>
    <mat-form-field class="full-form-field" appearance="outline">
      <mat-label>Certificate</mat-label>
      <textarea matInput formControlName="certificate" placeholder="Certificate" rows="5"></textarea>
    </mat-form-field>
    <mat-form-field class="half-form-field" appearance="outline">
      <mat-label>Email Attribute</mat-label>
      <input matInput formControlName="emailKey" placeholder="Email" />
    </mat-form-field>
    <mat-form-field class="half-form-field" appearance="outline">
      <mat-label>Name Attribute</mat-label>
      <input matInput formControlName="firstNameKey" placeholder="Name" />
    </mat-form-field>
    <mat-form-field class="half-form-field" appearance="outline">
      <mat-label>Surname Attribute</mat-label>
      <input matInput formControlName="lastNameKey" placeholder="Surname" />
    </mat-form-field>
    <mat-form-field class="half-form-field" appearance="outline">
      <mat-label>Fullname Attribute</mat-label>
      <input matInput formControlName="fullNameKey" placeholder="Fullname" />
    </mat-form-field>
    <div class="btn-wrapper">
      <button type="submit" [disabled]="isSubmit" mat-raised-button color="primary">Submit</button>
    </div>
  </form>
</div>
