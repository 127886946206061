import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { DRAWABLE_SHAPES_CREATOR_MANAGER } from "../../resources/drawable.constants";
import { LetModule } from '@ngrx/component'
import { DrawableShapeColor, DrawableShapeType } from "@openreel/common";

@Component({
    selector: 'or-drawable-toolbar',
    standalone: true,
    templateUrl: './drawable-toolbar.component.html',
    styleUrls: ['./drawable-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgFor,
        NgIf,
        MatIconModule,
        MatButtonModule,
        AsyncPipe,
        LetModule
    ]
})
export class DrawableToolbarComponent {
    @Input() disabled: boolean = false;

    colors: DrawableShapeColor[] = [
        DrawableShapeColor.White,
        DrawableShapeColor.Red,
        DrawableShapeColor.Blue
    ];

    shapes = [
        { type: DrawableShapeType.Arrow, icon: 'north_east' },
        { type: DrawableShapeType.Rectangle, icon: 'crop_square' },
    ]

    manager = inject(DRAWABLE_SHAPES_CREATOR_MANAGER);

    isActive$ = this.manager.isActiveDrawMode$;
    color$ = this.manager.drawableShapeColor$;
    shape$ = this.manager.drawableShapeType$;
    drawResult$ = this.manager.drawResult$;

    toggleActive(isActive: boolean) {
        this.manager.changeDrawModeActiveState(isActive);
    }

    selectColor(color: DrawableShapeColor) {
        this.manager.changeDrawableShapeColor(color);
    }

    selectShape(shape: DrawableShapeType) {
        this.manager.changeDrawableShapeType(shape);
    }

    clear() {
        this.manager.clearDrawResult();
    }
}