import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CAPTURE_AUDIO_METER, CAPTURE_SESSION_CLIPS, CHATTING, TELEPROMPTER } from '@openreel/common';

@Injectable()
export class FeatureFlaggingServiceMock {
  public flags;
  flagChange: Subject<Record<string, boolean>> = new Subject<Record<string, boolean>>();
  flagChange$ = this.flagChange.asObservable();

  constructor() {
    this.flags = {
      [CHATTING]: true,
      [TELEPROMPTER]: true,
      [CAPTURE_SESSION_CLIPS]: true,
      [CAPTURE_AUDIO_METER]: true,
    };
  }
  getFlag(flag: string) {
    return true;
  }
}
