import MobileDetect from 'mobile-detect';
import { fromEvent, merge, MonoTypeOperatorFunction, Observable, of, throwError, timer } from 'rxjs';
import {
  bufferToggle,
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeAll,
  mergeMap,
  retryWhen,
  scan,
  shareReplay,
  tap,
  windowToggle,
} from 'rxjs/operators';
import * as EBML from 'ts-ebml';

export function onCancel<T>(onCancelCallback): MonoTypeOperatorFunction<T> {
  return (observable) =>
    new Observable((observer) => {
      let completed = false;
      let errored = false;
      const subscription = observable.subscribe({
        next: (v) => observer.next(v),
        error: (e) => {
          errored = true;
          observer.error(e);
        },
        complete: () => {
          completed = true;
          observer.complete();
        },
      });
      return () => {
        subscription.unsubscribe();
        if (!completed && !errored) {
          onCancelCallback();
        }
      };
    });
}

export function retryWithDelay<T>(delayMs: number, maxRetries = 1): MonoTypeOperatorFunction<T> {
  return (input) =>
    input.pipe(
      retryWhen((errors) =>
        errors.pipe(
          scan((acc, error) => ({ count: acc.count + 1, error }), {
            count: 0,
            error: undefined,
          }),
          tap((current) => {
            if (current.count > maxRetries) {
              throw current.error;
            }
          }),
          delay(delayMs)
        )
      )
    );
}

export function bufferPause<T>(remote$: Observable<boolean>): MonoTypeOperatorFunction<T> {
  return (source$) => {
    const pause$ = remote$.pipe(distinctUntilChanged(), shareReplay(1));
    const on$ = pause$.pipe(filter((v) => !v));
    const off$ = pause$.pipe(filter((v) => v));

    return merge(
      source$.pipe(
        bufferToggle(off$, () => on$),
        mergeAll()
      ),
      source$.pipe(
        windowToggle(on$, () => off$),
        mergeAll()
      )
    );
  };
}

export function delayEach<T>(millis: number) {
  return (o: Observable<T>) => o.pipe(concatMap((x) => of(x).pipe(delay(millis))));
}

// NOT WORKING ATM
export function isPackaged() {
  return window.require('electron').remote.process.mainModule.filename.indexOf('.asar') !== -1;
}

export function isRunningHotReload() {
  if ((window as Window & typeof globalThis & { forceNoHotReload: boolean }).forceNoHotReload) {
    return false;
  }
  return (
    document.URL.toLowerCase().startsWith('http://localhost') ||
    document.URL.toLowerCase().startsWith('https://localhost')
  );
}

export function fixPathForPackaging(path: string) {
  if (this.isPackaged()) {
    return path.replace('.asar', '.asar.unpacked');
  } else {
    return path;
  }
}

export function getNumericValuesOfEnum(someEnum): number[] {
  const ret: number[] = [];
  for (const key of Object.keys(someEnum)) {
    const n = parseInt(key, 10);
    if (!isNaN(n)) {
      ret.push(n);
    }
  }
  return ret;
}

export function isOnWeb() {
  return !window.require;
}

export function safeGetValue<T>(fn: () => T, errorValue: T): T {
  try {
    return fn();
  } catch (err) {
    // console.warn('Error safe getting the value: ', err.message);
    return errorValue;
  }
}

export function objToBoolString(value: unknown): '0' | '1' {
  return value ? '1' : '0';
}

export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function waitForKeyToAppear<T>(obj: T | (() => T), key: keyof T, timeout: number): Promise<void> {
  const started = new Date().getTime();
  const getVal: () => T = () => {
    if (typeof obj === 'function') {
      return (obj as () => T)();
    } else {
      return obj;
    }
  };
  while (!Object.keys(getVal()).includes(key as string)) {
    if (new Date().getTime() - started > timeout) {
      throw new Error('Timeout reached');
    }
    await wait(1);
  }
}

export function saveByteArray(blob: Blob, name: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export async function getOriginalSeekableBlob(dataChunk: Blob[]): Promise<{ refinedBlob: Blob; duration: number }> {
  const blob = new Blob(dataChunk, {
    type: 'video/webm',
  });
  const fileReader = new FileReader();
  const m1 = fromEvent<ProgressEvent<FileReader>>(fileReader, 'load').pipe(
    map((evt) => evt.target.result as ArrayBuffer)
  );
  const m2 = fromEvent<ProgressEvent<FileReader>>(fileReader, 'error').pipe(
    map((err) => {
      throw err;
    })
  );
  const p = merge(m1, m2).pipe(first()).toPromise();

  fileReader.readAsArrayBuffer(blob);
  return getSeekableWebmBlob(await p);
}

function getSeekableWebmBlob(buffer: ArrayBuffer): {
  refinedBlob: Blob;
  duration: number;
} {
  const decoder = new EBML.Decoder();
  const reader = new EBML.Reader();
  const tools = EBML.tools;
  reader.drop_default_duration = false;
  const elms = decoder.decode(buffer);
  elms.forEach((elm) => {
    reader.read(elm);
  });
  const duration = reader.duration;
  reader.stop();
  const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
  const body = buffer.slice(reader.metadataSize);
  const refinedWebM = new Blob([refinedMetadataBuf, body], {
    type: 'video/webm',
  });
  return { refinedBlob: refinedWebM, duration: duration };
}

export function containsOther(objA: unknown, objB: unknown) {
  if (!objA) {
    return false;
  }

  if (typeof objA !== 'object' || typeof objB !== 'object') {
    return false;
  }

  if (objA === objB) {
    return true;
  }

  const aProps = Object.getOwnPropertyNames(objA);

  let isEqual = true;
  aProps.forEach((prop) => {
    if (objA[prop] !== objB[prop]) {
      isEqual = false;
      return;
    }
  });

  return isEqual;
}

export function isIosDevice(identity: string): boolean {
  if (identity && identity.startsWith('ios_')) {
    return true;
  } else {
    return false;
  }
}

export function isIosSmartMobile(): boolean {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  return mobileDetect.is('iOS') || mobileDetect.is('iPadOS');
}

export function isAndroidMobile(): boolean {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  return mobileDetect.is('AndroidOS');
}

export function supportsSharedWorker(): boolean {
  return typeof SharedWorker !== 'undefined';
}

export function isMobile(): boolean {
  return isAndroidMobile() || isIosSmartMobile();
}

export function isIPadPro(): boolean {
  return /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
}

export function getBrowser() {
  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('opr') > -1:
      return 'opera';
    case agent.indexOf('chrome') > -1 || agent.indexOf('crios') > -1:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
}

export function isBrowserSupported() {
  const supportedBrowsers = ['chrome', 'firefox', 'edge'];
  return supportedBrowsers.includes(getBrowser());
}

export function toISODate(date: Date): string {
  if (!date) {
    return null;
  }

  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
}

/* eslint-disable */
export function throttle(wait: number = 500): MethodDecorator {
  return function (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    let timeout: any;
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const context = this;
      const later = function () {
        timeout = null;
      };
      const callNow = !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        originalMethod.apply(context, args);
      }
    };
    return descriptor;
  };
}

export const formatSecond = (d: number) => {
  const hour = Math.floor(d / 3600);
  const minute = Math.floor((d % 3600) / 60);
  const second = Math.floor((d % 3600) % 60);
  const time =
    hour > 0
      ? hour.toString().padStart(2, '0') + ':'
      : '' + minute.toString().padStart(2, '0') + ':' + second.toString().padStart(2, '0');
  return time;
};

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status) || !error.status) {
          return throwError(error);
        }
        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      })
    );
  };

/* eslint-enable */
