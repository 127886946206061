<div *ngIf="objectUsersLoaded$ | async; else loading">
  <ng-container *openreelLet="loggedInUserId$ | async as loggedInUserId">
    <ng-container *ngIf="groupsPermissionsFeatureEnabled$ | async">
      <ng-container *openreelLet="objectGroups$ | async as objectGroups">
        <div *ngIf="objectGroups?.length">
          <openreel-groups-list-item
            *ngFor="let group of objectGroups; trackBy: trackById"
            [group]="group"
          ></openreel-groups-list-item>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *openreelLet="objectUsers$ | async as objectUsers">
      <div *ngIf="objectUsers?.length; else noUsers">
        <openreel-users-list-item
          *ngFor="let user of objectUsers; trackBy: trackById"
          [user]="user"
          [loggedInUserId]="loggedInUserId"
        ></openreel-users-list-item>
      </div>

      <ng-template #noUsers>
        <div class="no-users">
          <span class="no-users-text paragraph-p200">Users not added yet</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loading">
    <mat-spinner color="primary" [strokeWidth]="2" [diameter]="25"></mat-spinner>
  </div>
</ng-template>
