import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService, Member, ProjectService, UserRoleType } from '@openreel/frontend/common';
import { AutocompleteInviteInternalUserComponent } from '@openreel/frontend/common/components/autocomplete-invite-internal-user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'openreel-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutocompleteInviteInternalUserComponent,
  ],
})
export class CreateProjectComponent {
  projectFrm: UntypedFormGroup;
  disabledBtn: boolean;
  isLightThemed = true;
  isPopup = true;
  members: Array<Member> = [];
  constructor(
    public dialogRef: MatDialogRef<CreateProjectComponent>,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private authService: AuthService
  ) {
    this.projectFrm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
    });

    const cookieData = JSON.parse(this.authService.getCookie());
    this.members.push({
      member_id: cookieData.data.user_id,
      role: UserRoleType.Internal,
      is_required: true,
      email: cookieData.data.email,
      name: cookieData.data.loggedin_fullname,
    });
  }

  createProject() {
    if (!this.projectFrm.valid) return;
    this.disabledBtn = true;
    this.projectService
      .createProject({
        name: this.projectFrm.value.name.trim(),
        members: this.members,
      })
      .subscribe(
        (createProjectData) => {
          this.disabledBtn = false;
          this.toastr.success('Project Created Successfully', 'Success!');
          this.closeProjectDialog({ status: 1, id: createProjectData.project.id });
        },
        (error) => {
          this.disabledBtn = false;
          this.toastr.error(error.message, 'Error!');
        }
      );
  }
  closeProjectDialog(data) {
    this.dialogRef.close(data);
  }

  onDialogCloseClicked() {
    this.dialogRef.close();
  }
}
