import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

interface PageEvent {
  previousPageIndex: number;
  pageIndex: number;
  pageSize: number;
  length: number;
}

@Component({
  selector: 'or-pager',
  templateUrl: './or-pager.component.html',
  styleUrls: ['./or-pager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatLegacyButtonModule, MatLegacySelectModule],
})
export class OrPagerComponent implements OnInit {
  @Input() pageSizeOptions = [];

  private _pageSize = 0;
  @Input()
  set pageSize(ps: number) {
    if (ps !== this.pageSize) {
      this._pageSize = ps;
      this.buildPages();
    }
  }
  get pageSize() {
    return this._pageSize;
  }

  private _pageIndex = 0;
  @Input()
  set pageIndex(i: number) {
    if (i !== this.pageIndex) {
      this._pageIndex = i;
      this.buildPages();
    }
  }
  get pageIndex() {
    return this._pageIndex;
  }

  private _length = 0;
  @Input()
  set length(l: number) {
    this._length = l;
    this.buildPages();
  }
  get length() {
    return this._length;
  }

  @Output() page = new EventEmitter<PageEvent>();

  private previousPageIndex = 0;

  disableLeft = true;
  disableRight = true;
  pages = [];

  ngOnInit(): void {
    this.buildPages();
  }

  //  page size changed
  selectPageSize(pageSize: number) {
    const startIndex = this.pageIndex * this.pageSize;
    this.previousPageIndex = this.pageIndex;
    this._pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;
    this.pageEmit();
  }

  // on left arrow
  onPrev() {
    if (this.hasPreviousPage()) {
      this.previousPageIndex = this.pageIndex;
      this.pageIndex--;
      this.pageEmit();
    }
  }

  // on right arrow
  onNext() {
    if (this.hasNextPage()) {
      this.previousPageIndex = this.pageIndex;
      this.pageIndex++;
      this.pageEmit();
    }
  }

  // on page click
  selectPage(page: number) {
    if (page >= 0 && page < this.getNumberOfPages()) {
      this.previousPageIndex = this.pageIndex;
      this.pageIndex = page;
      this.pageEmit();
    }
  }

  private hasPreviousPage() {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  private hasNextPage() {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  private getNumberOfPages() {
    return !this.pageSize ? 0 : Math.ceil(this.length / this.pageSize);
  }

  private pageEmit() {
    this.page.emit({
      previousPageIndex: this.previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }

  // enable/disable left and right arrows
  private enableDisable() {
    this.disableLeft = !this.hasPreviousPage();
    this.disableRight = !this.hasNextPage();
  }

  private buildPages() {
    this.enableDisable();
    const activePage = this.pageIndex;
    const maxPage = Math.max(this.getNumberOfPages() - 1, 0);
    const remaining = Math.max(maxPage - activePage, 0);
    // indexes below and above active
    let toLeft = [...Array(activePage).keys()];
    let toRight = [...Array(remaining).keys()].map((i) => activePage + i + 1);
    // 3 allowed each side normally, one extra on the edges
    const allowedLeft = toRight.length ? 3 : 4;
    const allowedRight = toLeft.length ? 3 : 4;
    if (toLeft.length > allowedLeft) {
      // need to truncate left, keep first and last, and -1 in mid to truncate
      const tailL = toLeft.pop();
      const headL = toLeft.shift();
      const midL = [-1];
      if (allowedLeft === 4) {
        // also keep second to last in mid if extra
        midL.push(toLeft.pop());
      }
      toLeft = [headL, ...midL, tailL];
    }
    if (toRight.length > allowedRight) {
      // need to truncate right, keep first and last, and -1 in mid to truncate
      const tailR = toRight.pop();
      const headR = toRight.shift();
      const midR = [-1];
      if (allowedRight === 4) {
        // also keep second in mid if extra
        midR.unshift(toRight.shift());
      }
      toRight = [headR, ...midR, tailR];
    }
    // set pages from left, active and right
    this.pages = [...toLeft, activePage, ...toRight];
  }
}
