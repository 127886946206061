<input
  #fileInput
  [hidden]="true"
  type="file"
  data-cy="file-upload"
  [accept]="acceptedFileExtensions"
  [multiple]="parsedOptions.multiple"
  (change)="onFileDialogChange($event)"
/>
<div
  class="container"
  [class.dark]="darkBackground"
  (dragover)="onDragOver($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <div class="background" [class.has-color]="parsedOptions.showBackgroundColor"></div>
  <ng-container *ngIf="!isUploading || parsedOptions.forceShowFilePicker">
    <div *ngIf="!sourceId || parsedOptions.forceShowFilePicker" class="upload">
      <div class="upload-caption-container" data-cy="upload-caption">
        <ng-container *ngIf="uploadCaption">
          <div class="uploader-message">
            <div class="uploader-icon">
              <mat-icon svgIcon="cloud-upload"></mat-icon>
            </div>
            <p>{{ uploadCaption }}</p>
          </div>
        </ng-container>
        <ng-content *ngIf="!uploadCaption"></ng-content>
      </div>
      <button *ngIf="!isDisabled" type="button" (click)="onSelectFile()"></button>
    </div>
    <div
      *ngIf="sourceId && !parsedOptions.forceShowFilePicker"
      [class]="'uploaded ' + uploadedClasses"
      [class.full-size]="fullSize"
    >
      <ng-container *ngIf="fileType === 'image'">
        <img
          data-cy="uploaded-image"
          [openreelAssetImage]="sourceId"
          alt="Uploaded Image"
          [class.selectable-image]="parsedOptions.isImageClickable"
          (click)="onSelectImage()"
        />
      </ng-container>
      <ng-container *ngIf="fileType === 'audio'">
        <audio
          #audio
          disableRemotePlayback
          disablePictureInPicture
          loop
          disablepictureinpicture
          oncontextmenu="return false;"
          preload="metadata"
          src=""
          controls
          [openreelAssetVideo]="sourceId"
          [assetVideoProvider]="selectedSourceProvider"
        ></audio>
      </ng-container>
      <ng-container *ngIf="fileType === 'video'">
        <video
          #video
          disableRemotePlayback
          disablePictureInPicture
          muted
          loop
          disablepictureinpicture
          oncontextmenu="return false;"
          preload="metadata"
          src=""
          [openreelAssetVideo]="sourceId"
          [assetVideoProvider]="selectedSourceProvider"
        ></video>
      </ng-container>
      <div class="file-info" *ngIf="fileType === 'document'">
        <mat-icon class="doc-icon">description</mat-icon>
        <div class="name-size">
          <div class="file-name">{{ fileName$ | async }}</div>
          <div class="file-size">{{ fileSize$ | async }}</div>
        </div>
      </div>
      <div *ngIf="fileType === 'font'">
        <mat-icon class="doc-icon">description</mat-icon>
      </div>
      <button *ngIf="!isDisabled && parsedOptions.showRemoveAction" type="button" (click)="removeFile()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!parsedOptions.forceShowFilePicker && isUploading">
    <div class="file-info">
      <mat-icon svgIcon="upload-file"></mat-icon>
      <div class="name-size">
        <div class="file-name">{{ fileName$ | async }}</div>
        <div class="file-size">{{ fileSize$ | async }}</div>
      </div>
    </div>
    <div class="progress-bar-container">
      <div class="progress-bar-full">
        <div class="progress-bar" data-cy="upload-progressbar" [ngStyle]="{ width: uploadProgress + '%' }"></div>
      </div>
      <div class="progress-percentage">{{ uploadProgress }}%</div>
    </div>
    <button mat-icon-button *ngIf="showCancelBtn" (click)="cancelUpload()" matTooltip="Cancel Upload">
      <mat-icon color="warn">cancel_presentation</mat-icon>
    </button>
  </ng-container>
</div>
