import { Injectable } from '@angular/core';
import {
  DirectorSessionInfo,
  MyIdentitySendType,
  DirectorOnlineInfo,
  ReceiveSocketMessageInfo,
  MultiSendSocketCompleteInfo,
  ISocketIoLibService,
  SocketData,
  EVT_D2S_DIRECTOR_FORCE_RELEASE,
  EVT_D2S_DIRECTOR_SESSION_SETTINGS_CHANGED,
  UserRoleType,
  EVT_ADMISSION_REQUEST_RESPONSE,
  EVT_SUBJECT_REQUEST_TO_JOIN,
  SocketRequestToJoin,
} from '../../interfaces';
import { Subject, BehaviorSubject, throwError, merge, Observable } from 'rxjs';
import { take, timeoutWith, tap, filter, map, first } from 'rxjs/operators';

import { SocketAckTimeout } from './subject-socket.service';
import { BaseSocketService } from './base-socket.service';
import {
  EVT_D2S_DIRECTOR_ONLINE,
  EVT_SEND_JOIN_ROOM,
  EVT_SEND_JOIN_ROOM_ACC,
  EVT_D2S_DIRECTOR_LIVE_STATUS,
  EVT_S2D_MOBILE_DEVICE_ALERT,
  DirectorLiveStatus,
} from '../../interfaces/socket-events';
import { getMappingFromIdentity } from '../session/participant-helpers.service';

type IdentityKeys = 'account_id' | 'identity' | 'ovra_id' | 'ovra_user_id';
@Injectable()
export class DirectorSocketService extends BaseSocketService {
  directorSession$ = new BehaviorSubject<DirectorSessionInfo>(null);
  directorOnline$: Observable<DirectorLiveStatus & { SessionID: number }>;
  showDeviceAlert$: Observable<{
    type: string;
    title: string;
    msg: string;
    from: string;
  }>;

  constructor(socketProvider: ISocketIoLibService) {
    super(socketProvider);
    this.showDeviceAlert$ = this.anySocketEvent$.pipe(
      filter((event) => event.eventName === EVT_S2D_MOBILE_DEVICE_ALERT),
      map((msg) => ({
        ...(msg.data as { title: string; msg: string; type: string }),
        from: msg.from,
      }))
    );
    this.directorOnline$ = this.getSocketEventByName<DirectorLiveStatus & { SessionID: number }>(
      EVT_D2S_DIRECTOR_LIVE_STATUS
    ).pipe(map((r) => r.data));
  }

  // Notify others that director is online.
  // TODO: Move this to director socket extension.
  async sendDirectorOnline(directorOnlineInfo: DirectorOnlineInfo) {
    this.emitSocket(
      EVT_D2S_DIRECTOR_ONLINE,
      {
        ovra_id: 0,
      },
      MyIdentitySendType.NO_IDENTITY
    );
    const filterFunction = (message: ReceiveSocketMessageInfo<SocketData>) =>
      // eslint-disable-next-line eqeqeq
      message.data.SessionID == +directorOnlineInfo.session;
    await this.waitForAck(filterFunction, EVT_D2S_DIRECTOR_LIVE_STATUS);
  }

  async sendForceRelease() {
    this.emitSocket(EVT_D2S_DIRECTOR_FORCE_RELEASE, { ovra_id: 0 }, MyIdentitySendType.NO_IDENTITY);
  }

  async sendSessionSettingsChanged(data) {
    this.emitSocket(EVT_D2S_DIRECTOR_SESSION_SETTINGS_CHANGED, data, MyIdentitySendType.NO_IDENTITY);
  }

  async sendAdmissionResponse(data: { loginId: number; SessionID: number; approved: boolean; userRole: UserRoleType }) {
    this.emitSocket(EVT_ADMISSION_REQUEST_RESPONSE, data, MyIdentitySendType.NO_IDENTITY);
  }

  async requestToJoin(name: string, loginId: number, sessionInfo: DirectorSessionInfo) {
    this.directorSession$.next(sessionInfo);
    const payload: SocketRequestToJoin = { name, id: loginId };
    this.emitSocket(EVT_SUBJECT_REQUEST_TO_JOIN, payload, MyIdentitySendType.NO_IDENTITY);
  }

  async joinRoom(sessionInfo: DirectorSessionInfo) {
    this.directorSession$.next(sessionInfo);
    this.emitSocket(
      EVT_SEND_JOIN_ROOM,
      {
        token: sessionInfo.token,
        device_type: sessionInfo.deviceType,
        user_type: sessionInfo.userType,
        ovra_id: 0,
      },
      MyIdentitySendType.NO_IDENTITY
    );
    this.emitSocket(
      EVT_SEND_JOIN_ROOM_ACC,
      {
        account_id: sessionInfo.accountId.toString(),
        token: sessionInfo.token,
        identity: '',
        device_type: sessionInfo.deviceType,
        user_type: sessionInfo.userType,
      },
      MyIdentitySendType.ACCOUNT_ID
    );
    try {
      const filterFunction = (message: ReceiveSocketMessageInfo<{ token: string }>) =>
        message.data.token === sessionInfo.token;
      await merge(
        this.waitForAck(filterFunction, EVT_SEND_JOIN_ROOM),
        this.waitForAck(filterFunction, EVT_SEND_JOIN_ROOM_ACC)
      ).toPromise();
    } catch (err) {
      this.directorSession$.next(null);
      throw err;
    }
  }

  // Send message to this particular participant.
  emitSocketTo<SendType>(eventName: string, data: SendType, identity: string) {
    this.emitSocket(eventName, { ...data, identity }, MyIdentitySendType.NO_IDENTITY);
  }

  // Send message to these particular participants.
  emitSocketToArr<SendType>(eventName: string, data: SendType, identityArr: string[]) {
    identityArr = identityArr.filter((i) => !!i);
    if (identityArr.length > 0) {
      this.emitSocket(eventName, { ...data, identityArr }, MyIdentitySendType.NO_IDENTITY);
    }
  }

  // Wait for messages from multiple participants.
  async waitForMultipleAck(
    waitFromIdentities: string[],
    eventName: string,
    // if true, if any of subjects respond successfuly, promise will resolve.
    // if false, if all of subjects respond successfuly, promise will resolve
    anyForSuccess: boolean,
    // pass this to optionally get notified when each subject responds (either error or success)
    acks$?: Subject<MultiSendSocketCompleteInfo>,
    ackTimeout = 5000
  ) {
    let didAnyoneError = false;
    let didAnyoneComplete = false;
    const promises = waitFromIdentities.map((id) =>
      this.waitForAck((msg) => waitFromIdentities.indexOf(msg.from) !== -1, eventName, ackTimeout).then(
        () => {
          // when one of the subjects respond
          if (acks$) {
            acks$.next({ success: true, from: id });
          }
          didAnyoneComplete = true;
          return Promise.resolve();
        },
        (err: Error) => {
          // when one of the subject errors
          if (acks$) {
            acks$.next({ success: false, from: id, errorMessage: err.message });
          }
          didAnyoneError = true;
          return Promise.resolve();
        }
      )
    );
    await Promise.all(promises);
    if (acks$) {
      acks$.complete();
    }
    if (anyForSuccess) {
      if (!didAnyoneComplete) {
        throw new Error('Noone responded successfuly to the message');
      }
    }
    if (!anyForSuccess) {
      if (didAnyoneError) {
        throw new Error('Somebody from subjects responded with an error');
      }
    }
  }

  basicSocketEmit<SendValueType>(event: string, value: SendValueType) {
    this.emitSocket<SendValueType>(event, value, MyIdentitySendType.IDENTITY);
  }

  // Emit socket and automatically append session id and my identity. My
  // identity may be sent in a different way. Typically, we send IDENTITY when
  // talking to subjects, and some other way when talking to backend or
  // other director/collaborator.
  emitSocket<SendType>(eventName: string, data: SendType, howToSendMyIdentity: MyIdentitySendType) {
    this.directorSession$
      .pipe(
        filter((session) => !!session),
        first()
      )
      .subscribe((session) => {
        const newData: SendType & {
          SessionID: number;
          userid?: string;
        } & Partial<Record<IdentityKeys, string | number>> = {
          ...data,
          SessionID: parseInt(session.session, 10),
        };

        switch (howToSendMyIdentity) {
          case MyIdentitySendType.ACCOUNT_ID:
            newData.account_id = session.accountId;
            break;
          case MyIdentitySendType.IDENTITY:
            newData.identity = session.identity;
            break;
          case MyIdentitySendType.NO_IDENTITY:
            break;
          case MyIdentitySendType.OVRA_ID:
            newData.ovra_id = session.ovraId;
            break;
          case MyIdentitySendType.OVRA_USER_ID:
            newData.ovra_user_id = getMappingFromIdentity(session.identity);
            break;
          case MyIdentitySendType.OVRA_ID_OVRA_USER_ID:
            newData.ovra_user_id = getMappingFromIdentity(session.identity);
            newData.userid = session.ovraId;
            break;
        }
        if (session) {
          this.socket.emit(eventName, newData);
        } else {
          throw new Error('Tried to emit socket while not joined to the room.');
        }
      });
  }

  // Wait for acknowledgment from particular participant. If timeout is reached
  // error is thrown.
  waitForAckFrom<T>(identity: string, eventName: string, ackTimeout = BaseSocketService.ackTimeoutTime) {
    return this.waitForAck<T>((msg) => msg.from === identity, eventName, ackTimeout);
  }
  // Wait for acknowledgment. If timeout is reached error is thrown. Filter
  // function must be provided in order to tell system which function should
  // be listened to. First message for which filterFunction returns true is
  // considered to be acknowledgement message.
  async waitForAck<T>(
    // First message for which filterFunction returns true is considered to be
    // acknowledgement message.
    filterFunction: (message: ReceiveSocketMessageInfo<T>) => boolean,
    eventName: string,
    ackTimeout = BaseSocketService.ackTimeoutTime
  ) {
    return await this.getSocketEventByName<T>(eventName)
      .pipe(
        filter(filterFunction),
        tap((value) => {
          const anyData = value.data as T & { stat: number; message: string };
          // eslint-disable-next-line eqeqeq
          if ('stat' in anyData && anyData.stat != 1) {
            throw new Error(anyData.message);
          }
        }),
        take(1),
        map((val) => val.data),
        timeoutWith(ackTimeout, throwError(new SocketAckTimeout(`${eventName} Ack timeout`)))
      )
      .toPromise();
  }
}
