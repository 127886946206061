<openreel-permissioned-list-item
  [entityDetailsTemplate]="userTemplate"
  [entity]="user"
  permissionedEntityType='user'
></openreel-permissioned-list-item>

<ng-template #userTemplate let-userDetails>
  <div class="user" fxLayoutAlign="flex-start center" fxLayoutGap="15px">
    <div class="user-avatar">
      <mat-icon>person</mat-icon>
    </div>

    <div class="user-details" fxLayout="column">
      <span class="user-name paragraph-p100">{{ userDetails.fullname }}</span>
      <span class="user-email paragraph-p100">{{ userDetails.email }}</span>
    </div>
  </div>
</ng-template>
