export enum DrawableShapeType {
  Rectangle = 'rectangle',
  Arrow = 'arrow',
}

export enum DrawableShapeColor {
  White = '#ffffff',
  Red = '#c02c2c',
  Blue = '#0066ff',
}
