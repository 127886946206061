import { CanvasKit } from '../skottie/canvas-kit';
import { TextBoxMeasurer, TextBoxMeasurerProps } from '@openreel/creator/common';
import { LoadedFont, processTextForSkiaMeasurer } from '@openreel/common';

export class TextBoxCanvasKitMeasurer implements TextBoxMeasurer {
  constructor(private readonly CanvasKit: CanvasKit) {}

  measureTextBox(props: TextBoxMeasurerProps, fonts: LoadedFont[]) {
    const font = fonts.find((f) => f.fontFamily === props.font.fontFamily && f.fontVariant === props.font.fontVariant);
    if (!font) {
      throw new Error('Font not found.');
    }

    const result = this.CanvasKit.MeasureTextBox(
      processTextForSkiaMeasurer(props.text),
      font.fontFile,
      props.fontSize,
      props.maxWidth,
      props.lineHeight * props.fontSize
    );

    return {
      width: result.width,
      height: result.height,
    };
  }
}
