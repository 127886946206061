<h1 mat-dialog-title *ngIf="data.participant$ | async as participant">{{ participant.name }} device performance</h1>
<mat-dialog-content *ngIf="data.participant$ | async as participant">
  <div fxLayout="column" fxLayoutGap="30px">
    <div>
      <div class="title-main">Internet Speed</div>
      <span class="vol-bars" *ngIf="!isRemoteIos">
        <span class="vol-bars-active" [style.width]="(networkQuality / 5) * 100 + '%'"></span>
      </span>
      <div style="margin-top: 10px">
        <div>Download: {{ downloadSpeed }}</div>
        <div>Upload: {{ uploadSpeed }}</div>
      </div>
    </div>
    <div *ngIf="!isRemoteIos">
      <div class="single-column" fxLayout="column">
        <div class="single-column-title" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="15px">
          <div class="title-main">CPU</div>
          <div *ngIf="!isOnWeb">Speed: {{ cpuSpeed }}</div>
          <div>CPUs: {{ cpuCount }}</div>
        </div>
        <div
          class="single-column-title"
          fxLayout="row"
          fxLayoutAlign="space-around"
          fxLayoutGap="15px"
          *ngIf="participant.deviceProperties.storageGB"
        >
          <div class="title-main">Available Storage</div>
          <div>{{ participant.deviceProperties.storageGB | number: '1.1-1' }} GB</div>
        </div>
        <div
          class="single-column-title"
          fxLayout="row"
          fxLayoutAlign="space-around"
          fxLayoutGap="15px"
          *ngIf="participant.deviceProperties.browserAnimationFps"
        >
          <div class="title-main">Performance</div>
          <div [ngSwitch]="gradeFpsAsPerformance(participant.deviceProperties.browserAnimationFps)">
            <div *ngSwitchCase="4" style="color: green" matTooltip="Browser is providing optimal performance">
              Excellent
            </div>
            <div *ngSwitchCase="3" style="color: greenyellow" matTooltip="Browser is performing well">Good</div>
            <div
              *ngSwitchCase="2"
              style="color: yellow"
              matTooltip="Browser could use improvement; look for ways to improve (clear cache, close other applications/tabs, etc.)"
            >
              Fair
            </div>
            <div
              *ngSwitchDefault
              style="color: red"
              matTooltip="Browser is struggling; we recommend improving performance before recording (clear cache, close other applications/tabs, etc.)"
            >
              Poor
            </div>
          </div>
        </div>

        <ng-container *ngIf="!isOnWeb">
          <div class="progress-title">Usage: {{ cpuUsage }}</div>
          <mat-progress-bar [value]="cpuUsage"></mat-progress-bar>
          <div class="progress-title">Application usage: {{ cpuAppUsage }}</div>
          <mat-progress-bar [value]="cpuAppUsage"></mat-progress-bar>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!isOnWeb && !isRemoteIos">
      <div class="single-column" fxLayout="column">
        <div class="single-column-title" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="15px">
          <div class="title-main">MEM</div>
          <div>Total: {{ memory }}</div>
          <div class="progress-title">Usage: {{ memoryUsage }}</div>
        </div>
        <mat-progress-bar [value]="memoryUsage"></mat-progress-bar>
        <div class="progress-title">Application usage: {{ memoryAppUsage }}</div>
        <mat-progress-bar [value]="memoryAppUsage"></mat-progress-bar>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button (click)="close()">
    <mat-icon color="primary">close</mat-icon>
  </button>
  <button mat-button (click)="refresh()" *ngIf="!isRemoteIos">
    <mat-icon color="primary">refresh</mat-icon>
  </button>
</div>
