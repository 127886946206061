import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'openreel-brandkit-preview',
  templateUrl: './brandkit-preview.component.html',
  styleUrls: ['./brandkit-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
  ],
})
export class BrandkitPreviewComponent {
  @Input() logoSrc: string | null = null;
  @Input() watermarkSrc: string | null = null;
  @Input() fontName: string | null = null;
  @Input() color: string | null = null;

  readonly defaultColor = '#0066ff';
}
