import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthService,
  Cleanupable,
  DirectorUserDetails,
  FeatureFlaggingService,
  GUEST_USER_COMPANY_WEBSITE,
  ROUTE_DIRECTOR_LOGIN,
  UserRoleType
} from '@openreel/frontend/common';
import { debounceTime, filter, first, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';
import { Userpilot } from 'userpilot';
import { selectDirectorUserDetails, selectAuthRole } from '../app-state/auth/auth.selector';
import { NavigationService } from './navigation.service';
import { fetchDirectorUserDetails } from '../app-state/auth/auth.actions';
import { logout } from '../app-state/session/session.actions';
import { BrandKitSettingsStore } from '../settings/brand-kit-settings/brandkit-settings.store';
import { BrandKitAutofetchService } from '../settings/brand-kit-settings/brand-kit-autofetch/brand-kit-autofetch.service';
import { BRANDING_AUTOFETCH, SELF_SIGNUP_UI_V2 } from '@openreel/common';

@Component({
  selector: 'openreel-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [NavigationService, BrandKitSettingsStore, BrandKitAutofetchService],
})
export class NavigationComponent extends Cleanupable implements OnInit, OnDestroy {
  baseurl: string;
  search: string;
  webinarAllowed = false;
  userDetails: DirectorUserDetails;

  title = 'OpenReel';
  subtitle = '';
  titleRoute: string | string[];

  showHeader$ = this.navigationService.showHeader$.pipe(debounceTime(0));

  constructor(
    private readonly navigationService: NavigationService,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly brandkitSettingsStore: BrandKitSettingsStore,
    private readonly featureFlagService: FeatureFlaggingService,
  ) {
    super();
    this.subscriptions.push(
      this.navigationService.navigationTitleOptions$.asObservable().subscribe((options) => {
        this.title = options.title;
        this.subtitle = options.subtitle;
        this.titleRoute = options.route;
      })
    );
  }

  ngOnInit() {
    this.store
      .select(selectAuthRole)
      .pipe(
        filter((auth) => auth === UserRoleType.Internal),
        first(),
        tap(() => this.store.dispatch(fetchDirectorUserDetails()))
      )
      .subscribe(() => {
        this.getUserDetails();
      });

      this._showBrandkitSettings();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onSearchChanged(newSearchText: string) {
    this.navigationService.searchInput$.next(newSearchText);
  }

  identifyUserPilot(userDetails: DirectorUserDetails) {
    Userpilot.identify(userDetails.id.toString(), {
      accountId: userDetails.account_id,
      userId: userDetails.id,
      packageCapture: userDetails.site_users?.packageCapture,
      packageWebinar: userDetails.site_users?.packageWebinar,
      packageCreator: userDetails.site_users?.packageCreator,
      packageHosting: userDetails.site_users?.packageHosting,
    });
  }

  public onLogoutButtonClicked(): void {
    const isLogoutAllowed = this.authService.softLogoutRequest();

    if (isLogoutAllowed) {
      /**
       * Logs out the user if redirect from the active route is allowed and performed successfully.
       *
       * Rejects premature token invalidation and logout if redirect is now allowed.
       * Example: CanDeactivate guard of the component returns `false`
       */
      this.router.navigateByUrl(`/director/${ROUTE_DIRECTOR_LOGIN}`).then((isRedirectSuccessful) => {
        if (isRedirectSuccessful) {
          this.authService.logout();
          this.store.dispatch(logout());
          this.authService.resetActiveSession();
        }
      });
    }
  }

  getUserDetails() {
    this.store
      .select(selectDirectorUserDetails)
      .pipe(filter((user) => !!user))
      .subscribe(
        (res) => {
          this.userDetails = res;
          //send user identity to user pilot
          this.identifyUserPilot(res);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  private _showBrandkitSettings() {
    const companyWebsite = sessionStorage.getItem(GUEST_USER_COMPANY_WEBSITE);
    if(!companyWebsite) return;
    sessionStorage.removeItem(GUEST_USER_COMPANY_WEBSITE);

    this.mortalize(
      combineLatest([
        this.featureFlagService.isFeatureFlagEnabled(SELF_SIGNUP_UI_V2),
        this.featureFlagService.isFeatureFlagEnabled(BRANDING_AUTOFETCH)
      ])
      .pipe(
        take(1),
        switchMap(([isSelfSignupUiV2Enabled, isBrandAutofetchEnabled]) => {
          if(!isSelfSignupUiV2Enabled || !isBrandAutofetchEnabled) return EMPTY;
          this.brandkitSettingsStore.loadBrandKit();
          return this.brandkitSettingsStore.brandKit$.pipe(
            filter(brandkit => !!brandkit),
            take(1)
          )
        }),
      )
    ).subscribe((brandkit) => {
      brandkit.companyInfo.website = companyWebsite;
      this.brandkitSettingsStore.updateBrandkit(brandkit);
      this.brandkitSettingsStore.generateBrandKit(companyWebsite);
    })
  }
}
