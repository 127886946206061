import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import videojs from 'video.js';
import { VideoJsPlayerOptions } from 'video.js';
import { MaterialIconModule } from '../../lib/material-icon.module';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { CommonModule } from '@angular/common';

export interface PlayRecordingPopupInput {
  loading?: boolean;
  url?: string;
  type?: string;
  title?: string;
  autoplay?: true;
  urlLoader?: Promise<string>;
}

@Component({
  selector: 'openreel-play-recording-popup',
  templateUrl: './play-recording-popup.component.html',
  styleUrls: ['./play-recording-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    MaterialIconModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyButtonModule,
    CommonModule,
  ],
})
export class PlayRecordingPopupComponent implements OnInit, OnDestroy {
  isLightThemed = true;
  isPopup = true;
  isLoading = false;

  @ViewChild('target', { static: true }) target: ElementRef;
  options: VideoJsPlayerOptions;
  player: videojs.Player;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PlayRecordingPopupInput,
    private dialogRef: MatDialogRef<PlayRecordingPopupComponent>
  ) {}

  ngOnInit() {
    if (this.data.loading) {
      this.isLoading = true;

      if (this.data.urlLoader) {
        this.data.urlLoader.then((url) => {
          this.data.url = url;
          this.initVideoPlayer(this.data);
        });
      }
    } else {
      this.initVideoPlayer(this.data);
    }

    this.title = this.data.title || 'Play Recording';
  }

  initVideoPlayer(data: PlayRecordingPopupInput) {
    this.isLoading = false;

    // instantiate Video.js
    this.options = {
      width: 650,
      sources: [{ src: data.url, type: data.type }],
      preload: 'auto',
      muted: false,
      autoplay: data.autoplay,
      bigPlayButton: true,
      controls: true,
    };
    // for live video remove withCredentials
    const html5 = data.type?.includes('x-mpegURL')
      ? {}
      : {
          vhs: {
            withCredentials: true,
          },
          mp4: {
            withCredentials: true,
          },
        };
    this.options = { ...this.options, html5 };
    this.player = videojs(this.target.nativeElement, this.options);
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  closePopup() {
    this.dialogRef.close();
  }
}
