import { LoadedFont } from '@openreel/common';
import { cloneDeep } from 'lodash';

export const loadedFontsToCanvasKitFonts = (loadedFonts: Map<string, LoadedFont>): Record<string, ArrayBuffer> => {
  const canvasKitFonts: Record<string, ArrayBuffer> = {};

  loadedFonts.forEach((value, key) => {
    canvasKitFonts[key] = value.fontFile;
  });

  // also inject Roboto font for hard-coded text fields in lotties.
  if (canvasKitFonts['Roboto_400_normal']) {
    canvasKitFonts['Roboto'] = cloneDeep(canvasKitFonts['Roboto_400_normal']);
  }

  return canvasKitFonts;
};
