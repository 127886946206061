import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[orDragOver]',
  standalone: true,
})
export class DragOverDirective {
  @Input()
  dragEnabled = true;

  @HostListener('dragenter')
  dragEnter() {
    this.dragged = this.dragEnabled;
  }

  @HostListener('dragleave')
  dragLeave() {
    this.dragged = false;
  }

  @HostListener('dragover', ['$event'])
  dragOver(evt: Event) {
    if (this.dragEnabled) evt.preventDefault();
  }

  @HostListener('drop')
  drop() {
    this.dragged = false;
  }

  @HostBinding('class.dragged')
  dragged = false;
}
