import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit} from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AsyncPipe, NgFor, NgIf} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {Observable, of, Subject, combineLatest} from "rxjs";
import {YOUTUBE_ALLOWED_MAX_VIDEO_DURATION_FOR_UPLOAD, YoutubeVideoPrivacyStatus} from "@openreel/common";
import {YoutubeChannel} from "@openreel/frontend/common";
import { YoutubeSharingStore } from './youtube-sharing.store';


interface DialogData {
  videoId: number;
  videoDuration: number;
}

@Component({
  selector: 'or-youtube-sharing-dialog',
  standalone: true,
  templateUrl: './youtube-sharing-dialog.component.html',
  styleUrls: ['./youtube-sharing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgIf,
    NgFor,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
  ],
})
export class YoutubeSharingDialogComponent implements OnInit, OnDestroy {
  readonly isLightThemed = true;
  readonly isPopup = true;
  readonly VideoPrivacyStatus = YoutubeVideoPrivacyStatus;

  private readonly data: DialogData = inject(MAT_DIALOG_DATA);
  private readonly dialogRef = inject(MatDialogRef);
  private readonly youtubeSharingStore = inject(YoutubeSharingStore);
  private readonly destroySub$ = new Subject<void>();

  private sharingFormValidator() {
    return (fg: FormGroup) => {
      return this.selectedChannel$.pipe(
        take(1),
        tap(selectedChannel => {
          if(!selectedChannel || selectedChannel.allowedIntermediateFeatures) return;
          const videoDurationControl = fg.controls['videoDuration'];
          if(videoDurationControl.value < YOUTUBE_ALLOWED_MAX_VIDEO_DURATION_FOR_UPLOAD) return;
          const channelControl = fg.controls['channelId'];
          channelControl.setErrors({ uploadNotAllowed: true })
        }),
        map(_ => null)
      )
    }
  }

  protected sharingForm = new FormGroup({
    videoId: new FormControl<number>(this.data.videoId, [Validators.required]),
    videoDuration: new FormControl<number>(this.data.videoDuration, [Validators.required]),
    integratedUserId: new FormControl<number>(null, [Validators.required]),
    channelId: new FormControl<string>(null, [Validators.required]),
    privacyStatus: new FormControl<YoutubeVideoPrivacyStatus>(YoutubeVideoPrivacyStatus.Unlisted, [Validators.required])
  }, [], [this.sharingFormValidator()])

  integratedUsers$ = this.youtubeSharingStore.integratedUsers$;
  userChannels$: Observable<YoutubeChannel[]> = this.sharingForm.controls.integratedUserId.valueChanges.pipe(
    switchMap(userId => !userId ? of([]) : this.youtubeSharingStore.selectIntegratedUserChannels(userId))
  )

  selectedChannel$: Observable<YoutubeChannel> = combineLatest([
    this.userChannels$,
    this.sharingForm.controls.channelId.valueChanges.pipe(startWith(this.sharingForm.controls.channelId.value))
  ]).pipe(
    map(([channels, channelId]) => {
      if(!channelId || !channels.length) return null;
      return channels.find(channel => channel.id == channelId)
    }),
    shareReplay(1)
  )

  ngOnInit() {
    this.sharingForm
      .controls
      .integratedUserId
      .valueChanges
      .pipe(
        takeUntil(this.destroySub$),
        filter(userId => !!userId),
      )
      .subscribe(userId => {
        this.youtubeSharingStore.fetchUserChannels(userId);
        this.sharingForm.controls.channelId.setValue(null);
      })
  }

  ngOnDestroy() {
    this.destroySub$.next();
    this.destroySub$.complete();
  }

  share() {
    if(this.sharingForm.valid) {
      this.dialogRef.close(this.sharingForm.value);
    }
  }
}
