import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Video } from '@openreel/frontend/common';
import { Ng5SliderModule, Options } from 'ng5-slider';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Cleanupable } from '../../../classes/cleanupable';
import { Trimming } from '../transformation.interface';
import { CommonModule } from '@angular/common';

const asDecimal = (n: number) =>
  n.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const asInt = (n: number) =>
  n.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });

const translate = (value: number): string => {
  let output = '';

  const totalSeconds = value / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  if (hours > 0) {
    output = `${asInt(hours)}:`;
  }

  return output + `${asInt(minutes)}:${asDecimal(seconds)}`;
};

@Component({
  selector: 'openreel-trimmer',
  templateUrl: './trimmer.component.html',
  styleUrls: ['./trimmer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, Ng5SliderModule],
})
export class TrimmerComponent extends Cleanupable implements OnInit, OnDestroy {
  @Input()
  video$: BehaviorSubject<Video>;

  @Output()
  trimmingChange = new EventEmitter<Trimming>();

  minValue = 0;
  maxValue = 100;
  isReady = false;

  frames: string[];

  options: Options = {
    floor: 0,
    ceil: 100,
    noSwitching: true,
    showOuterSelectionBars: true,
    translate: translate,
  };

  private frameCount = 6;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.video$
        .pipe(
          tap(() => (this.isReady = false)),
          filter((video) => video !== null && video !== undefined),
          mergeMap((video) => this.createFrames(video)),
          withLatestFrom(this.video$)
        )
        .subscribe(([frames, video]) => {
          this.frames = frames;
          this.options = {
            ...this.options,
          };
          this.options.ceil = video.duration;
          this.maxValue = video.duration;
          this.isReady = true;
        })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  emitCurrentTrimming() {
    this.trimmingChange.emit({
      startTime: this.minValue,
      endTime: this.maxValue,
    });
  }

  createFrames(video: Video): Observable<string[]> {
    return new Observable<string[]>((observer) => {
      const frames = [];
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const videoElem = document.createElement('video');

      let seekedResolve;
      videoElem.onseeked = () => {
        if (seekedResolve) {
          context.drawImage(videoElem, 0, 0, videoElem.videoWidth, videoElem.videoHeight);
          frames.push(canvas.toDataURL());
          seekedResolve();
        }
      };

      videoElem.onloadedmetadata = async () => {
        canvas.width = videoElem.videoWidth;
        canvas.height = videoElem.videoHeight;

        const skip = video.duration / this.frameCount;

        for (let time = 0; time < video.duration; time += skip) {
          videoElem.currentTime = time;
          await new Promise((r) => (seekedResolve = r));
        }

        observer.next(frames);
        videoElem.remove();
        canvas.remove();
      };

      videoElem.src = video.mainStream;
    });
  }
}
