<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
<openreel-upload-indicator></openreel-upload-indicator>
<openreel-salesforce-chat> </openreel-salesforce-chat>
<a
  mat-fab
  color="primary"
  href="#"
  data-acsb-custom-trigger="true"
  style="position: fixed; bottom: 10px; right: 75px; z-index: 10; line-height: 52px"
>
  <mat-icon>accessibility</mat-icon>
</a>
