import { TEXT_BOX_PRESET_FIELD_NAME } from '../constants';
import {
  AssetLayer,
  Bounds,
  Layer,
  LayerOptions,
  LottieLayer,
  Section,
  SectionLayer,
  Sections,
  SectionType,
  SolidColor,
  SectionTimeline,
  VideoLayer,
  VideoLayerInfo,
  WorkflowDataDto,
  Timeline,
  TimelineType,
  LayerType,
} from '../interfaces';
import { getSectionTypeTimelinesByType, getTimelinesByTypeAndSectionId } from './workflow.helpers';

export interface LayerData {
  sectionId: string;
  layer: Layer;
  timeline?: SectionTimeline;
  timelineId?: string;
}

// Copied from @openreel/frontend/common -> move to common library for frontend/backend
const padZerosStart = (num: number, maxLength: number) => num.toString().padStart(maxLength, '0');

export function pctToPx(percent: number, bound: number) {
  return Math.round((percent * bound) / 100);
}

export const formatMillisecondsAsTime = (ms: number): string => {
  const h = Math.floor(ms / 3_600_000);
  const m = Math.floor((ms - h * 3_600_000) / 60_000);
  const s = Math.floor((ms - h * 3_600_000 - m * 60_000) / 1_000);
  ms = Math.trunc(ms - h * 3_600_000 - m * 60_000 - s * 1_000);

  return `${h ? `${h}:` : ''}${padZerosStart(m, h ? 2 : 0)}:${padZerosStart(s, 2)}.${padZerosStart(ms, 3)}`;
};

export function getSectionsOfType(sections: Sections, sectionType: SectionType) {
  return Object.keys(sections)
    .map((sectionId) => ({ sectionId, section: sections[sectionId] }))
    .filter(({ section }) => section.sectionType === sectionType);
}

export const getTimelineById = (id: string, sections: Sections, sectionType: SectionType = 'main') => {
  if (!id) {
    return null;
  }

  const sectionsOfType = getSectionsOfType(sections, sectionType);

  for (const { section, sectionId } of sectionsOfType) {
    const timeline = section.timelines.find((t) => t.id === id);
    if (timeline) {
      return { timeline, sectionId };
    }
  }

  return null;
};

export interface EditableLayer {
  sectionId: string;
  sectionName: string;
  layerId: string;
  layerType: LayerType;
  // Lottie values
  fieldId?: string;
  isTextBox?: boolean;
  defaultValue?: string;
}

export function getEditableLayers(workflow: WorkflowDataDto) {
  const ALLOWED_COMBINATIONS: [SectionType, TimelineType, LayerType][] = [
    ['intro', 'main', 'video'],
    ['intro', 'main', 'lottie'],
    ['intro', 'overlays', 'lottie'],
    ['main', 'main', 'video'],
    ['main', 'overlays', 'lottie'],
    ['main', 'text-boxes', 'lottie'],
    ['outro', 'main', 'video'],
    ['outro', 'main', 'lottie'],
    ['outro', 'overlays', 'lottie'],
  ];
  const editableLayers: EditableLayer[] = [];

  let mainSceneCounter = 0;

  for (const layer of workflow.timelines[0].layers) {
    const { sectionId } = layer as SectionLayer;
    const { sectionType, timelines } = workflow.sections[sectionId];

    if (sectionType === 'main') {
      mainSceneCounter++;
    }

    for (const timeline of timelines) {
      for (const layer of timeline.layers) {
        if (
          !ALLOWED_COMBINATIONS.some(([st, tt, lt]) => st === sectionType && tt === timeline.type && lt === layer.type)
        ) {
          continue;
        }

        let sectionName = '';
        switch (sectionType) {
          case 'intro':
            sectionName = 'Intro';
            break;
          case 'main':
            sectionName = `Scene ${mainSceneCounter}`;
            break;
          case 'outro':
            sectionName = 'Outro';
            break;
        }

        if (layer.type === 'video') {
          editableLayers.push({
            sectionId,
            sectionName,
            layerId: layer.layerId,
            layerType: 'video',
          });
        } else if (layer.type === 'lottie' && layer.isTextBox) {
          editableLayers.push({
            sectionId,
            sectionName,
            layerId: layer.layerId,
            layerType: 'lottie',
            fieldId: null,
            isTextBox: true,
            defaultValue: layer.data[TEXT_BOX_PRESET_FIELD_NAME].value,
          });
        } else if (layer.type === 'lottie' && !layer.isTextBox) {
          for (const [fieldId, field] of Object.entries(layer.data).filter(([, field]) => field.type === 'text')) {
            editableLayers.push({
              sectionId,
              sectionName,
              layerId: layer.layerId,
              layerType: 'lottie',
              fieldId,
              isTextBox: false,
              defaultValue: field.value,
            });
          }
        }
      }
    }
  }

  return editableLayers;
}

export function* getLayers(
  workflow: WorkflowDataDto,
  options?: {
    types?: string[];
    sectionIds?: string[];
  }
): Generator<{ layer: Layer; timeline?: Timeline; section?: Section }> {
  const doesTypeMatch = (layer: Layer) => !options?.types || options.types.includes(layer.type);
  const sectionMatches = (sectionId: string) => !options?.sectionIds || options.sectionIds.includes(sectionId);

  function* getLayersFromTimeline(section: Section, timeline: Timeline) {
    for (const layer of timeline.layers) {
      if (doesTypeMatch(layer)) {
        yield { layer, timeline, section };
      }
    }
  }

  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  for (const layer of mainTimeline.layers) {
    if (layer.type !== 'section') {
      continue;
    }

    if (!sectionMatches(layer.sectionId)) {
      continue;
    }

    if (!doesTypeMatch(layer)) {
      continue;
    }

    yield { layer, timeline: mainTimeline };
  }

  const sections: [string, Section][] = Object.entries(workflow.sections);
  for (const [sectionId, section] of sections) {
    if (!sectionMatches(sectionId)) {
      continue;
    }

    for (const timeline of section.timelines) {
      yield* getLayersFromTimeline(section, timeline);
    }
  }

  const transitionLayers = workflow.timelines[0].layers
    .filter((l) => l.type === 'section' && l.transitions?.crossLayer)
    .map((l) => l.transitions.crossLayer.layer);

  for (const layer of transitionLayers) {
    if (doesTypeMatch(layer)) {
      yield { layer };
    }
  }
}

export const getSectionLayer = (sectionId: string, workflow: WorkflowDataDto) => {
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  return mainTimeline.layers.find((l) => l.type === 'section' && l.sectionId === sectionId) as LayerOptions &
    SectionLayer;
};

export const getLayerFromId = (layerId: string, workflow: WorkflowDataDto): LayerData => {
  function checkTimeline(timeline: SectionTimeline, sectionId: string) {
    for (const layer of timeline.layers) {
      if (layer.layerId === layerId) {
        return { sectionId, layer, timeline, timelineId: timeline.id };
      }
    }

    return null;
  }

  const sections: [string, Section][] = Object.entries(workflow.sections);
  for (const [sectionId, section] of sections) {
    for (const timeline of section.timelines) {
      const result = checkTimeline(timeline, sectionId);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export function getTimelineLayers(timelineId: string, workflow: WorkflowDataDto) {
  const timelineInfo = getTimelineById(timelineId, workflow.sections);
  if (!timelineInfo) {
    return [];
  }

  const { timeline } = timelineInfo;

  const layers = timeline.layers.map((layer: LayerOptions & VideoLayer) => {
    const asset = workflow.assets.find((a) => a.id === layer.assetId);
    const duration = layer.visibility.endAt - layer.visibility.startAt;

    const videoLayerInfo: VideoLayerInfo = {
      layerId: layer.layerId,
      asset,
      assetFile: asset.file,
      startAt: layer.visibility.startAt,
      duration,
      tooltip: `Starts at: ${formatMillisecondsAsTime(layer.visibility.startAt)}`,
      bounds: layer.bounds,
      isPlaceholder: asset.isPlaceholder ?? false,
      hasAudio: timeline.hasAudio,
    };
    return videoLayerInfo;
  });

  return layers;
}

export function isSectionEmpty(section: Section) {
  const mainTimelines = section.timelines.filter((t) => t.type === 'main');
  if (mainTimelines.length === 0) {
    return false;
  }

  return mainTimelines.every((t) => t.layers.length === 0);
}

export const isBoundsFullScreen = (bounds: Bounds) =>
  !bounds || (bounds.x === 0 && bounds.y === 0 && bounds.width === 100 && bounds.height === 100);

export function hasLogoFields(workflow: WorkflowDataDto, sectionId: string) {
  for (const { layer } of getLayers(workflow, { types: ['lottie'], sectionIds: [sectionId] })) {
    for (const [, field] of Object.entries((layer as LottieLayer).data)) {
      if (field.type !== 'logo') {
        continue;
      }

      return true;
    }
  }

  return false;
}

export function isLogoOverriddenAtSceneLevel(workflow: WorkflowDataDto) {
  const logoAssetIds: string[] = [];

  for (const { layer } of getLayers(workflow, { types: ['lottie'], sectionIds: ['intro', 'outro'] })) {
    for (const [, field] of Object.entries((layer as LottieLayer).data)) {
      if (field.type !== 'logo') {
        continue;
      }

      logoAssetIds.push(field.assetId);
    }
  }

  const globalAssetId = workflow.globalSettings.logo.settings.enabled
    ? workflow.globalSettings.logo.settings.assetId
    : undefined;
  return logoAssetIds.some((a) => a !== globalAssetId);
}

export function isWatermarkOverriddenAtSceneLevel(workflow: WorkflowDataDto) {
  const watermarkTimelines = getSectionTypeTimelinesByType(workflow.sections, 'main', 'watermark');
  const watermarkAssetIds = watermarkTimelines.map((t) => {
    if (t.layers.length === 0) {
      return null;
    }

    return (t.layers[0] as AssetLayer).assetId;
  });

  const globalAssetId = workflow.globalSettings.watermark.settings.enabled
    ? workflow.globalSettings.watermark.settings.assetId
    : undefined;
  return watermarkAssetIds.some((a) => a !== globalAssetId);
}

export function isBackgroundOverriddenAtSceneLevel(workflow: WorkflowDataDto) {
  const backgroundTimelines = getTimelinesByTypeAndSectionId(workflow.sections, 'background');
  const backgroundValues = backgroundTimelines.map((timeline) => {
    if (timeline.layers.length === 0) {
      return null;
    }

    const layer = timeline.layers[0];
    let value = '';

    if (layer.type === 'video') {
      value = layer.assetId;
    } else if (layer.type === 'color') {
      value = workflow.styles.find((s) => s.id === layer.styleId)?.color;
    }

    return value;
  });

  const globalValue = workflow.globalSettings.backgroundAsset.settings.enabled
    ? workflow.globalSettings.backgroundAsset.settings.assetId
    : (workflow.globalSettings.backgroundColor as SolidColor).color;

  return backgroundValues.some((value) => value !== globalValue);
}
