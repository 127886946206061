import { AssetDto, CreateAssetRequest } from './assets.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AssetId } from '@openreel/creator/common';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DirectorApiService, VideoType, AssetsBaseService } from '@openreel/frontend/common';
import { getMimeTypeFromExtension, splitNameAndExtension } from '@openreel/common';

@Injectable({
  providedIn: 'root',
})
export class AssetsService extends AssetsBaseService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}assets`,
    byId: (assetId) => `${this.urls.base}/${assetId}`,
  };

  constructor(private readonly httpClient: HttpClient, private readonly directorApiService: DirectorApiService) {
    super();
  }

  createAssetUrl(data: CreateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.base, data);
  }

  uploadAsset(signedUrl: string, file: File) {
    const [, extension] = splitNameAndExtension(file.name);

    return this.httpClient.put(signedUrl, file, {
      headers: new HttpHeaders({
        'Content-Type': getMimeTypeFromExtension(extension),
      }),
      reportProgress: true,
      observe: 'events',
    });
  }

  getAssetUrlById(provider: 'url' | 'or-assets' | 'or-recordings', id: AssetId): Observable<string> {
    switch (provider) {
      case 'url':
        return of(id.toString());
      case 'or-assets':
        return this.httpClient.get<AssetDto>(this.urls.byId(id)).pipe(map((dto) => dto.read_url.url));
      case 'or-recordings':
        return from(this.directorApiService.getVideoSignedUrlWithExpiry(+id, VideoType.SD, 'play')).pipe(
          map((signedUrl) => signedUrl.url)
        );
    }
  }
}
