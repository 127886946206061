export const commonenv = {
  apiUrl: 'https://remoteapi.openreel.com/',
  hostUrl: 'https://remote.openreel.com/',
  appUrl: 'https://remote.openreel.com/',
  nextGenAppUrl: 'https://capture.bcp.openreel.cloud/',
  nextGenApiUrl: 'https://capture.bcp.openreel.cloud/api/',
  automationsApiUrl: 'https://automation.bcp.openreel.cloud/api/',
  hostingAppUrl: 'https://library.bcp.openreel.cloud/',
  hostingPlaybackApiUrl: 'https://capture.bcp.openreel.cloud/api/',
  creatorAppUrl: 'https://creator.bcp.openreel.cloud',
  webinarAppUrl: 'https://web.events.bcp.openreel.cloud/webinars-manager/events',
  awsStreambaseUrl: 'https://stream.bcp.openreel.cloud/openreel-videos/',
  liveSessionTrackID: '36db86a9.71b6bdda',
  websocketUrl: 'https://capture.bcp.openreel.cloud/',
  automationsWebsocketUrl: 'https://automation.bcp.openreel.cloud/',
  production: true,
  sentryDSN: 'https://f24b2145d1c24eba9f8f4398d4fd6030@o215724.ingest.sentry.io/5549142',
  froalaKey: 'nQE2uA2D1F1I1B1A1lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2E1C5F1B1F1H4C11==',
  sentryTracesSampleRate: 1,
  launchDarklyClientSideId: '602160a2e9ac9c09ef7504b4',
  creatorMixpanelToken: '4b3371d292c3fc2fb5033270ad921ff3',
  hostingMixpanelToken: '1c8805bc28143843402f96711fd6c9f4',
  openReelTermsConditionsUrl: 'https://www.openreel.com/terms-of-service/',
  virtualBackgroundProcessor: 'https://cdn.jsdelivr.net/npm/@shiguredo/virtual-background@latest/dist',
  effectsSDKToken: 'c4063ce89549d4c9b22d0437fb3dafc2958a157e',
};
