<div fxLayout="row" fxFill>
  <div
    fxFlex="100"
    class="sso-login-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="sso-login-box" *ngIf="companyName">
        <p>Company Name</p>
        <h3 class="company-name">{{ companyName }}</h3>
        <p class="company-txt">Your account is configured for SSO Log in</p>
        <a [href]="loginUrl" mat-raised-button color="primary">Login</a>
    </div>
  </div>
</div>
