import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OpenreelLogoComponent } from "./openreel-logo.component";


@NgModule({
    declarations: [OpenreelLogoComponent],
    imports: [CommonModule],
    exports: [OpenreelLogoComponent]
})
export class OpenreelLogoModule {}