import * as Interfaces from './global-assets.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';

@Injectable({ providedIn: 'root' })
export class GlobalAssetsTagsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}global-assets-tags`,
    byId: (globalTagId: number) => `${this.urls.base}/${globalTagId}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAllWithAssetsCount() {
    return this.httpClient.get<Interfaces.GlobalAssetsTagsWithCount[]>(this.urls.base);
  }

  update(globalTagId: number, dto: Interfaces.GlobalAssetTagDefUpdateDto) {
    return this.httpClient.put<Interfaces.GlobalAssetTagEntity>(this.urls.byId(globalTagId), dto, {
      headers: new HttpHeaders({ [CaseConverterInterceptorSkipHeader]: '' }),
    });
  }

  delete(globalTagId: number) {
    return this.httpClient.delete(this.urls.byId(globalTagId));
  }
}
