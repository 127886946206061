import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { map, of, switchMap, timer, take, finalize } from 'rxjs';
import { HostingService } from './hosting.service';

@Injectable()
export class HostingSlugValidatorService {
  previousValue: string;
  constructor(private hostingService: HostingService, private changeDetectorRef: ChangeDetectorRef) {}
  videoSlugValidator(id: number) {
    return (control: AbstractControl) => {
      if (!control.value || !id) return of(null);
      else
        return timer(500).pipe(
          take(1),
          switchMap(() =>
            this.hostingService
              .checkVideoSlugExist(id, control.value)
              .pipe(map((res) => (res.slugExist ? { slugExist: true } : null)))
          ),
          finalize(() =>
            //need for on push change detection component
            this.changeDetectorRef.markForCheck()
          )
        );
    };
  }

  hubSlugValidator(id: number) {
    return (control: AbstractControl) => {
      if (!control.value || !id) return of(null);
      else
        return timer(500).pipe(
          take(1),
          switchMap(() =>
            this.hostingService
              .checkHubSlugExist(id, control.value)
              .pipe(map((res) => (res.slugExist ? { slugExist: true } : null)))
          ),
          finalize(() =>
            //need for on push change detection component
            this.changeDetectorRef.markForCheck()
          )
        );
    };
  }
}
