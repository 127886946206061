import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService, FeatureFlaggingService } from '..';
import { WORKFLOWS_TEMPLATE, USER_UPLOAD, HOSTING } from '@openreel/common';

@Injectable({ providedIn: 'root' })
export class FeatureEnabledService {
  private onFlagOrUser$ = combineLatest([this.auth.accessDetail$, this.featureFlagService.flagChange$]).pipe(
    map(([data]) => data),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private auth: AuthService, private featureFlagService: FeatureFlaggingService) {}

  private readonly CUSTOM_ACCESS_RULES: { [key: string]: Observable<boolean> } = {
    [HOSTING]: this.onFlagOrUser$.pipe(
      map((data) => {
        const flagAllowed = this.featureFlagService.getFlag(HOSTING);
        return flagAllowed && data && data.hostingAllowed;
      })
    ),
    [WORKFLOWS_TEMPLATE]: this.onFlagOrUser$.pipe(
      map((data) => {
        const flagAllowed = this.featureFlagService.getFlag(WORKFLOWS_TEMPLATE);
        return flagAllowed && data && data.creatorAllowed;
      })
    ),
    [USER_UPLOAD]: this.onFlagOrUser$.pipe(
      map(() => {
        const flagAllowed = this.featureFlagService.getFlag(USER_UPLOAD);
        return flagAllowed;
      })
    ),
  };

  featureEnabled(feature: string): Observable<boolean> {
    const accessRule = this.CUSTOM_ACCESS_RULES[feature];
    if (accessRule) {
      return accessRule;
    }

    return this.onFlagOrUser$.pipe(map(() => this.featureFlagService.getFlag(feature)));
  }
}
