import { clamp, cloneDeep, flatten, flattenDeep, isEqual, sortBy } from 'lodash';
import {
  Asset,
  AssetsFileProviderType,
  Layer,
  LayerDataChanges,
  LayersDataChangedEvent,
  LottieLayer,
  LottieLayerData,
  File,
  Preset,
  ProjectFont,
  SectionLayer,
  Sections,
  SectionType,
  Style,
  SectionTimeline,
  TimelineType,
  VideoLayer,
  WorkflowDataDto,
  BrandKitAssets,
  LayerOptions,
  SectionId,
  TemplateLottieTransition,
  ProjectVideo,
  VideoLayerInfo,
  GlobalSettings,
  TemplateSections,
  Section,
  Bounds,
} from '../interfaces';
import { getLayerFromId, getLayers, getSectionsOfType, getTimelineLayers } from './timelines.helpers';
import { v4 as uuidv4 } from 'uuid';
import { getMaxTimelinesDuration, getTimelineDuration } from './workflow-duration.helpers';
import { FontVariant } from '@openreel/common';
import { TEXT_BOX_ASSET, TEXT_BOX_ASSET_ID } from '../constants';

const SHORTER_VIDEO_FADE_TIME = 500;
const SHORTER_VIDEO_FADE_MAX_OFFSET = 100;

export function getMainClips(dto: WorkflowDataDto, sectionId?: string, timelineFilter?: (timeline: SectionTimeline) => boolean) {
  const mainTimelines = getSectionTypeTimelinesByType(dto.sections, 'main', 'main', sectionId);
  const topLayers: Layer[] = [];

  for (const timeline of mainTimelines.filter((t) => (timelineFilter ? timelineFilter(t) : true))) {
    for (const layer of timeline.layers) {
      topLayers.push(layer);
    }
  }

  return topLayers as (LayerOptions & VideoLayer)[];
}

export function getTranscribableLayers(dto: WorkflowDataDto, includeLayersWithNoAudio = false) {
  return includeLayersWithNoAudio ? getMainClips(dto) : getMainClips(dto, undefined, (t) => t.hasAudio);
}

export function getTranscribableAssetIds(dto: WorkflowDataDto, includeLayersWithNoAudio = false) {
  return getTranscribableLayers(dto, includeLayersWithNoAudio).map((clip) => clip.assetId);
}

export function assetTypeToAssetFileProviderType(type: string): AssetsFileProviderType {
  switch (type) {
    case 'asset':
      return 'or-assets';
    case 'video':
      return 'or-recordings';
    default:
      return null;
  }
}

export function assetFileProviderTypeToAssetType(type: AssetsFileProviderType): string {
  switch (type) {
    case 'or-assets':
      return 'asset';
    case 'or-recordings':
      return 'video';
    default:
      return null;
  }
}

export function updateMainClipTransitions(workflow: WorkflowDataDto) {
  const sections = getSectionTimelines(workflow.sections, 'main', 'main');

  for (const { timelines } of sections) {
    if (timelines.length < 2) {
      return;
    }

    const mainTimelineDuration = getMaxTimelinesDuration(timelines, workflow.assets);

    const fullLengthTimelines = timelines.filter(
      (timeline) => getTimelineDuration(timeline, workflow.assets) === mainTimelineDuration
    );
    const shorterTimelines = timelines.filter(
      (timeline) => getTimelineDuration(timeline, workflow.assets) < mainTimelineDuration
    );

    for (const timeline of fullLengthTimelines) {
      for (const layer of timeline.layers) {
        layer.transitions = {};
      }
    }

    for (const timeline of shorterTimelines) {
      let lastVideoEndAt = 0;
      timeline.layers.forEach((layer, index) => {
        const { startAt, endAt } = layer.visibility;
        layer.transitions = {};

        if (startAt - lastVideoEndAt > SHORTER_VIDEO_FADE_MAX_OFFSET) {
          layer.transitions.entrance = {
            duration: SHORTER_VIDEO_FADE_TIME,
            type: 'fade-in',
          };
        }

        if (index !== timeline.layers.length - 1) {
          const nextLayer = timeline.layers[index + 1];
          if (endAt + SHORTER_VIDEO_FADE_MAX_OFFSET < nextLayer.visibility.startAt) {
            layer.transitions.exit = {
              duration: SHORTER_VIDEO_FADE_TIME,
              type: 'fade-out',
            };
          }
        }

        if (index === timeline.layers.length - 1) {
          if (endAt + SHORTER_VIDEO_FADE_MAX_OFFSET < mainTimelineDuration) {
            layer.transitions.exit = {
              duration: SHORTER_VIDEO_FADE_TIME,
              type: 'fade-out',
            };
          }
        }

        lastVideoEndAt = endAt;
      });
    }
  }
}

export function getOrderedPresetFields(preset: Preset) {
  return Object.keys(preset)
    .map((field) => ({ field, order: preset[field].order || 0 }))
    .sort((a, b) => a.order - b.order)
    .map((d) => d.field);
}

export function getChangesEventFromLottieLayer(layer: LottieLayer, workflow: WorkflowDataDto) {
  const result: LayerDataChanges = {
    assetChanges: {},
    styleChanges: {},
    values: {},
  };
  const assetId = layer.assetId;
  const asset = assetId ? workflow.assets.find((a) => a.id === assetId) : null;

  for (const key of getOrderedPresetFields(asset.preset)) {
    const presetField = asset.preset[key];
    const dataField = layer.data[key];
    const style = dataField.styleId ? workflow.styles.find((s) => s.id === dataField.styleId) : null;
    switch (presetField.type) {
      case 'text':
      case 'shape': {
        result.values[key] = {
          type: presetField.type,
          styleId: null,
          value: dataField.value,
        };
        result.styleChanges[key] = {
          color: style?.color,
        };
        break;
      }
      case 'image':
      case 'logo': {
        result.assetChanges[key] = {
          newAssetFileId: +asset?.file.path,
          removedAssetId: null,
        };
        result.values[key] = {
          type: presetField.type,
          assetId: assetId,
        };
        break;
      }
    }
  }

  return result;
}

export function getChangesEventFromLayers(layerIds: string[], workflow: WorkflowDataDto) {
  const changes: LayersDataChangedEvent = {};

  for (const layerId of layerIds) {
    const layerInfo = getLayerFromId(layerId, workflow);
    if (!layerInfo) {
      throw new Error(`Layer id: ${layerId} not found in workflow.`);
    }

    let layerChanges: LayerDataChanges;
    switch (layerInfo.layer.type) {
      case 'lottie': {
        layerChanges = getChangesEventFromLottieLayer(layerInfo.layer, workflow);
        break;
      }
      case 'image':
      case 'video': {
        const assetId = layerInfo.layer.assetId;
        const asset = assetId ? workflow.assets.find((a) => a.id === assetId) : null;
        layerChanges = {
          assetChanges: {
            default: {
              newAssetFileId: +asset?.file.path,
              removedAssetId: null,
            },
          },
          styleChanges: {},
          values: {
            default: {
              type: layerInfo.layer.type,
              assetId: assetId,
            },
          },
        };
        break;
      }
    }

    changes[layerId] = layerChanges;
  }

  return changes;
}

export function getSectionTimelines(
  sections: Sections,
  sectionType: SectionType,
  timelineType: TimelineType,
  sectionId?: string
) {
  const filteredSections = Object.entries(sections)
    .filter(([key, section]) => section.sectionType === sectionType && (!sectionId || key === sectionId))
    .map(([key, section]) => ({ sectionId: key, timelines: section.timelines }));

  const filteredSectionTimelines: { sectionId: string; timelines: SectionTimeline[] }[] = [];
  for (const section of filteredSections) {
    filteredSectionTimelines.push({
      sectionId: section.sectionId,
      timelines: section.timelines.filter((t) => t.type === timelineType),
    });
  }

  return filteredSectionTimelines;
}

export function getSectionTypeTimelinesByType(
  sections: Sections,
  sectionType: SectionType,
  timelineType: TimelineType,
  sectionId: string = null
): SectionTimeline[] {
  return getSectionTypeTimelines(sections, sectionType, sectionId).filter((t) => t.type === timelineType);
}

export function getSectionTypeTimelines(
  sections: Sections,
  sectionType: SectionType,
  sectionId: string = null
): SectionTimeline[] {
  const filteredSections = getSectionsOfType(sections, sectionType).filter(
    (e) => !sectionId || e.sectionId === sectionId
  );
  return filteredSections.reduce((arr, { section }) => {
    arr.push(...section.timelines);
    return arr;
  }, []);
}

export function getTimelinesByTypeAndSectionId(
  sections: Sections,
  timelineType: TimelineType,
  sectionId: string = null
): SectionTimeline[] {
  const filteredSections = Object.keys(sections)
    .map((sectionId) => ({ sectionId, section: sections[sectionId] }))
    .filter((e) => !sectionId || e.sectionId === sectionId);
  return filteredSections
    .reduce((arr, { section }) => {
      arr.push(...section.timelines);
      return arr;
    }, [])
    .filter((timeline) => timeline.type === timelineType);
}

export function createLottieLayerDataFromAsset(
  asset: Asset,
  globalSettings: GlobalSettings
): {
  data: LottieLayerData;
  styles: Style[];
} {
  const styles = [];
  const data = {};

  for (const [key, field] of Object.entries(asset.preset)) {
    let color = null;
    if (field.colorTag) {
      const colorTag = asset.colorTags.find((t) => t.tag === field.colorTag);
      if (colorTag.color) {
        color = colorTag.color;
      }
    }

    const style: Style = {
      id: uuidv4(),
      ...(field.type === 'text' ? { font: globalSettings.font, fontWeight: 400, fontStyle: 'normal' } : {}),
      ...(color !== null ? { color, colorShade: field.colorShade } : {}),
    };

    if (asset.data.defaultInlineEditBounds) {
      style.fontSize = asset.data.defaultInlineEditBounds.height;
      style.textAlign = 'center';
    }

    styles.push(style);

    if (field.type === 'logo') {
      field.assetId = globalSettings.logo.settings.assetId;
    }

    data[key] = {
      type: field.type,
      value: field.defaultValue,
      assetId: field.assetId,
      styleId: style.id,
    };
  }

  return { data, styles };
}

export function getSortedSections<T extends { sectionId: string }>(sections: T[], workflow: WorkflowDataDto): T[] {
  return sortBy(sections, (section) =>
    workflow.timelines[0].layers.findIndex((layer) => (layer as SectionLayer).sectionId === section.sectionId)
  );
}

export const pxToPct = (value: number, max: number) => ((value ?? 0) / max) * 100;

export function getIntroOutroEnabled(intro: boolean, source: WorkflowDataDto) {
  const mainTimeline = source.timelines.find((t) => t.type === 'main');
  const sectionLayer = mainTimeline.layers.find(
    (l) => l.type === 'section' && l.sectionId === (intro ? 'intro' : 'outro')
  );
  if (!sectionLayer) {
    return false;
  }

  return sectionLayer.enabled || false;
}

export function getIntroOutroLayerIds(intro: boolean, source: WorkflowDataDto) {
  const sectionType = intro ? 'intro' : 'outro';
  const section = source.sections[sectionType];
  if (!section) {
    return [];
  }

  const layerIds = flatten(
    sortBy(section.timelines, 'zIndex')
      .filter((t) => t.type === 'main' || t.type === 'overlays')
      .map((t) => t.layers.filter((l) => l.type === 'lottie').map((l) => l.layerId))
  );

  if (layerIds.length === 0) {
    const mainTimeline = getSectionTypeTimelines(source.sections, sectionType).find((t) => t.type === 'main');
    if (mainTimeline?.layers.length > 0) {
      layerIds.push(mainTimeline.layers[0].layerId);
    }
  }

  return layerIds;
}

export function assetFileEquals(asset1: File, asset2: File) {
  return asset1.path === asset2.path && asset1.provider === asset2.provider;
}

export function preserveIds(
  oldTimelines: SectionTimeline[],
  newTimelines: SectionTimeline[],
  workflow: WorkflowDataDto
) {
  const randomizedNewTimelines = randomizeDuplicateIds(newTimelines, workflow);

  const tryToPreserveTimelineLayerIds = (type: TimelineType) => {
    const previous = oldTimelines.filter((t) => t.type === type);
    const current = randomizedNewTimelines.filter((t) => t.type === type);
    if (previous.length > 0 && previous.length === current.length) {
      current.forEach((ct, i) => {
        const pt = previous[i];

        const linkedTimelines = randomizedNewTimelines.filter((t) => t.pairId === ct.id);
        linkedTimelines.forEach((lt) => (lt.pairId = pt.id));

        ct.id = pt.id;
        if (pt.layers.length === 1 && ct.layers.length === 1) {
          ct.layers[0].layerId = pt.layers[0].layerId;
        }
      });
    }
  };

  tryToPreserveTimelineLayerIds('main');
  tryToPreserveTimelineLayerIds('overlays');
  tryToPreserveTimelineLayerIds('watermark');
  tryToPreserveTimelineLayerIds('b-roll');

  return randomizedNewTimelines;
}

export function randomizeDuplicateIds(timelines: SectionTimeline[], workflow: WorkflowDataDto) {
  const getId = (oldId: string) => {
    const newId = usedIds.has(oldId) ? uuidv4() : oldId;
    usedIds.add(newId);

    return newId;
  };

  const randomizedTimelines = cloneDeep(timelines);
  const usedIds = new Set<string>();
  Object.entries(workflow.sections).forEach(([, section]) => {
    for (const t of section.timelines) {
      usedIds.add(t.id);

      for (const l of t.layers) {
        usedIds.add(l.layerId);
      }
    }
  });

  for (const t of randomizedTimelines) {
    const oldId = t.id;
    const linkedTimelines = randomizedTimelines.filter((t) => t.pairId === oldId);

    const newId = getId(oldId);
    t.id = newId;
    linkedTimelines.forEach((lt) => (lt.pairId = newId));

    for (const l of t.layers) {
      l.layerId = getId(l.layerId);
    }
  }

  return randomizedTimelines;
}

const fontKey = (font: ProjectFont) => `${font.source}-${font.id}`;

export function getWorkflowFontVariants(workflow: WorkflowDataDto, font: ProjectFont) {
  const variants: Set<FontVariant> = new Set();

  for (const style of workflow.styles) {
    if (style.font && fontKey(style.font) == fontKey(font)) {
      const fontStyle = style.fontStyle || 'normal';

      if (fontStyle === 'normal' && style.fontWeight === 400) {
        variants.add('regular');
      } else if (fontStyle === 'italic' && style.fontWeight === 400) {
        variants.add('italic');
      } else if (fontStyle === 'normal' && style.fontWeight === 700) {
        variants.add('700');
      } else if (fontStyle === 'italic' && style.fontWeight === 700) {
        variants.add('700italic');
      }
    }
  }

  return [...variants];
}

export function getWorkflowFonts(styles: Style[], globalFont?: ProjectFont) {
  const fonts = new Map<string, ProjectFont>();
  if (globalFont) {
    fonts.set(fontKey(globalFont), globalFont);
  }

  for (const style of styles) {
    if (!style.font || fonts.has(fontKey(style.font))) {
      continue;
    }

    fonts.set(fontKey(style.font), style.font);
  }

  return [...fonts].map(([, font]) => font);
}

export function getWorkflowAssetIds(workflow: WorkflowDataDto) {
  const assetIds = new Set<string>();

  for (const asset of workflow.assets) {
    if (asset.file?.provider !== 'or-assets') {
      continue;
    }

    assetIds.add(asset.file.path.toString());
  }

  const uploadedAssets = [
    ...(workflow.globalSettings?.backgroundAsset?.uploaded ?? []),
    ...(workflow.globalSettings?.logo?.uploaded ?? []),
    ...(workflow.globalSettings?.watermark?.uploaded ?? []),
  ];

  for (const asset of uploadedAssets) {
    if (asset.file.provider !== 'or-assets') {
      continue;
    }

    assetIds.add(asset.file.path.toString());
  }

  return [...assetIds];
}

export function getBrandKitAssetIds(brandKitAssets: BrandKitAssets) {
  const assetIds = new Set<string>();
  for (const assetData of brandKitAssets.assets.data) {
    if (assetData.asset.file.provider !== 'or-assets') {
      continue;
    }

    assetIds.add(assetData.asset.file.path.toString());
  }

  return [...assetIds];
}

export function doesWorkflowHaveMissingAssets(workflow: WorkflowDataDto) {
  if (!workflow || !workflow.assets) {
    return false;
  }

  return workflow.assets.some((a) => a.file?.provider === 'or-local');
}

export function muteTimelines(workflow: WorkflowDataDto) {
  for (const [, section] of Object.entries(workflow.sections)) {
    if (!section.audio.muted) {
      continue;
    }
    section.timelines = section.timelines.map((t) => ({ ...t, hasAudio: false }));
  }
}

export function isSectionEnabled(workflow: WorkflowDataDto, sectionId: string) {
  const layer = workflow.timelines[0].layers.find((layer) => layer.type === 'section' && layer.sectionId === sectionId);

  return layer?.enabled ?? false;
}

export function isWorkflowEmpty(workflow: WorkflowDataDto) {
  const layers = workflow.timelines.find((t) => t.type === 'main').layers;
  return !layers.some((l) => l.enabled);
}

export function addEditableAsset(workflow: WorkflowDataDto) {
  const asset = workflow.assets?.find((asset) => asset.id === TEXT_BOX_ASSET_ID);

  if (!asset) {
    workflow.assets.push(TEXT_BOX_ASSET);
  }
}

export function getWorkflowUsedAssetIds(workflow: WorkflowDataDto) {
  const usedAssetIds = new Set<string>();

  // Add global and preset assets
  for (const asset of workflow.assets) {
    if (asset.isGlobal) {
      usedAssetIds.add(asset.id);
    } else if (asset.type === 'json' && asset.preset) {
      for (const [, presetField] of Object.entries(asset.preset)) {
        if (presetField.assetId) {
          usedAssetIds.add(presetField.assetId);
        }
      }
    }
  }

  // Add assets in timelines
  for (const [, section] of Object.entries(workflow.sections)) {
    for (const timeline of section.timelines) {
      for (const layer of timeline.layers) {
        if (layer.type === 'image' || layer.type === 'video') {
          usedAssetIds.add(layer.assetId);
        } else if (layer.type === 'lottie') {
          usedAssetIds.add(layer.assetId);

          for (const [, field] of Object.entries(layer.data)) {
            if (field.type === 'logo' && field.assetId) {
              usedAssetIds.add(field.assetId);
            }
          }
        }
      }
    }
  }

  // Add assets in template sections
  if (workflow.templateSections) {
    for (const [, { sections }] of Object.entries(workflow.templateSections)) {
      for (const templateSection of sections) {
        for (const asset of templateSection.assets) {
          usedAssetIds.add(asset.id);
        }
      }
    }
  }

  // Add assets in globalSettings
  if (workflow.globalSettings.textOverlays?.assetId) {
    usedAssetIds.add(workflow.globalSettings.textOverlays.assetId);
  }

  usedAssetIds.add(workflow.globalSettings.logo.settings.assetId);
  usedAssetIds.add(workflow.globalSettings.watermark.settings.assetId);
  usedAssetIds.add(workflow.globalSettings.backgroundAsset.settings.assetId);

  // Add assets in crossLayer transitions
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  for (const layer of mainTimeline.layers) {
    if (layer.transitions?.crossLayer?.layer?.assetId) {
      usedAssetIds.add(layer.transitions.crossLayer.layer.assetId);
    }
  }

  return usedAssetIds;
}

export function getLottieTransitions(aspectRatioId: number, templateName: string, templateSections: TemplateSections) {
  const transitions: TemplateLottieTransition[] = [];

  const introSection = templateSections[SectionId.Intro].sections.find((s) => s.aspectRatioId === aspectRatioId);
  const outroSection = templateSections[SectionId.Outro].sections.find((s) => s.aspectRatioId === aspectRatioId);

  if (introSection?.transitions?.crossLayer) {
    const crossLayer = introSection.transitions.crossLayer;
    const asset = introSection.assets.find((a) => a.id === crossLayer.layer.assetId);
    transitions.push({ name: `${templateName} (Intro)`, asset, transitionLayer: crossLayer });
  }

  if (outroSection?.transitions?.crossLayer) {
    const crossLayer = outroSection.transitions.crossLayer;
    const asset = outroSection.assets.find((a) => a.id === crossLayer.layer.assetId);
    transitions.push({ name: `${templateName} (Outro)`, asset, transitionLayer: crossLayer });
  }

  return transitions;
}

export function getProjectVideos(workflow: WorkflowDataDto) {
  const videos: ProjectVideo[] = [];
  const distinctVideos = new Set<string>();
  for (const { layer, timeline, section } of getLayers(workflow)) {
    if (
      layer.type !== 'video' ||
      (section && section.sectionType !== 'main') ||
      !['main', 'b-roll'].includes(timeline.type)
    ) {
      continue;
    }

    const asset = workflow.assets.find((a) => a.id === layer.assetId);
    if (!asset) {
      continue;
    }

    const videoKey = `${asset.file.provider}-${asset.file.path}`;
    if (distinctVideos.has(videoKey)) {
      continue;
    }

    distinctVideos.add(videoKey);
    videos.push({ videoId: +asset.file.path, videoType: asset.file.provider });
  }

  return videos;
}

export function shouldUpdateWorkflowTranscriptions(
  originalWorkflow: WorkflowDataDto,
  updatedWorkflow: WorkflowDataDto
) {
  const getMainLayers = (workflow: WorkflowDataDto) => {
    const mainSections = getSectionTimelines(workflow.sections, 'main', 'main');

    const mainLayers = flattenDeep(
      getSortedSections(mainSections, workflow).map((section) =>
        section.timelines.map((t) => getTimelineLayers(t.id, workflow))
      )
    ).map((mainLayer) => ({
      ...(mainLayer as VideoLayerInfo),
      bounds: null,
    }));

    return mainLayers;
  };

  const getDurationPerScene = (workflow: WorkflowDataDto) => {
    const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
    return mainTimeline.layers.map((l: Layer) => workflow.sections[(l as SectionLayer).sectionId].sectionDuration);
  };

  const currentMainLayers = getMainLayers(updatedWorkflow);
  const previousMainLayers = getMainLayers(originalWorkflow);
  if (!isEqual(currentMainLayers, previousMainLayers)) {
    return true;
  }

  const currentDurationPerScene = getDurationPerScene(updatedWorkflow);
  const previousDurationPerScene = getDurationPerScene(originalWorkflow);
  if (!isEqual(currentDurationPerScene, previousDurationPerScene)) {
    return true;
  }

  return false;
}

export function getCameraTimelines(timelines: SectionTimeline[]) {
  const specifiedCameraTimelines = timelines.filter((timeline) => timeline.contentType === 'camera');
  if (specifiedCameraTimelines.length > 0) {
    return specifiedCameraTimelines;
  }

  const timelinesWithOverlays = timelines.filter((timeline) => !!timeline.pairId);
  if (timelinesWithOverlays.length > 0) {
    return timelinesWithOverlays;
  }

  const timelinesWithAudio = timelines.filter((timeline) => timeline.hasAudio);
  if (timelinesWithAudio.length > 0) {
    return timelinesWithAudio;
  }

  return [];
}

export function getAssetFromId(id: string, assets: Asset[]) {
  if (!id) {
    return null;
  }

  return assets.find((a) => a.id === id);
}

export function getFirstLayerWithTextColor(layerIds: string[], workflow: WorkflowDataDto) {
  for (const layerId of layerIds) {
    const layerInfo = getLayerFromId(layerId, workflow);
    if (!layerInfo) {
      continue;
    }

    const { layer } = layerInfo;
    if (layer.type !== 'lottie' || !layer?.colorTags || layer.colorTags.length === 0) {
      continue;
    }

    return layer;
  }

  return null;
}

export function hasTextOverlay(globalSettings: GlobalSettings) {
  return !!globalSettings.textOverlays?.assetId;
}

export function getUsedArea(section: Section): Bounds {
  const items = section.timelines.filter((t) => t.type !== 'background');
  if (items.length === 0) {
    return {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    };
  }

  const usedArea = { left: 100, right: 0, top: 100, bottom: 0 };
  for (const {
    layers: [layer],
  } of items) {
    const bounds = layer.bounds ?? { x: 0, y: 0, width: 100, height: 100 };

    usedArea.left = Math.min(bounds.x, usedArea.left);
    usedArea.right = Math.max(bounds.x + bounds.width, usedArea.right);
    usedArea.top = Math.min(bounds.y, usedArea.top);
    usedArea.bottom = Math.max(bounds.y + bounds.height, usedArea.bottom);
  }

  return {
    x: clamp(usedArea.left, 0, 100),
    y: clamp(usedArea.top, 0, 100),
    width: clamp(usedArea.right - usedArea.left, 0, 100),
    height: clamp(usedArea.bottom - usedArea.top, 0, 100),
  };
}
