import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'openreel-alert-banner',
  templateUrl: './openreel-alert-banner.component.html',
  styleUrls: ['./openreel-alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatLegacyButtonModule],
})
export class OpenreelAlertBannerComponent {
  @Input() set hideCloseButton(isCloseButtonHidden: boolean) {
    this._isCloseButtonHidden = coerceBooleanProperty(isCloseButtonHidden);
  }

  @Input() type: 'info' | 'warning' | 'error';
  @Input() message: string;
  @Input() buttonText: string;
  @Input() buttonSuffixIcon: string;
  @Input() buttonPrefixIcon: string;
  @Output() actionButtonClick = new EventEmitter<void>();
  @Output() closeButtonClick = new EventEmitter<void>();

  private _isCloseButtonHidden = false;

  get btnColor(): ThemePalette {
    if (this.type === 'info') {
      return 'primary';
    } else {
      return this.type === 'error' ? 'warn' : 'accent';
    }
  }

  get isCloseButtonHidden(): boolean {
    return this._isCloseButtonHidden;
  }

  onActionButtonClick() {
    this.actionButtonClick.emit();
  }
  onCloseButtonClick() {
    this.closeButtonClick.emit();
  }
}
