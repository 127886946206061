<mat-toolbar class="navbar">
  <div class="wrapper">
    <ng-container *ngIf="titleRoute">
      <div class="title-route">
        <button mat-button [routerLink]="titleRoute">
          <mat-icon color="primary">navigate_before</mat-icon> {{ title }}
        </button>
        <div class="search-content">
          <ng-container *ngTemplateOutlet="searchContent"></ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!titleRoute">
      <openreel-page-title [title]="title" [titleSize]="900" [subTitle]="getSubtitle()" [subTitleSize]="600">
        <ng-container *ngTemplateOutlet="searchContent"></ng-container>
      </openreel-page-title>
    </ng-container>

    <openreel-divider-bar [height]="2" [top]="36" [bottom]="24"></openreel-divider-bar>
  </div>
  <ng-template #searchContent>
    <div class="title-content">
      <mat-icon class="notifications-icon" svgIcon="bell"></mat-icon>
      <openreel-navigation-search></openreel-navigation-search>
    </div>
  </ng-template>
</mat-toolbar>
