import * as Interfaces from './workflow-tags.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}workflows/tags`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getTags() {
    return this.httpClient.get<Interfaces.WorkflowTagEntity[]>(this.urls.base);
  }
}
