import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PossibleSessionState, SessionBase } from '@openreel/frontend/common';
import { SessionState } from './interfaces/session-store.interface';

export const selectSession = createFeatureSelector<SessionState<SessionBase>>('session');

export const selectSessionId = createSelector(
  selectSession,
  (state) => state.sessionId ?? state.sessionDetails?.session_id
);

export const selectCompanySlug = createSelector(selectSession, (state) => state.companySlugName);

export const selectSessionDetails = createSelector(selectSession, (state) => state.sessionDetails);
export const selectSessionPresentationId = createSelector(selectSessionDetails, (state) => state?.presentationId);

export const selectSessionSettings = createSelector(selectSession, (state) => state.sessionSettings);

export const selectProject = createSelector(selectSession, (state) => state.sessionProjectData);

export const selectMyLoginId = createSelector(selectSessionDetails, (details) => details?.login_id);
export const selectSessionName = createSelector(selectSessionDetails, (details) => details?.session_name);

export const selectSessionParticipants = createSelector(selectSession, (state) => state.participants);

export const selectSessionRecordings = createSelector(selectSession, (state) => state.recordings);

export const selectSessionRecordingsCount = createSelector(selectSession, (state) => state.sessionRecordingsCount);

export const selectFetchingSessionRecordings = createSelector(
  selectSession,
  (state) => state.fetchingSessionRecordings
);

export const selectSessionFavoriteRecordings = createSelector(selectSession, (state) =>
  state.recordings.filter((r) => r.favourite)
);

export const selectSessionRecording = (id: number) =>
  createSelector(selectSession, (state) => state.recordings.find((r) => r.ovra_session_videos_id === id));

export const selectTimerForParticipant = (id: string) =>
  createSelector(selectSession, (state) => state.sessionState.states[id]?.timer);

export const selectRecordingStateForParticipant = (id: string) =>
  createSelector(selectSession, (state) => state.sessionState.states[id]?.state);

export const selectIsSessionRecording = createSelector(selectSession, (state) =>
  Object.keys(state.sessionState.states)
    .map((identity) =>
      [PossibleSessionState.RECORDING, PossibleSessionState.PRERECORDING].includes(
        state.sessionState.states[identity].state
      )
    )
    .includes(true)
);

export const selectAdjustmentsPanelSelectedId = createSelector(
  selectSession,
  (state) => state.adjustmentsPanelSelectedId
);

export const selectSessionOngoingRecordingVideoId = createSelector(selectSession, (state) =>
  (state.recordings.find((r) => [1, 2].includes(r.status)))?.ovra_session_videos_id
);

// This flag is for starting spot presentation recording
export const selectIsRecordPresentationEnabled = createSelector(
  selectSession,
  (session) => session.recordSpotPresentation
);