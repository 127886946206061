import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

@Component({
  selector: 'openreel-select',
  templateUrl: './orselect.component.html',
  styleUrls: ['./orselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacySelectModule],
})
export class OrSelectComponent {
  @Input()
  selection;
  @Input()
  items = [];
  @Input()
  idKey = 'id';
  @Input()
  labelKey: string | ((item: unknown) => string) = 'label';
  @Output()
  selectionChange = new EventEmitter();

  getItemLabel(item) {
    if (typeof item === 'string') {
      return item;
    } else if (typeof this.labelKey === 'string') {
      return item[this.labelKey];
    } else {
      return this.labelKey(item);
    }
  }

  onSelectionChange(event) {
    this.selectionChange.emit(event.value);
  }

  compareWithFn = (option, value) => {
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return option === value;
    }

    return value[this.idKey] === option[this.idKey];
  };
}
