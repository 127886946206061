import { Asset, Layer, LottieLayerData, TimelineType, WorkflowDataDto } from './workflow.interfaces';

export const timelineItemFactory = (layer: Layer, type: TimelineType, workflow: Partial<WorkflowDataDto>) => {
  switch (layer.type) {
    case 'video':
    case 'image':
    case 'lottie': {
      const assetId = layer.assetId;
      const asset = workflow.assets?.find((a) => a.id === assetId);

      return new TimelineItem(layer, asset, type);
    }
    default:
      return null;
  }
};

export class TimelineItem {
  constructor(public layer: Layer, public asset: Asset, public type: TimelineType) {}

  get layerId() {
    return this.layer.layerId;
  }

  get startAt() {
    if (typeof this.layer.visibility?.startAt === 'number') {
      return this.layer.visibility?.startAt;
    }
    return null;
  }

  get endAt() {
    if (typeof this.layer.visibility?.endAt === 'number') {
      return this.layer.visibility?.endAt;
    }
    return null;
  }

  get data() {
    if (this.layer.type === 'lottie') {
      return this.layer.data;
    }

    return null;
  }

  get preset() {
    return this.asset.preset;
  }

  get duration() {
    if (!this.layer.visibility?.endAt) {
      return 0;
    }

    return this.layer.visibility.endAt - this.layer.visibility.startAt;
  }
}

export interface TimelineItemStretchStartEvent {
  startX: number;
}

export interface TimelineItemStretchingEvent {
  startX: number;
  currentX: number;
}

export interface TimelineItemStretchEndEvent {
  startX: number;
  endX: number;
}

export interface LayerDataChanges {
  values: LottieLayerData;
  assetChanges: {
    [key: string]: {
      newAssetFileId: number;
      removedAssetId: string;
    };
  };
  styleChanges: {
    [key: string]: {
      color?: string;
      fontSize?: number;
    };
  };
}

export interface LayersDataChangedEvent {
  [layerId: string]: LayerDataChanges;
}
