import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private routeTitle = '';

  private defaultValue: string;
  private prefix: string;
  private suffix: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private title: Title) {}

  init(defaultValue: string, prefix?: string) {
    this.defaultValue = defaultValue;
    this.prefix = prefix;

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .pipe(
        map(() => {
          let routeTitle = '';
          let child = this.activatedRoute.snapshot.firstChild;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else {
              child = null;
            }

            if (child?.data?.pageTitle) {
              routeTitle = child.data.pageTitle;
            }
          }

          return routeTitle;
        })
      )
      .subscribe((routeTitle) => {
        this.routeTitle = routeTitle;
        this.setTitle(this.routeTitle, this.suffix);
      });
  }

  appendAlways(textToAppend: string) {
    this.suffix = textToAppend;
    this.setTitle(this.routeTitle, this.suffix);
  }

  appendNow(textToAppend: string) {
    this.setTitle(this.routeTitle, textToAppend);
  }

  private setTitle(value: string, suffix?: string) {
    const title = `${value ? value : ''}${suffix ? suffix : ''}`;
    if (!title) {
      this.title.setTitle(this.defaultValue);
    } else {
      this.title.setTitle(`${this.prefix ? this.prefix + ' - ' : ''}${title}`);
    }
  }
}
