import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  StreamTokenRequest,
  StreamTokenResponse,
  DeviceInfoRequest,
  DeviceInfoResponse,
  RemoteSessionDetailsRequest,
  InvitedUser,
  Account,
} from '../../interfaces/interfaces';
import { commonenv } from '../../environments/environment';

const URL_TWILIO_TOKEN = 'stream/token';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(private httpClient: HttpClient) {}

  getStreamToken(request: StreamTokenRequest) {
    return this.httpClient.post<StreamTokenResponse>(
      `${commonenv.nextGenApiUrl}${URL_TWILIO_TOKEN}`,
      request
    );
  }

  getDeviceInfo(request: DeviceInfoRequest) {
    return this.httpClient.get<DeviceInfoResponse>(
      `${commonenv.nextGenApiUrl}device/${request.device_id}?session_id=${request.session_id}`
    );
  }

  getAccountsByName(term: string) {
    return this.httpClient.get<Account[]>(`${commonenv.nextGenApiUrl}company/accounts-by-name/${term}`);
  }

  sessionInvitedUsers(sessionDetailsRequest: RemoteSessionDetailsRequest) {
    return this.httpClient.get<{ data: InvitedUser[] }>(
      `${commonenv.nextGenApiUrl}sessions/${sessionDetailsRequest.session_id}/invited-users`
    );
  }
}
