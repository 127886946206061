import { ChangeDetectionStrategy, Component, HostBinding, HostListener, inject, Input } from '@angular/core';
import { OpenreelRadioGroupComponent } from '../openreel-radio-group.component';

@Component({
  selector: '[openreelRadioGroupItem]',
  templateUrl: './openreel-radio-group-item.component.html',
  styleUrls: ['./openreel-radio-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class OpenreelRadioGroupItemComponent<T> {

  @Input() value: T | null = null;

  @HostBinding('class.disabled')
  @Input() disabled = false;

  private readonly radioGroup: OpenreelRadioGroupComponent<T> = inject(OpenreelRadioGroupComponent);

  @HostBinding('class.selected')
  get selected(): boolean {
    return this.radioGroup.value === this.value;
  }

  @HostListener('click')
  onClick(): void {
    if (this.disabled) {
      return;
    }

    this.radioGroup.setValue(this.selected ? null : this.value);
  }
}
