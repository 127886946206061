import {
  Bounds,
  ImageLayer,
  LayerOptions,
  LayoutDto,
  LayoutType,
  SectionTimeline,
  VideoLayer,
  WorkflowDataDto,
} from '../interfaces';
import { getChangesEventFromLayers, getSectionTimelines, getTimelineById } from '../helpers';
import { clamp, cloneDeep, difference } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLayoutEvent {
  sectionId: string;
  templateLayoutId: number;
  layoutType: LayoutType;
  layoutData: LayoutDto;
  aspectRatioBounds: Bounds[];
}

export class UpdateSectionLayoutCommand extends WorkflowBaseBuilder<UpdateLayoutEvent> {
  private projectBefore: WorkflowDataDto;
  run({ sectionId, templateLayoutId, layoutType, layoutData, aspectRatioBounds }: UpdateLayoutEvent) {
    if (!this.source.sections[sectionId]) {
      return this.error('Can not find section with sectionId : ' + sectionId);
    }

    this.projectBefore = cloneDeep(this.original);

    this.applyLayout(sectionId, templateLayoutId, layoutType, layoutData, aspectRatioBounds, false);

    // Apply watermark if present on old project
    this.applyWatermark(sectionId);

    // Copy main clips from old project
    // If same number of timelines: copy as-is
    // If new project contains less timelines then old project, skip non-existing timelines
    // If new project contains more timelines then old project, duplicate clips
    this.applyMainClips(sectionId, layoutType);

    // Copy text overlays from old project
    // If same number of timelines: copy as-is
    // If new project contains less timelines then old project, skip non-existing timelines
    // If new project contains more timelines then old project, create new text overlays and fill with old text
    this.applyTextOverlays(sectionId);

    return this.ok();
  }

  private applyWatermark(sectionId: string) {
    if (!this.projectBefore.globalSettings.watermark?.settings.enabled) {
      return;
    }

    const oldTimelines = getSectionTimelines(this.projectBefore.sections, 'main', 'watermark', sectionId)[0].timelines;
    const oldWatermarkTimeline = oldTimelines[0];

    if (oldWatermarkTimeline.layers.length === 0) {
      return;
    }

    const { layerId, assetId } = oldTimelines[0].layers[0] as LayerOptions & ImageLayer;
    this.createWatermarkLayerForSection(sectionId, { layerId, assetId });
  }

  private applyMainClips(sectionId: string, layoutType: LayoutType) {
    const sourceTimelines = getSectionTimelines(this.projectBefore.sections, 'main', 'main', sectionId)[0].timelines;
    const targetTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId)[0].timelines;

    const timelinesMap = new Map<
      string,
      { sourceTimeline: SectionTimeline; targetTimelineIndex: number; existedBefore: boolean }
    >();
    if (targetTimelines.length <= sourceTimelines.length) {
      targetTimelines.forEach(({ id }, index) =>
        timelinesMap.set(id, {
          existedBefore: true,
          targetTimelineIndex: index,
          sourceTimeline: sourceTimelines[index],
        })
      );
    }
    if (targetTimelines.length > sourceTimelines.length) {
      targetTimelines.forEach(({ id }, index) => {
        const sourceTimeline =
          sourceTimelines.length > 0 ? sourceTimelines[clamp(index, 0, sourceTimelines.length - 1)] : null;
        return timelinesMap.set(id, {
          existedBefore: sourceTimelines.length > index,
          targetTimelineIndex: index,
          sourceTimeline,
        });
      });
    }

    const reusedAssetIds: string[] = [];
    for (const [targetTimelineId, { existedBefore, targetTimelineIndex, sourceTimeline }] of timelinesMap.entries()) {
      const { timeline: newTimeline } = getTimelineById(targetTimelineId, this.source.sections);

      const sourceLayer =
        sourceTimeline && sourceTimeline.layers.length > 0
          ? (sourceTimeline.layers[0] as LayerOptions & VideoLayer)
          : null;

      let asset = sourceLayer ? this.projectBefore.assets.find((a) => a.id === sourceLayer.assetId) : null;
      if (!asset) {
        continue;
      }

      if (asset.isPlaceholder) {
        const placeholderAssetIds = this.getPlaceholdersForLayout(layoutType);
        if (placeholderAssetIds.length > targetTimelineIndex && placeholderAssetIds[targetTimelineIndex]) {
          asset = this.projectBefore.assets.find((a) => a.id === placeholderAssetIds[targetTimelineIndex]);
        }
      }
      if (existedBefore) {
        if (asset.isPlaceholder) {
          asset = this.duplicateAsset(asset.id);
        }

        this.addLayerWithExistingAsset(newTimeline, asset, sourceLayer ? { layerId: sourceLayer.layerId } : {});
        reusedAssetIds.push(asset.id);
      } else {
        this.addAssetLayer(newTimeline, {
          source: asset.data.source,
          assetId: asset.id,
          assetFileId: asset.file.path,
          assetProviderType: asset.file.provider,
          duration: asset.data.duration,
          name: asset.data.name,
          textCuts: asset.textCuts,
          trim: asset.trim,
          isPlaceholder: asset.isPlaceholder,
        });
      }
    }

    const assetIdsToRemove = difference(this.getTimelinesAssetIds(sourceTimelines), reusedAssetIds);
    this.removeAssets(assetIdsToRemove);
  }

  private applyTextOverlays(sectionId: string) {
    const newTextOverlayTimelines = getSectionTimelines(this.source.sections, 'main', 'overlays', sectionId)[0]
      .timelines;
    const oldTextOverlayTimelines = getSectionTimelines(this.projectBefore.sections, 'main', 'overlays', sectionId)[0]
      .timelines;

    if (newTextOverlayTimelines.length === 0) {
      return;
    }

    const newTextOverlayLayers = newTextOverlayTimelines.map((timeline) => this.addTextOverlay(timeline, 0));
    const oldTextOverlayLayers = oldTextOverlayTimelines.map((timeline) =>
      timeline.layers.length > 0 ? timeline.layers[0] : null
    );

    if (oldTextOverlayLayers.length > 0) {
      for (const [index, newLayer] of newTextOverlayLayers.entries()) {
        const oldLayerIndex = index < oldTextOverlayLayers.length ? index : 0;
        if (!oldTextOverlayLayers[oldLayerIndex]) {
          return this.error('Old text overlay layers not found!');
        }
        const oldLayerId = oldTextOverlayLayers[oldLayerIndex].layerId;

        newLayer.enabled = oldTextOverlayLayers[oldLayerIndex].enabled;
        if (index < oldTextOverlayLayers.length) {
          // Preserve Layer Id (where possible)
          newLayer.layerId = oldTextOverlayLayers[index].layerId;
        }

        const oldTextOverlayChanges = getChangesEventFromLayers([oldLayerId], this.projectBefore)[oldLayerId];
        this.applyLayerChanges(newLayer, oldTextOverlayChanges);
      }
    }
  }
}
