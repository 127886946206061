import { Injectable } from '@angular/core';
import { map, catchError, of, Subject, firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SelfRecordingMetaData } from './dto/self-record-upload.dto';
import { MultiPartUploader } from './multi-part-uploader';
import { CreateUserUploadDto, UserUploadCredentials, UserUploadService } from '../../user-upload';
import { AuthService } from '../auth/auth.service';
import { HostablePermissionTypes, HostingService, HostableVideo, HostingCreateData } from '../../hosting';
import { UploadDetailsResponse } from './dto/upload.dto';
import { WarningBarService } from '../warning-bar/warning-bar.service';
import { v4 } from 'uuid';
import { SelfRecordSource } from '@openreel/common';

@Injectable({
  providedIn: 'root',
})
export class SelfRecordUploadService {
  private recordingMetadata = new Map<number, SelfRecordingMetaData>();
  private uploaderState = new Map<number, MultiPartUploader>();

  private hostingReadyEvent = new Subject<{ assetId: number; hostableVideo: HostableVideo }>();
  public hostingReadyEvent$ = this.hostingReadyEvent.asObservable();

  private hostingFailedEvent = new Subject<{ assetId: number }>();
  public hostingFailedEvent$ = this.hostingFailedEvent.asObservable();

  constructor(
    private readonly userUploadService: UserUploadService,
    private readonly authService: AuthService,
    private readonly warningBarService: WarningBarService,
    private readonly hostingService: HostingService,
    private toastrService: ToastrService
  ) {}

  initMultiPartUpload(metadata: SelfRecordingMetaData, recordingSource: SelfRecordSource) {
    return this.initMultiPartUploads([metadata], recordingSource).pipe(map((uploads) => uploads[0]));
  }

  initMultiPartUploads(metadatas: SelfRecordingMetaData[], recordingSource: SelfRecordSource) {
    const personName = this.authService.getUserDetails()?.data?.loggedin_fullname;

    const metadatasWithId = metadatas.map((metadata) => ({
      id: v4(),
      metadata,
    }));

    const uploads = metadatasWithId.map(({ id, metadata }): CreateUserUploadDto => ({
      name: metadata.name,
      extension: metadata.extension,
      fileSize: metadata.fileSize,
      fileResolution: { width: metadata.width, height: metadata.height },
      personName: personName,
      source: 'web',
      recordingType: metadata.source === 'webcam' ? 'camera' : 'screen',
      captureProjectId: metadata.captureProjectId,
      uploadRequestId: id,
    }));

    return this.userUploadService
      .createUserUploads(uploads)
      .pipe(
        map((uploads) => {
          const userUploads = uploads.map((upload) => {
            const metadata = metadatasWithId.find((m) => m.id === upload.uploadRequestId).metadata;

            const uploader = new MultiPartUploader(this.getUploaderConfig(upload.uploadCredentials), {
              dbKey: metadata.name,
            });
            uploader.aborted$.subscribe(() => this.handleMultiPartUploadAbort(upload.assetId));
            uploader.error$.subscribe((err) => this.hanldeMultiPartUploadError(upload.assetId, err));
            const newMetadata: SelfRecordingMetaData = {
              ...metadata,
              assetId: upload.assetId,
              userUploadId: upload.id,
              videoPersonName: personName,
            };
            this.recordingMetadata.set(upload.assetId, newMetadata);
            this.uploaderState.set(upload.assetId, uploader);

            return {
              uploader: uploader,
              metadata: newMetadata,
            };
          });

          const promises = userUploads.map((userUpload) => firstValueFrom(userUpload.uploader.complete$));
          Promise.all(promises).then(() => {
            const assetIds = userUploads.map((userUpload) => userUpload.metadata.assetId);
            this.handleMultiPartUploadComplete(assetIds, recordingSource)
          });

          return userUploads;
        }),
        catchError((error) => {
          if (error.message === this.warningBarService.STORAGE_LIMIT_REACHED_ERROR_MESSAGE) {
            this.warningBarService.setStorageBannerVisibility(true);
          }
          this.toastrService.error(error.message, 'Error!');
          return of(null);
        })
      );
  }
  updateMetadata(assetId: number, newData: Partial<SelfRecordingMetaData>) {
    const data = this.recordingMetadata.get(assetId);
    if (data) {
      this.recordingMetadata.set(assetId, { ...data, ...newData });
    }
  }
  getUploader(assetId: number) {
    return this.uploaderState.get(assetId);
  }
  getMetaData(assetId: number) {
    return this.recordingMetadata.get(assetId);
  }
  getUserUpload(metaData: SelfRecordingMetaData) {
    return this.userUploadService.getUserUpload(metaData.userUploadId);
  }
  private async handleMultiPartUploadComplete(assetIds: number[], recordingSource: SelfRecordSource) {
    const metadatas = assetIds.map(assetId => this.recordingMetadata.get(assetId));
    const promises = metadatas.map(metadata => firstValueFrom(
      this.userUploadService
        .updateUserUpload(metadata.userUploadId, {
          fileSize: metadata.fileSize,
          duration: metadata.duration / 1000,
          state: 'complete',
        })
    ));
    await Promise.all(promises);

    const hostMetadatas = metadatas.filter((metadata) => metadata.isHostVideo);
    if (hostMetadatas.length > 0) {
      this.hostVideo(recordingSource, hostMetadatas);
    }
  }

  hostVideo(recordingSource: SelfRecordSource, metadatas: SelfRecordingMetaData[]) {
    const cameraMetadatas = metadatas.filter((metadata) => metadata.source === 'webcam');
    const screenMetadatas = metadatas.filter((metadata) => metadata.source === 'screen');

    if (cameraMetadatas.length === 0 && screenMetadatas.length === 0) {
      throw new Error('No metadata provided');
    } else if (cameraMetadatas.length > 1) {
      throw new Error('Only one camera video can be sent to this hosting endpoint at a time');
    } else if (screenMetadatas.length > 1) {
      throw new Error('Only one screen video can be sent to this hosting endpoint at a time');
    }

    let screenData: HostingCreateData;
    if (screenMetadatas.length > 0) {
      screenData = {
        id: screenMetadatas[0].userUploadId,
        title: screenMetadatas[0].name,
        permission: HostablePermissionTypes.Private,
      }
    }

    let cameraData: HostingCreateData;
    if (cameraMetadatas.length > 0) {
      cameraData = {
        id: cameraMetadatas[0].userUploadId,
        title: cameraMetadatas[0].name,
        permission: HostablePermissionTypes.Private,
      }
    }

    this.hostingService
      .selfRecordHostVideo(recordingSource, {
        screen: screenData,
        camera: cameraData,
      })
      .subscribe({
        next: ({ camera, screen }) => {
          if (camera) {
            this.hostingReadyEvent.next({ assetId: cameraMetadatas[0].assetId, hostableVideo: camera });
            this.recordingMetadata.delete(cameraMetadatas[0].assetId);
            this.uploaderState.delete(cameraMetadatas[0].assetId);
          }

          if (screen) {
            this.hostingReadyEvent.next({ assetId: screenMetadatas[0].assetId, hostableVideo: screen });
            this.recordingMetadata.delete(screenMetadatas[0].assetId);
            this.uploaderState.delete(screenMetadatas[0].assetId);
          }
        },
        error: (error) => {
          if (cameraData) {
            this.hostingFailedEvent.next({ assetId: cameraMetadatas[0].assetId });
          }

          if (screenData) {
            this.hostingFailedEvent.next({ assetId: screenMetadatas[0].assetId });
          }

          this.toastrService.error(error.message, 'Error!');
        }
      });
  }

  deleteUserUpload(id: number) {
    this.userUploadService.deleteUserUpload(id).subscribe(() => {});
  }

  private handleMultiPartUploadAbort(assetId: number) {
    this.recordingMetadata.delete(assetId);
    this.uploaderState.delete(assetId);
    this.toastrService.error('Upload aborted', 'Error!');
  }
  private hanldeMultiPartUploadError(assetId: number, error: string[]) {
    this.toastrService.error('Upload failed', 'Error!');
  }

  private getUploaderConfig(credentials: UserUploadCredentials): UploadDetailsResponse {
    return {
      url: '',
      bucket: credentials.bucket,
      path: credentials.key,
      region: credentials.region,
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      sessionToken: credentials.credentials.sessionToken,
      useAccelerateEndpoint: false,
    };
  }
}
