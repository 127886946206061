import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Duration } from "moment";
import { DurationUtils } from "../duration.utils";

export namespace DurationValidators {
    export const isValid = (control: AbstractControl): ValidationErrors | null => {
        const value = control?.value;
        if(!value) return null;
        return !DurationUtils.isValidDurationString(value) ? { invalidDuration: true } : null
    }


    export const min = (minDuration: Duration): ValidatorFn => {
        return (control:AbstractControl) : ValidationErrors | null => {
            const value = control?.value;
            if(!value) return null;

            const dur = DurationUtils.parseDuration(value);
            if(!dur) return null;

            const result = DurationUtils.compareDurations(dur, minDuration);
            return result === -1 ? { minDuration: { required: minDuration, current: dur } } : null
        }
    }

    export const max = (maxDuration: Duration): ValidatorFn => {
        return (control:AbstractControl) : ValidationErrors | null => {
            const value = control?.value;
            if(!value) return null;

            const dur = DurationUtils.parseDuration(value);
            if(!dur) return null;

            const result = DurationUtils.compareDurations(maxDuration, dur);
            return result === -1 ? { maxDuration: { required: maxDuration, current: dur } } : null
        }
    }
}