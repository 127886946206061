import { z } from 'zod';
import { LayerStylesSchema } from './common.zod';

const LayoutsFeaturesSchema = z.object({
  styles: z.optional(LayerStylesSchema),
});

const CaptionsFeaturesSchema = z.object({
  burnIn: z.optional(z.boolean()),
  upToDate: z.optional(z.boolean()),
  userEdited: z.optional(z.boolean()),
});

export const FeaturesSchema = z.object({
  layouts: z.optional(LayoutsFeaturesSchema),
  captions: z.optional(CaptionsFeaturesSchema),
});
