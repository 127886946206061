import { BehaviorSubject, Subject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

export interface NavigationTitleOptions {
  type: 'static' | 'route';
  title: string;
  subtitle?: string;
  route?: string | string[];
}

@Injectable()
export class NavigationService {
  navigationTitleOptions$ = new Subject<NavigationTitleOptions>();

  private showHeader = new BehaviorSubject<boolean>(true);
  showHeader$ = this.showHeader.asObservable();

  searchInput$ = new EventEmitter<string>();

  public setStatic(title: string, subtitle?: string) {
    this.navigationTitleOptions$.next({
      title,
      subtitle,
      type: 'static',
    });
  }

  public setRoute(title: string, route: string[]) {
    this.navigationTitleOptions$.next({
      title,
      route,
      type: 'route',
    });
  }

  public setShowHeader(show: boolean) {
    this.showHeader.next(show);
  }
}
