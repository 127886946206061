import { Point, ResizeDirections, ResizeDirectionsExtended, SimpleBounds } from '../interfaces';
import { addSimpleBounds } from './bounds-helpers';
import { getRotatedBounds } from './rotation.helpers';

export type CuePlayerResizeHandlePosition = 'nw' | 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w';

export const RESIZE_DIR_SIGNS: { [key in CuePlayerResizeHandlePosition]: ResizeDirections } = {
  nw: { x: -1, y: -1 },
  n: { x: 0, y: -1 },
  ne: { x: 1, y: -1 },
  e: { x: 1, y: 0 },
  se: { x: 1, y: 1 },
  s: { x: 0, y: 1 },
  sw: { x: -1, y: 1 },
  w: { x: -1, y: 0 },
};

/**
 * Calculate new bounds after applying a resize operation on the initial Bounds.
 *
 * _**Note**_: This methods works with Pixels.
 * @param {SimpleBounds} initialBounds The bounds of the box before the resize
 * @param {number} angle The rotation angle of the box
 * @param {Point} delta The resize value (x & y)
 * @param {ResizeDirectionsExtended} resizeDir Specify which direction the box needs to be resized to
 * @returns {SimpleBounds} The result bound after applying the resize operation on the initial bounds
 */
// Works with pixels
export function calculateBoundsResize(
  initialBounds: SimpleBounds,
  angle: number,
  delta: Point,
  resizeDir: ResizeDirectionsExtended
): SimpleBounds {
  let x: number, y: number, width: number, height: number;

  if (resizeDir.x === 'both') {
    x = -delta.x / 2;
    width = delta.x;
  } else {
    x = resizeDir.x === -1 ? delta.x : 0;
    width = resizeDir.x * delta.x;
  }

  if (resizeDir.y === 'both') {
    y = -delta.y / 2;
    height = delta.y;
  } else {
    y = resizeDir.y === -1 ? delta.y : 0;
    height = resizeDir.y * delta.y;
  }

  const boundsOriginalDelta: SimpleBounds = { x, y, width, height };

  const boundsRotatedDelta = getRotatedBounds(boundsOriginalDelta, angle);

  const resizeBoundsDelta = {
    x:
      boundsOriginalDelta.x +
      (boundsRotatedDelta.x + boundsRotatedDelta.width / 2 - (boundsOriginalDelta.x + boundsOriginalDelta.width / 2)),
    y:
      boundsOriginalDelta.y +
      (boundsRotatedDelta.y + boundsRotatedDelta.height / 2 - (boundsOriginalDelta.y + boundsOriginalDelta.height / 2)),
    width: boundsOriginalDelta.width,
    height: boundsOriginalDelta.height,
  };

  return addSimpleBounds(initialBounds, resizeBoundsDelta);
}

export function shouldHandleMaintainAspectRatio(handle: CuePlayerResizeHandlePosition) {
  const singleDirectionResize = ['n', 'e', 's', 'w'].includes(handle);

  return !singleDirectionResize;
}

export function shouldAllowFreeResize(handle: CuePlayerResizeHandlePosition, allowFreeResize: boolean) {
  return allowFreeResize && !['n', 'e', 's', 'w'].includes(handle);
}
