import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { GuestUserAccountInfo } from '@openreel/common';
import { commonenv } from '@openreel/frontend/common/env/environment';

@Injectable({ providedIn: 'root' })
export class SignupService {
    private readonly http = inject(HttpClient);
    private readonly urls = {
        registration: commonenv.nextGenApiUrl + 'registration'
    }

    signupUser(guest: GuestUserAccountInfo) {
        return this.http.post(this.urls.registration, guest);
    }
}