<mat-card class="video-upload-card">
    <mat-card-content class="upload-card-content">
        <div class="content-header">
            <mat-icon class="upload indicator-icon">cloud_upload</mat-icon>
            <mat-icon 
                *ngIf="isWaiting || isUploading" 
                class="delete-btn indicator-icon"
                (click)="onCancelUpload($event)"
            >delete</mat-icon>
            <mat-icon *ngIf="isCompleting" class="indicator-icon done">check_circle</mat-icon>
        </div>
        <div class="upload-main">
            <p class="file-name">{{ fileName }}</p>
            <p class="file-size" *ngIf="fileSize">{{ fileSize | clipSizeBytes }}</p>
            <p class="status-label" *ngIf="isWaiting">WAITING UPLOAD</p>
            <p class="status-label" *ngIf="isCompleting">CREATING VIDEO</p>
            <div class="progress-container" *ngIf="isUploading">
                <progress class="progress, progress-bar" max="100" [value]="progress"></progress>
                <span class="progress-percent">{{ progress }}%</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>