import { inject } from '@angular/core';
import { MatLegacyDialog as  MatDialog } from '@angular/material/legacy-dialog';
import { HostingVideoViewerDialogData } from './hosting-video-viewer-dialog.interface';
import { HostingVideoViewerDialogComponent } from './hosting-video-viewer-dialog.component';

export function useHostingVideoViewerDialogManager(dialog = inject(MatDialog)) {
    const open = (data: HostingVideoViewerDialogData) => {
        return dialog.open(HostingVideoViewerDialogComponent, {
            width: '600px',
            disableClose: true,
            data
        })
    }

    return { open }
}