import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'moment';
import { DurationFormatOptions, DurationUtils } from '../duration.utils';

@Pipe({ name: 'formatDuration', standalone: true })
export class FormatDurationPipe implements PipeTransform {
  transform(duration: Duration, options?: DurationFormatOptions) {
    if (!duration) return null;
    return DurationUtils.formatDuration(duration, options);
  }
}
