<div class="drawable-toolbar" *ngrxLet="{ isActive: isActive$, color: color$, shape: shape$ } as vm">
    <div *ngIf="vm.isActive" class="toolbar-items">
        <button 
            *ngIf="drawResult$ | async" 
            mat-button
            color="primary"
            type="button" 
            class="clear-btn"
            (click)="clear()"
        >Clear</button>
        <div class="tools-container">
            <button 
                *ngFor="let toolbarColor of colors" 
                type="button" 
                class="color-btn"
                [style.backgroundColor]="toolbarColor"
                [class.selected]="toolbarColor == vm.color"
                (click)="selectColor(toolbarColor)"
            ></button>
        </div>
        <div class="tools-container">
            <button 
                *ngFor="let toolbarShape of shapes"
                type="button" 
                class="shape-btn"
                [class.selected]="toolbarShape.type == vm.shape"
                (click)="selectShape(toolbarShape.type)"
            >
                <mat-icon>{{toolbarShape.icon}}</mat-icon>
            </button>
        </div>
    </div>

    <button
        type="button"
        class="activate-btn"
        [disabled]="disabled"
        (click)="toggleActive(!vm.isActive)"
        [class.active]="vm.isActive"
    >
        <mat-icon>brush</mat-icon>
    </button>
</div>