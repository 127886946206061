import { BehaviorSubject, first, Observable, takeUntil } from 'rxjs';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PlayerState, VideoDetails } from './player.interfaces';

import { Cleanupable } from '../../../classes/cleanupable';
import { RecordingType } from 'libs/frontend/common/src/extension';
import { Trimming } from '../transformation.interface';
import { Video } from '../../../interfaces';
import videojs from 'video.js';
import { CommonModule } from '@angular/common';
import { AssetPreloadingService } from '../../../services';
import { File } from '@openreel/creator/common';

@Component({
  selector: 'openreel-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PlayerComponent extends Cleanupable implements OnInit, OnDestroy {
  private isReady = new BehaviorSubject(false);

  @ViewChild('primaryVideoParent', { static: true })
  primaryVideoParent: ElementRef;

  @ViewChild('secondaryVideoParent', { static: true })
  secondaryVideoParent: ElementRef;

  @Input()
  video$: Observable<Video | File>;
  @Input()
  trimming: Trimming;

  @Input()
  options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
  };

  @Output()
  details = new EventEmitter<VideoDetails>();
  @Output()
  state = new EventEmitter<PlayerState>();

  player: videojs.Player;
  secondaryPlayer: videojs.Player;
  secondaryVideoType: RecordingType = null;

  currentTime$ = new BehaviorSubject<number>(0);

  isReady$ = this.isReady.asObservable();
  hasTwoStreams = false;

  constructor(private readonly preloadingService: AssetPreloadingService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.video$.subscribe((video) => {
        if (!video) {
          this.disposePlayers();
          return;
        }

        if ('provider' in video && 'path' in video) {
          this.preloadingService
            .preloadAsset(video.path, video.provider, 'video')
            .pipe(first(), takeUntil(this.ngUnsubscribe))
            .subscribe((assetUrl) => {
              this.setUpPlayer({
                mainStream: assetUrl,
              });
            });
        } else {
          if (video.mainBlob) {
            video.mainStream = window.URL.createObjectURL(video.mainBlob);
            if (!video.contentType) {
              video.contentType = video.mainBlob.type;
            }
          }

          if (video.secondaryBlob) {
            video.secondaryStream = window.URL.createObjectURL(video.secondaryBlob);
            if (!video.contentType) {
              video.contentType = video.secondaryBlob.type;
            }
          }

          this.setUpPlayer(video);
          this.setUpSecondaryPlayer(video);

          if (this.player && this.secondaryPlayer) {
            this.player.muted(false);
            this.secondaryPlayer.muted(true);
          } else {
            if (this.player) {
              this.player.muted(false);
            }
            if (this.secondaryPlayer) {
              this.secondaryPlayer.muted(false);
            }
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.disposePlayers();
    super.ngOnDestroy();
  }

  mute() {
    if (this.player) {
      this.player.muted(true);
    }
    if (this.secondaryPlayer) {
      this.secondaryPlayer.muted(true);
    }
  }

  unmute() {
    if (this.player) {
      this.player.muted(false);
    }
    if (this.secondaryPlayer) {
      this.secondaryPlayer.muted(false);
    }
  }

  play() {
    if (!this.player) return;

    try {
      if (this.player.paused()) {
        this.player.play();
      }
    } catch (err) {
      console.error(err);
    }
  }

  pause() {
    if (!this.player) return;
    try {
      if (!this.player.paused()) {
        this.player.pause();
      }
    } catch (err) {
      console.error(err);
    }
  }

  stop() {
    if (!this.player) return;

    const startPos = this.trimming?.startTime || 0;
    try {
      if (!this.player.paused()) {
        this.player.pause();
      }
      this.player.currentTime(startPos);
    } catch (err) {
      console.error(err);
    }
  }

  private disposePlayers() {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }

    if (this.secondaryPlayer) {
      this.secondaryPlayer.dispose();
      this.secondaryPlayer = undefined;
    }
  }

  private setUpPlayer(video: Video) {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }

    if (!video.mainStream) {
      return;
    }

    const videoElem = this.createVideoElement(true);
    videoElem.setAttribute('controls', '');
    this.primaryVideoParent.nativeElement.appendChild(videoElem);

    this.player = videojs(
      videoElem,
      {
        ...this.options,
        sources: [{ src: video.mainStream, type: video.contentType }],
      },
      () => {
        this.isReady.next(true);
      }
    );
    this.player.on('play', () => this.onPlay());
    this.player.on('pause', () => this.onPause());
    this.player.on('timeupdate', () => this.onTimeUpdate());
    this.player.one('loadedmetadata', () => this.onLoadedMetadata(video));
  }

  private setUpSecondaryPlayer(video: Video) {
    this.hasTwoStreams = video.secondaryStream ? true : false;
    if (!this.hasTwoStreams) {
      if (this.secondaryPlayer) {
        this.secondaryPlayer.dispose();
        this.secondaryVideoType = null;
        this.secondaryPlayer = undefined;
      }
      return;
    }

    this.secondaryVideoType = video.recordingType;

    if (this.secondaryPlayer) {
      this.secondaryPlayer.dispose();
      this.secondaryPlayer = undefined;
    }

    // This is required because videojs's dispose will delete the video element
    const videoElem = this.createVideoElement(false);
    this.secondaryVideoParent.nativeElement.appendChild(videoElem);
    this.secondaryVideoParent.nativeElement.classList.add('rec-type-' + video.recordingType);

    this.secondaryPlayer = videojs(videoElem, {
      autoplay: false,
      fluid: true,
      controls: false,
      sources: [{ src: video.secondaryStream, type: video.contentType }],
    });
  }

  private onPlay() {
    this.adjustStartTime();
    if (this.hasTwoStreams && this.secondaryPlayer) {
      this.secondaryPlayer.play();
    }
    this.state.emit('playing');
  }

  private onPause() {
    if (this.hasTwoStreams && this.secondaryPlayer) {
      this.secondaryPlayer.pause();
    }
    this.state.emit('paused');
  }

  private onLoadedMetadata(video: Video) {
    const duration = this.player.duration();
    this.details.emit({
      duration: duration * 1000 || video.duration || 0,
      mainStream: video.mainStream,
      mainBlob: video.mainBlob,
    });
  }

  private onTimeUpdate() {
    if (!this.player) {
      this.currentTime$.next(0);
      return;
    }

    this.currentTime$.next(this.player.currentTime());

    this.adjustTimeUpdate();

    if (this.hasTwoStreams && this.secondaryPlayer) {
      this.secondaryPlayer.currentTime(this.player.currentTime());
    }
  }

  private adjustStartTime() {
    if (this.player && this.trimming) {
      const { startTime } = this.trimming;
      const currentTime = this.player.currentTime();
      const startTimeSecs = startTime / 1000.0;

      if (currentTime < startTimeSecs) {
        this.player.currentTime(startTimeSecs);
      }
    }
  }

  private async adjustTimeUpdate() {
    if (this.player && this.trimming) {
      const { startTime, endTime } = this.trimming;
      const currentTime = this.player.currentTime();
      const startTimeSecs = startTime / 1000.0;
      const endTimeSecs = endTime / 1000.0;

      if (currentTime < startTimeSecs) {
        this.player.currentTime(startTimeSecs);
      }

      if (this.player.currentTime() > endTimeSecs) {
        if (this.player.loop()) {
          this.player.currentTime(startTimeSecs);
        } else if (!this.player.paused()) {
          this.player.pause();
        }
      }
    }
  }

  private createVideoElement(primary: boolean) {
    const videoElem = document.createElement('video');
    videoElem.classList.add('video-js', primary ? 'primary' : 'secondary');
    videoElem.setAttribute('preload', 'auto');
    videoElem.setAttribute('playsinline', '');
    return videoElem;
  }
}
