import * as Interfaces from './workflow-template.interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private urls = {
    list: `${commonenv.nextGenApiUrl}workflows/templates`,
    ai: `${commonenv.nextGenApiUrl}workflows/templates/ai-composer`,
    blueprints: `${commonenv.nextGenApiUrl}workflows/templates/blueprints`,
    byId: (templateId: number) => `${commonenv.nextGenApiUrl}workflows/templates/${templateId}`,
    byIdFields: (templateId: number) => `${commonenv.nextGenApiUrl}workflows/templates/${templateId}/fields`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getTemplateById(templateId: number) {
    return this.httpClient.get<Interfaces.TemplateDto>(this.urls.byId(templateId));
  }

  getTemplateFields(templateId: number) {
    return this.httpClient.get<Interfaces.WorkflowTemplateFields>(this.urls.byIdFields(templateId));
  }

  getTemplates() {
    return this.httpClient.get<Interfaces.WorkflowTemplateList[]>(this.urls.list);
  }

  getBlueprintTemplates() {
    return this.httpClient.get<Interfaces.WorkflowTemplateList[]>(this.urls.blueprints);
  }

  getAiComposerTemplates() {
    return this.httpClient.get<Interfaces.WorkflowTemplateList[]>(this.urls.ai);
  }
}
