import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export class PointerDownEvent {
  readonly pageX: number;
  readonly pageY: number;
  readonly originalEvent: MouseEvent | TouchEvent;

  constructor(event: MouseEvent | TouchEvent) {
    if (event instanceof MouseEvent) {
      this.pageX = event.pageX;
      this.pageY = event.pageY;
    } else {
      this.pageX = event.changedTouches[0].pageX;
      this.pageY = event.changedTouches[0].pageY;
    }
    this.originalEvent = event;
  }
}

/**
 * Used for handling mousedown and touchstart as a single event
 */
@Directive({
  selector: '[openreelPointerDown]',
  standalone: true,
})
export class PointerDownDirective {
  @Output() openreelPointerDown = new EventEmitter<PointerDownEvent>();

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.openreelPointerDown.next(new PointerDownEvent(event));
  }

  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
    this.openreelPointerDown.next(new PointerDownEvent(event));
  }
}
