import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AsyncPipe, DatePipe, JsonPipe, NgIf } from '@angular/common';
import { ApprovalHistoryStore } from '../approval-history.store';
import { MatTableModule } from '@angular/material/table';
import { ApprovalActionPipe } from '../approval-action.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'openreel-approval-history-dialog',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    JsonPipe,
    DatePipe,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    ApprovalActionPipe,
    MatProgressSpinnerModule,
  ],
  templateUrl: './approval-history-dialog.component.html',
  styleUrls: ['./approval-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalHistoryDialogComponent {
  isLightThemed = true;
  isPopup = true;

  readonly displayedColumns = ['performer', 'action', 'date'];

  private approvalHistoryStore = inject(ApprovalHistoryStore);

  approvalEvents$ = this.approvalHistoryStore.approvalEvents$;
  isLoading$ = this.approvalHistoryStore.isLoading$;

  constructor() {
    this.approvalHistoryStore.refreshApprovalHistory();
  }
}
