import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VideoRetryComponent } from './video-retry/video-retry.component';
import { ROUTE_VIDEO_RETRY } from '@openreel/frontend/common';

const routes: Routes = [
  {
    path: ROUTE_VIDEO_RETRY,
    component: VideoRetryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VideoRetryRoutingModule {}
