import { S3Regions } from './../constants';

export enum Destination {
  DropBox = 'DropBox',
  S3 = 'S3',
}

export enum DestinationableStatus {
  pending = 'pending',
  failed = 'failed',
  successful = 'successful',
  uploading = 'uploading'
}

export interface Destinationable {
  id: number;
  status: DestinationableStatus;
  updated: Date;
  destinationableType: DestinationVideoType;
  failError?: string;
  destinationPath: string;
}

export interface DropBoxDestinationDetails {
  type: Destination.DropBox;
  dropbox_account_id: string;
  secret: string;
}

export interface S3DestinationDetails {
  type: Destination.S3;
  external_id: string;
  bucket_name: string;
  role_arn: string;
  region: S3Regions;
}

export interface MyDestination extends MyDestinationCreate {
  id: number;
}

export interface MyDestinationCreate {
  destinationType: Destination;
  integrationName: string;
  disabledAt?: Date;
  details?: DropBoxDestinationDetails | S3DestinationDetails;
  destinationVideoTypes: Record<DestinationVideoType, boolean>;
}

export enum DestinationVideoType {
  Creator = 'creator',
  Original = 'original',
  SD = 'sd',
  HD = 'hd',
}
