import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OpenreelAlert } from './openreel-alert.interface';

@Injectable({ providedIn: 'root' })
export class OpenreelAlertService {
  private onAlertOpen = new Subject<OpenreelAlert>();
  public onAlertOpen$ = this.onAlertOpen.asObservable();

  private onAlertClear = new Subject();
  public onAlertClear$ = this.onAlertClear.asObservable();

  showAlert(alert: OpenreelAlert) {
    this.onAlertOpen.next(alert);
  }

  clear() {
    this.onAlertClear.next(null);
  }
}
