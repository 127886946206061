import { DirectorUserDetails, UserRoleType } from '@openreel/frontend/common';

export interface AuthState {
  token: string;
  role: UserRoleType;
  admissionStatus: boolean;
  admissionDirector: string;
  user: DirectorUserDetails;
}

export const initialState: AuthState = {
  token: '',
  role: null,
  admissionStatus: null,
  admissionDirector: null,
  user: null,
};
