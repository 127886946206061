import { AllRoles } from '../constants/role.constants';

export enum SessionVideoSource {
  MobileCamera = 'mobile-cam',
  WebCamera = 'web-cam',
  WebScreen = 'web-screen',
  WebPresentation = 'web-presentation',
}

export interface VideoAuthUser {
  userId: number;
  videoId: number;
  accountId: number;
  role: AllRoles;
}

export enum SelfRecordSource {
  CreatorProject = 'creator_project',
  CaptureDashboard = 'capture_dashboard',
  CaptureProject = 'capture_project',
  Extension = 'extension',
}

export enum UserUploadRecordingType {
  Camera = 'camera',
  Screen = 'screen',
  BothPip = 'both-pip',
}
