<div *ngIf="viewtype === 'full'">
  <div fxLayout fxLayoutGap="20px">
    <div fxFlex="50">
      <mat-form-field appearance="outline" style="font-size: 9px; width: 100%">
        <input
          matInput
          placeholder="Enter name or email"
          (keyup)="handlePropagation($event)"
          (keydown)="handlePropagation($event)"
          #allMemberSearch
        />
      </mat-form-field>
    </div>
    <div fxFlex="50">
      <mat-form-field appearance="outline" style="font-size: 9px; width: 100%">
        <mat-select (selectionChange)="teamFilter($event, 'all')" placeholder="All Teams">
          <mat-option value="0">All Teams</mat-option>
          <mat-option [value]="team.id" *ngFor="let team of teamMember">{{ team.team_name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="team-members-wrapper slim-scroll" (scroll)="onUsersScrolled($event)">
    <div
      class="team-member"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
      *ngFor="let user of userList"
    >
      <div fxFlex="10" style="height: 45px">
        <ngx-avatar [name]="user.fullname" title="{{ user.fullname }}" bgColor="#3546ff" size="35"></ngx-avatar>
      </div>
      <div fxFlex="30">
        <span class="user-name">{{ user.fullname }}</span>
      </div>
      <div fxFlex="60" style="text-align: right">
        <mat-form-field
          appearance="outline"
          class="no-margin-padding"
          style="width: 160px; font-size: 10px; margin-right: 15px"
        >
          <mat-select [(ngModel)]="user.role" [disabled]="user.id == requiredUserId" placeholder="Access Level">
            <mat-option *ngFor="let role of helper.roleArr" [value]="role.value">{{ role.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-stroked-button
          style="width: 100px"
          type="button"
          class="mat-button-small"
          *ngIf="membersId.indexOf(user.id) == -1 && user.id != requiredUserId"
          (click)="addUser(user, 'add')"
          color="primary"
        >
          <mat-icon>add</mat-icon> Add
        </button>
        <button
          mat-flat-button
          style="width: 100px"
          [disabled]="user.id == requiredUserId"
          type="button"
          class="mat-button-small"
          *ngIf="membersId.indexOf(user.id) != -1 || user.id == requiredUserId"
          (click)="addUser(user, 'remove')"
          color="primary"
        >
          <mat-icon>check</mat-icon> Added
        </button>
      </div>
    </div>
    <div *ngIf="fetchUsersList" class="user-list-label">Loading....</div>
    <div *ngIf="!userList.length && !fetchUsersList" class="user-list-label">No users found</div>
  </div>
</div>
<div *ngIf="viewtype === 'side'">
  <div
    fxLayout
    fxLayoutGap="20px"
    class="team-members-wrapper slim-scroll"
    style="width: 800px"
    (scroll)="onUsersScrolled($event)"
  >
    <div fxFlex="50">
      <openreel-title-line style="margin: 6px 0" title="ACTIVE MEMBERS"></openreel-title-line>
      <span class="invite-title">Current members of the project</span>
      <div>
        <mat-form-field appearance="outline" style="font-size: 9px; width: 100%">
          <input
            matInput
            placeholder="Enter name or email"
            (keyup)="handlePropagation($event)"
            (keydown)="handlePropagation($event)"
            #existMemberSearch
          />
        </mat-form-field>
      </div>
      <p *ngIf="membersId.length === 0">No members</p>
      <div
        class="team-member"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        *ngFor="let user of existUserList"
      >
        <div fxFlex="10" style="height: 45px">
          <ngx-avatar [name]="user.fullname" title="{{ user.fullname }}" bgColor="#3546ff" size="35"></ngx-avatar>
        </div>
        <div fxFlex="40">
          <span class="user-name">{{ user.fullname }}</span>
        </div>
        <div fxFlex="40">
          <mat-form-field
            appearance="outline"
            class="no-margin-padding"
            style="width: 100px; font-size: 10px; margin-right: 15px"
          >
            <mat-select
              [(ngModel)]="user.id == requiredUserId ? 'admin' : user.role"
              [disabled]="user.id == requiredUserId"
              placeholder="Access Level"
            >
              <mat-option *ngFor="let role of helper.roleArr" [value]="role.value">{{ role.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="10">
          <span
            matTooltip="{{ removeBtnToolTip }}"
            class="material-icons"
            style="cursor: pointer"
            (click)="addUser(user, 'remove')"
            *ngIf="user.id !== currentUserId"
          >
            highlight_off
          </span>
        </div>
      </div>
    </div>
    <div fxFlex="50">
      <openreel-title-line style="margin: 6px 0" title="ACTIVE MEMBERS"></openreel-title-line>
      <span class="invite-title">Add team members to this project</span>
      <div fxLayout fxLayoutGap="20px">
        <div fxFlex="50">
          <mat-form-field appearance="outline" style="font-size: 9px; width: 100%">
            <input
              matInput
              #notExistMemberSearch
              placeholder="Enter name or email"
              (keyup)="handlePropagation($event)"
              (keydown)="handlePropagation($event)"
            />
          </mat-form-field>
        </div>
        <div fxFlex="20">
          <mat-form-field appearance="outline" style="font-size: 9px; width: 100%">
            <mat-select (selectionChange)="teamFilter($event, 'nonexistmember')" placeholder="All Teams">
              <mat-option value="0">All Teams</mat-option>
              <mat-option [value]="team.id" *ngFor="let team of teamMember">{{ team.team_name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngFor="let user of userList">
        <div
          class="team-member"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
          *ngIf="membersId.indexOf(user.id) == -1"
        >
          <div fxFlex="10" style="height: 45px">
            <ngx-avatar [name]="user.fullname" title="{{ user.fullname }}" bgColor="#3546ff" size="35"></ngx-avatar>
          </div>
          <div fxFlex="30">
            <span class="user-name">{{ user.fullname }}</span>
          </div>
          <div fxFlex="40">
            <mat-form-field
              appearance="outline"
              class="no-margin-padding"
              style="width: 100px; font-size: 10px; margin-right: 5px"
            >
              <mat-select
                [(ngModel)]="user.id == requiredUserId ? 'admin' : user.role"
                [disabled]="user.id == requiredUserId"
                placeholder="Access Level"
              >
                <mat-option *ngFor="let role of helper.roleArr" [value]="role.value">{{ role.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="20">
            <button
              matTooltip="{{ addBtnToolTip }}"
              mat-stroked-button
              style="width: 80px"
              type="button"
              class="mat-button-small"
              *ngIf="membersId.indexOf(user.id) == -1 && user.id != requiredUserId"
              (click)="addUser(user, 'add')"
              color="primary"
            >
              <mat-icon>add</mat-icon> Add
            </button>
          </div>
        </div>
      </ng-container>
      <div *ngIf="fetchUsersList" class="user-list-label">Loading....</div>
      <div *ngIf="!userList.length && !fetchUsersList" class="user-list-label">No users found</div>
    </div>
  </div>
</div>
