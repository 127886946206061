<h2 mat-dialog-title>
  <div class="title" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="flext-start center" fxLayoutGap="20px">
      <div class="icon-container">
        <mat-icon color="primary">person_add</mat-icon>
      </div>

      <h1 class="headline-h600">Collaborate with others</h1>
    </div>

    <button class="btn-close" data-cy="btn-close" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
</h2>

<mat-dialog-content>
  <div [formGroup]="form" fxLayout="column" fxLayoutGap="8px">
    <openreel-access-settings [data]="data" [enableAccessControl]="userCanManageAccessType$ | async"></openreel-access-settings>

    <div
      *ngIf="(isHostingSocialShareEnabled$ | async) && isPublic"
      class="social-share-container"
      @heightCollapse
      @fadeIn
    >
      <openreel-social-share
        [shareUrl]="data.shareUrl"
        [entityType]="entityType"
        [enableYoutube]="(isEnableYoutubeSharing$ | async) && (hasYoutubeIntegratedUsers$ | async)"
        (share)="socialShare($event)"
      />
    </div>

    <div
      *ngIf="advancedSharingEnabled"
      class="advanced-settings"
      fxLayout="column"
      fxLayoutGap="8px"
      @heightCollapse
      @fadeIn
    >
      <openreel-set-password [data]="data"></openreel-set-password>

      <openreel-embed-video-link
        *ngIf="isVideo"
        @heightCollapse
        @fadeIn
        [data]="data"
        formControlName="embed"
      ></openreel-embed-video-link>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="actions" fxLayout="row" fxLayoutGap="5px">
    <button
      data-cy="copy-video-link"
      mat-stroked-button
      fxFlex="100%"
      class="copy-link share-action-button"
      [class.clicked]="copyLinkClicked$ | async"
      [disabled]="(formInvalid$ | async) || (saving$ | async) || ((passwordValid$ | async) === false)"
      (click)="copyLink($event)"
    >
      <span class="copy-link-label copy">
        <mat-icon class="copy-button-icon copy-link-icon">add_link</mat-icon> Copy {{ isVideo ? 'video' : 'hub' }} link
      </span>

      <span class="copy-link-label copied">
        <mat-icon class="copy-button-icon">done</mat-icon> Link Copied
      </span>
    </button>

    <button
      data-cy="btn-done"
      mat-flat-button
      fxFlex="100%"
      color="primary"
      class="share-action-button"
      [disabled]="(formInvalid$ | async) || (saving$ | async) || ((passwordValid$ | async) === false)"
      (click)="share()"
    >
      Done
    </button>
  </div>
</mat-dialog-actions>
