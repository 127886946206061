<div #videoContainer>
  <video
    *ngIf="isBrowser"
    #videoEl
    controls
    playsinline
    preload="none"
    class="hosting-player vjs-16-9 video-js vjs-big-play-centered vjs-show-big-play-button-on-pause"
  >
    {{ srcData.altText }}
  </video>
  <div *ngIf="!isBrowser" class="hosting-player vjs-16-9 video-js vjs-big-play-centered thumb">
    <img [src]="srcData.thumbUrl" [alt]="srcData.altText" />
  </div>
</div>
