import { Component, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserData, Member, TeamMember } from '../../interfaces/team.interface';
import { HelperService } from '../../services/helper/helper.service';
import { TeamService } from '../../services/team/team.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { TitleLineComponent } from '../title-line';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarModule } from 'ngx-avatar';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'openreel-invite-internal-user',
  templateUrl: './or-invite-internal-user.component.html',
  styleUrls: ['./or-invite-internal-user.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySelectModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    MatIconModule,
    AvatarModule,
    ToastrModule,
    TitleLineComponent,
  ],
})
export class OrInviteInternalComponent implements OnInit, AfterViewInit {
  private _membersData: Array<Member> = [];

  @Input() heading: string;
  @Input() searchFilter: string;
  @Input() viewtype = 'full';
  @Input() set membersData(value: Array<Member>) {
    this._membersData = value;
    this.setMemberIds();
  }
  get membersData(): Array<Member> {
    return this._membersData;
  }

  @Input() addBtnToolTip: string;
  @Input() removeBtnToolTip: string;

  @Output() onChangeUser = new EventEmitter<Array<Member>>();
  company_slug: string;
  search_string = '';
  userList: Array<UserData> = [];
  existUserList: Array<UserData> = [];
  membersId: Array<number> = [];
  teamMember: Array<TeamMember> = [];
  team_id = 0;
  requiredUserId = 0;
  search_type = 'all'; //all,existmember,nonexistmember
  @ViewChild('existMemberSearch') existMemberSearch: ElementRef;
  @ViewChild('notExistMemberSearch') notExistMemberSearch: ElementRef;
  @ViewChild('allMemberSearch') allMemberSearch: ElementRef;
  fetchUsersList: boolean;
  usersListPage = 1;
  allUsersLoaded: boolean;
  usersRequestLimit = 50;
  currentUserId: number;

  constructor(
    private teamService: TeamService,
    public helper: HelperService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    const cookieData = JSON.parse(this.authService.getCookie());
    if (cookieData) {
      this.company_slug = cookieData.data.site_user_slug;
    }
  }

  setMemberIds() {
    this.membersId = [];
    if (this.membersData.length > 0) {
      for (let i = 0; i < this.membersData.length; i++) {
        this.membersId.push(this.membersData[i].member_id);
        //if no role found then assign deafult role
        if (!this.membersData[i].role) this.membersData[i].role = 'admin';
        if (this.membersData[i].is_required) this.requiredUserId = this.membersData[i].member_id;
      }
    }
  }

  ngOnInit() {
    this.getUserList();
    if (this.searchFilter === 'team') this.getTeamList();
    this.currentUserId = this.authService.getUserDetails()?.data?.user_id;
  }
  ngAfterViewInit() {
    this.bindAllSearch();
  }
  debounceOnKeyUp(source) {
    return fromEvent(source, 'keyup').pipe(
      map((event: InputEvent) => (<HTMLInputElement>event.target).value),
      debounceTime(500),
      distinctUntilChanged()
    );
  }
  bindAllSearch() {
    if (this.allMemberSearch) {
      this.debounceOnKeyUp(this.allMemberSearch.nativeElement).subscribe((text: string) => {
        this.userList = [];
        this.searchUser(text, 'all');
      });
    }
    if (this.existMemberSearch) {
      this.debounceOnKeyUp(this.existMemberSearch.nativeElement).subscribe((text: string) => {
        this.existUserList = [];
        this.searchUser(text, 'existmember');
      });
    }
    if (this.notExistMemberSearch) {
      this.debounceOnKeyUp(this.notExistMemberSearch.nativeElement).subscribe((text: string) => {
        this.userList = [];
        this.searchUser(text, 'nonexistmember');
      });
    }
  }
  searchUser(text: string, search_type: string) {
    this.search_string = text;
    this.search_type = search_type;
    this.resetUsersListRequestParams();
    this.getUserList();
  }
  addUser(user, type) {
    if (type === 'add') {
      if (!user.role) this.toastr.error('Error!', 'Please select a role');
      else {
        this.membersId.push(user.id);
        this.membersData.push({
          member_id: user.id,
          role: user.role,
          name: user.fullname,
          email: user.email,
        });
        this.existUserList.push(user);
      }
    } else if (type === 'remove') {
      this.deleteUserData(user.id);
    }
    this.onChangeUser.emit(this.membersData);
  }
  deleteUserData(id) {
    this.membersId.splice(this.membersId.indexOf(id), 1);
    const membersDataKey = this.membersData.map((item) => item.member_id).indexOf(id);
    const existUserListKey = this.existUserList.map((item) => item.id).indexOf(id);
    this.membersData.splice(membersDataKey, 1);
    this.existUserList.splice(existUserListKey, 1);
  }
  getUserList() {
    this.fetchUsersList = true;
    this.teamService
      .getUserList({
        search_string: this.search_string,
        team_id: this.team_id,
        limit: this.usersRequestLimit,
        page: this.usersListPage,
      })
      .subscribe(
        (res) => {
          if (!res.data || res.data.length < this.usersRequestLimit) {
            this.allUsersLoaded = true;
          }
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].role = 'admin'; //default role director
            const memberKey = this.membersId.indexOf(res.data[i].id);
            if (this.search_type === 'existmember' && memberKey !== -1) {
              //search for already exist members in project
              this.existUserList.push(res.data[i]);
            } else if (this.search_type === 'nonexistmember') {
              //search for non exist members in project
              this.userList.push(res.data[i]);
            } else if (this.search_type === 'all') {
              //search for all members in project
              this.userList.push(res.data[i]);
              if (memberKey !== -1) this.existUserList.push(res.data[i]);
            }
          }
          this.fetchUsersList = false;
        },
        (error) => {
          this.fetchUsersList = false;
          this.toastr.error(error.message, 'Error!');
        }
      );
  }
  getTeamList() {
    this.teamService.getTeamList({ limit: 50, page: 1, team_name: '' }).subscribe((res) => {
      this.teamMember = res.data.rows;
    });
  }
  teamFilter(event, type) {
    this.search_type = type;
    this.team_id = event.value;
    this.userList = [];
    this.resetUsersListRequestParams();
    this.getUserList();
  }
  handlePropagation(event: Event) {
    event.stopPropagation();
  }

  onUsersScrolled(event) {
    // fetch more items on scroll
    if (
      !this.allUsersLoaded &&
      !this.fetchUsersList &&
      event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1
    ) {
      this.usersListPage++;
      this.getUserList();
    }
  }

  resetUsersListRequestParams() {
    this.usersListPage = 1;
    this.allUsersLoaded = false;
  }
}
