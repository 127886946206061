import { Predicate } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import ValidatorJS from 'validator';
import isEmail from 'validator/lib/isEmail';

export class OpenreelValidators {
  static maxlengthReached(maxlength: number): ValidatorFn {
    return (ctrl: AbstractControl): { maxlengthReached: boolean } => {
      if (!ctrl.value) {
        return null;
      }

      const ctrlLength = ctrl.value.length;
      const valid = ctrlLength < maxlength;
      return valid ? null : { maxlengthReached: true };
    };
  }

  static isEmail(options?: ValidatorJS.IsEmailOptions): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;

      return isEmail(control.value, options) ? null : { invalidEmail: true };
    };
  }

  static requiredIf(predicate: Predicate<AbstractControl>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (predicate(control)) {
        return Validators.required(control);
      }

      return null;
    };
  }

  static readonly WEBSITE_PATTERN = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}/;
}
