import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SetPasswordComponent } from './set-password/set-password.component';
import { AccessSettingsModule } from './access-settings/access-settings.module';
import { EmbedVideoLinkModule } from './embed-video-link/embed-video-link.module';
import { HostingShareComponentStore } from './hosting-share.store';
import { ShareComponent } from './share.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SocialShareComponent } from './social-share/social-share.component';

@NgModule({
  declarations: [ShareComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    EmbedVideoLinkModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDividerModule,
    MatButtonModule,
    AccessSettingsModule,
    SetPasswordComponent,
    SocialShareComponent,
  ],
  providers: [HostingShareComponentStore],
})
export class ShareModule {}
