import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AngularResizeEventModule } from 'angular-resize-event';
import { AnimationModule } from './components/animation/animation.module';
import { UiModule } from '@openreel/ui';
import { AvatarModule } from 'ngx-avatar';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxStronglyTypedFormsModule } from 'ngx-strongly-typed-forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  exports: [CommonModule, FormsModule, UiModule, AngularResizeEventModule, FlexLayoutModule],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    UiModule,
    FlexLayoutModule,
    // ThemeModule,
    AvatarModule,
    AnimationModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    NgxStronglyTypedFormsModule,
    AngularResizeEventModule,
  ],
})
export class OpenreelCommonModule {}
