import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationRoutingModule } from './navigation-routing.module';
import { NgModule } from '@angular/core';
import { OpenreelCommonModule } from '@openreel/frontend/common';
import { NavigationComponent } from './navigation.component';
import { NavigationSearchModule } from './navigation-search/navigation-search.module';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarNoUserComponent } from './navbar/navbar-no-user.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AvatarModule } from 'ngx-avatar';
import { AlertEventCaptureDirective } from '@openreel/frontend/common/hosting';
import { OpenreelAlertContainerComponent } from '@openreel/frontend/common/components/openreel-alert-banner-container';
import { OpenreelPageTitleComponent } from '@openreel/ui/widgets/openreel-page-title';
import { OpenreelDividerBarComponent } from '@openreel/ui/widgets/openreel-divider-bar';
import { FeatureEnabledDirective } from '@openreel/frontend/common/directives/feature-enabled.directive';
import { OpenreelMenuComponent } from '@openreel/frontend/common/components/menu/menu.component';

const svgIconNames = ['nav-search'];

@NgModule({
  declarations: [NavigationComponent, NavbarComponent, NavbarNoUserComponent],
  imports: [
    OpenreelCommonModule,
    FlexLayoutModule,
    NavigationRoutingModule,
    NavigationSearchModule,
    SharedModule,
    AvatarModule,
    AlertEventCaptureDirective,
    OpenreelAlertContainerComponent,
    OpenreelPageTitleComponent,
    OpenreelDividerBarComponent,
    FeatureEnabledDirective,
    OpenreelMenuComponent,
  ],
  exports: [NavbarComponent, NavbarNoUserComponent],
})
export class NavigationModule {
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {
    for (const icon of svgIconNames) {
      const path = `assets/ui/material-icons/${icon}.svg`;
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    }
  }
}
