<h2 mat-dialog-title>Share on Youtube</h2>

<mat-dialog-content>
  <form [formGroup]="sharingForm" class="sharing-form">
    <mat-form-field class="form-control" appearance="outline">
      <mat-label>Integrated User</mat-label>
      <mat-select formControlName="integratedUserId">
        <mat-option *ngFor="let user of integratedUsers$ | async" [value]="user.id">
          {{user.googleUserFullname}} ({{user.googleUserEmail}})
        </mat-option>
      </mat-select>
      <mat-error *ngIf="sharingForm.controls.integratedUserId.hasError('required')">
        Google Integrated User is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outline">
      <mat-label>Youtube Channel</mat-label>
      <mat-select formControlName="channelId">
        <mat-option *ngFor="let channel of userChannels$ | async" [value]="channel.id">
          {{channel.title}} ({{channel.id}})
        </mat-option>
      </mat-select>
      <mat-error *ngIf="sharingForm.controls.channelId.hasError('required')">
        Youtube Channel is required
      </mat-error>
      <mat-error *ngIf="sharingForm.controls.channelId.hasError('uploadNotAllowed')">
        Cannot upload videos longer than 15 minutes to this channel
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="selectedChannel$ | async as selectedChannel">
      <p class="intermediate-features-warning" *ngIf="!selectedChannel.allowedIntermediateFeatures">
        <span>Warning:</span> Youtube "Intermediate" features is not enabled for the selected channel.
        Custom thumbnails and videos longer than 15 minutes cannot be uploaded.
      </p>
    </ng-container>

    <mat-form-field appearance="outline" class="form-control">
      <mat-label>Video Privacy</mat-label>
      <mat-select formControlName="privacyStatus">
        <mat-option [value]="VideoPrivacyStatus.Unlisted">Unlisted</mat-option>
        <mat-option [value]="VideoPrivacyStatus.Private">Private</mat-option>
        <mat-option [value]="VideoPrivacyStatus.Public">Public</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-flat-button [disabled]="sharingForm.invalid" color="primary" (click)="share()">Share</button>
  <button type="button" mat-flat-button [mat-dialog-close]="null">Close</button>
</mat-dialog-actions>
