import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoRetryComponent } from './video-retry/video-retry.component';
import { VideoRetryRoutingModule } from './video-retry-routing.module';
import { OpenreelCommonModule } from '@openreel/frontend/common';
import { PlayerComponent } from '@openreel/frontend/common/components/video-editor';
import { NavigationModule } from '../navigation/navigation.module';
import { UiModule } from '@openreel/ui';
import { ThemeModule } from '@openreel/theme';
import { OrSelectComponent } from '@openreel/ui/forms/or-select';

@NgModule({
  declarations: [VideoRetryComponent],
  imports: [
    CommonModule,
    VideoRetryRoutingModule,
    OpenreelCommonModule,
    NavigationModule,
    UiModule,
    ThemeModule,
    PlayerComponent,
    OrSelectComponent,
  ],
})
export class VideoRetryModule {}
