<div class="banner-container {{ type }}" [class.openreel-secondary-theme]="type !== 'info'">
  <div class="banner-content">
    <span class="message">{{ message }}</span>
    <button
      class="action-btn"
      mat-raised-button
      [color]="btnColor"
      (click)="onActionButtonClick();"
      *ngIf="buttonText"
    >
      <mat-icon *ngIf="buttonPrefixIcon">{{ buttonPrefixIcon }}</mat-icon>
      {{ buttonText }}
      <mat-icon *ngIf="buttonSuffixIcon">{{ buttonSuffixIcon }}</mat-icon>
    </button>
  </div>
  <button class="close-btn" mat-icon-button (click)="onCloseButtonClick();" *ngIf="!isCloseButtonHidden">
    <mat-icon>close</mat-icon>
  </button>
</div>
