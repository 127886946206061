export const TEXT_BOX_EFFECTS_LIMITS = {
  shadow: {
    blur: {
      min: 1,
      max: 50,
      step: 1,
    },
    offset: {
      min: 1,
      max: 50,
      step: 1,
    },
    angle: {
      min: 0,
      max: 360,
      step: 1,
    },
    opacity: {
      min: 0,
      max: 100,
      step: 1,
    },
  },
  glow: {
    blur: {
      min: 1,
      max: 100,
      step: 1,
    },
  },
  echo: {
    offset: {
      min: 1,
      max: 50,
      step: 1,
    },
    angle: {
      min: 0,
      max: 360,
      step: 1,
    },
    opacity: {
      min: 0,
      max: 100,
      step: 1,
    },
  },
  outline: {
    thickness: {
      min: 1,
      max: 10,
      step: 1,
    },
  },
  glitch: {
    offset: {
      min: 1,
      max: 25,
      step: 1,
    },
    angle: {
      min: 0,
      max: 360,
      step: 1,
    },
  },
  splice: {
    thickness: {
      min: 1,
      max: 10,
      step: 1,
    },
    offset: {
      min: 1,
      max: 50,
      step: 1,
    },
    angle: {
      min: 0,
      max: 360,
      step: 1,
    },
  },
  hollow: {
    thickness: {
      min: 1,
      max: 10,
      step: 1,
    },
  }
};
