import {
  boundsPercentToPx,
  pctToPx,
  PresetField,
  radiansToDegrees,
  SimpleBounds,
  TextBoxProperties,
} from '@openreel/creator/common';
import { TextDocument, TextJustify, TextLayer } from '../interfaces/lottie.interface';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';

const LETTER_SPACING_ANIMATOR = '#MainTextSpacing';
const LS_CORRECTION_FACTOR_PC = 15;

interface TextNodeUpdate {
  rootNode: TextLayer;
  fontSize: number;
  lineHeight: number;
  letterSpacing: number;
  justification: TextJustify;
  textBoxSize: number[];
  bounds: SimpleBounds;
  angleRad: number;
  isSkottie?: boolean;
}

export class TransformerTextBox extends TransformerBase {
  test(fieldDef: PresetField, textBoxProperties?: TextBoxProperties) {
    return fieldDef.type === 'text' && !!textBoxProperties;
  }

  process({ animation, renderOptions, fieldDef, fieldValues, textBoxProperties }: TransformerProcessOptions) {
    if (!fieldDef.lottiePath || !textBoxProperties?.bounds) {
      return animation;
    }

    const boundsPx = boundsPercentToPx(
      textBoxProperties?.bounds as SimpleBounds,
      renderOptions.width,
      renderOptions.height
    );
    const fontSize = pctToPx(textBoxProperties.style.fontSizeRelative, Math.min(renderOptions.height, renderOptions.width));
    const lineHeight = fontSize * textBoxProperties.style.lineHeight;
    const letterSpacing = pctToPx(textBoxProperties.style.letterSpacingRelative, fontSize);

    animation.h = renderOptions.height;
    animation.w = renderOptions.width;

    let justification: TextJustify;
    switch (fieldValues.textAlign) {
      case 'left':
        justification = TextJustify.Left;
        break;
      case 'center':
        justification = TextJustify.Center;
        break;
      case 'right':
        justification = TextJustify.Right;
        break;
      default:
        throw new Error('Unknown Text justification');
    }

    const sz = [boundsPx.width, boundsPx.height];

    if (!textBoxProperties.bounds.isTextBoxFixedWidth) {
      // we add 1% to the text width to make sure the text fits on skottie
      // on the transcoder some fonts need a bit more space to fit on one row
      // and adding 1% when the text box's width if it is not fixed is a good way to make sure the text fits
      // without altering the way the text looks
      sz[0] += sz[0] * 0.01;
    }

    const rootNodes = this.getRootNode<TextLayer | TextLayer[]>(animation, fieldDef.lottiePath);
    if (Array.isArray(rootNodes)) {
      rootNodes.forEach((rootNode) => {
        this.processTextNode({
          rootNode,
          fontSize,
          lineHeight,
          letterSpacing,
          justification,
          textBoxSize: sz,
          bounds: boundsPx,
          angleRad: textBoxProperties?.bounds.angleRad,
          isSkottie: textBoxProperties?.isSkottie,
        });
      });
    } else {
      this.processTextNode({
        rootNode: rootNodes,
        fontSize,
        lineHeight,
        letterSpacing,
        justification,
        textBoxSize: sz,
        bounds: boundsPx,
        angleRad: textBoxProperties?.bounds.angleRad,
        isSkottie: textBoxProperties?.isSkottie,
      });
    }

    return animation;
  }

  private processTextNode({
    rootNode,
    fontSize,
    lineHeight,
    letterSpacing,
    justification,
    textBoxSize,
    bounds,
    angleRad,
    isSkottie,
  }: TextNodeUpdate) {
    const lineShiftCorrectionOffset = (lineHeight * LS_CORRECTION_FACTOR_PC) / 100;
    let ls = -1 * lineHeight + lineShiftCorrectionOffset;
    if (isSkottie) {
      ls = 0;
    }

    rootNode.t.d.k[0].s = {
      ...rootNode.t.d.k[0].s,
      s: fontSize,
      lh: lineHeight,
      j: justification,
      sz: textBoxSize,
      ls,
    } as TextDocument;

    // lottie only supports setting letter spacing on an animator, so we have an animator that targets the entire text
    const letterSpacingAnimator = rootNode.t.a?.find((e) => e.nm === LETTER_SPACING_ANIMATOR);
    if (letterSpacingAnimator) {
      letterSpacingAnimator.a.t.k = letterSpacing;
    }

    // place anchor to the center of the layer in order to emulate CSS rotation
    rootNode.ks.a.k = [textBoxSize[0] / 2, textBoxSize[1] / 2];
    // neeed to account for the anchor positon, because that is in the middle of the layer
    rootNode.ks.p.k = [bounds.x + textBoxSize[0] / 2, bounds.y + textBoxSize[1] / 2];
    rootNode.ks.r.k = radiansToDegrees(angleRad ?? 0);
  }
}
