import { DBSchema } from 'idb';

export enum RecordingState {
  IDLE,
  STARTING_RECORDING,
  RECORDING,
  STOPPING_RECORDING,
}
export interface ChunkRecordingSchema extends DBSchema {
  chunks: {
    key: number;
    value: Blob;
  };
}
