import { Injectable } from '@angular/core';
import {
  FileValidatorBaseService,
  FileValidationError,
} from '@openreel/ui/openreel-uploader/openreel-uploader.component';
import { splitNameAndExtension } from '@openreel/common';
import { Observable } from 'rxjs';

const ONE_GB = 1024 * 1024 * 1024;
const MAX_SIZE = ONE_GB * 5;
const VALID_EXTENSIONS = ['pdf'];

@Injectable()
export class PresentationValidatorService extends FileValidatorBaseService {
  message = 'Only .pdf files allowed.';
  constructor() {
    super();
  }

  validate(file: File): FileValidationError | Observable<FileValidationError> {
    const [, ext] = splitNameAndExtension(file.name);
    if (!VALID_EXTENSIONS.includes(ext.toLowerCase())) {
      return this.message;
    }

    if (file.size > MAX_SIZE) {
      return 'Only files up to 5GB allowed';
    }

    return this.pdfReadValidation(file);
  }

  private pdfReadValidation(file: File) {
    return new Observable<string>((obs) => {
      const reader = new FileReader();

      reader.onload = async function (e) {
        try {
          const content = e.target.result as string;
          if (content.indexOf('/Encrypt') !== -1) {
            obs.next('Pdf is password protected. Cannot use this pdf.');
          } else {
            obs.next(null);
            obs.complete();
          }
        } catch (err) {
          obs.next('Cannot read pdf file. Try another file.');
          obs.complete();
        }
      };
      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onloadend = () => obs.complete();

      reader.readAsText(file);
    });
  }
}
