import { ApolloModule, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { ApolloLink, DefaultOptions, InMemoryCache, split } from '@apollo/client/core';
import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '@openreel/frontend/common';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import { HttpHeaders } from '@angular/common/http';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink, authService: AuthService) {
        const http = new ApolloLink((operation, forward) => {
          const token = authService.jwt$.getValue();
          if (token) {
            operation.setContext({
              headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
            });
          }
          return forward(operation);
        }).concat(
          httpLink.create({
            uri: environment.graphql,
          })
        );

        const ws = new GraphQLWsLink(
          createClient({
            url: environment.graphqlWs,
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
            lazy: true,
            lazyCloseTimeout: 10000,
            connectionParams() {
              const jwt = authService.jwt$.getValue();
              const token = authService.token$.getValue();
              const result = {};

              if (jwt) {
                result['authorization'] = `Bearer ${jwt}`;
              }
              if (token) {
                result['access-token'] = token;
              }
              return result;
            },
          })
        );

        const publicWs = new GraphQLWsLink(
          createClient({
            url: environment.graphqlWs,
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
            lazy: true,
            lazyCloseTimeout: 10000,
          })
        );

        const link = split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
            return kind === 'OperationDefinition' && operation === 'subscription';
          },
          ws,
          http
        );

        const publicLink = split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
            return kind === 'OperationDefinition' && operation === 'subscription';
          },
          publicWs,
          http
        );

        return {
          default: { link, cache: new InMemoryCache(), defaultOptions },
          public: { link: publicLink, cache: new InMemoryCache(), defaultOptions },
        };
      },
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphqlClientModule {}
