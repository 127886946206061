import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AlertService } from '@openreel/ui/openreel-alert';
import { merge } from 'lodash-es';
import { from } from 'rxjs';

export interface UnsavedChangesConfirmConfig {
  message: string;
  icon?: string;
  title?: string;
  confirmButtonText?: string;
  rejectButtonText?: string;
  panelClass?: string | string[];
}

const DEFAULT_CONFIG: UnsavedChangesConfirmConfig = {
  message: "Changes you've made may not be saved.",
  icon: 'ban',
  title: 'Unsaved Changes',
  confirmButtonText: 'Exit',
  rejectButtonText: 'Stay in the Page',
  panelClass: ['creator-alert', 'warning', 'compact'],
};

export interface HasUnsavedChanges {
  get hasUnsavedChanges(): boolean;
  get unsavedChangesConfirmConfig(): UnsavedChangesConfirmConfig;
}

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard<T extends HasUnsavedChanges> implements CanDeactivate<T> {
  constructor(private readonly alertService: AlertService) {}

  canDeactivate(component: T) {
    if (!component.hasUnsavedChanges) {
      return true;
    }

    const config = merge({}, DEFAULT_CONFIG, component.unsavedChangesConfirmConfig);
    const confirmPromise = this.alertService.confirm(config.message, config);

    return from(confirmPromise);
  }
}
