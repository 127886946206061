import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AclDirective } from './acl.directive';
import { HasPermissionDirective } from './has-permission.directive';

@NgModule({
  declarations: [
    AclDirective,
  ],
  imports: [
    CommonModule,
    HasPermissionDirective,
  ],
  exports: [
    AclDirective,
    HasPermissionDirective,
  ]
})
export class AclModule {
}
