import { Injectable, InjectionToken } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HostablePermissionTypes } from '../../../hosting/constants';

export const HOSTING_SHARE_FORM = new InjectionToken<FormGroup>('Hosting share form');

export interface HostingShareForm {
  accessType: FormControl<HostablePermissionTypes>;
  requirePassword: FormControl<boolean>;
  advancedMode: FormControl<boolean>;
  password: FormControl<string | null>;
  embed: FormControl<string>;
  embedEnabled: FormControl<boolean>;
  embedEnabledAt: FormControl<Date | null>;
  embedExceed: FormControl<boolean>;
  embedVideoCount: FormControl<number>;
  embedLimit: FormControl<number>;
}

@Injectable({
  providedIn: 'root',
})
export class HostingShareFormService {
  get form(): FormGroup {
    return this._form;
  }

  private readonly _form: FormGroup;

  constructor(private readonly fb: FormBuilder) {
    this._form = this.buildForm();
  }

  private buildForm(): FormGroup<HostingShareForm> {
    return this.fb.group<HostingShareForm>({
      accessType: this.fb.control(HostablePermissionTypes.Private),
      requirePassword: this.fb.control(false),
      advancedMode: this.fb.control(false),
      password: this.fb.control(null, [Validators.minLength(5), Validators.maxLength(20)]),
      embed: this.fb.control(''),
      embedEnabled: this.fb.control(false),
      embedEnabledAt: this.fb.control(null),
      embedExceed: this.fb.control(false),
      embedVideoCount: this.fb.control(0),
      embedLimit: this.fb.control(0),
    });
  }
}
