import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AddJwtTokenInterceptor,
  AddTokenInterceptor,
  ApiUrlInterceptor,
  AssetsBaseService,
  CookieInterceptor,
  ResponseParserInterceptor,
} from '@openreel/frontend/common';
import { UPLOADER_SERVICE_TOKEN } from 'libs/ui/src/components/openreel-uploader/openreel-uploader.component';
import { AssetsService } from './assets/assets.service';
import { UploaderService } from './uploader/uploader.service';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddJwtTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseParserInterceptor,
      multi: true,
    },
    {
      provide: UPLOADER_SERVICE_TOKEN,
      useExisting: UploaderService,
    },
    {
      provide: AssetsBaseService,
      useExisting: AssetsService,
    },
  ],
})
export class CoreModule {}
