import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { Stripe } from 'stripe';
import { commonenv } from '../../environments/environment';
import { Product, PaymentMethod, Invoice } from './billing.interface';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public urls = {
    products: `${commonenv.nextGenApiUrl}billing/products`,
    invoices: `${commonenv.nextGenApiUrl}billing/invoices`,
    paymentMethods: `${commonenv.nextGenApiUrl}billing/payment-methods`,
    deleteCard: (id: string) => `${commonenv.nextGenApiUrl}billing/payment-methods/${id}`,
    setDefaultCard: (cardId: string) => `${commonenv.nextGenApiUrl}billing/payment-methods/${cardId}/make-default`,
    checkout: `${commonenv.nextGenApiUrl}billing/checkout-session`,
    update: `${commonenv.nextGenApiUrl}billing/update`,
    cancel: `${commonenv.nextGenApiUrl}billing/cancel`,
    customerPortal: `${commonenv.nextGenApiUrl}billing/customer-portal`,
  };
  constructor(public httpClient: HttpClient) {}

  getAllProducts() {
    return this.httpClient.get<Product[]>(this.urls.products).pipe(shareReplay());
  }

  getPaymentMethods() {
    return this.httpClient.get<PaymentMethod[]>(this.urls.paymentMethods);
  }

  createCard(token: string) {
    return this.httpClient.post<PaymentMethod>(this.urls.paymentMethods, { token });
  }

  deleteCard(id: string) {
    return this.httpClient.delete<Stripe.DeletedCard>(this.urls.deleteCard(id));
  }

  setDefaultPaymentMethod(cardId: string) {
    return this.httpClient.put<Stripe.Customer>(this.urls.setDefaultCard(cardId), {}).pipe(shareReplay());
  }

  getInvoices() {
    return this.httpClient.get<Invoice[]>(this.urls.invoices);
  }

  getCustomerPortalLink() {
    return this.httpClient.get<{ url: string }>(this.urls.customerPortal);
  }

  getCheckoutLink(priceId: string, quantity: number, minQuantity: number, maxQuantity: number) {
    return this.httpClient.get<{ url: string }>(this.urls.checkout, {
      params: {
        quantity: quantity,
        priceId: priceId,
        minQuantity: minQuantity,
        maxQuantity: maxQuantity,
      },
    });
  }
  updateBilling(quantity: number) {
    return this.httpClient.put(this.urls.update, { quantity: quantity });
  }

  cancelBilling() {
    return this.httpClient.delete(this.urls.cancel);
  }
}
