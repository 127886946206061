<h2 mat-dialog-title>Approval History</h2>

<mat-dialog-content>
    <div *ngIf="isLoading$ | async" class="loader-wrapper">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
    <div *ngIf="!(isLoading$ | async)" class="table-wrapper">
        <table mat-table [dataSource]="approvalEvents$ | async">      
            <!-- Performer Column -->
            <ng-container matColumnDef="performer">
              <th mat-header-cell *matHeaderCellDef> Performer </th>
              <td mat-cell *matCellDef="let element"> {{element.actionedBy?.fullname}} </td>
            </ng-container>
          
            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element"> {{element.action | approvalAction}} </td>
            </ng-container>
          
            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd/MM/yyyy HH:mm'}} </td>
            </ng-container>
          
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>