import { Directive, HostListener, Input, inject } from "@angular/core";
import { CommentEventName, CommentEventPayload } from "../../../internal-comments";
import { HostingAnalyticsService } from "../hosting-analytics.service";
import { HostingAnalyticsEvent, HostingAnalyticsEvents } from "../hosting-analytics-events";

@Directive({
    selector: '[captureCommentEvent]',
    standalone: true,
})
export class CommentEventCaptureDirective {
    @Input() captureCommentEvent: { videoId: number; hubId: number | null };

    private _hostingAnalyticsService = inject(HostingAnalyticsService);

    @HostListener('commentEvent', ['$event'])
    onCommentEvent(e: { eventName: CommentEventName; payload: CommentEventPayload }): void {
        const event = this._getAnalyticsEvent(e.eventName, e.payload)
        if(!event) return;
        this._hostingAnalyticsService.logEvent(event);
    }

    private _getAnalyticsEvent(eventName: CommentEventName, payload: CommentEventPayload): HostingAnalyticsEvent | null {
        const properties = { 
            id: this.captureCommentEvent.videoId, 
            hub_id: this.captureCommentEvent.hubId ?? null, 
            comment_type: payload.accessibility
        };
        switch(eventName) {
            case 'comment_typing_started': return HostingAnalyticsEvents.videoCommentStarted(properties);
            case 'comment_added': return HostingAnalyticsEvents.videoCommentAdded(properties);
            case 'comment_edited': return HostingAnalyticsEvents.videoCommentEdited(properties);
            case 'comment_deleted': return HostingAnalyticsEvents.videoCommentDeleted(properties);
            default: return null;
        }
    }
}