import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PremiumPaywallComponent } from '../../components/premium-paywall';

@Injectable({
  providedIn: 'root',
})
export class PremiumPaywallService {
  showPaywall = false;
  constructor(public dialog: MatDialog) {}

  openPaywallModal() {
    if (!this.showPaywall) {
      const dialog = this.dialog.open(PremiumPaywallComponent, {
        width: '650px',
        disableClose: true,
        backdropClass: 'blurred',
      });
      this.showPaywall = true;
      dialog.afterClosed().subscribe(()=>{
        this.showPaywall = false;
      })
    }
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
