
export enum UserJobType {
    Support = 'support',
    Sales = 'sales',
    Marketing = 'marketing',
    Finance = 'finance',
    ProductManagement = 'product_management',
    Design = 'design',
    Operations = 'operations',
    SoftwareDevelopment = 'software_development',
    Legal = 'legal',
    Other = 'other'
}