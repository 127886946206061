import { Cleanupable } from '../../classes';
import { Subject, Observable } from 'rxjs';
import { filter, share, map, first } from 'rxjs/operators';
import {
  ReceiveSocketMessageInfo,
  ISocketIoLib,
  ISocketIoLibService,
  EVT_REQUEST_TO_JOIN,
  RequestToJoinPayload,
} from '../../interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseSocketService extends Cleanupable {
  static ackTimeoutTime = 10000;
  socket: ISocketIoLib;
  private anySocketEvent = new Subject<ReceiveSocketMessageInfo<unknown>>();
  public anySocketEvent$ = this.anySocketEvent.asObservable().pipe(share());

  constructor(socketProvider: ISocketIoLibService) {
    super();
    this.socket = socketProvider.createSocket();
    this.socket.on('*any*', (eventName, data) => {
      const socketEvt = {
        from: data.identity,
        session: data.SessionID,
        data,
        eventName,
      };
      this.anySocketEvent.next(socketEvt);
    });
  }

  connect(url: string) {
    if (!this.socket.connectionStatus$.value.connected) this.socket.connect(url);
  }

  getEmitWhenConnected(): Observable<boolean> {
    return this.socket.connectionStatus$.pipe(
      filter((status) => status.connected),
      map((status) => status.connected),
      first()
    );
  }

  getSocketEventByName<T>(eventName: string) {
    return this.anySocketEvent$.pipe(
      filter((evt) => {
        const ret = evt.eventName === eventName;
        return ret;
      })
    ) as Observable<ReceiveSocketMessageInfo<T>>;
  }

  emitRequestToJoin(id: string, name: string) {
    this.socket.emit(EVT_REQUEST_TO_JOIN, <RequestToJoinPayload>{
      id,
      name,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.socket.disconnect();
  }

  // Common way to send messages on socket. Useful for services which need to
  // send messages in the same way as subject or director (for example, chat)
  abstract basicSocketEmit<SendValueType>(event: string, value: SendValueType): void;
}
