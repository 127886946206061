import { deleteDB, openDB } from 'idb';
import fixWebmDuration from 'webm-duration-fix';
import {
  MIME_TYPE_VIDEO_WEBM_AVC1_CHROME,
  MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX,
  MIME_TYPE_VIDEO_WEBM_VP8,
  MIME_TYPE_VIDEO_MP4,
  DB_NAME_PREFIX,
  DB_VERSION

} from './recorder.constants';
import { ChunkRecordingSchema } from './recorder.interfaces';
import { first, fromEvent, Subscription } from 'rxjs';

export function getBestCodec() {
  return MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_AVC1_CHROME)
    ? MIME_TYPE_VIDEO_WEBM_AVC1_CHROME
    : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX)
    ? MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX
    : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_VP8)
    ? MIME_TYPE_VIDEO_WEBM_VP8
    : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_MP4)
    ? MIME_TYPE_VIDEO_MP4
    : null;
}

export async function getFileData(localFileName: string): Promise<Blob> {
  const db = await openDB<ChunkRecordingSchema>(DB_NAME_PREFIX + localFileName, DB_VERSION);
  const allChunks = await db.getAll('chunks' as unknown as never);
  db.close();
  const codecs = getBestCodec()?.split(';');
  const blobType = codecs?.length ? codecs[0] : null;
  try {
    // vh1-1427: using fixWebmDuration package instead of getOriginalSeekableBlob from ts-ebml
    // as getOriginalSeekableBlob not setting the correct duration in webm files
    const ret = await fixWebmDuration(new Blob([...allChunks], { type: blobType }));
    if (ret) return ret;
  } catch (err) {
    console.log(err);
  }
  console.warn('Sending non-seekable video');
  const ret = new Blob(allChunks, { type: blobType });
  return ret;
}

export async function removeFileData(localFileName: string) {
  try {
    await deleteDB(DB_NAME_PREFIX + localFileName);
  } catch (err) {
    console.warn('Error deleting database for ' + localFileName);
  }
}

export async function getBlobVideoDuration(blob: Blob): Promise<number> {
  const tempVideoEl = document.createElement('video');

  const subscriptions: Subscription[] = [];

  const durationP = new Promise<number>((resolve, reject) => {
    subscriptions.push(
      fromEvent(tempVideoEl, 'error').pipe(first()).subscribe((event) => reject(event))
    );

    subscriptions.push(
      fromEvent(tempVideoEl, 'loadedmetadata').pipe(first()).subscribe(() => {
        // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
        if (tempVideoEl.duration === Infinity || !tempVideoEl.duration) {
          tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
          subscriptions.push(
            fromEvent(tempVideoEl, 'timeupdate').pipe(first()).subscribe(() => {
              resolve(tempVideoEl.duration);
              tempVideoEl.currentTime = 0;
            })
          );
        } else {
          // Normal behavior
          resolve(tempVideoEl.duration);
        }
      })
    );
  });

  tempVideoEl.src = window.URL.createObjectURL(blob);

  const duration = await durationP;

  subscriptions.forEach(sub => sub.unsubscribe());

  return duration;
}
