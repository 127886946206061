<h2 mat-dialog-title class="title">
  Start a new project

  <button mat-icon-button class="close-btn" (click)="onDialogCloseClicked()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="dialog-content">
  <mat-divider class="title-divider"></mat-divider>

  <form #formRef="ngForm" [formGroup]="projectFrm" (submit)="createProject()">
    <div class="form-field-title headline-h400">Project name</div>

    <mat-form-field appearance="outline" class="name-control">
      <input matInput formControlName="name" required placeholder="Enter Project Name" />

      <mat-error class="form-field-error paragraph-p100" *ngIf="projectFrm?.controls.name?.errors?.required">
        Required Field
      </mat-error>
    </mat-form-field>

    <mat-divider class="divider"></mat-divider>

    <openreel-autocomplete-invite-internal-user [(membersData)]="members"></openreel-autocomplete-invite-internal-user>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="actions">
    <button mat-stroked-button type="button" class="action-btn" (click)="onDialogCloseClicked()">Cancel</button>

    <button
      mat-flat-button
      color="primary"
      type="button"
      class="action-btn"
      [disabled]="disabledBtn"
      (click)="formRef.onSubmit(); createProject()"
    >
      Create Project
    </button>
  </div>
</mat-dialog-actions>
