import * as Interfaces from './feature-flags.interface';
import { Injectable } from '@angular/core';

import { first, map } from 'rxjs/operators';
import { commonenv } from './../../environments/environment';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export type FeatureFlags = Record<string, boolean>;

const FEATURE_FLAGS_URL = `${commonenv.nextGenApiUrl}feature-flags`;
const ACCOUNT_FEATURE_FLAGS_URL = `${commonenv.nextGenApiUrl}feature-flags/account`;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlaggingService {
  private flags: FeatureFlags = {};

  private flagChange = new ReplaySubject<FeatureFlags>(1);
  flagChange$ = this.flagChange.asObservable();

  private accountFlagChange = new ReplaySubject<FeatureFlags>(1);
  accountFlagChange$ = this.accountFlagChange.asObservable();

  readonly accountFeatureFlagsLoadedSubject = new BehaviorSubject<boolean>(false);
  private accountFeatureFlagsLoaded = false;

  constructor(private readonly httpClient: HttpClient) {}

  getFlag(variation: string): boolean {
    return this.flags[variation];
  }

  isFeatureFlagEnabled(flag: string): Observable<boolean> {
    return this.flagChange$.pipe(map((flags) => !!flags[flag]));
  }

  setAccountFeatureFlags(flags: Interfaces.FeatureFlag[]): void {
    this.setFlags(flags, true);
    this.accountFeatureFlagsLoaded = true;
    this.accountFeatureFlagsLoadedSubject.next(true);
  }

  getGlobalFeatureFlags(): void {
    this.httpClient
      .get<Interfaces.FeatureFlag[]>(FEATURE_FLAGS_URL)
      .pipe(first())
      .subscribe((enabledFlags) => {
        if (this.accountFeatureFlagsLoaded) {
          return;
        }

        return this.setFlags(enabledFlags);
      });
  }

  resetFeatureFlags(): void {
    this.accountFeatureFlagsLoaded = false;
    this.accountFeatureFlagsLoadedSubject.next(false);
    this.getGlobalFeatureFlags();
  }

  checkAccountFeatureFlags(): void {
    this.httpClient
      .get<Interfaces.FeatureFlag[]>(ACCOUNT_FEATURE_FLAGS_URL)
      .pipe(first())
      .subscribe((enabledFlags) => {
        this.setAccountFeatureFlags(enabledFlags);
      });
  }

  private setFlags(flags: Interfaces.FeatureFlag[], isAccount = false): void {
    const flagsObj = flags.reduce((prev, { slug }) => {
      prev[slug] = true;
      return prev;
    }, {});

    this.flags = flagsObj;
    this.flagChange.next(this.flags);
    if (isAccount) this.accountFlagChange.next(this.flags);
  }
}
