import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { PermissionCheckFn } from "../../../acl/acl-permission.type";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { OpenreelLogoModule } from '@openreel/ui/openreel-logo/openreel-logo.module';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Comment } from "../../hosting-interfaces";
import { CommentListItemComponent, CommentTemplate } from "./comment-list-item/comment-list-item.component";
import { CommonModule } from "@angular/common";

@Component({
    selector: 'or-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        OpenreelLogoModule,
        MatProgressSpinnerModule,
        CommentListItemComponent,
    ]
})
export class CommentListComponent {
    @Input() commentTemplate: CommentTemplate = 'internal';
    @Input() comments: Comment[] = [];
    @Input() selectedComment: Comment | null = null;
    @Input() isAllCommentsLoaded: boolean = false;
    @Input() isLoadingComments: boolean = false;
    @Input() showAccessibility: boolean = false;
    @Output() deleteComment = new EventEmitter<Comment>();
    @Output() updateComment = new EventEmitter<Comment>();
    @Output() clickComment = new EventEmitter<Comment>();
    @Output() resolveComment = new EventEmitter<Comment>();
    @Output() clickUpdateComment = new EventEmitter<Comment>();
    @Output() loadMoreComments = new EventEmitter<void>();

    onLoadMoreComments() {
        this.loadMoreComments.emit();
    }
}