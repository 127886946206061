import { Injectable } from '@angular/core';
import { ISocketIoLibService } from '@openreel/frontend/common/interfaces';
import { BaseSocketService } from '../../services';

@Injectable({ providedIn: 'root' })
export class HostingSocketService extends BaseSocketService {
  private readonly namespace = '/hosting';

  constructor(readonly socketIoProvider: ISocketIoLibService) {
    super(socketIoProvider);
  }

  connect(url: string) {
    this.socket.connect(url, this.namespace);
  }

  basicSocketEmit<T>(event: string, value: T): void {
    this.emit(event, value);
  }

  emit<T>(event: string, data: T): void {
    this.socket.emit(event, data);
  }

  disconnect() {
    this.socket.disconnect();
  }
}
