import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { UsersListItemModule } from './users-list-item/users-list-item.module';
import { UsersListComponent } from './users-list.component';
import { GroupsListItemComponent } from './groups-list-item/groups-list-item.component';
import { LetDirective } from '@openreel/frontend/common/directives/let.directive';

@NgModule({
  declarations: [UsersListComponent],
  exports: [UsersListComponent],
  imports: [
    CommonModule,
    UsersListItemModule,
    GroupsListItemComponent,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    LetDirective,
  ],
})
export class UsersListModule {}
