import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatLegacyTooltip } from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[matTooltip][showIfTruncated]',
  standalone: true,
})
export class TooltipShowIfTruncatedDirective {

  readonly matTooltip = inject(MatTooltip, { optional: true });
  readonly legacyMatTooltip = inject(MatLegacyTooltip, { optional: true });
  readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

  @HostListener('mouseenter', ['$event'])
  setTooltipState(): void {
    const element = this.elementRef.nativeElement;
    const disabledTooltip = element.scrollWidth <= element.clientWidth;

    if (this.matTooltip) {
      this.matTooltip.disabled = disabledTooltip;

      return;
    }

    if (this.legacyMatTooltip) {
      this.legacyMatTooltip.disabled = disabledTooltip;
    }
  }
}
