import { Provider } from "@angular/core";
import { InternalCommentsManagerService } from "./internal-comments-manager.service";
import { DRAWABLE_SHAPES_CREATOR_MANAGER, DRAWABLE_SHAPES_VIEWER_MANAGER } from "../drawable-container/resources/drawable.constants";

export function provideInternalCommentsManager(): Provider[] {
    return [
        InternalCommentsManagerService,
        {provide: DRAWABLE_SHAPES_CREATOR_MANAGER, useExisting: InternalCommentsManagerService},
        {provide: DRAWABLE_SHAPES_VIEWER_MANAGER, useExisting: InternalCommentsManagerService},
    ]
}