<h2 mat-dialog-title *ngIf="data">Edit Video Hub</h2>
<h2 mat-dialog-title *ngIf="!data">New Video Hub</h2>

<form [formGroup]="hubForm" (submit)="createEditHub()">
    <mat-dialog-content>
        <!-- N A M E -->
        <div class="form-field-title">Video Hub Title <sup>*</sup></div>
        <mat-form-field class="form-field-input"  appearance="outline">
            <input matInput formControlName="name" required placeholder="Enter here">
            <mat-error *ngFor="let validation of validation_messages.name">
                <mat-error class="error-message" *ngIf="hubForm.get('name').hasError(validation.type) && (hubForm.get('name').dirty || hubForm.get('name').touched)">{{validation.message}}</mat-error>
            </mat-error>

        </mat-form-field>

        <ng-container *ngIf="data?.slug">
            <div class="form-field-title">
                Video Hub Slug <sup>*</sup>
                <mat-icon matTooltip="Your slug will be used in your content's public URL. It must be unique and if you modify the slug at a later date, previous URLs will be rendered inactive.">info_outline</mat-icon>
            </div>
            <mat-form-field class="form-field-input"  appearance="outline">
                <input matInput formControlName="slug" required placeholder="URL Slug" slugField>
                <mat-spinner matTooltip="Checking slug availability" diameter="15" matSuffix *ngIf="hubForm.get('slug').status === 'PENDING'"></mat-spinner>
                <mat-error *ngFor="let validation of validation_messages.slug">
                    <mat-error class="error-message" *ngIf="hubForm.get('slug').hasError(validation.type) && (hubForm.get('slug').dirty || hubForm.get('slug').touched)">{{validation.message}}</mat-error>
                </mat-error>
            </mat-form-field>
            <div class="slug-info">
                <p>
                  <span>{{hostingAppUrl}}hub/{{data.idHash}}-</span>
                  <span class="slug-data" *ngIf="hubForm.value">{{hubForm.value.slug}}</span>
                </p>
                <p class="slug-warning">
                  <strong>Warning:</strong> 
                  Any changes made to the slug will leave previous versions of the link inactive.
                </p>
              </div>
        </ng-container>

        <!-- P E R M I S S I O N S -->
        <ng-container *ngIf="!data">
            <ng-container *featureFlag="sharingFeature; else permissionSelect"></ng-container>
            <ng-template #permissionSelect>
                <div class="form-field-title">Permissions<sup>*</sup></div>
                <mat-form-field class="form-field-input"  appearance="outline">
                    <mat-select formControlName="permission" class="form-field-input" appearance="outline">
                        <mat-option value="private">Private</mat-option>
                        <mat-option value="public">Public</mat-option>
                    </mat-select>
                    <mat-error>
                        <span *ngIf="hubForm?.controls.permission?.errors?.required">Required Field</span>
                    </mat-error>
                </mat-form-field>
            </ng-template>
         </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button color="primary" type="submit" [disabled]="!hubForm.valid || hubForm.pristine">
            {{ data? 'Save Changes' : 'Create' }}
        </button>
        <button mat-stroked-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</form>

