import { BrandKitDto } from '@openreel/frontend/common';
import {
  ColorTag,
  BrandKitPermissions,
  FileAssetWithTag,
  Asset,
  FileList,
  FileAssetType,
  BrandKitSoundtrackSettings,
  BrandKitSoundtrack,
  ProjectFont,
  BrandKitCompany,
} from '@openreel/creator/common';
import { cloneDeep } from 'lodash-es';

export interface BrandKitFormSettings {
  id: number;
  name: string;
  companyInfo: BrandKitCompany;
  assets: BrandKitFormSettingsAssets;
  permissions: BrandKitFormSettingsPermissions;
}

export type BrandKitFormSettingsPermissions = BrandKitPermissions;
export type BrandKitFormSoundtrackSettings = BrandKitSoundtrackSettings;

export interface BrandKitFormSettingsAssets {
  font: ProjectFont;
  soundtrack: BrandKitSoundtrack;
  soundtrackSettings: BrandKitSoundtrackSettings;
  colors: ColorTag[];
  logo: FileList[];
  emailLogo: FileList[];
  watermark: FileList[];
  background: FileList[];
}

const DEFAULT_ASSETS: { [key: string]: FileAssetWithTag<Asset> } = {
  logo: {
    asset: {
      file: { path: null, provider: 'or-assets' },
      type: 'image',
    },
    tag: 'brand-kit-logo',
    type: 'logo',
  },
  'email-logo': {
    asset: {
      file: { path: null, provider: 'or-assets' },
      type: 'image',
    },
    tag: 'brand-kit-email-logo',
    type: 'email-logo',
  },
  watermark: {
    asset: {
      file: { path: null, provider: 'or-assets' },
      type: 'image',
    },
    tag: 'brand-kit-watermark',
    type: 'watermark',
  },
  'background-video': {
    asset: {
      file: { path: null, provider: 'or-assets' },
      type: 'clip',
    },
    tag: 'brand-kit-background-video',
    type: 'background-video',
  },
};

export class BrandKitFormSettingsModel implements BrandKitFormSettings {
  constructor(
    public id: number,
    public name: string,
    public companyInfo: BrandKitCompany,
    public assets: BrandKitFormSettingsAssets,
    public permissions: BrandKitFormSettingsPermissions
  ) {}

  static fromDTO(dto: BrandKitDto): BrandKitFormSettingsModel {
    const getAssetFiles = (assetType): FileList[] => {
      const bkAssets = dto.assets.assets.data.filter((asset) => asset.type === assetType && asset.asset.file.path);

      return bkAssets.map((item) => ({
        ...item.asset.file,
        isDefault: bkAssets.length === 1 ? true : item.isDefault,
        fileName: item.asset.fileName ?? '',
        type: item.asset.type,
      }));
    };

    const assets: BrandKitFormSettingsAssets = {
      font: { ...dto.assets.font },
      soundtrack: { ...dto.assets.soundtrack },
      soundtrackSettings: { ...dto.assets.soundtrackSettings },
      colors: dto.assets.colors.data.map((color) => ({
        name: color.asset.name,
        color: color.asset.value,
        tag: color.tag,
        palette: color.asset.palette,
      })),
      logo: getAssetFiles('logo'),
      emailLogo: getAssetFiles('email-logo'),
      watermark: getAssetFiles('watermark'),
      background: getAssetFiles('background-video'),
    };

    return new BrandKitFormSettingsModel(dto.id, dto.name, dto.companyInfo, assets, dto.permissions);
  }

  static toDTO(model: BrandKitFormSettings): BrandKitDto {
    const getAssets = (assetType: FileAssetType, newFiles: FileList | FileList[]) => {
      const files = Array.isArray(newFiles) ? newFiles : newFiles ? [newFiles] : [];
      return files.map((file) => {
        const pristineAsset = cloneDeep(DEFAULT_ASSETS[assetType]);

        pristineAsset.asset.type = file.type;

        pristineAsset.asset.file = {
          path: file.path,
          provider: file.provider,
        };
        pristineAsset.isDefault = file.isDefault;
        pristineAsset.asset.fileName = file.fileName;

        return pristineAsset;
      });
    };

    return {
      id: model.id,
      name: model.name,
      companyInfo: model.companyInfo,
      assets: {
        assets: {
          data: [
            ...getAssets('logo', model.assets.logo),
            ...getAssets('email-logo', model.assets.emailLogo),
            ...getAssets('watermark', model.assets.watermark),
            ...getAssets('background-video', model.assets.background),
          ],
        },
        font: {
          id: model.assets.font.id,
          source: model.assets.font.source,
        },
        soundtrack: { ...model.assets.soundtrack },
        soundtrackSettings: { ...model.assets.soundtrackSettings },
        colors: {
          data: model.assets.colors.map((asset) => ({
            asset: {
              type: 'color',
              name: asset.name,
              value: asset.color,
            },
            tag: asset.tag,
          })),
        },
      },
      permissions: model.permissions,
    };
  }
}
