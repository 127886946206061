import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, takeUntil, withLatestFrom } from 'rxjs';
import moment from 'moment';
import { AuthService, Cleanupable, FeatureFlaggingService, ROUTE_SETTINGS, commonenv } from '@openreel/frontend/common';
import { WORKFLOWS_ACCOUNT_BANNERS } from '@openreel/common';

const SHOW_RENEWAL_MESSAGE_WARNING_DAYS = 15;

@Injectable({ providedIn: 'root' })
export class WarningBarService extends Cleanupable {
  public readonly STORAGE_LIMIT_REACHED_ERROR_MESSAGE = 'Storage limit has been reached';

  private animateStorageBar = new BehaviorSubject<boolean>(false);
  animateStorageBar$ = this.animateStorageBar.asObservable();

  private showRenewalBanner = new BehaviorSubject<boolean>(false);
  showRenewalBanner$ = this.showRenewalBanner.asObservable();
  private showStorageBanner = new BehaviorSubject<boolean>(false);
  showStorageBanner$ = this.showStorageBanner.asObservable();

  private showUpgradeButton = new BehaviorSubject<boolean>(false);
  showUpgradeButton$ = this.showUpgradeButton.asObservable();

  private renewalMessage = new BehaviorSubject<string>('');
  renewalMessage$ = this.renewalMessage.asObservable();
  private storageMessage = new BehaviorSubject<string>('');
  storageMessage$ = this.storageMessage.asObservable();

  constructor(private readonly authService: AuthService, private readonly featureFlagService: FeatureFlaggingService) {
    super();

    this.init();
  }

  private init() {
    this.authService.userDetails$
      .pipe(
        filter((userDetails) => !!userDetails),
        withLatestFrom(this.featureFlagService.accountFlagChange$),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([userDetails, flags]) => {
        const showBanners = !!flags[WORKFLOWS_ACCOUNT_BANNERS];

        if (!showBanners) {
          this.showRenewalBanner.next(false);
          this.showStorageBanner.next(false);
          return;
        }

        const isRootAdmin = this.authService.getUserDetails().data.is_root_admin;

        this.showUpgradeButton.next(isRootAdmin);

        const creatorExpireDate = userDetails.site_users.creatorExpireAt;
        if (creatorExpireDate !== null) {
          const differenceInDays = Math.ceil(moment(creatorExpireDate).diff(moment(), 'days', true));
          const isAccountExpired = new Date(creatorExpireDate) < new Date();
          const isAccountExpiring = differenceInDays < SHOW_RENEWAL_MESSAGE_WARNING_DAYS;
          if (isAccountExpired) {
            this.showRenewalBanner.next(true);
            if (isRootAdmin) {
              this.renewalMessage.next(
                'Your account is currently a free account. Upgrade to get all the features of Pro.'
              );
            } else {
              this.renewalMessage.next('This is a free account; ask your admin to upgrade to Pro today!');
            }
          } else if (isAccountExpiring) {
            this.showRenewalBanner.next(true);
            this.renewalMessage.next(
              `Your account will expire in ${differenceInDays} days and will become a free account on ${new Date(
                creatorExpireDate
              ).toDateString()}.`
            );
          }
        }

        this.showStorageBanner.next(userDetails.isCreatorStorageLimitReached);
        if (isRootAdmin) {
          this.storageMessage.next(
            'Looks like your team has reached the storage limit. Upgrade today to keep creating!'
          );
        } else {
          this.storageMessage.next(
            "Looks like your team has reached the storage limit. Your administrator will need to upgrade your team's account."
          );
        }
      });
  }

  setStorageBannerVisibility(visibility: boolean) {
    this.showStorageBanner.next(visibility);
    if (visibility) {
      this.animateStorageBar.next(true);
    }
  }

  upgradeRedirect() {
    window.open(`${commonenv.nextGenAppUrl}${ROUTE_SETTINGS}?tab=seats`, '_self');
  }
}
