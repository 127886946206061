import { DrawableAreaData, DrawableShapeAttributes, DrawableShapeColor, DrawableShapeType } from "@openreel/common";
import { Observable } from "rxjs";

export interface DrawableShapesCreatorManager {
  readonly isActiveDrawMode$: Observable<boolean>;
  readonly drawableShapeType$: Observable<DrawableShapeType>;
  readonly drawableShapeColor$: Observable<DrawableShapeColor>;
  readonly drawResult$: Observable<DrawableAreaData | null>;
  changeDrawableShapeColor(color: DrawableShapeColor): void;
  changeDrawableShapeType(type: DrawableShapeType): void;
  changeDrawModeActiveState(isActive: boolean): void;
  completeDraw(data: DrawableAreaData): void;
  clearDrawResult(): void;
}

export interface DrawableShapesViewerManager {
  drawableDataToView$: Observable<DrawableAreaData>;
}

export enum DrawEventType {
  StartDrawing = 'start_drawing',
  MovePointer = 'move_pointer',
  CompleteDrawing = 'complete_drawing',
}

export interface Size {
  width: number;
  height: number;
}

export interface Point2DCoordinates {
  readonly x: number;
  readonly y: number;
}

export interface DrawEventData {
  readonly type: DrawEventType;
  readonly position: Point2DCoordinates | null;
}

export interface DrawableShape<T> {
    readonly type: DrawableShapeType;
    readonly attributes: T;
    setColor(color: string): void;
    setStartPoint(point: Point2DCoordinates): void;
    setEndPoint(point: Point2DCoordinates): void;
}

export interface DrawableArea {
    readonly shapes: DrawableShape<DrawableShapeAttributes>[];
    readonly draw$: Observable<DrawEventData>;
    toJSON(): DrawableAreaData;
    setSize(width: number, height: number): void;
    clearShapes(): void;
    destroy(): void;
    drawFromJSON(json: DrawableAreaData): void;
    drawShape(shapeType: DrawableShapeType, attributes?: DrawableShapeAttributes): DrawableShape<DrawableShapeAttributes>;
}