import {
  ChangeTemplate,
  CreateWorkflowProjectRequest,
  UpdateWorkflowProjectRequest,
  WorkflowProjectChangeTemplateDto,
  WorkflowProjectCreatedDto,
  WorkflowProjectDto,
  WorkflowProjectPreviewDto,
  WorkflowProjectSimpleDto,
  WorkflowProjectVideoDto,
  WorkflowRenderToLibraryParams,
  WorkflowsProjectsListEntity,
} from './workflow-project.interfaces';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AssetDto, CaseConverterInterceptorSkipHeader, CreateAssetRequest } from '../..';

@Injectable({
  providedIn: 'root',
})
export class WorkflowProjectService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/projects`,
    create: `${commonenv.nextGenApiUrl}workflows/projects/create`,
    createInstant: `${commonenv.nextGenApiUrl}workflows/projects/create-instant`,
    byId: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}`,
    byIdForPreview: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/preview`,
    changeTemplate: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/change-template`,
    skipChangeTemplate: (projectId: number) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/skip-change-template`,
    byIdName: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/name`,
    byIdRenderAndAddToLibrary: (projectId: number) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/render-to-library`,
    byIdCancelRender: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/render/cancel`,
    byIdTranscribe: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/transcribe`,
    signedUrl: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/signed-url`,
    duplicate: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/copy`,
    createAsset: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/assets`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getCaptureProjectWorkflows(captureProjectId: number, orderField?: 'created_at' | 'updated_at') {
    let params = new HttpParams().set('captureProjectId', captureProjectId);
    if (orderField) {
      params = params.set('orderField', orderField).set('orderDir', 'desc');
    }
    return this.httpClient.get<WorkflowsProjectsListEntity[]>(this.urls.root, { params });
  }

  getProjectWorkflowsByIds(projectIds: number[], orderField?: 'created_at' | 'updated_at') {
    let params = new HttpParams().set('projectIds', projectIds.join(','));
    if (orderField) {
      params = params.set('orderField', orderField).set('orderDir', 'desc');
    }
    return this.httpClient.get<WorkflowsProjectsListEntity[]>(this.urls.root, { params });
  }

  getCaptureProjectArchivedWorkflows(captureProjectId: number) {
    const params = new HttpParams().set('captureProjectId', captureProjectId.toString()).set('archived', '1');
    return this.httpClient.get<WorkflowsProjectsListEntity[]>(this.urls.root, { params });
  }

  getProjectById(projectId: number) {
    return this.httpClient.get<WorkflowProjectDto>(this.urls.byId(projectId));
  }

  getProjectByIdForPreview(projectId: number) {
    return this.httpClient.get<WorkflowProjectPreviewDto>(this.urls.byIdForPreview(projectId));
  }

  create(data: CreateWorkflowProjectRequest) {
    return this.httpClient.post<WorkflowProjectCreatedDto>(this.urls.create, data);
  }

  createInstant(captureProjectId?: number) {
    return this.httpClient.post<WorkflowProjectCreatedDto>(this.urls.createInstant, { captureProjectId });
  }

  updateProjectById(data: UpdateWorkflowProjectRequest) {
    return this.httpClient.put<WorkflowProjectSimpleDto>(this.urls.byId(data.id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  updateProjectNameById(projectId: number, newName: string) {
    return this.httpClient.put<WorkflowProjectSimpleDto>(this.urls.byIdName(projectId), {
      name: newName,
    });
  }

  renderProjectAndAddToLibrary(projectId: number, params: WorkflowRenderToLibraryParams) {
    return this.httpClient.post<WorkflowProjectVideoDto>(this.urls.byIdRenderAndAddToLibrary(projectId), params);
  }

  cancelProjectRender(projectId: number) {
    return this.httpClient.post<void>(this.urls.byIdCancelRender(projectId), {});
  }

  transcribe(projectId: number) {
    return this.httpClient.post<{ done: boolean }>(this.urls.byIdTranscribe(projectId), {});
  }

  public updateClipsListSubject = new Subject();

  getSignedUrl(projectId: number, forceDownload: boolean) {
    const params = new HttpParams().set('forceDownload', forceDownload);

    return this.httpClient.get<{ signedUrl: string }>(this.urls.signedUrl(projectId), {
      params,
    });
  }

  delete(id: number, confirm: boolean = false, deleteDestinations: boolean = false) {
    const params = new HttpParams()
      .set('confirm', confirm.toString())
      .set('deleteDestinations', deleteDestinations.toString());
    return this.httpClient.delete(this.urls.byId(id), { params }).pipe(tap(() => this.updateClipsListSubject.next({})));
  }

  restore(id: number) {
    return this.httpClient.patch(this.urls.byId(id), { restore: true });
  }

  changeTemplate(creatorProjectId: number, data: ChangeTemplate) {
    return this.httpClient.post<WorkflowProjectChangeTemplateDto>(this.urls.changeTemplate(creatorProjectId), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  skipChangeTemplate(creatorProjectId: number) {
    return this.httpClient.post(this.urls.skipChangeTemplate(creatorProjectId), null);
  }

  duplicate(id: number, createNewCaptureProject: boolean) {
    return this.httpClient
      .post<WorkflowProjectSimpleDto>(
        this.urls.duplicate(id),
        { createNewCaptureProject },
        {
          headers: new HttpHeaders({
            [CaseConverterInterceptorSkipHeader]: '',
          }),
        }
      )
      .pipe(tap(() => this.updateClipsListSubject.next({})));
  }

  createAsset(projectId: number, data: CreateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.createAsset(projectId), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }
}
