import { Injectable } from '@angular/core';
import { environment } from 'libs/ui/src/environments/environment';

declare global {
  interface Window {
    CREATOR_DEBUG: boolean;
  }
}

@Injectable({ providedIn: 'root' })
export class DebugService {
  get isCreatorDebugging() {
    return !environment.production && window.CREATOR_DEBUG;
  }
}
