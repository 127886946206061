import { z } from 'zod';
import {
  GradientColorSchema,
  LayerBoundsSchema,
  LayerStylesSchema,
  SimpleBoundsWithAngleSchema,
  SolidColorSchema,
  TimelineSchema,
} from './common.zod';

const SectionTimelineControlsSchema = z.object({
  switch: z.optional(z.boolean()),
});

const SectionTimelineSchema = TimelineSchema.extend({
  id: z.string(),
  zIndex: z.number(),
  title: z.string(),
  hasAudio: z.boolean(),
  controls: z.optional(SectionTimelineControlsSchema),
  bounds: z.optional(LayerBoundsSchema),
  styles: z.optional(LayerStylesSchema),
  pairId: z.optional(z.string().nullable()),
  contentType: z.optional(z.enum(['camera', 'screen']).nullable()),
});

const SectionGroupSchema = z.object({
  layerIds: z.array(z.string()),
  bounds: SimpleBoundsWithAngleSchema,
});

const SectionLayoutSchema = z.object({
  templateLayoutId: z.number(),
  layoutType: z.enum(['simple', 'interview', 'presentation', 'title', 'other', 'ai']),
});

const SectionAudioPropertiesSchema = z.object({
  muted: z.boolean(),
  volume: z.number(),
});

export const SectionSchema = z.object({
  sectionType: z.enum(['intro', 'main', 'outro']),
  sectionDuration: z.number(),
  timelines: z.array(SectionTimelineSchema),
  groups: z.array(SectionGroupSchema),
  layout: z.optional(SectionLayoutSchema).nullable(),
  backgroundColor: z.union([SolidColorSchema, GradientColorSchema]),
  audio: SectionAudioPropertiesSchema,
});

export const SectionsSchema = z.record(z.string(), SectionSchema);
