import { inject, Injectable, InjectionToken } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BrandForm } from './branding-form.interface';

export const BRANDFETCH_BRANDING_FORM = new InjectionToken<FormGroup<BrandForm>>('Brandfetch branding form');

@Injectable()
export class ApplyBrandingFormService {

  private readonly fb = inject(FormBuilder);

  private readonly _form: FormGroup;

  get form(): FormGroup {
    return this._form;
  }

  constructor() {
    this._form = this.createForm();
  }

  createForm(): FormGroup<BrandForm> {
    return this.fb.group({
      domain: this.fb.control(null),
      color: this.fb.control(null),
      logo: this.fb.control(null),
      watermark: this.fb.control(null),
      font: this.fb.control(null),
    });
  }
}
