import { Directive, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { HostingAnalyticsService } from '../hosting-analytics.service';
import { HostingAnalyticsEvents } from '../hosting-analytics-events';

@Directive({
  selector: '[captureUrlChange]',
  standalone: true,
})
export class UrlChangeEventCaptureDirective implements OnDestroy {
  private _router = inject(Router);
  private _hostingAnalyticsService = inject(HostingAnalyticsService);

  private _routeSubs = this._router.events
    .pipe(
      filter((routeEvent) => routeEvent instanceof NavigationEnd),
      map((routeEvent: NavigationEnd) => routeEvent.url),
      startWith(this._router.url),
    )
    .subscribe(url => {
        this._hostingAnalyticsService.logEvent(HostingAnalyticsEvents.pageVisited({ url }));
    });


  ngOnDestroy(): void {
    this._routeSubs.unsubscribe();
  }
}
