export interface AssetTranscript {
  words: AssetTranscriptWord[];
}

export type AssetTranscriptWordType = 'word' | 'mark' | 'newline';
export type AssetTranscriptTokenType = 'timeline-cut' | 'silence';
export type BaseAssetTranscriptTermType = AssetTranscriptWordType | AssetTranscriptTokenType;
export type AssetTranscriptTermType = BaseAssetTranscriptTermType | 'text-cut';

export const NON_NEWLINE_WORD_TYPES: AssetTranscriptTermType[] = [
  'word',
  'mark',
  'timeline-cut',
  'silence',
  'text-cut',
];
export const NON_MARK_WORD_TYPES: AssetTranscriptTermType[] = [
  'word',
  'newline',
  'timeline-cut',
  'silence',
  'text-cut',
];
export const WORD_AND_MARK_WORD_TYPES: AssetTranscriptTermType[] = ['word', 'mark'];
export const CURSORABLE_WORD_TYPES: AssetTranscriptTermType[] = ['word', 'mark', 'silence'];
export const TOKEN_WORD_TYPES: AssetTranscriptTermType[] = ['timeline-cut', 'silence', 'text-cut'];
export const TRIM_TOKEN_WORD_TYPES: AssetTranscriptTermType[] = ['timeline-cut', 'text-cut'];
export const WORD_MARK_AND_TOKEN_WORD_TYPES: AssetTranscriptTermType[] = [
  ...WORD_AND_MARK_WORD_TYPES,
  ...TOKEN_WORD_TYPES,
];

export interface AssetTranscriptWord {
  type: BaseAssetTranscriptTermType;
  text: string;
  start: number;
  end: number;
  confidence: number;
  speakerId: string;
}

export interface AssetTranscriptBaseWord extends AssetTranscriptWord {
  assetId: string;
  sectionId: string;
  originalIndex: number;
  originalStart: number;
  originalEnd: number;
}

export interface AssetTranscriptTextCutToken extends Omit<AssetTranscriptBaseWord, 'type'> {
  type: 'text-cut';
  hasText: boolean;
}

export type AssetTranscriptTrimmerWord = AssetTranscriptBaseWord | AssetTranscriptTextCutToken;

export interface AssetTranscriptSpeaker {
  uid: string;
  name: string;
  color: string;
}
