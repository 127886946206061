import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { PublicVideo } from 'libs/frontend/common/src/interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PublicVideoApiService {
  constructor(private httpClient: HttpClient) {}

  async getPublicVideoId(publicVideoId: string): Promise<PublicVideo> {
    return (
      await this.httpClient
        .get<{ data: PublicVideo }>(`${commonenv.nextGenApiUrl}videos/public/${publicVideoId}`)
        .toPromise()
    ).data;
  }
}
