import { SessionType } from './../constants/sessions.constants';

export enum SearchResultType {
  project = 'project',
  automation = 'automation',
  session = 'session',
  video = 'video',
  hostable = 'library',
  hub = 'hub',
}

interface CommonSearchResultType {
  id: number;
  name: string;
  image?: string;
  date: Date;
  url: SessionUrlType | ProjectUrlType | VideoUrlType | LibraryUrlType | HubUrlType;
  duration?: number;
}

interface CommonUrlType {
  id: number;
}

export interface SessionSearchResult extends CommonSearchResultType {
  type: SearchResultType.session;
  hasAccess: boolean;
  projectId: number;
}

export interface SessionUrlType extends CommonUrlType {
  type: SearchResultType.session;
  sessionType: SessionType;
  code: string;
}

export interface ProjectSearchResult extends CommonSearchResultType {
  type: SearchResultType.project | SearchResultType.automation;
}

export interface ProjectUrlType extends CommonUrlType {
  type: SearchResultType.project | SearchResultType.automation;
}

export interface VideoSearchResult extends CommonSearchResultType {
  type: SearchResultType.video;
}

export interface VideoUrlType extends CommonUrlType {
  type: SearchResultType.video;
}

export interface LibrarySearchResult extends CommonSearchResultType {
  type: SearchResultType.hostable;
}

export interface LibraryUrlType extends CommonUrlType {
  type: SearchResultType.hostable;
}

export interface HubSearchResult extends CommonSearchResultType {
  type: SearchResultType.hub;
}

export interface HubUrlType extends CommonUrlType {
  type: SearchResultType.hub;
}

export type SearchResult =
  | SessionSearchResult
  | ProjectSearchResult
  | VideoSearchResult
  | LibrarySearchResult
  | HubSearchResult;

export interface SearchedKeyword {
  label: string;
  value: string;
  type: 'text' | 'person';
  img?: string;
}
export interface SearchPageVideoResult {
  title: string;
  createdAt: Date;
  duration: number;
  thumbnail: string;
  id: number;
  type: string;
  transcript?: string;
  srcUrl?: string;
  projectId?: number;
}

export const VideoTypeLabel = {
  pro: 'CAPTURE PRO',
  ugc: 'VIDEO REQUEST',
  self_record: 'SELF RECORD',
  lite: 'CAPTURE LITE',
  library: 'LIBRARY',
};


export enum SearchAiProcessingStatus {
  QueueSent = 'QUEUE_SENT',
  OngoingProcessing = 'ONGOING_PROCESSING',
  Completed = 'COMPLETED'
}