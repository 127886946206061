import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const svgIconNames = [
  '3stars',
  '3stars-grad',
  'alert-square',
  'automations-main',
  'automations-categories',
  'automations-connections',
  'advanced',
  'apple',
  'aspect',
  'ban',
  'bell-notification',
  'bell',
  'bold',
  'both-pip',
  'exit',
  'branding',
  'brightness-filter',
  'bulk-check-white',
  'bulk-check',
  'bulk-checkfilled',
  'calendar-alt',
  'calendar',
  'camera-fill-active',
  'camera-fill',
  'camera-video',
  'camera',
  'cancel-process',
  'capitalize',
  'captions',
  'capture-card',
  'caret',
  'cc-bold',
  'cc',
  'chair',
  'chat-Copy',
  'chat',
  'check-circle',
  'check-round',
  'check',
  'check-alt',
  'check-thin',
  'checklist',
  'checkmark-empty',
  'checkmark-filled',
  'chek-round',
  'chek',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clipboard',
  'clock',
  'close-round',
  'close',
  'cloud-upload-filled',
  'cloud-upload',
  'cloud',
  'cog-bold',
  'cog-outline',
  'color-palette',
  'color-picker',
  'colour-filter',
  'comments',
  'computer',
  'contain',
  'control',
  'copy',
  'corner-down-right',
  'creator-card',
  'cube',
  'curve-easing',
  'curve-linear',
  'dashboard',
  'dataflow',
  'design-and-styling',
  'dest_dropbox_dark_mode_default',
  'devices',
  'diamond',
  'down-arrow-box',
  'down-arrow-alt',
  'down-arrow',
  'download-cloud',
  'download-round',
  'download-square',
  'download',
  'dot',
  'dots-vertical',
  'dots-drag',
  'drag',
  'drop',
  'dynamic',
  'edit-alt',
  'edit',
  'emoji',
  'energy',
  'enlarge',
  'export',
  'exposure',
  'eye-dropper',
  'eye-off',
  'eye',
  'fade-in',
  'fade-out',
  'files',
  'fill',
  'film',
  'filter',
  'finger',
  'fingerprint',
  'fingerprint2',
  'flag',
  'flip',
  'focus-filter',
  'focus',
  'folder',
  'folder-01',
  'folder-plus',
  'font-family',
  'fork',
  'fps-60',
  'frame-rate',
  'framerate-filter',
  'full-down-arrow',
  'full-left-arrow',
  'full-right-arrow',
  'full-up-arrow',
  'fullscreen',
  'fullscreen-on-arrows',
  'fullscreen-off-arrows',
  'g-sso',
  'gain-control',
  'gear',
  'globe',
  'grading',
  'grid-alt',
  'grid',
  'headphone',
  'heart-fill',
  'heart-outline',
  'hidden',
  'home',
  'hosting-card',
  'hosting-private',
  'hosting-public',
  'icon-analytics',
  'icon-dashboard',
  'icon-map',
  'image',
  'infinity',
  'info-alt',
  'info-lite',
  'info',
  'information',
  'intro-outro',
  'italic',
  'laptop-green',
  'laptop-grey',
  'layout',
  'left-arrow-alt-2',
  'left-arrow-alt',
  'left-arrow',
  'library',
  'lightbulb',
  'line',
  'link',
  'list',
  'lock',
  'logout',
  'mail',
  'marker',
  'menu-layout',
  'menu-logo',
  'menu-watermark',
  'menu',
  'message',
  'mic-green',
  'mic-grey',
  'mic',
  'minus-alt',
  'minus',
  'mountain',
  'move-timeline-back',
  'move-timeline-forward',
  'music-copy',
  'music-off',
  'music',
  'music-alt',
  'mute',
  'no-signal',
  'notebook',
  'options',
  'p-1080',
  'p-720',
  'pause-outline',
  'pause',
  'person-outline',
  'person',
  'phone-app',
  'phone',
  'photo-camera',
  'photo-camera',
  'photo',
  'pin',
  'pin',
  'pip',
  'play-circle',
  'play-circle-01',
  'play-icon',
  'play-outline',
  'play',
  'plus-alt',
  'plus',
  'plus-square',
  'preset-filter',
  'preset',
  'pro-audio',
  'projects',
  'question-filled',
  'question',
  'questionmark',
  'record-start-16',
  'record-start',
  'record-stop-16',
  'record-stop',
  'record-stop2',
  'record',
  'record2',
  'redo',
  'refresh-alt',
  'refresh',
  'remove',
  'replace',
  'reset',
  'resolution-filter',
  'right-arrow-alt-2',
  'right-arrow-alt',
  'right-arrow',
  'save',
  'scene-layout',
  'scissors',
  'screen-black',
  'screen-fill-active',
  'screen-fill',
  'screen-grey',
  'screen',
  'script',
  'search',
  'send',
  'send-02',
  'settings',
  'share-green',
  'sliders',
  'speaker',
  'person-box',
  'package-plus',
  'share-grey',
  'share',
  'share-alt',
  'shield',
  'shutter',
  'shuffle',
  'signal',
  'silence',
  'sound-off',
  'sound-on',
  'soundtrack',
  'spacing',
  'speaker',
  'split-screen',
  'split',
  'star-alt',
  'star',
  'stars',
  'stop-process',
  'stop-record-circle',
  'stop-record-square',
  'sun',
  'support',
  'swap-horizontal',
  'teleprompter-not-visible',
  'teleprompter-pause',
  'teleprompter-play',
  'teleprompter-preview',
  'teleprompter-reset',
  'teleprompter-stop',
  'teleprompter-visible',
  'new-text',
  'tag',
  'templates',
  'text-align-center',
  'text-align-left',
  'text-align-right',
  'text-centre',
  'text-left',
  'text-right',
  'text',
  'text-editing',
  'thumbnail-details',
  'thumbnail-play',
  'tick-circle',
  'transitions',
  'transition_cross_dissolve',
  'transition_intro_outro',
  'transition_fade',
  'transition_push',
  'trash',
  'trash-alt',
  'trash-light',
  'trim-timeline',
  'trim-text',
  'trim',
  'undo',
  'unlock',
  'up-arrow-alt',
  'up-dpwn-arrow',
  'upload-file',
  'upload',
  'upload2',
  'upload-03',
  'user-black',
  'user-edit',
  'user-frame',
  'user-grey',
  'user-icon-alt',
  'user-target',
  'user-speaker',
  'user',
  'users',
  'users-01',
  'user-square',
  'users-plus',
  'video-camera',
  'video-captions',
  'video-edit',
  'video-elements',
  'video-overlay',
  'video-soundtrack',
  'video-timeline',
  'videocam-off',
  'visable',
  'warning',
  'web',
  'webinar-card',
  'webinar',
  'wf-advanced',
  'white-balance-filter',
  'white-balance',
  'workflow',
  'activity',
  'bar-line-chart',
  'zoom-to-fit',
];

@NgModule({
  exports: [MatIconModule],
})
export class MaterialIconModule {
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {
    for (const svgIconName of svgIconNames) {
      const path = `assets/ui/material-icons/${svgIconName}.svg`;
      this.matIconRegistry.addSvgIcon(svgIconName, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    }
  }
}
