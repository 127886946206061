import { OverlayPositionOption } from './overlay-position.type';

export const OVERLAY_POSITIONS: OverlayPositionOption[] = [
  {
    name: 'Top Left',
    value: 'top-left',
  },
  {
    name: 'Top',
    value: 'top-center',
  },
  {
    name: 'Top Right',
    value: 'top-right',
  },
  {
    name: 'Left',
    value: 'center-left',
  },
  {
    name: 'Center',
    value: 'center',
  },
  {
    name: 'Right',
    value: 'center-right',
  },
  {
    name: 'Bottom Left',
    value: 'bottom-left',
  },
  {
    name: 'Bottom',
    value: 'bottom-center',
  },
  {
    name: 'Bottom Right',
    value: 'bottom-right',
  },
]
