import { LoadedFont } from '@openreel/common';
import { TextBoxMeasurer } from './measurers.interfaces';
import { TextBoxMeasurementsProps } from './text-box.interfaces';

export function measureTextBox(
  text: string,
  properties: TextBoxMeasurementsProps,
  measurer: TextBoxMeasurer,
  fonts: LoadedFont[]
) {
  const { referenceHeight, referenceWidth, style } = properties;
  const fontSize = (style.fontSizeRelative / 100) * Math.min(referenceHeight, referenceWidth);
  let maxWidth = referenceWidth;

  if (properties.bounds.isTextBoxFixedWidth) {
    maxWidth = (properties.bounds.width / 100) * referenceWidth;
  } else {
    const availableWidthToTheLeft =
      referenceWidth - (referenceWidth - ((properties.bounds.x + properties.bounds.width) / 100) * referenceWidth);
    const availableWidthToTheRight = referenceWidth - (properties.bounds.x / 100) * referenceWidth;
    const availableWidthCentered = Math.min(availableWidthToTheLeft, availableWidthToTheRight);

    if (style?.textAlign === 'left') {
      if (availableWidthToTheRight <= referenceWidth + fontSize) {
        maxWidth = availableWidthToTheRight;
      }
    } else if (style?.textAlign === 'center') {
      if (availableWidthCentered <= referenceWidth + fontSize) {
        maxWidth = availableWidthCentered;
      }
    } else if (style?.textAlign === 'right') {
      if (availableWidthToTheLeft <= referenceWidth + fontSize) {
        maxWidth = availableWidthToTheLeft;
      }
    }
  }

  const { width: elementWidth, height: elementHeight } = measurer.measureTextBox(
    {
      font: properties.style.font,
      fontSize,
      lineHeight: properties.style.lineHeight,
      maxWidth,
      text,
    },
    fonts
  );

  let boundsX = properties.bounds.x;

  const boundsWidth = properties.bounds.isTextBoxFixedWidth
    ? properties.bounds.width
    : (Math.max(elementWidth, fontSize) / referenceWidth) * 100;
  const widthDelta = boundsWidth - properties.bounds.width;

  if (style?.textAlign === 'center') {
    boundsX = boundsX - widthDelta / 2;
  } else if (style?.textAlign === 'right') {
    boundsX = boundsX - widthDelta;
  }

  const isAtMaxWidth = Math.round(elementWidth) >= Math.round(maxWidth);

  const bounds = {
    ...properties.bounds,
    x: boundsX,
    height: (Math.max(elementHeight, fontSize) / referenceHeight) * 100,
    width: boundsWidth,
    isTextBoxFixedWidth: properties.bounds.isTextBoxFixedWidth || isAtMaxWidth,
  };

  return {
    bounds,
  };
}
