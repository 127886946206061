import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Cleanupable, commonenv } from '@openreel/frontend/common';
import { OWNERSHIP_SHARING } from '@openreel/common';
import { HostingSlugValidatorService } from '../../services';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'openreel-create-edit-hub',
  templateUrl: './create-edit-hub.component.html',
  styleUrls: ['./create-edit-hub.component.scss'],
  providers: [HostingSlugValidatorService],
})
export class CreateEditHubComponent extends Cleanupable implements OnInit {
  isLightThemed = true;
  isPopup = true;
  sharingFeature = OWNERSHIP_SHARING;

  hubForm: UntypedFormGroup;
  hostingAppUrl = commonenv.hostingAppUrl;

  validation_messages = {
    name: [
      { type: 'required', message: 'Name is required' },
      { type: 'maxlength', message: 'Hub Name cannot be more than 50 characters long' },
      { type: 'pattern', message: 'Hub name must contain numbers and letters' },
    ],
    slug: [
      { type: 'required', message: 'Slug is required' },
      { type: 'maxlength', message: 'Slug cannot be more than 100 characters long' },
      { type: 'slugExist', message: 'Slug already in use' },
    ],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; name: string; slug: string; idHash: string },
    public dialogRef: MatDialogRef<CreateEditHubComponent>,
    private hostingSlugValidatorService: HostingSlugValidatorService
  ) {
    super();
    this.hubForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.data?.name,
        Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(/[\S]/)])
      ),
      slug: new FormControl(this.data?.slug, {
        validators: [Validators.maxLength(100), Validators.required],
        asyncValidators: [this.hostingSlugValidatorService.hubSlugValidator(this.data?.id)],
      }),
      permission: new UntypedFormControl('private', Validators.required),
    });
  }

  ngOnInit() {
    this.hubForm
      .get('name')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((title) => {
        if (this.hubForm.get('slug').pristine) {
          this.hubForm.get('slug').setValue(title.trim());
        }
      });
  }

  createEditHub() {
    if (!this.hubForm.valid) return;
    const data = {
      hub: this.hubForm.value.name.trim(),
      permission: this.hubForm.value.permission.trim(),
      slug: this.hubForm.value.slug,
    };
    this.dialogRef.close(data);
  }
}
