<div class="hosting-permission-selector" 
    [class.private]="isPrivate" 
    [class.public]="isPublic">
    <mat-select [formControl]="permissionCtrl">
        <mat-option *ngFor="let perm of permissionOpts" [value]="perm.value">{{perm.display}}</mat-option>
    </mat-select>
</div>
<span class="lock-icon" *ngIf="passwordProtected">
    <mat-icon title="Password Protected">key</mat-icon>
</span>
