import { z } from 'zod';
import { LayerBoundsSchema, TimingFunctionSchema } from './common.zod';
import { cloneDeep } from 'lodash';

export const AssetColorTagSchema = z.object({
  name: z.string(),
  tag: z.string(),
  color: z.string(),
});

const AssetTypeSchema = z.enum(['json', 'image', 'clip', 'audio']);

const AssetSource = z.enum(['webcam', 'screen']);

const AssetMetadataSchema = z.object({
  name: z.optional(z.string()),
  duration: z.optional(z.number()),
  source: z.optional(AssetSource),
  defaultInlineEditBounds: z.optional(LayerBoundsSchema),
});

const AssetPresetSchema = z.object({
  type: z.enum(['text', 'image', 'logo', 'shape', 'video', 'fill']),
  showOnUi: z.optional(z.boolean()),
  lottiePath: z.optional(z.string()),
  label: z.optional(z.string()),
  order: z.optional(z.number()),
  assetId: z.optional(z.string()),
  colorTag: z.optional(z.string()),
  validationRules: z.optional(
    z.object({
      required: z.optional(z.boolean()),
      minlength: z.optional(z.number()),
      maxlength: z.optional(z.number()),
    })
  ),
  // Text properties
  defaultValue: z.optional(z.string()),
  // Shape properties
  colorShade: z.optional(z.number()),
  // Image properties
  lottieAssetRefId: z.optional(z.string()),
});

const AssetPresetsSchema = z.record(z.string(), AssetPresetSchema);

const UrlFileSchema = z.object({
  provider: z.literal('url'),
  path: z.string(),
});

const AssetFileSchema = z.object({
  path: z.union([z.string(), z.number()]),
  provider: z.enum(['or-assets', 'or-recordings', 'or-local']),
});

const FileSchema = z.union([UrlFileSchema, AssetFileSchema]);

const AssetTrimRangeSchema = z.object({
  from: z.number(),
  to: z.number(),
});

const AssetCutRangeSchema = z.object({
  from: z.number(),
  to: z.number(),
  type: z.enum(['manual', 'auto-silence']),
});

const AssetVideoZoomPanRangeSchema = z
  .object({
    amount: z.number().gte(100),
    startTransition: z.object({
      startAt: z.number().gte(0),
      duration: z.number().gt(0),
      timingFunction: TimingFunctionSchema,
    }),
    endTransition: z.object({
      startAt: z.number().gt(0),
      duration: z.number().gt(0),
      timingFunction: TimingFunctionSchema,
    }),
    point: z.object({
      x: z.number().gte(0).lte(100),
      y: z.number().gte(0).lte(100),
    }),
  })
  .refine((value) => value.startTransition.startAt + value.startTransition.duration < value.endTransition.startAt, {
    message: 'start transition must end before the end transition has started',
  });

export const AssetSchema = z
  .object({
    id: z.string(),
    name: z.optional(z.string()),
    type: AssetTypeSchema,
    file: z.optional(FileSchema),
    fileName: z.optional(z.string()),
    data: z.optional(AssetMetadataSchema),
    colorTags: z.optional(z.array(AssetColorTagSchema)),
    preset: z.optional(AssetPresetsSchema),
    isGlobal: z.optional(z.boolean()),
    isPlaceholder: z.optional(z.boolean()),
    tags: z.optional(z.array(z.string())),
    textCuts: z.optional(z.array(AssetCutRangeSchema)),
    trim: z.optional(AssetTrimRangeSchema),
    zoomPans: z.optional(z.array(AssetVideoZoomPanRangeSchema)),
  })
  .refine(
    (value) => {
      if (value.zoomPans?.length) {
        const zoomPans = cloneDeep(value.zoomPans);
        zoomPans.sort((a, b) => a.startTransition.startAt - b.startTransition.startAt);
        for (let i = 0; i < zoomPans.length - 1; i++) {
          if (zoomPans[i].endTransition.startAt > zoomPans[i + 1].startTransition.startAt) {
            return false;
          }
        }
      }

      return true;
    },
    { message: 'ZoomPans must not overlap' }
  );
