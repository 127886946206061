import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SocialShareUrlPipe } from '@openreel/frontend/common/hosting/dialogs/share/social-share/social-share-url.pipe';
import { PermissionableEntity } from '@openreel/common';
import { SocialShareType } from './social-share-type';
import { NgIf } from '@angular/common';

@Component({
  selector: 'openreel-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    SocialShareUrlPipe,
  ],
})
export class SocialShareComponent {
  @Input() shareUrl: string | null = null;
  @Input() entityType: PermissionableEntity | null = null;
  @Input() enableYoutube: boolean = false;
  @Output() share = new EventEmitter<SocialShareType>()

  readonly PermissionableEntity = PermissionableEntity;
}
