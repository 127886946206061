import { AsyncPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Member, TeamService, UserData } from '@openreel/frontend/common';
import { LetDirective } from '@openreel/frontend/common/directives/let.directive';
import {
  TooltipShowIfTruncatedDirective
} from '@openreel/frontend/common/directives/tooltip-show-if-truncated.directive';
import { AvatarModule } from 'ngx-avatar';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, startWith } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

const ROLES = [
  { name: 'Director', value: 'admin' }
];

const DEFAULT_ROLE = 'admin';

@Component({
  selector: 'openreel-autocomplete-invite-internal-user',
  templateUrl: './autocomplete-invite-internal-user.component.html',
  styleUrls: ['./autocomplete-invite-internal-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgFor,
    AvatarModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    LetDirective,
    MatDividerModule,
    TitleCasePipe,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    TooltipShowIfTruncatedDirective,
  ],
})
export class AutocompleteInviteInternalUserComponent {
  @Input() placeholder = 'Add others: Type email or team members name';

  @Input() set membersData(members: Member[]) {
    this._members = cloneDeep(members);
    this.setMemberIds(members);
  }

  get membersData(): Member[] {
    return this._members;
  }

  @Output() membersDataChange = new EventEmitter<Member[]>();

  readonly ROLES = ROLES;

  readonly searchFilter = new FormControl('');

  readonly users$: Observable<UserData[]>;
  readonly loading$: Observable<boolean>;

  private _members: Member[] = [];

  private readonly memberIds = new Set<number>();

  private readonly teamsService = inject(TeamService);
  private readonly toastr = inject(ToastrService);

  private readonly loadingSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.loading$ = this.loadingSubject.asObservable();
    this.users$ = this.searchUsers();
  }

  trackByFn(_: number, member: Member): number {
    return member.member_id;
  }

  getRoleName(role: string): string {
    const roleItem = ROLES.find(item => item.value === role);

    return roleItem ? roleItem.name : '';
  }

  isMemberAdded(memberId: number): boolean {
    return this.memberIds.has(memberId);
  }

  addUser(event: MatAutocompleteSelectedEvent): void {
    const { option } = event;
    const value: UserData = option.value;

    option.deselect();

    this.searchFilter.setValue('');

    if (this.memberIds.has(value.id)) {
      return;
    }

    this.memberIds.add(value.id);

    this.membersData.push({
      member_id: value.id,
      name: value.fullname,
      email: value.email,
      is_required: false,
      role: DEFAULT_ROLE,
    });

    this.membersDataChange.emit(this.membersData);
  }

  removeUser(member: Member): void {
    const index = this.membersData.findIndex(addedMember => member.member_id === addedMember.member_id);

    if (index === -1 || member.is_required) {
      return;
    }

    this.memberIds.delete(member.member_id);
    this.membersData.splice(index, 1);
    this.membersDataChange.emit(this.membersData);
  }

  private setMemberIds(members: Member[]): void {
    this.memberIds.clear();

    members.forEach(member => {
      this.memberIds.add(member.member_id);
    });
  }

  private searchUsers(): Observable<UserData[]> {
    return this.searchFilter.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.loadingSubject.next(true)),
      switchMap((searchString) => {
        const currentPage = 1;

        return this.teamsService.getUserList({
          search_string: searchString,
          page: currentPage,
          limit: 50,
          team_id: 0,
        }).pipe(
          map((response) => response.data),
          catchError((error) => {
            this.toastr.error(error.message, 'Error!');

            return of([]);
          }),
          finalize(() => this.loadingSubject.next(false)),
        );
      }),
    );
  }
}
