import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { commonenv } from '../../environments/environment';
import {
  WorkflowVideoModel,
  WorkflowRecordingListRequest,
  WorkflowRecordingProjectListRequest,
  WorkflowRecordingProjectModel,
  VideoLibraryRequest,
  VideoLibraryResponse,
} from './workflow-recording.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowRecordingService {
  public urls = {
    projectList: `${commonenv.nextGenApiUrl}workflow-recordings/projects`,
    recordingList: `${commonenv.nextGenApiUrl}workflow-recordings/recordings`,
    videoLibraryList: `${commonenv.nextGenApiUrl}hosting/videos`,
    videoHubsList: `${commonenv.nextGenApiUrl}hosting/hubs`,
  };

  constructor(public httpClient: HttpClient) {}

  getRecordingProjectList(params: WorkflowRecordingProjectListRequest): Observable<WorkflowRecordingProjectModel[]> {
    return this.httpClient.get<WorkflowRecordingProjectModel[]>(this.urls.projectList, {
      params: {
        ...params,
        searchText: params.searchText || '',
      },
    });
  }

  getRecordingList(params: WorkflowRecordingListRequest): Observable<{ data: WorkflowVideoModel[]; total: number }> {
    return this.httpClient.get<{ data: WorkflowVideoModel[]; total: number }>(this.urls.recordingList, {
      params: {
        ...params,
        dateFrom: params.dateFrom?.toISOString() ?? '',
        dateTo: params.dateTo?.toISOString() ?? '',
      },
    });
  }

  getVideoLibraryList(
    params: VideoLibraryRequest,
    hubId?: string | number
  ): Observable<{ data: WorkflowVideoModel[]; total: number }> {
    const url = hubId ? `${this.urls.videoHubsList}/${hubId}/videos` : this.urls.videoLibraryList;
    return this.httpClient
      .get<VideoLibraryResponse>(url, {
        params: {
          ...params,
        },
      })
      .pipe(
        map((response) => ({
          total: response.count,
          data: response.rows.map((row) => ({
            id: row.sourceAssetId,
            hostingId: row.id,
            createdAt: row.createdAt,
            name: row.title,
            duration: row.duration,
            thumbnailUrl: row.thumbnailSignedUrl[0],
          })),
        }))
      );
  }
}
