<div
  class="preview-container"
  [class.loaded]="imgLoaded"
  (mouseleave)="mouseLeave(thumbUrl)"
  (mouseenter)="mouseEnter(previewUrl)"
  (dragstart)="setThumb(thumbUrl)"
  draggable="true"
>
  <img
    class="mini-player-image dont-drag"
    (load)="imgLoaded = true"
    [src]="imgSrc$ | async"
    fetchpriority="auto"
    [ngClass]="{ shaded: showControls }"
    [openreelFallback]="'assets/common/images/placeholder16x9.gif'"
    alt="{{ alt }}"
  />

  <div class="play-details-container">
    <div class="play-details-container-inner" [@showHide]="showControls ? 'show' : 'hide'">
      <div
        *ngIf="showPlayButton"
        (click)="onPlayClick($event)"
        inline="true"
        class="mini-player-play-video"
        [matTooltip]="'Play Video'"
        [matTooltipPosition]="'above'"
        [matTooltipShowDelay]="500"
        draggable="true"
      >
        <mat-icon svgIcon="thumbnail-play"></mat-icon>
      </div>
      <div
        *ngIf="showDetailsButton"
        (click)="onViewDetailsClick($event)"
        inline="true"
        class="mini-player-details-video"
        [matTooltip]="'Video Details'"
        [matTooltipPosition]="'above'"
        [matTooltipShowDelay]="500"
        draggable="true"
      >
        <mat-icon svgIcon="thumbnail-details"></mat-icon>
      </div>
    </div>
  </div>

  <div *ngIf="showPermissionIcon" class="permission-container">
    <mat-icon [svgIcon]="isVideoShared ? 'hosting-public' : 'hosting-private'"> </mat-icon>
  </div>
</div>
