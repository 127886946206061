import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FontDto } from '@openreel/common';
import { ProjectFont } from '@openreel/creator/common';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FontService {
  private cachedFonts = new Map<string, FontDto>();

  private urls = {
    byIdSource: ({ source, id }: { source: string; id: number }) =>
      `${commonenv.nextGenApiUrl}fonts/by-id-source?source=${source}&id=${id}`,
    default: `${commonenv.nextGenApiUrl}fonts/default`,
    custom: () => `${commonenv.nextGenApiUrl}fonts/custom`,
    suggested: () => `${commonenv.nextGenApiUrl}fonts/suggested`,
    trending: () => `${commonenv.nextGenApiUrl}fonts/trending`,
    search: (name: string, limit: number, page: number) =>
      `${commonenv.nextGenApiUrl}fonts/search?name=${name}&limit=${limit}&page=${page}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  // used to cache all loaded fonts, it's useful to get font family by font ID
  getFontByProjectFontFromCache(projectFont: ProjectFont) {
    const font = this.cachedFonts.get(FontService.fontCacheKey(projectFont));

    if (!font) {
      throw new Error(`Font not found in cache: ${FontService.fontCacheKey(projectFont)}`);
    }

    return font;
  }

  getFontByProjectFont(projectFont: ProjectFont) {
    const font = this.cachedFonts.get(FontService.fontCacheKey(projectFont));

    if (font) {
      return of(font);
    } else {
      return this.httpClient.get<FontDto>(this.urls.byIdSource(projectFont)).pipe(
        tap((font) => {
          this.addFontToCache(font);
        })
      );
    }
  }

  addFontToCache(font: FontDto) {
    if (!this.cachedFonts.has(FontService.fontCacheKey(font))) {
      this.cachedFonts.set(FontService.fontCacheKey(font), font);
    }
  }

  searchFont(query = '', limit = 20, page = 0) {
    return this.httpClient.get<FontDto[]>(this.urls.search(query, limit, page)).pipe(
      tap((fonts) => {
        for (const font of fonts) {
          if (!this.cachedFonts.has(FontService.fontCacheKey(font))) {
            this.cachedFonts.set(FontService.fontCacheKey(font), font);
          }
        }
      })
    );
  }

  getDefaultFont() {
    return this.httpClient.get<FontDto>(this.urls.default).pipe(
      tap((font) => {
        this.addFontToCache(font);
      })
    );
  }

  customFonts() {
    return this.httpClient.get<FontDto[]>(this.urls.custom()).pipe(
      tap((fonts) => {
        for (const font of fonts) {
          this.addFontToCache(font);
        }
      })
    );
  }

  trendingFonts() {
    return this.httpClient.get<FontDto[]>(this.urls.trending()).pipe(
      tap((fonts) => {
        for (const font of fonts) {
          this.addFontToCache(font);
        }
      })
    );
  }

  suggestedFonts() {
    return this.httpClient.get<FontDto[]>(this.urls.suggested()).pipe(
      tap((fonts) => {
        for (const font of fonts) {
          this.addFontToCache(font);
        }
      })
    );
  }

  static fontCacheKey(font: ProjectFont) {
    return `${font.source}_${font.id}`;
  }
}
