import { z } from 'zod';
import { AssetColorTagSchema } from './assets.zod';
import { TEXT_BOX_EFFECTS_LIMITS } from '../constants/text-box-effects-limits.constants';

export const LayerBoundsSchema = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.optional(z.number()),
  angleRad: z.optional(z.number()),
  isTextBoxFixedWidth: z.optional(z.boolean()),
});

export const SimpleBoundsWithAngleSchema = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
  angleRad: z.number(),
});

export const BorderSchema = z.object({
  x: z.number(),
  y: z.number(),
  color: z.optional(z.string()),
});

export const BorderRadiusSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const SolidColorSchema = z.object({
  type: z.literal('solid'),
  color: z.string(),
});

export const GradientColorSchema = z.object({
  type: z.literal('gradient'),
  angle: z.number(),
  colors: z.array(
    z.object({
      stop: z.number(),
      color: z.string(),
    })
  ),
});

export const VisibilitySchema = z.object({
  startAt: z.number(),
  endAt: z.optional(z.number()),
});

export const TimingFunctionSchema = z.enum(['easeOutExpo', 'easeInOutCubic', 'linear']);

export const PushTransitionSchema = z.object({
  type: z.literal('push'),
  direction: z.enum(['top', 'right', 'bottom', 'left']),
  duration: z.number(),
  timingFunction: TimingFunctionSchema,
});

export const FadeTransitionSchema = z.object({
  type: z.enum(['fade-out', 'fade-in']),
  color: z.optional(z.string()),
  duration: z.number(),
});

export const TransitionSchema = z.union([PushTransitionSchema, FadeTransitionSchema]);

export const TransitionLayerSchema = z.object({
  type: z.literal('layer'),
  duration: z.number(),
  midpoint: z.number(),
  layer: z.lazy(() => LayerOptionsSchema.and(LottieLayerSchema)),
});

export const TransitionsSchema = z.object({
  entrance: z.optional(TransitionSchema),
  exit: z.optional(TransitionSchema),
  cross: z.optional(TransitionSchema),
  crossLayer: z.optional(TransitionLayerSchema),
});

export const LayerStylesSchema = z.object({
  borderRadius: z.optional(z.array(BorderRadiusSchema)),
  border: z.optional(BorderSchema),
  backgroundColor: z.optional(z.discriminatedUnion('type', [SolidColorSchema, GradientColorSchema])),
  objectFit: z.optional(z.enum(['cover', 'contain', 'stretch'])),
  objectPosition: z.optional(z.array(z.number().min(0).max(100))),
});

export const LayerOptionsSchema = z.object({
  layerId: z.string(),
  enabled: z.boolean(),
  loop: z.boolean(),
  visibility: z.optional(VisibilitySchema),
  transitions: z.optional(TransitionsSchema),
  bounds: z.optional(LayerBoundsSchema),
  styles: z.optional(LayerStylesSchema),
  tags: z.optional(z.array(z.string())),
});

export const AssetLayerSchema = z.object({
  assetId: z.string(),
});

export const SectionLayerSchema = z.object({
  type: z.literal('section'),
  sectionId: z.string(),
});

export const LottieLayerFieldDataSchema = z.object({
  type: z.enum(['text', 'image', 'logo', 'shape', 'video', 'fill']),
  styleId: z.optional(z.string()),
  assetId: z.optional(z.string()),
  value: z.optional(z.string()),
});

export const TextEffectShadowSchema = z.object({
  type: z.literal('shadow'),
  color: z.string(),
  blur: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.shadow.blur.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.shadow.blur.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.shadow.blur.step),
  offset: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.shadow.offset.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.shadow.offset.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.shadow.offset.step),
  angle: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.shadow.angle.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.shadow.angle.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.shadow.angle.step),
  opacity: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.shadow.opacity.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.shadow.opacity.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.shadow.opacity.step),
});

export const TextEffectGlowSchema = z.object({
  type: z.literal('glow'),
  blur: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.glow.blur.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.glow.blur.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.glow.blur.step),
});

export const TextEffectEchoSchema = z.object({
  type: z.literal('echo'),
  color: z.string(),
  offset: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.echo.offset.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.echo.offset.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.echo.offset.step),
  angle: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.echo.angle.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.echo.angle.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.echo.angle.step),
  opacity: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.echo.opacity.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.echo.opacity.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.echo.opacity.step),
});

export const TextEffectOutlineSchema = z.object({
  type: z.literal('outline'),
  color: z.string(),
  thickness: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.outline.thickness.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.outline.thickness.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.outline.thickness.step),
});

export const TextEffectGlitchSchema = z.object({
  type: z.literal('glitch'),
  rightColor: z.string(),
  leftColor: z.string(),
  offset: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.glitch.offset.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.glitch.offset.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.glitch.offset.step),
  angle: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.glitch.angle.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.glitch.angle.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.glitch.angle.step),
});

export const TextEffectSpliceSchema = z.object({
  type: z.literal('splice'),
  color: z.string(),
  thickness: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.splice.thickness.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.splice.thickness.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.splice.thickness.step),
  offset: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.splice.offset.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.splice.offset.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.splice.offset.step),
  angle: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.splice.angle.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.splice.angle.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.splice.angle.step),
});

export const TextEffectHollowSchema = z.object({
  type: z.literal('hollow'),
  thickness: z
    .number()
    .gte(TEXT_BOX_EFFECTS_LIMITS.hollow.thickness.min)
    .lte(TEXT_BOX_EFFECTS_LIMITS.hollow.thickness.max)
    .step(TEXT_BOX_EFFECTS_LIMITS.hollow.thickness.step),
});

export const TextEffectSchema = z.union([
  TextEffectShadowSchema,
  TextEffectGlowSchema,
  TextEffectSpliceSchema,
  TextEffectHollowSchema,
  TextEffectEchoSchema,
  TextEffectOutlineSchema,
  TextEffectGlitchSchema,
]);

export const LottieLayerSchema = AssetLayerSchema.extend({
  type: z.literal('lottie'),
  renderer: z.enum(['canvas', 'svg', 'html']),
  isTextBox: z.optional(z.boolean()),
  data: z.optional(z.record(LottieLayerFieldDataSchema)),
  colorTags: z.optional(z.array(z.lazy(() => AssetColorTagSchema))),
  textEffect: z.optional(TextEffectSchema),
});

export const VideoLayerSchema = AssetLayerSchema.extend({
  type: z.literal('video'),
  editing: z.optional(
    z.object({
      label: z.string(),
    })
  ),
});

export const ImageLayerSchema = AssetLayerSchema.extend({
  type: z.literal('image'),
  editing: z.optional(
    z.object({
      label: z.string(),
    })
  ),
});

export const ColorLayerSchema = z.object({
  type: z.literal('color'),
  styleId: z.string(),
});

export const LayerSchema = LayerOptionsSchema.and(
  z.union([SectionLayerSchema, LottieLayerSchema, VideoLayerSchema, ImageLayerSchema, ColorLayerSchema])
);

export const TimelineSchema = z.object({
  type: z.enum(['background', 'main', 'b-roll', 'overlays', 'text-boxes', 'watermark', 'images']),
  layers: z.array(LayerSchema),
});

export const ProjectFontSchema = z.object({
  source: z.enum(['google', 'custom']),
  id: z.number(),
});
