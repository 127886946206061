import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, initialState } from './auth.state';
import * as AuthActions from './auth.actions';
export const authReducer = createReducer<AuthState, Action>(
  initialState,
  on(AuthActions.setToken, (state, action) => ({
    ...state,
    token: action.token,
  })),
  on(AuthActions.setRole, (state, action) => ({
    ...state,
    role: action.role,
  })),
  on(AuthActions.setDirectorUserDetails, (state, action) => ({
    ...state,
    user: action.director,
  }))
);
