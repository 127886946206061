import {
  hexToRgba,
  pctToPx,
  PresetField,
  radiansToDegrees,
  TextBoxProperties,
  TextEffectEcho,
  TextEffectGlitch,
  TextEffectGlow,
  TextEffectHollow,
  TextEffectOutline,
  TextEffectShadow,
} from '@openreel/creator/common';
import { TextLayer } from '../interfaces/lottie.interface';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';
import { cloneDeep } from 'lodash';

const MAIN_LAYER = 'Main Text';
const SHADOW = 'Shadow';
const SHADOW_BLUR_CORRECTION_FACTOR = 2;

export class TransformerTextEffect extends TransformerBase {
  test(fieldDef: PresetField, textBoxProperties?: TextBoxProperties) {
    return fieldDef.type === 'text' && !!textBoxProperties;
  }

  process({ animation, fieldDef, fieldValues, textBoxProperties }: TransformerProcessOptions) {
    if (!fieldDef.lottiePath) {
      return animation;
    }

    const rootNodes = this.getRootNode<TextLayer[]>(animation, fieldDef.lottiePath);
    if (!Array.isArray(rootNodes)) {
      return animation;
    }

    const mainLayerNode = rootNodes.find((l) => l.nm === MAIN_LAYER);
    if (!mainLayerNode) {
      return animation;
    }

    const referencePx = mainLayerNode.t.d.k[0].s.s;

    if (textBoxProperties.textEffect?.type === 'shadow') {
      this.setShadowEffect(referencePx, mainLayerNode, textBoxProperties.textEffect, textBoxProperties?.bounds.angleRad);
    } else if (textBoxProperties.textEffect?.type === 'echo') {
      this.setEchoEffect(referencePx, mainLayerNode, rootNodes, textBoxProperties.textEffect, textBoxProperties?.bounds.angleRad);
    } else if (textBoxProperties.textEffect?.type === 'glitch') {
      this.setGlitchEffect(referencePx, mainLayerNode, rootNodes, textBoxProperties.textEffect, textBoxProperties?.bounds.angleRad);
    } else if (textBoxProperties.textEffect?.type === 'glow') {
      this.setGlowEffect(referencePx, mainLayerNode, fieldValues.color, textBoxProperties.textEffect);
    } else if (textBoxProperties.textEffect?.type === 'outline' || textBoxProperties.textEffect?.type === 'hollow') {
      this.setOutlineEffect(referencePx, mainLayerNode, textBoxProperties.textEffect);
    } else if (textBoxProperties.textEffect?.type === 'splice') {
      this.setOutlineEffect(referencePx, mainLayerNode, {
        type: 'hollow',
        thickness: textBoxProperties.textEffect.thickness,
      });
      this.setCopyShadowEffect(
        referencePx,
        mainLayerNode,
        rootNodes,
        {
          type: 'shadow',
          blur: 0,
          color: textBoxProperties.textEffect.color,
          offset: textBoxProperties.textEffect.offset,
          angle: textBoxProperties.textEffect.angle,
          opacity: 100,
        },
        textBoxProperties?.bounds.angleRad
      );
    }

    return animation;
  }

  // echo effect works by creating 2 duplicates of the main text layer and moving them by the defined offset
  // the first layer is positioned exactly by the offset and with the opacity set by the user
  // the 2nd layer is moved by twice the offset set by the user and with 1/3 the opacity
  private setEchoEffect(referencePx: number, mainLayer: TextLayer, rootNodes: TextLayer[], textEffect: TextEffectEcho, angleRad: number) {
    const angle = textEffect.angle + radiansToDegrees(angleRad ?? 0);

    const echoOffsetX = Math.cos(angle * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx);
    const echoOffsetY = Math.sin(angle * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx);

    const echoLayer1 = cloneDeep(mainLayer);
    echoLayer1.ef = [];
    echoLayer1.t.d.k[0].s.fc = hexToRgba(textEffect.color);
    echoLayer1.ks.o.k = textEffect.opacity;
    echoLayer1.ks.p.k[0] += echoOffsetX;
    echoLayer1.ks.p.k[1] += echoOffsetY;
    rootNodes.push(echoLayer1);

    const echoLayer2 = cloneDeep(mainLayer);
    echoLayer2.ef = [];
    echoLayer2.t.d.k[0].s.fc = hexToRgba(textEffect.color);
    echoLayer2.ks.o.k = textEffect.opacity / 3;
    echoLayer2.ks.p.k[0] += echoOffsetX * 2;
    echoLayer2.ks.p.k[1] += echoOffsetY * 2;
    rootNodes.push(echoLayer2);
  }

  // echo effect works by creating 2 duplicates of the main text layer and moving them by the defined offset
  // the 2 layer are positioned oposite of each other, and they each have the color that is set by the user
  private setGlitchEffect(
    referencePx: number, mainLayer: TextLayer,
    rootNodes: TextLayer[],
    textEffect: TextEffectGlitch,
    angleRad: number
  ) {
    const angle = -1 * textEffect.angle + radiansToDegrees(angleRad ?? 0);

    const glitchRightLayer = cloneDeep(mainLayer);
    glitchRightLayer.ef = [];
    glitchRightLayer.t.d.k[0].s.fc = hexToRgba(textEffect.rightColor);
    glitchRightLayer.ks.p.k[0] += Math.cos((angle + 180) * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx); // X
    glitchRightLayer.ks.p.k[1] += Math.sin((angle + 180) * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx); // Y
    rootNodes.push(glitchRightLayer);

    const glitchLeftLayer = cloneDeep(mainLayer);
    glitchLeftLayer.ef = [];
    glitchLeftLayer.t.d.k[0].s.fc = hexToRgba(textEffect.leftColor);
    glitchLeftLayer.ks.p.k[0] += Math.cos(angle * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx); // X
    glitchLeftLayer.ks.p.k[1] += Math.sin(angle * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx); // Y
    rootNodes.push(glitchLeftLayer);
  }

  // shadow effect uses native lottie shadow effect applied on the main text layer
  // https://lottiefiles.github.io/lottie-docs/effects/#drop-shadow-effect
  private setShadowEffect(referencePx: number, mainLayer: TextLayer, textEffect: TextEffectShadow, angleRad: number) {
    const shadowEffect = mainLayer.ef.find((e) => e.nm === SHADOW);
    shadowEffect.ef[0].v.k = hexToRgba(textEffect.color);
    shadowEffect.ef[1].v.k = (textEffect.opacity / 100) * 256;
    shadowEffect.ef[2].v.k = textEffect.angle + 90 + radiansToDegrees(angleRad ?? 0);
    shadowEffect.ef[3].v.k = pctToPx(textEffect.offset, referencePx);
    shadowEffect.ef[4].v.k = pctToPx(textEffect.blur, referencePx) * SHADOW_BLUR_CORRECTION_FACTOR;
  }

  // copy shadow effect works by creating a duplicate layer (like echo) so that the shadow doesn't have an outline
  private setCopyShadowEffect(
    referencePx: number, mainLayer: TextLayer,
    rootNodes: TextLayer[],
    textEffect: TextEffectShadow,
    angleRad: number
  ) {
    const angle = textEffect.angle + radiansToDegrees(angleRad ?? 0);

    const echoOffsetX = Math.cos(angle * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx);
    const echoOffsetY = Math.sin(angle * (Math.PI / 180)) * pctToPx(textEffect.offset, referencePx);

    const echoLayer = cloneDeep(mainLayer);
    echoLayer.ef = [];
    echoLayer.t.d.k[0].s.fc = hexToRgba(textEffect.color);
    echoLayer.t.d.k[0].s.sw = 0;
    echoLayer.ks.o.k = textEffect.opacity;
    echoLayer.ks.p.k[0] += echoOffsetX;
    echoLayer.ks.p.k[1] += echoOffsetY;
    rootNodes.push(echoLayer);
  }

  // glow uses the native shadow effect but with no offset
  private setGlowEffect(referencePx: number, mainLayer: TextLayer, color: number[], textEffect: TextEffectGlow) {
    // https://lottiefiles.github.io/lottie-docs/effects/#drop-shadow-effect
    const shadowEffect = mainLayer.ef.find((e) => e.nm === SHADOW);
    shadowEffect.ef[0].v.k = color;
    shadowEffect.ef[1].v.k = 256;
    shadowEffect.ef[2].v.k = 0;
    shadowEffect.ef[3].v.k = 0;
    shadowEffect.ef[4].v.k = pctToPx(textEffect.blur, referencePx) * SHADOW_BLUR_CORRECTION_FACTOR;
  }

  // outline is just a property which is set on the lottie text layer
  private setOutlineEffect(referencePx: number, mainLayer: TextLayer, textEffect: TextEffectOutline | TextEffectHollow) {
    mainLayer.t.d.k[0].s.sw = Math.max(pctToPx(textEffect.thickness, referencePx), 1);

    if (textEffect.type === 'hollow') {
      mainLayer.t.d.k[0].s.sc = mainLayer.t.d.k[0].s.fc;
      mainLayer.t.d.k[0].s.fc = [0, 0, 0, 0];
    } else {
      mainLayer.t.d.k[0].s.sc = hexToRgba(textEffect.color);
    }
  }
}
