<h2 mat-dialog-title>Password protection for the public {{data.type}} access</h2>
<mat-dialog-content>
  <div>
    <p>Visitors will have to type in the password in order to see the {{data.type}}</p>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput (blur)="trimSpaces();" [formControl]="password" [type]="hide ? 'password' : 'text'" placeholder="Set Password" />
      <mat-icon style="cursor: pointer" matSuffix (click)="hide = !hide">{{
        hide ? 'visibility_off' : 'visibility'
      }}</mat-icon>
      <mat-error *ngIf="password.errors?.required">Password is required</mat-error>
      <mat-error *ngIf="password.errors?.minlength">Password must be at least 5 characters long.</mat-error>
      <mat-error *ngIf="password.errors?.maxlength">Password cannot exceed 20 characters.</mat-error>
    </mat-form-field>
  </div>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" [disabled]="password.invalid" (click)="setPassword();">Confirm</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</mat-dialog-content>
