<mat-sidenav-container class="example-container" style="height: 100%" hasBackdrop="false">
  <mat-sidenav mode="side" opened disableClose>
    <openreel-menu (logout)="onLogoutButtonClicked()"></openreel-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div *ngIf="showHeader$ | async" class="nav-toolbar">
      <openreel-navbar
        padding-80
        [userDetails]="userDetails"
        [title]="title"
        [subtitle]="subtitle"
        [titleRoute]="titleRoute"
      ></openreel-navbar>
    </div>

    <div class="content-wrapper" [class.full-height]="(showHeader$ | async) === false">
      <div class="alert-container">
        <openreel-alert-container alertEventCapture></openreel-alert-container>
      </div>

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
