import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from './navbar.component';

@Component({
  selector: 'openreel-navbar-no-user',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarNoUserComponent extends NavbarComponent implements OnInit {
  ngOnInit() {
    this.navbarAttributes.forEach((attr) => {
      if (this.getElement().hasAttribute(attr)) {
        this.getElement().classList.add(attr);
      }
    });
  }
}
