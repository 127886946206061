<div mat-dialog-title class="dialog-header">
  <div class="heading-wrapper">
    <div class="icon-wrapper">
      <div class="icon">
        <mat-icon svgIcon="automations-main"></mat-icon>
      </div>
    </div>

    <div class="heading">
      <h2 class="title">{{ data.title }}</h2>
      <h3 class="subtitle">We have setup your Brand Kit for you, please review and change anything that may be incorrect or missing.</h3>
    </div>
  </div>

  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="brandkit">
    <openreel-brandkit-preview
      [logoSrc]="form.get('logo').value?.src"
      [watermarkSrc]="form.get('watermark').value?.src"
      [fontName]="form.get('font').value?.family"
      [color]="form.get('color').value"
    />

    <openreel-brandkit-preview-form [data]="data" />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="actions" [class.space-between]="data.welcomeModal">
    <button mat-stroked-button *ngIf="data.welcomeModal" (click)="goToBrandKitSettings()">Adjust brand kit</button>

    <button mat-flat-button color="primary" (click)="apply()">Confirm</button>
  </div>
</mat-dialog-actions>

