<div 
    [initHostingInternalAnalytics]="analytics == 'internal'"
    [initHostingPublicAnalytics]="analytics == 'public'"
>
    <mat-spinner *ngIf="loading" [diameter]="40"></mat-spinner>
    <openreel-hosting-player
        *ngIf="!loading && playbackData"
        [border]="true"
        [srcData]="playbackData"
        class="cornered-player"
        [playerEventCapture]="{ 
            videoId: playbackData.id, 
            videoTitle: playbackData.title, 
            videoIdHash: playbackData.idHash, 
            accountIdHash: playbackData.accountIdHash 
        }"
    >
    </openreel-hosting-player>
</div>