export interface Product {
  id: string;
  name: string;
  description: string;
  recurringInterval: string;
  recurringIntervalCount: number;
  price: number;
  features: string[];
  active: boolean;
  default: boolean;
  cancelAt?: string;
}

export interface PaymentMethod {
  id: string;
  brand: string;
  last4: string;
  expiryMonth: number;
  expiryYear: number;
  isDefault: boolean;
}

export interface Invoice {
  id: string;
  name: string;
  date: string;
  paid: boolean;
  amount: number;
  plan: string;
  url: string;
}
export const EnterpriseFeatures = ['Advanced Features', 'Advanced Reporting', 'Approval Process', 'Custom Templates'];
