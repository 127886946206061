<a class="hosting-video" [class.selected]="isSelected" (click)="click($event)">
    <div class="video-thumb-box">
        <div class="video-thumb">
            <img 
              alt="Not found" 
              [src]="video.thumbnailSignedUrl?.[0]"
              [openreelFallback]="'assets/common/images/placeholder16x9.gif'"
             />
        </div>
        <mat-icon *ngIf="enablePreview" svgIcon="play"></mat-icon>
    </div>
    <div class="video-details">
        <div>
          <h2 class="title">{{ video.title }}</h2>
          <div class="info">
            <span>{{ video.createdAt | date : 'MMM dd, y' }}</span>
            <span>{{ video.duration | secondsToMinSec }}</span>
          </div>
        </div>
        <button
          *ngIf="enableSelection"
          class="select-btn"
          [class.active]="isSelected"
          (click)="select($event)"
        >
          <mat-icon>
            {{isSelected ? 'check_circle' : 'radio_button_unchecked'}}
          </mat-icon>
        </button>
    </div>
</a>