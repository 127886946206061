import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { FeatureFlaggingService } from '../services/feature-flagging/feature-flagging.service'
import { BaseConditionalStructuralDirective } from './base-conditional-structural.directive';

@Directive({
  selector: '[featureFlag],[featureFlagElse]',
  standalone: true,
})
export class FeatureFlagDirective extends BaseConditionalStructuralDirective implements OnInit {
  private _feature: string;
  @Input() set featureFlag(feature: string) {
      this._feature = feature;
      this.checkFlags();
  }

  @Input('featureFlagElse') elseTemplate?: TemplateRef<unknown>

  constructor(private featureFlagService: FeatureFlaggingService, 
              templateRef: TemplateRef<unknown>, 
              viewContainer: ViewContainerRef) {
    super(templateRef, viewContainer)
  }

  ngOnInit() {
    this.mortalize(this.featureFlagService.flagChange$).subscribe(() => this.checkFlags());
  }

  private checkFlags() {
    const flag = this._feature ? this.featureFlagService.getFlag(this._feature) : true;
    this.renderView(flag);
  }
}
