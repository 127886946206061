import { Injectable, inject } from '@angular/core';
import { BrandKitService } from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';
import { BrandKitAutofetchService } from './brand-kit-autofetch/brand-kit-autofetch.service';
import { MatDialog } from '@angular/material/dialog';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { BrandKitFormSettings, BrandKitFormSettingsModel } from './interfaces/brand-kit-settings.interface';
import { EMPTY, catchError, switchMap, tap } from 'rxjs';
import { ApplyBrandfetchBrandingModalComponent } from './brand-kit-autofetch/apply-brandfetch-branding-modal/apply-brandfetch-branding-modal.component';
import { BrandKitApplyBrandfetchDto } from '@openreel/common';

interface State {
  brandKit: BrandKitFormSettings | null;
}

@Injectable()
export class BrandKitSettingsStore extends ComponentStore<State> {
  private readonly brandKitService = inject(BrandKitService);
  private readonly toastrService = inject(ToastrService);
  private readonly brandKitAutofetchService = inject(BrandKitAutofetchService);
  private readonly dialog = inject(MatDialog);

  constructor() {
    super({
      brandKit: null,
    });
  }

  public readonly brandKit$ = this.select((state) => state.brandKit);

  public readonly setBrandKit = this.updater((state, brand: BrandKitFormSettings) => ({ ...state, brandKit: brand }));

  public readonly loadBrandKit = this.effect<void>(($) =>
    $.pipe(
      switchMap(() =>
        this.brandKitService.getBrandKitForAccount().pipe(
          tapResponse(
            (brand) => {
              this.setBrandKit(BrandKitFormSettingsModel.fromDTO(brand));
            },
            (error: Error) => {
              this.toastrService.error(error.message, 'Error!');
            }
          )
        )
      )
    )
  );

  public readonly updateBrandkit = this.effect<BrandKitFormSettings>((brandkitData$) =>
    brandkitData$.pipe(
      switchMap((brandkitData) =>
        this.brandKitService.updateBrandKit(brandkitData.id, BrandKitFormSettingsModel.toDTO(brandkitData)).pipe(
          catchError((error) => {
            this.toastrService.error(error.message, 'Error!');
            return EMPTY;
          })
        )
      )
    )
  );

  public readonly generateBrandKit = this.effect<string>((companyWebsite$) =>
    companyWebsite$.pipe(
      switchMap((companyWebsite) => {
        const hostName = this._getHostName(companyWebsite);
        return this.brandKitAutofetchService.retrieveBrand(hostName).pipe(
          catchError((error) => {
            this.toastrService.error(error.message, 'Error!');
            return EMPTY;
          })
        );
      }),
      switchMap((brand) => {
        return this.dialog
          .open(ApplyBrandfetchBrandingModalComponent, {
            data: {
              welcomeModal: true,
              title: 'Welcome to OpenReel',
              ...brand,
            },
            minWidth: '500px',
          })
          .afterClosed();
      }),
      switchMap((brandingData: BrandKitApplyBrandfetchDto) => {
        if (!brandingData) {
          return EMPTY;
        }
        return this.brandKitService.applyBranding(brandingData).pipe(
          catchError((error) => {
            this.toastrService.error(error.message, 'Error!');
            return EMPTY;
          })
        );
      }),
      tap(() => {
        this.toastrService.success('Brandkit updated successfully', 'Success!');
      })
    )
  );

  private _getHostName = (url: string): string => {
    if (!/^https?:\/\//i.test(url)) {
      url = 'http://' + url;
    }

    return new URL(url).hostname;
  };
}
