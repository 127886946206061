import {inject, Injectable} from "@angular/core";
import {commonenv} from "@openreel/frontend/common/env/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ShareVideoOnYoutubeDto, YoutubeChannel} from "@openreel/frontend/common/interfaces";

@Injectable({
  providedIn: 'root'
})
export class YoutubeIntegrationService {
  private readonly url = `${commonenv.nextGenApiUrl}youtube`;
  private readonly http = inject(HttpClient);

  fetchYoutubeChannels(userId: number): Observable<YoutubeChannel[]> {
    return this.http.get<YoutubeChannel[]>(`${this.url}/channels/${userId}`);
  }

  shareVideo(dto: ShareVideoOnYoutubeDto) {
    return this.http.post(`${this.url}/upload`, dto);
  }
}
