import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, SsoService, SsoSetupData, SsoXmlParserService } from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'openreel-sso-setup',
  templateUrl: './sso-setup.component.html',
  styleUrls: ['./sso-setup.component.scss'],
})
export class SsoSetupComponent implements OnInit {
  ssoForm: UntypedFormGroup;
  defaultSlug: string;
  defaultCallbackUrl: string;
  isSubmit = false;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private ssoService: SsoService,
    private ssoXmlParserService: SsoXmlParserService
  ) {}
  ngOnInit() {
    const user = this.authService.getUserDetails();
    this.defaultSlug = user?.data.site_user_slug;
    this.defaultCallbackUrl = this.ssoService.urls.getCallbackUrl(this.defaultSlug);
    this.getSsoData();
  }
  async getSsoData() {
    try {
      const ssoData: SsoSetupData = await this.ssoService.getSsoSetup();
      this.createSsoForm(ssoData);
    } catch (e) {
      this.createSsoForm(null);
    }
  }
  createSsoForm(ssoData: SsoSetupData) {
    this.ssoForm = new UntypedFormGroup({
      slug: new UntypedFormControl(ssoData ? ssoData.slug : this.defaultSlug, Validators.required),
      callbackUrl: new UntypedFormControl(
        ssoData ? ssoData.samlConfig.callbackUrl : this.defaultCallbackUrl,
        Validators.required
      ),
      entryPoint: new UntypedFormControl(ssoData?.samlConfig.entryPoint, Validators.required),
      issuer: new UntypedFormControl(ssoData?.samlConfig.issuer, Validators.required),
      certificate: new UntypedFormControl(''),
      firstNameKey: new UntypedFormControl(ssoData?.userFieldReplacer.firstName),
      lastNameKey: new UntypedFormControl(ssoData?.userFieldReplacer.lastName),
      fullNameKey: new UntypedFormControl(ssoData?.userFieldReplacer.fullName),
      emailKey: new UntypedFormControl(ssoData?.userFieldReplacer.email, Validators.required),
    });
  }
  async submitSsoForm() {
    this.ssoForm.patchValue({ callbackUrl: this.ssoService.urls.getCallbackUrl(this.ssoForm.value.slug) });
    if (this.ssoForm.valid) {
      this.isSubmit = true;
      try {
        await this.ssoService.createSsoSetup(this.ssoForm.value);
        this.toastr.success('SSO Setup Successfully', 'Success!');
      } catch (e) {
        this.toastr.error(e.message || 'Unable to Setup SSO', 'Error!');
      }
      this.isSubmit = false;
    }
  }
  formatSlug() {
    const formattedSlug = this.ssoService.getFormattedSlug(this.ssoForm.value.slug);
    this.ssoForm.patchValue({ slug: formattedSlug, callbackUrl: this.ssoService.urls.getCallbackUrl(formattedSlug) });
  }
  readFile(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
      const xmlData = evt.target.result as string;
      const metaData = this.ssoXmlParserService.parseXml(xmlData);
      this.ssoForm.patchValue(metaData);
    };
    reader.readAsText(file);
  }
  removeValue(event: Event) {
    (event.target as HTMLInputElement).value = null;
  }
}
