import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectSocketService } from '../../socket/subject-socket.service';
import {
  StartVideo,
  StopVideo,
  CheckRecordingStatus,
  VideoUploadStatus,
  UploadVideoEndApp,
  AckUploadVideoEndApp,
  CancelVideoUpload,
  EVT_D2S_START_RECORDING,
  EVT_D2S_STOP_RECORDING,
  EVT_D2S_CANCEL_UPLOAD,
  EVT_S2D_START_RECORDING_ACK,
  EVT_S2D_CHECK_RECORDING,
  EVT_S2D_STOP_RECORDING_ACK,
  EVT_S2D_START_UPLOADING,
  EVT_S2D_UPLOAD_STATUS,
  EVT_S2D_UPLOAD_DONE_APP,
  EVT_S2D_CANCEL_UPLOAD_ACK,
  EVT_S2D_SET_UPLOAD_VIDEO_ACTIVE_ACK,
  VideoStatus,
} from '../../../interfaces/socket-events';
import { RecordingMetadata } from '../../upload/dto/upload.dto';

@Injectable()
export class SocketExtensionRecordingService {
  startVideo$: Observable<StartVideo>;
  stopVideo$: Observable<StopVideo>;
  cancelVideoUpload$: Observable<CancelVideoUpload>;

  constructor(private socket: SubjectSocketService) {
    this.startVideo$ = this.socket.getMySocketEventByName(EVT_D2S_START_RECORDING);
    this.stopVideo$ = this.socket.getMySocketEventByName(EVT_D2S_STOP_RECORDING);

    this.cancelVideoUpload$ = this.socket.getMySocketEventByName(EVT_D2S_CANCEL_UPLOAD);
  }

  sendAckForRecordStart(data: RecordingMetadata, stat: '1' | '0', uploadDuringRecording?: boolean, errorcode?: '1') {
    this.socket.emitSocket({
      eventName: EVT_S2D_START_RECORDING_ACK,
      data: {
        VideoName: data.localFileName,
        file_size: 0,
        resolution: data.resolution,
        stat: stat,
        status: VideoStatus.RECORDING,
        videoid: data.videoId,
        uploadDuringRecording,
        errorcode,
      },
    });
  }

  sendAckForRecordStop(data: RecordingMetadata, stat: '1' | '0') {
    this.socket.emitSocket({
      eventName: EVT_S2D_STOP_RECORDING_ACK,
      data: {
        VideoName: data.localFileName,
        file_size: 0, // FIXME: use the correct one
        stat: stat,
        status: VideoStatus.RECORDED,
        video_length: -1, // FIXME: use the correct value
        videoid: data.videoId,
        totalRecordDuration: data.totalRecordDuration,
      },
    });
  }

  sendAckStartUploading(data: RecordingMetadata, stat: '1' | '0') {
    this.socket.emitSocket({
      eventName: EVT_S2D_START_UPLOADING,
      data: {
        status: VideoStatus.UPLOADING,
        videoid: data.videoId,
        stat,
      },
    });
  }

  sendRecordingStatus(status: CheckRecordingStatus) {
    this.socket.emitSocket({
      eventName: EVT_S2D_CHECK_RECORDING,
      data: status,
    });
  }

  sendAckSetVideoUploadActive(videoid: number, active: number) {
    this.socket.emitSocket({
      eventName: EVT_S2D_SET_UPLOAD_VIDEO_ACTIVE_ACK,
      data: {
        videoid,
        active,
      },
    });
  }

  sendUploadVideoStatus(value: VideoUploadStatus) {
    this.socket.emitSocket({ eventName: EVT_S2D_UPLOAD_STATUS, data: value });
  }

  async sendVideoUploadEnd(value: UploadVideoEndApp) {
    return await this.socket.sendAndWaitAck<UploadVideoEndApp, AckUploadVideoEndApp>({
      eventName: EVT_S2D_UPLOAD_DONE_APP,
      data: value,
    });
  }
  async sendAckCancelUpload(stat: '0' | '1', videoid: number) {
    this.socket.emitSocket({
      data: {
        stat,
        videoid,
        status: '2',
      },
      eventName: EVT_S2D_CANCEL_UPLOAD_ACK,
    });
  }
}
