import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { commonenv } from '../../environments/environment';
import { UserUploadCreate, UserUpload, CreateUserUploadDto } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserUploadService {
  private baseUrl = commonenv.nextGenApiUrl;
  constructor(private http: HttpClient) {}

  createUserUpload(
    name: string,
    extension: string,
    fileSize: number,
    fileResolution: { width: number; height: number },
    personName?: string,
    source?: 'web' | 'extension',
    recordingType?: 'camera' | 'screen' | 'both-pip',
    captureProjectId?: number
  ) {
    return this.http.post<UserUploadCreate>(`${this.baseUrl}user-upload`, {
      name,
      extension: extension.trim().toLowerCase(),
      fileSize,
      fileResolution,
      source: source || 'web',
      recordingType: recordingType || null,
      personName: personName || null,
      captureProjectId: captureProjectId || null,
    });
  }

  createUserUploads(uploads: CreateUserUploadDto[]) {
    return this.http.post<UserUploadCreate[]>(`${this.baseUrl}user-upload/multi`, uploads);
  }

  getUserUpload(id: number): Observable<UserUpload>;
  getUserUpload(id: number, withCredentials: true): Observable<UserUploadCreate>;
  getUserUpload(id: number, withCredentials?: true) {
    let params = new HttpParams();
    if (withCredentials) params = params.set('withCredentials', true);
    return this.http.get(`${this.baseUrl}user-upload/${id}`, { params });
  }

  updateUserUpload(id: number, uploadData: { state: string; fileSize?: number; duration?: number }) {
    return this.http.patch<UserUpload>(`${this.baseUrl}user-upload/${id}`, uploadData);
  }

  deleteUserUpload(id: number) {
    return this.http.delete<void>(`${this.baseUrl}user-upload/${id}`);
  }
}
