import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { Cleanupable } from '../../classes';
import { PerformanceService } from '../../services/performance/performance.interface';
import { isOnWeb } from '../../utils';
import { NextgenParticipant } from '../../interfaces/nextgen-participant.interface';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatIconModule } from '@angular/material/icon';

const APP_DEVICE_NAME = 'NEXTGEN DESKTOP CLIENT';

interface PerformanceScreenPopupData {
  participant$?: Observable<NextgenParticipant>;
  performanceService: PerformanceService;
  networkQuality$: Observable<number>;
}

@Component({
  selector: 'openreel-performance-screen-popup',
  templateUrl: './performance-screen-popup.component.html',
  styleUrls: ['./performance-screen-popup.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    MatLegacyProgressBarModule,
    MatIconModule,
  ],
})
export class PerformanceScreenPopupComponent extends Cleanupable implements OnInit, OnDestroy {
  constructor(
    private readonly dialogRef: MatDialogRef<PerformanceScreenPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: PerformanceScreenPopupData
  ) {
    super();
  }

  isRunning = true;
  cpuSpeed = 'Calculating...';
  cpuCount = 'Calculating...';
  cpuUsage = 'Calculating...';
  cpuAppUsage = 'Calculating...';
  memory = 'Calculating...';
  memoryUsage = 'Calculating...';
  memoryAppUsage = 'Calculating...';
  uploadSpeed = 'Calculating...';
  downloadSpeed = 'Calculating...';
  networkQuality = 0;
  isOnWeb = false;
  isRemoteIos = false;
  participant: NextgenParticipant;

  ngOnInit(): void {
    if (this.data.participant$) {
      this.data.participant$
        .pipe(
          takeUntil(this.ngUnsubscribe),
          distinctUntilChanged((prev, curr) => prev.identity === curr.identity)
        )
        .subscribe((participant) => {
          this.participant = participant;
          this.onParticipantChange();
        });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onParticipantChange(): void {
    if (this.participant && this.participant.isIosDevice) {
      //fetching internet speed for iOS
      const appNetworkSpeed = this.participant.deviceProperties.speed.split(',');
      this.downloadSpeed = appNetworkSpeed[0] ? appNetworkSpeed[0].split(':')[1] : 'N/A';
      this.uploadSpeed = appNetworkSpeed[1] ? appNetworkSpeed[1].split(':')[1] : 'N/A';
      this.isRemoteIos = true;
    }
    if (this.data.networkQuality$) {
      this.subscriptions.push(
        this.data.networkQuality$.subscribe((level: number) => {
          this.networkQuality = level;
        })
      );
    }
    /* There should be a simpler way of figuring out whether a remote participant is using the electron app */
    this.isOnWeb = this.participant?.deviceName
      ? this.participant.deviceName.toUpperCase() !== APP_DEVICE_NAME
      : isOnWeb();
    if (!this.isRemoteIos) {
      this.subscriptions.push(
        this.data.performanceService.hardwareConsumption$.subscribe(
          ({ cpuUsage, cpuSpeed, cpuCount, cpuAppUsage, memoryUsage, memoryAppUsage, memory, networkQuality }) => {
            this.cpuCount = this.withUnit(cpuCount);
            this.cpuAppUsage = this.withUnit(cpuAppUsage?.toFixed(2), '%');
            this.cpuSpeed = this.withUnit(cpuSpeed, ' GHz');
            this.cpuUsage = this.withUnit(cpuUsage?.toFixed(2), '%');
            this.memory = this.withUnit(memory, ' GB');
            this.memoryAppUsage = this.withUnit(memoryAppUsage?.toFixed(2), '%');
            this.memoryUsage = this.withUnit(memoryUsage?.toFixed(2), '%');
            if (networkQuality) this.networkQuality = networkQuality;
          }
        )
      );
      this.subscriptions.push(
        this.data.performanceService.networkSpeed$.subscribe(({ upload, download }) => {
          this.uploadSpeed = this.withUnit(upload?.toFixed(2), ' Mbps');
          this.downloadSpeed = this.withUnit(download?.toFixed(2), ' Mbps');
        })
      );
      this.data.performanceService.fetchHardwarePerformance(this.participant);
      this.data.performanceService.fetchNetworkSpeed(this.participant);
    }
  }

  refresh() {
    this.data.performanceService.fetchHardwarePerformance(this.participant);
    this.data.performanceService.fetchNetworkSpeed(this.participant);
  }

  public gradeFpsAsPerformance(browserAnimationFps) {
    if (browserAnimationFps > 29) {
      return 4;
    } else if (browserAnimationFps > 25) {
      return 3;
    } else if (browserAnimationFps > 20) {
      return 2;
    } else return 1;
  }
  close() {
    this.dialogRef.close();
  }

  private withUnit(value: number | string, unit?: string): string {
    return value ? `${value}${unit ? unit : ''}` : 'N/A';
  }
}
