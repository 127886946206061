import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { HostableVideo, PageOptions } from '../../hosting-interfaces';
import { HostingVideosDialogStore } from './hosting-videos-dialog.store';
import { Subject, debounceTime, defer, distinctUntilChanged, startWith, takeUntil } from 'rxjs';
import { OrPagerComponent } from '@openreel/ui/widgets/or-pager';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HostingVideoCardComponent } from '../../components/hosting-video-card/hosting-video-card.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { LetModule } from '@ngrx/component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

interface DialogData {
  selectedVideo: HostableVideo | null;
}

@Component({
  selector: 'openreel-hosting-videos-dialog',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    OrPagerComponent,
    HostingVideoCardComponent,
    LetModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  providers: [HostingVideosDialogStore],
  templateUrl: './hosting-videos-dialog.component.html',
  styleUrls: ['./hosting-videos-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostingVideosDialogComponent implements OnInit, OnDestroy {
  isLightThemed = true;
  isPopup = true;

  private readonly dialogStore = inject(HostingVideosDialogStore);
  private readonly dialogRef = inject(MatDialogRef);
  private readonly dialogData: DialogData = inject(MAT_DIALOG_DATA);

  vm$ = this.dialogStore.vm$;

  readonly selectedVideoControl = new FormControl<HostableVideo>(null, { nonNullable: false });

  readonly selectedVideo$ = defer(() =>
    this.selectedVideoControl.valueChanges.pipe(startWith(this.selectedVideoControl.value))
  );

  searchTextControl = new FormControl<string>('');

  private unsub$ = new Subject<void>();

  ngOnInit(): void {
    this.dialogStore.fetchVideos();
    if (this.dialogData?.selectedVideo) {
      this.selectedVideoControl.reset(this.dialogData.selectedVideo);
    }

    this.searchTextControl
        .valueChanges
        .pipe(
          takeUntil(this.unsub$),
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe(v => {
          this.dialogStore.setSearchText(v);
          this.dialogStore.fetchVideos();
        })
  }

  ngOnDestroy(): void {
      this.unsub$.next();
      this.unsub$.complete();
  }

  toggleVideoSelection(video: HostableVideo) {
    if (this.selectedVideoControl.value?.id == video.id) this.selectedVideoControl.setValue(null);
    else this.selectedVideoControl.setValue(video);

    if (this.selectedVideoControl.value?.id == this.dialogData?.selectedVideo?.id) this.selectedVideoControl.markAsPristine();
    else this.selectedVideoControl.markAsDirty();
  }

  loadPage(pageOptions: PageOptions) {
    this.dialogStore.setPageOptions(pageOptions);
    this.dialogStore.fetchVideos();
  }

  confirm() {
    this.dialogRef.close({ selectedVideo: this.selectedVideoControl.value });
  }
}
