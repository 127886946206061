import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { Observable } from 'rxjs';
import { VideoPlaybackData } from '../hosting-interfaces';

@Injectable({ providedIn: 'root' })
export class HostingVideoPlaybackService {
  constructor(private http: HttpClient) {}
  private baseUrl = commonenv.hostingPlaybackApiUrl;

  getVideoData(id: number, playbackDataAccessToken?: string): Observable<VideoPlaybackData> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: {
        ...(!!playbackDataAccessToken && { token: playbackDataAccessToken })
      },
      withCredentials: true,
    };

    return this.http.get<VideoPlaybackData>(`${this.baseUrl}hosting/videos/${id}/playback`, httpOptions);
  }

  getVideoPlaybackDataByToken(videoToken: string): Observable<VideoPlaybackData> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    };
    return this.http.get<VideoPlaybackData>(`${this.baseUrl}hosting/videos/${videoToken}/playback-data`, httpOptions);
  }
}
