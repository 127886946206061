import { z } from 'zod';
import { ProjectFontSchema } from './common.zod';

export const StyleSchema = z.object({
  id: z.string(),
  color: z.optional(z.string()),
  colorShade: z.optional(z.number()),
  font: z.optional(ProjectFontSchema),
  fontWeight: z.optional(z.number()),
  fontStyle: z.optional(z.enum(['normal', 'italic'])),
  fontSize: z.optional(z.number()),
  textAlign: z.optional(z.enum(['left', 'center', 'right'])),
  lineHeight: z.optional(z.number()),
  letterSpacing: z.optional(z.number()),
});
