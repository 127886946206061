import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { BehaviorSubject } from 'rxjs';
import { startWith, map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ROUTE_HOST } from '@openreel/frontend/common';
import { SearchService } from './search.service';
import { SearchResult, SearchResultType, SessionSearchResult, SessionType } from '@openreel/common';

@Component({
  selector: 'openreel-navigation-search',
  templateUrl: './navigation-search.component.html',
  styleUrls: ['./navigation-search.component.scss'],
})
export class NavigationSearchComponent implements OnInit {
  searchCtrl = new UntypedFormControl();
  searchResults$: BehaviorSubject<SearchResult[]>;

  constructor(private searchService: SearchService, private router: Router) {}

  ngOnInit() {
    this.searchResults$ = this.searchService.searchResults$;
    this.searchCtrl.valueChanges
      .pipe(
        startWith(''),
        // Start searching after 0.5 second of release
        debounceTime(500),
        distinctUntilChanged(),
        // characters minimum rule
        filter((text) => text?.length >= 1),
        map((text) => this.fetchResults(text))
      )
      .subscribe();
  }

  private fetchResults(value: string) {
    const filterValue = value.toLowerCase();
    this.searchService.search(filterValue);
  }

  searchOnFocus() {
    const searchStr = this.searchCtrl.value;
    if (searchStr?.length >= 1) {
      this.fetchResults(this.searchCtrl.value);
    }
  }

  displayWith(item) {
    return item ? item.name : '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const option = event.option.value as SearchResult;
    if (option) {
      // Until we convert all component router values to observables, we will open results in new windows
      let routeParts;
      switch (option.url.type) {
        case SearchResultType.project:
          routeParts = ['/projects', option.url.id];

          break;
        case SearchResultType.automation:
          routeParts = ['/automations', option.url.id];

          break;
        case SearchResultType.session:
          switch (option.url.sessionType) {
            case SessionType.Lite:
              routeParts = ['/capture-lite/', option.url.code];
              break;
            case SessionType.UGC:
              routeParts = ['/ugc-subject/', option.url.code];
              break;
            case SessionType.Pro:
            case SessionType.SelfRecord:
              routeParts = ['/director/session', option.url.id];
              break;
          }
          break;
        case SearchResultType.video:
          routeParts = ['/director/session', option.url.id];

          break;
        case SearchResultType.hostable:
          routeParts = [`/${ROUTE_HOST}/videos`, option.url.id];

          break;
        case SearchResultType.hub:
          routeParts = [`/${ROUTE_HOST}/hub`, option.url.id];

          break;
      }

      if (option.url.type === SearchResultType.session && !(option as SessionSearchResult).hasAccess) {
        return;
      }

      const url = this.router.createUrlTree(routeParts);
      window.open(url.toString(), '_blank');
    }
  }

  goToProject(projectId: number, sessionId: number) {
    this.router.navigate(['/projects', projectId], { queryParams: { 'focus-session': sessionId } });
  }
}
