export enum PermissionableEntity {
  Hostable = 'hosting_hostable',
  HostingHubs = 'hosting_hubs',
}

export enum PermissionedEntityType {
  User = 'user',
  Group = 'group',
}

export enum PermissionableEntityAction {
  Create = 'create',
  Update = 'update',
  Read = 'read',
  Delete = 'delete',
  AddCaption = 'add_caption',
  GetCaption = 'get_caption',
  UpdateCaption = 'update_caption',
  DeleteCaption = 'delete_caption',
  AddCta = 'add_cta',
  GetCta = 'get_cta',
  UpdateCta = 'update_cta',
  DeleteCta = 'delete_cta',
  AddThumbnail = 'add_thumbnail',
  GetThumbnail = 'get_thumbnail',
  DeleteThumbnail = 'delete_thumbnail',
  AddChapter = 'add_chapter',
  GetChapter = 'get_chapter',
  UpdateChapter = 'update_chapter',
  DeleteChapter = 'delete_chapter',

  AddLayout = 'add_layout',
  GetLayout = 'get_layout',
  UpdateLayout = 'update_layout',
  DeleteLayout = 'delete_layout',

  GetDownload = 'get_download',
  Download = 'download',
  ManageAccess = 'manage_access',
  TransferOwnership = 'transfer_ownership',
  AddAppearance = 'add_appearance',
  GetAppearance = 'get_appearance',
  UpdateAppearance = 'update_appearance',
  DeleteAppearance = 'delete_appearance',
}

export enum PermissionableEntityField {
  ReadTitle = 'read.title',
  UpdateTitle = 'update.title',
  ReadDescription = 'read.description',
  UpdateDescription = 'update.description',
  ReadAltText = 'read.altText',
  UpdateAltText = 'update.altText',
  ReadSlug = 'read.slug',
  UpdateSlug = 'update.slug',
  UpdateCommentsStatus = 'update.commentsStatus'
}

export const HostingPermissions = {
  ...PermissionableEntityAction,
  ...PermissionableEntityField
};

export enum EntityGroupsType {
  Hub = 'hub',
}
