import { Injectable } from '@angular/core';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { commonenv } from '../environments/environment';
import { WorkflowTemplateList } from '../workflows';
import { SuperProperties, EventPropertyValue, EventProperties, EventName } from '@openreel/creator/common';
import * as Sentry from '@sentry/angular';

@Injectable()
export class CreatorMixpanelService {
  private mixpanelInstance: Mixpanel;

  private isInitialized = false;

  init(): void {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;
    this.mixpanelInstance = mixpanel.init(commonenv.creatorMixpanelToken, {}, 'creator');
    this.setSuperProperty('data_source', 'frontend');
  }

  setSuperProperty(property: SuperProperties, value?: EventPropertyValue): void {
    const name = `*${property}`;
    if (!this.mixpanelInstance) {
      this.init();
    }

    if (value) {
      const obj = {};
      obj[name] = value;
      this.mixpanelInstance.register(obj);

      Sentry.setTag(property, String(value));
    } else {
      this.mixpanelInstance.unregister(name);
    }
  }

  logEvent(eventName: EventName, description: string, properties: EventProperties = {}): void {
    if (!this.mixpanelInstance) {
      this.init();
    }

    if (description) {
      properties = {
        ...properties,
        description,
      };
    }

    const eventProperties = {};
    if (properties) {
      for (const key of Object.keys(properties)) {
        eventProperties[`#${key}`] =
          key === 'index' && Number.isInteger(properties[key]) ? (properties[key] as number) + 1 : properties[key];
      }
    }

    this.mixpanelInstance.track(eventName, eventProperties);

    this.mixpanelInstance.people.increment('^total_event_count', 1);
    this.mixpanelInstance.people.set({ '^last_event': new Date() });
    this.setSuperProperty('session_event_count', this.incrementMixPanelSuperProperty('session_event_count'));

    Sentry.addBreadcrumb({
      message: description ? description : 'No message',
      category: eventName,
      data: eventProperties,
      level: 'log',
    });
  }

  identify(userId: number, analyticsId: string) {
    if (!this.mixpanelInstance) {
      this.init();
    }

    if (userId) {
      this.mixpanelInstance.identify(userId.toString());
      this.mixpanelInstance.people.set({ '^analytics_id': analyticsId });

      Sentry.setUser({ id: userId.toString() });
    } else {
      Sentry.setUser({ id: null });
    }
  }

  private incrementMixPanelSuperProperty(property: string): number {
    let value = this.mixpanelInstance.get_property(`*${property}`);
    if (value && typeof value === 'number') {
      value = value + 1;
    } else {
      value = 1;
    }
    return value;
  }

  static mixpanelTemplatePayload(template: WorkflowTemplateList) {
    return {
      template_id: template.id,
      template_name: template.name,
      layout_type: template.layoutType,
    };
  }
}
