import { AssetDto, CreateAssetRequest } from './assets.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AssetId } from '@openreel/creator/common';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { CaseConverterInterceptorSkipHeader } from '@openreel/frontend/common';
import { getMimeTypeFromExtension, splitNameAndExtension } from '@openreel/common';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}assets`,
    global: () => `${this.urls.base}/global`,
    byId: (assetId: AssetId) => `${this.urls.base}/${assetId}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  createAssetUrl(data: CreateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.base, data);
  }

  createGlobalAssetUrl(data: CreateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.global(), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  uploadAsset(signedUrl: string, file: File) {
    const [, extension] = splitNameAndExtension(file.name);

    return this.httpClient.put(signedUrl, file, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
        'Content-Type': getMimeTypeFromExtension(extension),
      }),
      reportProgress: true,
      observe: 'events',
    });
  }

  getAssetUrlById(assetId: AssetId) {
    return this.httpClient.get<AssetDto>(this.urls.byId(assetId));
  }
}
