import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Animation, convertToCanvasKitFonts } from '@openreel/lottie-parser';
import { SkottieCanvasKitAnimation } from './skottie-canvas-kit-animation';
import { CanvasKit, CanvasKitInitOptions } from './canvas-kit';
import { Subject, firstValueFrom } from 'rxjs';
import { SkottieCanvasKitRenderer } from './skottie-canvas-kit-renderer';
import { LayerStylesObjectFit } from '@openreel/creator/common';
import { FontDOMService } from '@openreel/frontend/common';

export declare function CanvasKitInit(opts: CanvasKitInitOptions): Promise<CanvasKit>;

export interface SkottieCanvasKitInitOptions {
  animationData: Animation;
  canvasEl: HTMLCanvasElement | OffscreenCanvas;
  isStatic: boolean;
  loop: boolean;
  initTime?: number;
  fitOptions: SkottieCanvasKitFitOptions;
}

export interface SkottieCanvasKitFitOptions {
  animationFit: Exclude<LayerStylesObjectFit, 'cover'>;
  hostAspectRatio?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SkottieCanvasKitService implements OnDestroy {
  public CanvasKit: CanvasKit;
  public renderer: SkottieCanvasKitRenderer;

  private canvasKitLoading: Subject<CanvasKit>;

  get isLoaded() {
    return !!this.CanvasKit;
  }

  constructor(
    private readonly zone: NgZone,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly fontDomService: FontDOMService,
  ) {
    this.loadCanvasKit();
  }

  async initCanvasKit(opts: SkottieCanvasKitInitOptions) {
    await this.loadCanvasKit();
    const fonts = convertToCanvasKitFonts(this.fontDomService.loadedFonts);
    const skottie = new SkottieCanvasKitAnimation(this.CanvasKit, this.renderer, fonts, opts);
    await skottie.loadAnimation(opts.initTime);
    return skottie;
  }

  async loadCanvasKit() {
    if (this.isLoaded) {
      return this.CanvasKit;
    }

    if (this.canvasKitLoading) {
      return firstValueFrom(this.canvasKitLoading);
    }

    this.canvasKitLoading = new Subject();

    this.zone.run(() => {
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/assets/ui/canvaskit/canvaskit.js';
      script.onload = () => {
        this.initCanvasKitWasm();
      };
      this.document.head.appendChild(script);
    });

    return firstValueFrom(this.canvasKitLoading);
  }

  cleanup() {
    if (this.isLoaded) {
      this.renderer.destroyAll();
      this.CanvasKit = null;
      this.initCanvasKitWasm();
    }
  }

  private initCanvasKitWasm() {
    CanvasKitInit({
      locateFile: () => '/assets/ui/canvaskit/canvaskit.wasm',
    }).then((wasm) => {
      this.CanvasKit = wasm;
      this.renderer = new SkottieCanvasKitRenderer(this.CanvasKit);
      this.canvasKitLoading.next(wasm);
    });
  }

  ngOnDestroy() {
    this.renderer.destroyAll();
  }
}
