import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovableTypes, ApprovalStatuses, ApprovalWorkflow, ApprovalWorkflowVideosCount, CreateUpdateApprovalWorkflowDto } from '@openreel/common';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApprovalEvent, CreateApprovalRequestDto, HostingApproval, UpdateApprovalRequestDto } from '../../interfaces/hosting-approval.interface';
import { HostableApprovalListResponse } from './hostable-approval-list-response.interface';

@Injectable({
  providedIn: 'root',
})
export class HostableApprovalService {
  private readonly urls = {
    approval: commonenv.nextGenApiUrl + 'approval',
    approvalWorkflows: commonenv.nextGenApiUrl + 'approval-workflows',
  };

  constructor(private readonly http: HttpClient) {}

  submitForApproval(dto: CreateApprovalRequestDto): Observable<HostingApproval> {
    return this.http.post<HostingApproval>(`${this.urls.approval}`, dto)
  }

  cancelApproval(id: number, dto: UpdateApprovalRequestDto): Observable<HostingApproval> {
    return this.http.put<HostingApproval>(`${this.urls.approval}/${id}`, dto)
  }

  getPendingApprovalVideosCount(status: ApprovalStatuses): Observable<number> {
    const params = new HttpParams().set('approvalStatus', status);
    return this.http.get<number>(`${this.urls.approval}/count`, { params });
  }

  getSubmittedApprovalVideosCount(status: ApprovalStatuses): Observable<number> {
    const params = new HttpParams().set('approvalStatus', status);
    return this.http.get<number>(`${this.urls.approval}/submitted/count`, { params });
  }

  getApprovalVideo(id: number): Observable<HostingApproval> {
    return this.http.get<HostingApproval>(`${this.urls.approval}/${id}`).pipe(tap((video) => console.log({ video })));
  }

  getPendingApprovalVideos(
    status: ApprovalStatuses,
    pageNumber: number,
    pageSize: number
  ): Observable<HostableApprovalListResponse> {
    const params = new HttpParams()
      .set('approvalStatus', status)
      .set('page', pageNumber.toString())
      .set('perPage', pageSize.toString());

    return this.http.get<HostableApprovalListResponse>(`${this.urls.approval}`, { params });
  }

  getSubmittedApprovalVideos(
      status: ApprovalStatuses,
      pageNumber: number,
      pageSize: number
  ): Observable<HostableApprovalListResponse> {
    const params = new HttpParams()
        .set('approvalStatus', status)
        .set('page', pageNumber.toString())
        .set('perPage', pageSize.toString());

    return this.http.get<HostableApprovalListResponse>(`${this.urls.approval}/submitted`, { params });
  }

  approve(id: HostingApproval['id']): Observable<HostingApproval> {
    return this.http.post<HostingApproval>(`${this.urls.approval}/${id}/approve`, null);
  }

  reject(id: HostingApproval['id']): Observable<HostingApproval> {
    return this.http.post<HostingApproval>(`${this.urls.approval}/${id}/reject`, null);
  }

  fetchApprovalWorkflows(): Observable<ApprovalWorkflow[]> {
    return this.http.get<ApprovalWorkflow[]>(`${this.urls.approvalWorkflows}`);
  }

  fetchApprovalWorkflow(id: number): Observable<ApprovalWorkflow> {
    return this.http.get<ApprovalWorkflow>(`${this.urls.approvalWorkflows}/${id}`);
  }

  createApprovalWorkflow(dto: CreateUpdateApprovalWorkflowDto): Observable<ApprovalWorkflow> {
    return this.http.post<ApprovalWorkflow>(`${this.urls.approvalWorkflows}`, dto);
  }

  updateApprovalWorkflow(dto: CreateUpdateApprovalWorkflowDto): Observable<ApprovalWorkflow> {
    return this.http.put<ApprovalWorkflow>(`${this.urls.approvalWorkflows}/${dto.id}`, dto);
  }

  deleteApprovalWorkflow(id: number): Observable<void> {
    return this.http.delete<void>(`${this.urls.approvalWorkflows}/${id}`);
  }

  fetchVideosCountOfApprovalWorkflow(approvalWorkflowId: number): Observable<ApprovalWorkflowVideosCount> {
    return this.http.get<ApprovalWorkflowVideosCount>(`${this.urls.approvalWorkflows}/${approvalWorkflowId}/videos/count`)
  }

  fetchApprovableHistory(approvableId: number, approvableType: ApprovableTypes): Observable<ApprovalEvent[]> {
    return this.http.get<ApprovalEvent[]>(`${this.urls.approval}/${approvableId}/${approvableType}/history`);
  }
}
