import { SOUNDTRACK_TRANSITION_TIME, SectionId } from "../interfaces";

type SoundtrackTimingType =
  'start-intro'
  | 'start-main'
  | 'start-outro'
  | 'intro'
  | 'main'
  | 'outro'
  | 'intro-main'
  | 'main-outro'
  | 'intro-outro'
  | 'end-intro'
  | 'end-main'
  | 'end-outro';

export interface SoundtrackTimingNoVolume {
  type: SoundtrackTimingType;
  startAt: number;
  endAt: number;
}

export interface SoundtrackTiming extends SoundtrackTimingNoVolume {
  startVolume: number;
  endVolume: number;
}

interface SectionTimings {
  startAt: number;
  endAt: number;
  id: string;
}

interface VolumeSettings {
  introVolume: number;
  mainVolume: number;
  outroVolume: number;
}


export function processSoundtrackTimings(volumeSettings: VolumeSettings, sections: SectionTimings[]): SoundtrackTiming[] {
  const timings: SoundtrackTimingNoVolume[] = [];

  const intro = sections.find((section) => section.id === SectionId.Intro);
  const mains = sections.filter((section) => section.id !== SectionId.Intro && section.id !== SectionId.Outro);
  const outro = sections.find((section) => section.id === SectionId.Outro);

  if (intro) {
    timings.push({
      type: 'intro',
      startAt: intro.startAt,
      endAt: intro.endAt,
    });
  }

  if (mains?.length) {
    if (intro) {
      insertTransition(timings, 'intro-main');
    }

    timings.push({
      type: 'main',
      startAt: mains[0].startAt + (intro ? SOUNDTRACK_TRANSITION_TIME / 2 : 0),
      endAt: mains[mains.length - 1].endAt,
    });
  }

  if (outro) {
    if (mains?.length) {
      insertTransition(timings, 'main-outro');
    } else if (intro) {
      insertTransition(timings, 'intro-outro');
    }

    timings.push({
      type: 'outro',
      startAt: outro.startAt + ((mains?.length || intro) ? SOUNDTRACK_TRANSITION_TIME / 2 : 0),
      endAt: outro.endAt,
    });
  }

  timings[0].startAt += SOUNDTRACK_TRANSITION_TIME;
  timings[timings.length - 1].endAt -= SOUNDTRACK_TRANSITION_TIME;

  timings.unshift({
    type: `start-${timings[0].type}` as SoundtrackTimingType,
    startAt: 0,
    endAt: SOUNDTRACK_TRANSITION_TIME,
  });

  timings.push({
    type: `end-${timings[timings.length - 1].type}` as SoundtrackTimingType,
    startAt: timings[timings.length - 1].endAt,
    endAt: timings[timings.length - 1].endAt + SOUNDTRACK_TRANSITION_TIME,
  });

  return timings.map((timing) => {
    const volumeTiming: SoundtrackTiming = {
      ...timing,
      startVolume: 0,
      endVolume: 0,
    }

    switch (timing?.type) {
      case 'intro':
        volumeTiming.endVolume = volumeTiming.startVolume = volumeSettings.introVolume;
        break;
      case 'main':
        volumeTiming.endVolume = volumeTiming.startVolume = volumeSettings.mainVolume;
        break;
      case 'outro':
        volumeTiming.endVolume = volumeTiming.startVolume = volumeSettings.outroVolume;
        break;
      case 'start-intro':
        volumeTiming.startVolume = 0;
        volumeTiming.endVolume = volumeSettings.introVolume;
        break;
      case 'start-main':
        volumeTiming.startVolume = 0;
        volumeTiming.endVolume = volumeSettings.mainVolume;
        break;
      case 'start-outro':
        volumeTiming.startVolume = 0;
        volumeTiming.endVolume = volumeSettings.outroVolume;
        break;
      case 'end-intro':
        volumeTiming.startVolume = volumeSettings.introVolume;
        volumeTiming.endVolume = 0;
        break;
      case 'end-main':
        volumeTiming.startVolume = volumeSettings.mainVolume;
        volumeTiming.endVolume = 0;
        break;
      case 'end-outro':
        volumeTiming.startVolume = volumeSettings.outroVolume;
        volumeTiming.endVolume = 0;
        break;
      case 'intro-main':
        volumeTiming.startVolume = volumeSettings.introVolume;
        volumeTiming.endVolume = volumeSettings.mainVolume;
        break;
      case 'main-outro':
        volumeTiming.startVolume = volumeSettings.mainVolume;
        volumeTiming.endVolume = volumeSettings.outroVolume;
        break;
      case 'intro-outro':
        volumeTiming.startVolume = volumeSettings.introVolume;
        volumeTiming.endVolume = volumeSettings.outroVolume;
        break;
    }

    return volumeTiming;
  });
}


function insertTransition(timings: Partial<SoundtrackTiming>[], type: SoundtrackTimingType) {
  timings[timings.length - 1].endAt -= SOUNDTRACK_TRANSITION_TIME / 2; // what if endAt is less than 0

  timings.push({
    type,
    startAt: timings[timings.length - 1].endAt,
    endAt: timings[timings.length - 1].endAt + SOUNDTRACK_TRANSITION_TIME,
  });
}
