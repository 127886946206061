import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { ASSStyle } from '@openreel/transcription';
import { CaptionsDto } from './interfaces/captions.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CaptionsApiService {
  private urls = {
    byIdCaptions: (projectId) => `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/captions`,
    byProjectIdToggleBurnIn: (projectId: number) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/captions/burn-in`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  updateCaptionGlobalStyle(projectId: number, data: ASSStyle) {
    return this.httpClient.put<CaptionsDto>(this.urls.byIdCaptions(projectId) + '/style', data);
  }

  toggleBurnIn(projectId: number, burnIn: boolean) {
    return this.httpClient.put(this.urls.byProjectIdToggleBurnIn(projectId), { burnIn });
  }
}
