import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { SalesforceChatComponent } from './salesforce-chat.component';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule
  ],
  exports: [SalesforceChatComponent],
  declarations: [SalesforceChatComponent],
  providers: [],
})
export class SalesforceChatModule { }
