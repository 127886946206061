import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { SharePermissionedGroup } from '../../../../../models/object-user.interface';
import { PermissionedListItemComponent } from '../permissioned-list-item/permissioned-list-item.component';

@Component({
  selector: 'openreel-groups-list-item',
  templateUrl: './groups-list-item.component.html',
  styleUrls: ['./groups-list-item.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PermissionedListItemComponent, FlexLayoutModule, MatIconModule],
})
export class GroupsListItemComponent {
  @Input() readonly group: SharePermissionedGroup | null = null;
}
