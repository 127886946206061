import { Directive, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith, switchMap, timer } from 'rxjs';
import { HostingAnalyticsService } from '../hosting-analytics.service';
import { HostingAnalyticsEvents } from '../hosting-analytics-events';

@Directive({
  selector: '[captureIdleOnRoute]',
  standalone: true,
})
export class IdleOnRouteEventCaptureDirective implements OnDestroy {
  private _router = inject(Router);
  private _hostingAnalyticsService = inject(HostingAnalyticsService);

  private _routeSubs = this._router.events
    .pipe(
      filter((routeEvent) => routeEvent instanceof NavigationEnd),
      map((routeEvent: NavigationEnd) => routeEvent.url),
      startWith(this._router.url),
      switchMap((url) => timer(30000).pipe(map((_) => url)))
    )
    .subscribe(url => {
      this._hostingAnalyticsService.logEvent(HostingAnalyticsEvents.idle({ url }));
    });


  ngOnDestroy(): void {
    this._routeSubs.unsubscribe();
  }
}
