import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { PermissionableEntity } from '@openreel/common';
import {
  EntityRole,
  SharePermissionedEntityBase,
  TeamPermissionableEntity,
  TeamService,
} from '@openreel/frontend/common';
import { HostableVideo, HostingHub, HostingService } from '@openreel/frontend/common/hosting';
import {
  EntityObjectPermissionRequest,
  EntityObjectPermissionUpdateRequest,
  TransferOwnershipRequest,
  TransferRoleResponse,
} from '@openreel/frontend/common/services/entity-permissions/entity-object-permission-request.interface';
import { EntityPermissionsService } from '@openreel/frontend/common/services/entity-permissions/entity-permissions.service';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  PermissionedEntities,
  PermissionedEntityType,
  SharePermissionedGroup,
  SharePermissionedUser,
} from '../../../models/object-user.interface';
import { ShareModalData } from './share-modal-data.interface';
import { HostablePermissionTypes } from '../../constants';

interface HostingShareState {
  objectUsers: SharePermissionedUser[];
  objectGroups: SharePermissionedGroup[];
  objectUsersLoaded: boolean;
  entityRoles: EntityRole[];
  shareModalData: ShareModalData | null;
  accessType: HostablePermissionTypes;
  permissionableEntities: TeamPermissionableEntity[];
  saving: boolean;
  requirePassword: boolean;
  passwordSubmitted: boolean;
}

const initialState: HostingShareState = {
  objectUsers: [],
  objectGroups: [],
  objectUsersLoaded: false,
  entityRoles: [],
  shareModalData: null,
  accessType: HostablePermissionTypes.Private,
  permissionableEntities: [],
  saving: false,
  requirePassword: false,
  passwordSubmitted: true,
};

@Injectable({
  providedIn: 'root',
})
export class HostingShareComponentStore extends ComponentStore<HostingShareState> {
  readonly shareModalData$ = this.select((state) => state.shareModalData);
  readonly permissionableEntities$ = this.select((state) => state.permissionableEntities);
  readonly objectUsers$ = this.select((state) => state.objectUsers);
  readonly objectGroups$ = this.select((state) => state.objectGroups);
  readonly objectUsersLoaded$ = this.select((state) => state.objectUsersLoaded);
  readonly objectRoles$ = this.select((state) => state.entityRoles);
  readonly canManageRolesIds$ = this.select((state) =>
    state.entityRoles.filter((role) => role.canManage).map((role) => role.id)
  );
  readonly accessType$ = this.select((state) => state.accessType);
  readonly saving$ = this.select((state) => state.saving);
  readonly passwordValid$ = this.select((state) =>
    (state.requirePassword ? state.passwordSubmitted : true)
  );

  // need to do this temporarily till we figure out a better method of identifying roles
  readonly getObjectRoleByName$ = (roleName: string) =>
    this.select((state) => state.entityRoles.find((role) => role.name.toLowerCase().includes(roleName.toLowerCase())));

  readonly getCurrentOwner$ = this.select((state) => state.objectUsers.find((objectUser) => objectUser.role.isOwner));

  readonly setShareModalData = this.updater((state, hostableDetails: ShareModalData) => ({
    ...state,
    shareModalData: hostableDetails,
  }));

  readonly setRequirePassword = this.updater((state, requirePassword: boolean) => ({
    ...state,
    requirePassword,
  }));

  readonly setPasswordSubmitted = this.updater((state, passwordSubmitted: boolean) => ({
    ...state,
    passwordSubmitted,
  }));

  readonly setAccessType = this.updater((state, accessType: HostablePermissionTypes) => ({
    ...state,
    accessType,
  }));

  readonly setPermissionableEntities = this.updater((state, permissionableEntities: TeamPermissionableEntity[]) => ({
    ...state,
    permissionableEntities,
  }));

  readonly setPermissionedEntities = this.updater((state, entities: PermissionedEntities) => ({
    ...state,
    objectUsers: entities?.users ?? [],
    objectGroups: entities?.groups ?? [],
    objectUsersLoaded: true,
  }));

  readonly deleteObjectUserById = this.updater((state, userId: SharePermissionedUser['id']) => ({
    ...state,
    objectUsers: state.objectUsers.filter((objectUser) => objectUser.id !== userId),
  }));

  readonly deleteGroupById = this.updater((state, groupId: SharePermissionedGroup['id']) => ({
    ...state,
    objectGroups: state.objectGroups.filter((objectGroup) => objectGroup.id !== groupId),
  }));

  readonly setObjectUsersLoading = this.updater((state, loaded: boolean) => ({
    ...state,
    objectUsersLoaded: loaded,
  }));

  readonly setObjectRoles = this.updater((state, objectRoles: EntityRole[]) => ({
    ...state,
    entityRoles: objectRoles,
  }));

  readonly addObjectUser = this.updater((state, objectUser: SharePermissionedUser) => ({
    ...state,
    objectUsers: [...state.objectUsers, objectUser],
  }));

  readonly editObjectUser = this.updater((state, updatedObjectUser: SharePermissionedEntityBase | SharePermissionedUser) => ({
    ...state,
    objectUsers: state.objectUsers.map((user) => (
      user.id === updatedObjectUser.id ? { ...user, ...updatedObjectUser } : user)
    ),
  }));

  readonly addObjectGroup = this.updater((state, group: SharePermissionedGroup) => ({
    ...state,
    objectGroups: [...state.objectGroups, group],
  }));

  readonly editObjectGroup = this.updater((state, updatedObjectGroup: SharePermissionedEntityBase | SharePermissionedGroup) => ({
    ...state,
    objectGroups: state.objectGroups.map((group) => (
      group.id === updatedObjectGroup.id ? { ...group, ...updatedObjectGroup } : group)
    ),
  }));

  readonly setSaving = this.updater((state, saving: boolean) => ({
    ...state,
    saving,
  }));

  constructor(
    private readonly teamService: TeamService,
    private readonly entityPermissionsService: EntityPermissionsService,
    private readonly hostingService: HostingService,
    private readonly toastr: ToastrService,
    private readonly clipboard: Clipboard
  ) {
    super(initialState);
  }

  readonly getPermissionableEntities = this.effect((searchQuery$: Observable<string>) =>
    searchQuery$.pipe(
      withLatestFrom(this.objectUsers$),
      switchMap(([searchQuery, _]) =>
        this.teamService.getPermissionableEntities(30, 0, searchQuery).pipe(
          tap((permissionableEntities) => {
            this.setPermissionableEntities(permissionableEntities);
          }),
          catchError(() => {
            this.setPermissionableEntities([]);

            return EMPTY;
          })
        )
      )
    )
  );

  readonly getPermissionedObjects = this.effect((idEntity$: Observable<[number, PermissionableEntity]>) =>
    idEntity$.pipe(
      switchMap(([id, entity]) => {
        this.setObjectUsersLoading(false);

        return this.entityPermissionsService.getPermissionedEntitiesForObject(entity, id).pipe(
          tap((users) => {
            this.setPermissionedEntities(users)
          }),
          catchError(() => {
            this.setPermissionedEntities({
              users: [],
              groups: [],
            });

            return EMPTY;
          })
        );
      })
    )
  );

  readonly getEntityRoles = this.effect((idEntity$: Observable<[number, PermissionableEntity]>) =>
    idEntity$.pipe(
      switchMap(([id, entity]) =>
        this.entityPermissionsService
          .getEntityRoles({
            entity,
            entityId: id,
          })
          .pipe(
            tap((objectRoles) => {
              this.setObjectRoles(objectRoles);
            }),
            catchError(() => {
              this.setObjectRoles([]);

              return EMPTY;
            })
          )
      )
    )
  );

  readonly addPermissionedEntityToObject = this.effect(($: Observable<EntityObjectPermissionRequest>) =>
    $.pipe(
      switchMap((data) =>
        this.entityPermissionsService.addPermissionedEntityToObject(data).pipe(
          tap((entity) => {
            if (data.permissionedEntityType === 'user') {
              this.addObjectUser(entity as SharePermissionedUser);
            } else if (data.permissionedEntityType === 'group') {
              this.addObjectGroup(entity as SharePermissionedGroup);
            }
          }),
          catchError((error: Error) => {
            this.toastr.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  readonly deleteUserFromObject = this.effect((data$: Observable<Omit<EntityObjectPermissionRequest, 'roleId'>>) =>
    data$.pipe(
      switchMap((data) =>
        this.entityPermissionsService.deletePermissionedEntityFromObject(data).pipe(
          tap(() => {
            if (data.permissionedEntityType === 'user') {
              this.deleteObjectUserById(data.permissionedEntityId);
            } else if (data.permissionedEntityType === 'group') {
              this.deleteGroupById(data.permissionedEntityId);
            }
          }),
          catchError((error: Error) => {
            this.toastr.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  readonly updatePassword = this.effect((password$: Observable<string>) => password$.pipe(
    tap(() => {
      this.setSaving(true);
    }),
    withLatestFrom(this.shareModalData$),
    switchMap(([password, shareModalData]) => {
      const request$: Observable<HostingHub | HostableVideo> = shareModalData.type === 'hub'
        ? this.hostingService.updateHubPassword(shareModalData.id, password)
        : this.hostingService.putVideo(shareModalData.id, {
          password,
          permission: password ? HostablePermissionTypes.Password : HostablePermissionTypes.Public,
        });

      return request$.pipe(
        tap(() => {
          this.patchState(state => ({
            passwordSubmitted: true,
            shareModalData: {
              ...state.shareModalData,
              permission: HostablePermissionTypes.Password,
            },
          }));

          this.toastr.success('Password changed successfully');
        }),
        finalize(() => this.setSaving(false)),
      );
    }),
  ));

  readonly updatePermission = this.effect((permission$: Observable<HostablePermissionTypes>) => permission$.pipe(
    tap(() => {
      this.setSaving(true);
    }),
    withLatestFrom(this.shareModalData$, this.accessType$),
    switchMap(([permission, shareModalData, accessType]) => {
      const request$: Observable<HostingHub | HostableVideo> = shareModalData.type === 'hub'
        ? this.hostingService.updateHub(shareModalData.id, { permission })
        : this.hostingService.putVideo(shareModalData.id, {
          permission,
        });

      return request$.pipe(
        tap((response) => {
          this.patchState(state => ({
            shareModalData: {
              ...state.shareModalData,
              permission: accessType,
              shareUrl: response.shareUrl,
            },
          }));
        }),
        finalize(() => this.setSaving(false)),
      );
    }),
  ));

  updateUserObjectRole(
    data: EntityObjectPermissionUpdateRequest,
    permissionedEntityType: PermissionedEntityType,
  ): Observable<SharePermissionedUser | SharePermissionedGroup> {
    return this.entityPermissionsService.updateObjectRole(data).pipe(
      tap((permissionedObject) => {
        if (permissionedEntityType === 'user') {
          this.editObjectUser(permissionedObject as SharePermissionedUser);
        } else if (permissionedEntityType === 'group') {
          this.editObjectGroup(permissionedObject as SharePermissionedGroup);
        }
      }),
      catchError((error: Error) => {
        this.toastr.error(error.message);

        return EMPTY;
      }),
    );
  }

  transferOwnership(data: TransferOwnershipRequest, entity: PermissionableEntity): Observable<TransferRoleResponse> {
    const { entityId, fromUserId, toUserId, setfromUserToRoleId } = data;

    return this.entityPermissionsService
      .transferRole({ entity, entityId }, { fromUserId, toUserId, setfromUserToRoleId })
      .pipe(
        tap(({ fromUserRole, newUserRole }) => {
          this.editObjectUser(fromUserRole);
          this.editObjectUser(newUserRole);
        }),
        catchError((error: Error) => {
          this.toastr.error(error.message);

          return EMPTY;
        })
      );
  }

  copyEntityLink = this.effect(($: Observable<void>) =>
    $.pipe(
      withLatestFrom(this.shareModalData$),
      tap(([_, shareModalData]) => {
        const getVideoLink = (): string => shareModalData.shareUrl;

        this.clipboard.copy(getVideoLink());
      })
    )
  );
}
