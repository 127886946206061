import {
  RemoveAiVideo,
  WorkflowProjectCreatedDto,
} from './workflow-project.interfaces';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkflowDataDto } from '@openreel/creator/common';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { CreateAiWorkflowProjectRequest, WorkflowProjectCanHaveAiDto } from './workflow-ai.interfaces';

@Injectable({
  providedIn: 'root',
})
export class WorkflowAiService {
  private urls = {
    removeAiVideo: (projectId: number) => `${commonenv.nextGenApiUrl}workflows/ai/${projectId}/remove-ai-video`,
    canHaveAi: `${commonenv.nextGenApiUrl}workflows/ai/supports-ai`,
    createAi: `${commonenv.nextGenApiUrl}workflows/ai/create-ai`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  canHaveAi(captureProjectId: number) {
    const params = new HttpParams().set('captureProjectId', captureProjectId.toString());
    return this.httpClient.get<WorkflowProjectCanHaveAiDto>(this.urls.canHaveAi, { params });
  }

  removeAiVideo(creatorProjectId: number, data: RemoveAiVideo) {
    return this.httpClient.post<WorkflowDataDto>(this.urls.removeAiVideo(creatorProjectId), data);
  }

  createAi(data: CreateAiWorkflowProjectRequest) {
    return this.httpClient.post<WorkflowProjectCreatedDto[]>(this.urls.createAi, data);
  }
}
