import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { SALESFORCE_CHAT_ID } from './salesforce-chat.token';
import { SalesforceChatService } from './salesforce-chat.service';
import { Cleanupable } from '@openreel/frontend/common';

@Component({
  selector: 'openreel-salesforce-chat',
  templateUrl: 'salesforce-chat.component.html',
  styleUrls: ['salesforce-chat.component.scss'],
})
export class SalesforceChatComponent extends Cleanupable implements AfterViewInit {
  @ViewChild('online', { read: ElementRef })
  public online: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private window = window as any;
  private scriptUrl = 'https://c.la1-core1.sfdc-lywfpd.salesforceliveagent.com/content/g/js/55.0/deployment.js';
  private chatLink = 'https://d.la1-core1.sfdc-lywfpd.salesforceliveagent.com/chat';
  private isBrowser = isPlatformBrowser(this.platformId);
  private liveAgent;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(SALESFORCE_CHAT_ID) private chatId: string[],
    private readonly salesforceChatService: SalesforceChatService
  ) {
    super();
    this.subscriptions.push(
      this.salesforceChatService.openChatWindow$.subscribe(() => {
        this.startChat();
      })
    );
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      return;
    }
    this.addScript();
  }

  startChat() {
    this.liveAgent.startChat(this.chatId[0]);
  }

  addScript() {
    const h =
      document.head ||
      document.getElementsByTagName('head')[0] ||
      // document.querySelector('head') ||
      document.body;

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = this.scriptUrl;

    s.onload = () => {
      this.scriptOnLoad();
    };
    h.appendChild(s);
  }

  scriptOnLoad() {
    this.window = window as any;
    this.liveAgent = this.window.liveagent;

    this.liveAgent.init(this.chatLink, this.chatId[1], this.chatId[2]);

    if (!this.window._laq) {
      this.window._laq = [];
    }
    this.window._laq.push(() => {
      this.liveAgent.showWhenOnline(this.chatId[0], this.online.nativeElement);
      // Only if we got rid of Openreel bot
      // this.liveAgent.showWhenOffline(this.chatId, this.offline.nativeElement);
    });
  }
}
