import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export class LetContext<T> {
  constructor(private readonly directive: LetDirective<T>) {}

  get openreelLet(): T {
    return this.directive.openreelLet;
  }
}

/**
 * Works like *ngIf directive but does not have a condition
 * Use it to declare the result of pipes calculation
 * (i. e. async pipe)
 */
@Directive({
  selector: '[openreelLet]',
  standalone: true,
})
export class LetDirective<T> {
  @Input() readonly openreelLet: T;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<LetContext<T>>
  ) {
    this.viewContainer.createEmbeddedView(this.templateRef, new LetContext<T>(this));
  }
}
