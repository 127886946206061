import { cloneDeep, sortBy } from 'lodash';
import { Asset, ZoomAndPanEvent } from '../interfaces';
import { easeInOutCubic, easeOutExpo } from './math.helpers';
import { ZOOM_AND_PAN_SPEED } from '../constants';
import { convertOriginalTimeToUITime, getAssetFinalTrims } from './trimmer.helpers';
import { invertTimeRanges } from '@openreel/common';
import { getAssetDuration } from './workflow-duration.helpers';

export function removeTrimmedZoomAndPans(asset: Asset) {
  const { zoomPans } = asset;

  if (!zoomPans || zoomPans.length === 0) {
    return;
  }

  // NOTE: finalCuts are the ranges we GET RID OF
  const finalCuts = invertTimeRanges(getAssetDuration(asset), getAssetFinalTrims(asset));
  const sortedCuts = sortBy(finalCuts, 'from');

  // Go over each zoom and pan and reduce its duration to the new duration
  const zoomPanNewDurations = zoomPans.map((zp) => {
    const from = zp.startTransition.startAt;
    const to = zp.endTransition.startAt;
    let duration = to - from;

    for (const cut of sortedCuts) {
      if (cut.to < from || cut.from > to) {
        continue;
      }

      if (cut.from <= from && cut.to >= to) {
        duration = 0;
        break;
      }

      if (cut.from >= from && cut.to <= to) {
        duration -= cut.to - cut.from;
      } else if (cut.from < from && cut.to > from) {
        duration -= cut.to - from;
      } else if (cut.from < to && cut.to > to) {
        duration -= to - cut.from;
      }
    }

    return duration;
  });

  asset.zoomPans = zoomPans.filter((_, i) => zoomPanNewDurations[i] >= ZOOM_AND_PAN_SPEED.SLOW);
}

export function convertZoomAndPansToUiTime(asset: Asset) {
  const zoomAndPan = (asset.zoomPans || []).map((zoomPan) => {
    const start = convertOriginalTimeToUITime(
      zoomPan.startTransition.startAt,
      getAssetDuration(asset),
      asset.textCuts,
      asset.trim
    );
    const end = convertOriginalTimeToUITime(
      zoomPan.endTransition.startAt,
      getAssetDuration(asset),
      asset.textCuts,
      asset.trim
    );

    const newZoomPan = cloneDeep(zoomPan);
    newZoomPan.startTransition.startAt = start;
    newZoomPan.endTransition.startAt = end;
    return newZoomPan;
  });

  return zoomAndPan;
}

export function processZoomAndPanEvents(asset: Asset): ZoomAndPanEvent[] {
  const sortedZoomPans = convertZoomAndPansToUiTime(asset);
  sortedZoomPans.sort((a, b) => a.startTransition.startAt - b.startTransition.startAt);

  // create zoom events
  const zoomPanEvents: ZoomAndPanEvent[] = [];
  sortedZoomPans.forEach((zoomPan) => {
    zoomPanEvents.push({
      x: zoomPan.point.x,
      y: zoomPan.point.y,
      startZoom: 1,
      endZoom: zoomPan.amount / 100,
      timeMs: zoomPan.startTransition.startAt,
      transition: {
        duration: zoomPan.startTransition.duration,
        type: zoomPan.startTransition.timingFunction,
      },
    });
    zoomPanEvents.push({
      x: zoomPan.point.x,
      y: zoomPan.point.y,
      startZoom: zoomPan.amount / 100,
      endZoom: 1,
      timeMs: zoomPan.endTransition.startAt,
      transition: {
        duration: zoomPan.endTransition.duration,
        type: zoomPan.endTransition.timingFunction,
      },
    });
  });

  // find overlaping events
  for (let i = 0; i < zoomPanEvents.length - 1; i++) {
    const timeDelta = zoomPanEvents[i].timeMs + zoomPanEvents[i].transition.duration - zoomPanEvents[i + 1].timeMs;
    if (timeDelta > 0) {
      const zoomDelta = zoomPanEvents[i].startZoom - zoomPanEvents[i].endZoom;

      const x = timeDelta / zoomPanEvents[i].transition.duration;
      let y: number;
      if (zoomPanEvents[i].transition.type === 'linear') {
        y = x;
      } else if (zoomPanEvents[i].transition.type === 'easeOutExpo') {
        y = easeOutExpo(x);
      } else if (zoomPanEvents[i].transition.type === 'easeInOutCubic') {
        y = easeInOutCubic(x);
      }

      zoomPanEvents[i].actualEndZoom = zoomPanEvents[i].startZoom - zoomDelta * (1 - y);
    }
  }

  return zoomPanEvents;
}
