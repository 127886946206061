import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cleanupable } from '@openreel/frontend/common';

import { SharePermissionedUser } from '../../../../../models/object-user.interface';

@Component({
  selector: 'openreel-users-list-item',
  templateUrl: './users-list-item.component.html',
  styleUrls: ['./users-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListItemComponent extends Cleanupable {
  @Input() readonly loggedInUserId: number;
  @Input() readonly user: SharePermissionedUser | null = null;
}
