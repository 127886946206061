import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsersListModule } from '../users-list/users-list.module';
import { AccessSettingsComponent } from './access-settings.component';
import { AvatarModule } from 'ngx-avatar';
import { LetDirective } from '@openreel/frontend/common/directives/let.directive';

@NgModule({
  declarations: [AccessSettingsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    UsersListModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    AvatarModule,
    LetDirective,
  ],
  exports: [AccessSettingsComponent],
})
export class AccessSettingsModule {}
