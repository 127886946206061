import * as Interfaces from './workflow-template-builders.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { AssetDto } from '../assets/assets.interfaces';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';
import { getMimeTypeFromExtension, splitNameAndExtension } from '@openreel/common';

@Injectable({
  providedIn: 'root',
})
export class TemplateBuildersService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}workflows/templates-builders`,
    byId: (id: number) => `${commonenv.nextGenApiUrl}workflows/templates-builders/${id}`,
    blueprint: (id: number) => `${commonenv.nextGenApiUrl}workflows/templates-builders/${id}/blueprint`,
    upgrade: `${commonenv.nextGenApiUrl}workflows/templates-builders/upgrade`,
    duplicate: (id: number) => `${commonenv.nextGenApiUrl}workflows/templates-builders/${id}/duplicate`,
    createAsset: `${commonenv.nextGenApiUrl}workflows/templates-builders/create-asset`,
    saveAll: `${commonenv.nextGenApiUrl}workflows/templates-builders/`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getBuilders() {
    return this.httpClient.get<Interfaces.TemplateBuilderListEntity[]>(this.urls.base);
  }

  getBuilderById(id: number) {
    return this.httpClient.get<Interfaces.TemplateBuilderEntity>(this.urls.byId(id));
  }

  create() {
    return this.httpClient.post<Interfaces.TemplateBuilderEntity>(this.urls.base, {});
  }

  duplicate(id: number) {
    return this.httpClient.post<Interfaces.TemplateBuilderEntity>(this.urls.duplicate(id), {});
  }

  update(id: number, data: Interfaces.UpdateTemplateBuilderRequest) {
    return this.httpClient.put<Interfaces.TemplateBuilderEntity>(this.urls.byId(id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  updateBlueprint(id: number, data: Interfaces.UpdateTemplateBlueprintRequest) {
    return this.httpClient.put<void>(this.urls.blueprint(id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  delete(id: number) {
    return this.httpClient.delete<Interfaces.TemplateBuilderEntity>(this.urls.byId(id), {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  upgrade() {
    return this.httpClient.post<Interfaces.TemplateBuilderListEntity[]>(this.urls.upgrade, {});
  }

  createAssetUrl(data: Interfaces.CreateTemplateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.createAsset, data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  uploadAsset(signedUrl: string, file: File) {
    const [_, extension] = splitNameAndExtension(file.name);

    return this.httpClient.put(signedUrl, file, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
        'Content-Type': getMimeTypeFromExtension(extension),
      }),
      reportProgress: true,
      observe: 'events',
    });
  }
}
