<form class="search-form white-bg-control">
  <mat-form-field class="search-field" appearance="outline">
    <mat-icon matPrefix svgIcon="nav-search"></mat-icon>
    <input matInput placeholder="Search" aria-label="Search" [matAutocomplete]="auto" [formControl]="searchCtrl"
      (focus)="searchOnFocus()" />
    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="500" (optionSelected)="onOptionSelected($event)"
      [displayWith]="displayWith">
      <mat-option *ngFor="let result of searchResults$ | async" [value]="result">
        <div>
          <ng-container *ngIf="result.image">
            <img class="result-image" aria-hidden [src]="result.image" />
          </ng-container>
          <ng-container *ngIf="!result.image">
            <img class="result-image" aria-hidden src="assets/common/images/placeholder.jpg" alt="search-item-image" />
          </ng-container>
          <span [ngClass]="{'name-wrap': !result.hasAccess && result.type == 'session'}" class="name-wrap">{{
            result.name }}</span>
          |
          <small>{{ result.type | titlecase }}</small> |
          <small>Created {{ result.date | date }}</small> |
          <span *ngIf="!result.hasAccess && result.type == 'session'">
            <button mat-raised-button color="primary" style="line-height: 25px;"
              (click)="goToProject(result.projectId, result.id)">Project</button>
          </span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>