import { Recording, VideoShowableStatus, VideoStatus, VideoTranscodingStatus, VideoType } from '../interfaces';

export const canPlayRecording = (recording: Recording, type: VideoType): { canPlay: boolean; message?: string } => {
  if (recording.status !== VideoStatus.UPLOADED) {
    const message =
      (recording.status === VideoStatus.UPLOADING ? 'The clip is being uploaded. ' : '') +
      'Sorry! Video can not be played when recording is in progress.';
    return {
      canPlay: false,
      message,
    };
  } else if (type === VideoType.HD && recording.hq_status !== VideoTranscodingStatus.Ready) {
    const message = `Your MP4 clip is being processed.
      Please try again later once the status of the clip is ${VideoShowableStatus.Mp4Ready} `;
    return {
      canPlay: false,
      message,
    };
  } else if (
    type === VideoType.SD &&
    recording.preview_status !== VideoTranscodingStatus.Ready &&
    !recording.is_opentok_archive
  ) {
    const message =
      recording.preview_status === 'failed'
        ? 'Video processing failed'
        : `Your clip is being processed.
      Please try again later once the status of the clip is ${VideoShowableStatus.Ready} `;
    return {
      canPlay: false,
      message,
    };
  }
  return { canPlay: true };
};
