import { Directive, ElementRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InternalCommentsManagerService } from './internal-comments-manager.service';

@Directive({
    selector: '[orInternalCommentsCount]'
})
export class InternalCommentsCountDirective implements OnInit, OnDestroy {

    @Input()
    get token() {
      return this._token;
    }
    set token(token: string) {
        if(token !== this.token) {
            this._token = token;
            this._fetchComments();
        }
    }
    private _token: string;

    constructor(
        @Optional() private internalCommentsManagerService: InternalCommentsManagerService,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    private _fetchComments() {
        if(!!this.token && !!this.internalCommentsManagerService) {
            this.internalCommentsManagerService.setToken(this.token);
        }
    }

    private unsub$ = new Subject<void>();


    ngOnInit(): void {
        if (this.internalCommentsManagerService) {
            this.internalCommentsManagerService
                .commentsCount$
                .pipe(takeUntil(this.unsub$))
                .subscribe(count => this._renderCommentsCount(count));
        }
    }

    ngOnDestroy(): void {
        this.unsub$.next(null);
        this.unsub$.complete();
    }

    private _renderCommentsCount(count: number) {
        this.elementRef.nativeElement.innerText = `${count == -1 ? 0 : count} ${count === 1 ? 'Comment' : 'Comments'}`;
    }
}
