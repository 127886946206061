import { Injectable } from '@angular/core';
import {
  FileValidatorBaseService,
  FileValidationError,
} from '@openreel/ui/openreel-uploader/openreel-uploader.component';
import { Observable } from 'rxjs';
import { MediaMeta, MediaValidator } from '../../media/helpers/validate-media';
import * as Sentry from '@sentry/angular';
import { splitNameAndExtension } from '@openreel/common';

const ONE_GB = 1024 * 1024 * 1024;
const MAX_SIZE = ONE_GB * 5;
const VALID_EXTENSIONS = ['mov', 'webm', 'mp4'];

@Injectable({ providedIn: 'root' })
export class UserUploadFileValidatorService extends FileValidatorBaseService {
  message = 'Only .mov, .webm, and .mp4 files allowed.';

  constructor() {
    super();
  }

  // null is valid, a string is invalid. string will be displayed to user.
  validate(file: File): FileValidationError | Observable<FileValidationError> {
    const [, ext] = splitNameAndExtension(file.name);

    if (!VALID_EXTENSIONS.includes(ext.toLowerCase())) {
      return this.message;
    }

    if (file.size > MAX_SIZE) {
      return 'Only files up to 5GB allowed';
    }

    return this.isVideoExtensionSupported(file);
  }

  //
  // validate Webm file
  //
  // TODO: This logic has to be rewritten. It often fails with no obvious reason for valid media files
  validateFile(file: File): Promise<FileValidationError> {
    return (async () => {
      let videoData: MediaMeta;
      try {
        videoData = await MediaValidator.getMediaDataAsync(file);
      } catch (e) {
        Sentry.captureException(e);
        return this.message;
      }
      if (videoData && videoData.video) return null;
      else return this.message;
    })();
  }

  private isVideoExtensionSupported(file: File): FileValidationError {
    return MediaValidator.isVideoExtensionSupported(file) ? null : this.message;
  }
}
