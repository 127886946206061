import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { HostingPermissions } from '@openreel/common';
import { AclService, Cleanupable, commonenv } from '@openreel/frontend/common';
import { HostablePermissionTypes } from '@openreel/frontend/common/hosting';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

import { fadeIn, heightCollapse } from '../../../../animations';
import { HOSTING_SHARE_FORM, HostingShareForm } from '../hosting-share-form.service';
import { HostingShareComponentStore } from '../hosting-share.store';
import { ShareModalData } from '../share-modal-data.interface';

interface VideoSizeForm {
  width: number;
  height: number;
}

const WIDTH_VARIABLE = '{{width}}';
const HEIGHT_VARIABLE = '{{height}}';

@Component({
  selector: 'openreel-embed-video-link',
  templateUrl: './embed-video-link.component.html',
  styleUrls: ['./embed-video-link.component.scss'],
  animations: [fadeIn, heightCollapse],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EmbedVideoLinkComponent,
    },
  ],
})
export class EmbedVideoLinkComponent extends Cleanupable implements OnInit, ControlValueAccessor {
  @Input() readonly data: ShareModalData;

  /**
   * Responsible for responsiveness of the embedded video
   * @property true - responsive size
   * @property false - fixed size
   */
  readonly responsivenessControl = new FormControl(true);

  /**
   * Contains prepare embed link, ready to display
   */
  readonly embedLinkControl = new FormControl('');

  /**
   * Form to specify custom video sizing - width and height, in pixels
   */
  readonly embedVideoSizingForm: FormGroup;

  readonly embedUrl$: Observable<string>;
  readonly embedVideoCount$: Observable<number>;
  readonly shareModalData$ = this.hostingShareComponentStore.shareModalData$;

  disabled = false;

  onChange: (value: string) => void = null;
  onTouched: () => void = null;

  get disabledToggleTooltip(): string {
    if (this.form.controls.requirePassword.value) {
      return 'Password protected videos cannot be embedded';
    }

    if (this.form.controls.embedExceed.value) {
      return 'Embedding limit is exceeded';
    }
  }

  get embedEnabledControl(): FormControl<boolean> {
    return this.form.controls.embedEnabled;
  }

  get fixedSizeEnabled(): boolean {
    return !this.responsivenessControl.value;
  }

  get isRootAdmin(): boolean {
    return this.aclService.isUserHasPermissions(this.data.userPermissions, HostingPermissions.TransferOwnership);
  }

  constructor(
    @Inject(HOSTING_SHARE_FORM) readonly form: FormGroup<HostingShareForm>,
    private readonly fb: FormBuilder,
    private readonly clipboard: Clipboard,
    private readonly toastr: ToastrService,
    private readonly hostingShareComponentStore: HostingShareComponentStore,
    private readonly aclService: AclService,
  ) {
    super();

    this.embedVideoSizingForm = this.buildEmbedVideoSizingForm();
    this.embedVideoCount$ = this.getEmbedVideoCount$();

    this.embedUrl$ = this.getEmbedUrl$().pipe(
      tap((embed) => {
        this.embedLinkControl.patchValue(embed);
        this.onChange(embed);
      })
    );
  }

  ngOnInit(): void {
    this.subscribeToEmbedUrlChanges();
    this.subscribeToResponsivenessControlChanges();
    this.toggleControlOnRequirePasswordChanges();
    this.setEmbedEnabledInitialState();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.embedLinkControl.patchValue(value, { emitEvent: false });
  }

  copyEmbed(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.clipboard.copy(this.embedLinkControl.value);
    this.toastr.success('Embed code copied!');
  }

  private subscribeToEmbedUrlChanges(): void {
    this.mortalize(this.embedUrl$).subscribe();
  }

  private constructEmbedUrl(): string {
    return `<iframe width="${WIDTH_VARIABLE}" height="${HEIGHT_VARIABLE}" src="${commonenv.hostingAppUrl}embed/${this.data.idHash}-${this.data.slug}" title="OpenReel Video Player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>`;
  }

  private getIframeUrl$(): Observable<string> {
    return of(this.constructEmbedUrl());
  }

  private getEmbedUrl$(): Observable<string> {
    const { width: originalWidth, height: originalHeight } = this.embedVideoSizingForm.value as VideoSizeForm;

    let previousWidth = originalWidth;

    return this.mortalize(this.responsivenessControl.valueChanges).pipe(
      startWith<boolean>(this.responsivenessControl.value as boolean),
      switchMap((responsiveness) =>
        this.embedVideoSizingForm.valueChanges.pipe(
          startWith<VideoSizeForm>(this.embedVideoSizingForm.value as VideoSizeForm),
          debounceTime(300),
          map(({ width: currentWidth, height: currentHeight }) => {
            const isWidthChanged = previousWidth !== currentWidth;

            if (isWidthChanged) {
              return {
                width: currentWidth,
                height: (originalHeight / originalWidth) * currentWidth,
              };
            }

            return {
              width: (originalWidth / originalHeight) * currentHeight,
              height: currentHeight,
            };
          }),
          map(({ width, height }) => ({
            width: width ? Math.round(width) : 0,
            height: height ? Math.round(height) : 0,
          })),
          tap((dimension) => {
            const { width } = dimension;

            previousWidth = width;

            this.embedVideoSizingForm.patchValue(dimension, { emitEvent: false });
          }),
          switchMap(({ width, height }) =>
            this.getIframeUrl$().pipe(
              map((embed) =>
                embed
                  .replace(WIDTH_VARIABLE, responsiveness ? '100%' : `${width}px`)
                  .replace(HEIGHT_VARIABLE, responsiveness ? '100%' : `${height}px`)
              )
            )
          )
        )
      )
    );
  }

  private subscribeToResponsivenessControlChanges(): void {
    this.mortalize(this.responsivenessControl.valueChanges)
      .pipe(startWith<boolean>(this.responsivenessControl.value as boolean))
      .subscribe((responsiveness) => {
        const widthControl = this.embedVideoSizingForm.get('width');
        const heightControl = this.embedVideoSizingForm.get('height');

        if (responsiveness) {
          widthControl.disable({ emitEvent: false });
          heightControl.disable({ emitEvent: false });
        } else {
          widthControl.enable({ emitEvent: false });
          heightControl.enable({ emitEvent: false });
        }
      });
  }

  private buildEmbedVideoSizingForm(): FormGroup {
    return this.fb.group({
      width: [{ value: 960, disabled: true }, [Validators.required, Validators.min(0)]],
      height: [{ value: 540, disabled: true }, [Validators.required, Validators.min(0)]],
    });
  }

  private getEmbedVideoCount$(): Observable<number> {
    const embedVideoCountControl = this.form.get('embedVideoCount');

    return embedVideoCountControl.valueChanges.pipe(startWith<number>(embedVideoCountControl.value as number));
  }

  private toggleControlOnRequirePasswordChanges(): void {
    this.mortalize(this.form.controls.requirePassword.valueChanges)
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe((requirePassword) => {
        const embedEnabled = this.form.controls.embedEnabled;

        if (requirePassword) {
          embedEnabled.setValue(false, { emitEvent: embedEnabled.value });
          embedEnabled.disable({ emitEvent: false });
        } else {
          if (!this.form.controls.embedExceed.value) {
            embedEnabled.enable({ emitEvent: false });
          }
        }
      });
  }

  private setEmbedEnabledInitialState(): void {
    if (this.data.type === 'video') {
      const { embedExceed, embedEnabledAt } = this.data;

      const embedEnabledControl = this.form.controls.embedEnabled;
      const accessType = this.form.controls.accessType.value;
      const requirePassword = this.form.controls.requirePassword.value;

      if ((embedExceed && !embedEnabledAt) || accessType !== HostablePermissionTypes.Public || requirePassword) {
        embedEnabledControl.disable({ emitEvent: false });
      } else {
        embedEnabledControl.enable({ emitEvent: false });
      }
    }
  }
}
