import { AclPermission } from './acl-permission.type';

type CachedPermission = AclPermission | AclPermission[];

export class AclMemoize {

  private readonly cache = new Map<CachedPermission, boolean>();

  getValue(scope: CachedPermission): boolean {
    return this.cache.get(scope);
  }

  hasValue(scope: CachedPermission): boolean {
    return this.cache.has(scope);
  }

  setValue(scope: CachedPermission, value: boolean): void {
    this.cache.set(scope, value);
  }

  clear(): void {
    this.cache.clear();
  }
}
