import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services';
import { ROUTE_SUBJECT } from '../route-utils';

@Injectable({ providedIn: 'root' })
export class IsAuthenticatedGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate([`/${ROUTE_SUBJECT.replace(':mode', 'MODE_OTP')}`]);
        }
      })
    );
  }
}
