import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { ComponentStore } from '@ngrx/component-store';
import {
  HostingRenderProgress,
  EVT_HOSTING_RENDER_PROGRESS,
  EVT_HOSTING_JOIN_BY_PROJECT_ID,
  HostingVideoTranscriptProgress,
  EVT_HOSTING_CHAPTER_RENDER_PROGRESS,
  EVT_HOSTING_VIDEO_SUMMARY_TRANSCRIPT_PROGRESS,
  EVT_HOSTING_VIDEO_CAPTIONS_TRANSCRIPT_PROGRESS,
  EVT_HOSTING_CAPTIONS_TRANSLATION_PROGRESS,
  HostingCaptionsTranslationProgress,
  HostingAudioExtractionProgress,
  EVT_HOSTING_AUDIO_EXTRACTION_PROGRESS,
} from '../hosting-interfaces';
import { HostingSocketService } from './hosting-socket.service';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class HostingProgressService {
  private renderProgress$: Observable<HostingRenderProgress> = this.socket
    .getSocketEventByName<HostingRenderProgress>(EVT_HOSTING_RENDER_PROGRESS)
    .pipe(map((event) => event.data));
  renderSub?: Subscription;
  rejoinSub?: Subscription;
  private renderStore = new ComponentStore<{ [key: number]: HostingRenderProgress }>({});

  getRenderProgress(id: number) {
    return this.renderStore
      .select((state) => state[id])
      .pipe(
        filter((p) => !!p),
        distinctUntilChanged((p1, p2) => p1.progress === p2.progress && p1.state === p2.state)
      );
  }

  private readonly updateRenderProgress = this.renderStore.updater((state, render: HostingRenderProgress) => ({
    ...state,
    [render.id]: render,
  }));

  constructor(
    private readonly socket: HostingSocketService,
    private toastr: ToastrService,
    private readonly authService: AuthService
  ) {}

  init() {
    const sessionInfo = {
      deviceType: 'WEB',
      userType: this.authService.role,
      token: this.authService.token$.value,
    };

    this.connect(sessionInfo);

    this.renderSub?.unsubscribe();
    this.renderSub = this.renderProgress$.subscribe((p: HostingRenderProgress) => {
      this.updateRenderProgress(p);

      // report to UI, since sometimes no subscribers are visibale on the screen
      if (p.state === 'failed') {
        this.toastr.error('Transcode for your shared item failed', 'Error!');
      }
    });
  }

  connect(sessionInfo) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.connect(commonenv.websocketUrl + '?access-token=' + payload.token);
    console.log('Hosting - starting listening socket...');
    if (this.rejoinSub) {
      this.rejoinSub.unsubscribe();
    }
    this.rejoinSub = this.socket.socket.connectionStatus$
      .pipe(
        map((status) => status.connected),
        distinctUntilChanged(),
        filter((connected) => connected)
      )
      .subscribe(() => {
        this.joinRoom(sessionInfo);
      });
  }

  joinRoom(sessionInfo) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.emit(EVT_HOSTING_JOIN_BY_PROJECT_ID, payload);
  }

  getChapterProgress(id: number): Observable<HostingVideoTranscriptProgress> {
    return this.socket
    .getSocketEventByName<HostingVideoTranscriptProgress>(EVT_HOSTING_CHAPTER_RENDER_PROGRESS)
    .pipe(
      filter((event)=> event.data.id === id),
      map((event) => event.data)
    );
  }

  getVideoSummaryProgress(id: number): Observable<HostingVideoTranscriptProgress> {
    return this.socket
      .getSocketEventByName<HostingVideoTranscriptProgress>(EVT_HOSTING_VIDEO_SUMMARY_TRANSCRIPT_PROGRESS)
      .pipe(
        filter((event) => event.data.id === id),
        map((event) => event.data)
      )
  }

  getCaptionsProgress(id: number): Observable<HostingVideoTranscriptProgress> {
    return this.socket
      .getSocketEventByName<HostingVideoTranscriptProgress>(EVT_HOSTING_VIDEO_CAPTIONS_TRANSCRIPT_PROGRESS)
      .pipe(
        filter((event) => event.data.id === id),
        map((event) => event.data)
      )
  }

  getCaptionsTranslationProgress(id: number): Observable<HostingCaptionsTranslationProgress> {
    return this.socket
      .getSocketEventByName<HostingCaptionsTranslationProgress>(EVT_HOSTING_CAPTIONS_TRANSLATION_PROGRESS)
      .pipe(
        filter((event) => event.data.id === id),
        map((event) => event.data)
      )
  }

  getAudioExtractionProgress(): Observable<HostingAudioExtractionProgress> {
    return this.socket
      .getSocketEventByName<HostingAudioExtractionProgress>(EVT_HOSTING_AUDIO_EXTRACTION_PROGRESS)
      .pipe(
        map((event) => event.data)
      );
  }

  disconnect() {
    this.renderSub?.unsubscribe();
    this.renderSub = null;
    this.rejoinSub?.unsubscribe();
    this.rejoinSub = null;
    this.socket.disconnect();
    this.renderStore.setState({});
  }

  private getSessionInfoPayload(sessionInfo) {
    return {
      device_type: 'WEB',
      user_type: sessionInfo.userType,
      token: sessionInfo.token,
    };
  }
}
