import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OpenreelCommonModule } from '@openreel/frontend/common';
import { AvatarModule } from 'ngx-avatar';

import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { TopbarComponent } from '../components/topbar/topbar.component';

@NgModule({
  declarations: [SidebarComponent, TopbarComponent],
  imports: [CommonModule, RouterModule, AvatarModule, FlexLayoutModule, FormsModule, OpenreelCommonModule],
  exports: [SidebarComponent, TopbarComponent],
  providers: [],
})
export class ThemeModule {}
