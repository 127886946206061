import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { DirectorUserDetails } from '@openreel/frontend/common';

@Component({
  selector: 'openreel-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  protected navbarAttributes = ['padding-80', 'padding-110'];

  @Input() userDetails: DirectorUserDetails;
  @Input() title: string;
  @Input() subtitle: string | null;
  @Input() titleRoute: DirectorUserDetails;

  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit() {
    this.navbarAttributes.forEach((attr) => {
      if (this.getElement().hasAttribute(attr)) {
        this.getElement().classList.add(attr);
      }
    });
  }

  protected getElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  getSubtitle(): string {
    if (this.subtitle) {
      return this.subtitle;
    } else if (this.subtitle === null) {
      return '';
    } else {
      return this.userDetails ? 'Welcome back, ' + this.userDetails?.fullname + ' !' : 'Welcome Guest!';
    }
  }
}
