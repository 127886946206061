import { Component, OnDestroy, OnInit } from '@angular/core';
import { UploadService } from '../../services/upload/upload.service';
import { UploadFileInfo, MultiPartUploadInfo, RecordingMetadata } from '../../services/upload/dto/upload.dto';
import { Cleanupable } from '../../classes';
import { Subscription, merge, combineLatest, of, Observable, EMPTY } from 'rxjs';
import { map, switchMap, tap, shareReplay, filter, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { UserRoleType } from '../../interfaces';
import { VideoSource } from '../../media';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { DownloadUrlPipe } from '../../pipes/download-url.pipe';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'openreel-upload-indicator',
  templateUrl: './upload-indicator.component.html',
  styleUrls: ['./upload-indicator.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyProgressBarModule,
    DownloadUrlPipe,
  ],
})
export class UploadIndicatorComponent extends Cleanupable implements OnInit, OnDestroy {
  isNaN = isNaN;
  public show = true;
  isSubject = false;
  public uploads: UploadFileInfo[];

  mpUploads$: Observable<MultiPartUploadInfo[]> = this.uploadService.mpuQueue$.pipe(
    switchMap((mpus) =>
      !mpus.length
        ? of(mpus)
        : combineLatest(
            mpus
              .filter((m) => m.metadata.videoSource !== VideoSource.PRESENTATION)
              .map((mpu) =>
                combineLatest([mpu.uploader.isOpen$, mpu.uploader.hasError$]).pipe(
                  map(([open, hasError]) => ({ open, hasError, mpu }))
                )
              )
          ).pipe(
            map((res) => res.filter((r) => !r.open).map((r) => r.mpu)),
            shareReplay(1)
          )
    )
  );

  constructor(public uploadService: UploadService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.currentlyUploadingVideos(),
      this.mpUploads$
        .pipe(
          switchMap((mpus) =>
            !mpus.length
              ? EMPTY
              : merge(
                  ...mpus.map((mpu) => {
                    this.isSubject = mpu.metadata.role === UserRoleType.Subject;
                    return combineLatest([mpu.uploader.progress$, mpu.uploader.hasError$]).pipe(
                      filter(([, hasError]) => !hasError),
                      map(([data]) => ({
                        progress: parseInt((data * 100).toString(), 10),
                        videoId: mpu.metadata.videoId,
                        sessionId: mpu.metadata.sessionId,
                        isLiteVideo: mpu.metadata.isLiteVideo,
                        metadata: mpu.metadata,
                      }))
                    );
                  })
                )
          ),
          filter(
            (uploadFilePercentInfo) => Boolean(uploadFilePercentInfo.sessionId) && uploadFilePercentInfo.progress >= 0
          ),
          distinctUntilChanged((previous, current) => previous.progress === current.progress),
          mergeMap((uploadFilePercentInfo) =>
            this.uploadService.sendUploadProgress(
              {
                videoid: uploadFilePercentInfo.videoId,
                upload_percentage: uploadFilePercentInfo.progress,
                SessionID: uploadFilePercentInfo.sessionId,
                isLiteVideo: uploadFilePercentInfo.isLiteVideo || false,
              },
              uploadFilePercentInfo.metadata
            )
          )
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  retryUpload(metadata: RecordingMetadata) {
    this.uploadService.removeFromUploadQueue(metadata);
    window.open('/video-retry', '_blank');
  }

  private currentlyUploadingVideos(): Subscription {
    return this.uploadService.queue$
      .pipe(
        map((uploads) => uploads.filter((u) => u.metadata.videoSource !== VideoSource.PRESENTATION).slice(0, 5)),
        tap((uploads) => {
          this.uploads = uploads;
        }),
        map((uploads) =>
          uploads.map((upload) => {
            this.isSubject = upload.metadata.role === UserRoleType.Subject;
            return upload.status$.pipe(
              map((status) => ({
                videoId: upload.metadata.videoId,
                progress: parseInt((status.percentage * 100).toString(), 10),
                sessionId: upload.metadata.sessionId,
                isLiteVideo: upload.metadata.isLiteVideo,
                metadata: upload.metadata,
              }))
            );
          })
        ),
        switchMap((uploadFileNetWorkStatus) => merge(...uploadFileNetWorkStatus)),
        filter(
          (uploadFilePercentInfo) => Boolean(uploadFilePercentInfo.sessionId) && uploadFilePercentInfo.progress >= 0
        ),
        distinctUntilChanged((previous, current) => previous.progress === current.progress),
        mergeMap((uploadFilePercentInfo) =>
          this.uploadService.sendUploadProgress(
            {
              videoid: uploadFilePercentInfo.videoId,
              upload_percentage: uploadFilePercentInfo.progress,
              SessionID: uploadFilePercentInfo.sessionId,
              isLiteVideo: uploadFilePercentInfo.isLiteVideo || false,
            },
            uploadFilePercentInfo.metadata
          )
        )
      )
      .subscribe();
  }
}
