import { trigger, transition, style, animate } from '@angular/animations';

const TRANSITION = '{{duration}}ms ease-in-out';
const DURATION = { params: { duration: 300 } };

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate(TRANSITION, style({ opacity: 1 }))], DURATION),
  transition(':leave', [style({ opacity: 1 }), animate(TRANSITION, style({ opacity: 0 }))], DURATION),
]);

export const fadeInLimitedTo = (minOpacity = 0, maxOpacity = 1) => trigger('fadeInLimitedTo', [
  transition(':enter', [style({ opacity: minOpacity }), animate(TRANSITION, style({ opacity: maxOpacity }))], DURATION),
  transition(':leave', [style({ opacity: maxOpacity }), animate(TRANSITION, style({ opacity: minOpacity }))], DURATION),
]);

export const heightCollapse = trigger('heightCollapse', [
  transition(':enter', [style({ height: 0 }), animate(TRANSITION, style({ height: '*' }))], DURATION),
  transition(':leave', [style({ height: '*' }), animate(TRANSITION, style({ height: 0 }))], DURATION),
]);

export const widthCollapse = trigger('widthCollapse', [
  transition(':enter', [style({ width: 0 }), animate(TRANSITION, style({ width: '*' }))], DURATION),
  transition(':leave', [style({ width: '*' }), animate(TRANSITION, style({ width: 0 }))], DURATION),
]);
