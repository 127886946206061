import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalesforceChatService {
  openChatWindow$: Subject<boolean> = new Subject();

  openChat() {
    this.openChatWindow$.next(true);
  }
}
