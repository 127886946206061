<div class="menu">
  <div class="menu-wrapper">
    <ng-container *ngIf="(homePageConfig$ | async) as homePageConfig; else plainLogo">
      <ng-container *ngIf="homePageConfig.external; else internalLogoUrl">
        <a [href]="homePageConfig.url">
          <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>
      </ng-container>

      <ng-template #internalLogoUrl>
        <a [routerLink]="homePageConfig.url">
          <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>
      </ng-template>
    </ng-container>

    <ng-template #plainLogo>
      <ng-container *ngTemplateOutlet="logo"></ng-container>
    </ng-template>

    <div class="navigation-items">
      <ng-container
        *ngTemplateOutlet="navigationItemsTemplate; context: { $implicit: placementTopItems$ | async }"
      ></ng-container>
    </div>
  </div>

  <div class="extra-items">
    <div class="navigation-items">
      <ng-container
        *ngTemplateOutlet="navigationItemsTemplate; context: { $implicit: placementBottomItems$ | async }"
      ></ng-container>
    </div>

    <ng-container *ngIf="userDetails$ | async as userDetails">
      <openreel-profile-menu
        [name]="userDetails.fullname"
        [accountName]="userDetails.site_users?.name"
        (logoutButtonClicked)="onLogoutButtonClicked()"
      ></openreel-profile-menu>
    </ng-container>
  </div>
</div>

<ng-template #logo>
  <img class="logo" src="assets/common/logos/blue-gradient.svg" />
</ng-template>

<ng-template #navigationItemsTemplate let-navigationItems>
  <ng-container *ngFor="let navigationItem of navigationItems">
    <ng-container *ngIf="!!navigationItem.featureFlag; else unflagged">
      <ng-container *featureEnabled="navigationItem.featureFlag">
        <ng-container *ngTemplateOutlet="navigationItemTemplate; context: { $implicit: navigationItem }"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #unflagged>
      <ng-container *ngTemplateOutlet="navigationItemTemplate; context: { $implicit: navigationItem }"></ng-container>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #navigationItemTemplate let-navigationItem>
  <a
    *ngIf="navigationItem.external; else internalLink"
    [matTooltip]="navigationItem.title"
    matTooltipPosition="right"
    [href]="navigationItem.link"
    [target]="navigationItem.openInNewTab ? '_blank' : ''"
    routerLinkActive="navigation-item-active"
    class="navigation-item"
  >
    <mat-icon [svgIcon]="navigationItem.icon"></mat-icon>
  </a>

  <ng-template #internalLink>
    <a
      [matTooltip]="navigationItem.title"
      [routerLink]="navigationItem.link"
      routerLinkActive="navigation-item-active"
      matTooltipPosition="right"
      class="navigation-item"
    >
      <mat-icon [svgIcon]="navigationItem.icon"></mat-icon>
    </a>
  </ng-template>
</ng-template>
