import { LottieProcessedFieldData, PresetField } from '@openreel/creator/common';
import { Color4, TextLayer } from '../interfaces/lottie.interface';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';

export class TransformerText extends TransformerBase {
  test(fieldDef: PresetField) {
    return fieldDef.type === 'text';
  }

  process({ animation, fieldDef, fieldValues }: TransformerProcessOptions) {
    if (!fieldDef.lottiePath) {
      return animation;
    }

    const rootNodes = this.getRootNode<TextLayer | TextLayer[]>(animation, fieldDef.lottiePath);
    if (Array.isArray(rootNodes)) {
      rootNodes.forEach((rootNode) => {
        this.processTextNode(rootNode, fieldValues, fieldDef);
      });
    } else {
      this.processTextNode(rootNodes, fieldValues, fieldDef);
    }

    return animation;
  }

  private processTextNode(rootNode: TextLayer, fieldValues: LottieProcessedFieldData, fieldDef: PresetField) {
    rootNode.t.d.k[0].s = {
      ...rootNode.t.d.k[0].s,
      t: fieldValues.value?.replace(/\n/g, '\r'),
      f: fieldValues.font,
      ...(fieldDef.colorTag ? { fc: fieldValues.color as Color4 } : {}),
    };
  }
}
