import { NumberRange, intersectTimeRanges, invertTimeRanges } from '@openreel/common';
import { cloneDeep } from 'lodash';
import { Asset, LayerTrimmerInfo, TrimmerClip, TrimmerData, VideoLayer, WorkflowDataDto } from '../interfaces';
import { getLayerFromId } from './timelines.helpers';
import { getAssetDuration, getAssetDurationAfterTextTrimmer, getAssetFinalDuration } from './workflow-duration.helpers';

export function convertLayerIntoTrimmerClip(layerInfo: LayerTrimmerInfo) {
  const clip: TrimmerClip = {
    id: layerInfo.asset.id,
    layerId: layerInfo.layerId,
    assetFileId: layerInfo.asset.file.path,
    assetProviderType: layerInfo.asset.file.provider,
    name: layerInfo.asset.name,
    textCuts: layerInfo.textCuts,
    trim: layerInfo.trim,
    originalTrim: layerInfo.originalTrim,
    duration: getAssetDuration(layerInfo.asset),
    timelineDuration: getAssetDurationAfterTextTrimmer(layerInfo.asset),
  };

  return clip;
}

// NOTE: video trim works on duration after text trimmer
export function createTrimmerDataForVideoTrim(layerInfo: LayerTrimmerInfo) {
  const transformedAsset: Asset = {
    ...layerInfo.asset,
    textCuts: layerInfo.textCuts,
    trim: layerInfo.trim,
  };
  const assetDuration = getAssetDuration(transformedAsset);

  const trimmerData: TrimmerData = {
    clips: [
      {
        ...convertLayerIntoTrimmerClip(layerInfo),
        trim: convertOriginalTimeRangeToUITime(transformedAsset.trim, assetDuration, transformedAsset.textCuts),
        duration: assetDuration,
        timelineDuration: getAssetDurationAfterTextTrimmer(transformedAsset),
      },
    ],
  };

  return trimmerData;
}

// NOTE: section trim works on final duration (after text trimmer & timeline trimmer)
export function createTrimmerDataForSectionTrim(layerInfos: LayerTrimmerInfo[]) {
  const trimmerData: TrimmerData = { clips: [] };

  for (const layerInfo of layerInfos) {
    const transformedAsset: Asset = {
      ...layerInfo.asset,
      textCuts: layerInfo.textCuts,
      trim: layerInfo.trim,
    };
    const assetDuration = getAssetFinalDuration(transformedAsset);

    trimmerData.clips.push({
      ...convertLayerIntoTrimmerClip(layerInfo),
      textCuts: [],
      trim: { from: 0, to: assetDuration },
      duration: assetDuration,
      timelineDuration: assetDuration,
    });
  }

  return trimmerData;
}

// NOTE: split scene works on final duration (after text trimmer & timeline trimmer)
export function createTrimmerDataForSplitter(layerIds: string[], workflow: WorkflowDataDto) {
  const trimmerData: TrimmerData = {
    clips: [],
  };

  for (const layerId of layerIds) {
    const layerInfo = getLayerFromId(layerId, workflow);
    if (!layerInfo || layerInfo.layer.type !== 'video') {
      continue;
    }

    const layer = layerInfo.layer as VideoLayer;

    const asset = workflow.assets.find((a) => a.id === layer.assetId);

    trimmerData.clips.push({
      id: null,
      layerId,
      assetFileId: asset.file.path,
      assetProviderType: asset.file.provider,
      name: asset.name,
      textCuts: asset.textCuts?.length ? cloneDeep(asset.textCuts) : [],
      trim: asset.trim ? cloneDeep(asset.trim) : { from: 0, to: getAssetDuration(asset) },
      originalTrim: asset.trim ? cloneDeep(asset.trim) : { from: 0, to: getAssetDuration(asset) },
      duration: getAssetDuration(asset),
      timelineDuration: getAssetFinalDuration(asset),
    });
  }

  return trimmerData;
}

export function getAssetTimelineTrim(asset: Asset): NumberRange {
  return asset.trim ?? { from: 0, to: getAssetDuration(asset) };
}

export function getAssetFinalTrims(asset: Asset): NumberRange[] {
  const timelineTrim = getAssetTimelineTrim(asset);
  const textTrims = invertTimeRanges(asset.data.duration, asset.textCuts, true);

  const finalTrims: NumberRange[] = [];
  for (const textTrim of textTrims) {
    if (textTrim.to <= timelineTrim.from || textTrim.from >= timelineTrim.to) {
      continue;
    }

    finalTrims.push({
      from: Math.max(textTrim.from, timelineTrim.from),
      to: Math.min(textTrim.to, timelineTrim.to),
    });
  }

  return finalTrims;
}

export function getAssetFinalCuts(asset: Asset): NumberRange[] {
  const finalTrims = getAssetFinalTrims(asset);
  return invertTimeRanges(getAssetDuration(asset), finalTrims, true);
}

export function convertOriginalTimeToUITime(
  time: number,
  assetDuration: number,
  cuts: NumberRange[],
  trim?: NumberRange
) {
  const textTrims = invertTimeRanges(assetDuration, cuts, true);
  const trims = trim ? intersectTimeRanges(textTrims, trim) : textTrims;

  let elapsedTime = 0;
  for (const trim of trims) {
    if (trim.from >= time) {
      break;
    }

    elapsedTime += Math.min(trim.to, time) - trim.from;
  }

  return elapsedTime;
}

export function convertOriginalTimeRangeToUITime(
  range: NumberRange,
  assetDuration: number,
  cuts: NumberRange[],
  trim?: NumberRange
): NumberRange {
  return {
    from: convertOriginalTimeToUITime(range.from, assetDuration, cuts, trim),
    to: convertOriginalTimeToUITime(range.to, assetDuration, cuts, trim),
  };
}

export function convertUITimeRangeToOriginalTime(
  uiRange: NumberRange,
  assetDuration: number,
  cuts: NumberRange[],
  trim?: NumberRange
) {
  let timeLeftFrom = uiRange.from;
  let timeLeftTo = uiRange.to;

  const result: NumberRange = { from: null, to: null };

  const textTrims = invertTimeRanges(assetDuration, cuts, true);
  const trims = trim ? intersectTimeRanges(textTrims, trim) : textTrims;

  for (const trim of trims) {
    const trimDuration = trim.to - trim.from;

    if (result.from === null) {
      if (timeLeftFrom === 0) {
        result.from = trim.from;
      } else if (trimDuration <= timeLeftFrom) {
        timeLeftFrom -= trimDuration;
      } else {
        result.from = trim.from + timeLeftFrom;
      }
    }

    if (result.to === null) {
      if (trimDuration < timeLeftTo) {
        timeLeftTo -= trimDuration;
      } else {
        result.to = trim.from + timeLeftTo;
      }
    }

    if (result.from !== null && result.to !== null) {
      break;
    }
  }

  if (result.to === null) {
    result.to = trims[trims.length - 1].to;
  }

  if (result.from === null) {
    // if no suitable trim found, that means that uiRange is outside the assetDuration, so set from to asset duration
    result.from = assetDuration - 1;
  }

  if (result.from > result.to) {
    // double check whether from is greater than to
    result.to = result.from;
  }

  return result;
}

export function convertOriginalTimeRangeToUITimeWithAsset(range: NumberRange, asset: Asset) {
  return convertOriginalTimeRangeToUITime(range, getAssetDuration(asset), asset.textCuts, asset.trim);
}

export function convertUITimeRangeToOriginalTimeWithAsset(uiRange: NumberRange, asset: Asset) {
  return convertUITimeRangeToOriginalTime(uiRange, getAssetDuration(asset), asset.textCuts, asset.trim);
}
