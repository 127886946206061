import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchResult } from '@openreel/common';
import { environment } from 'libs/theme/src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private urls = {
    search: environment.nextGenApiUrl + 'search',
  };
  constructor(private httpClient: HttpClient) {}

  searchResults$ = new BehaviorSubject<SearchResult[]>([]);

  search(text: string) {
    this.httpClient
      .post<SearchResult[]>(this.urls.search, {
        text: text,
      })
      .subscribe((res) => {
        this.searchResults$.next(res);
      });
  }
}
