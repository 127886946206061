import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, startWith } from 'rxjs';
import { DirectorUserDetails } from '@openreel/frontend/common';

import { FeatureEnabledDirective } from '../../directives/feature-enabled.directive';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationItem } from './navigation-item.interface';
import { HOME_PAGE_CONFIG, NAVIGATION_CONFIG } from './navigation-config.token';
import { map } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ProfileMenuComponent } from '../profile-menu';

const icons = [
  'home-line',
  'home-automations',
  'layers-three-01',
  'folder',
  'image-user',
  'calendar',
  'search-lg',
  'settings-01',
  'life-buoy-01',
];

@Component({
  selector: 'openreel-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    NgTemplateOutlet,
    FeatureEnabledDirective,
    AsyncPipe,
    MatTooltipModule,
    MatButtonModule,
    ProfileMenuComponent,
  ],
})
export class OpenreelMenuComponent {
  @Output() logout = new EventEmitter<void>();

  readonly userDetails$: Observable<DirectorUserDetails>;
  readonly placementTopItems$: Observable<NavigationItem[]>;
  readonly placementBottomItems$: Observable<NavigationItem[]>;

  readonly homePageConfig$ = inject(HOME_PAGE_CONFIG, { optional: true });

  private readonly navigationItems$ = inject(NAVIGATION_CONFIG);
  private readonly authService = inject(AuthService);
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);

  constructor() {
    for (const icon of icons) {
      const path = `assets/common/icons/${icon}.svg`;
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    }

    this.userDetails$ = this.authService.userDetails$;

    this.placementTopItems$ = this.navigationItems$.pipe(
      startWith([]),
      map((items) => items.filter((item) => item.placement === 'top'))
    );

    this.placementBottomItems$ = this.navigationItems$.pipe(
      startWith([]),
      map((items) => items.filter((item) => item.placement === 'bottom'))
    );
  }

  onLogoutButtonClicked(): void {
    this.logout.emit();
  }
}
