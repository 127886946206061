import { Asset, LayerOptions, LottieLayer, File } from '../interfaces';

export const TEXT_BOX_PRESET_FIELD_NAME = 'text';
export const TEXT_BOX_LOTTIE_PROPERTIES_PATH = 'layers';
export const TEXT_BOX_ASSET_ID = 'text-box-element-asset';
export const TEXT_BOX_ASSET_FILE: File = {
  path: 'text-box-element',
  provider: 'or-assets',
};
export const TEXT_BOX_ASSET: Asset = {
  id: TEXT_BOX_ASSET_ID,
  type: 'json',
  isGlobal: true,
  file: TEXT_BOX_ASSET_FILE,
  preset: {
    [TEXT_BOX_PRESET_FIELD_NAME]: {
      type: 'text',
      defaultValue: 'This is a text element',
      colorTag: 'text',
      lottiePath: TEXT_BOX_LOTTIE_PROPERTIES_PATH,
    },
  },
  data: {
    defaultInlineEditBounds: {
      x: 22.68,
      y: 46.29725031308302,
      width: 32.93939299662408,
      height: 3.702749686916983,
    },
  },
  colorTags: [
    {
      tag: 'text',
      name: 'Text Color',
      color: '#000000',
    },
  ],
};

export const TEXT_BOX_LAYER_HEADING: Partial<LayerOptions & LottieLayer> = {
  data: {
    [TEXT_BOX_PRESET_FIELD_NAME]: {
      type: 'text',
      value: 'This is a heading',
    },
  },
  bounds: {
    x: 13.75,
    y: 45.926,
    width: 72.5,
    height: 8.148,
  },
};

export const TEXT_BOX_LAYER_SUBHEADING: Partial<LayerOptions & LottieLayer> = {
  data: {
    [TEXT_BOX_PRESET_FIELD_NAME]: {
      type: 'text',
      value: 'This is a subheading',
    },
  },
  bounds: {
    x: 22.68,
    y: 46.93,
    width: 54.63,
    height: 6.14,
  },
};

export const TEXT_BOX_LAYER_BODY: Partial<LayerOptions & LottieLayer> = {
  data: {
    [TEXT_BOX_PRESET_FIELD_NAME]: {
      type: 'text',
      value: 'This is a text element',
    },
  },
  bounds: {
    x: 33.53,
    y: 48.15,
    width: 32.93939299662408,
    height: 3.702749686916983,
  },
};
