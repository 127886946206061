import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv, EntityRole } from '@openreel/frontend/common';
import {
  EntityObjectPermissionRequest,
  EntityObjectPermissionUpdateRequest,
  GetEntityRolesRequest,
  TransferRoleRequestPayload,
  TransferRoleResponse,
} from '@openreel/frontend/common/services/entity-permissions/entity-object-permission-request.interface';
import { Observable } from 'rxjs';

import {
  SharePermissionedUser,
  PermissionedEntities,
  SharePermissionedGroup,
} from '../../models/object-user.interface';

@Injectable({
  providedIn: 'root',
})
export class EntityPermissionsService {
  readonly urls = {
    getEntityRoles: commonenv.nextGenApiUrl + 'entity-roles',
    entityObjectPermission: commonenv.nextGenApiUrl + 'entity-object-permission',
  };

  constructor(private readonly http: HttpClient) {}

  getEntityRoles(data: GetEntityRolesRequest): Observable<EntityRole[]> {
    const { entityId, entity } = data;

    return this.http.get<EntityRole[]>(`${this.urls.entityObjectPermission}/${entity}/${entityId}/roles`);
  }

  getPermissionedEntitiesForObject(entity: string, entityId: number): Observable<PermissionedEntities> {
    return this.http.get<PermissionedEntities>(`${this.urls.entityObjectPermission}/${entity}/${entityId}/users`);
  }

  addPermissionedEntityToObject({ entity, entityId, permissionedEntityId, permissionedEntityType, roleId }: EntityObjectPermissionRequest): Observable<SharePermissionedUser | SharePermissionedGroup> {
    return this.http.post<SharePermissionedUser>(`${this.urls.entityObjectPermission}/${entity}/${entityId}/${permissionedEntityType}/${permissionedEntityId}`, {
      roleId,
    });
  }

  deletePermissionedEntityFromObject(
    { entity, entityId, permissionedEntityId, permissionedEntityType }: Omit<EntityObjectPermissionRequest, 'roleId'>,
  ): Observable<void> {
    return this.http.delete<void>(`${ this.urls.entityObjectPermission }/${ entity }/${ entityId }/${permissionedEntityType}/${ permissionedEntityId }`);
  }

  updateObjectRole({ entity, entityId, permissionId, roleId }: EntityObjectPermissionUpdateRequest): Observable<SharePermissionedUser | SharePermissionedGroup> {
    return this.http.patch<SharePermissionedUser>(`${this.urls.entityObjectPermission}/${entity}/${entityId}/${permissionId}`, {
      roleId,
    });
  }

  transferRole(
    requestParams: Pick<EntityObjectPermissionRequest, 'entity' | 'entityId'>,
    payload: TransferRoleRequestPayload
  ): Observable<TransferRoleResponse> {
    const { entityId, entity } = requestParams;

    return this.http.patch<TransferRoleResponse>(
      `${this.urls.entityObjectPermission}/${entity}/${entityId}/transfer-role`,
      payload
    );
  }
}
