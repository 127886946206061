import { Directive, Input, OnInit, inject } from "@angular/core";
import { HostingAnalyticsService } from "../hosting-analytics.service";

@Directive({
    selector: '[initHostingPublicAnalytics]',
    standalone: true,
})
export class InitHostingPublicAnalyticsDirective implements OnInit {
    @Input() initHostingPublicAnalytics: boolean = true;

    hostingAnalyticsService = inject(HostingAnalyticsService);

    ngOnInit(): void {
        if(!this.initHostingPublicAnalytics) return;
        this.hostingAnalyticsService.initPublicAnalytics();
    }
}