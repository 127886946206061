import { createSelector } from '@ngrx/store';
import { getIosSupportedFPS, mapInvitedUserToParticipant } from '@openreel/frontend/common/services';
import {
  CaptureTeleprompterStatus,
  NextgenParticipant,
  TeleprompterParticipant,
  UserRoleType,
} from '@openreel/frontend/common/interfaces';
import { selectSession, selectSessionDetails } from '../session.selectors';
import { selectStreamingAudioStream, selectStreamToken, selectVideoStream } from '../stream/stream.selectors';
import { invitedUserAdapter, twilioParticipantAdapter } from './interfaces/participant-store.interface';
import { getParticipantId } from './participant.reducer-helper';
import { expectedFps, expectedResolutions } from '@openreel/frontend/common';
import { PresentationStatus } from '@openreel/common';

const { selectEntities: selectIUEntities, selectAll: selectIUs } = invitedUserAdapter.getSelectors();
const { selectEntities: selectPartEntities } = twilioParticipantAdapter.getSelectors();

export const selectParticipants = createSelector(selectSession, (state) => state?.participants);

export const selectMyParticipantEntity = createSelector(selectParticipants, (state) => state?.myParticipant);

export const selectInvitedUsersState = createSelector(selectParticipants, (state) => state?.invitedUsers);

export const selectInviterUsersEntities = createSelector(selectInvitedUsersState, selectIUEntities);

export const selectMyParticipant = createSelector(
  selectVideoStream,
  selectStreamingAudioStream,
  selectStreamToken,
  selectInviterUsersEntities,
  selectMyParticipantEntity,
  (video, audio, token, invitedUsers, myParticipant) => {
    if (!token || !myParticipant) return;
    const invitedUser = invitedUsers[getParticipantId(token.identity)];
    const audioTracks = audio?.stream?.getTracks() || [];

    return <NextgenParticipant>{
      ...mapInvitedUserToParticipant(invitedUser, myParticipant),
      videoTracks: video && video.track ? [video.track] : [],
      audioTracks,
    };
  }
);

export const selectAmIDirector = createSelector(
  selectMyParticipant,
  (participant) => participant?.role === UserRoleType.Internal || participant?.role === UserRoleType.Collaborator
);

export const selectMyIdentity = createSelector(selectMyParticipantEntity, (participant) => participant?.identity);

export const selectMyLoginId = createSelector(selectMyParticipantEntity, (participant) => participant?.loginId);

export const selectMyAudioOptions = createSelector(selectMyParticipant, (participant) => participant?.audio);

export const selectVideoMirrorStatus = createSelector(
  selectMyParticipantEntity,
  (participant) => participant?.isVideoMirrored
);

export const selectParticipantState = createSelector(selectParticipants, (state) => state?.twilioParticipants);

export const selectInvitedUsers = createSelector(selectInvitedUsersState, selectIUs);

export const selectParticipantEntities = createSelector(selectParticipantState, selectPartEntities);

export const selectAllParticipants = createSelector(
  selectInviterUsersEntities,
  selectParticipantEntities,
  (invitedUsers, participants) => {
    const keys = Array.from(new Set([...Object.keys(invitedUsers || {}), ...Object.keys(participants || {})]));
    return keys.map((key) =>
      mapInvitedUserToParticipant(invitedUsers && invitedUsers[key], participants && participants[key])
    );
  }
);

export const selectParticipantByIdentity = (identity: string) =>
  createSelector(selectAllParticipants, (participants) => participants?.find((p) => p.identity === identity));

export const selectOnlineParticipants = createSelector(selectAllParticipants, (participants) =>
  participants.filter((participant) => participant.isConnected)
);

export const selectOnlineParticipantsExceptMe = createSelector(
  selectAllParticipants,
  selectMyIdentity,
  (participants, myIdentity) =>
    participants.filter((participant) => participant.isConnected && participant.identity !== myIdentity)
);

export const selectHasOnlineParticipants = createSelector(
  selectOnlineParticipants,
  (participants) => participants.length > 0
);

export const selectOnlineParticipantsIncludingMe = createSelector(
  selectOnlineParticipants,
  selectMyParticipant,
  (participants, myParticipant) => participants.concat(myParticipant)
);

export const selectIsMePresenting = createSelector(
  selectMyParticipantEntity,
  (me) => me?.presentationProperties?.status === PresentationStatus.Presenting && me?.presentationProperties?.enabled
);

export const selectIsPresenting = createSelector(selectOnlineParticipantsIncludingMe, (participants) =>
  participants.some((p) => p?.presentationProperties?.status === PresentationStatus.Presenting)
);

export const selectOnlineParticipantIdentities = createSelector(selectOnlineParticipantsIncludingMe, (participants) =>
  participants.map((p) => p?.identity)
);

export const selectOnlineParticipantIdentitiesExceptMe = createSelector(
  selectOnlineParticipantsIncludingMe,
  selectMyIdentity,
  (participants, myIdentity) => participants.map((p) => p?.identity).filter((x) => x !== myIdentity)
);

export const selectOnlineLiveParticipants = createSelector(selectOnlineParticipantsIncludingMe, (participants) =>
  participants.filter((o) => o && o.isLive)
);

export const selectOnlinePinnedParticipantsIncludingMe = createSelector(
  selectOnlineParticipantsIncludingMe,
  (participants) => participants.filter((o) => o && o.isPinned)
);

export const selectOnlineParticipantsByIdentity = createSelector(selectOnlineParticipants, (participants) => {
  const result: { [key: string]: NextgenParticipant } = {};
  participants.forEach((part) => (result[part.identity] = part));
  return result;
});

export const selectOnlineParticipantByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => participants[identity]);

export const selectOnlineNonSubjects = createSelector(selectOnlineParticipants, (participants) =>
  participants.filter((participant) => participant.role !== UserRoleType.Subject)
);

export const selectOnlineSubjects = createSelector(selectOnlineParticipants, (participants) =>
  participants.filter((participant) => participant.role === UserRoleType.Subject)
);

export const selectOnlineSubjectsIncludingMe = createSelector(selectOnlineParticipantsIncludingMe, (participants) =>
  participants.filter((participant) => participant?.role === UserRoleType.Subject)
);

export const selectTeleprompterParticipants = createSelector(selectOnlineParticipants, (participants) =>
  participants
    .filter((participant) => participant.role === UserRoleType.Subject)
    .map(
      (p) =>
        ({
          identity: p.identity,
          deviceName: p.deviceName,
          isIosDevice: p.isIosDevice,
          name: p.name,
          teleprompterProperties: p.teleprompterProperties,
        } as TeleprompterParticipant)
    )
);

export const selectPresentationParticipants = createSelector(selectOnlineParticipants, (participants) =>
  participants.map((p) => ({
    identity: p.identity,
    deviceName: p.deviceName,
    isIosDevice: p.isIosDevice,
    name: p.name,
    role: p.role,
    presentationProperties: p.presentationProperties,
  }))
);

export const selectIsSubject = createSelector(selectSessionDetails, (sessionDetails) => sessionDetails?.isSubject);

export const sessionInitialized = createSelector(
  selectSessionDetails,
  selectInvitedUsers,
  (session, invitedUsers) => session != null && invitedUsers && invitedUsers.length > 0
);

export const selectOnlineDirectors = createSelector(selectOnlineParticipants, (participants) =>
  participants.filter((participant) => participant.role === UserRoleType.Internal)
);

export const selectIsPinnedParticipantByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipants, (participants) =>
    participants.find((o) => o.identity === identity && o.isPinned)
  );

export const selectPinnedParticipants = createSelector(selectOnlineParticipants, (participants) =>
  participants.filter((o) => o.isPinned)
);

export const selectMyParticipantRole = createSelector(selectMyParticipantEntity, (state) => state?.role);

export const selectRecordingParticipants = createSelector(selectAllParticipants, (state) =>
  state.filter((p) => p.isRecording)
);

export const selectRecordingSubjects = createSelector(selectAllParticipants, (state) =>
  state.filter((p) => p.role === UserRoleType.Subject && p.isRecording)
);

export const selectParticipantIsIosDeviceByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => participants[identity]?.isIosDevice);

export const selectParticipantFramerateOptionsByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => {
    if (participants[identity]?.isIosDevice && participants[identity].deviceSupport) {
      const selectedCamera = participants[identity].deviceProperties.isFrontCamera ? 'front_cam' : 'back_cam';
      return getIosSupportedFPS(selectedCamera, participants[identity].deviceSupport);
    } else {
      return expectedFps.map((fps) => ({
        value: fps.toString(),
        label: fps.toString(),
      }));
    }
  });

export const selectParticipantFramerateByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => participants[identity]?.videoProperties?.fps);

export const selectParticipantResolutionOptionsByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => {
    if (participants[identity]?.isIosDevice && participants[identity].deviceSupport) {
      const selectedCamera = participants[identity].deviceProperties.isFrontCamera ? 'front_cam' : 'back_cam';
      const resolutionStr = participants[identity].deviceSupport[selectedCamera].resolution_support.toString();
      const resolutionArr = resolutionStr.split(',');
      return resolutionArr.map((item) => ({ value: item, label: item }));
    } else {
      return expectedResolutions
        .sort((a, b) => a.height - b.height)
        .map((item) => ({
          value: item.height.toString(),
          label: item.label ?? item.height.toString(),
        }));
    }
  });

export const selectParticipantResolutionByIdentity = (identity: string) =>
  createSelector(
    selectOnlineParticipantsByIdentity,
    (participants) => participants[identity]?.videoProperties?.resolution
  );

export const selectParticipantAudioByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => participants[identity]?.audio);

export const selectParticipantFocusStatusByIdentity = (identity: string) =>
  createSelector(
    selectOnlineParticipantsByIdentity,
    (participants) => participants[identity]?.deviceProperties?.enableManualFocus
  );

export const selectParticipantAdjustmentsPanelStatusByIdentity = (identity: string) =>
  createSelector(selectOnlineParticipantsByIdentity, (participants) => participants[identity]?.isAdjustmentsPanelOpen);

export const selectParticipantsInputAudioSource = createSelector(selectOnlineSubjects, (participants) =>
  participants
    .filter(
      (participant) => participant?.name && participant.audio?.availableInputs?.some((ai) => ai.type === 'bluetooth')
    )
    .map((participant) => ({
      name: participant.name,
      identity: participant.identity,
      bluetoothSelected:
        participant.audio.availableInputs.find((ai) => ai.type === 'bluetooth').id === participant.audio.selectedInput,
    }))
);

export const selectActiveTeleprompterParticipantIdentities = createSelector(
  selectTeleprompterParticipants,
  (participants) => participants.filter((p) => p.teleprompterProperties.isTeleprompterVisible).map((p) => p.identity)
);

export const selectIsTeleprompterEnabledForAll = createSelector(
  selectTeleprompterParticipants,
  (participants) => participants.every((p) => p.teleprompterProperties.isTeleprompterVisible) && participants.length > 0
);

export const selectActivePlayingTeleprompterParticipantIdentities = createSelector(
  selectTeleprompterParticipants,
  (participants) =>
    participants
      .filter(
        (p) =>
          p.teleprompterProperties.isTeleprompterVisible &&
          [
            CaptureTeleprompterStatus.PLAYING,
            CaptureTeleprompterStatus.PAUSED,
            CaptureTeleprompterStatus.IDLE,
          ].includes(p.teleprompterProperties.status)
      )
      .map((p) => p.identity)
);

export const selectIsTeleprompterCounting = createSelector(selectTeleprompterParticipants, (participants) =>
  participants.reduce(
    (prev, curr) => prev || curr.teleprompterProperties.status === CaptureTeleprompterStatus.COUNTING,
    false
  )
);

export const selectIsTeleprompterPlayable = createSelector(selectParticipantState, (state) =>
  state.ids.some((id) =>
    [CaptureTeleprompterStatus.PLAYING, CaptureTeleprompterStatus.PAUSED, CaptureTeleprompterStatus.IDLE].includes(
      state.entities[id].teleprompterProperties.status
    )
  )
);

export const selectIsTeleShowninAnyDevice = createSelector(
  selectParticipantState,
  (state) => !!(state.ids as string[]).find((id) => state.entities[id].teleprompterProperties.isTeleprompterVisible)
);

export const selectIsTeleprompterStopped = createSelector(selectParticipantState, (state) =>
  (state.ids as string[]).every((id) =>
    [CaptureTeleprompterStatus.STOPPED, CaptureTeleprompterStatus.PENDING].includes(
      state.entities[id].teleprompterProperties.status
    )
  )
);
