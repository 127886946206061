import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ClipSizeBytesPipe } from '@openreel/frontend/common/pipes/clip-size-bytes.pipe';

@Component({
  selector: 'or-hosting-video-upload-card',
  templateUrl: './video-upload-card.component.html',
  styleUrls: ['./video-upload-card.component.scss'],
  standalone: true,
  imports: [NgIf, MatCardModule, MatIconModule, ClipSizeBytesPipe],
})
export class HostingVideoUploadCardComponent {
  @Input() status: 'waiting' | 'uploading' | 'completing' = 'waiting';
  @Input() progress = 0;
  @Input() fileName = '';
  @Input() fileSize = 0;
  @Output() cancelUpload = new EventEmitter<Event>();

  get isWaiting(): boolean {
    return this.status == 'waiting';
  }
  get isUploading(): boolean {
    return this.status == 'uploading';
  }
  get isCompleting(): boolean {
    return this.status == 'completing';
  }

  onCancelUpload(event: Event) {
    this.cancelUpload.emit(event);
  }
}
