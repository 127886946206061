import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { range } from 'lodash';
import { Observable, fromEvent, merge } from 'rxjs';
import { distinctUntilChanged, filter, groupBy, map, mergeMap, share, take, takeUntil } from 'rxjs/operators';
import { PointerDownEvent } from '../../directives/pointer-down.directive';

export interface MoveEvent {
  newEvent: MouseEvent | TouchEvent;
  touch?: Touch;
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
  done: boolean;
}

@Injectable({ providedIn: 'root' })
export class DOMEventsService {
  public readonly documentMouseDown$ = fromEvent<MouseEvent>(this.document, 'mousedown').pipe(share());
  public readonly documentMouseUp$ = fromEvent<MouseEvent>(this.document, 'mouseup').pipe(share());
  public readonly documentMouseMove$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(share());
  public readonly documentClick$ = fromEvent<MouseEvent>(this.document, 'click').pipe(share());

  public readonly documentTouchStart$ = fromEvent<TouchEvent>(this.document, 'touchstart').pipe(share());
  public readonly documentTouchEnd$ = fromEvent<TouchEvent>(this.document, 'touchend').pipe(share());
  public readonly documentTouchMove$ = fromEvent<TouchEvent>(this.document, 'touchmove').pipe(share());

  public readonly documentKeyDown$ = fromEvent<KeyboardEvent>(this.document, 'keydown').pipe(share());
  public readonly documentKeyUp$ = fromEvent<KeyboardEvent>(this.document, 'keyup').pipe(share());

  public readonly documentCopy$ = fromEvent<ClipboardEvent>(this.document, 'copy').pipe(share());
  public readonly documentPaste$ = fromEvent<ClipboardEvent>(this.document, 'paste').pipe(share());

  public readonly keyPressed$ = merge(this.documentKeyDown$, this.documentKeyUp$).pipe(
    groupBy((event) => event.key),
    mergeMap((group$) =>
      group$.pipe(
        distinctUntilChanged((a, b) => a.type === b.type),
        map((event) => ({
          key: event.key,
          target: event.target as HTMLElement,
          pressed: event.type === 'keydown',
        }))
      )
    ),
    share()
  );

  public readonly clipboard$ = merge(this.documentCopy$, this.documentPaste$).pipe(share());

  public readonly selectStart$ = fromEvent<Event>(this.document, 'selectstart').pipe(share());
  public readonly selectionChange$ = fromEvent<Event>(this.document, 'selectionchange').pipe(share());

  public listenForMoveBeforeUpByTrigger(trigger: PointerDownEvent | MouseEvent | TouchEvent): Observable<MoveEvent> {
    const event = trigger instanceof PointerDownEvent ? trigger.originalEvent : trigger;

    if (event instanceof MouseEvent) {
      return this.listenForMouseMoveBeforeMouseUp();
    }

    return this.listenForTouchMoveBeforeTouchUp(event.changedTouches[0]);
  }

  public listenForMouseMoveBeforeMouseUp(): Observable<MoveEvent> {
    return merge(
      this.documentMouseMove$.pipe(takeUntil(this.documentMouseUp$)),
      this.documentMouseUp$.pipe(take(1))
    ).pipe(
      map((newEvent) => ({
        newEvent,
        pageX: newEvent.pageX,
        pageY: newEvent.pageY,
        clientX: newEvent.clientX,
        clientY: newEvent.clientY,
        done: newEvent.type === 'mouseup',
      }))
    );
  }

  public listenForTouchMoveBeforeTouchUp(targetTouch: Touch): Observable<MoveEvent> {
    const targetTouchId = targetTouch.identifier;

    const touchEnd$ = this.documentTouchEnd$.pipe(
      map((event) => ({
        event,
        index: range(0, event.changedTouches.length).findIndex(
          (i) => event.changedTouches.item(i).identifier === targetTouchId
        ),
      })),
      filter(({ index }) => index !== -1)
    );

    return merge(
      this.documentTouchMove$.pipe(
        map((event) => ({
          event,
          index: range(0, event.changedTouches.length).findIndex(
            (i) => event.changedTouches.item(i).identifier === targetTouchId
          ),
        })),
        filter(({ index }) => index !== -1),
        takeUntil(touchEnd$)
      ),
      touchEnd$.pipe(take(1))
    ).pipe(
      map(({ event, index }) => {
        const touch = event.changedTouches.item(index);

        return {
          newEvent: event,
          touch,
          pageX: touch.pageX,
          pageY: touch.pageY,
          clientX: touch.clientX,
          clientY: touch.clientY,
          done: event.type === 'touchend',
        };
      })
    );
  }

  constructor(@Inject(DOCUMENT) protected readonly document: Document) {}
}
