import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  SsoService,
  SsoLoginDetails,
  AuthService,
  ROUTE_DASHBOARD,
  ROUTE_DIRECTOR_LOGIN,
} from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'openreel-sso-login',
  template: '',
})
export class SsoLoginComponent implements OnInit {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private ssoService: SsoService,
    private authService: AuthService
  ) {}
  ngOnInit() {
    //logout the existing user
    this.authService.logout();
    if (this.cookieService.get('sso_login')) {
      const ssoLoginDetails: SsoLoginDetails = JSON.parse(this.cookieService.get('sso_login'));
      if (ssoLoginDetails.token) {
        this.checkSsoToken(ssoLoginDetails.token);
      } else {
        this.loginFailed(ssoLoginDetails.error);
      }
    } else {
      this.loginFailed();
    }
  }
  async checkSsoToken(token: string) {
    try {
      const tokenUserDetails = await this.ssoService.checkSsoToken(token);
      this.authService.loginViaTokenSuccess(tokenUserDetails);
      this.router.navigateByUrl(ROUTE_DASHBOARD);
    } catch (e) {
      this.loginFailed();
    }
  }
  loginFailed(error: string = 'Unable to login') {
    this.toastr.error(error, 'Error!');
    this.router.navigateByUrl(`/director/${ROUTE_DIRECTOR_LOGIN}`);
  }
}
