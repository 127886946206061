import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { InvitedUser, NextgenParticipant, UserRoleType } from '@openreel/frontend/common/interfaces';

import { getParticipantId } from '../participant.reducer-helper';

export interface InvitedUserState extends EntityState<InvitedUser> {}
export const invitedUserAdapter = createEntityAdapter<InvitedUser>({
  selectId: (user) => `${user.sum_role === UserRoleType.Subject ? 's' : 'd'}_${user.ovra_sessionUser_mapping_id}`,
});

export interface TwilioParticipantState extends EntityState<NextgenParticipant> {}
export const twilioParticipantAdapter = createEntityAdapter<NextgenParticipant>({
  selectId: (participant) => getParticipantId(participant.identity),
});

export interface ParticipantState {
  invitedUsers: InvitedUserState;
  twilioParticipants: TwilioParticipantState;
  myParticipant: NextgenParticipant;
  dominantSubjectIdentity: string;
  participantIdsWithGuides: string[];
}
