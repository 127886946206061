import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Cleanupable,
  MultiPartUploadInfo,
  RecordingMetadata,
  UploadFileInfo,
  UploadService,
  Video,
  VideoSource,
  getFileData,
} from '@openreel/frontend/common';
import { BehaviorSubject, map, take, tap } from 'rxjs';

@Component({
  selector: 'openreel-video-retry',
  templateUrl: './video-retry.component.html',
  styleUrls: ['./video-retry.component.scss'],
})
export class VideoRetryComponent extends Cleanupable implements OnInit, OnDestroy {
  selectedUpload: RecordingMetadata;
  video$ = new BehaviorSubject<Video>(null);
  recordingUrl: SafeUrl;
  adaptiveRecording: RecordingMetadata[] = [];
  nonAdaptiveRecording: RecordingMetadata[] = [];
  uploadInfo: RecordingMetadata[] = [];
  uploadError = new Map<number, boolean>();

  constructor(public readonly uploadService: UploadService, private readonly sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.uploadService.queue$
        .pipe(
          map((uploads) => uploads.filter((u) => u.metadata.videoSource !== VideoSource.PRESENTATION)),
          tap((uploads) => {
            this.handleNonAdaptiveUploads(uploads);
          })
        )
        .subscribe()
    );
    this.subscriptions.push(
      this.uploadService.mpuQueue$
        .pipe(
          map((uploads) => uploads.filter((u) => u.metadata.videoSource !== VideoSource.PRESENTATION)),
          tap((uploads) => {
            this.handleAdaptiveUploads(uploads);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleNonAdaptiveUploads(uploads: UploadFileInfo[]) {
    this.nonAdaptiveRecording = uploads.map((data) => data.metadata);
    this.updateAllRecording();
    uploads.forEach((upload) => {
      upload.status$.pipe(take(1)).subscribe((value) => {
        this.uploadError.set(upload.metadata.videoId, value.hasFailed);
      });
    });
  }

  handleAdaptiveUploads(uploads: MultiPartUploadInfo[]) {
    this.adaptiveRecording = uploads.map((data) => data.metadata);
    this.updateAllRecording();
    uploads.forEach((upload) => {
      upload.uploader.hasError$.pipe().subscribe((value) => {
        this.uploadError.set(upload.metadata.videoId, value);
      });
    });
  }

  updateAllRecording() {
    this.uploadInfo = [...this.nonAdaptiveRecording, ...this.adaptiveRecording];
    const selectedVideo = this.uploadInfo.filter((data) => data.videoId === this.selectedUpload?.videoId);
    if (selectedVideo.length === 0) {
      this.selectedUpload = null;
    }
  }

  upload() {
    if (this.selectedUpload.uploadDuringRecording) {
      this.uploadService.mpuQueue$
        .pipe(
          map((uploads) => uploads.filter((u) => u.metadata.videoId === this.selectedUpload.videoId)[0]),
          take(1),
          tap((uploads) => {
            uploads.uploader.retryUpload();
            uploads.uploader.complete();
          })
        )
        .subscribe();
    } else {
      this.uploadService.uploadVideoRecording(this.selectedUpload);
    }
  }

  onDelete() {
    if (this.selectedUpload.uploadDuringRecording) {
      this.uploadService.mpuQueue$
        .pipe(
          map((uploads) => uploads.filter((u) => u.metadata.videoId === this.selectedUpload.videoId)[0]),
          take(1),
          tap((uploads) => {
            this.uploadService.cleanMpuQueue(uploads);
            this.selectedUpload = null;
          })
        )
        .subscribe();
    } else {
      this.uploadService.removeRecordingMetadata(this.selectedUpload);
      this.selectedUpload = null;
    }
  }

  public async onVideoSelectionChange() {
    this.recordingUrl = null;
    if (this.selectedUpload) {
      const recordingBlob = await getFileData(this.selectedUpload.localFileName);
      this.video$.next({ mainBlob: recordingBlob });
      this.recordingUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(recordingBlob));
    } else {
      this.video$.next(null);
    }
  }

  public videoName(item: RecordingMetadata): string {
    return item?.fileNameForUpload;
  }
}
