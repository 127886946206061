import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'openreel-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent {
  isLightThemed = true;
  isPopup = true;
  hide = true;
  password = new FormControl(
    '',
    Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(20)])
  );
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string },
    public dialogRef: MatDialogRef<UpdatePasswordComponent>
  ) {}
  trimSpaces() {
    this.password.setValue(this.password.value.trim());
  }
  setPassword() {
    if (!this.password.valid) return;
    this.dialogRef.close({ password: this.password.value });
  }
}
