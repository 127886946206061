import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ApprovalEvent } from '../../interfaces';
import { ApprovableTypes, VIDEO_APPROVAL_HISTORY } from '@openreel/common';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs';
import { Injectable, Injector, inject } from '@angular/core';
import { FeatureFlaggingService, HostableApprovalService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApprovalHistoryDialogComponent } from './approval-history-dialog/approval-history-dialog.component';

interface ApprovalHistoryState {
  approvableId: number | null;
  approvableType: ApprovableTypes | null;

  loading: boolean;
  approvalEvents: ApprovalEvent[];
}

@Injectable()
export class ApprovalHistoryStore extends ComponentStore<ApprovalHistoryState> {
  private approvalService = inject(HostableApprovalService);
  private toastr = inject(ToastrService);
  private dialog = inject(MatDialog);
  private featureFlagService = inject(FeatureFlaggingService);
  private injector = inject(Injector);

  private isEnabledVideoApprovalsHistory$ = this.featureFlagService.isFeatureFlagEnabled(VIDEO_APPROVAL_HISTORY);

  private approvableId$ = this.select(state => state.approvableId);
  private approvableType$ = this.select(state => state.approvableType);

  isLoading$ = this.select((state) => state.loading);
  approvalEvents$ = this.select((state) => state.approvalEvents);
  hasApprovalHistory$ = this.select(this.approvalEvents$, (history) => history.length > 0);

  isAvailableApprovalHistory$ = this.select(
    this.isEnabledVideoApprovalsHistory$,
    this.hasApprovalHistory$,
    (isEnabled, hasHistory) => isEnabled && hasHistory
  );

  constructor() {
    super({
      approvableId: null,
      approvableType: null,

      loading: false,
      approvalEvents: [],
    });
  }

  private _setIsLoading = this.updater((state, loading: boolean) => ({ ...state, loading }));
  private _setApprovalEvents = this.updater((state, events: ApprovalEvent[]) => ({ ...state, approvalEvents: events }));
  private _setApprovableIdAndType = this.updater((state, [id, type]: [number, ApprovableTypes]) => ({ ...state, approvableId: id, approvableType: type }))

  fetchApprovalHistory(approvableId: number, approvableType: ApprovableTypes) {
    this._setApprovableIdAndType([approvableId, approvableType]);
    this._fetchApprovalHistory({ approvableId, approvableType });
  }

  refreshApprovalHistory = this.effect<void>($ => $.pipe(
    withLatestFrom(this.approvableId$, this.approvableType$),
    filter(([_, approvableId, approvableType]) => !!approvableId && !!approvableType),
    tap(([_, approvableId, approvableType]: [void, number, ApprovableTypes]) => {
      this._fetchApprovalHistory({ approvableId, approvableType })
    })
  ))

  private _fetchApprovalHistory = this.effect<{ approvableId: number; approvableType: ApprovableTypes }>((params$) =>
    params$.pipe(
      withLatestFrom(this.isEnabledVideoApprovalsHistory$),
      filter(([params, isEnabledApprovalHistoryFeature]) => !!isEnabledApprovalHistoryFeature),
      tap(() => this._setIsLoading(true)),
      switchMap(([params]) =>
        this.approvalService.fetchApprovableHistory(params.approvableId, params.approvableType).pipe(
          tapResponse(
            (result) => {
              this._setIsLoading(false);
              this._setApprovalEvents(result);
            },
            (error: Error) => {
              this._setIsLoading(false);
              this.toastr.error(error.message, 'Error!');
            }
          )
        )
      )
    )
  );

  openApprovalHistoryDialog() {
    return this.dialog.open(ApprovalHistoryDialogComponent, {
      width: '740px',
      injector: this.injector
    });
  }
}
