<div fxLayout="column" [formGroup]="form">
  <div class="section-header" fxLayoutAlign="space-between center">
    <h2 class="headline-h400 title">General access</h2>

    <div fxLayoutAlign="flex-start center" fxLayoutGap="5px">
      <mat-slide-toggle
        *ngIf="isPublic"
        labelPosition="before"
        color="primary"
        class="toggle-advanced"
        [formControl]="advancedModeControl"
      >
        <h2 data-cy="toggle" class="headline-h300 title toggle-title">
          Advanced settings
        </h2>
      </mat-slide-toggle>
    </div>
  </div>

  <div class="access-type">
    <mat-form-field
      appearance="outline"
      class="access-type form-control"
      matTooltip="{{ data.userObjectRole | titlecase }} does not have permission"
      [matTooltipDisabled]="form.controls['accessType'].enabled"
    >
      <mat-select formControlName="accessType">
        <mat-option
          [attr.data-cy]="data.type"
          [value]="data.type"
          *ngFor="let data of ACCESS_TYPE_OPTIONS"
        >
          {{ data.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-divider class="sharing-divider"></mat-divider>

  <div class="users-search">
    <mat-form-field appearance="outline" class="form-control">
      <input
        data-cy="search-user"
        matInput
        type="text"
        name="fname"
        autocomplete="off"
        placeholder="Add others: Type email, team name or team members name"
        [formControl]="usersSearchControl"
        [matAutocomplete]="auto"
      />

      <mat-autocomplete
        data-cy="add-others"
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="addPermissionedEntity($event)"
      >

        <mat-option *ngFor="let option of entities$ | async" [value]="option">
          <div fxLayoutAlign="flex-start center" fxLayoutGap="5px">
            <ngx-avatar
              *ngIf="option.entity === 'user'; else groupAvatar"
              [name]="option.name"
              bgColor="#0066ff"
              size="25"
            ></ngx-avatar>

            <ng-template #groupAvatar>
              <div class="group-avatar">
                <mat-icon>group_add</mat-icon>
              </div>
            </ng-template>

            <span class="name paragraph-p100">{{ option.name }}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<div class="users-list">
  <openreel-users-list></openreel-users-list>
</div>
