import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { FeatureFlaggingService } from '../feature-flagging/feature-flagging.service';
import { Injectable, inject } from '@angular/core';
import { GUEST_USER_ACCOUNT_INFO, SelfSignupUIVersion, USE_SELF_SINGUP_UI_V2 } from '../../constants/signup.constants';
import { GuestUserAccountInfo, SELF_SIGNUP_UI_V2 } from '@openreel/common';
import { filter, mergeMap, switchMap, takeUntil } from 'rxjs';
import { SignupService } from './signup.service';
import { RegistrationRespDto, SocialAuthorizedUser } from '../../interfaces';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  GUEST_ACCOUNT_ACTIVATED,
  GUEST_FILL_ACCOUNT_INFO,
  ROUTE_DIRECTOR,
  ROUTE_DIRECTOR_LOGIN
} from '../../route-utils';
import { MagicLinkService } from './magic-link.service'

interface State {
    uiVersion: SelfSignupUIVersion | null;
}

@Injectable()
export class SelfSignupFlowManagerService extends ComponentStore<State> {
    private readonly featureFlaggingService = inject(FeatureFlaggingService);
    private readonly signupService = inject(SignupService);
    private readonly magicLinkService = inject(MagicLinkService)
    private readonly toastr = inject(ToastrService);
    private readonly router = inject(Router);
    private readonly useSelfSignupUiV2 = inject(USE_SELF_SINGUP_UI_V2, { optional: true });

    constructor() {
        super({
            uiVersion: null,
        })

        if(!this.useSelfSignupUiV2) {
            this.patchState({ uiVersion: SelfSignupUIVersion.V1 })
        } else {
            this.featureFlaggingService
                .isFeatureFlagEnabled(SELF_SIGNUP_UI_V2)
                .pipe(takeUntil(this.destroy$))
                .subscribe(isEnabled => {
                    this.patchState({ uiVersion: isEnabled ? SelfSignupUIVersion.V2 : SelfSignupUIVersion.V1 });
                })
        }
    }

    public readonly uiVersion$ = this.select(state => state.uiVersion).pipe(filter(version => !!version));
    public readonly isUiV1$ = this.select(this.uiVersion$, version => version == SelfSignupUIVersion.V1);
    public readonly isUiV2$ = this.select(this.uiVersion$, version => version == SelfSignupUIVersion.V2);


    public readonly signupUser = this.effect<GuestUserAccountInfo>(guest$ => guest$.pipe(
        mergeMap(guest => this.signupService.signupUser(guest).pipe(
            tapResponse(
                (response: RegistrationRespDto) => {
                    guest.account_id = response.data.account_id;
                    localStorage.setItem(GUEST_USER_ACCOUNT_INFO, JSON.stringify(guest));
                    this.router.navigate([`/${ROUTE_DIRECTOR}/${GUEST_ACCOUNT_ACTIVATED}`]);
                },
                (error: Error) => {
                    this.toastr.error(error.message ? error.message : 'An error occurred. Please try again later.', 'Error!');
                }
            )
        ))
    ))

    public readonly checkSocialUserRegistration = this.effect<string>(userToken$ => userToken$.pipe(
      switchMap(userToken => this.magicLinkService.checkSocialUserRegistration(userToken).pipe(
        tapResponse(
          (res) => {
            const guest = this.getGuestInfo() ?? {};
            guest.token = userToken;
            guest.is_social_user = true;
            guest.available_accounts = res.data;
            this.saveGuestInfo(guest);
            this.router.navigate([`/${ROUTE_DIRECTOR}/${GUEST_FILL_ACCOUNT_INFO}`]);
          },
          (error: Error) => {
            this.deleteGuestInfo();
            this.toastr.error(error.message ? error.message : 'An error occurred. Please try again later.', 'Error!');
            this.router.navigate([`/${ROUTE_DIRECTOR}/${ROUTE_DIRECTOR_LOGIN}`]);
          }
        )
      ))
    ))

    public getGuestInfo(): Partial<GuestUserAccountInfo> {
      const guestInfo = localStorage.getItem(GUEST_USER_ACCOUNT_INFO);
      if(!guestInfo) return null;
      return JSON.parse(guestInfo);
    }

    public saveGuestInfo(guest: Partial<GuestUserAccountInfo>) {
      localStorage.setItem(GUEST_USER_ACCOUNT_INFO, JSON.stringify(guest));
    }

    public deleteGuestInfo() {
      localStorage.removeItem(GUEST_USER_ACCOUNT_INFO);
    }
}
