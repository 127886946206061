import {
  commonenv,
  TagCreateDto,
  TagDto,
  TagsQueryDto,
  TagUpdateDto,
  TagWithUsageCountDto, UpdateEntityTagsDto
} from '@openreel/frontend/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TagEntityType } from '@openreel/common';

@Injectable({
  providedIn: 'root'
})
export class EntityTagsService {
  private readonly http = inject(HttpClient);
  private readonly urls = {
    tag: commonenv.nextGenApiUrl + 'tags',
  };

  fetchTags(dto: TagsQueryDto): Observable<TagWithUsageCountDto[]> {
    let params = new HttpParams();
    if(dto.entityType) params = params.append('entityType', dto.entityType);
    return this.http.get<TagWithUsageCountDto[]>(this.urls.tag, { params })
  }

  createTag(dto: TagCreateDto): Observable<TagDto> {
    return this.http.post<TagDto>(this.urls.tag, dto);
  }

  updateTag(tagId: number, dto: TagUpdateDto): Observable<TagDto> {
    return this.http.put<TagDto>(`${this.urls.tag}/${tagId}`, dto);
  }

  deleteTag(tagId: number): Observable<void> {
    return this.http.delete<void>(`${this.urls.tag}/${tagId}`);
  }

  fetchEntityTags(entityId: number, entityType: TagEntityType): Observable<TagDto[]> {
    return this.http.get<TagDto[]>(`${this.urls.tag}/entity/${entityType}/${entityId}`);
  }

  updateEntityTags(entityId: number, entityType: TagEntityType, dto: UpdateEntityTagsDto): Observable<TagDto[]> {
    return this.http.put<TagDto[]>(`${this.urls.tag}/entity/${entityType}/${entityId}`, dto);
  }
}
