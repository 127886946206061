<div *ngIf="!!commentsToken" class="comments-container">
  <ng-container *featureFlag="COMMENTS_VISIBILITY_FEATURE_FLAG">
    <div *ngIf="showCommentsVisibilityToggle" class="comments-visibility-toggle-container">
      <span>Allow comments</span>
      <mat-slide-toggle
        color="primary"
        [checked]="isEnable$ | async"
        (change)="toggleAllowComments($event.checked)"
      >
      </mat-slide-toggle>
    </div>

    <p class="comments-disabled-text" *ngIf="!showCommentsVisibilityToggle && commentsDisabled">
      Comments are turned off for this video.
    </p>
  </ng-container>

  <ng-container *ngIf="isEnable$ | async">
    <form *ngIf="!readOnly" class="comment-form" [formGroup]="addCommentForm" (ngSubmit)="addComment()">
      <div class="comment-form-content">
        <div class="comment-box">
          <mat-form-field appearance="outline" class="comment-input">
            <textarea
              matInput
              formControlName="content"
              placeholder="Enter comment here..."
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
          <button
              type="submit"
              [disabled]="addCommentForm.invalid || !addCommentForm.controls.content.value.length"
              mat-flat-button
              color="primary"
              class="send-btn"
            >
              <mat-icon>send</mat-icon>
          </button>
        </div>
        <div class="divider"></div>
        <div class="comment-details">
          <div class="timecode-box-container">
            <ng-container *ngIf="!(isActiveDrawMode$ | async) && (accessibility$ | async) == 'internal'">
              <mat-checkbox *ngIf="!!duration" color="primary" [checked]="isTimecoded$ | async" (change)="changeIsTimecoded($event)">Comment at</mat-checkbox>
              <input durationInput type="text" formControlName="start" class="duration-box" />
            </ng-container>
          </div>
          <div class="accessibility-container">
            <ng-container *featureFlag="PUBLIC_COMMENTS_FEATURE_FLAG">
              <mat-select
                *ngIf="!(isActiveDrawMode$ | async) && showCommentAccessibility && commentableType == 'hostable'"
                class="accessibility"
                panelClass="comment-form-accessibility-select-panel"
                [value]="accessibility$ | async"
                (selectionChange)="changeAccessibility($event)"
              >
                <mat-option value="internal">Internal</mat-option>
                <mat-option value="public">Public</mat-option>
              </mat-select>
            </ng-container>
          </div>
          <div *ngIf="showDrawableToolbar$ | async" [matTooltip]="!(isTimecoded$ | async) ? 'Annotations require a timecode. Please enter a timecode to add an annotation' : ''">
            <or-drawable-toolbar [disabled]="!(isTimecoded$ | async)"></or-drawable-toolbar>
          </div>
        </div>
      </div>
      <div>
        <span class="error-text">
          <ng-container *ngIf="addCommentForm.controls.content.hasError('maxlength')">
            Comment cannot be more than {{ addCommentForm.controls.content.getError('maxlength')['requiredLength'] }} characters long
          </ng-container>
          <ng-container *ngIf="addCommentForm.controls.start.hasError('required')">
            Comment time is required.
          </ng-container>
          <ng-container *ngIf="addCommentForm.controls.start.hasError('invalidDuration')">
            Invalid duration format.
          </ng-container>
          <ng-container *ngIf="addCommentForm.controls.start.hasError('minDuration')">
            Comment time must be {{ addCommentForm.controls.start.getError('minDuration')['required'] | formatDuration }} or higher.
          </ng-container>
          <ng-container *ngIf="addCommentForm.controls.start.hasError('maxDuration')">
            Comment time is past video length ({{ addCommentForm.controls.start.getError('maxDuration')['required'] | formatDuration }}).
          </ng-container>
        </span>
      </div>
    </form>

    <div *ngIf="comments$ | async as comments" class="comments-list">
      <div *ngIf="showHeader && comments?.length" class="comments-header">
        <h4>Comments</h4>
        <mat-select
          [value]="sortOption$ | async"
          (selectionChange)="changeSortOption($event.value)"
          class="comment-sort-select"
          panelClass="comment-sort-select-panel"
        >
          <mat-option [value]="'timecodeMs'">Timecode</mat-option>
          <mat-option [value]="'createdAt'">Created at</mat-option>
        </mat-select>
      </div>
      <or-comment-list
        [commentTemplate]="'internal'"
        [comments]="comments"
        [selectedComment]="selectedComment$ | async"
        [isAllCommentsLoaded]="isAllCommentsLoaded$ | async"
        [isLoadingComments]="isLoading$ | async"
        [showAccessibility]="showCommentAccessibility && (isPublicCommentsFeatureEnabled$ | async) && commentableType == 'hostable'"
        (clickComment)="selectComment($event)"
        (updateComment)="updateComment($event)"
        (resolveComment)="resolveComment($event)"
        (deleteComment)="deleteComment($event)"
        (loadMoreComments)="fetchComments()"
      ></or-comment-list>
    </div>

  </ng-container>
</div>
