import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinSec',
  standalone: true,
})
export class SecondsToMinSecPipe implements PipeTransform {
  public transform(value: number) {
    value = Number(value);

    const mins = Math.floor(value / 60);
    let secs = Math.floor(value % 60).toString();

    if (mins > 0) {
      secs = secs.padStart(2, '0');
    }

    return `${[mins, secs].filter(Boolean).join(':')}s`;
  }
}
