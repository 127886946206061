import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OpenreelAlertComponent, AlertDialogData, AlertDialogResult, AlertType } from './openreel-alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private readonly dialog: MatDialog) {}

  info(message: string) {
    this.dialog.open<OpenreelAlertComponent, AlertDialogData>(OpenreelAlertComponent, {
      backdropClass: 'blurred',
      data: {
        message,
        type: AlertType.Info,
      },
    });
  }

  error(message: string, title?: string) {
    this.dialog.open<OpenreelAlertComponent, AlertDialogData>(OpenreelAlertComponent, {
      backdropClass: 'blurred',
      data: {
        message,
        title,
        type: AlertType.Error,
      },
    });
  }

  async confirm(
    message: string,
    options?: {
      icon?: string;
      title?: string;
      confirmButtonText?: string;
      rejectButtonText?: string;
      panelClass?: string | string[];
      showCloseIcon?: boolean;
    }
  ) {
    const result = await this.dialog
      .open<OpenreelAlertComponent, AlertDialogData, AlertDialogResult>(OpenreelAlertComponent, {
        backdropClass: 'blurred',
        panelClass: options?.panelClass,
        data: {
          message,
          icon: options?.icon,
          type: AlertType.Confirmation,
          title: options?.title,
          confirmButtonText: options?.confirmButtonText,
          rejectButtonText: options?.rejectButtonText,
          showCloseIcon: options?.showCloseIcon,
        },
      })
      .afterClosed()
      .toPromise();

    return result?.value;
  }
}
