import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TeleprompterScriptEditPopupComponent } from './teleprompter-script-edit-popup/teleprompter-script-edit-popup.component';
import { OpenreelCommonModule } from '@openreel/frontend/common';
import { FeatureFlagPipe } from '@openreel/frontend/common/pipes/feature-flag.pipe';
import { FeatureEnabledPipe } from '@openreel/frontend/common/pipes/feature-enabled.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateProjectComponent } from './create-project/create-project.component';
import { RecordingsListComponent } from './recordings-list/recordings-list.component';
import { InvitationListCreatorComponent } from './invitation-list-creator/invitation-list-creator.component';
import { RecordingItemComponent } from './recordings-list/recording-item/recording-item.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { BulkActionComponent } from './bulk-action/bulk-action.component';
import { LobbyComponent } from './lobby/lobby/lobby.component';
import { MiniLobbyComponent } from './lobby/mini-lobby/mini-lobby.component';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { ShowTextPopupComponent } from './show-text-popup/show-text-popup.component';
import { IsMemberOnlinePipe } from './pipes/is-member-online.pipe';
import { LiveDestinationsComponent } from '../director/capture-live/live-destinations/live-destinations.component';
import { ScheduledSessionDateTimePipe } from './pipes/scheduled-session-date-time.pipe';
import { PRESENTATION_FACADE } from '@openreel/ui/openreel-presentations';
import { PresentationService } from './services/presentation.service';
import { RecordingsListClipComponent } from './recordings-list-clip/recordings-list.component';
import { RecordingItemClipComponent } from './recordings-list-clip/recording-item-clip/recording-item.component';
import { RichTextEditorComponent } from '@openreel/frontend/common/components/rich-text-editor';
import { VolumeMeterComponent } from '@openreel/frontend/common/components/volume-meter';
import { TitleLineComponent } from '@openreel/frontend/common/components/title-line';
import { OrInviteInternalComponent } from '@openreel/frontend/common/components/or-invite-internal-user';
import { ClipDurationPipe } from '@openreel/frontend/common/pipes/clip-duration.pipe';
import { ClipSizeBytesPipe } from '@openreel/frontend/common/pipes/clip-size-bytes.pipe';
import { ClipGroupStatusPipe } from '@openreel/frontend/common/pipes/clip-group-status.pipe';
import { TextHighlightPipe } from '@openreel/frontend/common/pipes/text-highlight.pipe';
import { ImgFallbackDirective } from '@openreel/frontend/common/directives/img-fallback.directive';
import { MatIconModule } from '@angular/material/icon';
import { PlayRecordingPopupComponent } from '@openreel/ui/play-recording-popup/play-recording-popup.component';

@NgModule({
  declarations: [
    TeleprompterScriptEditPopupComponent,
    RecordingsListComponent,
    RecordingItemComponent,
    RecordingsListClipComponent,
    RecordingItemClipComponent,
    InvitationListCreatorComponent,
    BulkActionComponent,
    LobbyComponent,
    MiniLobbyComponent,
    IsMemberOnlinePipe,
    UnsupportedBrowserComponent,
    ShowTextPopupComponent,
    LiveDestinationsComponent,
    ScheduledSessionDateTimePipe,
  ],
  exports: [
    RecordingsListComponent,
    RecordingsListClipComponent,
    InvitationListCreatorComponent,
    BulkActionComponent,
    LobbyComponent,
    MiniLobbyComponent,

    ShowTextPopupComponent,
    IsMemberOnlinePipe,
    LiveDestinationsComponent,
    RecordingItemComponent,
    RecordingItemClipComponent,
    ScheduledSessionDateTimePipe,
  ],
  providers: [
    Clipboard,
    DecimalPipe,
    {
      provide: PRESENTATION_FACADE,
      useClass: PresentationService,
    },
  ],
  imports: [
    MatCardModule,
    PlayRecordingPopupComponent,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    FlexLayoutModule,
    OpenreelCommonModule,
    ReactiveFormsModule,
    RichTextEditorComponent,
    VolumeMeterComponent,
    TitleLineComponent,
    OrInviteInternalComponent,
    ImgFallbackDirective,
    FeatureFlagPipe,
    FeatureEnabledPipe,
    ClipDurationPipe,
    ClipSizeBytesPipe,
    ClipGroupStatusPipe,
    TextHighlightPipe,
    CreateProjectComponent,
  ],
})
export class SharedModule {}
