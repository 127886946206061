import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

export enum AlertType {
  Info,
  Error,
  Confirmation,
}

export interface AlertDialogData {
  type: AlertType;
  message: string;
  icon?: string;
  title?: string;
  confirmButtonText?: string;
  rejectButtonText?: string;
  showCloseIcon?: boolean;
}

export interface AlertDialogResult {
  value?: boolean;
}

@Component({
  selector: 'openreel-alert',
  templateUrl: './openreel-alert.component.html',
  styleUrls: ['./openreel-alert.component.scss'],
})
export class OpenreelAlertComponent {
  AlertTypeEnum = AlertType;
  isLightThemed = true;
  isPopup = true;

  constructor(
    public dialogRef: MatDialogRef<OpenreelAlertComponent, AlertDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {}

  ok = () => this.dialogRef.close();
  yes = () => this.dialogRef.close({ value: true });
  no = () => this.dialogRef.close({ value: false });
}
