import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AssetId, AssetsFileProviderType } from '@openreel/creator/common';
import { Observable, of, throwError } from 'rxjs';
import { catchError, share, switchMap, tap } from 'rxjs/operators';
import { AssetsBaseService } from './assets-base.service';

@Injectable({
  providedIn: 'root',
})
export class AssetPreloadingService {
  private loadedAssets = new Map<string, string>();
  private loadingAssets = new Map<string, Observable<string>>();

  constructor(
    private readonly assetsService: AssetsBaseService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  addAsset(id: AssetId, provider: AssetsFileProviderType, url: string) {
    const key = this.getKey(id, provider);
    this.loadedAssets.set(key, url);
  }

  preloadAsset(
    id: AssetId,
    provider: AssetsFileProviderType,
    mediaType: 'image' | 'video' | 'audio'
  ): Observable<string> {
    const key = this.getKey(id, provider);

    if (this.loadedAssets.has(key)) {
      return of(this.loadedAssets.get(key));
    }

    if (this.loadingAssets.has(key)) {
      return this.loadingAssets.get(key);
    }

    const observable =
      provider === 'or-local'
        ? of('')
        : this.assetsService.getAssetUrlById(provider, id).pipe(
            switchMap((url) => this.doPreloadAsset(url, mediaType)),
            tap((url) => {
              this.loadingAssets.delete(key);
              this.loadedAssets.set(key, url);
            }),
            catchError((error) => {
              this.loadingAssets.delete(key);
              return throwError(error);
            }),
            share()
          );

    this.loadingAssets.set(key, observable);

    return observable;
  }

  private getKey(id: AssetId, provider: AssetsFileProviderType) {
    if (provider === 'url') {
      return id as string;
    }

    return `${provider}-${id}`;
  }

  private doPreloadAsset(src: string, mediaType: 'image' | 'video' | 'audio') {
    switch (mediaType) {
      case 'image':
        return this.preloadImage(src);
      case 'video':
      case 'audio':
        return this.preloadMedia(src, mediaType);
    }
  }

  private preloadImage(src: string): Observable<string> {
    return new Observable<string>((observer) => {
      const element = this.document.createElement('img');
      element.src = src;
      element.crossOrigin = 'anonymous';
      element.onload = () => {
        observer.next(src);
        observer.complete();
        element.remove();
      };
      element.onerror = () => {
        observer.error();
        element.remove();
      };
      element.style.display = 'none';

      this.document.body.appendChild(element);

      return () => {
        element.remove();
      };
    });
  }

  private preloadMedia(src: string, type: 'audio' | 'video'): Observable<string> {
    return new Observable<string>((observer) => {
      const element = this.document.createElement(type);
      element.src = src;
      element.preload = 'auto';
      element.crossOrigin = 'anonymous';
      element.oncanplaythrough = () => {
        observer.next(src);
        observer.complete();
        element.remove();
      };
      element.onerror = () => {
        observer.error();
        element.remove();
      };
      element.style.display = 'none';

      this.document.body.appendChild(element);

      return () => {
        element.remove();
      };
    });
  }
}
