import { LayerOptions, LottieLayer, WorkflowDataDto } from '../interfaces';
import { getLayerFromId, getLayers } from './timelines.helpers';

export const getTextLayerFromMainLayer = (
  workflow: WorkflowDataDto,
  mainLayerId: string
): LayerOptions & LottieLayer => {
  const mainLayerWithTimeLine = getLayerFromId(mainLayerId, workflow);

  for (const { layer } of getLayers(workflow, { types: ['lottie'], sectionIds: [mainLayerWithTimeLine.sectionId] })) {
    const textLayerWithTimeline = getLayerFromId(layer.layerId, workflow);
    if (textLayerWithTimeline?.timeline?.id === mainLayerWithTimeLine?.timeline?.pairId) {
      if (layer.type === 'lottie') {
        return layer;
      }
    }
  }

  return null;
};

export const getTextLayerWithAsset = (workflow: WorkflowDataDto, assetId: string): (LayerOptions & LottieLayer)[] => {
  const layers: (LayerOptions & LottieLayer)[] = [];

  for (const { layer } of getLayers(workflow, { types: ['lottie'] })) {
    if (layer.type === 'lottie' && layer.assetId === assetId) {
      layers.push(layer);
    }
  }

  return layers;
};

export const extractSubjectName = (personName: string) => {
  if (personName) {
    let subjectFirstName = personName.split(' ')[0];
    let subjectLastName = personName.split(' ').slice(1).join(' ');
    subjectFirstName = subjectFirstName.charAt(0).toUpperCase() + subjectFirstName.slice(1);
    subjectFirstName = subjectFirstName.trim();
    subjectLastName = subjectLastName.charAt(0).toUpperCase() + subjectLastName.slice(1);
    subjectLastName = subjectLastName.trim();

    return {
      subjectFirstName,
      subjectLastName,
    };
  }

  return null;
};
