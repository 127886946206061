import { Pipe, PipeTransform } from '@angular/core';
import { EntityRole } from '@openreel/frontend/common';

@Pipe({
  name: 'canManageRole',
  standalone: true,
})
export class CanManageRolePipe implements PipeTransform {
  transform(roleId: EntityRole['id'], canManageRoleIds: Array<EntityRole['id']> = []): boolean {
    return canManageRoleIds.includes(roleId);
  }
}
