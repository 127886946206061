import * as AuthActions from './auth.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService, FeatureFlaggingService, UserRoleType } from '@openreel/frontend/common';
import { combineLatest, from } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { fetchDirectorUserDetails } from './auth.actions';
import { isEqual } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  constructor(
    public auth: AuthService,
    private readonly featureFlaggingService: FeatureFlaggingService,
    private actions$: Actions
  ) {}

  deviceTokenChange$ = createEffect(() =>
    from(this.auth.token$).pipe(switchMap((token) => [AuthActions.setToken({ token })]))
  );
  roleChange$ = createEffect(() => from(this.auth.role$).pipe(switchMap((role) => [AuthActions.setRole({ role })])));

  accountSelected$ = createEffect(() =>
    from(this.auth.selectedAccountId$).pipe(switchMap(() => [fetchDirectorUserDetails()]))
  );

  fetchDirectorUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.fetchDirectorUserDetails),
      switchMap(() =>
        combineLatest([
          this.auth.selectedAccountId$.asObservable(),
          this.auth.userId$.asObservable(),
          this.auth.userDetails$,
        ]).pipe(
          filter(([accountId, userId, user]) => Boolean(accountId) && Boolean(userId) && Boolean(user)),
          distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1] && isEqual(a[2], b[2])),
          map(([, , director]) => AuthActions.setDirectorUserDetails({ director }))
        )
      )
    )
  );

  accountFeatureFlag$ = createEffect(
    () =>
      from(this.auth.role$).pipe(
        distinctUntilChanged(),
        filter((role) => !!role && role !== UserRoleType.Internal),
        tap(() => {
          this.featureFlaggingService.checkAccountFeatureFlags();
        })
      ),
    { dispatch: false }
  );

  logoutResetFeatureFlag$ = createEffect(
    () =>
      from(this.auth.logout$).pipe(
        tap(() => {
          this.featureFlaggingService.resetFeatureFlags();
        })
      ),
    { dispatch: false }
  );
}
