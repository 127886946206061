import { coerceArray } from '@angular/cdk/coercion';
import { AclPermission, HostableUserPermission } from '@openreel/frontend/common';
import { Operator } from './acl-operator.type';
import { AclMemoize } from './acl-memoize';

/**
 * internal
 */
export function toAclFunction(
  permissions: HostableUserPermission[],
  isFeatureFlagEnabled: boolean,
  aclMemoize?: AclMemoize,
): (requestedPermission: AclPermission | AclPermission[], operator?: Operator) => boolean {
  return (requestedPermission: AclPermission | AclPermission[], operator: Operator = 'OR'): boolean => {
    const requestedPermissionsArray = coerceArray(requestedPermission).filter(Boolean);

    if (!requestedPermissionsArray.length || !isFeatureFlagEnabled) {
      return true;
    }

    if (!Array.isArray(permissions) || !permissions.length) {
      return false;
    }

    if (aclMemoize && aclMemoize.hasValue(requestedPermission)) {
      return aclMemoize.getValue(requestedPermission);
    }

    const isUserHasPermissions = (permissionScope: string): boolean => {
      const [action, field] = permissionScope.split('.');
      const permission = permissions.find((permission) => permission.action === action);

      let hasPermission: boolean;

      if (!permission) {
        hasPermission = false;
      } else if (typeof permission.fields === 'string' && permission.fields === '*') {
        hasPermission = true;
      } else if (!field) {
        hasPermission = permission.action === action;
      } else {
        hasPermission = permission.fields.includes(field);
      }

      return hasPermission;
    };

    const hasPermission = operator === 'OR'
      ? requestedPermissionsArray.some(isUserHasPermissions)
      : requestedPermissionsArray.every(isUserHasPermissions);

    if (aclMemoize) {
      aclMemoize.setValue(requestedPermission, hasPermission);
    }

    return hasPermission;
  };
}
