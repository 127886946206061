import { clamp, isEqual, isNumber, round } from 'lodash';
import { Bounds, Point, ResizeDirections, SimpleBounds, SimpleBoundsWithAngle } from '../interfaces';
import { PI_HALF } from './math.helpers';
import { getRotatedPoint } from './rotation.helpers';

const ALLOWED_ASPECT_RATIO_ERROR_MARGIN = 0.03;

export function normalizeBounds(bounds: Bounds): Bounds {
  bounds.x = round(bounds.x, 2);
  bounds.y = round(bounds.y, 2);

  if (isNumber(bounds.width)) {
    bounds.width = round(bounds.width, 2);
  }
  if (isNumber(bounds.height)) {
    bounds.height = round(bounds.height, 2);
  } else {
    delete bounds.height;
  }

  if (bounds.angleRad == null) {
    bounds.angleRad = 0;
  }

  return bounds;
}

export function normalizeSimpleBounds(bounds: SimpleBounds): SimpleBounds {
  return {
    x: round(bounds.x, 2),
    y: round(bounds.y, 2),
    width: round(bounds.width, 2),
    height: round(bounds.height, 2),
  };
}

export function deleteRedundantHeightAndNormalize(bounds: Bounds, oldBounds: Bounds) {
  if (!isNumber(oldBounds.height)) {
    delete bounds.height;
  }

  return normalizeBounds(bounds);
}

export function boundsEqual(bound1: Bounds, bound2: Bounds): boolean {
  if (!bound1 || !bound2) {
    return !bound1 && !bound2;
  }

  bound1 = normalizeBounds({ ...bound1 });
  bound2 = normalizeBounds({ ...bound2 });

  return isEqual(bound1, bound2);
}

export function normalizeObjectPosition(objectPosition: number[]): number[] {
  if (!objectPosition) {
    return [50, 50];
  } else {
    return objectPosition.map((p) => round(clamp(p, 0, 100), 2));
  }
}

export function boundsCollide(bound1: Bounds, bounds2: Bounds) {
  return (
    bound1.x < bounds2.x + bounds2.width &&
    bound1.x + bound1.width > bounds2.x &&
    bound1.y < bounds2.y + bounds2.height &&
    bound1.height + bound1.y > bounds2.y
  );
}

export function fillBoundsHeight(
  boundsPc: Bounds,
  elementWidthPx: number,
  elementHeightPx: number,
  containerWidthPx: number,
  containerHeightPx: number
): SimpleBounds {
  return fillBoundsHeightByAspectRatio(boundsPc, elementWidthPx / elementHeightPx, containerWidthPx, containerHeightPx);
}

export function fillBoundsHeightByAspectRatio(
  boundsPc: Bounds,
  aspectRatioPx: number,
  containerWidthPx: number,
  containerHeightPx: number
): SimpleBounds {
  if (typeof boundsPc.height === 'number') {
    return {
      x: boundsPc.x,
      y: boundsPc.y,
      width: boundsPc.width,
      height: boundsPc.height,
    };
  }

  return {
    x: boundsPc.x,
    y: boundsPc.y,
    width: boundsPc.width,
    height: (boundsPc.width * (containerWidthPx / 100)) / aspectRatioPx / (containerHeightPx / 100),
  };
}

export function getBoundingBox(bounds: SimpleBoundsWithAngle): SimpleBounds {
  if (!bounds.angleRad) {
    return { x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height };
  }

  const center = { x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 };

  const initialPoints = [
    { x: bounds.x, y: bounds.y },
    { x: bounds.x + bounds.width, y: bounds.y },
    { x: bounds.x + bounds.width, y: bounds.y + bounds.height },
    { x: bounds.x, y: bounds.y + bounds.height },
  ];

  const tranformedPoints = initialPoints.map((p) => ({
    x: center.x + (p.x - center.x) * Math.cos(bounds.angleRad) + (p.y - center.y) * Math.sin(bounds.angleRad),
    y: center.y - (p.x - center.x) * Math.sin(bounds.angleRad) + (p.y - center.y) * Math.cos(bounds.angleRad),
  }));

  const coordinates = {
    minX: Math.min(...tranformedPoints.map((p) => p.x)),
    maxX: Math.max(...tranformedPoints.map((p) => p.x)),
    minY: Math.min(...tranformedPoints.map((p) => p.y)),
    maxY: Math.max(...tranformedPoints.map((p) => p.y)),
  };

  return {
    x: coordinates.minX,
    y: coordinates.minY,
    width: coordinates.maxX - coordinates.minX,
    height: coordinates.maxY - coordinates.minY,
  };
}

export function boundsPercentToPx(
  boundsPc: SimpleBounds,
  containerWidth: number,
  containerHeight: number
): SimpleBounds {
  return {
    x: (boundsPc.x / 100) * containerWidth,
    y: (boundsPc.y / 100) * containerHeight,
    width: (boundsPc.width / 100) * containerWidth,
    height: (boundsPc.height / 100) * containerHeight,
  };
}

export function boundsPxToPercent(
  boundsPx: SimpleBounds,
  containerWidth: number,
  containerHeight: number
): SimpleBounds {
  return {
    x: (boundsPx.x / containerWidth) * 100,
    y: (boundsPx.y / containerHeight) * 100,
    width: (boundsPx.width / containerWidth) * 100,
    height: (boundsPx.height / containerHeight) * 100,
  };
}

export function getBoundingBoxByPercent(
  boundsPc: SimpleBoundsWithAngle,
  containerWidth: number,
  containerHeight: number
) {
  return boundsPxToPercent(
    getBoundingBox({
      ...boundsPercentToPx(boundsPc, containerWidth, containerHeight),
      angleRad: boundsPc.angleRad,
    }),
    containerWidth,
    containerHeight
  );
}

export function pointPercentToPx(pointPc: Point, containerWidth: number, containerHeight: number): Point {
  return {
    x: (pointPc.x / 100) * containerWidth,
    y: (pointPc.y / 100) * containerHeight,
  };
}

export function pointPxToPercent(pointPx: Point, containerWidth: number, containerHeight: number): Point {
  return {
    x: (pointPx.x / containerWidth) * 100,
    y: (pointPx.y / containerHeight) * 100,
  };
}

export function getBoundsPcAspectRatioPx(boundsPc: SimpleBounds, containerWidth: number, containerHeight: number) {
  const w = (boundsPc.width / 100) * containerWidth;
  const h = (boundsPc.height / 100) * containerHeight;

  return w / h;
}

export function addToBounds(bounds: Bounds, delta: SimpleBounds): Bounds {
  return {
    ...bounds,
    x: bounds.x + delta.x,
    y: bounds.y + delta.y,
    width: bounds.width + delta.width,
    height: bounds.height ? bounds.height + delta.height : null,
  };
}

export function addSimpleBounds(bounds1: SimpleBounds, bounds2: SimpleBounds): SimpleBounds {
  return {
    x: bounds1.x + bounds2.x,
    y: bounds1.y + bounds2.y,
    width: bounds1.width + bounds2.width,
    height: bounds1.height + bounds2.height,
  };
}

export function moveSimpleBounds(bounds: SimpleBounds, delta: Point): SimpleBounds {
  return {
    ...bounds,
    x: bounds.x + delta.x,
    y: bounds.y + delta.y,
  };
}

export function getEmptySimpleBounds(): SimpleBounds {
  return { x: 0, y: 0, width: 0, height: 0 };
}

export function getResizePointAngleFromCenter(
  width: number,
  height: number,
  resizeDirections: ResizeDirections
): number {
  if (!resizeDirections) {
    return 0;
  }

  const resizePointAngle = Math.atan(height / width);

  if (resizeDirections.x === 1) {
    return (resizeDirections.y ?? 0) * resizePointAngle;
  }

  if (resizeDirections.x === -1) {
    if (!resizeDirections.y) {
      return -Math.PI;
    }

    return resizeDirections.y * (Math.PI - resizePointAngle);
  }

  return resizeDirections.y * PI_HALF;
}

export function getSimpleBoundsCenterPoint(bounds: SimpleBounds): Point {
  return {
    x: bounds.x + bounds.width / 2,
    y: bounds.y + bounds.height / 2,
  };
}

export function isPointInBounds(point: Point, bounds: SimpleBoundsWithAngle): boolean {
  const boundsCenter: Point = { x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 };
  const boundsPoints: Point[] = [
    { x: bounds.x, y: bounds.y },
    { x: bounds.x + bounds.width, y: bounds.y },
    { x: bounds.x + bounds.width, y: bounds.y + bounds.height },
    { x: bounds.x, y: bounds.y + bounds.height },
  ].map((p) => getRotatedPoint(p, bounds.angleRad, boundsCenter));

  return boundsPoints.every((p1, index) => {
    const p2 = boundsPoints[index + 1] || boundsPoints[0];

    // If "d > 0", then the point is on the left hand-side of p2->p1 line
    // If "d = 0", then the point is on the line p2->p1
    // If "d >= 0" for each side p2->p1, then the point is inside the rectangle
    const d = (p2.x - p1.x) * (point.y - p1.y) - (point.x - p1.x) * (p2.y - p1.y);

    return d >= 0;
  });
}

export function areBoundsARsEqual(oldBounds: Bounds, newBounds: Bounds): boolean {
  const maxPrecision = Math.floor(Number.MAX_SAFE_INTEGER / 100);
  const currentAspectRatio = Math.round(oldBounds.width * maxPrecision) / Math.round(oldBounds.height * maxPrecision);
  const newAspectRatio = Math.round(newBounds.width * maxPrecision) / Math.round(newBounds.height * maxPrecision);

  return (
    round(Math.abs(round(currentAspectRatio, 2) - round(newAspectRatio, 2)), 2) <= ALLOWED_ASPECT_RATIO_ERROR_MARGIN
  );
}
