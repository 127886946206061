<div class="users-search" *openreelLet="users$ | async as users">
  <mat-form-field appearance="outline" class="form-control">
    <input
      matInput
      type="text"
      autocomplete="nope"
      [placeholder]="placeholder"
      [formControl]="searchFilter"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete
      class="invite-internal-user-autocomplete"
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="addUser($event)"
    >
      <mat-option disabled *ngIf="loading$ | async; else usersList" class="loading">
        <mat-spinner diameter="35"></mat-spinner>
      </mat-option>

      <ng-template #usersList>
        <ng-container *ngFor="let user of users">
          <mat-option [value]="user" *ngIf="!isMemberAdded(user.id)">
            <div class="user-row">
              <ngx-avatar [name]="user.fullname" bgColor="#0066ff" size="25"></ngx-avatar>

              <span class="name paragraph-p100">{{ user.fullname }}</span>
            </div>
          </mat-option>
        </ng-container>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>
</div>

<div class="members-list">
  <div class="member" *ngFor="let member of membersData; trackBy: trackByFn">
    <div class="member-info">
      <div class="member-avatar">
        <mat-icon>person</mat-icon>
      </div>

      <div class="member-details">
        <span class="member-name paragraph-p100" [matTooltip]="member.name" showIfTruncated>{{ member.name }}</span>
        <span class="member-email paragraph-p100" [matTooltip]="member.email" showIfTruncated>{{ member.email }}</span>
      </div>
    </div>

    <div class="role">
      <button
        mat-button
        type="button"
        class="role-menu-trigger"
        [matMenuTriggerFor]="menu"
        [disabled]="member.is_required"
      >
        <span class="role-menu-trigger-label paragraph-p100">{{ getRoleName(member.role) | titlecase }}</span>

        <mat-icon iconPositionEnd>expand_more</mat-icon>
      </button>

      <mat-menu #menu="matMenu" class="role-menu">
        <ng-container *ngFor="let role of ROLES">
          <button mat-menu-item class="role-menu-option" type="button">
            <mat-icon class="role-check-icon" [class.visible]="role.value === member.role">check</mat-icon>
            <span>{{ role.name | titlecase }}</span>
          </button>
        </ng-container>

        <mat-divider></mat-divider>

        <button mat-menu-item (click)="removeUser(member)">
          <span>Remove User</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
