import * as TimelineInterfaces from '../interfaces/timelines.interfaces';

import { NumberRange, fontWeightStyleToVariant } from '@openreel/common';
import { LayerDataChanges } from '../interfaces/element.interfaces';
import {
  Asset,
  AssetId,
  AssetLayer,
  AssetMetadata,
  AssetsFileProviderType,
  Bounds,
  ColorLayer,
  DEFAULT_FONT_WEIGHT,
  File,
  ImageLayer,
  Layer,
  LayerOptions,
  LayerStyles,
  LayerType,
  LottieLayer,
  LottieLayerData,
  OVERLAY_TIMELINE_TYPES,
  PROJECT_TIMELINE_TYPES,
  PresetFieldTokenType,
  ProjectFont,
  Section,
  SectionId,
  SectionLayer,
  SectionTimeline,
  SolidColor,
  Style,
  TemplateSection,
  TextCutRange,
  TimelineType,
  VideoLayer,
  WorkflowDataDto,
} from './../interfaces/workflow.interfaces';

import { LoadedFont } from '@openreel/common';
import { camelCase, clamp, cloneDeep, isEqual, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  createLottieLayerDataFromAsset,
  extractSubjectName,
  getAssetFinalDuration,
  getCameraTimelines,
  getLayerFromId,
  getLayers,
  getSectionDuration,
  getSectionTimelines,
  getSectionTypeTimelinesByType,
  getTimelinesByTypeAndSectionId,
  getWorkflowUsedAssetIds,
  isSectionEmpty,
  preserveIds,
  removeTrimmedZoomAndPans,
  randomizeDuplicateIds,
  updateMainClipTransitions,
} from '../helpers';
import { LayoutDto, LayoutType } from '../interfaces';
import { TextBoxMeasurementsProps, TextBoxMeasurer, measureTextBox } from '../text-boxes';
import { createSectionLayer } from './../template-builder/baseWorkflow';
import { AddSectionEvent, AddSectionEventResult } from './add-section.command';
import { TEXT_BOX_ASSET_ID, TEXT_BOX_PRESET_FIELD_NAME } from '../constants';

export interface CommandResult<R> {
  success: boolean;
  errorMessage?: string;
  result?: R;
  updatedWorkflow?: WorkflowDataDto;
}

export abstract class WorkflowBaseBuilder<T, R = null> {
  protected static textBoxMeasurer: TextBoxMeasurer;
  protected static fonts: LoadedFont[];

  protected original: WorkflowDataDto;
  protected source: WorkflowDataDto;

  static readonly setMeasurer = (measurer: TextBoxMeasurer) => (this.textBoxMeasurer = measurer);
  static readonly setFonts = (fonts: LoadedFont[]) => (this.fonts = fonts);

  abstract run(event: T): CommandResult<R>;

  constructor(original: WorkflowDataDto) {
    this.original = cloneDeep(original);
    this.source = cloneDeep(original);
  }

  protected toWorkflow() {
    for (const [sectionId, section] of Object.entries(this.source.sections)) {
      // NOTE: section duration must be calculated before any other commands run
      this.updateSectionDuration(section);
      this.clampOverlays(sectionId);

      if (section.sectionType === 'main') {
        this.toggleSection(sectionId, !isSectionEmpty(section));
      }
    }
    this.removeEmptyTimelines();
    updateMainClipTransitions(this.source);

    this.removeUnusedAssets();
    this.removeUnusedStyles();
    this.removeHiddenZooms();

    return this.source;
  }

  protected ok(result?: R): CommandResult<R> {
    return {
      success: true,
      result,
      updatedWorkflow: this.toWorkflow(),
    };
  }

  protected error(errorMessage: string): CommandResult<R> {
    return {
      success: false,
      errorMessage,
    };
  }

  protected getUniqueSectionId = () => camelCase(this.getUniqueId());

  protected getUniqueId = () => uuidv4();

  protected toggleLayer(layerId: string, enabled: boolean) {
    const layerInfo = getLayerFromId(layerId, this.source);
    if (!layerInfo) {
      return;
    }

    const { layer } = layerInfo;
    layer.enabled = enabled;
  }

  //#region Watermark
  protected createWatermarkLayer() {
    const watermarkSettings = this.source.globalSettings.watermark.settings;
    const watermarkAsset = this.getAsset(watermarkSettings.assetId);

    if (watermarkAsset.type !== 'image') {
      throw new Error('Only image watermarks are currently supported.');
    }

    const layer: LayerOptions & ImageLayer = {
      type: 'image',
      layerId: this.getUniqueId(),
      enabled: true,
      loop: false,
      assetId: watermarkSettings.assetId,
      visibility: {
        startAt: 0,
      },
    };

    return { layer };
  }

  protected createWatermarkLayerForSection(sectionId: string, options: Partial<LayerOptions & ImageLayer> = {}) {
    const sectionTimelines = getSectionTimelines(this.source.sections, 'main', 'watermark', sectionId);
    sectionTimelines
      .filter((s) => s.timelines[0] && s.timelines[0].layers.length === 0)
      .forEach((s) => {
        const { layer } = this.createWatermarkLayer();

        s.timelines[0].layers.push({
          ...layer,
          bounds: s.timelines[0].bounds,
          ...options,
        });
      });
  }

  protected updateWatermarkSection(sectionId: string | null, assetId: string | null) {
    const assetsToBeRemoved = [];

    const timelines: SectionTimeline[] = getTimelinesByTypeAndSectionId(this.source.sections, 'watermark', sectionId);
    timelines.forEach((timeline) => {
      if (assetId === null && timeline.layers.length === 0) {
        return;
      }

      if (assetId === null) {
        if (timeline.layers[0].type === 'image' && timeline.layers[0].assetId)
          assetsToBeRemoved.push(timeline.layers[0].assetId);
        timeline.layers = [];
        return;
      }

      if (timeline.layers.length === 0) {
        this.createWatermarkLayerForSection(sectionId);
      }

      const assetLayer = timeline.layers[0] as AssetLayer;
      const previousAssetId = assetLayer.assetId;
      if (previousAssetId !== assetId) {
        assetsToBeRemoved.push(previousAssetId);
      }
      assetLayer.assetId = assetId;
    });

    this.removeAssets(assetsToBeRemoved);
  }

  protected removeGlobalWatermark() {
    this.source.globalSettings.watermark.settings.enabled = false;
  }

  protected setGlobalWatermark(file: File) {
    this.source.globalSettings.watermark.settings.enabled = true;
    const assetId = this.source.globalSettings.watermark.settings.assetId;
    const asset = this.getAsset(assetId);
    asset.file = file;
  }

  //#endregion Watermark

  //#region Logo
  protected updateLogo(sectionId: SectionId.Intro | SectionId.Outro | null, assetId: string | null) {
    const assetsToBeRemoved = [];

    for (const { layer } of getLayers(this.source, {
      types: ['lottie'],
      sectionIds: sectionId ? [sectionId] : ['intro', 'outro'],
    })) {
      for (const [, field] of Object.entries((layer as LottieLayer).data)) {
        if (field.type !== 'logo') {
          continue;
        }
        if (field.assetId !== assetId) {
          assetsToBeRemoved.push(field.assetId);
        }

        if (assetId !== null) {
          field.assetId = assetId;
        } else {
          delete field.assetId;
        }
      }
    }
    this.removeAssets(assetsToBeRemoved);
  }

  protected removeGlobalLogo() {
    this.source.globalSettings.logo.settings.enabled = false;
  }

  protected setGlobalLogo(file: File) {
    this.source.globalSettings.logo.settings.enabled = true;
    const assetId = this.source.globalSettings.logo.settings.assetId;
    const asset = this.getAsset(assetId);
    asset.file = file;
  }

  //#endregion Logo

  //#region Section

  protected toggleSection(sectionId: string, enabled: boolean) {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    const layer = mainTimeline.layers.find((el) => el.type === 'section' && el.sectionId === sectionId);
    layer.enabled = enabled;
  }

  protected createSection(data: AddSectionEvent): AddSectionEventResult {
    const newSectionId = this.getUniqueSectionId();

    const timelines = randomizeDuplicateIds(data.timelines, this.source);
    const hasBackground = timelines.some((t) => t.type === 'background');
    if (!hasBackground) {
      timelines.unshift({
        id: this.getUniqueId(),
        type: 'background',
        hasAudio: false,
        zIndex: 0,
        title: '',
        layers: [],
      });
    }

    this.generateNewSectionStyles(timelines);

    const newSection: Section = {
      timelines,
      groups: [],
      sectionType: 'main',
      sectionDuration: 0,
      backgroundColor: { ...this.source.globalSettings.backgroundColor },
      audio: { muted: false, volume: 1 },
    };

    this.addSection(newSectionId, newSection, data.sectionIndex);

    if (data.templateLayoutId) {
      this.applyLayout(
        newSectionId,
        data.templateLayoutId,
        data.layoutType,
        data.layoutData,
        data.aspectRatioBounds,
        false
      );
    }

    if (this.source.globalSettings.watermark?.settings.enabled) {
      this.createWatermarkLayerForSection(newSectionId);
    }
    this.duplicateAssetsForSection(newSection);
    this.applyGlobalBackgroundState(newSectionId);

    const mainClipsLayerIds: string[] = [];
    if (data.mainClips) {
      mainClipsLayerIds.push(
        ...this.populateSectionMainClips(newSectionId, data.mainClips, data.useSourceForVideoPlacement ?? false)
      );
    } else {
      this.addLayoutPlaceholders(newSectionId, data.layoutType);
    }

    return { addedSectionId: newSectionId, mainClipsLayerIds };
  }

  protected addSection(sectionId: string, section: Section, sectionIndex?: number) {
    this.source.sections = {
      ...this.source.sections,
      [sectionId]: section,
    };

    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    const sectionLayer: LayerOptions & SectionLayer = {
      layerId: this.getUniqueId(),
      enabled: true,
      loop: false,
      type: 'section',
      sectionId,
    };

    const outroIndex = mainTimeline.layers.findIndex((l) => l.type === 'section' && l.sectionId === SectionId.Outro);

    const insertIndex = sectionIndex ?? (outroIndex !== -1 ? outroIndex : mainTimeline.layers.length);

    mainTimeline.layers.splice(insertIndex, 0, sectionLayer);
  }

  protected removeSection(sectionId: string) {
    const assetIds = this.getSectionAssetIds(sectionId);
    this.removeAssets(assetIds);

    delete this.source.sections[sectionId];

    this.source.timelines.forEach((t) => {
      t.layers = t.layers.filter((l: Layer) => (l as SectionLayer).sectionId !== sectionId);
    });
  }

  protected populateSectionMainClips(
    sectionId: string,
    clips: TimelineInterfaces.NewClip[],
    useSourceForVideoPlacement: boolean
  ): string[] {
    const sectionsWithTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId);
    if (sectionsWithTimelines.length === 0 || sectionsWithTimelines[0].timelines.length === 0) {
      return [];
    }

    const sectionTimelines = sectionsWithTimelines[0].timelines;

    const mainClipsLayerIds: string[] = [];
    if (useSourceForVideoPlacement) {
      const cameraTimelines = getCameraTimelines(sectionTimelines);
      const webcamClips = clips.filter((clip) => clip.source === 'webcam');
      const placedClips = [];
      const usedTimelines = [];

      cameraTimelines.forEach((timeline, index) => {
        if (webcamClips[index]) {
          timeline.layers = [];
          const newLayer = this.addAssetLayer(timeline, webcamClips[index]);
          mainClipsLayerIds.push(newLayer.layerId);
          placedClips.push(webcamClips[index].assetFileId);
          usedTimelines.push(timeline.id);
        }
      });

      const screenTimelines = sectionTimelines.filter((timeline) => !usedTimelines.includes(timeline.id));
      const remainingClips = clips.filter((clip) => !placedClips.includes(clip.assetFileId));

      remainingClips.forEach((clip, index) => {
        const timeline = screenTimelines[index];
        if (timeline) {
          timeline.layers = [];
          const newLayer = this.addAssetLayer(screenTimelines[index], clip);
          mainClipsLayerIds.push(newLayer.layerId);
        }
      });
    } else {
      clips.forEach((clip, index) => {
        const timeline = sectionTimelines[index];
        timeline.layers = [];
        const newLayer = this.addAssetLayer(sectionTimelines[index], clip);
        mainClipsLayerIds.push(newLayer.layerId);
      });
    }

    return mainClipsLayerIds;
  }

  protected generateNewSectionStyles(timelines: SectionTimeline[]) {
    for (const timeline of timelines) {
      for (const layer of timeline.layers) {
        if (layer.type === 'color') {
          const style: Style = { id: this.getUniqueId() };
          this.source.styles.push(style);
          layer.styleId = style.id;
        }
      }
    }
  }

  protected copySection(sectionId: string, insertionIndex: number) {
    const sourceScene = this.source.sections[sectionId];

    const newSceneId = this.getUniqueSectionId();
    const newScene = cloneDeep(sourceScene);
    this.addSection(newSceneId, newScene, insertionIndex);

    // Now go over newly added scene and duplicate all assets/styles/...
    for (const timeline of newScene.timelines) {
      const pairedTimelines = newScene.timelines.filter((t) => t.pairId === timeline.id);

      timeline.id = this.getUniqueId();
      pairedTimelines.forEach((pt) => (pt.pairId = timeline.id));

      for (const layer of timeline.layers) {
        layer.layerId = this.getUniqueId();

        switch (layer.type) {
          case 'color': {
            const style = this.source.styles.find((s) => s.id === layer.styleId);
            layer.styleId = this.getUniqueId();
            this.source.styles.push({ ...cloneDeep(style), id: layer.styleId });
            break;
          }
          case 'image':
          case 'video': {
            const asset = this.source.assets.find((s) => s.id === layer.assetId);
            layer.assetId = this.getUniqueId();
            this.source.assets.push({ ...cloneDeep(asset), id: layer.assetId });
            break;
          }
          case 'lottie': {
            for (const [, field] of Object.entries(layer.data)) {
              if (!field.styleId) {
                continue;
              }

              const style = this.source.styles.find((s) => s.id === field.styleId);
              field.styleId = this.getUniqueId();
              this.source.styles.push({ ...cloneDeep(style), id: field.styleId });
            }

            break;
          }
        }
      }
    }

    return newSceneId;
  }

  //#endregion Section

  //#region Background

  protected createBackgroundLayer(type: LayerType, color?: string) {
    const baseLayer: LayerOptions = {
      layerId: this.getUniqueId(),
      enabled: true,
      loop: true,
      bounds: { x: 0, y: 0, width: 100, height: 100 },
      visibility: { startAt: 0 },
    };

    let layer: Layer;
    let style: Style;

    if (type === 'video' || type === 'image') {
      const assetId = this.source.globalSettings.backgroundAsset.settings.assetId;

      layer = { ...baseLayer, type, assetId };
    } else if (type === 'color') {
      style = { id: this.getUniqueId(), color };
      layer = { ...baseLayer, type, styleId: style.id };
    } else {
      this.error(`Invalid layer type '${type}' for background timeline.`);
    }

    return { layer, style };
  }

  protected updateBackgroundAssetForTimelines(timelines: SectionTimeline[], assetId: string) {
    const asset = this.getAsset(assetId);
    const desiredLayerType = asset.type === 'image' ? 'image' : 'video';

    for (const timeline of timelines) {
      if (timeline.layers.length === 0 || timeline.layers[0].type !== desiredLayerType) {
        const { layer } = this.createBackgroundLayer(asset?.type === 'image' ? 'image' : 'video');
        timeline.layers = [{ ...layer }];
      }

      const assetLayer = timeline.layers[0] as LayerOptions & (VideoLayer | ImageLayer);
      assetLayer.assetId = assetId;
      assetLayer.enabled = Boolean(asset.file);
    }
  }

  protected updateBackgroundColorForTimelines(timelines: SectionTimeline[], color: string, styles: Style[]) {
    for (const timeline of timelines) {
      if (timeline.layers.length === 0 || timeline.layers[0].type !== 'color') {
        const { layer, style } = this.createBackgroundLayer('color', color);
        styles.push(style);
        timeline.layers = [layer];
      }

      const colorLayer = timeline.layers[0] as ColorLayer;
      const style = styles.find((s) => s.id === colorLayer.styleId);
      style.color = color;
    }
  }

  protected applyGlobalBackgroundState(sectionId: string) {
    const section = this.source.sections[sectionId];
    if (!section) {
      console.error(`No section with ID: ${sectionId}`);
      return;
    }

    const globalBackgroundAsset = this.source.globalSettings.backgroundAsset;
    const timelines: SectionTimeline[] = section.timelines.filter((t) => t.type === 'background');
    if (globalBackgroundAsset.settings.enabled) {
      this.updateBackgroundAssetForTimelines(timelines, globalBackgroundAsset.settings.assetId);
    } else {
      const color = (section.backgroundColor as SolidColor).color;
      this.updateBackgroundColorForTimelines(timelines, color, this.source.styles);
    }
  }

  protected setAssetBackground(sectionId: string, assetFile: File, type: 'image' | 'video', duration: number) {
    const isGlobalUpdate = !sectionId;
    const globalAssetId = this.source.globalSettings.backgroundAsset.settings.assetId;

    if (isGlobalUpdate) {
      this.source.globalSettings.backgroundAsset.settings.enabled = true;
      const assetId = this.source.globalSettings.backgroundAsset.settings.assetId;
      const asset = this.getAsset(assetId);
      asset.data = { ...asset.data, duration };
      asset.type = type === 'image' ? 'image' : 'clip';
      asset.file = assetFile;

      const timelines: SectionTimeline[] = getTimelinesByTypeAndSectionId(this.source.sections, 'background');
      this.updateBackgroundAssetForTimelines(timelines, globalAssetId);
    } else {
      const sectionAssetId = this.getBackgroundSectionAssetId(assetFile);
      const sectionAsset = this.source.assets.find((a) => a.id === sectionAssetId);
      sectionAsset.data = {
        ...sectionAsset.data,
        duration,
      };
      sectionAsset.type = type === 'image' ? 'image' : 'clip';

      const timelines: SectionTimeline[] = this.source.sections[sectionId].timelines.filter(
        (t) => t.type === 'background'
      );
      this.updateBackgroundAssetForTimelines(timelines, sectionAssetId);
    }
  }

  protected setColorBackground(sectionId: string, color: string | null) {
    const isGlobalUpdate = !sectionId;
    if (isGlobalUpdate) {
      this.source.globalSettings.backgroundAsset.settings.enabled = false;
      this.source.globalSettings.backgroundColor = { type: 'solid', color };
      for (const [, section] of Object.entries(this.source.sections)) {
        section.backgroundColor = { color, type: 'solid' };
      }

      const timelines: SectionTimeline[] = getTimelinesByTypeAndSectionId(this.source.sections, 'background');
      this.updateBackgroundColorForTimelines(timelines, color, this.source.styles);
    } else {
      this.source.sections[sectionId].backgroundColor = { type: 'solid', color: color };

      const timelines: SectionTimeline[] = this.source.sections[sectionId].timelines.filter(
        (t) => t.type === 'background'
      );
      this.updateBackgroundColorForTimelines(timelines, color, this.source.styles);
    }
  }

  protected getBackgroundSectionAssetId(assetFile: File) {
    const globalAssetId = this.source.globalSettings.backgroundAsset.settings.assetId;
    const globalAsset = this.getAsset(globalAssetId);
    let sectionAssetId = '';

    if (isEqual(globalAsset.file, assetFile)) {
      sectionAssetId = globalAssetId;
    } else {
      const newAsset = this.addAsset(assetFile.path, 'clip');
      sectionAssetId = newAsset.id;
    }

    return sectionAssetId;
  }

  // #endregion Background

  //#region Asset
  protected addAsset(newAssetFileId: number | string, type: 'image' | 'clip') {
    const newAsset: Asset = {
      id: this.getUniqueId(),
      type,
      file: {
        path: newAssetFileId,
        provider: 'or-assets',
      },
    };

    this.addAndReplaceAssets([newAsset]);

    return newAsset;
  }

  protected addAndReplaceAssets(assetsToAdd: Asset[]) {
    const assetIdsToAdd = assetsToAdd.map((a) => a.id);
    this.source.assets = this.source.assets.filter((a) => !assetIdsToAdd.includes(a.id));

    this.source.assets.push(...assetsToAdd);
  }

  protected removeAssets(assetIds: string | string[]) {
    const assetIdsToRemove = Array.isArray(assetIds) ? [...assetIds] : [assetIds];

    for (const assetId of assetIdsToRemove) {
      const assetToRemove = this.source.assets.find((a) => a.id === assetId);
      if (!assetToRemove || assetToRemove.isGlobal) {
        continue;
      }

      let usedCnt = 0;
      for (const { layer } of getLayers(this.source)) {
        if (
          (layer.type === 'video' || layer.type === 'image' || layer.type === 'lottie') &&
          layer.assetId === assetId
        ) {
          usedCnt += 1;
        }

        if (layer.type === 'lottie') {
          Object.keys(layer.data).forEach((key) => {
            if (layer.data[key].assetId === assetId) {
              usedCnt += 1;
            }
          });
        }
      }

      if (usedCnt <= 1) {
        this.source.assets = this.source.assets.filter((a) => a.id !== assetId);
      }
    }
  }

  protected updateAssetFile(assetId: string, file: File | null) {
    const asset = this.getAsset(assetId);
    if (file) {
      asset.file = file;
    } else {
      delete asset.file;
    }
  }

  protected getAsset(assetId: string) {
    return this.source.assets.find((a) => a.id === assetId);
  }

  protected removeUnusedAssets() {
    const usedAssetIds = getWorkflowUsedAssetIds(this.source);

    this.source.assets = this.source.assets.filter((s) => usedAssetIds.has(s.id));
  }

  protected removeHiddenZooms() {
    const assetsWithZooms = this.source.assets.filter((asset) => asset.type === 'clip' && asset.zoomPans?.length);

    for (const asset of assetsWithZooms) {
      removeTrimmedZoomAndPans(asset);
    }
  }

  //#endregion Asset

  //#region Styles
  protected addAndReplaceStyles(stylesToAdd: Style[]) {
    const styleIdsToAdd = stylesToAdd.map((a) => a.id);
    this.source.styles = this.source.styles.filter((a) => !styleIdsToAdd.includes(a.id));

    this.source.styles.push(...stylesToAdd);
  }

  protected removeUnusedStyles() {
    const usedStyleIds = new Set<string>();
    for (const { layer } of getLayers(this.source)) {
      if (layer.type === 'lottie') {
        for (const [, field] of Object.entries(layer.data)) {
          usedStyleIds.add(field.styleId);
        }
      }

      if (layer.type === 'color') {
        usedStyleIds.add(layer.styleId);
      }
    }

    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    for (const sectionLayer of mainTimeline.layers) {
      if (sectionLayer.type !== 'section') {
        continue;
      }

      if (!sectionLayer.transitions?.crossLayer) {
        continue;
      }
      if (sectionLayer.transitions.crossLayer.layer.type !== 'lottie') {
        continue;
      }

      for (const [, field] of Object.entries(sectionLayer.transitions.crossLayer.layer.data)) {
        usedStyleIds.add(field.styleId);
      }
    }

    this.source.styles = this.source.styles?.filter((s) => usedStyleIds.has(s.id));
  }

  //#endregion Styles

  protected reorderMainSections(mainSectionIds: string[]) {
    const mainLayers = this.source.timelines.find((t) => t.type === 'main').layers;
    const introSections = mainLayers.filter(
      (layer) => this.source.sections[(layer as SectionLayer).sectionId].sectionType === 'intro'
    );
    const mainSections = mainLayers.filter(
      (layer) => this.source.sections[(layer as SectionLayer).sectionId].sectionType === 'main'
    );
    const outroSections = mainLayers.filter(
      (layer) => this.source.sections[(layer as SectionLayer).sectionId].sectionType === 'outro'
    );

    if (mainSections.length !== mainSectionIds.length) {
      throw new Error('Main section count different from the new section IDs order.');
    }

    const orderedMainSections: Layer[] = [];

    mainSectionIds.forEach((sectionId) => {
      const section = mainSections.find((layer) => (layer as SectionLayer).sectionId === sectionId);
      if (!section) {
        throw new Error(`Main section with ID ${sectionId} not found.`);
      }

      orderedMainSections.push(section);
    });

    this.source.timelines[0].layers = [...introSections, ...orderedMainSections, ...outroSections];
  }

  protected addNewTimelineToSection(sectionId: string, timelineType: TimelineType) {
    const maxZIndex = this.getMaxZIndex(sectionId);

    const timeline: SectionTimeline = {
      id: this.getUniqueId(),
      type: timelineType,
      layers: [],
      zIndex: maxZIndex + 1,
      title: '',
      hasAudio: false,
    };

    this.source.sections[sectionId].timelines.push(timeline);
    return timeline;
  }

  protected addTextBoxLayer(sectionId: string) {
    const asset: Asset = this.getAsset(TEXT_BOX_ASSET_ID);
    if (!asset) {
      throw new Error('EditableText asset not found');
    }

    const { data, styles } = createLottieLayerDataFromAsset(asset, this.source.globalSettings);
    this.addAndReplaceStyles(styles);

    const timeline = this.addNewTimelineToSection(sectionId, 'text-boxes');
    timeline.bounds = asset.data.defaultInlineEditBounds;

    const layer: LayerOptions & LottieLayer = {
      layerId: this.getUniqueId(),
      isTextBox: true,
      enabled: true,
      loop: false,
      type: 'lottie',
      assetId: asset.id,
      renderer: 'svg',
      bounds: asset.data.defaultInlineEditBounds,
      visibility: {
        startAt: 0,
      },
      data,
      colorTags: asset.colorTags,
    };

    timeline.layers.push(layer);

    return layer;
  }

  protected addImageLayer(sectionId: string, assetFileId: AssetId, bounds: Bounds) {
    const newAsset: Asset = {
      id: this.getUniqueId(),
      file: { path: assetFileId, provider: 'or-assets' },
      type: 'image',
    };

    this.source.assets.push(newAsset);

    const timeline = this.addNewTimelineToSection(sectionId, 'images');
    timeline.bounds = bounds;

    const layer: LayerOptions & ImageLayer = {
      layerId: this.getUniqueId(),
      assetId: newAsset.id,
      enabled: true,
      loop: false,
      type: 'image',
      bounds: timeline.bounds,
      visibility: {
        startAt: 0,
      },
    };

    timeline.layers.push(layer);

    return layer;
  }

  protected addTextOverlayToSection(sectionId: string) {
    const layers: Layer[] = [];

    const sectionTimelines = getSectionTimelines(this.source.sections, 'main', 'overlays', sectionId);
    if (sectionTimelines.length === 0) {
      return layers;
    }

    for (const timeline of sectionTimelines[0].timelines) {
      if (timeline.layers.length === 0) {
        this.addTextOverlay(timeline, 0);
      }

      layers.push(timeline.layers[0]);
    }

    return layers;
  }

  protected addTextOverlay(timeline: SectionTimeline, startAt?: number) {
    const assetId = this.source.globalSettings.textOverlays?.assetId;
    if (!assetId) {
      return;
    }

    const textOverlayTransitions = this.source.globalSettings.textOverlays.transitions;

    const animationAsset = this.getAsset(assetId.toString());
    const { data, styles } = createLottieLayerDataFromAsset(animationAsset, this.source.globalSettings);
    this.addAndReplaceStyles(styles);

    const visibility = {
      startAt: startAt,
      endAt: startAt + animationAsset.data.duration,
    };

    const layer: LayerOptions & LottieLayer = {
      layerId: this.getUniqueId(),
      enabled: false,
      loop: false,
      type: 'lottie',
      assetId,
      renderer: 'svg',
      bounds: timeline.bounds,
      visibility,
      data,
      colorTags: animationAsset.colorTags,
      ...(textOverlayTransitions ? { transitions: textOverlayTransitions } : {}),
    };

    timeline.layers.push(layer);

    return layer;
  }

  protected addAssetLayer(
    timeline: SectionTimeline,
    clip: TimelineInterfaces.NewClip,
    options: Partial<LayerOptions> = {}
  ) {
    const asset = clip.isPlaceholder
      ? this.duplicateAsset(clip.assetId)
      : this.addClipAsset(
          clip.assetFileId,
          clip.assetProviderType,
          {
            name: clip.name,
            duration: clip.duration,
            ...(clip.source && { source: clip.source }),
          },
          clip.textCuts ?? [],
          clip.trim
        );

    return this.addLayerWithExistingAsset(timeline, asset, options);
  }

  protected addLayerWithExistingAsset(timeline: SectionTimeline, asset: Asset, options: Partial<LayerOptions> = {}) {
    const [bounds, styles] = this.getTimelineBoundsAndStyles(timeline);
    const assetDuration = getAssetFinalDuration(asset);

    const newLayer: VideoLayer & LayerOptions = {
      layerId: this.getUniqueId(),
      enabled: true,
      loop: false,
      type: 'video',
      assetId: asset.id,
      visibility: {
        startAt: 0,
        endAt: assetDuration,
      },
      bounds: bounds,
      styles: styles,
      ...options,
    };

    timeline.layers = [newLayer];

    return newLayer;
  }

  protected applyLayerChanges(layer: Layer, changes: LayerDataChanges) {
    for (const [key, fieldValue] of Object.entries(changes.values)) {
      const assetChanges = changes.assetChanges[key];
      const styleChanges = changes.styleChanges[key];

      if (['image', 'logo', 'video'].includes(fieldValue.type)) {
        if (assetChanges.removedAssetId) {
          this.removeAssets(assetChanges.removedAssetId);
        }
        if (assetChanges.newAssetFileId) {
          // NOTE: special case, watermark
          const layerInfo = getLayerFromId(layer.layerId, this.source);
          if (!layerInfo) {
            throw new Error(`No layer found for id: ${layer.layerId}`);
          }

          if (layerInfo.timeline.type === 'watermark') {
            const assetId = this.source.globalSettings.watermark.settings.assetId;
            const asset = this.getAsset(assetId);
            asset.file.path = assetChanges.newAssetFileId;
            fieldValue.assetId = assetId;
          } else {
            const assetType = fieldValue.type === 'video' ? 'clip' : 'image';
            const addedAsset = this.addAsset(assetChanges.newAssetFileId, assetType);
            fieldValue.assetId = addedAsset.id;
          }
        }
      }

      if (['text', 'shape'].includes(fieldValue.type)) {
        if (fieldValue.value === null) {
          delete fieldValue.value;
        }

        if (styleChanges) {
          let style: Style;
          if (fieldValue.styleId) {
            style = this.source.styles.find((s) => s.id === fieldValue.styleId);
          } else {
            style = {
              id: this.getUniqueId(),
              ...(fieldValue.type === 'text'
                ? { font: this.source.globalSettings.font, fontWeight: DEFAULT_FONT_WEIGHT }
                : {}),
            };
            this.source.styles.push(style);
            fieldValue.styleId = style.id;
          }

          if (style) {
            if (styleChanges.color) {
              style.color = styleChanges.color;
            }

            if (styleChanges.fontSize) {
              style.fontSize = styleChanges.fontSize;
            } else if (styleChanges.fontSize === null) {
              delete style.fontSize;
            }
          }
        }
      }
    }

    if (layer.type === 'lottie') {
      // NOTE: changes.values might not include all keys since it only contains fields editable on UI
      // (it wont contain shape or fill for example)
      layer.data = {
        ...layer.data,
        ...changes.values,
      };
    }

    if (layer.type === 'video' || layer.type === 'image') {
      const assetId = changes.values['default'].assetId as string;
      layer.assetId = assetId;
      layer.enabled = !!assetId;
    }
  }

  protected updateLottieLayerColors(layer: LottieLayer, fieldsToUpdate: string[], color: string) {
    fieldsToUpdate.forEach((field) => {
      const styleId = layer.data[field].styleId;
      const style = this.source.styles.find((s) => s.id === styleId);
      style.color = color;
    });
  }

  protected getTimelineBoundsAndStyles(timeline: SectionTimeline): [Bounds, LayerStyles] {
    const bounds = timeline.bounds;
    const styles = timeline.styles;

    return [bounds, styles];
  }

  protected applyGlobalColor(color: string, tag: string) {
    const assetsChanged = new Map<string, { fieldsToUpdate: string[]; colorTag: string; colorValue: string }>();

    // Update assets
    this.source.assets
      .filter((a) => a.type === 'json' && a.colorTags?.length)
      .forEach((a) => {
        const affectedColorTags = a.colorTags.filter((ct) => ct.tag === tag);
        affectedColorTags.forEach((colorTag) => {
          colorTag.color = color;

          const fieldsToUpdate = Object.keys(a.preset).filter((key) => a.preset[key].colorTag === colorTag.tag);

          assetsChanged.set(a.id, {
            fieldsToUpdate,
            colorTag: colorTag.tag,
            colorValue: colorTag.color,
          });
        });
      });

    // Update layers
    for (const { layer } of getLayers(this.source, { types: ['lottie'] })) {
      const lottieLayer = layer as LottieLayer;
      const assetChange = assetsChanged.get(lottieLayer.assetId);
      if (!assetChange) {
        continue;
      }

      const affectedColorTag = (lottieLayer.colorTags || []).find((layerCt) => layerCt.tag === assetChange.colorTag);
      if (affectedColorTag) {
        affectedColorTag.color = assetChange.colorValue;
      }

      this.updateLottieLayerColors(lottieLayer, assetChange.fieldsToUpdate, assetChange.colorValue);
    }
  }

  protected updatePrimaryColor(color: string) {
    if (!color) {
      return;
    }

    this.source.globalSettings.primaryColor = { type: 'solid', color };
  }

  // #region Apply/Update Layout

  protected applyLayout(
    sectionId: string,
    templateLayoutId: number,
    layoutType: LayoutType,
    layoutData: LayoutDto,
    layoutAspectRatioBounds: Bounds[],
    applyPlaceholders: boolean
  ) {
    const newLayout = cloneDeep(layoutData);
    const section = this.source.sections[sectionId];

    section.sectionDuration = layoutData.sceneDuration;
    section.layout = {
      ...section.layout,
      templateLayoutId,
      layoutType,
    };

    this.applyTemplateStylesToLayout(newLayout);

    this.applyLayoutTimelines(section, newLayout, layoutAspectRatioBounds);

    if (applyPlaceholders) {
      this.addLayoutPlaceholders(sectionId, layoutType);
    }
  }

  private applyTemplateStylesToLayout(layout: LayoutDto) {
    const { styles: templateStyles } = this.source.features.layouts;

    if (layout.applyTemplateStyles) {
      const mainTimelines = layout.timelines.filter((t) => t.type === 'main');
      for (const mainTimeline of mainTimelines) {
        mainTimeline.styles = cloneDeep(templateStyles);
      }
    }
  }

  private applyLayoutTimelines(section: Section, layout: LayoutDto, layoutAspectRatioBounds: Bounds[]) {
    const layoutTimelines = preserveIds(section.timelines, layout.timelines, this.source);
    layoutAspectRatioBounds.forEach((bounds, i) => {
      layoutTimelines[i].bounds = { ...bounds };
    });

    section.timelines = section.timelines.filter((t) => PROJECT_TIMELINE_TYPES.includes(t.type));
    section.timelines.push(...layoutTimelines);
    this.moveImagesAndTextboxesToFront(section);
  }

  private moveImagesAndTextboxesToFront(section: Section) {
    const layoutTimelines = section.timelines.filter((t) => !['images', 'text-boxes'].includes(t.type));
    const textImageTimelines = section.timelines.filter((t) => ['images', 'text-boxes'].includes(t.type));

    layoutTimelines.sort((a, b) => a.zIndex - b.zIndex);
    textImageTimelines.sort((a, b) => a.zIndex - b.zIndex);

    section.timelines = [...layoutTimelines, ...textImageTimelines];
    section.timelines.forEach((timeline, i) => (timeline.zIndex = i));
  }

  protected addLayoutPlaceholders(sectionId: string, layoutType: LayoutType, overrideAsset = false) {
    const placeholderAssetIds = this.getPlaceholdersForLayout(layoutType);
    if (placeholderAssetIds.length === 0) {
      return;
    }

    const sectionTimelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId);
    for (const { timelines } of sectionTimelines) {
      timelines.forEach((timeline, index) => {
        const placeholderAssetId = placeholderAssetIds[index];
        const placeholderAsset = placeholderAssetId ? this.duplicateAsset(placeholderAssetId) : null;

        if (!placeholderAsset) {
          if (overrideAsset && timeline.layers[0]?.type === 'video') {
            timeline.layers = [];
          }

          return;
        }

        const alreadyHasAsset = timeline.layers.length > 0;
        if (alreadyHasAsset) {
          if (overrideAsset && timeline.layers[0]?.type === 'video') {
            const layer = timeline.layers[0];
            layer.assetId = placeholderAsset.id;
            layer.visibility.startAt = 0;
            layer.visibility.endAt = placeholderAsset.data.duration;
          }
        } else {
          const newClip: VideoLayer & LayerOptions = {
            layerId: this.getUniqueId(),
            enabled: true,
            loop: false,
            type: 'video',
            assetId: placeholderAsset.id,
            visibility: {
              startAt: 0,
              endAt: placeholderAsset.data.duration,
            },
            styles: timeline.styles,
            bounds: timeline.bounds,
          };

          timeline.layers = [newClip];
        }
      });
    }
  }

  protected getPlaceholdersForLayout(layoutType: LayoutType) {
    const placeholderAssetIds = [];

    const placeholders = this.source.globalSettings.placeholders;
    switch (layoutType) {
      case 'simple':
        placeholderAssetIds.push(placeholders.simpleAssetId ?? null);
        break;
      case 'interview':
        placeholderAssetIds.push(placeholders.interviewAssetIds?.left);
        placeholderAssetIds.push(placeholders.interviewAssetIds?.right);
        break;
      case 'presentation':
        placeholderAssetIds.push(placeholders.presentationAssetIds?.left);
        placeholderAssetIds.push(placeholders.presentationAssetIds?.right);
        break;
    }

    return placeholderAssetIds;
  }

  //#endregion

  protected getSectionAssetIds(sectionId: string) {
    const section = this.source.sections[sectionId];

    return this.getTimelinesAssetIds(section.timelines);
  }

  protected getTimelinesAssetIds(timelines: SectionTimeline[]): string[] {
    const assets: string[] = [];

    timelines
      .filter((t) => t.type === 'main' || t.type === 'b-roll')
      .forEach((timeline) => {
        timeline.layers.forEach((layer: LayerOptions & VideoLayer) => {
          assets.push(layer.assetId);
        });
      });

    return assets;
  }

  protected duplicateAssetsForSection(section: Section) {
    const newAssets: Asset[] = [];

    section.timelines
      .filter((t) => t.type === 'main' || t.type === 'b-roll' || t.type === 'images')
      .forEach((timeline) => {
        timeline.layers.forEach((layer: LayerOptions & VideoLayer) => {
          const asset = this.source.assets.find((a) => a.id === layer.assetId);
          if (asset.isGlobal) {
            return;
          }

          const newAsset: Asset = {
            ...asset,
            id: this.getUniqueId(),
          };

          newAssets.push(newAsset);
          layer.assetId = newAsset.id;
        });
      });

    this.addAndReplaceAssets(newAssets);
  }

  protected updateGlobalFont(font: ProjectFont, updateBounds: boolean = true) {
    this.source.globalSettings.font = { ...font };

    this.source.styles = this.source.styles.map((style) => ({
      ...style,
      font: { ...font },
      fontWeight: 400,
      fontStyle: 'normal',
    }));

    if (updateBounds) {
      this.updateAllTextLayerBounds();
    }
  }

  protected updateAllTextLayerBounds() {
    for (const { layer } of getLayers(this.source, { types: ['lottie'] })) {
      if (layer.type === 'lottie' && layer.isTextBox) {
        this.updateTextLayerBounds(layer);
      }
    }
  }

  protected updateTextLayerBounds(layer: LayerOptions & LottieLayer, bounds?: Bounds) {
    const style = this.source.styles.find((s) => s.id === layer.data[TEXT_BOX_PRESET_FIELD_NAME].styleId);
    if (!bounds) {
      bounds = layer.bounds;
    }

    if (!WorkflowBaseBuilder.fonts) {
      throw new Error('Fonts are not loaded.');
    }

    const font = WorkflowBaseBuilder.fonts.find((f) => f.id === style.font.id && f.source === style.font.source);

    if (!font) {
      throw new Error(`Font not loaded: ${style.font.id} / ${style.font.source}`);
    }

    const measurementProps: TextBoxMeasurementsProps = {
      bounds,
      style: {
        fontSizeRelative: style.fontSize,
        lineHeight: style.lineHeight,
        textAlign: style.textAlign,
        font: {
          fontFamily: font.fontFamily,
          fontVariant: fontWeightStyleToVariant(String(style.fontWeight), style.fontStyle),
        },
      },
      referenceHeight: this.source.globalSettings.resolution.height,
      referenceWidth: this.source.globalSettings.resolution.width,
    };

    if (!WorkflowBaseBuilder.textBoxMeasurer) {
      throw new Error('Measurer not found.');
    }

    const measurements = measureTextBox(
      layer.data[TEXT_BOX_PRESET_FIELD_NAME].value,
      measurementProps,
      WorkflowBaseBuilder.textBoxMeasurer,
      WorkflowBaseBuilder.fonts
    );

    layer.bounds = cloneDeep(measurements.bounds);
  }

  protected migrateLottieData(
    previousLottieAsset: Asset,
    currentLottieAsset: Asset,
    previousData: LottieLayerData,
    currentData: LottieLayerData
  ) {
    const exactMatch = previousLottieAsset.file?.path === currentLottieAsset.file?.path;

    const tokenData = new Map<PresetFieldTokenType, { value: string; oldKey: string }>();
    const migratedKeys: string[] = [];

    for (const key in previousData) {
      if (
        previousData[key].type === 'text' &&
        previousData[key].value?.length &&
        previousLottieAsset.preset[key].tokenType &&
        previousLottieAsset.preset[key].tokenType !== PresetFieldTokenType.NONE
      ) {
        tokenData.set(previousLottieAsset.preset[key].tokenType, { value: previousData[key].value, oldKey: key });
      }
    }

    if (tokenData.has(PresetFieldTokenType.FULL_NAME)) {
      const subjectName = extractSubjectName(tokenData.get(PresetFieldTokenType.FULL_NAME).value);
      if (!tokenData.has(PresetFieldTokenType.FIRST_NAME)) {
        tokenData.set(PresetFieldTokenType.FIRST_NAME, {
          value: subjectName.subjectFirstName,
          oldKey: tokenData.get(PresetFieldTokenType.FULL_NAME).oldKey,
        });
      }
      if (!tokenData.has(PresetFieldTokenType.LAST_NAME)) {
        tokenData.set(PresetFieldTokenType.LAST_NAME, {
          value: subjectName.subjectLastName,
          oldKey: tokenData.get(PresetFieldTokenType.FULL_NAME).oldKey,
        });
      }
    } else {
      if (tokenData.has(PresetFieldTokenType.FIRST_NAME) && tokenData.has(PresetFieldTokenType.LAST_NAME)) {
        tokenData.set(PresetFieldTokenType.FULL_NAME, {
          value: `${tokenData.get(PresetFieldTokenType.FIRST_NAME).value} ${
            tokenData.get(PresetFieldTokenType.LAST_NAME).value
          }`,
          oldKey: tokenData.get(PresetFieldTokenType.FIRST_NAME).oldKey,
        });
      } else if (tokenData.has(PresetFieldTokenType.FIRST_NAME)) {
        tokenData.set(PresetFieldTokenType.FULL_NAME, tokenData.get(PresetFieldTokenType.FIRST_NAME));
      } else if (tokenData.has(PresetFieldTokenType.LAST_NAME)) {
        tokenData.set(PresetFieldTokenType.FULL_NAME, tokenData.get(PresetFieldTokenType.LAST_NAME));
      }
    }

    for (const key in currentData) {
      if (currentData[key].type === 'text') {
        if (tokenData.has(currentLottieAsset.preset[key].tokenType)) {
          currentData[key].value = tokenData.get(currentLottieAsset.preset[key].tokenType).value;
          migratedKeys.push(tokenData.get(currentLottieAsset.preset[key].tokenType).oldKey);
        } else if (previousData[key]?.value?.length && exactMatch) {
          currentData[key].value = previousData[key].value;
          migratedKeys.push(key);
        } else if (currentData[key].value === currentLottieAsset.preset[key].defaultValue) {
          currentData[key].value = '';
        }
      } else if (currentData[key].value && exactMatch) {
        currentData[key].value = previousData[key].value;
        migratedKeys.push(key);
      }
    }

    return {
      notMigratedKeys: Object.keys(previousData).filter(
        (key) => !migratedKeys.includes(key) && previousData[key].value?.length
      ),
      migratedKeys,
    };
  }

  protected updateSectionDuration(section: Section) {
    const mainTimelines = section.timelines.filter((t) => t.type === 'main');
    section.sectionDuration =
      mainTimelines.length > 0
        ? getSectionDuration(section, this.source.assets)
        : clamp(section.sectionDuration ?? 0, 0, section.sectionDuration);
  }

  protected clampOverlays(sectionId: string) {
    const section = this.source.sections[sectionId];
    if (!section) {
      return;
    }

    const sectionDuration = getSectionDuration(section, this.source.assets);
    const overlayTimelines: SectionTimeline[] = [];
    for (const timelineType of OVERLAY_TIMELINE_TYPES) {
      overlayTimelines.push(
        ...getSectionTypeTimelinesByType(this.source.sections, section.sectionType, timelineType, sectionId)
      );
    }

    for (const overlayTimeline of overlayTimelines.filter((t) => t.layers.length > 0)) {
      for (const layer of overlayTimeline.layers) {
        const updateVisibility = !!layer.visibility.endAt && layer.visibility.endAt > sectionDuration;

        let asset: Asset;
        let assetDuration = null;
        if (layer.type === 'video' || layer.type === 'lottie') {
          asset = this.source.assets.find((a) => a.id === layer.assetId);
          assetDuration = getAssetFinalDuration(asset);
        }

        let layerDuration = null;
        if (layer.visibility.startAt < sectionDuration) {
          // Layer is partially still visible
          layerDuration = sectionDuration - layer.visibility.startAt;

          if (updateVisibility) {
            layer.visibility.endAt = layer.visibility.startAt + layerDuration;
          }
        } else {
          layerDuration = assetDuration ? Math.min(assetDuration, sectionDuration) : sectionDuration;

          if (updateVisibility) {
            layer.visibility.startAt = 0;
            layer.visibility.endAt = layerDuration;
          }
        }
      }
    }
  }

  protected removeEmptyTimelines() {
    const cannotBeEmptyTypes: TimelineType[] = ['text-boxes', 'images'];

    for (const [, section] of Object.entries(this.source.sections)) {
      section.timelines = section.timelines.filter((t) => {
        if (!cannotBeEmptyTypes.includes(t.type)) {
          return true;
        }

        return t.layers.length > 0;
      });

      sortBy(section.timelines, 'zIndex').forEach((t, i) => (t.zIndex = i));
    }
  }

  protected copyTemplateSection(sectionId: SectionId, templateSection: TemplateSection, initialCreation: boolean) {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');

    const previousSection = this.source.sections[sectionId];
    const currentSection = cloneDeep(templateSection);
    const currentTimelines: SectionTimeline[] = [];
    if (initialCreation) {
      currentTimelines.push(...currentSection.section.timelines);
    } else {
      currentTimelines.push(
        ...previousSection.timelines.filter((t) => PROJECT_TIMELINE_TYPES.includes(t.type)),
        ...currentSection.section.timelines.filter((t) => !PROJECT_TIMELINE_TYPES.includes(t.type))
      );
    }

    // Copy section
    this.source.sections[sectionId] = {
      ...currentSection.section,
      ...previousSection,
      timelines: currentTimelines,
      sectionDuration: currentSection.section.sectionDuration,
    };
    this.moveImagesAndTextboxesToFront(this.source.sections[sectionId]);

    const transformedAssets = this.pointNewLayersAndAssetsToLogoAsset(sectionId, templateSection.assets);

    this.addAndReplaceAssets(transformedAssets);
    this.addAndReplaceStyles(
      cloneDeep(templateSection.styles).map((s) => ({ ...s, font: this.source.globalSettings.font }))
    );

    // Migrate lottie data (skipping this on initial creation since there is nothing to migrate)
    if (!initialCreation) {
      this.migrateTemplateSectionLottieData(sectionId);
    }

    // Add section layer if not there
    const hasSectionLayerAlready = mainTimeline.layers.some((l) => l.type === 'section' && l.sectionId === sectionId);
    if (!hasSectionLayerAlready) {
      const sectionLayer = createSectionLayer(sectionId);
      if (sectionId === 'intro') {
        mainTimeline.layers.splice(0, 0, sectionLayer);
      } else {
        mainTimeline.layers.push(sectionLayer);
      }
    }

    // If the user had crossLayer transitions selected, replace them with new ones from new section
    let transitionSectionLayer: Layer;
    if (sectionId === SectionId.Intro) {
      transitionSectionLayer = mainTimeline.layers.find((l) => l.type === 'section' && l.sectionId === sectionId);
    } else {
      const mainSectionLayers = mainTimeline.layers.filter(
        (l) => l.type === 'section' && this.source.sections[l.sectionId].sectionType === 'main'
      );
      transitionSectionLayer = mainSectionLayers.length > 0 ? mainSectionLayers[mainSectionLayers.length - 1] : null;
    }

    if (transitionSectionLayer) {
      // NOTE: this is a problem for blueprint templates right now
      // transitionSectionLayer will be ALWAYS null for outro since there is no main section when the template is defined initially
      // Solution for this is to first add a main section in blueprint mode before coming back to template builder and adding transition to outro
      // Overall solution would be to move transition definitions away from section layers
      if (initialCreation || transitionSectionLayer?.transitions?.crossLayer) {
        if (templateSection.transitions) {
          transitionSectionLayer.transitions = templateSection.transitions;
        } else {
          delete transitionSectionLayer.transitions;
        }
      }
    }
  }

  protected pointNewLayersAndAssetsToLogoAsset(sectionId: string, assets: Asset[]) {
    const logoAssetId = this.source.globalSettings.logo.settings.assetId;

    for (const { layer } of getLayers(this.source, { sectionIds: [sectionId] })) {
      if (layer.type !== 'lottie' || layer.isTextBox) {
        continue;
      }

      Object.entries(layer.data)
        .filter(([, field]) => field.type === 'logo')
        .forEach(([, field]) => (field.assetId = logoAssetId));
    }

    const transformedAssets = cloneDeep(assets).map((asset) => {
      if (asset.type === 'json') {
        Object.entries(asset.preset)
          .filter(([, field]) => field.type === 'logo')
          .forEach(([, field]) => (field.assetId = logoAssetId));
      }

      return asset;
    });

    return transformedAssets;
  }

  protected migrateTemplateSectionLottieData(sectionId: SectionId) {
    const originalSection = this.original.sections[sectionId];
    const currentSection = this.source.sections[sectionId];

    const originalMainTimeline = originalSection.timelines.find((t) => t.type === 'main');
    const originalMainLayer = originalMainTimeline.layers.length > 0 ? originalMainTimeline.layers[0] : null;
    const originalOverlayTimeline = originalSection.timelines.find((t) => t.type === 'overlays');
    const originalOverlayLayer = originalOverlayTimeline?.layers.length > 0 ? originalOverlayTimeline.layers[0] : null;

    const curentMainTimeline = currentSection.timelines.find((t) => t.type === 'main');
    const currentMainLayer = curentMainTimeline.layers.length > 0 ? curentMainTimeline.layers[0] : null;
    const currentOverlayTimeline = currentSection.timelines.find((t) => t.type === 'overlays');
    const currentOverlayLayer = currentOverlayTimeline?.layers.length > 0 ? currentOverlayTimeline.layers[0] : null;

    const migrate = (originalLayer: Layer, currentLayer: Layer) => {
      if (originalLayer?.type === 'lottie' && originalLayer?.type === currentLayer?.type) {
        const originalTextColor = originalLayer.colorTags.find((ct) => ct.tag === 'text');
        const currentTextColor = currentLayer.colorTags.find((ct) => ct.tag === 'text');

        if (currentTextColor && originalTextColor) {
          currentTextColor.color = originalTextColor.color;
        }

        const hasSameAsset = originalLayer.assetId === currentLayer.assetId;
        if (hasSameAsset) {
          for (const [lottieFieldId, lottieField] of Object.entries(currentLayer.data)) {
            if (lottieField.type !== 'text') {
              continue;
            }

            if (originalLayer.data[lottieFieldId]) {
              lottieField.value = originalLayer.data[lottieFieldId].value;
            }

            const style = this.source.styles.find((s) => s.id === lottieField.styleId);
            style.color = currentTextColor.color;
          }
        } else {
          const originalAsset = this.original.assets.find((a) => a.id === originalLayer.assetId);
          const currentAsset = this.source.assets.find((a) => a.id === currentLayer.assetId);
          this.migrateLottieData(originalAsset, currentAsset, originalLayer.data, currentLayer.data);
        }

        Object.entries(currentLayer.data)
          .filter(([, lottieField]) => lottieField.type === 'text')
          .forEach(([, textField]) => {
            const style = this.source.styles.find((s) => s.id === textField.styleId);
            style.color = currentTextColor.color;
          });
      }
    };

    if (originalMainLayer && currentMainLayer) {
      migrate(originalMainLayer, currentMainLayer);
    }

    if (originalOverlayLayer && currentOverlayLayer) {
      migrate(originalOverlayLayer, currentOverlayLayer);
    }
  }

  protected removeTemplateSection(sectionId: SectionId) {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');

    delete this.source.sections[sectionId];
    mainTimeline.layers = mainTimeline.layers.filter((l) => l.type === 'section' && l.sectionId !== sectionId);
  }

  protected duplicateAsset(assetId: string) {
    const sourceAsset = this.source.assets.find((a) => a.id === assetId);
    const newAsset: Asset = cloneDeep({
      ...sourceAsset,
      id: this.getUniqueId(),
      isGlobal: false,
    });
    this.source.assets.push(newAsset);

    return newAsset;
  }

  private getMaxZIndex(sectionId: string) {
    return Math.max(...this.source.sections[sectionId].timelines.map((timeline) => timeline.zIndex));
  }

  private addClipAsset(
    assetId: AssetId,
    assetProvider: AssetsFileProviderType,
    data: AssetMetadata,
    textCuts: TextCutRange[],
    trim: NumberRange
  ) {
    const newAsset: Asset = {
      id: this.getUniqueId(),
      type: 'clip',
      file: {
        path: assetId,
        provider: assetProvider,
      },
      data,
      ...(textCuts.length > 0 ? { textCuts: textCuts } : {}),
      ...(trim ? { trim } : {}),
    };

    this.source.assets.push(newAsset);

    return newAsset;
  }
}
