import { FontSource, FontVariant, NumberRange } from '@openreel/common';
import { LayoutType } from './layouts.interfaces';

export const LATEST_TEMPLATE_VERSION = '1.9';

export const MINIMUM_PROJECT_VERSION = '5.3';
export const LATEST_PROJECT_VERSION = '5.3';

export const SELF_RECORD_VIDEO_WIDTH = 1920;
export const SELF_RECORD_VIDEO_HEIGHT = 1080;

export const OFFSCREEN_CANVAS_WIDTH = 1920;
export const OFFSCREEN_CANVAS_HEIGHT = 1920;

// TODO: this should not be hardcoded
export const BUILDER_UI_RATIO_WIDTH = 1920;
export const BUILDER_UI_RATIO_HEIGHT = 1080;

export const RENDERED_VIDEO_FPS = 30;

export const DEFAULT_FONT_NAME = 'Roboto';
export const DEFAULT_FONT_VARIANTS: FontVariant[] = ['regular', 'italic', '700', '700italic'];
export const DEFAULT_FONT_WEIGHT = 400;
export const DEFAULT_SUBTITLE_FONT_NAME = 'Inter';

export const DEFAULT_MAIN_SECTION_ID = 'main';

export const TEMPLATE_PLACEHOLDER_VIDEO_ASSET_NAME = 'placeholder-video';

export const SOUNDTRACK_TRANSITION_TIME = 1000;

// Presets
// Image, video are not actual possible preset types but we need them here since we use this interface in layer.service
// And there you can edit image or video LAYER
export type PresetFieldType = 'text' | 'image' | 'logo' | 'shape' | 'video' | 'fill';

export interface ProjectFont {
  source: FontSource;
  id: number;
}

export interface ProjectSoundtrack {
  assetId: string;
  introVolume: number;
  mainVolume: number;
  outroVolume: number;
}

export enum PresetFieldTokenType {
  NONE = 'NONE',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  FULL_NAME = 'FULL_NAME',
  TEXT_OVERLAY_SUBTITLE = 'TEXT_OVERLAY_SUBTITLE',
  INTRO_TEXT = 'INTRO_TEXT',
  INTRO_SUBTITLE = 'INTRO_SUBTITLE',
  OUTRO_TEXT = 'OUTRO_TEXT',
  OUTRO_SUBTITLE = 'OUTRO_SUBTITLE',
}

export interface PresetField {
  type: PresetFieldType;
  showOnUi?: boolean;
  lottiePath?: string;
  label?: string;
  order?: number;
  assetId?: string;
  colorTag?: string;
  tokenType?: PresetFieldTokenType;
  validationRules?: {
    required?: boolean;
    minlength?: number;
    maxlength?: number;
  };
  // Text properties
  defaultValue?: string;
  // Shape properties
  colorShade?: number;
  // Image properties
  lottieAssetRefId?: string;
}

export interface Preset {
  [key: string]: PresetField;
}

// Assets
export type AssetsFileProviderType = 'or-assets' | 'or-recordings' | 'or-local' | 'url';

export type AssetId = number | string;

export interface File {
  path: AssetId;
  provider: AssetsFileProviderType;
}

export interface FileList extends File {
  isDefault: boolean;
  fileName: string;
  type: AssetType;
}

export type Source = 'webcam' | 'screen';

export interface AssetMetadata {
  name?: string;
  duration?: number;
  source?: Source;
  defaultInlineEditBounds?: Bounds;
}

export interface ColorTag {
  name: string;
  tag: string;
  color: string;
}

export type AssetType = 'json' | 'image' | 'clip' | 'audio';

export interface TextCutManual {
  type: 'manual';
}
export interface TextCutAutoSilence {
  type: 'auto-silence';
}

export type TextCutRange = NumberRange & (TextCutManual | TextCutAutoSilence);

export interface VideoZoomPanRange {
  amount: number; // target zoom amount in % where 100% is the original size
  startTransition: {
    startAt: number; // (ms) relative to absolute video asset time before trim
    duration: number; // (ms)
    timingFunction: TimingFunction;
  };
  endTransition: {
    startAt: number; // (ms) relative to absolute video asset time before trim
    duration: number; // (ms)
    timingFunction: TimingFunction;
  };
  point: { x: number; y: number }; // relative coordinates in percentage (0-100)
}

export interface Asset {
  id: string;
  name?: string;
  type: AssetType;
  file?: File;
  fileName?: string;
  textCuts?: TextCutRange[];
  trim?: NumberRange;
  data?: AssetMetadata;
  colorTags?: ColorTag[];
  preset?: Preset;
  isGlobal?: boolean;
  isPlaceholder?: boolean;
  zoomPans?: VideoZoomPanRange[];
}

export interface LottieMetadata {
  preset: Preset;
  data?: AssetMetadata;
  colorTags?: ColorTag[];
}

// Layers
export type Layer = LayerOptions & (LottieLayer | SectionLayer | VideoLayer | ImageLayer | ColorLayer);

export type ChildLayer = LayerOptions & (LottieLayer | VideoLayer);

export interface LottieLayerFieldData {
  type: PresetFieldType;
  styleId?: string;
  assetId?: string;
  value?: string;
}

export type LottieTextAlign = 'left' | 'center' | 'right';

export interface LottieProcessedValues {
  [key: string]: string | number;
}

export interface SolidColor {
  type: 'solid';
  color: string;
}

export interface GradientColorStep {
  stop: number;
  color: string;
}

export interface GradientColor {
  type: 'gradient';
  angle: number;
  colors: GradientColorStep[];
}

export interface Bounds {
  x: number;
  y: number;
  width: number;
  height?: number;
  angleRad?: number;
  isTextBoxFixedWidth?: boolean;
}

export interface SimpleBounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface SimpleBoundsWithAngle extends SimpleBounds {
  angleRad: number;
}

export interface BorderRadius {
  x: number;
  y: number;
}

export interface Border {
  x: number;
  y: number;
  color?: string;
}

export type BorderRadiusType = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

export const BORDER_RADIUS_TYPES: BorderRadiusType[] = ['top-left', 'top-right', 'bottom-right', 'bottom-left'];

export type LayerStylesObjectFit = 'cover' | 'contain' | 'stretch';

export interface LayerStyles {
  borderRadius?: BorderRadius[];
  border?: Border;
  backgroundColor?: SolidColor | GradientColor;
  objectFit?: LayerStylesObjectFit;
  objectPosition?: number[];
}

export type LottieRenderer = 'canvas' | 'svg' | 'html';

export interface Visibility {
  startAt: number;
  endAt?: number;
}
export type TimingFunction = 'linear' | 'easeOutExpo' | 'easeInOutCubic';

export type TransitionType = 'fade-out' | 'fade-in' | 'push' | 'layer';
export type TransitionDirection = 'top' | 'right' | 'bottom' | 'left';

export interface Transitions {
  entrance?: Transition;
  exit?: Transition;
  cross?: Transition;
  crossLayer?: TransitionLayer;
}

export interface PushTransition {
  type: 'push';
  direction: TransitionDirection;
  duration: number;
  timingFunction: TimingFunction;
}

export interface FadeTransition {
  type: 'fade-out' | 'fade-in';
  color?: string;
  duration: number;
}

export type Transition = FadeTransition | PushTransition;

export interface TransitionLayer {
  type: 'layer';
  duration: number;
  midpoint: number;
  layer: ChildLayer;
}

export interface LottieLayerData {
  [key: string]: LottieLayerFieldData;
}

export interface AssetLayer {
  assetId: string;
}

export interface LottieLayer extends AssetLayer {
  type: 'lottie';
  renderer: LottieRenderer;
  data?: LottieLayerData;
  colorTags?: ColorTag[];
  isTextBox?: boolean;
  textEffect?: TextEffect;
}

export type TextEffectType = 'echo' | 'outline' | 'splice' | 'hollow' | 'glow' | 'glitch' | 'shadow';
export type TextEffect =
  | TextEffectShadow
  | TextEffectSplice
  | TextEffectGlow
  | TextEffectEcho
  | TextEffectOutline
  | TextEffectHollow
  | TextEffectGlitch;

export interface TextEffectShadow {
  type: 'shadow';
  blur: number; // relative
  color: string;
  offset: number; // relative
  angle: number;
  opacity: number;
}

export interface TextEffectGlow {
  type: 'glow';
  blur: number; // relative
}

export interface TextEffectEcho {
  type: 'echo';
  offset: number; // relative
  angle: number;
  opacity: number;
  color: string;
}

export interface TextEffectOutline {
  type: 'outline';
  thickness: number; // relative
  color: string;
}

export interface TextEffectHollow {
  type: 'hollow';
  thickness: number; // relative
}

export interface TextEffectSplice {
  type: 'splice';
  thickness: number; // relative
  color: string;
  offset: number; // relative
  angle: number;
}

export interface TextEffectGlitch {
  type: 'glitch';
  rightColor: string;
  leftColor: string;
  offset: number; // relative
  angle: number;
}

export type SectionType = 'intro' | 'main' | 'outro';
export enum SectionId {
  Intro = 'intro',
  Outro = 'outro',
}

export interface SectionGroup {
  groupId: string;
  layerIds: string[];
  bounds: SimpleBoundsWithAngle;
}

export interface SectionLayer {
  type: 'section';
  sectionId: string;
}

export interface SectionLayout {
  templateLayoutId: number;
  layoutType: LayoutType;
}

export interface SectionAudioProperties {
  muted: boolean;
  volume: number;
}

export interface Section {
  sectionType: SectionType;
  sectionDuration: number;
  timelines: SectionTimeline[];
  groups: SectionGroup[];
  layout?: SectionLayout;
  backgroundColor: SolidColor | GradientColor;
  audio: SectionAudioProperties;
}

export interface VideoLayer extends AssetLayer {
  type: 'video';
  editing?: {
    label: string;
  };
}

export interface ImageLayer extends AssetLayer {
  type: 'image';
  editing?: {
    label: string;
  };
}

export interface ColorLayer {
  type: 'color';
  styleId: string;
}

export const LAYER_TYPES = ['lottie', 'image', 'video', 'color'] as const;
export type LayerType = typeof LAYER_TYPES[number];

export interface LayerOptions {
  layerId: string;
  enabled: boolean;
  loop: boolean;
  visibility?: Visibility;
  transitions?: Transitions;
  bounds?: Bounds;
  styles?: LayerStyles;
}

// Features
export interface GlobalSettingsPlaceholders {
  simpleAssetId?: string;
  interviewAssetIds: {
    left?: string;
    right?: string;
  };
  presentationAssetIds: {
    left?: string;
    right?: string;
  };
}

export interface LayoutsFeature {
  styles?: LayerStyles;
}

export interface Features {
  layouts?: LayoutsFeature;
}

export interface Style {
  id: string;
  color?: string;
  colorShade?: number;
  font?: ProjectFont;
  fontWeight?: 400 | 700;
  fontStyle?: 'normal' | 'italic';
  fontSize?: number;
  textAlign?: LottieTextAlign;
  lineHeight?: number;
  letterSpacing?: number;
}

// Workflow
export type TimelineType = 'background' | 'main' | 'b-roll' | 'overlays' | 'text-boxes' | 'watermark' | 'images';

export const TIMELINE_TYPE_MAP = new Map<TimelineType, string>([
  ['background', 'Background'],
  ['main', 'Main'],
  ['b-roll', 'Video Overlay'],
  ['overlays', 'Text Overlay'],
  ['text-boxes', 'Text Box Overlay'],
  ['watermark', 'Watermark'],
  ['images', 'Images'],
]);

export const PROJECT_FREE_ENTRY_TIMELINE_TYPES: TimelineType[] = ['text-boxes', 'images'];
export const PROJECT_TIMELINE_TYPES: TimelineType[] = ['background', 'text-boxes', 'images'];
export const OVERLAY_TIMELINE_TYPES: TimelineType[] = ['b-roll', 'overlays', 'text-boxes', 'images'];

export interface TimelineLayersControls {
  switch?: boolean;
}

export interface Timeline {
  type: TimelineType;
  layers: Layer[];
}

export type TimelineContentType = 'camera' | 'screen';

export interface SectionTimeline extends Timeline {
  id: string;
  zIndex: number;
  title: string;
  hasAudio: boolean;
  controls?: TimelineLayersControls;
  bounds?: Bounds;
  styles?: LayerStyles;
  pairId?: string;
  contentType?: TimelineContentType;
}

export interface GlobalSettingsTextOverlays {
  assetId: string;
  transitions?: Transitions;
}

export interface GlobalSettingsAsset {
  uploaded: Asset[];
  settings: {
    assetId: string;
    enabled: boolean;
  };
}

export interface Resolution {
  width: number;
  height: number;
}

export interface GlobalSettings {
  resolution: Resolution;
  textOverlays?: GlobalSettingsTextOverlays;
  logo: GlobalSettingsAsset;
  watermark: GlobalSettingsAsset;
  backgroundAsset: GlobalSettingsAsset;
  backgroundColor: SolidColor | GradientColor;
  primaryColor?: SolidColor | GradientColor;
  font?: ProjectFont;
  soundtrack: ProjectSoundtrack;
  placeholders?: GlobalSettingsPlaceholders;
}

export type Sections = { [key: string]: Section };

export interface TemplateSectionsType {
  sectionId: string;
  isEnabled: boolean;
  useSameSectionForAllAspectRatios: boolean;
  sections: TemplateSection[];
}

export interface TemplateSection {
  aspectRatioId: number;
  section: Section;
  assets: Asset[];
  styles: Style[];
  transitions?: Transitions;
}

export interface TemplateSections {
  [key: string]: TemplateSectionsType;
}

export interface WorkflowDataDto {
  assets: Asset[];
  sections: Sections;
  features: Features;
  timelines: Timeline[];
  styles: Style[];
  globalSettings: GlobalSettings;
  templateSections?: TemplateSections;
}
