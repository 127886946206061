import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Cleanupable } from '../../classes/cleanupable';
import { OpenreelAlert } from './openreel-alert.interface';
import { OpenreelAlertService } from './openreel-alert.service';
import { CommonModule } from '@angular/common';
import { OpenreelAlertBannerComponent } from '@openreel/ui/alerts/openreel-alert-banner/openreel-alert-banner.component';

export interface AlertEvent {
  event: 'clickClose' | 'clickAction';
  alert: OpenreelAlert;
}

@Component({
  selector: 'openreel-alert-container',
  templateUrl: './openreel-alert-container.component.html',
  styleUrls: ['./openreel-alert-container.component.scss'],
  standalone: true,
  imports: [CommonModule, OpenreelAlertBannerComponent],
})
export class OpenreelAlertContainerComponent extends Cleanupable implements OnInit {
  @Output() alertEvent = new EventEmitter<AlertEvent>();

  alerts: OpenreelAlert[] = [];

  constructor(private router: Router, private alertService: OpenreelAlertService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.alertService.onAlertOpen$.subscribe((alert) => {
        // add alert to array
        this.alerts.push(alert);
      })
    );
    this.subscriptions.push(
      this.alertService.onAlertClear$.subscribe(() => {
        this.alerts = [];
      })
    );
    this.subscriptions.push(
      // clear alerts on location change
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.alertService.clear();
        }
      })
    );
  }

  removeAlert(alert: OpenreelAlert) {
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  closeButtonClick(alert: OpenreelAlert) {
    this.removeAlert(alert);
    if (alert.onClose) alert.onClose();
    this.alertEvent.emit({ event: 'clickClose', alert });
  }

  actionButtonClick(alert: OpenreelAlert) {
    if (alert.onAction) alert.onAction();
    this.alertEvent.emit({ event: 'clickAction', alert });
  }
}
