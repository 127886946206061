export const TWO_PI = 2 * Math.PI;
export const PI_FOURTH = Math.PI / 4;
export const PI_HALF = Math.PI / 2;
export const THREE_PI_FOURTH = 3 * PI_FOURTH;

export function radiansToDegrees(radians: number) {
  return radians * (180 / Math.PI);
}


export function easeOutExpo(x: number) {
  return 1 - 2 ** (-10 * x);
}

export function easeInOutCubic(x: number) {
  return x < 0.5 ? 4 * x ** 3 : 1 - (-2 * x + 2) ** 3 / 2;
}
