import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DurationUtils } from '../duration.utils';
import { DurationValidators } from '../validators/duration.validators';
import { Duration } from 'moment';
import { Observable, Subject, combineLatest, lastValueFrom } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { InternalCommentsManagerService } from './internal-comments-manager.service';
import { CommentAccessibility, CommentableSortOptions, CommentableType } from '../hosting/constants';
import { CreateInternalCommentDTO, Comment } from '../hosting/hosting-interfaces';
import { AuthService } from '../services/auth/auth.service';
import { FeatureFlaggingService } from '../services/feature-flagging/feature-flagging.service';
import { COMMENTS_VISIBILITY, DrawableAreaData, DrawableShapeColor, DrawableShapeType, INTERNAL_COMMENT_ANNOTATIONS, PUBLIC_COMMENTS } from '@openreel/common';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { GenericAlertService } from '../services';

export type CommentEventName = 'comment_typing_started' | 'comment_added' | 'comment_edited' | 'comment_deleted';
export type CommentEventPayload = CreateInternalCommentDTO | Comment | { accessibility?: CommentAccessibility };

@Component({
  selector: 'or-internal-comments',
  templateUrl: './internal-comments.component.html',
  styleUrls: ['./internal-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalCommentsComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService, { optional: true });
  private featureFlagService = inject(FeatureFlaggingService);
  private cdr = inject(ChangeDetectorRef);
  private internalCommentsManagerService = inject(InternalCommentsManagerService);
  private genericAlertService = inject(GenericAlertService);

  @Input() showCommentsVisibilityToggle: boolean = false;

  @Input()
  get commentsDisabled() { return this._commentsDisabled };
  set commentsDisabled(isDisabled: boolean) {
    this._commentsDisabled = isDisabled;
    this.internalCommentsManagerService.setCommentsEnableState(!isDisabled);
    if(!isDisabled) this._fetchComments();
  }
  private _commentsDisabled: boolean = false;

  @Input()
  get commentableType() {
    return this._commentableType;
  }
  set commentableType(type: CommentableType) {
    if (type !== this._commentableType) {
      this._commentableType = type;
      this._fetchComments();
      this.cdr.detectChanges();
    }
  }
  private _commentableType: CommentableType;

  @Input()
  get commentsToken(): string {
    return this._commentsToken;
  }
  set commentsToken(token: string) {
    if (token !== this._commentsToken) {
      this._commentsToken = token;
      this._fetchComments();
      this.cdr.detectChanges();
    }
  }
  private _commentsToken: string;

  @Input()
  get includeRelatedComments() {
    return this._includeRelatedComments;
  }
  set includeRelatedComments(include: boolean) {
    if (include !== this._includeRelatedComments) {
      this._includeRelatedComments = include;
      this._fetchComments();
      this.cdr.detectChanges();
    }
  }
  private _includeRelatedComments: boolean = true;

  @Input()
  get videoDuration() {
    return this._videoDuration;
  }
  set videoDuration(duration: number | null) {
    if (duration !== this._videoDuration) {
      this._clearStartControlValidators();
      if (!!duration) {
        this.duration = DurationUtils.parseDuration(Math.floor(duration));
        this._setStartControlValidators();
      }
      this._videoDuration = duration;
      this.cdr.detectChanges();
    }
  }
  private _videoDuration: number | null = null;

  @Input() readOnly = false;
  @Input() showHeader = true;
  @Input() showCommentAccessibility: boolean = true;

  @Output() commentEvent = new EventEmitter<{ eventName: CommentEventName; payload: CommentEventPayload }>();
  @Output() changeCommentsDisabledState = new EventEmitter<boolean>()

  readonly fullname = this.authService?.getUserDetails()?.data?.loggedin_fullname ?? 'Anonymous';

  PUBLIC_COMMENTS_FEATURE_FLAG = PUBLIC_COMMENTS;
  COMMENTS_VISIBILITY_FEATURE_FLAG = COMMENTS_VISIBILITY;
  readonly isPublicCommentsFeatureEnabled$ = this.featureFlagService.isFeatureFlagEnabled(this.PUBLIC_COMMENTS_FEATURE_FLAG);
  readonly isEnableCommentAnnotations$ = this.featureFlagService.isFeatureFlagEnabled(INTERNAL_COMMENT_ANNOTATIONS);

  addCommentForm = new FormGroup({
    content: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(200)] }),
    isTimecoded: new FormControl(true),
    start: new FormControl({ disabled: false, value: '00:00:00' }),
    accessibility: new FormControl<CommentAccessibility>('internal'),
    annotation: new FormControl<DrawableAreaData | null>(null),
  });

  duration: Duration | null = null;

  private _startedCommentInput: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  isEnable$ = this.internalCommentsManagerService.isEnable$;
  sortOption$ = this.internalCommentsManagerService.sortOption$;
  comments$ = this.internalCommentsManagerService.comments$;
  selectedComment$ = this.internalCommentsManagerService.selectedComment$;
  isLoading$ = this.internalCommentsManagerService.isLoading$;
  isAllCommentsLoaded$ = this.internalCommentsManagerService.isAllCommentsLoaded$;
  isActiveDrawMode$ = this.internalCommentsManagerService.isActiveDrawMode$;

  accessibility$ = this.addCommentForm.controls.accessibility.valueChanges.pipe(
    startWith(this.addCommentForm.controls.accessibility.value),
    shareReplay(1)
  );

  isTimecoded$ = this.addCommentForm.controls.isTimecoded.valueChanges.pipe(
    startWith(this.addCommentForm.controls.isTimecoded.value),
    shareReplay(1)
  )

  showDrawableToolbar$ = combineLatest([this.isEnableCommentAnnotations$, this.accessibility$]).pipe(
    map(([isEnableCommentAnnotations, accessibility]) => isEnableCommentAnnotations && accessibility == 'internal'),
    distinctUntilChanged(),
  )

  ngOnInit(): void {
    this._subscribeCommentFormEvents();
    this._subscribeDrawShapesData();
  }

  ngOnDestroy(): void {
    this.internalCommentsManagerService.changeDrawModeActiveState(false);
    this.internalCommentsManagerService.selectComment(null);
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private _fetchComments(): void {
    if(this.commentsDisabled) return;
    if (!!this.commentsToken) {
      this.internalCommentsManagerService.setToken(this.commentsToken, this.includeRelatedComments);
    }
  }

  private _setStartControlValidators(): void {
    this.addCommentForm.controls.start.setValidators([
      Validators.required,
      DurationValidators.isValid,
      DurationValidators.min(DurationUtils.parseDuration('00:00:00')),
      DurationValidators.max(this.duration ?? DurationUtils.parseDuration('00:00:00')),
    ]);
    this.addCommentForm.controls.start.updateValueAndValidity();
  }

  private _clearStartControlValidators(): void {
    this.addCommentForm.controls.start.clearValidators();
    this.addCommentForm.controls.start.updateValueAndValidity();
  }

  fetchComments(): void {
    this.internalCommentsManagerService.fetchComments();
  }

  changeSortOption(value: CommentableSortOptions): void {
    this.internalCommentsManagerService.setSortOption(value);
    this.internalCommentsManagerService.fetchComments();
  }

  selectComment(comment: Comment): void {
    this.internalCommentsManagerService.selectComment(comment);
  }

  addComment(): void {
    this.addCommentForm.markAllAsTouched();
    if (this.addCommentForm.valid && !!this.commentsToken) {
      const dto: CreateInternalCommentDTO = {
        comment: this.addCommentForm.value.content,
        accessibility: this.addCommentForm.value.accessibility,
        timecodeMs: this.addCommentForm.value.isTimecoded
          ? DurationUtils.parseDuration(this.addCommentForm.value.start).asSeconds()
          : null,
        annotation: this.addCommentForm.value.isTimecoded && this.addCommentForm.value.accessibility == 'internal'
          ? this.addCommentForm.value.annotation
          : null,
        commentsToken: this.commentsToken,
      };
      this.internalCommentsManagerService.addComment(dto);
      this.commentEvent.emit({ eventName: 'comment_added', payload: dto });
      this.resetAddCommentForm();
    }
  }

  deleteComment(comment: Comment): void {
    this.internalCommentsManagerService.deleteComment(comment);
    this.commentEvent.emit({ eventName: 'comment_deleted', payload: comment });
  }

  updateComment(comment: Comment): void {
    this.internalCommentsManagerService.updateComment(comment);
    this.commentEvent.emit({ eventName: 'comment_edited', payload: comment });
  }

  resolveComment(comment: Comment): void {
    this.internalCommentsManagerService.resolveComment(comment);
    this.commentEvent.emit({ eventName: 'comment_edited', payload: comment });
  }

  resetAddCommentForm(): void {
    this.addCommentForm.reset({
      content: '',
      isTimecoded: true,
      start: '00:00:00',
      accessibility: 'internal',
      annotation: null,
    });
    this.addCommentForm.markAsUntouched();
    this.addCommentForm.markAsPristine();
    this.addCommentForm.controls.content.setErrors(null);
    this.addCommentForm.controls.start.setErrors(null);
    this._startedCommentInput = false;
    this.setDrawModeActiveState(false);
    this.internalCommentsManagerService.clearDrawResult()
  }

  setDrawModeActiveState(isActive: boolean) {
    this.internalCommentsManagerService.changeDrawModeActiveState(isActive);
  }

  selectColorToDraw(color: DrawableShapeColor) {
    this.internalCommentsManagerService.changeDrawableShapeColor(color);
  }

  selectShapeToDraw(shape: DrawableShapeType) {
    this.internalCommentsManagerService.changeDrawableShapeType(shape);
  }

  toggleAllowComments(allow: boolean) {
    this.changeCommentsDisabledState.emit(!allow);
  }

  async changeIsTimecoded(e: MatCheckboxChange) {
    if(!this.addCommentForm.controls.annotation.value) {
      this.addCommentForm.controls.isTimecoded.setValue(e.checked);
      return;
    }

    const result = await lastValueFrom(this._confirm('Are you sure? If you remove the time code, you will also lose your annotation'));
    if(result) this.addCommentForm.controls.isTimecoded.setValue(e.checked);
    else e.source.writeValue(true)
  }

  async changeAccessibility(e: MatSelectChange) {
    if(!this.addCommentForm.controls.annotation.value) {
      this.addCommentForm.controls.accessibility.setValue(e.value);
      return;
    }

    const result = await lastValueFrom(this._confirm('Are you sure? If you make the comment as "Public", you will also lose your annotation'));
    if(result) this.addCommentForm.controls.accessibility.setValue(e.value);
    else e.source.writeValue('internal');
  }

  private _confirm(message: string): Observable<boolean> {
    return this.genericAlertService
      .openAlertModal({
        title: 'Confirmation',
        content: message,
        confirmButtonLabel: 'Confirm',
        cancelButtonLabel: 'Cancel',
        isLightThemed: true,
      }).pipe(
        map(r => r.value)
      )
  }

  private _updateStartTimeControlForPlayerCurrentTime(currentTime: number) {
    if (currentTime) {
      this.addCommentForm.controls.start.markAsDirty();
      const durationStr = DurationUtils.formatDuration(DurationUtils.parseDuration(Math.floor(currentTime)), {
        forceDisplayHours: true,
      });
      this.addCommentForm.controls.start.setValue(durationStr);
    }
  }

  private _subscribeCommentFormEvents(): void {
    this.addCommentForm.controls.content.valueChanges
      .pipe(withLatestFrom(this.internalCommentsManagerService.playerCurrentTime$), takeUntil(this.unsubscribe$))
      .subscribe(([value, currentTime]) => {
        if (!this._startedCommentInput) {
          this.commentEvent.emit({ eventName: 'comment_typing_started', payload: this.addCommentForm.value });
          this._startedCommentInput = true;
        }
        if (this.addCommentForm.controls.start.dirty) return;
        this._updateStartTimeControlForPlayerCurrentTime(currentTime);
        if (!!currentTime && currentTime > 0 && !this.addCommentForm.controls.isTimecoded.value) {
          this.addCommentForm.controls.isTimecoded.setValue(true);
        }
      });

    this.addCommentForm.controls.isTimecoded.valueChanges
      .pipe(withLatestFrom(this.internalCommentsManagerService.playerCurrentTime$), takeUntil(this.unsubscribe$))
      .subscribe(([v, currentTime]) => {
        if (v) {
          this.addCommentForm.controls.start.enable();
          this._updateStartTimeControlForPlayerCurrentTime(currentTime);
        } else {
          this.addCommentForm.controls.start.disable();
          this.setDrawModeActiveState(false);
          this.internalCommentsManagerService.clearDrawResult()
        }
      });


    this.showDrawableToolbar$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(show => {
          if(show) return;
          this.setDrawModeActiveState(false);
          this.internalCommentsManagerService.clearDrawResult();
        })

  }

  private _subscribeDrawShapesData() {
    this.internalCommentsManagerService
        .drawResult$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          this.addCommentForm.controls.annotation.setValue(data);
        })
  }
}
