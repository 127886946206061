export const DB_NAME_PREFIX = 'recording-chunk-';
export const DB_VERSION = 1;
export const TIMESLICE = 2000;
export const BITS_PER_RESOLUTION_PER_FPS = 250;
export const MINIMUM_BITRATE = BITS_PER_RESOLUTION_PER_FPS * 720 * 30; // equivalent to the calculated bitrate of 720p 30 fps
/************************************************/
// DON'T Add space to the name, don't use = instead of :
/************************************************/
export const MIME_TYPE_VIDEO_WEBM_VP8 = 'video/webm;codecs:vp8,opus';
export const MIME_TYPE_VIDEO_WEBM_VP9 = 'video/webm;codecs:vp9,opus';
export const MIME_TYPE_VIDEO_WEBM_H264 = 'video/webm;codecs:h264,opus';
export const MIME_TYPE_VIDEO_WEBM_AVC1_FIREFOX = 'video/webm;codecs:avc1';
export const MIME_TYPE_VIDEO_WEBM_AVC1_CHROME = 'video/webm;codecs=avc1';

// for UGC mobile
export const MIME_TYPE_VIDEO_MP4 = 'video/mp4';
