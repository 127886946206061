import { cloneDeep, first, last } from 'lodash';
import { NumberRange } from '../interfaces';

export function invertTimeRanges(duration: number, ranges: NumberRange[], emptyToFullRange = true): NumberRange[] {
  if (!ranges?.length) {
    return emptyToFullRange ? [{ from: 0, to: duration }] : [];
  }

  const invertedRanges: NumberRange[] = [];

  if (first(ranges).from > 0) {
    invertedRanges.push({ from: 0, to: first(ranges).from });
  }

  for (let i = 0; i < ranges.length - 1; i++) {
    const currentRange = ranges[i];
    const nextRange = ranges[i + 1];

    if (nextRange.from - currentRange.to > 0) {
      invertedRanges.push({
        from: currentRange.to,
        to: nextRange.from,
      });
    }
  }

  if (last(ranges).to < duration) {
    invertedRanges.push({ from: last(ranges).to, to: duration });
  }

  return invertedRanges;
}

export function doTimeRangesIntersect(range1: NumberRange, range2: NumberRange): boolean {
  return range1.from < range2.to && range2.from < range1.to;
}

export function subtractFromTimeRanges<T extends NumberRange>(ranges: T[], subtrahend: NumberRange): T[] {
  const result = cloneDeep(ranges);

  for (let i = 0; i < result.length; i++) {
    const range = result[i];

    if (range.to <= subtrahend.from) {
      continue;
    }
    if (range.from > subtrahend.to) {
      break;
    }

    const leftRange: T = {
      ...range,
      from: range.from,
      to: subtrahend.from,
    };
    const rightRange: T = {
      ...range,
      from: subtrahend.to,
      to: range.to,
    };

    result.splice(i, 1);
    i--;

    if (leftRange.from < leftRange.to) {
      result.splice(i + 1, 0, leftRange);
      i++;
    }
    if (rightRange.from < rightRange.to) {
      result.splice(i + 1, 0, rightRange);
      i++;
    }
  }

  return result;
}

export function subtractFromTimeRangesMany<T extends NumberRange>(ranges: T[], subtrahends: NumberRange[]): T[] {
  let result = ranges;
  for (const subtrahend of subtrahends) {
    result = subtractFromTimeRanges(result, subtrahend);
  }

  return result;
}

export function subtractFromIndexRanges<T extends NumberRange>(ranges: T[], subtrahend: NumberRange): T[] {
  const result = cloneDeep(ranges);

  for (let i = 0; i < result.length; i++) {
    const range = result[i];

    if (range.to < subtrahend.from) {
      continue;
    }
    if (range.from > subtrahend.to) {
      break;
    }

    const leftRange: T = {
      ...range,
      from: range.from,
      to: subtrahend.from - 1,
    };
    const rightRange: T = {
      ...range,
      from: subtrahend.to + 1,
      to: range.to,
    };

    result.splice(i, 1);
    i--;

    if (leftRange.from <= leftRange.to) {
      result.splice(i + 1, 0, leftRange);
      i++;
    }
    if (rightRange.from <= rightRange.to) {
      result.splice(i + 1, 0, rightRange);
      i++;
    }
  }

  return result;
}

export function intersectTimeRanges(ranges: NumberRange[], operand: NumberRange): NumberRange[] {
  const result = cloneDeep(ranges);

  for (let i = result.length - 1; i >= 0; i--) {
    const range = result[i];

    if (range.to <= operand.from || range.from >= operand.to) {
      result.splice(i, 1);
      continue;
    }

    if (range.from < operand.from) {
      range.from = operand.from;
    }
    if (range.to > operand.to) {
      range.to = operand.to;
    }
  }

  return result;
}

export function rangeByEndSorter(range1: NumberRange, range2: NumberRange): number {
  return range1.to - range2.to || range1.from - range2.from;
}

export function getTimeRangesDuration(ranges: NumberRange[]): number {
  return ranges.reduce((sum, range) => sum + (range.to - range.from), 0);
}
