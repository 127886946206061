import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OpenreelRadioGroupComponent, OpenreelRadioGroupItemComponent } from '@openreel/ui/openreel-radio-group';
import { BrandkitFormModalData } from '../brandkit-form-modal/interfaces/brandkit-form-modal-data.interface';
import { BrandkitModalForm } from '../branding-form.interface';
import { BRANDFETCH_BRANDING_FORM } from '../apply-branding-form.service';

@Component({
  selector: 'openreel-brandkit-form',
  templateUrl: './brandkit-form.component.html',
  styleUrls: ['./brandkit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    OpenreelRadioGroupComponent,
    ReactiveFormsModule,
    OpenreelRadioGroupItemComponent,
    NgStyle,
    NgForOf,
    NgIf,
  ],
})
export class BrandkitFormComponent {
  @Input() data: BrandkitFormModalData | null = null;

  readonly form: FormGroup<BrandkitModalForm>;
  readonly brandingForm = inject(BRANDFETCH_BRANDING_FORM);

  private readonly fb = inject(FormBuilder);

  constructor() {
    this.form = this.createForm();
  }

  private createForm(): FormGroup<BrandkitModalForm> {
    const { color, logo, watermark } = this.brandingForm.value;

    return this.fb.group({
      color: this.fb.control(color),
      logo: this.fb.control(logo),
      watermark: this.fb.control(watermark),
    });
  }
}
