import { Directive, Input, OnInit, inject } from "@angular/core";
import { AuthService } from "@openreel/frontend/common/services";
import { HostingAnalyticsService } from "../hosting-analytics.service";

@Directive({
    selector: '[initHostingInternalAnalytics]',
    standalone: true,
})
export class InitHostingInternalAnalyticsDirective implements OnInit {
    @Input() initHostingInternalAnalytics: boolean = true;

    authService = inject(AuthService);
    hostingAnalyticsService = inject(HostingAnalyticsService);

    ngOnInit(): void {
        if(!this.initHostingInternalAnalytics) return;
        const details = this.authService.getUserDetails();
        this.hostingAnalyticsService.initInternalAnalytics();
        this.hostingAnalyticsService.setAccountId(details.data.account_id);
        this.hostingAnalyticsService.setUserId(details.data.user_id);
        this.hostingAnalyticsService.setIsAdmin(details.data.is_root_admin);
    }
}