import { from, ReplaySubject, Observable } from 'rxjs';
import S3 from 'aws-sdk/clients/s3';
import { first, switchMap } from 'rxjs/operators';

export class RxS3 {
  private s3Source = new ReplaySubject<S3>(1);
  private s3$ = this.s3Source.pipe(first());
  private run<T>(func: (S3) => Observable<T>): Observable<T> {
    return this.s3$.pipe(switchMap((s3) => func(s3)));
  }
  constructor(s3Config: S3.ClientConfiguration) {
    this.refreshCredentials(s3Config);
  }

  // structured this way to facilitate retries.
  createMultipartUpload$(params: S3.CreateMultipartUploadRequest) {
    return this.run((s3: S3) => from(s3.createMultipartUpload(params).promise()));
  }

  uploadPart$(params: S3.UploadPartRequest) {
    return this.run((s3: S3) => from(s3.uploadPart(params).promise()));
  }

  completeMultipartUpload$(params: S3.CompleteMultipartUploadRequest) {
    return this.run((s3: S3) => from(s3.completeMultipartUpload(params).promise()));
  }

  abortMultipartUpload$(params: S3.AbortMultipartUploadRequest) {
    return this.run((s3: S3) => from(s3.abortMultipartUpload(params).promise()));
  }

  refreshCredentials(s3Config: S3.ClientConfiguration) {
    this.s3Source.next(new S3(s3Config));
  }
}

// factory to make mocking for tests easier
export class S3Factory {
  static createS3(config: S3.ClientConfiguration) {
    return new RxS3(config);
  }
}
