import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HostablePermissionTypes } from '../../constants';

const PERMISSION_OPTIONS: {[key in HostablePermissionTypes]: {value: HostablePermissionTypes, display: string}} = {
    [HostablePermissionTypes.Private]: {
        value: HostablePermissionTypes.Private,
        display: 'Private',
    },
    [HostablePermissionTypes.Public]: {
        value: HostablePermissionTypes.Public,
        display: 'Public',
    },
    [HostablePermissionTypes.Password]: {
        value: HostablePermissionTypes.Password,
        display: 'Password',
    }
}

@Component({
    selector: 'or-permission-selector',
    templateUrl: './permission-selector.component.html',
    styleUrls: ['./permission-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionSelectorComponent implements OnInit {
    private _permission = HostablePermissionTypes.Private;
    @Input() 
    set permission(perm: HostablePermissionTypes) {
        if(perm === HostablePermissionTypes.Password) {
            this.passwordProtected = true;
            perm = HostablePermissionTypes.Public;
        } else {
            this.passwordProtected = false;
        }
        this._permission = perm;
        this.permissionCtrl.setValue(perm, {emitEvent: false});
    }
    get permission() {
        return this._permission;
    }

    get isPrivate() {
        return this.permission === HostablePermissionTypes.Private;
    }

    get isPublic() {
        return this.permission === HostablePermissionTypes.Public;
    }

    @Output() permissionChanged = new EventEmitter<HostablePermissionTypes>();

    permissionCtrl = new UntypedFormControl(this.permission);

    passwordProtected = false;

    permissionOpts = Object.values(PERMISSION_OPTIONS).filter((data)=> data.value !== HostablePermissionTypes.Password);

    ngOnInit() {
        this.permissionCtrl.valueChanges.subscribe(
            (perm: HostablePermissionTypes) => {
                if (this._permission !== perm) {
                    this._permission = perm;
                    this.permissionChanged.emit(this.permission);
                }
            }
        );
    }

}