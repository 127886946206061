import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AuthService, commonenv } from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { fadeIn, heightCollapse } from '../../../animations';
import { OpenreelAlertService } from '../../../components/openreel-alert-banner-container/openreel-alert.service';
import { CLOSE_EMBED_UPGRADE_BAR, HostablePermissionTypes } from '../../constants';
import { UrlType } from '../../hosting-interfaces';
import { HostingService } from '../../services/hosting.service';
import { UpdatePasswordComponent } from '../update-password/update-password.component';

export function duplicateNameValidator(emailList: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let forbidden = false;

    if (emailList.includes(control.value)) {
      forbidden = true;
    }

    return forbidden ? { duplicate: { value: control.value } } : null;
  };
}

interface VideoSizeForm {
  width: number;
  height: number;
}

const WIDTH_VARIABLE = '{{width}}';
const HEIGHT_VARIABLE = '{{height}}';

@Component({
  selector: 'openreel-hosting-share',
  templateUrl: './hosting-share.component.html',
  styleUrls: ['./hosting-share.component.scss'],
  animations: [fadeIn, heightCollapse],
})
export class HostingShareComponent implements OnInit, OnDestroy {
  /**
   * Responsible for responsiveness of the embedded video
   * @property true - responsive size
   * @property false - fixed size
   */
  readonly responsivenessControl = new UntypedFormControl(true);

  readonly embedUrl$: Observable<string>;

  readonly shareForm: UntypedFormGroup;
  readonly embedVideoSizingForm: UntypedFormGroup;
  readonly embedVideoToggleControl = new UntypedFormControl(false);

  isLightThemed = true;
  isPopup = true;

  emailList: string[] = [];

  embedExceed: boolean;

  embedLimit: number;

  embedVideoCount: number;

  embedUpdating = false;

  embedAlertShowed = false;

  isRootAdmin: boolean;

  private readonly destroy$ = new Subject<void>();

  get fixedSizeEnabled(): boolean {
    return !this.responsivenessControl.value;
  }

  get shareFormInvalid(): boolean {
    return this.shareForm.invalid || this.embedVideoSizingForm.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: UrlType;
      id: number;
      slug: string;
      idHash: string;
      hasPassword: boolean;
      embedEnabledAt?: Date;
      embedExceed: boolean;
      embedLimit: number;
      embedVideoCount: number;
    },
    private dialogRef: MatDialogRef<HostingShareComponent>,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private hostingService: HostingService,
    private authService: AuthService,
    private openreelAlertService: OpenreelAlertService,
    private router: Router
  ) {
    this.shareForm = this.buildForm();
    this.embedVideoSizingForm = this.buildEmbedVideoSizingForm();

    this.embedUrl$ = this.getEmbedUrl$().pipe(
      tap((embed) => {
        this.shareForm.patchValue({
          embed,
        });
      })
    );
    this.embedExceed = data?.embedExceed;
    this.embedLimit = data?.embedLimit;
    this.embedVideoCount = data?.embedVideoCount;
    this.constructLink();
  }

  ngOnInit(): void {
    this.isRootAdmin = this.authService.getUserDetails().data.is_root_admin;
    this.subscribeToEmbedUrlChanges();
    this.subscribeToResponsivenessControlChanges();
    this.setEmbedToggleControlInitialValue();

    if (this.data.type === 'video') {
      this.detectEmbedChange();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  // Share
  share(): void {
    if (this.shareFormInvalid) {
      return;
    }

    const data = {
      email: this.emailList,
      message: this.shareForm.value.message.trim(),
    };

    this.dialogRef.close(data);
  }

  // copy URL to Clipboard
  copyLink(evt: MouseEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.clipboard.copy(this.shareForm.value.link);
    this.toastr.success('Copied Share Link');
  }

  copyEmbed(evt: MouseEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.clipboard.copy(this.shareForm.value.embed);
    this.toastr.success('Copied Embed Code');
  }

  openUpdatePassword() {
    this.dialog
      .open(UpdatePasswordComponent, {
        width: '400px',
        data: { type: this.data.type },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && data.password) this.setPassword(true, data.password);
      });
  }

  async setPassword(hasPassword: boolean, password: string) {
    try {
      if (this.data.type === 'video') {
        await this.hostingService
          .putVideo(this.data.id, {
            password,
            permission: password ? HostablePermissionTypes.Password : HostablePermissionTypes.Public,
          })
          .toPromise();
      } else if (this.data.type === 'hub') {
        await this.hostingService.updateHubPassword(this.data.id, hasPassword ? password : null).toPromise();
      }

      this.data.hasPassword = hasPassword;

      if (hasPassword) {
        this.toastr.success('Password set successfully');
        this.embedVideoToggleControl.setValue(false);
        this.embedVideoToggleControl.disable();
      } else {
        this.toastr.success('Password remove successfully');
        this.embedVideoToggleControl.enable();
      }
    } catch {
      this.toastr.error('Unable to set new password');
    }
  }

  private constructLink(): void {
    const getLink = (type: UrlType): string => {
      if (type === 'hub') {
        return commonenv.hostingAppUrl + 'hub/' + this.data.idHash + '-' + this.data.slug;
      } else if (type === 'video') {
        return commonenv.hostingAppUrl + this.data.idHash + '-' + this.data.slug;
      }

      return '';
    };

    this.shareForm.patchValue({
      link: getLink(this.data.type),
    });
  }

  private constructEmbedUrl(): string {
    return this.data?.type === 'video'
      ? `<iframe width="${WIDTH_VARIABLE}" height="${HEIGHT_VARIABLE}" src="${commonenv.hostingAppUrl}embed/${this.data.idHash}-${this.data.slug}" title="OpenReel Video Player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>`
      : '';
  }

  private getIframeUrl$(): Observable<string> {
    return of(this.constructEmbedUrl());
  }

  private getEmbedUrl$(): Observable<string> {
    const { width: originalWidth, height: originalHeight } = this.embedVideoSizingForm.value as VideoSizeForm;

    let previousWidth = originalWidth;

    return this.responsivenessControl.valueChanges.pipe(
      startWith<boolean>(this.responsivenessControl.value as boolean),
      switchMap((responsiveness) =>
        this.embedVideoSizingForm.valueChanges.pipe(
          startWith<VideoSizeForm>(this.embedVideoSizingForm.value as VideoSizeForm),
          debounceTime(300),
          map(({ width: currentWidth, height: currentHeight }) => {
            const isWidthChanged = previousWidth !== currentWidth;

            if (isWidthChanged) {
              return {
                width: currentWidth,
                height: (originalHeight / originalWidth) * currentWidth,
              };
            }

            return {
              width: (originalWidth / originalHeight) * currentHeight,
              height: currentHeight,
            };
          }),
          map(({ width, height }) => ({
            width: width ? Math.round(width) : 0,
            height: height ? Math.round(height) : 0,
          })),
          tap((dimension) => {
            const { width } = dimension;

            previousWidth = width;

            this.embedVideoSizingForm.patchValue(dimension, { emitEvent: false });
          }),
          switchMap(({ width, height }) =>
            this.getIframeUrl$().pipe(
              map((embed) =>
                embed
                  .replace(WIDTH_VARIABLE, responsiveness ? '100%' : `${width}px`)
                  .replace(HEIGHT_VARIABLE, responsiveness ? '100%' : `${height}px`)
              )
            )
          )
        )
      ),
      takeUntil(this.destroy$)
    );
  }

  private subscribeToEmbedUrlChanges(): void {
    this.embedUrl$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  private subscribeToResponsivenessControlChanges(): void {
    this.responsivenessControl.valueChanges
      .pipe(startWith<boolean>(this.responsivenessControl.value as boolean), takeUntil(this.destroy$))
      .subscribe((responsiveness) => {
        const widthControl = this.embedVideoSizingForm.get('width');
        const heightControl = this.embedVideoSizingForm.get('height');

        if (responsiveness) {
          widthControl.disable({ emitEvent: false });
          heightControl.disable({ emitEvent: false });
        } else {
          widthControl.enable({ emitEvent: false });
          heightControl.enable({ emitEvent: false });
        }
      });
  }

  private buildForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      link: new UntypedFormControl(''),
      embed: new UntypedFormControl(''),
      // this control is not visible
      // we keep it to use validation only
      // pass it to email-list control to use validation
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, Validators.email, duplicateNameValidator(this.emailList)])
      ),
      message: new UntypedFormControl('', Validators.compose([Validators.maxLength(512)])),
    });
  }

  private buildEmbedVideoSizingForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      width: new UntypedFormControl(
        { value: 960, disabled: true },
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      height: new UntypedFormControl(
        { value: 540, disabled: true },
        Validators.compose([Validators.required, Validators.min(0)])
      ),
    });
  }

  detectEmbedChange() {
    this.embedVideoToggleControl.valueChanges
      .pipe(
        switchMap((enabled) => {
          this.embedUpdating = true;
          return this.hostingService.updateEmbedVideo(this.data.id, enabled).pipe(
            catchError((err) => {
              this.shareForm.get('embedEnabledAt').setValue(!enabled, { emitEvent: false });

              if (err.data && err.data.embedExceed) {
                if (localStorage.getItem(CLOSE_EMBED_UPGRADE_BAR) === 'true') {
                  this.showUpdateBanner();
                }

                localStorage.removeItem(CLOSE_EMBED_UPGRADE_BAR);
              } else {
                this.toastr.error(err.message, 'Error');
              }

              return EMPTY;
            }),
            finalize(() => {
              this.embedUpdating = false;
            })
          );
        }),
        tap((data) => {
          this.embedExceed = data.embedExceed;
          this.embedLimit = data.embedLimit;
          this.embedVideoCount = data.embedVideoCount;
          this.toastr.success('Updated successfully');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  showUpdateBanner() {
    const message = this.isRootAdmin
      ? `You have used ${this.embedLimit} out of ${this.embedLimit} embedded videos!`
      : ` You have used ${this.embedLimit} out of ${this.embedLimit} embedded videos, contact your team administrators
        to add more.`;
    this.embedAlertShowed = true;
    this.openreelAlertService.showAlert({
      type: 'warning',
      name: 'embedded_videos_limit',
      message: message,
      buttonText: this.authService.isRootAdmin() ? 'Upgrade' : '',
      buttonPrefixIcon: 'diamond',
      onAction: () => {
        this.router.navigate(['/settings'], { queryParams: { tab: 'seats' } });
      },
      onClose: () => {
        localStorage.setItem(CLOSE_EMBED_UPGRADE_BAR, 'true');
      },
    });
  }

  private setEmbedToggleControlInitialValue(): void {
    this.embedVideoToggleControl.setValue(!!this.data?.embedEnabledAt, { emitEvent: false });

    const { hasPassword } = this.data;

    if (hasPassword) {
      this.embedVideoToggleControl.disable({ emitEvent: false });
    } else {
      this.embedVideoToggleControl.enable({ emitEvent: false });
    }
  }
}
