import { Store } from '@ngrx/store';
import { Cleanupable } from '../classes';
import { Recording, VideoType } from '../interfaces';
import { ClipboardService, DirectorApiService } from '../services';

export class BaseRecordingActions extends Cleanupable {
  constructor(
    public directorApiService: DirectorApiService,
    public clipboardService: ClipboardService,
    protected store: Store
  ) {
    super();
  }

  protected async downloadRecording(recording: Recording, type: VideoType, downloadUrl: string) {
    // const url = window.URL.createObjectURL(downloadUrl);
    const url = downloadUrl;

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    // TODO: Change format as needed
    a.download = recording.video_name;
    a.target = '_blank';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  protected async downloadAudioRecording(name: string, downloadUrl: string) {
    // const url = window.URL.createObjectURL(downloadUrl);
    const url = downloadUrl;

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    // TODO: Change format as needed
    a.download = name;
    a.target = '_blank';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  protected async copyRecording(recording: Recording, type: VideoType, downloadUrl: string) {
    if (!(await this.clipboardService.copy(downloadUrl))) {
      // Copying failed
    }
  }

  protected async transcodeRecording(recording: Recording, type: VideoType) {
    return this.directorApiService.transcodeVideo(recording.ovra_session_videos_id, type);
  }

  protected async transcriptRecording(videoId: number) {
    return this.directorApiService.transcriptVideo(videoId);
  }

  protected formatRecordings(recordings: Recording[]) {
    return recordings.map((recording) => {
      recording.favourite = recording.shortlisted === '0' ? false : true;
      return recording;
    });
  }

  protected async extractAudioRecording(videoId: number) {
    return this.directorApiService.captureVideoExtractAudio(videoId);
  }
}
