import { merge } from 'lodash';

const MS_IN_A_SECOND = 1000;
const MS_IN_A_MINUTE = 60 * MS_IN_A_SECOND;
const MS_IN_AN_HOUR = 60 * MS_IN_A_MINUTE;

const DURATION_FORMAT_REG = /^(\d*)(:\d*){0,2}(\.\d*)?$/;

export type MillisecondDigits = 1 | 2 | 3;

export interface FormatDurationOptions {
  hours: boolean;
  minutes: boolean;
  seconds: boolean;
  milliseconds: false | MillisecondDigits;
}

const defaultFormatDurationOptions: FormatDurationOptions = {
  hours: false,
  minutes: true,
  seconds: true,
  milliseconds: false,
};

/** Format number-duration to string-duration.
 * @param {number} valueMS - Duration in milliseconds.
 * @param {boolean} options.hours - Show hours in the formatted duration.
 * @param {boolean} options.minutes - Show minutes in the formatted duration.
 * @param {boolean} options.seconds - Show seconds in the formatted duration.
 * @param {false | MillisecondDigits} options.milliseconds - If `false`, do not show milliseconds in the formatted duration. If `MillisecondDigits` is provided, then show the specified number of digits.
 * @returns The Duration in milliseconds.
 */
export function formatDuration(valueMS: number, options?: Partial<FormatDurationOptions>): string {
  const _options = merge({}, defaultFormatDurationOptions, options);

  if (
    (_options.hours && !_options.minutes) || // If show hours, then also show minutes and seconds
    (_options.minutes && !_options.seconds) || // If show minutes, then also seconds
    (!_options.seconds && _options.milliseconds !== 3) // If no hours, no minutes and no seconds, then miliseconds should be 3-digit
  ) {
    console.error('Invalid options');
    return '';
  }

  let value = valueMS;
  let hours: number, minutes: number, seconds: number, miliseconds: number;

  if (_options.hours) {
    hours = Math.floor(value / MS_IN_AN_HOUR);
    value -= hours * MS_IN_AN_HOUR;
  }
  if (_options.minutes) {
    minutes = Math.floor(value / MS_IN_A_MINUTE);
    value -= minutes * MS_IN_A_MINUTE;
  }
  if (_options.seconds) {
    seconds = Math.floor(value / MS_IN_A_SECOND);
    value -= seconds * MS_IN_A_SECOND;
  }
  if (_options.milliseconds) {
    miliseconds = Math.floor(value / 10 ** (3 - _options.milliseconds));
  }

  const format = [
    [
      _options.hours ? `${hours}`.padStart(2, '0') : null,
      _options.minutes ? `${minutes}`.padStart(2, '0') : null,
      _options.seconds ? `${seconds}`.padStart(2, '0') : null,
    ]
      .filter(Boolean)
      .join(':'),
    _options.milliseconds
      ? `${miliseconds}`.padStart(_options.milliseconds, '0').substring(0, _options.milliseconds)
      : null,
  ]
    .filter(Boolean)
    .join('.');

  return format;
}

/** Parse string-duration to number-duration.
 * @param {string} duration - Duration in the formats `hh:mm:ss[.S{1,3}]`, `mm:ss[.S{1,3}]`, `ss[.S{1,3}]` or `ss...s`.
 * @returns The Duration in milliseconds.
 */
export function parseDuration(duration: string): number {
  if (!DURATION_FORMAT_REG.test(duration)) {
    return 0;
  }

  const [secondsMs, minutes, hours] = duration.split(':').reverse();
  const [seconds, rawMilliseconds] = secondsMs.split('.');

  let milliseconds = rawMilliseconds;
  let millisecondsDigits = 3;
  if (milliseconds) {
    // Trim if more than 3 digits
    milliseconds = milliseconds.substring(0, 3);
    // Infer the number of digits of milliseconds
    millisecondsDigits = milliseconds.length;
  }

  return (
    +(milliseconds ? milliseconds + ''.padEnd(3 - millisecondsDigits, '0') : '0') +
    +(seconds ?? '0') * MS_IN_A_SECOND +
    +(minutes ?? '0') * MS_IN_A_MINUTE +
    +(hours ?? '0') * MS_IN_AN_HOUR
  );
}
