import { OFFSCREEN_CANVAS_HEIGHT, OFFSCREEN_CANVAS_WIDTH } from '@openreel/creator/common';
import { Canvas, CanvasKit, Surface } from './canvas-kit';
import { SkottieCanvasKitAnimation } from './skottie-canvas-kit-animation';
import { BehaviorSubject, fromEvent, take } from 'rxjs';

export type CallbackFn = (skiaCanvas: Canvas, offScreenCanvas: HTMLCanvasElement) => Promise<void>;
const MAX_CANVASES = 16;

interface SkottieRenderer {
  offScreenCanvas: HTMLCanvasElement;
  skottieSurface: Surface;
  webglContextLost: BehaviorSubject<boolean>;
  canvasBusy: boolean;
}

export class SkottieCanvasKitRenderer {
  private queue: { animation: SkottieCanvasKitAnimation; callback: CallbackFn }[] = [];
  private renderers: SkottieRenderer[];
  private destroyInProgress = false;

  constructor(private CanvasKit: CanvasKit) {
    this.init();
  }

  public requestAnimationFrame(animation: SkottieCanvasKitAnimation, callback: CallbackFn) {
    if (this.destroyInProgress) {
      console.warn('Cannot request new frame, cleanup is in progress.')
    } else {
      this.queue.push({ animation, callback });
      this.processQueue();
    }
  }

  private init() {
    this.renderers = [];
    this.destroyInProgress = false;

    for (let i = 0; i < MAX_CANVASES; i++) {
      this.renderers.push({
        ...this.initRenderer(),
        canvasBusy: false,
      });
    }
  }

  private initRenderer() {
    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = OFFSCREEN_CANVAS_WIDTH;
    offScreenCanvas.height = OFFSCREEN_CANVAS_HEIGHT;
    const skottieSurface = this.CanvasKit.MakeWebGLCanvasSurface(offScreenCanvas);

    const renderer = {
      offScreenCanvas,
      skottieSurface,
      webglContextLost: new BehaviorSubject(false),
    }

    fromEvent(offScreenCanvas, 'webglcontextlost').pipe((take(1))).subscribe(() => {
      renderer.webglContextLost.next(true);
    });

    if (!skottieSurface) {
      throw new Error('Could not make skottie surface.');
    }

    return renderer
  }

  private processQueue() {
    if (this.queue.length > 0 && !this.destroyInProgress) {
      const renderer = this.nextAvailableRenderer();

      if (renderer) {
        renderer.canvasBusy = true;
        renderer.skottieSurface.requestAnimationFrame(this.renderFrame.bind(this, renderer));
      }
    }
  }

  private nextAvailableRenderer() {
    const renderer = this.renderers.find((renderer) => !renderer.canvasBusy && !renderer.skottieSurface.isDeleted());

    if (renderer) {
      if (renderer.webglContextLost.getValue()) {
        const newRenderer = this.initRenderer();
        renderer.skottieSurface = newRenderer.skottieSurface;
        renderer.offScreenCanvas = newRenderer.offScreenCanvas;
        renderer.webglContextLost = newRenderer.webglContextLost;
      }
    }

    return renderer;
  }

  private async renderFrame(renderer: SkottieRenderer, skiaCanvas: Canvas) {
    if (!renderer.skottieSurface || renderer.skottieSurface.isDeleted() || this.destroyInProgress) {
      renderer.canvasBusy = false;
      return;
    }

    const nextItem = this.queue.shift();
    if (nextItem) {
      await nextItem.callback(skiaCanvas, renderer.offScreenCanvas);
    }
    renderer.canvasBusy = false;
    this.processQueue();
  }

  destroy(animation: SkottieCanvasKitAnimation) {
    this.queue = this.queue.filter((item) => item.animation !== animation);
  }

  async destroyAll() {
    this.queue = [];
    this.destroyInProgress = true;

    let busyRenderers = 0;

    do {
      busyRenderers = await this.cleanupRenderers();
    } while(busyRenderers > 0)

    this.renderers = [];
  }

  private cleanupRenderers() {
    return new Promise<number>((resolve) => {
      setTimeout(() => {
        let busyRenderers = 0;

        this.renderers.forEach((renderer) => {
          if (renderer.canvasBusy) {
            busyRenderers++;
          } else {
            renderer.skottieSurface?.delete();
            renderer.skottieSurface = null;
            renderer.offScreenCanvas?.remove();
            renderer.offScreenCanvas = null;
          }
        });

        this.renderers = this.renderers.filter((renderer) => !!renderer.skottieSurface);

        resolve(busyRenderers);
      });
    })
  }
}
