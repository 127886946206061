import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Input,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Cleanupable, fadeIn, heightCollapse } from '@openreel/frontend/common';
import { LetDirective } from '@openreel/frontend/common/directives/let.directive';
import { HostablePermissionTypes } from '@openreel/frontend/common/hosting';
import { ShareModalData } from '@openreel/frontend/common/hosting/dialogs/share/share-modal-data.interface';
import { startWith } from 'rxjs';
import { HOSTING_SHARE_FORM, HostingShareForm } from '../hosting-share-form.service';
import { HostingShareComponentStore } from '../hosting-share.store';
import { distinctUntilChanged, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'openreel-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn, heightCollapse],
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    FlexModule,
    LetDirective,
    MatButtonModule,
    MatDividerModule,
    MatSlideToggleModule,
  ],
})
export class SetPasswordComponent extends Cleanupable implements OnInit {

  @Input() readonly data: ShareModalData;

  readonly saving$ = this.hostingShareComponentStore.saving$;
  readonly shareModalData$ = this.hostingShareComponentStore.shareModalData$;

  showPassword = false;

  get entity(): 'video' | 'hub' {
    return this.data.type;
  }

  get passwordControl(): FormControl {
    return this.form.controls.password as FormControl;
  }

  get requirePasswordControl(): FormControl {
    return this.form.controls.requirePassword as FormControl;
  }

  constructor(
    @Inject(HOSTING_SHARE_FORM) readonly form: FormGroup<HostingShareForm>,
    private readonly cdr: ChangeDetectorRef,
    private readonly hostingShareComponentStore: HostingShareComponentStore
  ) {
    super();
  }

  ngOnInit(): void {
    this.setValidationOnRequirePasswordChanges();
    this.subscribeToPasswordChanges();
    this.updatePermissionOnPasswordDisable();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;

    this.cdr.markForCheck();
  }

  setPassword(): void {
    if (this.passwordControl.invalid) {
      return;
    }

    const password = this.passwordControl.value;

    this.hostingShareComponentStore.updatePassword(password);
    this.resetPasswordControl();

  }

  private updatePermissionOnPasswordDisable(): void {
    this.mortalize(this.requirePasswordControl.valueChanges).pipe(
      startWith<boolean>(this.requirePasswordControl.value as boolean),
      withLatestFrom(this.shareModalData$),
      tap(([requirePassword, shareModalData]) => {
        if (!requirePassword && shareModalData.permission === HostablePermissionTypes.Password) {
          this.hostingShareComponentStore.updatePermission(HostablePermissionTypes.Public);
        }
      }),
    ).subscribe();
  }

  private setValidationOnRequirePasswordChanges(): void {
    this.mortalize(this.requirePasswordControl.valueChanges)
      .pipe(
        startWith<boolean>(this.requirePasswordControl.value as boolean),
        distinctUntilChanged(),
        withLatestFrom(this.shareModalData$),
        tap(([requirePassword, data]) => {
          if (requirePassword) {
            this.passwordControl.enable();

            // If permission is "password", then password is optional
            if (data.permission === HostablePermissionTypes.Password) {
              this.passwordControl.removeValidators([Validators.required]);
            } else {
              this.passwordControl.addValidators([Validators.required]);
            }
          } else {
            this.passwordControl.setValue(null);
            this.passwordControl.disable();
            this.passwordControl.removeValidators([Validators.required]);
          }

          this.passwordControl.updateValueAndValidity();
        }),
      )
      .subscribe();
  }

  private subscribeToPasswordChanges(): void {
    this.mortalize(this.passwordControl.valueChanges)
      .pipe(
        startWith<string>(this.passwordControl.value as string),
        withLatestFrom(this.shareModalData$),
        tap(([password, shareModalData]) => {
          if (!password && shareModalData.permission === HostablePermissionTypes.Password) {
            this.hostingShareComponentStore.setPasswordSubmitted(true);
          } else {
            this.hostingShareComponentStore.setPasswordSubmitted(false);
          }
        }),
      )
      .subscribe();
  }

  private resetPasswordControl(): void {
    this.passwordControl.setValue(null, { emitEvent: false });
    this.passwordControl.removeValidators([Validators.required]);
    this.passwordControl.updateValueAndValidity();
  }
}
