import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HostingShareComponentStore } from '../hosting-share.store';
import { AuthService, FeatureFlaggingService } from '@openreel/frontend/common';
import { GROUPS_PERMISSIONS_SHARING } from '@openreel/common';
import { SharePermissionedGroup, SharePermissionedUser } from '../../../../models/object-user.interface';

@Component({
  selector: 'openreel-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent {
  readonly groupsPermissionsFeatureEnabled$ =
    this.featureFlaggingService.isFeatureFlagEnabled(GROUPS_PERMISSIONS_SHARING);
  readonly objectUsers$ = this.hostingShareComponentStore.objectUsers$;
  readonly objectGroups$ = this.hostingShareComponentStore.objectGroups$;
  readonly objectUsersLoaded$ = this.hostingShareComponentStore.objectUsersLoaded$;
  readonly loggedInUserId$ = this.authService.userId$;

  constructor(
    private readonly hostingShareComponentStore: HostingShareComponentStore,
    private readonly featureFlaggingService: FeatureFlaggingService,
    private readonly authService: AuthService
  ) {}

  trackById(index: number, user: SharePermissionedUser | SharePermissionedGroup): SharePermissionedUser['id'] {
    return user.id;
  }
}
