import { ColorPaletteGenerator } from './color-palette-generator';

const DEFAULT_COLOR = '#000000';

export type Color4 = [number, number, number, number];

export function getColorLottieTransformation(color: string, paletteKey?: number) {
  if (paletteKey) {
    const entirePalette = ColorPaletteGenerator.generatePalette(color);
    const paletteValue = entirePalette.find((p) => p.shade === paletteKey);
    return hexToRgba(paletteValue?.hex ?? color);
  }

  return hexToRgba(color);
}

export function getColorLottie(fieldKey: string, color: string, generatePalette: boolean) {
  let colors = getSingleColorLottie(fieldKey, color);

  if (generatePalette) {
    ColorPaletteGenerator.generatePalette(color?.length ? color : DEFAULT_COLOR).forEach((shadeColor) => {
      colors = {
        ...colors,
        ...getSingleColorLottie(fieldKey, shadeColor.hex, shadeColor.shade),
      };
    });
  }

  return colors;
}

export function getSingleColorLottie(fieldKey: string, color: string, shade: number = null) {
  const rgbValues = hexToRgba(color?.length ? color : DEFAULT_COLOR);
  const shadeString = shade ? shade : '';

  return {
    [`${fieldKey}Color${shadeString}`]: `[${rgbValues.toString()}]`,
    [`${fieldKey}Color${shadeString}R`]: rgbValues[0].toString(),
    [`${fieldKey}Color${shadeString}G`]: rgbValues[1].toString(),
    [`${fieldKey}Color${shadeString}B`]: rgbValues[2].toString(),
    [`${fieldKey}Color${shadeString}Hex`]: `"${color}"`,
  };
}

// Source: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgba(hex: string): Color4 | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16) / 255,
        parseInt(result[2], 16) / 255,
        parseInt(result[3], 16) / 255,
        parseInt(result[4] ?? 'FF', 16) / 255,
      ]
    : null;
}

function componentToHex(comp: number) {
  const hex = comp.toString(16);
  return hex.padStart(2, '0');
}

export function rgbaToHex(color: Color4) {
  return (
    '#' +
    componentToHex(Math.trunc(255 * color[0])) +
    componentToHex(Math.trunc(255 * color[1])) +
    componentToHex(Math.trunc(255 * color[2])) +
    (color.length === 4 ? componentToHex(Math.trunc(255 * color[3])) : '')
  );
}

export function hexCssToRgb(hex: string): number[] | null {
  const rgba = hexToRgba(hex);

  if (rgba) {
    return rgba.map((c) => c * 100).slice(0, 3);
  }

  return null;
}

export function invertColor(color: Color4) {
  const newColor: Color4 = [...color];
  for (let i = 0; i < 3; i++) {
    newColor[i] = 1 - newColor[i];
  }
  return newColor;
}
