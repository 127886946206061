import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FileMetadata,
  UPLOADER_SERVICE_TOKEN,
  UploaderOptions,
  VALIDATOR_SERVICE_TOKEN,
} from '@openreel/ui/openreel-uploader/openreel-uploader.component';
import { UserUploadStateService } from '../../services/user-upload-state.service';
import { UserUploadFileValidatorService } from '../../services/user-upload-file-validator.service';
import { FileInfo, OPENREEL_UPLOAD_MANAGER } from '@openreel/ui/openreel-uploader';

@Component({
  selector: 'or-user-upload-selector',
  templateUrl: './user-upload-selector.component.html',
  styleUrls: ['./user-upload-selector.component.scss'],
  providers: [
    { provide: UPLOADER_SERVICE_TOKEN, useExisting: UserUploadStateService },
    { provide: VALIDATOR_SERVICE_TOKEN, useExisting: UserUploadFileValidatorService },
  ],
})
export class UserUploadSelectorComponent {
  @Input() options: UploaderOptions;
  @Input() extra: Record<string, any> = {};
  @Output() uploadStarted = new EventEmitter<number>();
  @Output() filesSelected = new EventEmitter<FileInfo[]>();

  onUploadStarted(id: number) {
    this.uploadStarted.emit(id);
  }

  onFilesSelected(files: FileInfo[]): void {
    this.filesSelected.emit(files);
  }
}
