import { Observable } from "rxjs";
import { DrawEventData, DrawEventType, DrawableArea, DrawableShape } from "./drawable.types";
import Konva from "konva";
import { ArrowAttributes, DrawableAreaData, DrawableShapeAttributes, DrawableShapeType, RectangleAttributes } from "@openreel/common";

export class DrawableKonvaWrapper implements DrawableArea {
    private _stage: Konva.Stage;
    private _layer: Konva.Layer;
    private _shapes: DrawableShape<DrawableShapeAttributes>[];

    private constructor(container: HTMLDivElement) {
        this._createKonvaStage(container);
        this._createLayer();
        this._shapes = [];
    }

    static create(container: HTMLDivElement) {
        return new DrawableKonvaWrapper(container);
    }
  
    get shapes() { return this._shapes; }
    get draw$() { return _fromDrawOnKonvaStageEvent(this._stage) }

    toJSON(): DrawableAreaData {
        const size = this._stage.size();
        return {
            shapes: this._shapes.map(shape => ({ 
                type: shape.type, 
                attributes: _getShapeRelativeAttributes(shape.type, shape.attributes, size) 
            }))
        }
    }

    setSize(width: number, height: number): void {
        this._stage.size({ width, height });
    }

    clearShapes(): void {
        this._shapes = [];
        this._layer.destroyChildren();
    }

    drawFromJSON(areaJson: DrawableAreaData) {
        if(areaJson?.shapes?.length) {
            const size = this._stage.size();
            areaJson.shapes.forEach(shape => {
                const absoluteAttrs = _getShapeAbsoluteAttributes(shape.type, shape.attributes, size);
                this.drawShape(shape.type, absoluteAttrs)
            })
        }
    }

    destroy() {
        this._shapes = [];
        this._layer.destroy();
        this._stage.destroy();
    }

    drawShape(shapeType: DrawableShapeType, attributes?: DrawableShapeAttributes): DrawableShape<DrawableShapeAttributes> {
        const [drawableShape, konvaShape] = _drawShape(shapeType, attributes);
        this._layer.add(konvaShape);
        this._shapes.push(drawableShape);
        return drawableShape;
    }


    private _createKonvaStage(containerElement: HTMLDivElement): void {
        const size = containerElement.getBoundingClientRect();
        this._stage = new Konva.Stage({
            container: containerElement,
            width: size.width,
            height: size.height,
        })
    }

    private _createLayer() {
        this._layer = new Konva.Layer();
        this._stage.add(this._layer);
    }
}


function _drawShape(shapeType: DrawableShapeType, attributes?: DrawableShapeAttributes): [DrawableShape<any>, Konva.Shape] {
    switch(shapeType) {
        case DrawableShapeType.Rectangle: return _drawRectangle(attributes as RectangleAttributes);
        case DrawableShapeType.Arrow: return _drawArrow(attributes as ArrowAttributes);
        default: throw new Error('Unknown shape type');
    }
}

function _drawRectangle(attrs?: RectangleAttributes): [DrawableShape<RectangleAttributes>, Konva.Shape] {
    let attributes: RectangleAttributes = attrs ?? {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
        strokeWidth: 3,
        color: '#FFF',
        cornerRadius: 0,
    }
    const { color, ...rest } = attributes;
    const rect = new Konva.Rect({
        ...rest,
        stroke: color,
    })

    const drawableShape: DrawableShape<RectangleAttributes> = {
        type: DrawableShapeType.Rectangle,
        get attributes() { return attributes; },
        setColor: color => {
            attributes.color = color;
            rect.stroke(attributes.color);
        },
        setStartPoint: coords => {
            attributes.x = coords.x;
            attributes.y = coords.y;
            rect.x(attributes.x); 
            rect.y(attributes.y);
        },
        setEndPoint: coords => {
            attributes.width = coords.x - attributes.x;
            attributes.height = coords.y - attributes.y;
            rect.size({
                width: attributes.width,
                height: attributes.height,
            });
        }
    }

    return [drawableShape, rect]
}


function _drawArrow(attrs?: ArrowAttributes): [DrawableShape<ArrowAttributes>, Konva.Shape] {
    let attributes: ArrowAttributes = attrs ?? {
        start: [0,0],
        end: [0,0],
        strokeWidth: 3,
        color: '#FFF',
    }
    const { start, end, color, strokeWidth } = attributes;
    const arrow = new Konva.Arrow({
        points: [...start, ...end],
        strokeWidth,
        stroke: color,
    })

    const drawableShape: DrawableShape<ArrowAttributes> = {
        type: DrawableShapeType.Arrow,
        get attributes() { return attributes; },
        setColor: color => {
            attributes.color = color;
            arrow.stroke(attributes.color)
        },
        setStartPoint: coords => {
            attributes.start = [coords.x, coords.y];
            attributes.end = [coords.x, coords.y]
            arrow.points([...attributes.start, ...attributes.end]);
        },
        setEndPoint: coords => {
            attributes.end = [coords.x, coords.y];
            arrow.points([...attributes.start, ...attributes.end]);
        }
    }   

    return [drawableShape, arrow]
}




function _fromDrawOnKonvaStageEvent(stage: Konva.Stage): Observable<DrawEventData> {
    return new Observable((observer) => {
      let isDrawing: boolean = false;
  
      const pointerDownListener = (e: Konva.KonvaEventObject<PointerEvent>) => {
        isDrawing = true;
        const position = stage.getPointerPosition();
        observer.next({ type: DrawEventType.StartDrawing, position })
      }
      const pointerMoveListener = (e: Konva.KonvaEventObject<PointerEvent>) => {
        if(!isDrawing) return;
        const position = stage.getPointerPosition();
        observer.next({ type: DrawEventType.MovePointer, position })
      }
      const pointerUpListener = (e: Konva.KonvaEventObject<PointerEvent>) => {
        const position = stage.getPointerPosition();
        observer.next({ type: DrawEventType.CompleteDrawing, position });
        isDrawing = false;
      }
      
      stage.on('pointerdown', pointerDownListener);
      stage.on('pointermove', pointerMoveListener);
      stage.on('pointerup', pointerUpListener);
  
      return () => {
        stage.off('pointerdown', pointerDownListener);
        stage.off('pointermove', pointerMoveListener);
        stage.off('pointerup', pointerUpListener);
      }
    })
}



function _getShapeRelativeAttributes(type: DrawableShapeType, attributes: DrawableShapeAttributes, size: { width: number, height: number }): DrawableShapeAttributes {
    switch(type) {
        case DrawableShapeType.Rectangle:
            const r_attr = attributes as RectangleAttributes;
            const x = r_attr.x * 100 / size.width;
            const y = r_attr.y * 100 / size.height;
            const width = r_attr.width * 100 / size.width;
            const height = r_attr.height * 100 / size.height;
            return { ...r_attr, x, y, width, height };
            break;
        case DrawableShapeType.Arrow:
            const a_attr = attributes as ArrowAttributes;
            const [startX, startY] = a_attr.start;
            const start: [number, number] = [ startX * 100 / size.width, startY * 100 / size.height ];
            const [endX, endY] = a_attr.end;
            const end: [number, number] = [ endX * 100 / size.width, endY * 100 / size.height ];
            return { ...a_attr, start, end }
            break; 
        default:
            break;
    }
}


function _getShapeAbsoluteAttributes(type: DrawableShapeType, attributes: DrawableShapeAttributes, size: { width: number, height: number }): DrawableShapeAttributes {
    switch(type) {
        case DrawableShapeType.Rectangle:
            const r_attr = attributes as RectangleAttributes;
            const x = r_attr.x * size.width / 100;
            const y = r_attr.y * size.height / 100;
            const width = r_attr.width * size.width / 100;
            const height = r_attr.height * size.height / 100;
            return { ...r_attr, x, y, width, height };
        case DrawableShapeType.Arrow:
            const a_attr = attributes as ArrowAttributes;
            const [startX, startY] = a_attr.start;
            const start: [number, number] = [ startX * size.width / 100, startY * size.height / 100 ];
            const [endX, endY] = a_attr.end;
            const end: [number, number] = [ endX * size.width / 100, endY * size.height / 100 ];
            return { ...a_attr, start, end }
        default:
            break;
    }
}