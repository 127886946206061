import { Border, BorderRadius, Bounds, GradientColor, LayerStyles, SolidColor } from '../interfaces';
import { hexToRgba } from './color.helpers';
import { pctToPx } from './timelines.helpers';

export interface LayerCssStyles {
  background: string;
  borderWidth: string;
  borderColor: string;
  padding: string;
  borderRadiusOuter: string;
  borderRadiusInner: string;
  borderWidthPx: {
    x: number;
    y: number;
  }
  borderRadiusInnerPx: { x: number; y: number }[];
  borderRadiusOuterPx: { x: number; y: number }[];
}

export function styleLeftFromBounds(bounds: Bounds) {
  return `${bounds?.x || 0}%`;
}

export function styleTopFromBounds(bounds: Bounds) {
  return `${bounds?.y || 0}%`;
}

export function styleWidthFromBounds(bounds: Bounds) {
  return bounds?.width ? `${bounds?.width}%` : 'auto';
}

export function styleHeightFromBounds(bounds: Bounds) {
  return bounds?.height ? `${bounds?.height}%` : 'auto';
}

export function styleRotationFromBounds(bounds: Bounds) {
  return `rotate(${bounds?.angleRad ?? 0}rad)`;
}

export function calculateLayerCssStyles(hostWidth: number, hostHeight: number, styles: LayerStyles): LayerCssStyles {
  const minAxisValue = Math.min(hostWidth, hostHeight);
  const { cssBorderWidth, borderWidthPx } = calculateStylesBorderWidth(styles.border, minAxisValue);
  const { cssBackground } = calculateStylesBackground(styles.backgroundColor);
  const { cssBorderRadiusOuter, cssBorderRadiusInner, borderRadiusInnerPx, borderRadiusOuterPx } = calculateStylesBorderRadius(
    styles.borderRadius,
    borderWidthPx,
    minAxisValue
  );

  const calculatedStyles: LayerCssStyles = {
    background: cssBackground,
    borderWidth: '0px',
    borderColor: 'transparent',
    borderRadiusOuter: cssBorderRadiusOuter,
    borderRadiusInner: cssBorderRadiusInner,
    padding: '0px',
    borderWidthPx,
    borderRadiusInnerPx,
    borderRadiusOuterPx,
  };

  if (cssBorderWidth) {
    if (styles.border?.color && hexToRgba(styles.border?.color)[3] > 0) {
      calculatedStyles.borderWidth = cssBorderWidth;
      calculatedStyles.borderColor = styles.border?.color;
    } else {
      calculatedStyles.padding = cssBorderWidth;
    }
  }

  return calculatedStyles;
}

export function calculateStylesBackground(backgroundColor: SolidColor | GradientColor) {
  let cssBackground = 'none';
  if (backgroundColor?.type === 'solid') {
    cssBackground = backgroundColor.color;
  } else if (backgroundColor?.type === 'gradient') {
    const colors = backgroundColor.colors.map((color) => `${color.color} ${color.stop}%`);
    cssBackground = `linear-gradient(${backgroundColor.angle}deg, ${colors.join(',')})`;
  }

  return { cssBackground };
}

export function calculateStylesBorderWidth(border: Border, minAxisValue: number) {
  const borderWidthPx = { x: 0, y: 0 };
  let cssBorderWidth: string;

  if (border) {
    const minAxis = minAxisValue;
    borderWidthPx.x = pctToPx(border.x, minAxis);
    borderWidthPx.y = pctToPx(border.y, minAxis);
    cssBorderWidth = `${borderWidthPx.y}px ${borderWidthPx.x}px`;
  }
  return { cssBorderWidth, borderWidthPx };
}

export function calculateStylesBorderRadius(
  borderRadius: BorderRadius[],
  borderWidthPx: { x: number; y: number },
  minAxisValue: number
) {
  let cssBorderRadiusOuter: string;
  let cssBorderRadiusInner: string;
  let borderRadiusOuterPx: { x: number; y: number }[] = [];
  let borderRadiusInnerPx: { x: number; y: number }[] = [];

  if (borderRadius) {
    borderRadiusOuterPx = borderRadius.map((br) => ({
      x: pctToPx(br.x, minAxisValue),
      y: pctToPx(br.y, minAxisValue),
    }));

    borderRadiusInnerPx = borderRadiusOuterPx.map((br) => ({
      x: Math.max(0, br.x - borderWidthPx.x),
      y: Math.max(0, br.y - borderWidthPx.y),
    }));

    cssBorderRadiusOuter = borderRadiusOuterPx.map((br) => `${br.x}px`).join(' ');
    cssBorderRadiusOuter += ' / ';
    cssBorderRadiusOuter += borderRadiusOuterPx.map((br) => `${br.y}px`).join(' ');

    cssBorderRadiusInner = borderRadiusOuterPx.map((br) => `${Math.max(0, br.x - borderWidthPx.x)}px`).join(' ');
    cssBorderRadiusInner += ' / ';
    cssBorderRadiusInner += borderRadiusOuterPx.map((br) => `${Math.max(0, br.y - borderWidthPx.y)}px`).join(' ');
  }

  return { cssBorderRadiusOuter, cssBorderRadiusInner, borderRadiusOuterPx, borderRadiusInnerPx };
}
