import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SsoAuthComponent } from './sso-auth/sso-auth.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { SsoSetupComponent } from './sso-setup/sso-setup.component';
import { AuthGuard, ROUTE_SSO_AUTH, ROUTE_SSO_LOGIN, ROUTE_SSO_SETUP } from '@openreel/frontend/common';
const routes: Routes = [
  {
    path: ROUTE_SSO_AUTH + '/:slug',
    component: SsoAuthComponent,
  },
  {
    path: ROUTE_SSO_LOGIN,
    component: SsoLoginComponent,
  },
  {
    path: ROUTE_SSO_SETUP,
    component: SsoSetupComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SsoRoutingModule {}
