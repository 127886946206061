<div class="set-password-container">
  <div class="header">
    <div class="headings">
      <h2 class="headline-h400 title">Require password</h2>
      <p class="paragraph-p100 subtitle">Set a password that is needed to view the {{ entity }}</p>
    </div>

    <mat-slide-toggle
      color="primary"
      labelPosition="before"
      class="require-password-toggle"
      [formControl]="requirePasswordControl"
    >
      <h2 data-cy="toggle-password" class="headline-h300 title toggle-title">{{ requirePasswordControl.value ? 'Active' : 'Inactive' }}</h2>
    </mat-slide-toggle>
  </div>

  <div class="active-panel" *ngIf="requirePasswordControl.value" @fadeIn @heightCollapse>
    <mat-divider></mat-divider>

    <div [formGroup]="form" class="set-password-control">
      <mat-form-field appearance="outline" class="password">
        <input
          matInput
          placeholder="Choose a password of 5 or more characters"
          name="password"
          autocomplete="new-password"
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
        />

        <mat-icon
          matSuffix
          class="toggle-password-visibility"
          (click)="togglePasswordVisibility()"
        >
          {{ showPassword ? 'visibility' : 'visibility_off' }}
        </mat-icon>
      </mat-form-field>

      <button
        class="set-password-btn"
        mat-flat-button
        color="primary"
        (click)="setPassword()"
        [disabled]="(saving$ | async) || passwordControl.invalid || !passwordControl.value"
      >
        Save password
      </button>
    </div>
  </div>
</div>

<div class="errors-container">
  <mat-error *ngIf="passwordControl.errors?.required" class="paragraph-p100">Password is required</mat-error>
  <mat-error *ngIf="passwordControl.errors?.minlength" class="paragraph-p100">Password must be at least 5 characters long.</mat-error>
  <mat-error *ngIf="passwordControl.errors?.maxlength" class="paragraph-p100">Password cannot exceed 20 characters.</mat-error>
</div>

<div class="password-protected-text" *openreelLet="shareModalData$ | async as hostableDetails">
  <span class="paragraph-p100" *ngIf="hostableDetails.permission === 'password'">
    Password is already active. Enter new password to update
  </span>
</div>
