import { Observable, OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function skipFirstIf<T>(predicate: (value: T) => boolean): OperatorFunction<T, T> {
  return (source$) => new Observable(subscriber => source$.pipe(
      filter((data, index) => index === 0 ? !predicate(data) : true)
    ).subscribe(data => {
      subscriber.next(data);
    }));
}
