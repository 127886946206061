import { LayoutType } from '../../../interfaces/layouts.interfaces';

/* eslint-disable @typescript-eslint/no-namespace */
export namespace WorkflowDataDto_2_1 {
  export const BRAND_KIT_LOGO_TAG_NAME = 'brand-kit-logo';
  export const BRAND_KIT_WATERMARK_TAG_NAME = 'brand-kit-watermark';
  export const BRAND_KIT_BACKGROUND_VIDEO_TAG_NAME = 'brand-kit-background-video';

  export const ELEMENT_ASSET_LOGO_ID = 'wizard-element-logo';
  export const ELEMENT_ASSET_BACKGROUND_VIDEO_ID = 'wizard-element-background-video';

  export const LATEST_PROJECT_VERSION = '2.0';
  export const RENDERED_VIDEO_WIDTH = 1920;
  export const RENDERED_VIDEO_HEIGHT = 1080;

  // Presets
  // Image, video are not actual possible preset types but we need them here since we use this interface in layer.service
  // And there you can edit image or video LAYER
  export type PresetFieldType = 'text' | 'image' | 'logo' | 'shape' | 'video' | 'fill';

  export type FontExtension = 'ttf' | 'otf' | 'woff' | 'woff2' | 'svg';

  export interface PresetField {
    type: PresetFieldType;
    showOnUi?: boolean;
    lottiePath?: string;
    label?: string;
    order?: number;
    assetId?: string;
    colorTag?: string;
    validationRules?: {
      required?: boolean;
      minlength?: number;
      maxlength?: number;
    };
    // Text properties
    fontIndex?: number;
    fontWeight?: number;
    defaultValue?: string;
    // Shape properties
    colorShade?: number;
    // Image properties
    lottieAssetRefId?: string;
  }

  export interface Preset {
    [key: string]: PresetField;
  }

  // Assets
  export interface UrlFile {
    path: string;
    provider: 'url';
  }

  export type AssetsFileProviderType = 'or-assets' | 'or-recordings';

  export type AssetId = number | string;

  export interface OrAssetsFile {
    path: AssetId;
    provider: AssetsFileProviderType;
  }

  export interface OrAssetsListFile extends OrAssetsFile {
    isDefault: boolean;
    fileName: string;
  }

  export type File = UrlFile | OrAssetsFile;

  export type Source = 'webcam' | 'screen';

  export interface AssetMetadata {
    name?: string;
    duration?: number;
    source?: Source;
  }

  export interface ColorTag {
    name: string;
    tag: string;
    color: string;
  }

  export type AssetType = 'json' | 'image' | 'clip';

  export interface Asset {
    id: string;
    name?: string;
    type: AssetType;
    file?: File;
    fileName?: string;
    trimFrom?: number;
    trimTo?: number;
    data?: AssetMetadata;
    colorTags?: ColorTag[];
    preset?: Preset;
    isGlobal?: boolean;
    isPlaceholder?: boolean;
    tags?: string[];
  }

  export interface GoogleFontAsset {
    id: string;
    name?: string;
    type: 'font';
    file: File;
    family: string;
    weight?: string;
    weights?: string[];
    extra?: boolean;
    custom?: false;
  }

  export interface CustomFontAsset {
    id: string;
    name: string;
    type: 'font';
    files: UrlFile[];
    formats: FontExtension[];
    family: string;
    weights: string[];
    custom: true;
  }

  export type FontAsset = GoogleFontAsset | CustomFontAsset;

  export interface LottieMetadata {
    preset: Preset;
    data?: AssetMetadata;
    colorTags?: ColorTag[];
  }

  // Layers
  export type Layer = LayerOptions &
    (LottieLayer | TimelinesLayer | SectionLayer | VideoLayer | ImageLayer | ColorLayer);

  export type ChildLayer = LayerOptions & (LottieLayer | VideoLayer);

  export interface LottieLayerFieldData {
    type: PresetFieldType;
    styleId?: string;
    assetId?: string;
    value?: string;
  }

  export interface LottieProcessedFieldsData {
    [key: string]: {
      value?: string;
      color?: number[];
      font?: string;
      url?: string;
    };
  }

  export interface SolidColor {
    type: 'solid';
    color: string;
  }

  export interface GradientColor {
    type: 'gradient';
    angle: number;
    colors: {
      stop: number;
      color: string;
    }[];
  }

  export interface Bounds {
    x: number;
    y: number;
    width: number;
    height?: number;
  }

  export interface BorderRadius {
    x: number;
    y: number;
  }

  export interface Border {
    x: number;
    y: number;
    color?: string;
  }

  export type BorderRadiusType = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

  export const BORDER_RADIUS_TYPES: BorderRadiusType[] = ['top-left', 'top-right', 'bottom-right', 'bottom-left'];

  export interface LayerStyles {
    borderRadius?: BorderRadius[];
    border?: Border;
    backgroundColor?: SolidColor | GradientColor;
    objectFit?: 'cover' | 'contain';
    objectPosition?: number[];
  }

  export interface LayerControls {
    zoomToFit: boolean;
  }

  export type LottieRenderer = 'canvas' | 'svg' | 'html';

  export interface Visibility {
    startAt: number;
    endAt?: number;
  }

  export type TransitionType = 'fade-out' | 'fade-in' | 'push' | 'layer';
  export type TransitionTimingFunction = 'easeOutExpo' | 'linear';
  export type TransitionDirection = 'top' | 'right' | 'bottom' | 'left';

  export interface Transitions {
    entrance?: Transition;
    exit?: Transition;
    cross?: Transition;
    crossLayer?: TransitionLayer;
  }

  export interface PushTransition {
    type: 'push';
    direction: TransitionDirection;
    duration: number;
    timingFunction: TransitionTimingFunction;
  }

  export interface FadeTransition {
    type: 'fade-out' | 'fade-in';
    color?: string;
    duration: number;
  }

  export type Transition = FadeTransition | PushTransition;

  export interface TransitionLayer {
    type: 'layer';
    duration?: number;
    layer: ChildLayer;
  }

  export interface TimelinesLayer {
    type: 'timelines';
    children: Timeline[];
  }

  export interface LottieLayerData {
    [key: string]: LottieLayerFieldData;
  }

  export interface AssetLayer {
    assetId: string;
  }

  export interface LottieLayer extends AssetLayer {
    type: 'lottie';
    renderer: LottieRenderer;
    data?: LottieLayerData;
    colorTags?: ColorTag[];
  }

  export type SectionType = 'intro' | 'main' | 'outro';
  export enum SectionId {
    Intro = 'intro',
    Outro = 'outro',
  }

  export interface SectionLayer {
    type: 'section';
    sectionId: string;
  }

  export interface SectionLayout {
    templateLayoutId: number;
    layoutType: LayoutType;
  }

  export interface Section {
    sectionType: SectionType;
    timelines: Timeline[];
    layout?: SectionLayout;
    backgroundColor: SolidColor | GradientColor;
  }

  export interface VideoLayer extends AssetLayer {
    type: 'video';
    editing?: {
      label: string;
    };
  }

  export interface ImageLayer extends AssetLayer {
    type: 'image';
    editing?: {
      label: string;
    };
  }

  export interface ColorLayer {
    type: 'color';
    styleId: string;
  }

  export type LayerType = 'lottie' | 'image' | 'video' | 'color';

  export interface LayerOptions {
    layerId: string;
    enabled: boolean;
    loop: boolean;
    visibility?: Visibility;
    transitions?: Transitions;
    bounds?: Bounds;
    styles?: LayerStyles;
    tags?: string[];
  }

  // Features
  export interface CaptionsFeature {
    burnIn?: boolean;
    upToDate?: boolean;
  }

  export interface IntroOutroFeature {
    dataLayerIds: string[];
    dataVisibleFieldTypes: string[];
  }

  export interface LayoutsPlaceholders {
    mainClips: {
      simpleAssetId?: string;
      interviewAssetIds?: string[];
      presentationAssetIds?: string[];
    };
  }

  export interface LayoutsFeature {
    styles?: LayerStyles;
    placeholders?: LayoutsPlaceholders;
  }

  export interface Features {
    layouts?: LayoutsFeature;
    captions?: CaptionsFeature;
    intro?: IntroOutroFeature;
    outro?: IntroOutroFeature;
  }

  export interface Style {
    id: string;
    color?: string;
    colorShade?: number;
    fontIndex?: number;
    fontWeight?: number;
  }

  // Workflow
  export type TimelineType = 'background' | 'main' | 'b-roll' | 'overlays' | 'watermark';

  export interface TimelineLayersControls {
    zoomToFit?: boolean;
    switch?: boolean;
  }

  export interface Timeline {
    type: TimelineType;
    id?: string;
    title?: string;
    layers: Layer[];
    hasAudio?: boolean;
    isLayerSelectable?: boolean;
    controls?: TimelineLayersControls;
    zIndex?: number;
    bounds?: Bounds;
    styles?: LayerStyles;
  }

  export interface GlobalSettingsLayout {
    defaultLayoutId?: number;
    defaultLayoutType?: LayoutType;
  }

  export interface GlobalSettingsAsset {
    uploaded: Asset[];
    settings?: {
      assetId?: string;
      enabled: boolean;
      visible: boolean;
    };
  }

  export interface GlobalSettingsTextOverlays {
    assetId: string;
  }

  export interface GlobalSettings {
    layout?: GlobalSettingsLayout;
    textOverlays?: GlobalSettingsTextOverlays;
    logo: GlobalSettingsAsset;
    watermark: GlobalSettingsAsset;
    backgroundVideo: GlobalSettingsAsset;
    backgroundColor: SolidColor | GradientColor;
    primaryColor?: SolidColor | GradientColor;
    fontIndex: number;
  }

  export type Sections = { [key: string]: Section };

  export interface WorkflowDataDto {
    fonts?: FontAsset[];
    assets?: Asset[];
    sections: Sections;
    features: Features;
    timelines: Timeline[];
    styles: Style[];
    globalSettings: GlobalSettings;
  }
}
