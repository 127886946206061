import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, flatMap } from 'rxjs/operators';
import { GlobalService } from '../services/global/global.service';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AddJwtTokenInterceptor<T> implements HttpInterceptor {
  constructor(private authService: AuthService, private global: GlobalService) {}

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (
      request.headers.has('authorization') ||
      request.url.includes('upload-test-file') ||
      request.url.includes('amazonaws.com')
    ) {
      return next.handle(request);
    }
    return this.authService.jwt$.pipe(
      take(1),
      flatMap((jwt) =>
        next.handle(
          jwt
            ? request.clone({
                setHeaders: { authorization: `Bearer ${jwt}` },
              })
            : request
        )
      )
    );
  }
}
