import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanySoundtrack } from '@openreel/creator/common';
import { commonenv } from 'libs/frontend/common/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanySoundtracksService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}company-soundtracks`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<CompanySoundtrack[]>(this.urls.base);
  }
}
