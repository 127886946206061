import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AvatarModule } from 'ngx-avatar';

@Component({
  selector: 'openreel-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  standalone: true,
  imports: [AvatarModule, MatMenuModule, MatIconModule, MatButtonModule],
})
export class ProfileMenuComponent {
  @Input()
  name: string;

  @Input()
  accountName: string;

  @Output()
  logoutButtonClicked = new EventEmitter<void>();

  onLogoutButtonClicked() {
    this.logoutButtonClicked.emit();
  }
}
