import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalActions } from '@openreel/common';

@Pipe({ name: 'approvalAction', standalone: true })
export class ApprovalActionPipe implements PipeTransform {
  transform(action: ApprovalActions) {
    switch(action) {
        case ApprovalActions.APPROVE: return 'Approved';
        case ApprovalActions.FINAL_APPROVE: return 'Approved and Published to Library';
        case ApprovalActions.CANCEL: return 'Canceled Approval request';
        case ApprovalActions.SUBMIT: return 'Submitted for Approval';
        case ApprovalActions.REJECT: return 'Rejected';
        default: return '';
    }
  }
}