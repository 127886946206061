import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewVideoRequest, NewVideoResponse } from '../../interfaces';

import { commonenv } from '../../environments/environment';
import { SanitizeFilenameService } from '../director/sanitize-filename.service';

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  urls = {
    videoCreate: `${commonenv.nextGenApiUrl}videos/self-record/`,
  };
  constructor(private httpClient: HttpClient, private sanitizeFilenameService: SanitizeFilenameService) {}

  createNewVideo(request: NewVideoRequest) {
    /**
     * Patch video names
     */
    request.videos = request.videos.map((video) => {
      video.video_name = this.sanitizeFilenameService.sanitize(video.video_name);
      return video;
    });

    return this.httpClient.post<NewVideoResponse>(this.urls.videoCreate, request);
  }
}
