import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { ApprovalHistoryStore } from './approval-history.store';
import { ApprovableTypes } from '@openreel/common';
import { Subject, takeUntil } from 'rxjs';

interface ApprovableData {
    approvableId: number;
    approvableType: ApprovableTypes;
}

@Directive({ 
    selector: '[orWhenApprovalHistoryAvailable]', 
    standalone: true,
    providers: [ApprovalHistoryStore]
})
export class ApprovalHistoryAvailableDirective implements OnInit, OnDestroy {
  private templateRef = inject(TemplateRef<any>);
  private viewContainerRef = inject(ViewContainerRef);

  private approvalHistoryStore = inject(ApprovalHistoryStore);

  private context = {
    openApprovalHistoryDialog: () => {
        this.approvalHistoryStore.openApprovalHistoryDialog();
    },
  }

  @Input('orWhenApprovalHistoryAvailable') set approvableData(data: ApprovableData) {
    if (data != this._data) {
      this._data = data;
      this.viewContainerRef.clear();
      if(!!data?.approvableId && !!data?.approvableType) {
        this.approvalHistoryStore.fetchApprovalHistory(data.approvableId, data.approvableType);
      }
    }
  }
  private _data: ApprovableData = undefined;

  private destroySub = new Subject<void>();

  ngOnInit(): void {
    this.approvalHistoryStore.isAvailableApprovalHistory$
        .pipe(takeUntil(this.destroySub))
        .subscribe(isAvailable => {
            this.viewContainerRef.clear();
            if(isAvailable) {
                this.viewContainerRef.createEmbeddedView(this.templateRef, this.context)
            }
        })
  }

  ngOnDestroy(): void {
      this.destroySub.next();
      this.destroySub.complete();
  }
}
