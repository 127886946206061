import {
  TextEffectEcho,
  TextEffectGlitch,
  TextEffectGlow,
  TextEffectHollow,
  TextEffectOutline,
  TextEffectSplice,
  TextEffectShadow,
  LottieProcessedFieldData,
  TextBoxStyles,
} from '../interfaces';

export const DEFAULT_TEXT_ALIGN = 'center';
export const DEFAULT_TEXT_COLOR = '#000000';
export const DEFAULT_TEXT_LINE_HEIGHT = 1;
export const DEFAULT_TEXT_LETTER_SPACING = 0;

export const DEFAULT_TEXT_EFFECT_ECHO: TextEffectEcho = {
  type: 'echo',
  offset: 10,
  angle: 135,
  opacity: 50,
  color: '#F70000',
};

export const DEFAULT_TEXT_EFFECT_OUTLINE: TextEffectOutline = {
  type: 'outline',
  thickness: 2,
  color: '#F70000',
};

export const DEFAULT_TEXT_EFFECT_HOLLOW: TextEffectHollow = {
  type: 'hollow',
  thickness: 2,
};

export const DEFAULT_TEXT_EFFECT_SPLICE: TextEffectSplice = {
  type: 'splice',
  thickness: 2,
  color: '#F70000',
  offset: 10,
  angle: 135,
};

export const DEFAULT_TEXT_EFFECT_GLITCH: TextEffectGlitch = {
  type: 'glitch',
  rightColor: '#00FFFF',
  leftColor: '#FA00FA',
  offset: 3,
  angle: 0,
};

export const DEFAULT_TEXT_EFFECT_GLOW: TextEffectGlow = {
  type: 'glow',
  blur: 50,
};

export const DEFAULT_TEXT_EFFECT_SHADOW: TextEffectShadow = {
  type: 'shadow',
  blur: 15,
  color: '#F70000',
  offset: 10,
  opacity: 50,
  angle: 135,
};

export function getTextStyleFromFieldData(fieldData: LottieProcessedFieldData): TextBoxStyles {
  const [fontFamily, fontWeight, fontStyle] = fieldData.font.split('_');

  return {
    color: fieldData.color,
    fontSizeRelative: fieldData.fontSize,
    fontFamily,
    fontWeight: parseInt(fontWeight, 10) === 700 ? 700 : 400,
    fontStyle: fontStyle === 'italic' ? 'italic' : 'normal',
    textAlign: fieldData.textAlign ?? DEFAULT_TEXT_ALIGN,
    lineHeight: fieldData.lineHeight ?? DEFAULT_TEXT_LINE_HEIGHT,
    letterSpacingRelative: fieldData.letterSpacing ?? DEFAULT_TEXT_LETTER_SPACING,
  };
}
