import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { VideoSource } from '@openreel/frontend/common';

export enum RecorderState {
  IDLE = 'Not Recording',
  PRERECORDING = 'Initializing',
  RECORDING = 'Recording',
  STOPPING_RECORDING = 'Stopping',
}
export interface Recording {
  isRecording: boolean;
  mediaRecorder: MediaRecorder;
  recorderState: RecorderState;
  videoName: string;
  videoId: number;
  countdown: number;
  startRecordingTime: Date;
  resolution: string;
  fps: string;
  videoSource: VideoSource;
  uploadDuringRecording: string[];
  uploadAuthToken: string;
  startSpotPresentationRecording?: boolean;
}
export interface RecordingState extends EntityState<Recording> {
  selectedUserId: string | null;
}

export const recordingAdapter: EntityAdapter<Recording> = createEntityAdapter<Recording>({
  selectId: selectUserId,
});

export function selectUserId(a: Recording): number {
  return a.videoId;
}
export const initialState: RecordingState = recordingAdapter.getInitialState({
  selectedUserId: null,
  isRecording: null,
  mediaRecorder: null,
  recorderState: RecorderState.IDLE,
  videoName: null,
  videoId: null,
  videoSource: null,
  countdown: null,
  startRecordingTime: null,
  resolution: null,
  fps: null,
  uploadDuringRecording: [],
  uploadAuthToken: null
});
