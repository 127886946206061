import { LottieProcessedFieldsData, Preset, TextBoxProperties } from '@openreel/creator/common';
import { cloneDeep } from 'lodash';
import { Animation } from './interfaces/lottie.interface';
import { TransformerBase } from './transformers/transformer.base';
import { TransformerFill } from './transformers/transformer.fill';
import { TransformerImage } from './transformers/transformer.image';
import { TransformerShape } from './transformers/transformer.shape';
import { TransformerText } from './transformers/transformer.text';
import { TransformerTextBox } from './transformers/transformer.text-box';
import { TransformerTextEffect } from './transformers/transformer.text-effect';
import { TransformerDuration } from './transformers/transformer.duration';

export interface LottieTransformerRenderOptions {
  width: number;
  height: number;
  duration?: number;
}

export class LottieTransformer {
  private animation: Animation;
  private renderOptions: LottieTransformerRenderOptions;

  private transformers: TransformerBase[] = [
    new TransformerText(),
    new TransformerTextBox(),
    new TransformerTextEffect(), // needs to be after TransformerTextBox because it needs to have the size of the animation which TransformerTextBox sets
    new TransformerFill(),
    new TransformerShape(),
    new TransformerImage(),
    new TransformerDuration(),
  ];

  constructor(animation: Animation, renderOptions: LottieTransformerRenderOptions) {
    this.animation = cloneDeep(animation);
    this.renderOptions = renderOptions;
  }

  transform(data: LottieProcessedFieldsData, preset: Preset, textBoxProperties?: TextBoxProperties) {
    for (const [key, field] of Object.entries(preset)) {
      let found = false;
      for (const transformer of this.transformers) {
        if (transformer.test(field, textBoxProperties)) {
          this.animation = transformer.process({
            animation: this.animation,
            renderOptions: this.renderOptions,
            fieldDef: field,
            fieldValues: data[key],
            textBoxProperties,
          });
          found = true;
        }
      }

      if (!found) {
        throw `Are you kidding me? No valid transformer for field type ${field.type}`;
      }
    }

    return this.animation;
  }
}
