import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  commonenv,
  CreateEditPermissionedGroupDto,
  PermissionedGroup,
  PermissionedGroupList,
  PermissionedGroupWithUsers,
} from '@openreel/frontend/common';
import { Observable } from 'rxjs';

interface PermissionedGroupListParams {
  query: string;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionedGroupsService {

  private readonly urls = {
    permissionedGroups: commonenv.nextGenApiUrl + 'permissioned-groups',
    systemPermissionedGroup: commonenv.nextGenApiUrl + 'permissioned-groups/system',
  };

  private readonly http = inject(HttpClient);

  getSystemPermissionedGroup(): Observable<PermissionedGroup> {
    return this.http.get<PermissionedGroup>(this.urls.systemPermissionedGroup);
  }

  fetchPermissionedGroups(params: PermissionedGroupListParams = { query: '' }): Observable<PermissionedGroupList> {
    const queryParams = new HttpParams().append('query', params.query);

    return this.http.get<PermissionedGroupList>(this.urls.permissionedGroups, { params: queryParams });
  }

  getPermissionedGroup(id: PermissionedGroup['id']): Observable<PermissionedGroupWithUsers> {
    return this.http.get<PermissionedGroupWithUsers>(`${this.urls.permissionedGroups}/${id}`);
  }

  createPermissionedGroup(dto: CreateEditPermissionedGroupDto): Observable<PermissionedGroup> {
    return this.http.post<PermissionedGroup>(this.urls.permissionedGroups, dto);
  }

  updatePermissionedGroup(groupId: PermissionedGroup['id'], dto: CreateEditPermissionedGroupDto): Observable<PermissionedGroup> {
    return this.http.put<PermissionedGroup>(`${this.urls.permissionedGroups}/${groupId}`, dto);
  }

  deletePermissionedGroup(groupId: PermissionedGroup['id']): Observable<PermissionedGroup> {
    return this.http.delete<PermissionedGroup>(`${this.urls.permissionedGroups}/${groupId}`);
  }
}
