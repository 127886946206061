import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
  constructor(private overlayContainer: OverlayContainer) {}

  setTheme(theme: string) {
    const themeClasses = ['light-theme', 'dark-theme'];
    const overlayContainerElement = this.overlayContainer.getContainerElement();

    themeClasses.forEach((themeClass) => {
      overlayContainerElement.classList.remove(themeClass);
    });

    overlayContainerElement.classList.add(theme);
  }
}
