import { NgModule } from '@angular/core';
import { NavigationSearchComponent } from './navigation-search.component';
import { SearchService } from './search.service';
import { OpenreelCommonModule } from '@openreel/frontend/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [OpenreelCommonModule, ReactiveFormsModule],

  declarations: [NavigationSearchComponent],
  exports: [NavigationSearchComponent],
  providers: [SearchService],
})
export class NavigationSearchModule {}
