import { createAction, props } from '@ngrx/store';
import { DirectorUserDetails, UserRoleType } from '@openreel/frontend/common';

const context = '[Auth]';
export const setToken = createAction(`${context} Set Token`, props<{ token: string }>());
export const setRole = createAction(`${context} Set Role`, props<{ role: UserRoleType }>());

export const fetchDirectorUserDetails = createAction(`${context} Fetch User Details`);
export const setDirectorUserDetails = createAction(
  `${context} Set User Details`,
  props<{ director: DirectorUserDetails }>()
);
