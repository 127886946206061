import { commonenv } from '@openreel/frontend/common/env/environment.prod';

export const environment = {
  ...commonenv,
  environmentName: 'production',
  production: true,
  userPilotToken: '95cd14q4',
  nonSubjectsMixpanelToken: 'c46b5f04be2cd98f2a76cdeb2b49dd69',
  subjectsMixpanelToken: '2992095f40c85475208582cd9adc39af',
  facebookAppId: '1796714797388277',
  googleClientId: '80778371151-qnalapvq6m5nm4lvd6saifabf36c8ea4.apps.googleusercontent.com',
  googleApiKey: 'AIzaSyANvsp8Kmh4__e_JPYWZMpVJ-DpKDvPxlY',
  salesforceChatId: ['573EY000000001m', '572EY000000001N', '00D1I000001YfOv'],
  stripeApiKey: 'pk_live_2nTNAJVnkPFVsIqCaiznycHz',

  graphql: 'https://capture.bcp.openreel.cloud/graphql',
  graphqlWs: 'wss://capture.bcp.openreel.cloud/graphql',
};
