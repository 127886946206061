import { ApprovalStatuses } from '@openreel/common';

export const WORKFLOW_VIDEO_APPROVABLE_TYPE = 'workflow_video';

export interface WorkflowApprovalDto {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  approvalStatus: ApprovalStatuses;
}
