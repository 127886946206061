import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DirectorUserDetails,
  NewVideoRequest,
  NewVideoResponse,
  CameraPresetCreate,
  CameraPresetCreateResponse,
  VersionControlRequest,
  VersionControlResponse,
  VideoType,
  GetDeviceCameraDetails,
  ArchiveVideoResponse,
} from '../../../interfaces';
import {
  UpdateDeviceInfoRequest,
  GetAllDeviceDetailsRequest,
  DeleteVideoResponse,
} from '../../../interfaces/interfaces';
import { SignedUrl } from '@openreel/common';

import { commonenv } from '../../../environments/environment';
import { SanitizeFilenameService } from '../sanitize-filename.service';
import { firstValueFrom } from 'rxjs';

const URL_DIRECTOR_USER_DETAILS = 'fetch-user-details';
const URL_UPDATE_CONNECTED_DEVICE = 'update-connected-device';
const URL_GET_ALL_DEVICE_DETAILS = 'get-all-device-details';
const URL_REMOVE_CAMERA_PRESET = 'remove-camera-preset';
const URL_ADD_CAMERA_PRESET = 'load-camera-preset';
const URL_VERSION_CONTROL = 'version-control';
const LOGOUT_EXTERNAL = `${commonenv.nextGenApiUrl}logout`;

@Injectable({
  providedIn: 'root',
})
export class DirectorApiService {
  urls = {
    videoCreate: `${commonenv.nextGenApiUrl}videos/`,
    shortlist: (id) => `${commonenv.nextGenApiUrl}videos/${id}/shortlist`,
    video_delete: (id) => `${commonenv.nextGenApiUrl}videos/${id}`,
    video_archive: (id) => `${commonenv.nextGenApiUrl}videos/${id}/archive`,
    video_restore: (id) => `${commonenv.nextGenApiUrl}videos/${id}/restore`,
    uploadAllowed: `${commonenv.nextGenApiUrl}videos/is-upload-allowed`,
  };
  constructor(private httpClient: HttpClient, private sanitizeFilenameService: SanitizeFilenameService) {}

  updateConnectedDevice(request: UpdateDeviceInfoRequest) {
    return this.httpClient.patch<{ data: { device_child_id: number } }>(`${commonenv.nextGenApiUrl}device`, request);
  }

  getDeviceCameraDetail(request: GetAllDeviceDetailsRequest) {
    return this.httpClient.get<GetDeviceCameraDetails>(
      `${commonenv.nextGenApiUrl}device/${request.identity}/camera-details?session_id=${request.session_id}`
    );
  }

  createNewVideo(request: NewVideoRequest) {
    /**
     * Patch video names
     */
    request.videos = request.videos.map((video) => {
      video.video_name = this.sanitizeFilenameService.sanitize(video.video_name);
      return video;
    });

    return this.httpClient.post<NewVideoResponse>(this.urls.videoCreate, request);
  }

  deleteVideo(videoId: number) {
    return this.httpClient.delete<DeleteVideoResponse>(this.urls.video_delete(videoId)).toPromise();
  }

  archiveVideo(videoId: number) {
    return this.httpClient
      .put<ArchiveVideoResponse>(this.urls.video_archive(videoId), {
        videoId: videoId,
      })
      .toPromise();
  }

  restoreVideo(videoId: number) {
    return this.httpClient
      .put<ArchiveVideoResponse>(this.urls.video_restore(videoId), {
        videoId: videoId,
      })
      .toPromise();
  }

  transcodeVideo(videoId: number, type: string) {
    return this.httpClient
      .post<DeleteVideoResponse>(`${commonenv.nextGenApiUrl}videos/${videoId}/transcode`, { transcode_type: type })
      .toPromise();
  }

  transcriptVideo(videoId: number) {
    return this.httpClient
      .post<DeleteVideoResponse>(`${commonenv.nextGenApiUrl}videos/${videoId}/transcript`, {})
      .toPromise();
  }

  captureVideoExtractAudio(videoId: number) {
    return this.httpClient
      .post<DeleteVideoResponse>(`${commonenv.nextGenApiUrl}videos/${videoId}/extract-audio`, {})
      .toPromise();
  }

  getUserDetails() {
    return this.httpClient.get<DirectorUserDetails>(`${commonenv.nextGenApiUrl}user`, {});
  }

  removeCameraPreset(presetId: number, sessionId: number) {
    return this.httpClient
      .post<void>(URL_REMOVE_CAMERA_PRESET, {
        ovra_cameraSetting_presets_id: presetId,
        session_id: sessionId,
      })
      .toPromise();
  }

  addCameraPreset(preset: CameraPresetCreate) {
    return this.httpClient.post<CameraPresetCreateResponse>(URL_ADD_CAMERA_PRESET, preset);
  }

  async getVideoDownloadUrl(videoId: number, type = VideoType.ORIGINAL, responseType = 'download'): Promise<string> {
    return (await this.getVideoSignedUrlWithExpiry(videoId, type, responseType))?.url;
  }

  async getAudioDownloadUrl(videoId: number) {
    return (
      await firstValueFrom(
        this.httpClient.get<{ data: { url: string; audioFileName: string } }>(
          `${commonenv.nextGenApiUrl}videos/${videoId}/download-audio-url`
        )
      )
    )?.data;
  }

  async getVideoSignedUrlWithExpiry(
    videoId: number,
    type = VideoType.ORIGINAL,
    responseType = 'download'
  ): Promise<SignedUrl> {
    return this.httpClient
      .get<SignedUrl>(
        `${commonenv.nextGenApiUrl}videos/${videoId}/download-url?video_type=${type}&response_type=${responseType}`
      )
      .toPromise();
  }

  async getPublicVideoId(videoId: number): Promise<string> {
    return (
      await this.httpClient
        .get<{ data: { public_id: string } }>(`${commonenv.nextGenApiUrl}videos/${videoId}/public-id`)
        .toPromise()
    ).data.public_id;
  }

  async getAppVersionControl(params: VersionControlRequest): Promise<VersionControlResponse> {
    return this.httpClient
      .get<VersionControlResponse>(
        `${commonenv.nextGenApiUrl}${URL_VERSION_CONTROL}?app_name=${params.app_name}&version=${params.version}&device=${params.device}`
      )
      .toPromise();
  }

  shortlist(recordingId: number) {
    return this.httpClient.patch(this.urls.shortlist(recordingId), {}).toPromise();
  }

  logoutExternal() {
    return this.httpClient.get<{ data: { invited_token: string; name: string } }>(LOGOUT_EXTERNAL);
  }

  isUploadAllowed() {
    return this.httpClient.get<{ status: number }>(this.urls.uploadAllowed);
  }
}
