import { createSelector } from '@ngrx/store';
import { RootState } from '../../root-state';
import { presentationEntityAdapter as adapter } from './interfaces/presentation.interface';
import { PresentationStatus } from '@openreel/common';
import {
  selectOnlineParticipants,
  selectOnlineParticipantsExceptMe,
  selectOnlineParticipantsIncludingMe,
} from '../participants/participant.selectors';

const selectMyIdentity = (state: RootState) => state?.session?.participants?.myParticipant?.identity;
const selectFeature = (state: RootState) => state.session.presentation;

export const selectPresentationState = selectFeature;
export const { selectAll: selectPresentationSlides } = adapter.getSelectors(selectFeature);
export const selectCurrentSlide = createSelector(selectFeature, (s) => s.currentSlide);
export const selectSlide = (id: number) => createSelector(selectFeature, (s) => s.entities[id]);
export const selectIsPresentationProcessing = createSelector(
  selectFeature,
  (s) => s.status === PresentationStatus.Processing
);
export const selectPresentationStatus = createSelector(selectFeature, (s) => s.status);
export const selectShowControls = createSelector(selectFeature, (s) => s.ids.length > 0);
export const selectPresentationId = createSelector(selectFeature, (s) => s.presentationId);
export const selectIsPresenter = createSelector(
  selectFeature,
  selectMyIdentity,
  (s, myIdentity) => s.identity === myIdentity
);
export const selectPresenterName = createSelector(selectFeature, (s) => s.presenterName);
export const selectPresenterIdentity = createSelector(selectFeature, (s) => s.identity);

export const selectPresenterFileName = createSelector(selectFeature, (s) => s.fileName);

export const selectPresenterOnline = createSelector(
  selectFeature,
  selectOnlineParticipantsIncludingMe,
  (presentation, participants) => participants.some((p) => p.identity === presentation.identity)
);

export const selectEnabledDevicesIdentity = createSelector(
  selectFeature,
  (presentation) => presentation.enabledDevices
);

export const selectEnabledDevicesIdentityExceptMine = createSelector(
  selectFeature,
  selectMyIdentity,
  (presentation, myId) => presentation.enabledDevices.filter(x => x !== myId)
);

export const selectPresentationParticipants = createSelector(
  selectOnlineParticipants,
  selectEnabledDevicesIdentity,
  (participants, enableDevicesIdentity) =>
    participants.map((p) => ({
      identity: p.identity,
      deviceName: p.deviceName,
      isIosDevice: p.isIosDevice,
      name: p.name,
      role: p.role,
      presentationProperties: {
        status: p.presentationProperties?.status || PresentationStatus.NotPresenting,
        enabled: enableDevicesIdentity.indexOf(p.identity) > -1,
      },
    }))
);

export const selectPresentationIdAndFilename = createSelector(
  selectPresentationId,
  selectPresenterFileName,
  (id, fileName) => ({ id, fileName })
);

export const presentationAnyDeviceEnabled = createSelector(
  selectEnabledDevicesIdentityExceptMine,
  selectOnlineParticipantsExceptMe,
  (enabledDevices, participants) => enabledDevices.length > 0 && participants.length > 0
);

export const allDeviceEnabled = createSelector(
  selectOnlineParticipantsExceptMe,
  selectEnabledDevicesIdentity,
  (participants, enableDevicesIdentity) =>
    participants.every((p) => enableDevicesIdentity.indexOf(p.identity) > -1) && participants.length > 0
);

export const selectPresentationForTrackEvents = createSelector(
  selectFeature,
  selectMyIdentity,
  (presentation, myId) => ({
    presenterFileName: presentation.fileName,
    presenterName: presentation.presenterName,
    presenterIdentity: presentation.identity
  })
);