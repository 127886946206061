import {inject, Injectable, Injector} from '@angular/core';
import {GoogleIntegratedUser} from '../../../../interfaces/google-integration.interface';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {mergeMap, of, switchMap} from 'rxjs';
import { GoogleIntegrationService } from "@openreel/frontend/common/services/google-integrations/google-integration.service";
import {GoogleIntegratedApp} from "@openreel/common";
import {catchError, filter, tap, withLatestFrom} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {ShareVideoOnYoutubeDto, YoutubeChannel, YoutubeIntegrationService} from "@openreel/frontend/common";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { YoutubeSharingDialogComponent } from "./youtube-sharing-dialog.component";


interface State {
    integratedUsers: GoogleIntegratedUser[];
    channels: Record<number, YoutubeChannel[]>;
    sharingInProgress: boolean;
}

@Injectable()
export class YoutubeSharingStore extends ComponentStore<State> {
  private readonly googleIntegrationService = inject(GoogleIntegrationService);
  private readonly youtubeIntegrationService = inject(YoutubeIntegrationService);
  private readonly dialog = inject(MatDialog);
  private readonly injector = inject(Injector);
  private readonly toastrService = inject(ToastrService);

  constructor() {
    super({
      integratedUsers: [],
      channels: {},
      sharingInProgress: false,
    })
  }

  private readonly _setIntegratedUsers = this.updater((state, users: GoogleIntegratedUser[]) => ({
    ...state,
    integratedUsers: users
  }));

  private readonly _setIntegratedUserChannels = this.updater((state, [userId, channels]: [number, YoutubeChannel[]]) => ({
    ...state,
    channels: {
      ...state.channels,
      [userId]: channels
    }
  }))

  private readonly _setSharingInProgress = this.updater((state, inProgress: boolean) => ({
    ...state,
    sharingInProgress: inProgress
  }))

  integratedUsers$ = this.select(state => state.integratedUsers);
  hasIntegratedUsers$ = this.select(this.integratedUsers$, users => users.length > 0);
  channels$ = this.select(state => state.channels);
  sharingInProgress$ = this.select(state => state.sharingInProgress);

  selectIntegratedUserChannels = (userId: number) => this.select(state => state.channels[userId] ?? []);

  fetchIntegratedUsers = this.effect<void>($ => $.pipe(
    switchMap(() => this.googleIntegrationService.fetchIntegratedUsers(GoogleIntegratedApp.Youtube).pipe(
      catchError((error: Error) => {
        this.toastrService.error(error.message, 'Error!');
        return of([]);
      }),
      tap(users => this._setIntegratedUsers(users))
    ))
  ))

  fetchUserChannels = this.effect<number>(userId$ => userId$.pipe(
    withLatestFrom(this.channels$),
    filter(([userId, channels]) => !channels[userId]?.length),
    mergeMap(([userId]) => this.youtubeIntegrationService.fetchYoutubeChannels(userId).pipe(
      catchError((error: Error) => {
        this.toastrService.error(error.message, 'Error!');
        return of([]);
      }),
      tap(channels => this._setIntegratedUserChannels([userId, channels]))
    ))
  ))

  shareVideo = this.effect<ShareVideoOnYoutubeDto>(dto$ => dto$.pipe(
    tap(v => this._setSharingInProgress(true)),
    mergeMap(dto => this.youtubeIntegrationService.shareVideo(dto).pipe(
      tapResponse(
        result => {
          this.toastrService.success('Video uploading process has been started. The video will be available on Youtube shortly.');
          this._setSharingInProgress(false);
        },
        (err: Error) => {
          this.toastrService.error(err.message, 'Error!');
          this._setSharingInProgress(false);
        }
      )
    ))
  ))

  openSharingDialog = this.effect<{ videoId: number; videoDuration: number }>(params$ => params$.pipe(
    mergeMap(params => this.dialog.open(YoutubeSharingDialogComponent, {
      width: '560px',
      data: params,
      injector: this.injector,
    }).afterClosed()
      .pipe(
        filter(dto => !!dto),
        tap(dto => this.shareVideo(dto))
      ))
  ))
}
