import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'openreel-radio-group',
  templateUrl: './openreel-radio-group.component.html',
  styleUrls: ['./openreel-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class OpenreelRadioGroupComponent<T> implements ControlValueAccessor {

  value: T | null = null;
  disabled = false;

  onChange: (value: T) => void = null;
  onTouched: () => void = null;

  private readonly ngControl = inject(NgControl, { optional: true, self: true });

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: T | null): void {
    this.value = value;
  }

  setValue(value: T | null): void {
    this.value = value;

    this.onTouched();
    this.onChange(value);
  }
}
