// import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';

let disableLogging = false;

if (environment.production) {
  // enableProdMode();
  disableLogging = true;
}

if (environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.environmentName,
    integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration()],
    tracePropagationTargets: [new RegExp('^' + environment.nextGenApiUrl)],
    tracesSampleRate: environment.sentryTracesSampleRate,
  });
}

if (disableLogging) {
  /**
   * Disable Logging for production environment or if it's set as false initially
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.log = window.console.warn = window.console.error = () => {};
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then()
  .catch((err) => console.error(err));
