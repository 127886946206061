<div fxLayout="column" fxLayoutAlign="center center" class="container" ngClass.lt-sm="mobile-container" ngClass.sm="mobile-container">
  <div class="header">
    <img src="assets/common/wordmarks/blue-gradient.svg" class="logo" />
  </div>
  <div class="content">
    <p>You are trying to use a non-supported browser</p>
  </div>
  <div class="actions">
    <a
      mat-raised-button
      color="primary"
      target="_blank"
      href="https://openreel.my.site.com/helpcenter/s/article/Supported-Devices-Recommended-Specs-for-Self-Recording"
      rel="noopener noreferrer"
    >
      Supported Browser List
    </a>
  </div>
  <div class="app-store-text">Download the subject mobile application</div>

  <div class="badges">
    <a href="https://apps.apple.com/us/app/id1542979247" rel="noopener noreferrer" class="app-badge" target="_blank"
      ><img src="assets/common/badges/app-store.svg" alt="Download on the App Store"
    /></a>

    <a
      href="https://play.google.com/store/apps/details?id=com.openreel.capture2"
      rel="noopener noreferrer"
      target="_blank"
      class="app-badge"
      ><img height="83" alt="Get it on Google Play" src="assets/common/badges/play-store.png"
    /></a>
  </div>
</div>
