<div class="avatar" [matMenuTriggerFor]="profileMenu">
  <ngx-avatar [name]="name" bgColor="#3546ff" size="48"></ngx-avatar>
</div>

<mat-menu #profileMenu="matMenu" yPosition="above" overlapTrigger>
  <div class="profile-menu">
    <ngx-avatar [name]="name" bgColor="#3546ff" size="48"></ngx-avatar>

    <div class="profile-details">
      <div class="username">{{ name }}</div>
      <div class="account-name">{{ accountName }}</div>
    </div>

    <div class="logout">
      <button mat-icon-button class="logout-button" (click)="onLogoutButtonClicked()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>
</mat-menu>
