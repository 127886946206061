import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { PermissionableEntity } from '@openreel/common';
import { Cleanupable } from '@openreel/frontend/common';
import { debounceTime, startWith, take, tap } from 'rxjs/operators';

import { fadeIn, heightCollapse } from '../../../../animations';
import { HostablePermissionTypes } from '../../../constants';
import { DEFAULT_ENTITY_ROLE } from '../default-entity-role';
import { HOSTING_SHARE_FORM, HostingShareForm } from '../hosting-share-form.service';
import { HostingShareComponentStore } from '../hosting-share.store';
import { ShareModalData } from '../share-modal-data.interface';
import { AccessSetting } from './access-setting.interface';

const ACCESS_TYPE_OPTIONS: AccessSetting[] = [
  {
    type: HostablePermissionTypes.Private,
    description: 'Restricted - Only people with access can view',
  },
  {
    type: HostablePermissionTypes.Public,
    description: 'Public - Anyone with the link can view',
  },
];

@Component({
  selector: 'openreel-access-settings',
  templateUrl: './access-settings.component.html',
  styleUrls: ['./access-settings.component.scss'],
  animations: [fadeIn, heightCollapse],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessSettingsComponent extends Cleanupable implements OnInit {
  @Input() readonly data: ShareModalData;
  @Input() set enableAccessControl(value: boolean) {
    const accessTypeControl = this.form.get('accessType');
    if(value) {
      accessTypeControl.enable()
    } else {
      accessTypeControl.disable()
    }
  };

  readonly ACCESS_TYPE_OPTIONS = ACCESS_TYPE_OPTIONS;
  readonly usersSearchControl = new FormControl('');

  readonly entities$ = this.hostingShareComponentStore.permissionableEntities$;

  get advancedModeControl(): FormControl<boolean> {
    return this.form.controls.advancedMode;
  }

  get accessType(): FormControl<HostablePermissionTypes> {
    return this.form.controls.accessType as FormControl<HostablePermissionTypes>;
  }

  get isPublic(): boolean {
    return this.accessType.value === HostablePermissionTypes.Public;
  }

  get isVideo() {
    return this.data.type === 'video';
  }

  constructor(
    @Inject(HOSTING_SHARE_FORM) readonly form: FormGroup<HostingShareForm>,
    private readonly hostingShareComponentStore: HostingShareComponentStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToUsersSearchChanges();
    this.setInitialAdvancedMode();
  }

  addPermissionedEntity(event: MatAutocompleteSelectedEvent): void {
    const { option } = event;

    option.deselect();
    this.usersSearchControl.setValue('');

    this.hostingShareComponentStore
      .getObjectRoleByName$(DEFAULT_ENTITY_ROLE)
      .pipe(
        take(1),
        tap((role) => {
          this.hostingShareComponentStore.addPermissionedEntityToObject({
            roleId: role.id,
            entityId: this.data.id,
            entity: this.isVideo ? PermissionableEntity.Hostable : PermissionableEntity.HostingHubs,
            permissionedEntityId: option.value.id,
            permissionedEntityType: option.value.entity,
          });
        })
      )
      .subscribe();
  }

  private subscribeToUsersSearchChanges(): void {
    this.mortalize(this.usersSearchControl.valueChanges)
      .pipe(startWith<string>(this.usersSearchControl.value as string), debounceTime(300))
      .subscribe((searchQuery) => {
        this.hostingShareComponentStore.getPermissionableEntities(searchQuery);
      });
  }

  private setInitialAdvancedMode(): void {
    const embedEnabledAt = this.form.controls.embedEnabledAt.value;
    const requirePassword = this.form.controls.requirePassword.value;

    this.advancedModeControl.setValue(!!(embedEnabledAt || requirePassword), { emitEvent: false });
  }
}
