import { ChangeTemplateResult, ChangeTemplateResultLabel } from './../builders/change-template.command';

export const OVERRIDE_MESSAGE = 'This will overwrite all copies of the current video. Do you want to continue?';

function processLabelRow(label: ChangeTemplateResultLabel) {
  return ` • ${label.label ? `${label.label} :` : ''} ${label.value}\n`
}

export function templateChangeAlert(templateChangeResults: ChangeTemplateResult) {
  if (
    templateChangeResults.notMigratedLabels.intro.length > 0
    || templateChangeResults.notMigratedLabels.outro.length > 0
    || templateChangeResults.notMigratedLabels.textOverlays.length > 0
  ) {
    let message = 'By changing the template you will lose some data.\nThe fields that are going to be lost are:\n';

    if (templateChangeResults.notMigratedLabels.intro.length > 0) {
      message += `\nIntro:\n`;
      templateChangeResults.notMigratedLabels.intro.forEach((label) => {
        message += processLabelRow(label);
      });
    }
    if (templateChangeResults.notMigratedLabels.outro.length > 0) {
      message += `\nOutro:\n`;
      templateChangeResults.notMigratedLabels.outro.forEach((label) => {
        message += processLabelRow(label);
      });
    }
    if (templateChangeResults.notMigratedLabels.textOverlays.length > 0) {
      templateChangeResults.notMigratedLabels.textOverlays.forEach((labels) => {
        message += `\nLower 3rd:\n`;
        labels.forEach((label) => {
          message += processLabelRow(label);
        });
      });
    }

    return message;
  }

  return '';
}

export const TEMPLATE_CHANGE_ALERT = {
  title: 'Data loss alert',
  confirmButtonText: 'I understand, continue',
  rejectButtonText: 'Cancel',
};

export const TEMPLATE_UPDATE_ALERT = {
  title: 'This template has been updated. Would you like to apply the updates to your video?',
  confirmButtonText: 'Yes',
  rejectButtonText: 'No',
};
