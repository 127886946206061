import { Pipe, PipeTransform } from '@angular/core';
import { Comment } from '../../hosting-interfaces';
import { TextUtils } from '../../../text.utils';

@Pipe({
  name: 'commentOwner',
  standalone: true,
})
export class CommentOwnerPipe implements PipeTransform {
  transform(value: Comment, modify?: 'abbreviate'): string {
    if (value.createdBy?.fullname) {
      let fullname =  value.createdBy.fullname;
      if(modify == 'abbreviate') fullname = TextUtils.abbreviateText(fullname);
      return fullname;
    }

    let anonymous = value.anonymousUserInfo?.fullname || 'Anonymous';

    if (modify == 'abbreviate') {
      return TextUtils.abbreviateText(anonymous);
    }

    if(value.anonymousUserInfo?.email) {
        anonymous += ` (${value.anonymousUserInfo.email})`
    }

    return anonymous;
  }
}