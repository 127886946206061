import { Injectable } from '@angular/core';
import { FeatureFlaggingService } from '@openreel/frontend/common';
import { WORKFLOWS_SKIA_MEASURER } from '@openreel/common';
import { SkottieCanvasKitService } from '@openreel/ui';
import { TextBoxCanvasKitMeasurer } from './canvaskit-measurer';
import { TextBoxDomMeasurer } from './dom-measurer';

@Injectable({ providedIn: 'root' })
export class TextBoxMeasuringService {
  constructor(
    private readonly skottieCanvasKitService: SkottieCanvasKitService,
    private readonly featureFlagService: FeatureFlaggingService
  ) {}

  load() {
    return this.skottieCanvasKitService.loadCanvasKit();
  }

  getMeasurer() {
    const isSkiaFlagEnabled = this.featureFlagService.getFlag(WORKFLOWS_SKIA_MEASURER);

    if (isSkiaFlagEnabled) {
      const CanvasKit = this.skottieCanvasKitService.CanvasKit;

      if (!CanvasKit) {
        throw new Error('CanvasKit is not loaded.');
      }

      return new TextBoxCanvasKitMeasurer(CanvasKit);
    }

    return new TextBoxDomMeasurer();
  }
}
