import { Point, SimpleBounds } from '../interfaces';

export function getRotatedPoint(point: Point, angleRad: number, origin?: Point): Point {
  if (!angleRad) {
    return {
      x: point.x,
      y: point.y,
    };
  }

  let delta: Point = origin ? { x: point.x - origin.x, y: point.y - origin.y } : point;
  delta = {
    x: Math.cos(angleRad) * delta.x - Math.sin(angleRad) * delta.y,
    y: Math.sin(angleRad) * delta.x + Math.cos(angleRad) * delta.y,
  };

  return origin ? { x: origin.x + delta.x, y: origin.y + delta.y } : delta;
}

export function getRotatedBounds(bounds: SimpleBounds, angleRad: number): SimpleBounds {
  if (!angleRad) {
    return { ...bounds };
  }

  return {
    x: Math.cos(angleRad) * bounds.x - Math.sin(angleRad) * bounds.y,
    y: Math.sin(angleRad) * bounds.x + Math.cos(angleRad) * bounds.y,
    width: Math.cos(angleRad) * bounds.width - Math.sin(angleRad) * bounds.height,
    height: Math.sin(angleRad) * bounds.width + Math.cos(angleRad) * bounds.height,
  };
}
