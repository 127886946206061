import { property } from 'lodash';
import { LottieProcessedFieldData, PresetField, TextBoxProperties } from '@openreel/creator/common';
import { Animation } from '../interfaces/lottie.interface';
import { LottieTransformerRenderOptions } from '../lottie-transformer';

export interface TransformerProcessOptions {
  animation: Animation;
  renderOptions: LottieTransformerRenderOptions;
  fieldDef: PresetField;
  fieldValues: LottieProcessedFieldData;
  textBoxProperties?: TextBoxProperties;
}

export abstract class TransformerBase {
  abstract test(fieldDef: PresetField, textBoxProperties?: TextBoxProperties): boolean;
  abstract process(options: TransformerProcessOptions): Animation;

  protected getRootNode<T>(animation: Animation, path: string) {
    const pathFn = property(path);
    return pathFn(animation) as T;
  }
}
