import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserUploadDialogComponent } from '../components/user-upload-dialog/user-upload-dialog.component';

@Injectable({providedIn: 'root'})
export class UserUploadDialogService {
    constructor(
        private dialog: MatDialog,
    ) {}

    openUserUpload() {
        return this.dialog.open(UserUploadDialogComponent, {
            width: '600px',
            height: '300px',
            disableClose: true,
            data: {}
        }).afterClosed();
    }
}
