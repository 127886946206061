import * as Handlebars from 'handlebars';

import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetId, AssetsFileProviderType } from '@openreel/creator/common';
import { Observable, of } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { AssetsBaseService } from './assets-base.service';

@Injectable({
  providedIn: 'root',
})
export class LottieRetrievalService {
  private httpClient: HttpClient;

  private loadedTemplates = new Map<string, HandlebarsTemplateDelegate>();
  private loadingTemplates = new Map<string, Observable<HandlebarsTemplateDelegate>>();

  constructor(private readonly assetsService: AssetsBaseService) {
    this.httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
  }

  apply<T>(id: AssetId, provider: AssetsFileProviderType, values: unknown): Observable<T> {
    return this.getCachedOrFetch(id, provider).pipe(
      map((template) => {
        const json = template(values);

        try {
          return JSON.parse(json) as T;
        } catch (error) {
          console.error('Can not parse lottie template', {
            id,
            provider,
            values,
            template: json,
          });
          throw error;
        }
      })
    );
  }

  getCachedOrFetch(id: AssetId, provider: AssetsFileProviderType): Observable<HandlebarsTemplateDelegate> {
    const key = this.getKey(id, provider);

    if (this.loadedTemplates.has(key)) {
      return of(this.loadedTemplates.get(key));
    }

    if (this.loadingTemplates.has(key)) {
      return this.loadingTemplates.get(key);
    }

    if (provider === 'or-local') {
      console.error("Lottie does not support 'or-local' provider type.");
      return;
    }

    const observable = this.assetsService.getAssetUrlById(provider, id).pipe(
      switchMap((url) =>
        this.httpClient.get(url, {
          responseType: 'text',
        })
      ),
      map((content) => Handlebars.compile(content)),
      tap((template) => {
        this.loadedTemplates.set(key, template);
        this.loadingTemplates.delete(key);
      }),
      share()
    );

    this.loadingTemplates.set(key, observable);

    return observable;
  }

  private getKey(id: AssetId, provider: AssetsFileProviderType) {
    if (provider === 'url') {
      return id as string;
    }

    return `${provider}-${id}`;
  }
}
