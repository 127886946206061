export interface BrandKitPermissionsCurrentUser {
  logo: boolean;
  watermark: boolean;
  background: boolean;
  soundtrack: boolean;
  colors: boolean;
  font: boolean;
}

export enum WorkflowRenderStatus {
  Rendering = 'rendering',
  Done = 'done',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export interface WorkflowProjectRenderSubProgress {
  progress: number;
  text: string;
}

export interface WorkflowProjectRenderTimings {
  description: string;
  timeElapsed: number;
}

export interface WorkflowProjectRenderProgress {
  state: WorkflowRenderStatus;
  progress: number;
  progressText: string;
  subProgress?: WorkflowProjectRenderSubProgress;
  outputTimings?: WorkflowProjectRenderTimings[];
  projectId: number;
  signedUrl?: string;
  captureProjectId?: number;
  renderedAt?: Date;
}

export type WorkflowProjectCreationSource = 'user' | 'ai_composer';
