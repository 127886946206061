import { clamp } from 'lodash';
import { TransformerBase, TransformerProcessOptions } from './transformer.base';

export class TransformerDuration extends TransformerBase {
  test() {
    return true;
  }

  process({ animation, renderOptions: { duration }, textBoxProperties }: TransformerProcessOptions) {
    if (textBoxProperties) {
      animation.op = 1;
      return animation;
    }

    if (!duration) {
      return animation;
    }

    const frames = Math.max(1, animation.fr * duration);
    animation.op = clamp(animation.op, animation.ip, animation.ip + frames);

    return animation;
  }
}
