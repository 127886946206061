import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoogleAuthConfig, GoogleIntegratedUser } from '@openreel/frontend/common/interfaces';
import { GoogleIntegratedApp } from '@openreel/common';
import { commonenv } from '../../environments/environment';
import { ConnectionAuthEntity } from '@openreel/common/automations';

@Injectable({
  providedIn: 'root'
})
export class GoogleIntegrationService {
  private readonly url = `${commonenv.nextGenApiUrl}google-auth`;

  constructor(private readonly http: HttpClient) {}

  fetchAuthConfig(app: GoogleIntegratedApp): Observable<GoogleAuthConfig> {
    return this.http.get<GoogleAuthConfig>(`${this.url}/config/${app}`);
  }

  authorize(code: string, userToken: string): Observable<GoogleIntegratedUser> {
    return this.http.post<GoogleIntegratedUser>(`${this.url}/authorize`, { code, userToken });
  }

  authorizeGmail(code: string, userToken: string): Observable<ConnectionAuthEntity> {
    return this.http.post<ConnectionAuthEntity>(`${this.url}/authorize-gmail`, { code, userToken });
  }

  fetchIntegratedUsers(app: GoogleIntegratedApp): Observable<GoogleIntegratedUser[]> {
    return this.http.get<GoogleIntegratedUser[]>(`${this.url}/${app}/users`);
  }

  deleteIntegratedUser(id: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/users/${id}`);
  }
}
