import { z } from 'zod';
import { AssetSchema } from './assets.zod';
import { FeaturesSchema } from './features.zod';
import { SectionsSchema } from './sections.zod';
import { TimelineSchema } from './common.zod';
import { StyleSchema } from './styles.zod';
import { GlobalSettingsSchema } from './global-settings.zod';
import { getAssetFromId, getLayers, removeTrimmedZoomAndPans } from '../helpers';
import { WorkflowDataDto } from '../interfaces';

// NOTE: for now, these both are the same
// We will need to start spliting them in the future to propery validate fields
// that are optional in template but required in project

export const ProjectWorkflowSchema = z
  .object({
    assets: z.array(AssetSchema),
    features: FeaturesSchema,
    sections: SectionsSchema,
    styles: z.array(StyleSchema),
    timelines: z.array(TimelineSchema),
    globalSettings: GlobalSettingsSchema,
  })
  .superRefine((value, ctx) => {
    const wfData = value as WorkflowDataDto;

    for (const { layer } of getLayers(wfData, {
      types: ['lottie'],
    })) {
      if (layer.type === 'lottie') {
        const asset = getAssetFromId(layer.assetId, wfData.assets);
        if (!asset?.file) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `${layer.type} layer with ID ${layer.layerId} does not map to a valid asset file.`,
          });
        }
      }
    }

    for (const { layer } of getLayers(wfData, {
      types: ['video'],
    })) {
      if (layer.type === 'video') {
        const asset = getAssetFromId(layer.assetId, wfData.assets);

        if (asset?.zoomPans?.length) {
          const oldLength = asset.zoomPans.length;
          removeTrimmedZoomAndPans(asset);

          if (oldLength !== asset.zoomPans.length) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Zoom and pan data invalid for video layer with ID ${layer.layerId}. Some zooms are hidden because of the text cuts.`,
            });
          }
        }
      }
    }
  });

export const TemplateWorkflowSchema = z.object({
  assets: z.array(AssetSchema),
  features: FeaturesSchema,
  sections: SectionsSchema,
  styles: z.array(StyleSchema),
  timelines: z.array(TimelineSchema),
  globalSettings: GlobalSettingsSchema,
});
