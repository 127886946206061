import { CommentAccessibility } from "../constants";

export enum HostingApplicationName {
  Internal = 'internal_ui',
  Public = 'public_ui',
}

export enum HostingAnalyticsEventName {
  // Internal events
  MenuItemClicked = 'menu_item_clicked',
  HubNameUpdated = 'hub_name_updated',
  BannerDismissed = 'banner_dismissed',
  BannerUpgradeClicked = 'banner_upgrade_clicked',
  VideoThumbnailPlay = 'video_thumbnail_play',
  VideoThumbnailViewDetails = 'video_thumbnail_view_details',
  VideoThumbnailTitleClicked = 'video_thumbnail_title_clicked',
  VideoThumbnailEllipsisOption = 'video_thumbnail_ellipsis_option',
  VideoListSort = 'video_list_sort',
  CreateHubButtonClicked = 'create_hub_button_clicked',
  CreateHubSubmitted = 'create_hub_submitted',
  CreateHubCanceled = 'create_hub_cancelled',
  VideoDetailsPlayerInteraction = 'video_details_player_interaction',
  OptionsButtonClicked = 'options_button_clicked',
  OptionsDrawerClosed = 'options_drawer_closed',
  OptionsVideoDetailsOpened = 'options_video_details_opened',
  OptionsVideoDetailsSaved = 'options_video_details_saved',
  OptionsChapterOpened = 'options_chapters_opened',
  OptionsChaptersSaved = 'options_chapters_saved',
  OptionsCaptionsOpened = 'options_captions_opened',
  OptionsCaptionsSaved = 'options_captions_saved',
  OptionsThumbnailOpened = 'options_thumbnail_opened',
  OptionsThumbnailUploaded = 'options_thumbnail_uploaded',
  OptionsThumbnailSaved = 'options_thumbnail_saved',
  OptionsCtaOpened = 'options_cta_opened',
  OptionsCtaSaved = 'options_cta_saved',
  VideoCommentStarted = 'video_comment_started',
  VideoCommentAdded = 'video_comment_added',
  VideoCommentEdited = 'video_comment_edited',
  VideoCommentDeleted = 'video_comment_deleted',
  Idle = 'idle',
  PageVisited = 'page_visited',

  // Public events
  SwitchToVideo = 'switch_to_video',
  VideoPlay = 'video_play',
  VideoPause = 'video_pause',
  VideoSeek = 'video_seek',
  VideoEnded = 'video_ended',
  VideoCompleted = 'video_completed',
  CtaButtonClicked = 'cta_button_clicked',
  ToggleFullscreen = 'toggle_full_screen',
  TogglePictureInPicture = 'toggle_picture_in_picture',
  ToggleCaptions = 'toggle_captions',
  ToggleChapters = 'toggle_chapters',
}

export type HostingAnalyticsEventProperties = Record<string, string | number | boolean | null | undefined> | void;

export type HostingAnalyticsEvent = {
    eventName: HostingAnalyticsEventName,
    description: string;
    properties?: HostingAnalyticsEventProperties;
}

export type BannerType = 'limit_reached' | 'embedded_videos_limit' | 'expiration_warning';
export type VideoPlayerInteraction = 'play' | 'pause' | 'seek' | 'chapters' | 'captions' | 'full_screen' | 'picture_in_picture';
export type HostingAnalyticsEventCreator<T> = (properties: T) => HostingAnalyticsEvent;

export type PublicVideoEventProperties = {
  id: string; //idHash
  account_id: string;
  title: string;
  action: string; 
  url: string; 
  position: number; 
  cta_button_url?: string | null;
};

function _generateEventCreator<T extends HostingAnalyticsEventProperties>(eventName: HostingAnalyticsEventName, description: string): HostingAnalyticsEventCreator<T> {
  return (properties: T): HostingAnalyticsEvent => {
    return { eventName, description, properties };
  };
}

export namespace HostingAnalyticsEvents {
  // Internal Events
  export const menuItemClicked = _generateEventCreator<{ hub_id: number; item_name: string; url: string }>(
    HostingAnalyticsEventName.MenuItemClicked,
    'Floating menu item clicked'
  );
  export const hubNameUpdated = _generateEventCreator<{ hub_id: number }>(
    HostingAnalyticsEventName.HubNameUpdated,
    'Hub name changed'
  );
  export const bannerDismissed = _generateEventCreator<{ banner_type: BannerType }>(
    HostingAnalyticsEventName.BannerDismissed,
    'Banner dismissed'
  );
  export const bannerUpgradeClicked = _generateEventCreator<{ banner_type: BannerType }>(
    HostingAnalyticsEventName.BannerUpgradeClicked,
    'Upgrade path from the banner'
  );
  export const videoThumbnailPlay = _generateEventCreator<{ id: number; position: number; title: string; url: string }>(
    HostingAnalyticsEventName.VideoThumbnailPlay,
    'Played video in thumbnail'
  );
  export const videoThumbnailViewDetails = _generateEventCreator<{ id: number; position: number; title: string; url: string }>(
    HostingAnalyticsEventName.VideoThumbnailViewDetails,
    'Opened video details from the thumbnail'
  );
  export const videoThumbnailTitleClicked = _generateEventCreator<{ id: number; position: number; title: string; url: string }>(
    HostingAnalyticsEventName.VideoThumbnailTitleClicked,
    'Opened video details from the video title'
  );
  export const videoThumbnailEllipsisOption = _generateEventCreator<{ id: number; position: number; title: string; url: string; option_value: string }>(
    HostingAnalyticsEventName.VideoThumbnailEllipsisOption,
    'Thumbnail ellipsis used'
  );
  export const videoListSort = _generateEventCreator<{ hub_id: number | null; url: string; old_value: string; new_value: string }>(
    HostingAnalyticsEventName.VideoListSort,
    'Changed list view sort order'
  );
  export const createHubButtonClicked = _generateEventCreator<void>(
    HostingAnalyticsEventName.CreateHubButtonClicked,
    'Started creating new hub'
  );
  export const createHubSubmitted = _generateEventCreator<{ hub_id: number }>(
    HostingAnalyticsEventName.CreateHubSubmitted,
    'New hub created'
  );
  export const createHubCanceled = _generateEventCreator<void>(
    HostingAnalyticsEventName.CreateHubCanceled,
    'New hub creation discarded'
  );
  export const videoDetailsPlayerInteraction = _generateEventCreator<{ hub_id: number | null; url: string; id: number; interaction: VideoPlayerInteraction }>(
    HostingAnalyticsEventName.VideoDetailsPlayerInteraction,
    'Interaction with the video player'
  );
  export const optionsButtonClicked = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsButtonClicked,
    'Options drawer opened'
  );
  export const optionsDrawerClosed = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsDrawerClosed,
    'Options drawer closed'
  );
  export const optionsVideoDetailsOpened = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsVideoDetailsOpened,
    'Video details configuration viewed'
  );
  export const optionsVideoDetailsSaved = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsVideoDetailsSaved,
    'Video details configuration saved'
  );
  export const optionsChaptersOpened = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsChapterOpened,
    'Video chapters configuration viewed'
  );
  export const optionsChaptersSaved = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsChaptersSaved,
    'Video chapters configuration saved'
  );
  export const optionsCaptionsOpened = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsCaptionsOpened,
    'Video captions configuration viewed'
  );
  export const optionsCaptionsSaved = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsCaptionsSaved,
    'Video captions configuration saved'
  );
  export const optionsThumbnailOpened = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsThumbnailOpened,
    'Video thumbnail configuration viewed'
  );
  export const optionsThumbnailSaved = _generateEventCreator<{ id: number; position: number; }>(
    HostingAnalyticsEventName.OptionsThumbnailSaved,
    'Video thumbnail updated with the video frame'
  );
  export const optionsThumbnailUploaded = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsThumbnailUploaded,
    'External thumbnail upload for the video'
  );
  export const optionsCtaOpened = _generateEventCreator<{ id: number; }>(
    HostingAnalyticsEventName.OptionsCtaOpened,
    'Video CTA configuration viewed'
  );
  export const optionsCtaSaved = _generateEventCreator<{ id: number; cta_url: string }>(
    HostingAnalyticsEventName.OptionsCtaSaved,
    'Video CTA configuration saved'
  );
  export const videoCommentStarted = _generateEventCreator<{ id: number; comment_type: CommentAccessibility; hub_id: number | null }>(
    HostingAnalyticsEventName.VideoCommentStarted,
    'New comment typing started'
  );
  export const videoCommentAdded = _generateEventCreator<{ id: number; comment_type: CommentAccessibility; hub_id: number | null }>(
    HostingAnalyticsEventName.VideoCommentAdded,
    'New comment submitted'
  );
  export const videoCommentEdited = _generateEventCreator<{ id: number; comment_type: CommentAccessibility; hub_id: number | null }>(
    HostingAnalyticsEventName.VideoCommentEdited,
    'Comment edited'
  );
  export const videoCommentDeleted = _generateEventCreator<{ id: number; comment_type: CommentAccessibility; hub_id: number | null }>(
    HostingAnalyticsEventName.VideoCommentDeleted,
    'Comment deleted'
  );
  export const idle = _generateEventCreator<{ url: string }>(
    HostingAnalyticsEventName.Idle,
    'User is idle'
  );
  export const pageVisited = _generateEventCreator<{ url: string }>(
    HostingAnalyticsEventName.PageVisited,
    'New page visited'
  );
  
  // Public events
  export const switchToVideo = _generateEventCreator<{ id: string; account_id: string; title: string }>(
    HostingAnalyticsEventName.SwitchToVideo,
    'select video'
  )
  export const videoPlay = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.VideoPlay,
    'Start video'
  )
  export const videoPause = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.VideoPause,
    'Pausing video'
  )
  export const videoSeek = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.VideoSeek,
    'Seeking in video'
  )
  export const videoEnded = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.VideoEnded,
    'Video ended'
  )
  export const videoCompleted = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.VideoCompleted,
    'Video play time is at least 90% of the video length'
  )
  export const ctaButtonClicked = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.CtaButtonClicked,
    'CTA button clicked'
  )
  export const toggleFullscreen = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.ToggleFullscreen,
    'Toggle full screen'
  )
  export const togglePictureInPicture = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.TogglePictureInPicture,
    'Toggle picture in picture mode'
  )
  export const toggleCaptions = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.ToggleCaptions,
    'Toggle captions'
  )
  export const toggleChapters = _generateEventCreator<PublicVideoEventProperties>(
    HostingAnalyticsEventName.ToggleChapters,
    'Toggle chapters'
  )
}