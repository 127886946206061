import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { splitNameAndExtension, getMimeTypeFromExtension, SignedUrl } from '@openreel/common';
import { GetUploadCredentialsOptions } from '@openreel/ui/openreel-uploader';
import { map } from 'rxjs';
import { commonenv } from '../../environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';

interface AssetDto {
    id: string;
    uploadUrl?: SignedUrl;
}

@Injectable()
export class PresentationApiService {
    private urls = {
        uploadUrl: `${commonenv.nextGenApiUrl}presentations/upload-url`,
    };

    constructor(private readonly httpClient: HttpClient) { }

    getUploadCredentials(data: GetUploadCredentialsOptions) {
        return this.httpClient.post<AssetDto>(this.urls.uploadUrl, data)
            .pipe(
                map((response) => ({
                    id: response.id,
                    credential: response.uploadUrl.url,
                }))
            )
    }

    upload(signedUrl: string, file: File) {
        const [, extension] = splitNameAndExtension(file.name);
        return this.httpClient.put(signedUrl, file, {
            headers: new HttpHeaders({
                [CaseConverterInterceptorSkipHeader]: '',
                'Content-Type': getMimeTypeFromExtension(extension),
            }),
            reportProgress: true,
            observe: 'events',
        });
    }
}