import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { commonenv } from '@openreel/frontend/common/env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BrandfetchRetrieveBrand } from '@openreel/common';

@Injectable()
export class BrandKitAutofetchService {

  private readonly urls = {
    baseUrl: commonenv.nextGenApiUrl + 'brandfetch',
  };

  private readonly httpClient = inject(HttpClient);

  retrieveBrand(domainName: string): Observable<BrandfetchRetrieveBrand> {
    const queryParams = new HttpParams().append('domainName', domainName);

    return this.httpClient.get<BrandfetchRetrieveBrand>(`${ this.urls.baseUrl }/brand`, {
      params: queryParams,
    });
  }
}
