import { Injectable } from '@angular/core';
import { AppUser } from '@openreel/frontend/common';
import { IUserDetails } from '@openreel/common';
import { MSG_GET_AUTH_DATA } from '../../messages';
import { ChromeMessagingService } from '../chrome-messaging/chrome-messaging.service';

@Injectable({
  providedIn: 'root',
})
export class AuthDataSharingService {
  constructor(private readonly chromeMessagingService: ChromeMessagingService) {}

  async getExtensionAuthData(): Promise<AppUser<IUserDetails>> {
    try {
      return await this.chromeMessagingService.send({
        event: MSG_GET_AUTH_DATA,
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
