import * as Interfaces from './recording-backgrounds.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class RecordingBackgroundsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}recording-backgrounds`,
    reorderAll: `${commonenv.nextGenApiUrl}recording-backgrounds/reorder`,
    creatorAll: `${commonenv.nextGenApiUrl}workflow-recordings/backgrounds`,
    byId: (id: number) => `${this.urls.base}/${id}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<Interfaces.RecordingBackground[]>(this.urls.base);
  }

  reorderAll(ids: number[]) {
    return this.httpClient.post<Interfaces.RecordingBackground[]>(this.urls.reorderAll, { ids });
  }

  getAllForCreator() {
    return this.httpClient.get<Interfaces.RecordingBackground[]>(this.urls.creatorAll);
  }

  getById(id: number) {
    return this.httpClient.get<Interfaces.RecordingBackground>(this.urls.byId(id));
  }

  create(data: Interfaces.RecordingBackgroundForm) {
    return this.httpClient.post<Interfaces.RecordingBackground>(this.urls.base, data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  delete(id: number) {
    return this.httpClient.delete<void>(this.urls.byId(id));
  }
}
