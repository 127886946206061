<div mat-dialog-title class="dialog-header">
  <div class="heading-wrapper">
    <div class="icon-wrapper">
      <div class="icon">
        <mat-icon svgIcon="copy"></mat-icon>
      </div>
    </div>

    <div class="heading">
      <h2 class="title">We have found following options</h2>
      <h3 class="subtitle">Please select which version is correct</h3>
    </div>
  </div>

  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <openreel-brandkit-form [data]="data" />
</mat-dialog-content>

<mat-dialog-actions>
  <div class="actions">
    <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="confirm()">Confirm</button>
  </div>
</mat-dialog-actions>
