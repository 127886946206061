import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IOpenreelPresentationFacade, PRESENTATION_FACADE, Slide } from './openreel-presentations.facade';

@Component({
  selector: 'openreel-presentations',
  templateUrl: './openreel-presentations.component.html',
  styleUrls: ['./openreel-presentations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenreelPresentationsComponent {
  @Input()
  slides: Slide[];

  @Input()
  currentSlide: Slide;

  @Input()
  isPresenter: boolean;

  constructor(@Inject(PRESENTATION_FACADE) public presentationFacade: IOpenreelPresentationFacade) {}

  setCurrentSlide(slide: Slide) {
    this.presentationFacade.setCurrentSlide(slide);
  }
}
