import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionOtpLoginRequest, SessionResponse } from '../../../interfaces';
import { commonenv } from '@openreel/frontend/common/env/environment';

const URL_OTP_LOGIN = `${commonenv.nextGenApiUrl}session-otp-login`;

const LOGOUT_SUBJECT = `${commonenv.nextGenApiUrl}logout`;

@Injectable({
  providedIn: 'root',
})
export class SubjectApiService {
  constructor(private httpClient: HttpClient) {}

  joinOtp(request: SessionOtpLoginRequest) {
    return this.httpClient.post<SessionResponse>(URL_OTP_LOGIN, request);
  }

  logoutSubject() {
    return this.httpClient.get<{ data: { invited_token: string; name: string; otp: string } }>(LOGOUT_SUBJECT);
  }
}
