import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SocketDirectorExtensionDeviceService } from '../director/socket-extensions/socket-director-extension-device.service';
import { NextgenParticipant } from '../../interfaces/nextgen-participant.interface';
import { HardwareConsumption, NetworkSpeed, PerformanceService } from './performance.interface';
@Injectable()
export class RemotePerformanceService implements PerformanceService {
  hardwareConsumption$: Subject<HardwareConsumption>;
  networkSpeed$: Subject<NetworkSpeed>;

  constructor(private readonly socketDevice: SocketDirectorExtensionDeviceService) {
    this.hardwareConsumption$ = new Subject<HardwareConsumption>();
    this.networkSpeed$ = new Subject<NetworkSpeed>();
  }

  fetchHardwarePerformance(participant: NextgenParticipant) {
    this.socketDevice
      .getDeviceHardwarePerformance(participant.identity)
      .then(
        ({
          cpu_speed,
          cpu_count,
          cpu_usage,
          cpu_app_usage,
          memory,
          memory_app_usage,
          memory_usage,
          network_quality,
        }) => {
          this.hardwareConsumption$.next({
            cpuSpeed: cpu_speed,
            cpuCount: cpu_count,
            cpuUsage: cpu_usage,
            cpuAppUsage: cpu_app_usage,
            memory,
            memoryAppUsage: memory_app_usage,
            memoryUsage: memory_usage,
            networkQuality: network_quality,
          });
        }
      );
  }

  fetchNetworkSpeed(participant: NextgenParticipant) {
    this.socketDevice.getDeviceNetworkSpeed(participant.identity).then((speed) => {
      this.networkSpeed$.next({
        download: speed.download || 0,
        upload: speed.upload || 0,
      });
    });
  }
}
