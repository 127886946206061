import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as FullStory from '@fullstory/browser';
@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  //TODO: Change that when we split out environments to local, staging and production, should be !environment.production

  enableFullStoryInDevMode = true;
  constructor() {
    FullStory.init({
      orgId: 'WR1KF',
      devMode: !this.enableFullStoryInDevMode && !environment.production,
    });
  }
  init() {
    //TODO: Do we need this?
  }
}
