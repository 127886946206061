<div mat-dialog-title>
  <div class="icon-wrapper">
    <mat-icon class="icon" svgIcon="premium-user"></mat-icon>
  </div>
  <div class="text-wrapper">
    <h2>Your 14-Day Trial Has Ended</h2>
    <p *ngIf="isRootAdmin">Upgrade now to continue enjoying OpenReel's full features!</p>
    <p *ngIf="!isRootAdmin">
      Your administrator will need to upgrade your team's account. Please contact your administrator
    </p>
  </div>
</div>
<div class="divider-bar"></div>
<div mat-dialog-content>
  <ng-container *ngIf="isRootAdmin">
    <div class="pricing-wrapper" *ngIf="selectedProduct$ | async as selectedProduct">
      <div class="pricing" [class.active]="isPremiumProduct" (click)="isPremiumProduct = true">
        <div class="labels">
          <p>
            <span class="price"> ${{ selectedProduct.price }} </span>
            <span class="recurring"> seat / {{ selectedProduct.recurringInterval }} </span>
          </p>
          <p class="name">{{ selectedProduct.name }}</p>
        </div>
        <ng-container
          *ngIf="{ selected: selected$ | async, monthly: monthly$ | async, annual: annual$ | async } as data"
        >
          <div class="tabs" *ngIf="data.monthly && data.annual">
            <div class="tab" [class.active]="data.selected === 'month'" (click)="selected$.next('month')">
              Monthly Billing
            </div>
            <div class="tab" [class.active]="data.selected === 'year'" (click)="selected$.next('year')">
              Annual Billing
            </div>
          </div>
        </ng-container>

        <div class="features">
          <ul>
            <li *ngFor="let feature of selectedProduct.features">
              <div class="feature">
                <mat-icon class="icon" svgIcon="feature-check"></mat-icon>
                <p>{{ feature }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="pricing" [class.active]="!isPremiumProduct" (click)="isPremiumProduct = false">
        <div class="labels">
          <p>
            <span class="price"> Contact Us </span>
          </p>
          <p class="name">OpenReel One</p>
        </div>
        <div class="features">
          <ul>
            <li *ngFor="let feature of enterpriseFeatures">
              <div class="feature">
                <mat-icon class="icon" svgIcon="feature-check"></mat-icon>
                <p>{{ feature }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="data-notice">
      <h2>Data Deletion Notice</h2>
      <p>
        Your data will be deleted in 60 days unless you upgrade. (<span class="day-remaining"
          >Day remaining: {{ dayRemaining }}</span
        >)
      </p>
    </div>
    <div class="divider-bar"></div>
  </ng-container>
  <div class="action-wrapper">
    <button mat-stroked-button (click)="openChatWindow()">
      <mat-icon class="icon" svgIcon="chat-to-us"></mat-icon>
      Chat to us
    </button>
    <button mat-raised-button color="primary" class="select-plan" *ngIf="isRootAdmin" (click)="selectPlan()">
      Select Plan
    </button>
  </div>
</div>
<!--logout button-->
<div *ngIf="userDetails" class="logout-menu">
  <openreel-profile-menu
    [name]="userDetails.fullname"
    [accountName]="userDetails.site_users?.name"
    (logoutButtonClicked)="onLogoutButtonClicked()"
  ></openreel-profile-menu>
</div>
<!--logout button-->
