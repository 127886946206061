export enum ApprovableTypes {
  WorkflowVideo = 'workflow_video',
  HostedVideo = 'hosted_video',
}

export enum ApprovalStatuses {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Canceled = 'cancelled',
}

export enum ApprovalWorkflowRules {
  REQUIRES_ALL_APPROVALS_PER_STEP = 'requires_all_approvals_per_step',
  REQUIRES_ONE_APPROVAL_PER_STEP = 'requires_one_approval_per_step',
}

export enum ApprovalWorkflowModes {
  PARALLEL = 'parallel',
  SERIAL = 'serial',
}

export enum ApprovalWorkflowParticipantEntities {
  USER = 'user',
  GROUP = 'group',
}

export enum ApprovalNotificationRecipientEntities {
  APPROVER = 'approver',
  APPROVAL_SUBMITTER = 'approval_submitter',
  PROJECT_OWNER = 'project_creator',
}

export enum ApprovalNotificationStatuses {
  CREATED = 'created',
  PENDING = 'pending',
  SENT = 'sent',
  FAILED = 'failed',
}

export enum ApprovalActions {
  SUBMIT = 'submit',
  APPROVE = 'approve',
  FINAL_APPROVE = 'final_approve',
  REJECT = 'reject',
  CANCEL = 'cancel',
}
