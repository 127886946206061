import jwtDecode from 'jwt-decode';

export const getUserClaimByToken = (jwt: string, name: string) => {
  try {
    return jwtDecode(jwt)[name];
  } catch (e) {
    return undefined;
  }
};

export const tokenExpired = (jwt: string) => {
  const exp = getUserClaimByToken(jwt, 'exp');
  const now = new Date().getTime() / 1000;
  const expired = !exp || now > exp;
  return !!expired;
};

export const getEmailByToken = (jwt: string) => getUserClaimByToken(jwt, 'email');

export const parseJwt = <T>(jwt: string): T => {
  try {
    return jwtDecode<T>(jwt);
  } catch (e) {
    return undefined;
  }
};
