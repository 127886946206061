export const MANUAL_S3_BUCKETNAME_PREFIX = 'orc-alt-';

export enum S3IntegrationErrorCodes {
  RoleAssume = 'ROLE_ASSUME',
  DuplicateEntryExist = 'ER_DUP_ENTRY',
  PutObjectError = 'PUT_ERROR',
  GetObjectError = 'GET_ERROR',
  DeleteObjectError = 'DELETE_ERROR',
  AltBucketNameNotFound = 'ALTERNATE_BUCKET_NAME_NOT_FOUND',
  AltBucketNameCreateErr = 'ALTERNATE_BUCKET_NAME_CREATE_ERR',
  AltBucketNameSaveErr = 'ALTERNATE_BUCKET_NAME_SAVE_ERR',
  AltBucketNameDupErr = 'ALTERNATE_BUCKET_NAME_DUP_ERR',
}

export enum S3IntegrationErrorMsg {
  AltBucketExistErrorMsg = 'This bucket name already exist for another account. Please re-create a new bucket name',
  AltBucketName404Msg = 'Bucket name not found, please try again',
  S3AlreadyExist = 'S3 is already integrated',
  // eslint-disable-next-line max-len
  S3PutOrGetOrAssumeError = 'S3 Integration failed, check S3 parameters. Make sure role has GET and PUT object permissions. For more details contact OpenReel support',
  // eslint-disable-next-line max-len
  RoleAssume = 'S3 Integration failed, assume role failed. For more details contact OpenReel support',
  PutObjectError = "S3 Integration failed, role doesn't have object PUT permissions. For more details contact OpenReel support",
  GetObjectError = "S3 Integration failed, role doesn't have object GET permissions. For more details contact OpenReel support",
  DeleteObjectError = "S3 Integration failed, role doesn't have object DELETE permissions. For more details contact OpenReel support",
}

export enum S3ConfigError {
  IntegrationNotFound = 'S3_INTEGRATION_NOT_FOUND',
  IntegrationModified = 'S3_INTEGRATION_MODIFIED',
}

export enum S3AccelerateConfiguration {
  Enabled = 'Enabled',
  Suspended = 'Suspended',
}

export enum S3Regions {
  'US East 1' = 'us-east-1',
  'AF South 1' = 'af-south-1',
  'AP East 1' = 'ap-east-1',
  'AP NorthEast 1' = 'ap-northeast-1',
  'AP NorthEast 2' = 'ap-northeast-2',
  'AP NorthEast 3' = 'ap-northeast-3',
  'AP South 1 ' = 'ap-south-1',
  'AP SouthEast 1' = 'ap-southeast-1',
  'AP SouthEast 2' = 'ap-southeast-2',
  'CA Central 1' = 'ca-central-1',
  'CN North 1' = 'cn-north-1',
  'CN NorthWest 1' = 'cn-northwest-1',
  'EU' = 'EU',
  'EU Central 1' = 'eu-central-1',
  'EU North 1' = 'eu-north-1',
  'EU South 1' = 'eu-south-1',
  'EU West 1' = 'eu-west-1',
  'EU West 2' = 'eu-west-2',
  'EU West 3' = 'eu-west-3',
  'ME South 1' = 'me-south-1',
  'SA East 1' = 'sa-east-1',
  'US East 2' = 'us-east-2',
  'US GOV East 1' = 'us-gov-east-1',
  'US GOV West 1' = 'us-gov-west-1',
  'US West 1' = 'us-west-1',
  'US West 2' = 'us-west-2',
}
