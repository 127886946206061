import { sumBy } from 'lodash';
import { Asset, LayerOptions, Section, SectionLayer, SectionTimeline, WorkflowDataDto } from '../interfaces';
import { getAssetFinalTrims } from './trimmer.helpers';

export function getAssetDuration(asset: Asset) {
  return asset.data?.duration ?? 0;
}

export function getAssetDurationAfterTextTrimmer(asset: Asset) {
  let assetDuration = getAssetDuration(asset);

  if (asset.textCuts?.length) {
    assetDuration -= sumBy(asset.textCuts, (t) => t.to - t.from);
  }

  return assetDuration;
}

export function getAssetFinalDuration(asset: Asset) {
  const finalTrims = getAssetFinalTrims(asset);

  const duration = finalTrims.reduce((prev, curr) => prev + (curr.to - curr.from), 0);
  return duration;
}

export function getSectionDuration(section: Section, assets: Asset[]) {
  const mainTimelines = section?.timelines.filter((t) => t.type === 'main');
  if (mainTimelines.length === 0) {
    return section.sectionDuration ?? 0;
  }

  return getMaxTimelinesDuration(mainTimelines, assets);
}

export function getWorkflowDuration(workflow: WorkflowDataDto) {
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');

  let duration = 0;
  for (const layer of mainTimeline.layers) {
    if (!layer.enabled) {
      continue;
    }

    // NOTE: null fallback is for older projects that do not yet have sectionDuration.
    // Once all projects are 3.2+ we can remove null coalesce
    duration += workflow.sections[(layer as SectionLayer).sectionId].sectionDuration ?? 0;
  }
  return duration;
}

export function getTimelineDuration(timeline: SectionTimeline, assets: Asset[]): number {
  if (timeline.layers.length === 0) {
    return 0;
  }

  const layer = timeline.layers[0];

  let assetDuration = null;
  if (layer.type === 'lottie' || layer.type === 'video') {
    const asset = assets.find((a) => a.id === layer.assetId);
    assetDuration = getAssetFinalDuration(asset);
  }

  const startAt = layer.visibility?.startAt ?? 0;
  const endAt = layer.visibility?.endAt ?? assetDuration ?? 0;

  return Math.max(endAt - startAt, 0);
}

export function getMaxTimelinesDuration(timelines: SectionTimeline[], assets: Asset[]) {
  const mainTimelines = timelines.filter((t) => t.type === 'main');
  return mainTimelines.reduce((prev, curr) => Math.max(prev, getTimelineDuration(curr, assets)), 0);
}

export function getIntroDuration(workflow: WorkflowDataDto) {
  const enabledLayers = workflow.timelines[0].layers.filter(
    (layer) => layer.type === 'section' && layer.enabled
  ) as (LayerOptions & SectionLayer)[];

  const introSections = Object.entries(workflow.sections).filter(
    ([sectionId, section]) =>
      section.sectionType === 'intro' && enabledLayers.find((layer) => layer.sectionId === sectionId)
  );

  return sumBy(introSections, ([, section]) => section.sectionDuration);
}
