import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services';
import { Injectable } from '@angular/core';
import { ROUTE_DIRECTOR_LOGIN, ROUTE_DIRECTOR } from '../route-utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DirectorSessionGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated$.pipe(
      map((isAuthenticate) => {
        if (!isAuthenticate) {
          this.router.navigate([`/${ROUTE_DIRECTOR}`, ROUTE_DIRECTOR_LOGIN], {
            queryParams: {
              redirect: state.url,
            },
          });
          return false;
        }

        return true;
      })
    );
  }
}
